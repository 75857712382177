var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"ParameterYh drag",attrs:{"id":"ParameterYh"}},[_c('div',{staticClass:"param-header"},[_c('span',{staticClass:"param-header-title"},[_vm._v(_vm._s(_vm.projectData.project)+"_优化参数")]),_c('div',{staticClass:"param-header-icon",on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"evaluate-header-tit"},[_vm._v("热风炉优化参数设置")]),_c('div',{staticClass:"ParameterYh-box"},[_c('div',{staticClass:"ParameterYh-box-flex flex align-cen"},[_c('div',{staticClass:"ParameterYh-box-flex-left publicborder"},[_vm._v("1#")]),_c('div',{staticClass:"ParameterYh-box-flex-con1 publicborder"},[_c('div',{staticClass:"con1-column"},[_c('div',{staticClass:"column1 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1",staticStyle:{"width":"7vw"}},[_c('div',{staticClass:"row1-text"},[_vm._v("优化时机")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ1 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1 column1-row2",staticStyle:{"width":"4vw"}},[_c('div',{staticClass:"row1-text"},[_vm._v("自动标志")]),_c('div',{staticClass:"row1-status",style:({
                  background:
                    _vm.infoList.KQZD1_B1 || _vm.infoList.MQZD1_B1
                      ? '#29DFAE'
                      : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1 column1-row2",staticStyle:{"width":"4vw"}},[_c('div',{staticClass:"row1-text"},[_vm._v("烧炉时间")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ5_B1 ? '#29DFAE' : '#f5fbff',
                })})])]),_c('div',{staticClass:"column1 column2 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1 column1-row1-frist"},[_c('div',{staticClass:"row1-text"},[_vm._v("拱顶温度")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ1_B1 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("废气温度")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ2_B1 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("单烧启停")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ4_B1 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("配比波动")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ3_B1 ? '#29DFAE' : '#00aeff10',
                })})])]),_c('div',{staticClass:"column1 column2 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1 column1-row1-frist"},[_c('div',{staticClass:"row1-text"},[_vm._v("拱顶高限")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.infoList.GDWDH_B1,"placement":"top"}},[_c('div',{staticClass:"row1-text1"},[_vm._v(_vm._s('GDWDH_B1' in _vm.infoList?_vm.infoList.GDWDH_B1 :0)+"℃")])])],1),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("拱顶时间")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.infoList.YST2_B1,"placement":"top"}},[_c('div',{staticClass:"row1-text1 ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.YST2_B1,
                      '拱顶时间',
                      'YST2_B1',
                      _vm.infoList.YST2_B1_node_string
                    )}}},[_vm._v(" "+_vm._s('YST2_B1' in _vm.infoList?_vm.infoList.YST2_B1 :0)+"S ")])])],1),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("废气高限")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.infoList.FQBHZ_B1,"placement":"top"}},[_c('div',{staticClass:"row1-text1"},[_vm._v(_vm._s('FQBHZ_B1' in _vm.infoList?_vm.infoList.FQBHZ_B1 :0)+"℃")])])],1),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("废气时间")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.infoList.YST3_B1,"placement":"top"}},[_c('div',{staticClass:"row1-text1 ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.YST3_B1,
                      '废气时间',
                      'YST3_B1',
                      _vm.infoList.YST3_B1_node_string
                    )}}},[_vm._v(" "+_vm._s('YST3_B1' in _vm.infoList?_vm.infoList.YST3_B1 :0)+"S ")])])],1)]),_c('div',{staticClass:"column1 column3 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1 column1-row1-frist"},[_c('div',{staticClass:"row1-text"},[_vm._v("顶温稳态精度")]),_c('div',{staticClass:"row1-text1"},[_vm._v(_vm._s('GDWD_XJ_B1' in _vm.infoList?_vm.infoList.GDWD_XJ_B1 :0)+"℃")])]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("烧炉时间")]),_c('div',{staticClass:"row1-text1 ipt",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YST1_B1,
                    '烧炉时间',
                    'YST1_B1',
                    _vm.infoList.YST1_B1_node_string
                  )}}},[_vm._v(" "+_vm._s('YST1_B1' in _vm.infoList?_vm.infoList.YST1_B1 :0)+" m ")])])])])]),_c('div',{staticClass:"ParameterYh-box-flex-con2 publicborder"},[_c('div',{staticClass:"con2-column"},[_c('div',{staticClass:"column-top"},[_c('div',{staticClass:"column-row flex justify-content-cen"},[_c('div',{staticClass:"column-row-data column-row-frist"},[_c('div',{staticClass:"data-name"},[_vm._v("TOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.TOP1,
                      'TOP',
                      'TOP1',
                      _vm.infoList.TOP1_node_string
                    )}}},[_vm._v(" "+_vm._s('TOP1' in _vm.infoList?_vm.infoList.TOP1 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风媒比SOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.SOP1,
                      '风媒比SOP',
                      'SOP1',
                      _vm.infoList.SOP1_node_string
                    )}}},[_vm._v(" "+_vm._s('SOP1' in _vm.infoList?_vm.infoList.SOP1 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("EOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.EOP1,
                      'EOP',
                      'EOP1',
                      _vm.infoList.EOP1_node_string
                    )}}},[_vm._v(" "+_vm._s('EOP1' in _vm.infoList?_vm.infoList.EOP1 :0)+"℃ ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("基本风煤比")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.JBFMB_B1,
                      '基本风媒比',
                      'JBFMB_B1',
                      _vm.infoList.JBFMB_B1_node_string
                    )}}},[_vm._v(" "+_vm._s('JBFMB_B1' in _vm.infoList?_vm.infoList.JBFMB_B1 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("实际风煤比")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'SJFMB_B1',
                      _vm.infoList.SJFMB_B1_node_string,
                      'SJFMB_B1_WF',
                      '1#实际风煤比'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.SJFMB_B1,
                      'SJFMB_B1',
                      _vm.infoList.SJFMB_B1_node_string,
                      'SJFMB_B1_WF'
                    )}}},[_vm._v(" "+_vm._s('SJFMB_B1' in _vm.infoList?_vm.infoList.SJFMB_B1 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比范围")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.FV_LL_B1,
                      '风煤比范围',
                      'FV_LL_B1',
                      _vm.infoList.FV_LL_B1_node_string
                    )}}},[_vm._v(" "+_vm._s('FV_LL_B1' in _vm.infoList?_vm.infoList.FV_LL_B1 :0)+"% ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("MAXEOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.MAXEOP_B1,
                      'MAXEOP',
                      'MAXEOP_B1',
                      _vm.infoList.MAXEOP_B1_node_string
                    )}}},[_vm._v(" "+_vm._s('MAXEOP_B1' in _vm.infoList?_vm.infoList.MAXEOP_B1 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("OLD")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'BCS_ML_B1',
                      _vm.infoList.BCS_ML_B1_node_string,
                      'BCS_ML_B1_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCS_ML_B1,
                      'BCS_ML_B1',
                      _vm.infoList.BCS_ML_B1_node_string,
                      'BCS_ML_B1_WF'
                    )}}},[_vm._v(" "+_vm._s('BCS_ML_B1' in _vm.infoList?_vm.infoList.BCS_ML_B1 :0)+" ")])])]),_c('div',{staticClass:"column-row column-row1 flex justify-content-cen"},[_c('div',{staticClass:"column-row-data column-row-frist"},[_c('div',{staticClass:"data-name"},[_vm._v("最高顶温")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'UHFV1_B1',
                      _vm.infoList.UHFV1_B1_node_string,
                      'UHFV1_B1_WF',
                      '1#煤气阀开度线性上限'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.UHFV1_B1,
                      'UHFV1_B1',
                      _vm.infoList.UHFV1_B1_node_string,
                      'UHFV1_B1_WF'
                    )}}},[_vm._v(" "+_vm._s('UHFV1_B1' in _vm.infoList?_vm.infoList.UHFV1_B1 :0)+"℃ ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("当前顶温")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'TE_12_B1',
                      _vm.infoList.TE_12_B1_node_string,
                      'TE_12_B1_WF',
                      '1#热风炉拱顶温度2'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.TE_12_B1,
                      'TE_12_B1',
                      _vm.infoList.TE_12_B1_node_string,
                      'TE_12_B1_WF'
                    )}}},[_vm._v(" "+_vm._s('TE_12_B1' in _vm.infoList?_vm.infoList.TE_12_B1 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("下降幅度")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.GDWD_XJ_B1,
                      '下降幅度',
                      'GDWD_XJ_B1',
                      _vm.infoList.GDWD_XJ_B1_node_string
                    )}}},[_vm._v(" "+_vm._s('GDWD_XJ_B1' in _vm.infoList?_vm.infoList.GDWD_XJ_B1 :0)+"℃ ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比增量")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'BCS_KH_B1',
                      _vm.infoList.BCS_KH_B1_node_string,
                      'BCS_KH_B1_WF',
                      '1#优化空气量累积'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCS_KH_B1,
                      'BCS_KH_B1',
                      _vm.infoList.BCS_KH_B1_node_string,
                      'BCS_KH_B1_WF'
                    )}}},[_vm._v(" "+_vm._s('BCS_KH_B1' in _vm.infoList?_vm.infoList.BCS_KH_B1 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("参考风煤比")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KM_MQ_B1',
                      _vm.infoList.KM_MQ_B1_node_string,
                      'KM_MQ_B1_WF',
                      '1#炉参考风煤比'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.KM_MQ_B1,
                      'KM_MQ_B1',
                      _vm.infoList.KM_MQ_B1_node_string,
                      'KM_MQ_B1_WF'
                    )}}},[_vm._v(" "+_vm._s('KM_MQ_B1' in _vm.infoList?_vm.infoList.KM_MQ_B1 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比上限")]),_c('div',{staticClass:"data-num"},[_vm._v(_vm._s('JBFMBH_B1' in _vm.infoList?_vm.infoList.JBFMBH_B1 :0))])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比下限")]),_c('div',{staticClass:"data-num"},[_vm._v(_vm._s('JBFMBL_B1' in _vm.infoList?_vm.infoList.JBFMBL_B1 :0))])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("NEW")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'BCS_MH_B1',
                      _vm.infoList.BCS_MH_B1_node_string,
                      'BCS_MH_B1_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCS_MH_B1,
                      'BCS_MH_B1',
                      _vm.infoList.BCS_MH_B1_node_string,
                      'BCS_MH_B1_WF'
                    )}}},[_vm._v(" "+_vm._s('BCS_MH_B1' in _vm.infoList?_vm.infoList.BCS_MH_B1 :0)+" ")])])])]),_c('div',{staticClass:"column-bot flex"},[_c('div',{staticClass:"column-bot-left"},[_vm._v("趋势优化")]),_c('div',{staticClass:"column-bot-right flex align-cen justify-content-cen"},[_c('div',{staticClass:"column-row-data column-row-frist"},[_c('div',{staticClass:"data-name"},[_vm._v("采样时间")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.AOPH_B1,
                      '采样时间',
                      'AOPH_B1',
                      _vm.infoList.AOPH_B1_node_string
                    )}}},[_vm._v(" "+_vm._s('AOPH_B1' in _vm.infoList?_vm.infoList.AOPH_B1 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("专家时间")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.AOPL_B1,
                      '专家时间',
                      'AOPL_B1',
                      _vm.infoList.AOPL_B1_node_string
                    )}}},[_vm._v(" "+_vm._s('AOPL_B1' in _vm.infoList?_vm.infoList.AOPL_B1 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("动态系数")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.KM_B1,
                      '动态系数',
                      'KM_B1',
                      _vm.infoList.KM_B1_node_string
                    )}}},[_vm._v(" "+_vm._s('KM_B1' in _vm.infoList?_vm.infoList.KM_B1 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("启动值")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.GDWDL_B1,
                      '启动值',
                      'GDWDL_B1',
                      _vm.infoList.GDWDL_B1_node_string
                    )}}},[_vm._v(" "+_vm._s('GDWDL_B1' in _vm.infoList?_vm.infoList.GDWDL_B1 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("持续时间")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.JBMG01_B1,
                      '持续时间',
                      'JBMG01_B1',
                      _vm.infoList.JBMG01_B1_node_string
                    )}}},[_vm._v(" "+_vm._s('JBMG01_B1' in _vm.infoList?_vm.infoList.JBMG01_B1 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("观测输出")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'FT_11N_B1',
                      _vm.infoList.FT_11N_B1_node_string,
                      'FT_11N_B1_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.FT_11N_B1,
                      'FT_11N_B1',
                      _vm.infoList.FT_11N_B1_node_string,
                      'FT_11N_B1_WF'
                    )}}},[_vm._v(" "+_vm._s('FT_11N_B1' in _vm.infoList?_vm.infoList.FT_11N_B1 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("优化输出")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'FT_12N_B1',
                      _vm.infoList.FT_12N_B1_node_string,
                      'FT_12N_B1_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.FT_12N_B1,
                      'FT_12N_B1',
                      _vm.infoList.FT_12N_B1_node_string,
                      'FT_12N_B1_WF'
                    )}}},[_vm._v(" "+_vm._s('FT_12N_B1' in _vm.infoList?_vm.infoList.FT_12N_B1 :0)+" ")])])])])])]),_c('div',{staticClass:"ParameterYh-box-flex-con3 publicborder"},[_c('div',{staticClass:"con3-column"},[_c('div',{staticClass:"con3-column-flex1"},[_c('div',{staticClass:"con3-column-flex1-text"},[_vm._v("优化方向及进程")]),_c('div',{staticClass:"con3-column-flex1-progress flex align-cen justify-content-cen"},[_c('div',[_vm._v("0")]),_c('div',{staticClass:"pro"},[(_vm.infoList.UHFV2_B1 >= 0 ? false : true)?_c('el-progress',{attrs:{"show-text":false,"stroke-width":10,"percentage":_vm.infoList.UHFV2_B1,"color":_vm.customColor}}):_vm._e()],1),_c('div',[_vm._v("100")])])]),_c('div',{staticClass:"con3-column-flex2 flex align-cen justify-content-cen"},[_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("强制优化")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.RESET1 ? '#29DFAE' : '#00aeff10',
                }),on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RESET1,
                    '强制优化',
                    'RESET1',
                    _vm.infoList.RESET1_node_string
                  )}}})]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("优化停止")]),_c('div',{staticClass:"row1-status",style:({ background: _vm.infoList.STOP1 ? 'red' : '#00aeff10' }),on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.STOP1,
                    '优化停止',
                    'STOP1',
                    _vm.infoList.STOP1_node_string
                  )}}})])])])])]),_c('div',{staticClass:"ParameterYh-box-flex flex align-cen"},[_c('div',{staticClass:"ParameterYh-box-flex-left publicborder"},[_vm._v("2#")]),_c('div',{staticClass:"ParameterYh-box-flex-con1 publicborder"},[_c('div',{staticClass:"con1-column"},[_c('div',{staticClass:"column1 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1",staticStyle:{"width":"7vw"}},[_c('div',{staticClass:"row1-text"},[_vm._v("优化时机")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ2 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1 column1-row2",staticStyle:{"width":"4vw"}},[_c('div',{staticClass:"row1-text"},[_vm._v("自动标志")]),_c('div',{staticClass:"row1-status",style:({
                  background:
                    _vm.infoList.KQZD1_B2 || _vm.infoList.MQZD1_B2
                      ? '#29DFAE'
                      : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1 column1-row2",staticStyle:{"width":"4vw"}},[_c('div',{staticClass:"row1-text"},[_vm._v("烧炉时间")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ5_B2 ? '#29DFAE' : '#00aeff10',
                })})])]),_c('div',{staticClass:"column1 column2 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1 column1-row1-frist"},[_c('div',{staticClass:"row1-text"},[_vm._v("拱顶温度")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ1_B2 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("废气温度")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ2_B2 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("单烧启停")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ4_B2 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("配比波动")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ3_B2 ? '#29DFAE' : '#00aeff10',
                })})])]),_c('div',{staticClass:"column1 column2 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1 column1-row1-frist"},[_c('div',{staticClass:"row1-text"},[_vm._v("拱顶高限")]),_c('div',{staticClass:"row1-text1"},[_vm._v(_vm._s('GDWDH_B2' in _vm.infoList?_vm.infoList.GDWDH_B2 :0)+"℃")])]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("拱顶时间")]),_c('div',{staticClass:"row1-text1 ipt",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YST2_B2,
                    '拱顶时间',
                    'YST2_B2',
                    _vm.infoList.YST2_B2_node_string
                  )}}},[_vm._v(" "+_vm._s('YST2_B2' in _vm.infoList?_vm.infoList.YST2_B2 :0)+"S ")])]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("废气高限")]),_c('div',{staticClass:"row1-text1"},[_vm._v(_vm._s('FQBHZ_B2' in _vm.infoList?_vm.infoList.FQBHZ_B2 :0)+"℃")])]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("废气时间")]),_c('div',{staticClass:"row1-text1 ipt",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YST3_B2,
                    '废气时间',
                    'YST3_B2',
                    _vm.infoList.YST3_B2_node_string
                  )}}},[_vm._v(" "+_vm._s('YST3_B2' in _vm.infoList?_vm.infoList.YST3_B2 :0)+"S ")])])]),_c('div',{staticClass:"column1 column3 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1 column1-row1-frist"},[_c('div',{staticClass:"row1-text"},[_vm._v("顶温稳态精度")]),_c('div',{staticClass:"row1-text1 ipt"},[_vm._v(_vm._s('GDWD_XJ_B2' in _vm.infoList?_vm.infoList.GDWD_XJ_B2 :0)+"℃")])]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("烧炉时间")]),_c('div',{staticClass:"row1-text1 ipt",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YST1_B2,
                    '烧炉时间',
                    'YST1_B2',
                    _vm.infoList.YST1_B2_node_string
                  )}}},[_vm._v(" "+_vm._s('YST1_B2' in _vm.infoList?_vm.infoList.YST1_B2 :0)+" m ")])])])])]),_c('div',{staticClass:"ParameterYh-box-flex-con2 publicborder"},[_c('div',{staticClass:"con2-column"},[_c('div',{staticClass:"column-top"},[_c('div',{staticClass:"column-row flex justify-content-cen"},[_c('div',{staticClass:"column-row-data column-row-frist"},[_c('div',{staticClass:"data-name"},[_vm._v("TOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.TOP2,
                      'TOP',
                      'TOP2',
                      _vm.infoList.TOP2_node_string
                    )}}},[_vm._v(" "+_vm._s('TOP2' in _vm.infoList?_vm.infoList.TOP2 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风媒比SOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.SOP2,
                      '风媒比SOP',
                      'SOP2',
                      _vm.infoList.SOP2_node_string
                    )}}},[_vm._v(" "+_vm._s('SOP2' in _vm.infoList?_vm.infoList.SOP2 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("EOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.EOP2,
                      'EOP',
                      'EOP',
                      _vm.infoList.EOP2_node_string
                    )}}},[_vm._v(" "+_vm._s('EOP2' in _vm.infoList?_vm.infoList.EOP2 :0)+"℃ ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("基本风煤比")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.JBFMB_B2,
                      '基本风媒比',
                      'JBFMB_B2',
                      _vm.infoList.JBFMB_B2_node_string
                    )}}},[_vm._v(" "+_vm._s('JBFMB_B2' in _vm.infoList?_vm.infoList.JBFMB_B2 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("实际风煤比")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'SJFMB_B2',
                      _vm.infoList.SJFMB_B2_node_string,
                      'SJFMB_B2_WF',
                      '2#实际风煤比'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.SJFMB_B2,
                      'SJFMB_B2',
                      _vm.infoList.SJFMB_B2_node_string,
                      'SJFMB_B2_WF'
                    )}}},[_vm._v(" "+_vm._s('SJFMB_B2' in _vm.infoList?_vm.infoList.SJFMB_B2 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比范围")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.FV_LL_B2,
                      '风煤比范围',
                      'FV_LL_B2',
                      _vm.infoList.FV_LL_B2_node_string
                    )}}},[_vm._v(" "+_vm._s('FV_LL_B2' in _vm.infoList?_vm.infoList.FV_LL_B2 :0)+"% ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("MAXEOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.MAXEOP_B2,
                      'MAXEOP',
                      'MAXEOP_B2',
                      _vm.infoList.MAXEOP_B2_node_string
                    )}}},[_vm._v(" "+_vm._s('MAXEOP_B2' in _vm.infoList?_vm.infoList.MAXEOP_B2 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("OLD")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'BCS_ML_B2',
                      _vm.infoList.BCS_ML_B2_node_string,
                      'BCS_ML_B2_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCS_ML_B2,
                      'BCS_ML_B2',
                      _vm.infoList.BCS_ML_B2_node_string,
                      'BCS_ML_B2_WF'
                    )}}},[_vm._v(" "+_vm._s('BCS_ML_B2' in _vm.infoList?_vm.infoList.BCS_ML_B2 :0)+" ")])])]),_c('div',{staticClass:"column-row column-row1 flex justify-content-cen"},[_c('div',{staticClass:"column-row-data column-row-frist"},[_c('div',{staticClass:"data-name"},[_vm._v("最高顶温")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'UHFV1_B2',
                      _vm.infoList.UHFV1_B2_node_string,
                      'UHFV1_B2_WF',
                      '2#煤气阀开度线性上限'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.UHFV1_B2,
                      'UHFV1_B2',
                      _vm.infoList.UHFV1_B2_node_string,
                      'UHFV1_B2_WF'
                    )}}},[_vm._v(" "+_vm._s('UHFV1_B2' in _vm.infoList?_vm.infoList.UHFV1_B2 :0)+"℃ ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("当前顶温")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'TE_12_B2',
                      _vm.infoList.TE_12_B2_node_string,
                      'TE_12_B2_WF',
                      '2#热风炉拱顶温度2'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.TE_12_B2,
                      'TE_12_B2',
                      _vm.infoList.TE_12_B2_node_string,
                      'TE_12_B2_WF'
                    )}}},[_vm._v(" "+_vm._s('TE_12_B2' in _vm.infoList?_vm.infoList.TE_12_B2 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("下降幅度")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.GDWD_XJ_B2,
                      '下降幅度',
                      'GDWD_XJ_B2',
                      _vm.infoList.GDWD_XJ_B2_node_string
                    )}}},[_vm._v(" "+_vm._s('GDWD_XJ_B2' in _vm.infoList?_vm.infoList.GDWD_XJ_B2 :0)+"℃ ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比增量")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'BCS_KH_B2',
                      _vm.infoList.BCS_KH_B2_node_string,
                      'BCS_KH_B2_WF',
                      '2#优化空气量累积'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCS_KH_B2,
                      'BCS_KH_B2',
                      _vm.infoList.BCS_KH_B2_node_string,
                      'BCS_KH_B2_WF'
                    )}}},[_vm._v(" "+_vm._s('BCS_KH_B2' in _vm.infoList?_vm.infoList.BCS_KH_B2 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("参考风煤比")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KM_MQ_B2',
                      _vm.infoList.KM_MQ_B2_node_string,
                      'KM_MQ_B2_WF',
                      '2#炉参考风煤比'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.KM_MQ_B2,
                      'KM_MQ_B2',
                      _vm.infoList.KM_MQ_B2_node_string,
                      'KM_MQ_B2_WF'
                    )}}},[_vm._v(" "+_vm._s('KM_MQ_B2' in _vm.infoList?_vm.infoList.KM_MQ_B2 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比上限")]),_c('div',{staticClass:"data-num"},[_vm._v(_vm._s('JBFMBH_B2' in _vm.infoList?_vm.infoList.JBFMBH_B2 :0))])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比下限")]),_c('div',{staticClass:"data-num"},[_vm._v(_vm._s('JBFMBL_B2' in _vm.infoList?_vm.infoList.JBFMBL_B2 :0))])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("NEW")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'BCS_MH_B2',
                      _vm.infoList.BCS_MH_B2_node_string,
                      'BCS_MH_B2_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCS_MH_B2,
                      'BCS_MH_B2',
                      _vm.infoList.BCS_MH_B2_node_string,
                      'BCS_MH_B2_WF'
                    )}}},[_vm._v(" "+_vm._s('BCS_MH_B2' in _vm.infoList?_vm.infoList.BCS_MH_B2 :0)+" ")])])])]),_c('div',{staticClass:"column-bot flex"},[_c('div',{staticClass:"column-bot-left"},[_vm._v("趋势优化")]),_c('div',{staticClass:"column-bot-right flex align-cen justify-content-cen"},[_c('div',{staticClass:"column-row-data column-row-frist"},[_c('div',{staticClass:"data-name"},[_vm._v("采样时间")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.AOPH_B2,
                      '采样时间',
                      'AOPH_B2',
                      _vm.infoList.AOPH_B2_node_string
                    )}}},[_vm._v(" "+_vm._s('AOPH_B2' in _vm.infoList?_vm.infoList.AOPH_B2 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("专家时间")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.AOPL_B2,
                      '专家时间',
                      'AOPL_B2',
                      _vm.infoList.AOPL_B2_node_string
                    )}}},[_vm._v(" "+_vm._s('AOPL_B2' in _vm.infoList?_vm.infoList.AOPL_B2 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("动态系数")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.KM_B2,
                      '动态系数',
                      'KM_B2',
                      _vm.infoList.KM_B2_node_string
                    )}}},[_vm._v(" "+_vm._s('KM_B2' in _vm.infoList?_vm.infoList.KM_B2 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("启动值")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.GDWDL_B2,
                      '启动值',
                      'GDWDL_B2',
                      _vm.infoList.GDWDL_B2_node_string
                    )}}},[_vm._v(" "+_vm._s('GDWDL_B2' in _vm.infoList?_vm.infoList.GDWDL_B2 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("持续时间")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.JBMG01_B2,
                      '持续时间',
                      'JBMG01_B2',
                      _vm.infoList.JBMG01_B2_node_string
                    )}}},[_vm._v(" "+_vm._s('JBMG01_B2' in _vm.infoList?_vm.infoList.JBMG01_B2 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("观测输出")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'FT_11N_B2',
                      _vm.infoList.FT_11N_B2_node_string,
                      'FT_11N_B2_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.FT_11N_B2,
                      'FT_11N_B2',
                      _vm.infoList.FT_11N_B2_node_string,
                      'FT_11N_B2_WF'
                    )}}},[_vm._v(" "+_vm._s('FT_11N_B2' in _vm.infoList?_vm.infoList.FT_11N_B2 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("优化输出")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'FT_12N_B2',
                      _vm.infoList.FT_12N_B2_node_string,
                      'FT_12N_B2_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.FT_12N_B2,
                      'FT_12N_B2',
                      _vm.infoList.FT_12N_B2_node_string,
                      'FT_12N_B2_WF'
                    )}}},[_vm._v(" "+_vm._s('FT_12N_B2' in _vm.infoList?_vm.infoList.FT_12N_B2 :0)+" ")])])])])])]),_c('div',{staticClass:"ParameterYh-box-flex-con3 publicborder"},[_c('div',{staticClass:"con3-column"},[_c('div',{staticClass:"con3-column-flex1"},[_c('div',{staticClass:"con3-column-flex1-text"},[_vm._v("优化方向及进程")]),_c('div',{staticClass:"con3-column-flex1-progress flex align-cen justify-content-cen"},[_c('div',[_vm._v("0")]),_c('div',{staticClass:"pro"},[(_vm.infoList.UHFV2_B2 >= 0 ? false : true)?_c('el-progress',{attrs:{"show-text":false,"stroke-width":10,"percentage":_vm.infoList.UHFV2_B2,"color":_vm.customColor}}):_vm._e()],1),_c('div',[_vm._v("100")])])]),_c('div',{staticClass:"con3-column-flex2 flex align-cen justify-content-cen"},[_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("强制优化")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.RESET2 ? '#29DFAE' : '#00aeff10',
                }),on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RESET2,
                    '强制优化',
                    'RESET2',
                    _vm.infoList.RESET2_node_string
                  )}}})]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("优化停止")]),_c('div',{staticClass:"row1-status",style:({ background: _vm.infoList.STOP2 ? 'red' : '#00aeff10' }),on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.STOP2,
                    '优化停止',
                    'STOP2',
                    _vm.infoList.STOP2_node_string
                  )}}})])])])])]),_c('div',{staticClass:"ParameterYh-box-flex flex align-cen"},[_c('div',{staticClass:"ParameterYh-box-flex-left publicborder"},[_vm._v("3#")]),_c('div',{staticClass:"ParameterYh-box-flex-con1 publicborder"},[_c('div',{staticClass:"con1-column"},[_c('div',{staticClass:"column1 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1",staticStyle:{"width":"7vw"}},[_c('div',{staticClass:"row1-text"},[_vm._v("优化时机")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ3 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1 column1-row2",staticStyle:{"width":"4vw"}},[_c('div',{staticClass:"row1-text"},[_vm._v("自动标志")]),_c('div',{staticClass:"row1-status",style:({
                  background:
                    _vm.infoList.KQZD1_B3 || _vm.infoList.MQZD1_B3
                      ? '#29DFAE'
                      : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1 column1-row2",staticStyle:{"width":"4vw"}},[_c('div',{staticClass:"row1-text"},[_vm._v("烧炉时间")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ5_B3 ? '#29DFAE' : '#00aeff10',
                })})])]),_c('div',{staticClass:"column1 column2 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1 column1-row1-frist"},[_c('div',{staticClass:"row1-text"},[_vm._v("拱顶温度")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ1_B3 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("废气温度")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ2_B3 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("单烧启停")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ4_B3 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("配比波动")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ3_B3 ? '#29DFAE' : '#00aeff10',
                })})])]),_c('div',{staticClass:"column1 column2 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1 column1-row1-frist"},[_c('div',{staticClass:"row1-text"},[_vm._v("拱顶高限")]),_c('div',{staticClass:"row1-text1"},[_vm._v(_vm._s('GDWDH_B3' in _vm.infoList?_vm.infoList.GDWDH_B3 :0)+"℃")])]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("拱顶时间")]),_c('div',{staticClass:"row1-text1 ipt",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YST2_B3,
                    '拱顶时间',
                    'YST2_B3',
                    _vm.infoList.YST2_B3_node_string
                  )}}},[_vm._v(" "+_vm._s('YST2_B3' in _vm.infoList?_vm.infoList.YST2_B3 :0)+"S ")])]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("废气高限")]),_c('div',{staticClass:"row1-text1"},[_vm._v(_vm._s('FQBHZ_B3' in _vm.infoList?_vm.infoList.FQBHZ_B3 :0)+"℃")])]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("废气时间")]),_c('div',{staticClass:"row1-text1 ipt",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YST3_B3,
                    '废气时间',
                    'YST3_B3',
                    _vm.infoList.YST3_B3_node_string
                  )}}},[_vm._v(" "+_vm._s('YST3_B3' in _vm.infoList?_vm.infoList.YST3_B3 :0)+"S ")])])]),_c('div',{staticClass:"column1 column3 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1 column1-row1-frist"},[_c('div',{staticClass:"row1-text"},[_vm._v("顶温稳态精度")]),_c('div',{staticClass:"row1-text1"},[_vm._v(_vm._s('GDWD_XJ_B3' in _vm.infoList?_vm.infoList.GDWD_XJ_B3 :0)+"℃")])]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("烧炉时间")]),_c('div',{staticClass:"row1-text1 ipt",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YST1_B3,
                    '烧炉时间',
                    'YST1_B3',
                    _vm.infoList.YST1_B3_node_string
                  )}}},[_vm._v(" "+_vm._s('YST1_B3' in _vm.infoList?_vm.infoList.YST1_B3 :0)+" m ")])])])])]),_c('div',{staticClass:"ParameterYh-box-flex-con2 publicborder"},[_c('div',{staticClass:"con2-column"},[_c('div',{staticClass:"column-top"},[_c('div',{staticClass:"column-row flex justify-content-cen"},[_c('div',{staticClass:"column-row-data column-row-frist"},[_c('div',{staticClass:"data-name"},[_vm._v("TOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.TOP3,
                      'TOP',
                      'TOP3',
                      _vm.infoList.TOP3_node_string
                    )}}},[_vm._v(" "+_vm._s('TOP3' in _vm.infoList?_vm.infoList.TOP3 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风媒比SOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.SOP3,
                      '风媒比SOP',
                      'SOP3',
                      _vm.infoList.SOP3_node_string
                    )}}},[_vm._v(" "+_vm._s('SOP3' in _vm.infoList?_vm.infoList.SOP3 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("EOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.EOP3,
                      'EOP',
                      'EOP3',
                      _vm.infoList.EOP3_node_string
                    )}}},[_vm._v(" "+_vm._s('EOP3' in _vm.infoList?_vm.infoList.EOP3 :0)+"℃ ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("基本风煤比")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.JBFMB_B3,
                      '基本风媒比',
                      'JBFMB_B3',
                      _vm.infoList.JBFMB_B3_node_string
                    )}}},[_vm._v(" "+_vm._s('JBFMB_B3' in _vm.infoList?_vm.infoList.JBFMB_B3 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("实际风煤比")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'SJFMB_B3',
                      _vm.infoList.SJFMB_B3_node_string,
                      'SJFMB_B3_WF',
                      '3#实际风煤比'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.SJFMB_B3,
                      'SJFMB_B3',
                      _vm.infoList.SJFMB_B3_node_string,
                      'SJFMB_B3_WF'
                    )}}},[_vm._v(" "+_vm._s('SJFMB_B3' in _vm.infoList?_vm.infoList.SJFMB_B3 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比范围")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.FV_LL_B3,
                      '风煤比范围',
                      'FV_LL_B3',
                      _vm.infoList.FV_LL_B3_node_string
                    )}}},[_vm._v(" "+_vm._s('FV_LL_B3' in _vm.infoList?_vm.infoList.FV_LL_B3 :0)+"% ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("MAXEOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.MAXEOP_B3,
                      'MAXEOP',
                      'MAXEOP_B3',
                      _vm.infoList.MAXEOP_B3_node_string
                    )}}},[_vm._v(" "+_vm._s('MAXEOP_B3' in _vm.infoList?_vm.infoList.MAXEOP_B3 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("OLD")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'BCS_ML_B3',
                      _vm.infoList.BCS_ML_B3_node_string,
                      'BCS_ML_B3_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCS_ML_B3,
                      'BCS_ML_B3',
                      _vm.infoList.BCS_ML_B3_node_string,
                      'BCS_ML_B3_WF'
                    )}}},[_vm._v(" "+_vm._s('BCS_ML_B3' in _vm.infoList?_vm.infoList.BCS_ML_B3 :0)+" ")])])]),_c('div',{staticClass:"column-row column-row1 flex justify-content-cen"},[_c('div',{staticClass:"column-row-data column-row-frist"},[_c('div',{staticClass:"data-name"},[_vm._v("最高顶温")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'UHFV1_B3',
                      _vm.infoList.UHFV1_B3_node_string,
                      'UHFV1_B3_WF',
                      '3#煤气阀开度线性上限'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.UHFV1_B3,
                      'UHFV1_B3',
                      _vm.infoList.UHFV1_B3_node_string,
                      'UHFV1_B3_WF'
                    )}}},[_vm._v(" "+_vm._s('UHFV1_B3' in _vm.infoList?_vm.infoList.UHFV1_B3 :0)+"℃ ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("当前顶温")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'TE_12_B3',
                      _vm.infoList.TE_12_B3_node_string,
                      'TE_12_B3_WF',
                      '3#热风炉拱顶温度2'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.TE_12_B3,
                      'TE_12_B3',
                      _vm.infoList.TE_12_B3_node_string,
                      'TE_12_B3_WF'
                    )}}},[_vm._v(" "+_vm._s('TE_12_B3' in _vm.infoList?_vm.infoList.TE_12_B3 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("下降幅度")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.GDWD_XJ_B3,
                      '下降幅度',
                      'GDWD_XJ_B3',
                      _vm.infoList.GDWD_XJ_B3_node_string
                    )}}},[_vm._v(" "+_vm._s('GDWD_XJ_B3' in _vm.infoList?_vm.infoList.GDWD_XJ_B3 :0)+"℃ ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比增量")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'BCS_KH_B3',
                      _vm.infoList.BCS_KH_B3_node_string,
                      'BCS_KH_B3_WF',
                      '3#优化空气量累积'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCS_KH_B3,
                      'BCS_KH_B3',
                      _vm.infoList.BCS_KH_B3_node_string,
                      'BCS_KH_B3_WF'
                    )}}},[_vm._v(" "+_vm._s('BCS_KH_B3' in _vm.infoList?_vm.infoList.BCS_KH_B3 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("参考风煤比")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.KM_MQ_B3,
                      '参考风煤比',
                      'KM_MQ_B3',
                      _vm.infoList.KM_MQ_B3_node_string
                    )}}},[_vm._v(" "+_vm._s('KM_MQ_B3' in _vm.infoList?_vm.infoList.KM_MQ_B3 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比上限")]),_c('div',{staticClass:"data-num"},[_vm._v(_vm._s('JBFMBH_B3' in _vm.infoList?_vm.infoList.JBFMBH_B3 :0))])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比下限")]),_c('div',{staticClass:"data-num"},[_vm._v(_vm._s('JBFMBL_B3' in _vm.infoList?_vm.infoList.JBFMBL_B3 :0))])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("NEW")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'BCS_MH_B3',
                      _vm.infoList.BCS_MH_B3_node_string,
                      'BCS_MH_B3_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCS_MH_B3,
                      'BCS_MH_B3',
                      _vm.infoList.BCS_MH_B3_node_string,
                      'BCS_MH_B3_WF'
                    )}}},[_vm._v(" "+_vm._s('BCS_MH_B3' in _vm.infoList?_vm.infoList.BCS_MH_B3 :0)+" ")])])])]),_c('div',{staticClass:"column-bot flex"},[_c('div',{staticClass:"column-bot-left"},[_vm._v("趋势优化")]),_c('div',{staticClass:"column-bot-right flex align-cen justify-content-cen"},[_c('div',{staticClass:"column-row-data column-row-frist"},[_c('div',{staticClass:"data-name"},[_vm._v("采样时间")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.AOPH_B3,
                      '采样时间',
                      'AOPH_B3',
                      _vm.infoList.AOPH_B3_node_string
                    )}}},[_vm._v(" "+_vm._s('AOPH_B3' in _vm.infoList?_vm.infoList.AOPH_B3 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("专家时间")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.AOPL_B3,
                      '专家时间',
                      'AOPL_B3',
                      _vm.infoList.AOPL_B3_node_string
                    )}}},[_vm._v(" "+_vm._s('AOPL_B3' in _vm.infoList?_vm.infoList.AOPL_B3 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("动态系数")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.KM_B3,
                      '动态系数',
                      'KM_B3',
                      _vm.infoList.KM_B3_node_string
                    )}}},[_vm._v(" "+_vm._s('KM_B3' in _vm.infoList?_vm.infoList.KM_B3 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("启动值")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.GDWDL_B3,
                      '启动值',
                      'GDWDL_B3',
                      _vm.infoList.GDWDL_B3_node_string
                    )}}},[_vm._v(" "+_vm._s('GDWDL_B3' in _vm.infoList?_vm.infoList.GDWDL_B3 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("持续时间")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.JBMG01_B3,
                      '持续时间',
                      'JBMG01_B3',
                      _vm.infoList.JBMG01_B3_node_string
                    )}}},[_vm._v(" "+_vm._s('JBMG01_B3' in _vm.infoList?_vm.infoList.JBMG01_B3 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("观测输出")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'FT_11N_B3',
                      _vm.infoList.FT_11N_B3_node_string,
                      'FT_11N_B3_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.FT_11N_B3,
                      'FT_11N_B3',
                      _vm.infoList.FT_11N_B3_node_string,
                      'FT_11N_B3_WF'
                    )}}},[_vm._v(" "+_vm._s('FT_11N_B3' in _vm.infoList?_vm.infoList.FT_11N_B3 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("优化输出")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'FT_12N_B3',
                      _vm.infoList.FT_12N_B3_node_string,
                      'FT_12N_B3_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.FT_12N_B3,
                      'FT_12N_B3',
                      _vm.infoList.FT_12N_B3_node_string,
                      'FT_12N_B3_WF'
                    )}}},[_vm._v(" "+_vm._s('FT_12N_B3' in _vm.infoList?_vm.infoList.FT_12N_B3 :0)+" ")])])])])])]),_c('div',{staticClass:"ParameterYh-box-flex-con3 publicborder"},[_c('div',{staticClass:"con3-column"},[_c('div',{staticClass:"con3-column-flex1"},[_c('div',{staticClass:"con3-column-flex1-text"},[_vm._v("优化方向及进程")]),_c('div',{staticClass:"con3-column-flex1-progress flex align-cen justify-content-cen"},[_c('div',[_vm._v("0")]),_c('div',{staticClass:"pro"},[(_vm.infoList.UHFV2_B3 >= 0 ? false : true)?_c('el-progress',{attrs:{"show-text":false,"stroke-width":10,"percentage":_vm.infoList.UHFV2_B3,"color":_vm.customColor}}):_vm._e()],1),_c('div',[_vm._v("100")])])]),_c('div',{staticClass:"con3-column-flex2 flex align-cen justify-content-cen"},[_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("强制优化")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.RESET3 ? '#29DFAE' : '#00aeff10',
                }),on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RESET3,
                    '强制优化',
                    'RESET3',
                    _vm.infoList.RESET3_node_string
                  )}}})]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("优化停止")]),_c('div',{staticClass:"row1-status",style:({ background: _vm.infoList.STOP3 ? 'red' : '#00aeff10' }),on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.STOP3,
                    '强制优化',
                    'STOP3',
                    _vm.infoList.STOP3_node_string
                  )}}})])])])])]),_c('div',{staticClass:"ParameterYh-box-flex flex align-cen",staticStyle:{"border-bottom":"1px solid #00e5ff20"}},[_c('div',{staticClass:"ParameterYh-box-flex-left publicborder"},[_vm._v("4#")]),_c('div',{staticClass:"ParameterYh-box-flex-con1 publicborder"},[_c('div',{staticClass:"con1-column"},[_c('div',{staticClass:"column1 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1",staticStyle:{"width":"7vw"}},[_c('div',{staticClass:"row1-text"},[_vm._v("优化时机")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ4 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1 column1-row2",staticStyle:{"width":"4vw"}},[_c('div',{staticClass:"row1-text"},[_vm._v("自动标志")]),_c('div',{staticClass:"row1-status",style:({
                  background:
                    _vm.infoList.KQZD1_B4 || _vm.infoList.MQZD1_B4
                      ? '#29DFAE'
                      : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1 column1-row2",staticStyle:{"width":"4vw"}},[_c('div',{staticClass:"row1-text"},[_vm._v("烧炉时间")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ5_B4 ? '#29DFAE' : '#00aeff10',
                })})])]),_c('div',{staticClass:"column1 column2 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1 column1-row1-frist"},[_c('div',{staticClass:"row1-text"},[_vm._v("拱顶温度")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ1_B4 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("废气温度")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ2_B4 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("单烧启停")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ4_B4 ? '#29DFAE' : '#00aeff10',
                })})]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("配比波动")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.YHSJ3_B4 ? '#29DFAE' : '#00aeff10',
                })})])]),_c('div',{staticClass:"column1 column2 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1 column1-row1-frist"},[_c('div',{staticClass:"row1-text"},[_vm._v("拱顶高限")]),_c('div',{staticClass:"row1-text1"},[_vm._v(_vm._s('GDWDH_B4' in _vm.infoList?_vm.infoList.GDWDH_B4 :0)+"℃")])]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("拱顶时间")]),_c('div',{staticClass:"row1-text1 ipt",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YST2_B4,
                    '拱顶时间',
                    'YST2_B4',
                    _vm.infoList.YST2_B4_node_string
                  )}}},[_vm._v(" "+_vm._s('YST2_B4' in _vm.infoList?_vm.infoList.YST2_B4 :0)+"S ")])]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("废气高限")]),_c('div',{staticClass:"row1-text1"},[_vm._v(_vm._s('FQBHZ_B4' in _vm.infoList?_vm.infoList.FQBHZ_B4 :0)+"℃")])]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("废气时间")]),_c('div',{staticClass:"row1-text1 ipt",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YST3_B4,
                    '废气时间',
                    'YST3_B4',
                    _vm.infoList.YST3_B4_node_string
                  )}}},[_vm._v(" "+_vm._s('YST3_B4' in _vm.infoList?_vm.infoList.YST3_B4 :0)+"S ")])])]),_c('div',{staticClass:"column1 column3 flex justify-content-cen"},[_c('div',{staticClass:"column1-row1 column1-row1-frist"},[_c('div',{staticClass:"row1-text"},[_vm._v("顶温稳态精度")]),_c('div',{staticClass:"row1-text1"},[_vm._v(_vm._s('GDWD_XJ_B4' in _vm.infoList?_vm.infoList.GDWD_XJ_B4 :0)+"℃")])]),_c('div',{staticClass:"column1-row1"},[_c('div',{staticClass:"row1-text"},[_vm._v("烧炉时间")]),_c('div',{staticClass:"row1-text1 ipt",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.YST1_B4,
                    '烧炉时间',
                    'YST1_B4',
                    _vm.infoList.YST1_B4_node_string
                  )}}},[_vm._v(" "+_vm._s('YST1_B4' in _vm.infoList?_vm.infoList.YST1_B4 :0)+" m ")])])])])]),_c('div',{staticClass:"ParameterYh-box-flex-con2 publicborder"},[_c('div',{staticClass:"con2-column"},[_c('div',{staticClass:"column-top"},[_c('div',{staticClass:"column-row flex justify-content-cen"},[_c('div',{staticClass:"column-row-data column-row-frist"},[_c('div',{staticClass:"data-name"},[_vm._v("TOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.TOP4,
                      'TOP',
                      'TOP4',
                      _vm.infoList.TOP4_node_string
                    )}}},[_vm._v(" "+_vm._s('TOP4' in _vm.infoList?_vm.infoList.TOP4 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风媒比SOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.SOP4,
                      '风媒比SOP',
                      'SOP4',
                      _vm.infoList.SOP4_node_string
                    )}}},[_vm._v(" "+_vm._s('SOP4' in _vm.infoList?_vm.infoList.SOP4 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("EOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.EOP4,
                      'EOP',
                      'EOP4',
                      _vm.infoList.EOP4_node_string
                    )}}},[_vm._v(" "+_vm._s('EOP4' in _vm.infoList?_vm.infoList.EOP4 :0)+"℃ ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("基本风煤比")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.JBFMB_B4,
                      '基本风媒比',
                      'JBFMB_B4',
                      _vm.infoList.JBFMB_B4_node_string
                    )}}},[_vm._v(" "+_vm._s('JBFMB_B4' in _vm.infoList?_vm.infoList.JBFMB_B4 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("实际风煤比")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'SJFMB_B4',
                      _vm.infoList.SJFMB_B4_node_string,
                      'SJFMB_B4_WF',
                      '4#实际风煤比'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.SJFMB_B4,
                      'SJFMB_B4',
                      _vm.infoList.SJFMB_B4_node_string,
                      'SJFMB_B4_WF'
                    )}}},[_vm._v(" "+_vm._s('SJFMB_B4' in _vm.infoList?_vm.infoList.SJFMB_B4 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比范围")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.FV_LL_B4,
                      '风煤比范围',
                      'FV_LL_B4',
                      _vm.infoList.FV_LL_B4_node_string
                    )}}},[_vm._v(" "+_vm._s('FV_LL_B4' in _vm.infoList?_vm.infoList.FV_LL_B4 :0)+"% ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("MAXEOP")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.MAXEOP_B4,
                      'MAXEOP',
                      'MAXEOP_B4',
                      _vm.infoList.MAXEOP_B4_node_string
                    )}}},[_vm._v(" "+_vm._s('MAXEOP_B4' in _vm.infoList?_vm.infoList.MAXEOP_B4 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("OLD")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'BCS_ML_B4',
                      _vm.infoList.BCS_ML_B4_node_string,
                      'BCS_ML_B4_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCS_ML_B4,
                      'BCS_ML_B4',
                      _vm.infoList.BCS_ML_B4_node_string,
                      'BCS_ML_B4_WF'
                    )}}},[_vm._v(" "+_vm._s('BCS_ML_B4' in _vm.infoList?_vm.infoList.BCS_ML_B4 :0)+" ")])])]),_c('div',{staticClass:"column-row column-row1 flex justify-content-cen"},[_c('div',{staticClass:"column-row-data column-row-frist"},[_c('div',{staticClass:"data-name"},[_vm._v("最高顶温")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'UHFV1_B4',
                      _vm.infoList.UHFV1_B4_node_string,
                      'UHFV1_B4_WF',
                      '4#煤气阀开度线性上限'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.UHFV1_B4,
                      'UHFV1_B4',
                      _vm.infoList.UHFV1_B4_node_string,
                      'UHFV1_B4_WF'
                    )}}},[_vm._v(" "+_vm._s('UHFV1_B4' in _vm.infoList?_vm.infoList.UHFV1_B4 :0)+"℃ ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("当前顶温")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'TE_12_B4',
                      _vm.infoList.TE_12_B4_node_string,
                      'TE_12_B4_WF',
                      '4#热风炉拱顶温度2'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.TE_12_B4,
                      'TE_12_B4',
                      _vm.infoList.TE_12_B4_node_string,
                      'TE_12_B4_WF'
                    )}}},[_vm._v(" "+_vm._s('TE_12_B4' in _vm.infoList?_vm.infoList.TE_12_B4 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("下降幅度")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.GDWD_XJ_B4,
                      '下降幅度',
                      'GDWD_XJ_B4',
                      _vm.infoList.GDWD_XJ_B4_node_string
                    )}}},[_vm._v(" "+_vm._s('GDWD_XJ_B4' in _vm.infoList?_vm.infoList.GDWD_XJ_B4 :0)+"℃ ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比增量")]),_c('div',{staticClass:"data-num"},[_vm._v(_vm._s('BCS_KH_B4' in _vm.infoList?_vm.infoList.BCS_KH_B4 :0))])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("参考风煤比")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.KM_MQ_B4,
                      '参考风煤比',
                      'KM_MQ_B4',
                      _vm.infoList.KM_MQ_B4_node_string
                    )}}},[_vm._v(" "+_vm._s('KM_MQ_B4' in _vm.infoList?_vm.infoList.KM_MQ_B4 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比上限")]),_c('div',{staticClass:"data-num"},[_vm._v(_vm._s('JBFMBH_B4' in _vm.infoList?_vm.infoList.JBFMBH_B4 :0))])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("风煤比下限")]),_c('div',{staticClass:"data-num"},[_vm._v(_vm._s('JBFMBL_B4' in _vm.infoList?_vm.infoList.JBFMBL_B4 :0))])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("NEW")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'BCS_MH_B4',
                      _vm.infoList.BCS_MH_B4_node_string,
                      'BCS_MH_B4_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.BCS_MH_B4,
                      'BCS_MH_B4',
                      _vm.infoList.BCS_MH_B4_node_string,
                      'BCS_MH_B4_WF'
                    )}}},[_vm._v(" "+_vm._s('BCS_MH_B4' in _vm.infoList?_vm.infoList.BCS_MH_B4 :0)+" ")])])])]),_c('div',{staticClass:"column-bot flex"},[_c('div',{staticClass:"column-bot-left"},[_vm._v("趋势优化")]),_c('div',{staticClass:"column-bot-right flex align-cen justify-content-cen"},[_c('div',{staticClass:"column-row-data column-row-frist"},[_c('div',{staticClass:"data-name"},[_vm._v("采样时间")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.AOPH_B4,
                      '采样时间',
                      'AOPH_B4',
                      _vm.infoList.AOPH_B4_node_string
                    )}}},[_vm._v(" "+_vm._s('AOPH_B4' in _vm.infoList?_vm.infoList.AOPH_B4 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("专家时间")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.AOPL_B4,
                      '专家时间',
                      'AOPL_B4',
                      _vm.infoList.AOPL_B4_node_string
                    )}}},[_vm._v(" "+_vm._s('AOPL_B4' in _vm.infoList?_vm.infoList.AOPL_B4 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("动态系数")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.KM_B4,
                      '动态系数',
                      'KM_B4',
                      _vm.infoList.KM_B4_node_string
                    )}}},[_vm._v(" "+_vm._s('KM_B4' in _vm.infoList?_vm.infoList.KM_B4 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("启动值")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.GDWDL_B4,
                      '启动值',
                      'GDWDL_B4',
                      _vm.infoList.GDWDL_B4_node_string
                    )}}},[_vm._v(" "+_vm._s('GDWDL_B4' in _vm.infoList?_vm.infoList.GDWDL_B4 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("持续时间")]),_c('div',{staticClass:"data-num ipt",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.JBMG01_B4,
                      '持续时间',
                      'JBMG01_B4',
                      _vm.infoList.JBMG01_B4_node_string
                    )}}},[_vm._v(" "+_vm._s('JBMG01_B4' in _vm.infoList?_vm.infoList.JBMG01_B4 :0)+"S ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("观测输出")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'FT_11N_B4',
                      _vm.infoList.FT_11N_B4_node_string,
                      'FT_11N_B4_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.FT_11N_B4,
                      'FT_11N_B4',
                      _vm.infoList.FT_11N_B4_node_string,
                      'FT_11N_B4_WF'
                    )}}},[_vm._v(" "+_vm._s('FT_11N_B4' in _vm.infoList?_vm.infoList.FT_11N_B4 :0)+" ")])]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("优化输出")]),_c('div',{staticClass:"data-num",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'FT_12N_B4',
                      _vm.infoList.FT_12N_B4_node_string,
                      'FT_12N_B4_WF'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.FT_12N_B4,
                      'FT_12N_B4',
                      _vm.infoList.FT_12N_B4_node_string,
                      'FT_12N_B4_WF'
                    )}}},[_vm._v(" "+_vm._s('FT_12N_B4' in _vm.infoList?_vm.infoList.FT_12N_B4 :0)+" ")])])])])])]),_c('div',{staticClass:"ParameterYh-box-flex-con3 publicborder"},[_c('div',{staticClass:"con3-column"},[_c('div',{staticClass:"con3-column-flex1"},[_c('div',{staticClass:"con3-column-flex1-text"},[_vm._v("优化方向及进程")]),_c('div',{staticClass:"con3-column-flex1-progress flex align-cen justify-content-cen"},[_c('div',[_vm._v("0")]),_c('div',{staticClass:"pro"},[(_vm.infoList.UHFV2_B4 >= 0 ? false : true)?_c('el-progress',{attrs:{"show-text":false,"stroke-width":10,"percentage":_vm.infoList.UHFV2_B4,"color":_vm.customColor}}):_vm._e()],1),_c('div',[_vm._v("100")])])]),_c('div',{staticClass:"con3-column-flex2 flex align-cen justify-content-cen"},[_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("强制优化")]),_c('div',{staticClass:"row1-status",style:({
                  background: _vm.infoList.RESET4 ? '#29DFAE' : '#00aeff10',
                }),on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.RESET4,
                    '强制优化',
                    'RESET4',
                    _vm.infoList.RESET4_node_string
                  )}}})]),_c('div',{staticClass:"column-row-data"},[_c('div',{staticClass:"data-name"},[_vm._v("优化停止")]),_c('div',{staticClass:"row1-status",style:({ background: _vm.infoList.STOP4 ? 'red' : '#00aeff10' }),on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.STOP4,
                    '强制优化',
                    'STOP4',
                    _vm.infoList.STOP4_node_string
                  )}}})])])])])])]),_c('inputVal',{ref:"inputVal",on:{"getTreeData":function($event){return _vm.DataJson(arguments)}}}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }