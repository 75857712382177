<template>
	<div class="Security">
		<!-- 抬头标题 -->
		<div class="title-hang">
			<div class="fl-t">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<!-- <div class="zhuangshizb fl-t"></div> -->
			<div class="zhuangshizj fl-t">{{projectData.project}}_安全设置</div>
			<!-- <div class="zhuangshiyb fl-t"></div> -->
			<div class="fr-t">
				<div class="fl-t">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
				</div>
			</div>
		</div>
		<!-- 第一个表格 -->
		<div class="table1">
			<div class="flex">
				<div class="title">
					<div class="title_context">安<br/>全<br/>限<br/>幅</div>
				</div>
				<div>
					<!-- 右边上部分 -->
					<div class="table1_line1 flex">
						<!-- 第一列 -->
						<div class="table1_context">
							<div class="title1">过热器</div>
							<div class="title1_context">
								<div class="flex">
									<div class="column1 float1">主给水泵调节上限</div>
									<div class="column2 float1"
										@click="toIpt(infoList.MCSMAN__p__MAN5.OUTT, '主给水泵调节上限','OUTT','MCSMAN__p__MAN5')">
											{{infoList.MCSMAN__p__MAN5.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">主给水泵调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN5.OUTB, '主给水泵调节下限','OUTB','MCSMAN__p__MAN5')">
											{{infoList.MCSMAN__p__MAN5.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">副给水泵调节上限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN6.OUTT, '副给水泵调节上限','OUTT','MCSMAN__p__MAN6')">
											{{infoList.MCSMAN__p__MAN6.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">副给水泵调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN6.OUTB, '副给水泵调节下限','OUTB','MCSMAN__p__MAN6')">
											{{infoList.MCSMAN__p__MAN6.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#减温阀门上限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN7.OUTT, '1#减温阀门上限','OUTT','MCSMAN__p__MAN7')">
											{{infoList.MCSMAN__p__MAN7.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#减温阀门下限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN7.OUTB, '1#减温阀门下限','OUTB','MCSMAN__p__MAN7')">
											{{infoList.MCSMAN__p__MAN7.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#减温阀门上限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN8.OUTT, '2#减温阀门上限','OUTT','MCSMAN__p__MAN8')">
											{{infoList.MCSMAN__p__MAN8.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#减温阀门下限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN8.OUTB, '2#减温阀门下限','OUTB','MCSMAN__p__MAN8')">
											{{infoList.MCSMAN__p__MAN8.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#减温阀门上限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN9.OUTT, '3#减温阀门上限','OUTT','MCSMAN__p__MAN9')">
											{{infoList.MCSMAN__p__MAN9.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#减温阀门下限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN9.OUTB, '3#减温阀门下限','OUTB','MCSMAN__p__MAN9')">
											{{infoList.MCSMAN__p__MAN9.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#减温阀门上限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN10.OUTT, '4#减温阀门上限','OUTT','MCSMAN__p__MAN10')">
											{{infoList.MCSMAN__p__MAN10.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#减温阀门下限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN10.OUTB, '4#减温阀门下限','OUTB','MCSMAN__p__MAN10')">
											{{infoList.MCSMAN__p__MAN10.OUTB}}
									</div>
								</div>
							</div>
						</div>
						<!-- 第二列 -->
						<div class="table1_context">
							<div class="title1">给煤限幅</div>
							<div class="title1_context">
								<div class="flex">
									<div class="column1 float1">1#给煤调节上限</div>
									<div class="column2 float1"
										@click="toIpt(infoList.MCSMAN__p__MAN1.OUTT, '1#给煤调节上限','OUTT','MCSMAN__p__MAN1')">
											{{infoList.MCSMAN__p__MAN1.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#给煤调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN1.OUTB, '1#给煤调节下限','OUTB','MCSMAN__p__MAN1')">
											{{infoList.MCSMAN__p__MAN1.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#给煤调节上限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN2.OUTT, '2#给煤调节上限','OUTT','MCSMAN__p__MAN2')">
											{{infoList.MCSMAN__p__MAN2.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#给煤调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN2.OUTB, '2#给煤调节下限','OUTB','MCSMAN__p__MAN2')">
											{{infoList.MCSMAN__p__MAN2.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#给煤调节上限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN3.OUTT, '3#给煤调节上限','OUTT','MCSMAN__p__MAN3')">
											{{infoList.MCSMAN__p__MAN3.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#给煤调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN3.OUTB, '3#给煤调节下限','OUTB','MCSMAN__p__MAN3')">
											{{infoList.MCSMAN__p__MAN3.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#给煤调节上限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN4.OUTT, '4#给煤调节上限','OUTT','MCSMAN__p__MAN4')">
											{{infoList.MCSMAN__p__MAN4.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#给煤调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN4.OUTB, '4#给煤调节下限','OUTB','MCSMAN__p__MAN4')">
											{{infoList.MCSMAN__p__MAN4.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">5#给煤调节上限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN5.OUTT, '5#给煤调节上限','OUTT','MCSMAN__p__MAN5')">
											{{infoList.MCSMAN__p__MAN5.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">5#给煤调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN5.OUTB, '5#给煤调节下限','OUTB','MCSMAN__p__MAN5')">
											{{infoList.MCSMAN__p__MAN5.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">6#给煤调节上限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN6.OUTT, '6#给煤调节上限','OUTT','MCSMAN__p__MAN6')">
											{{infoList.MCSMAN__p__MAN6.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">6#给煤调节下限</div>
									<div class="column2"
										@click="toIpt(infoList.MCSMAN__p__MAN6.OUTB, '6#给煤调节下限','OUTB','MCSMAN__p__MAN6')">
											{{infoList.MCSMAN__p__MAN6.OUTB}}
									</div>
								</div>
							</div>
						</div>
						<!-- 第三列 -->
						<div class="table1_context">
							<div class="title1">引风限幅</div>
							<div class="title1_context">
								<div class="flex">
									<div class="column1 float1">引风机电流上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.MCSBY.BYMNL41,'引风机电流上限','BYMNL41','MCSBY')">
									    {{infoList.MCSBY.BYMNL41}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#引风变频上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN19.OUTT,'1#引风变频上限','OUTT','MCSMAN__p__MAN19')">
									    {{infoList.MCSMAN__p__MAN19.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#引风变频下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN19.OUTB,'1#引风变频下限','OUTB','MCSMAN__p__MAN19')">
									    {{infoList.MCSMAN__p__MAN19.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#引风变频上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN20.OUTT,'2#引风变频上限','OUTT','MCSMAN__p__MAN20')">
									    {{infoList.MCSMAN__p__MAN20.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#引风变频下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN20.OUTB,'2#引风变频下限','OUTB','MCSMAN__p__MAN20')">
									    {{infoList.MCSMAN__p__MAN20.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#引风挡板上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN21.OUTT,'1#引风挡板上限','OUTT','MCSMAN__p__MAN21')">
									    {{infoList.MCSMAN__p__MAN21.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#引风挡板下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN21.OUTB,'1#引风挡板下限','OUTB','MCSMAN__p__MAN21')">
									    {{infoList.MCSMAN__p__MAN21.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#引风挡板上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN22.OUTT,'2#引风挡板上限','OUTT','MCSMAN__p__MAN22')">
									    {{infoList.MCSMAN__p__MAN22.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#引风挡板下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN22.OUTB,'2#引风挡板下限','OUTB','MCSMAN__p__MAN22')">
									    {{infoList.MCSMAN__p__MAN22.OUTB}}
									</div>
								</div>
							</div>
						</div>
						<!-- 第四列 -->
						<div class="table1_context">
							<div class="title1">一次风限幅</div>
							<div class="title1_context">
								<div class="flex">
									<div class="column1 float1">一次风电流上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.MCSBY.BYMNL42,'一次风电流上限','BYMNL42','MCSBY')">
									    {{infoList.MCSBY.BYMNL42}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#一次风挡板上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN16.OUTT,'1#一次风挡板上限','OUTT','MCSMAN__p__MAN16')">
									    {{infoList.MCSMAN__p__MAN16.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#一次风挡板下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN16.OUTB,'1#一次风挡板下限','OUTB','MCSMAN__p__MAN16')">
									    {{infoList.MCSMAN__p__MAN16.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#一次风挡板上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN25.OUTT,'2#一次风挡板上限','OUTT','MCSMAN__p__MAN25')">
									    {{infoList.MCSMAN__p__MAN25.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#一次风挡板下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN25.OUTB,'2#一次风挡板下限','OUTB','MCSMAN__p__MAN25')">
									    {{infoList.MCSMAN__p__MAN25.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">一次风上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSFENG.YCFSPH,'一次风上限','YCFSPH','MCSFENG')">
									    {{infoList.MCSFENG.YCFSPH}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">一次风下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSFENG.YCFSPL,'一次风下限','YCFSPL','MCSFENG')">
									    {{infoList.MCSFENG.YCFSPL}}
									</div>
								</div>
							</div>
						</div>
						<!-- 第五列 -->
						<div class="table1_context">
							<div class="title1">二次风限幅</div>
							<div class="title1_context">
								<div class="flex">
									<div class="column1 float1">二次风电流上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.MCSBY.BYMNL43,'二次风电流上限','BYMNL43','MCSBY')">
									    {{infoList.MCSBY.BYMNL43}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#二次风变频上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN17.OUTT,'1#二次风变频上限','OUTT','MCSMAN__p__MAN17')">
									    {{infoList.MCSMAN__p__MAN17.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#二次风变频下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN17.OUTB,'1#二次风变频下限','OUTB','MCSMAN__p__MAN17')">
									    {{infoList.MCSMAN__p__MAN17.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#二次风变频上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN30.OUTT,'2#二次风变频上限','OUTT','MCSMAN__p__MAN30')">
									    {{infoList.MCSMAN__p__MAN30.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#二次风变频下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN30.OUTB,'2#二次风变频下限','OUTB','MCSMAN__p__MAN30')">
									    {{infoList.MCSMAN__p__MAN30.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#二次风挡板上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN18.OUTT,'1#二次风挡板上限','OUTT','MCSMAN__p__MAN18')">
									    {{infoList.MCSMAN__p__MAN18.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#二次风挡板下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN18.OUTB,'1#二次风挡板下限','OUTB','MCSMAN__p__MAN18')">
									    {{infoList.MCSMAN__p__MAN18.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#二次风挡板上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN26.OUTT,'2#二次风挡板上限','OUTT','MCSMAN__p__MAN26')">
									    {{infoList.MCSMAN__p__MAN26.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#二次风挡板下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN26.OUTB,'2#二次风挡板下限','OUTB','MCSMAN__p__MAN26')">
									    {{infoList.MCSMAN__p__MAN26.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">二次风上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSFENG.ECFSPH,'二次风上限','ECFSPH','MCSFENG')">
									    {{infoList.MCSFENG.ECFSPH}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">二次风下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSFENG.ECFSPL,'二次风下限','ECFSPL','MCSFENG')">
									    {{infoList.MCSFENG.ECFSPL}}
									</div>
								</div>
							</div>
						</div>
						<!-- 第六列 -->
						<div class="table1_context">
							<div class="title1">冷渣机限幅</div>
							<div class="title1_context">
								<div class="flex">
									<div class="column1 float1">1#冷渣机调节上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.MCSMAN__p__MAN13.OUTT,'1#冷渣机调节上限','OUTT','MCSMAN__p__MAN13')">
									    {{infoList.MCSMAN__p__MAN13.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#冷渣机调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN13.OUTB,'1#冷渣机调节下限','OUTB','MCSMAN__p__MAN13')">
									    {{infoList.MCSMAN__p__MAN13.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#冷渣机调节上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN14.OUTT,'1#冷渣机调节上限','OUTT','MCSMAN__p__MAN14')">
									    {{infoList.MCSMAN__p__MAN14.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#冷渣机调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN14.OUTB,'1#冷渣机调节下限','OUTB','MCSMAN__p__MAN14')">
									    {{infoList.MCSMAN__p__MAN14.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#冷渣机调节上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN28.OUTT,'3#冷渣机调节上限','OUTT','MCSMAN__p__MAN28')">
									    {{infoList.MCSMAN__p__MAN28.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#冷渣机调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN28.OUTB,'3#冷渣机调节下限','OUTB','MCSMAN__p__MAN28')">
									    {{infoList.MCSMAN__p__MAN28.OUTB}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#冷渣机调节上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN29.OUTT,'4#冷渣机调节上限','OUTT','MCSMAN__p__MAN29')">
									    {{infoList.MCSMAN__p__MAN29.OUTT}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#冷渣机调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN29.OUTB,'4#冷渣机调节下限','OUTB','MCSMAN__p__MAN29')">
									    {{infoList.MCSMAN__p__MAN29.OUTB}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 右边下部分 -->
					<div class="table1_line2 flex">
						<!-- 第一个表 -->
						<div class="form1 flex">
							<div>
								<div class="flex">
									<div class="column1 float1">负荷范围上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.MCSGEIMEI.FHSPHH,'负荷范围上限','FHSPHH','MCSGEIMEI')">
									    {{infoList.MCSGEIMEI.FHSPHH}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">负荷范围下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSGEIMEI.FHSPLL,'负荷范围上限','FHSPLL','MCSGEIMEI')">
									    {{infoList.MCSGEIMEI.FHSPLL}}
									</div>
								</div>
							</div>
							<div class="line2">
								<div class="flex">
									<div class="column1 float2">给煤量上线系数</div>
									<div class="column2 float2"
									@click="toIpt(infoList.MCSBY.BYMNL11,'给煤量上线系数','BYMNL11','MCSBY')">
									    {{infoList.MCSBY.BYMNL11}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">给煤量上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSGEIMEI.GMLH,'给煤量上限','GMLH','MCSGEIMEI')">
									    {{infoList.MCSGEIMEI.GMLH}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">给煤量下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSGEIMEI.GMLL,'给煤量下限','GMLL','MCSGEIMEI')">
									    {{infoList.MCSGEIMEI.GMLL}}
									</div>
								</div>
							</div>
						</div>
						<!-- 第二个表 -->
						<div class="form2 flex">
							<div class="form2_word">再热器</div>
							<div>
								<div class="flex">
									<div class="column1 float1">1#减温调节上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.MCSMAN__p__MAN53.OUTB,'1#减温调节上限','OUTB','MCSMAN__p__MAN53')">
									   {{infoList.MCSMAN__p__MAN53 ? infoList.MCSMAN__p__MAN53.OUTB : "0.00"}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">1#减温调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN53.OUTT,'1#减温调节下限','OUTT','MCSMAN__p__MAN53')">
									   {{infoList.MCSMAN__p__MAN53 ? infoList.MCSMAN__p__MAN53.OUTT : "0.00"}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#减温调节上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN54.OUTB,'2#减温调节上限','OUTB','MCSMAN__p__MAN54')">
									   {{infoList.MCSMAN__p__MAN54 ? infoList.MCSMAN__p__MAN54.OUTB : "0.00"}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">2#减温调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN54.OUTT,'2#减温调节上限','OUTT','MCSMAN__p__MAN54')">
									   {{infoList.MCSMAN__p__MAN54 ? infoList.MCSMAN__p__MAN54.OUTT : "0.00"}}
									</div>
								</div>
							</div>
							<div>
								<div class="flex">
									<div class="column1 float1">3#减温调节上限</div>
									<div class="column2 float1"
									@click="toIpt(infoList.MCSMAN__p__MAN55.OUTB,'3#减温调节上限','OUTB','MCSMAN__p__MAN55')">
									   {{infoList.MCSMAN__p__MAN55 ? infoList.MCSMAN__p__MAN55.OUTB : "0.00"}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">3#减温调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN55.OUTT,'2#减温调节上限','OUTT','MCSMAN__p__MAN55')">
									   {{infoList.MCSMAN__p__MAN55 ? infoList.MCSMAN__p__MAN55.OUTT : "0.00"}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#减温调节上限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN56.OUTB,'4#减温调节上限','OUTB','MCSMAN__p__MAN56')">
									   {{infoList.MCSMAN__p__MAN56 ? infoList.MCSMAN__p__MAN56.OUTB : "0.00"}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">4#减温调节下限</div>
									<div class="column2"
									@click="toIpt(infoList.MCSMAN__p__MAN56.OUTT,'2#减温调节上限','OUTT','MCSMAN__p__MAN56')">
									   {{infoList.MCSMAN__p__MAN56 ? infoList.MCSMAN__p__MAN56.OUTT : "0.00"}}
									</div>
								</div>
							</div>
						</div>
						<div>
							<!-- 第三个表 -->
							<div class="form3 flex">
								<div class="form3_word">脱硝限幅</div>
								<div>
									<div class="flex">
										<div class="column1 float1">稀释水调节上限</div>
										<div class="column2 float1"
											@click="toIpt(infoList.MCSO2NOX.CYSAOH,'稀释水调节上限','CYSAOH','MCSO2NOX')">
											    {{infoList.MCSO2NOX.CYSAOH}}
										</div>
									</div>
									<div class="flex">
										<div class="column1">稀释水调节下限</div>
										<div class="column2"
											@click="toIpt(infoList.MCSO2NOX.CYSAOL,'稀释水调节下限','CYSAOL','MCSO2NOX')">
											    {{infoList.MCSO2NOX.CYSAOL}}
										</div>
									</div>
								</div>
								<div>
									<div class="flex">
										<div class="column1 float1">氨水调节上限</div>
										<div class="column2 float1"
											@click="toIpt(infoList.MCSO2NOX.NSAOH,'氨水调节上限','NSAOH','MCSO2NOX')">
											    {{infoList.MCSO2NOX.NSAOH}}
										</div>
									</div>
									<div class="flex">
										<div class="column1">氨水调节下限</div>
										<div class="column2"
											@click="toIpt(infoList.MCSO2NOX.NSAOL,'氨水调节下限','NSAOL','MCSO2NOX')">
											    {{infoList.MCSO2NOX.NSAOL}}
										</div>
									</div>
								</div>
							</div>
							<!-- 第四个表 -->
							<div class="form3 flex">
								<div class="form3_word">脱硫限幅</div>
								<div>
									<div class="flex">
										<div class="column1 float1">炉外脱硫调节上限</div>
										<div class="column2 float1"
											@click="toIpt(infoList.MCSO2NOX.SO2AOH,'炉外脱硫调节上限','SO2AOH','MCSO2NOX')">
											    {{infoList.MCSO2NOX.SO2AOH}}
										</div>
									</div>
									<div class="flex">
										<div class="column1">炉外脱硫调节下限</div>
										<div class="column2"
											@click="toIpt(infoList.MCSO2NOX.SO2AOL,'炉外脱硫调节下限','SO2AOL','MCSO2NOX')">
											    {{infoList.MCSO2NOX.SO2AOL}}
										</div>
									</div>
								</div>
								<div>
									<div class="flex">
										<div class="column1 float1">炉内脱硫调节上限</div>
										<div class="column2 float1"
											@click="toIpt(infoList.MCSO2NOX.SO2AOH,'炉内脱硫调节上限','SO2AOH','MCSO2NOX')">
											    {{infoList.MCSO2NOX.SO2AOH}}
										</div>
									</div>
									<div class="flex">
										<div class="column1">炉内脱硫调节下限</div>
										<div class="column2"
											@click="toIpt(infoList.MCSO2NOX.SO2AOL,'炉内脱硫调节下限','SO2AOL','MCSO2NOX')">
											    {{infoList.MCSO2NOX.SO2AOL}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			
		</div>
		<!-- 中间文字 -->
		<div class="word">
			<div class="location flex">
				<div class="column1 float1">报警自控率阈值</div>
				<div class="column2 float1">0.0</div>
			</div>
		</div>
		<!-- 第二个表格 -->
		<div class="table2 flex">
			<div class="flex">
				<div class="title">
					<div class="title_context">报<br/>警<br/>设<br/>置</div>
				</div>
				<!-- 第一栏 -->
				<div class="table2_line1">
					<div class="table2_line1_context flex">
						<div class="button" @click="toIpt(infoList.MCSALARM.ALMEN02, '汽压上限','ALMEN02','MCSALARM')">
							<el-switch  v-model="infoList.MCSALARM.ALMEN02"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">汽压上限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSALARM.PT102H,'汽压上限','PT102H','MCSALARM')">
								{{infoList.MCSALARM.PT102H}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">汽压下限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSALARM.PT102L,'汽压下限','PT102L','MCSALARM')">
								{{infoList.MCSALARM.PT102L}}
						</div>
					</div>
					<div class="flex">
						<div class="button">
							<el-switch  v-model="infoList.MCSALARM.ALMEN11"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">优化站异常</div>
					</div>
				</div>
				<!-- 第二栏 -->
				<div class="table2_line1">
					<div class="table2_line1_context flex">
						<div class="button" @click="toIpt(infoList.MCSALARM.ALMEN05,'汽温上限','ALMEN05','MCSALARM')">
							<el-switch  v-model="infoList.MCSALARM.ALMEN05"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">汽温上限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSALARM.TE102DH,'汽温上限','TE102DH','MCSALARM')">
								{{infoList.MCSALARM.TE102DH}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">汽温下限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSALARM.TE102DL,'汽温下限','TE102DL','MCSALARM')">
								{{infoList.MCSALARM.TE102DL}}
						</div>
					</div>
					<div class="flex">
						<div class="button" @click="toIpt(infoList.MCSALARM.ALMEN14,'床温MAX上限','ALMEN14','MCSALARM')">
							<el-switch  v-model="infoList.MCSALARM.ALMEN14"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">床温MAX上限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSBY.BYMNL02,'床温MAX上限','BYMNL02','MCSBY')">
								{{infoList.MCSBY.BYMNL02}}
						</div>
					</div>
					<div class="flex">
						<div class="button" @click="toIpt(infoList.MCSALARM.ALMEN14,'冷渣器水温上限','ALMEN14','MCSALARM')">
							<el-switch  v-model="infoList.MCSALARM.ALMEN14"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">冷渣器水温上限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSALARM.LCCYL_H,'冷渣器水温上限','LCCYL_H','MCSALARM')">
								{{infoList.MCSALARM.LCCYL_H}}
						</div>
					</div>
				</div>
				<!-- 第三栏 -->
				<div class="table2_line1">
					<div class="table2_line1_context flex">
						<div class="button" @click="toIpt(infoList.MCSALARM.ALMEN17,'氧量上限','ALMEN17','MCSALARM')">
							<el-switch  v-model="infoList.MCSALARM.ALMEN17"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">氧量上限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSALARM.O2_ALMH,'氧量上限','O2_ALMH','MCSALARM')">
								{{infoList.MCSALARM.O2_ALMH}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">氧量下限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSALARM.O2_ALML,'氧量下限','O2_ALML','MCSALARM')">
								{{infoList.MCSALARM.O2_ALML}}
						</div>
					</div>
					<div class="flex">
						<div class="button" @click="toIpt(infoList.MCSALARM.ALMEN04,'床温均值上限','ALMEN04','MCSALARM')">
							<el-switch  v-model="infoList.MCSALARM.ALMEN04"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">床温均值上限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSALARM.LCWD_H,'床温均值上限','LCWD_H','MCSALARM')">
								{{infoList.MCSALARM.LCWD_H}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">床温均值下限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSALARM.LCWD_L,'床温均值下限','LCWD_L','MCSALARM')">
								{{infoList.MCSALARM.LCWD_L}}
						</div>
					</div>
				</div>
				<!-- 第四栏 -->
				<div class="table2_line1">
					<div class="table2_line1_context flex">
						<div class="button" @click="toIpt(infoList.MCSALARM.ALMEN03,'负压上限','ALMEN03','MCSALARM')">
							<el-switch  v-model="infoList.MCSALARM.ALMEN03"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">负压上限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSALARM.FY_H,'负压上限','FY_H','MCSALARM')">
								{{infoList.MCSALARM.FY_H}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">负压下限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSALARM.FY_L,'负压下限','FY_L','MCSALARM')">
								{{infoList.MCSALARM.FY_L}}
						</div>
					</div>
					<div class="flex">
						<div class="button" @click="toIpt(infoList.MCSALARM.ALMEN01,'水位上限','ALMEN01','MCSALARM')">
							<el-switch  v-model="infoList.MCSALARM.ALMEN01"
							            active-color="#29DFAE"
							            inactive-color="red"
										disabled
										>
							</el-switch>
						</div>
						<div class="column1">水位上限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSALARM.SW_H,'水位上限','SW_H','MCSALARM')">
								{{infoList.MCSALARM.SW_H}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">水位下限</div>
						<div class="column2"
							@click="toIpt(infoList.MCSALARM.SW_L,'水位下限','SW_L','MCSALARM')">
								{{infoList.MCSALARM.SW_L}}
						</div>
					</div>
					<div class="flex">
						<div class="button"></div>
						<div class="column1">10s变化幅度</div>
						<div class="column2"
						@click="toIpt(infoList.MCSALARM.SWBHFD,'10s变化幅度','SWBHFD','MCSALARM')">
						    {{infoList.MCSALARM.SWBHFD}}
						</div>
					</div>
				</div>
				<!-- 第五栏 -->
				<div class="table2_line2">
					<div class="first">
						<div class="flex">
							<div class="button" @click="toIpt(infoList.MCSALARM.ALMEN06,'断煤下限','ALMEN06','MCSALARM')">
								<el-switch  v-model="infoList.MCSALARM.ALMEN06"
								            active-color="#29DFAE"
								            inactive-color="red"
											disabled
											>
								</el-switch>
							</div>
							<div class="column1">断煤下限</div>
							<div class="column2"
								@click="toIpt(infoList.MCSGEIMEI.DMLL,'断煤下限','DMLL','MCSGEIMEI')">
									{{infoList.MCSGEIMEI.DMLL}}
							</div>
						</div>
						<div class="flex">
							<div class="button" @click="toIpt(infoList.MCSALARM.ALMEN09,'断煤判断-10s氧量变化幅度','ALMEN09','MCSALARM')">
								<el-switch  v-model="infoList.MCSALARM.ALMEN09"
								            active-color="#29DFAE"
								            inactive-color="red"
											disabled
											>
								</el-switch>
							</div>
							<div class="column1">断煤判断-10s氧量变化幅度</div>
							<div class="column2"
							@click="toIpt(infoList.MCSALARM.O2BHFD,'断煤判断-10s氧量变化幅度','O2BHFD','MCSALARM')">
						    	{{infoList.MCSALARM.O2BHFD}}
							</div>
						</div>
						<div class="flex">
							<div class="button"></div>
							<div class="column1">断煤判断-10s床温变化幅度</div>
							<div class="column2"
							@click="toIpt(infoList.MCSALARM.CWBHFD,'断煤判断-10s床温变化幅度','CWBHFD','MCSALARM')">
								{{infoList.MCSALARM.CWBHFD}}
							</div>
						</div>
					</div>
					<div class="second">
						<div class="explain_context">
							<div class="line1">注：1.报警必须“断煤处理”使能。</div>
							<div class="flex">
								<div class="blank"></div>
								<div class="line2">2.断煤时风保持当前状态。</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus = 'isClose' :historyname = 'historyname' :node='node' :Lkname='Lkname' :infoList = 'infoList'></Historical>
				 <Cfbcysz
      v-if="flag == 5 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Cfbcysz>
	 <Cfbserve
      v-if="flag == 7 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Cfbserve>
	</div>
</template>
	
<script>	
		import inputVal from "@/components/inputVal.vue"; //输入框组件
		import Historical from "@/components/Historical.vue"  //历史趋势
		import Cfbcysz from "@/views/CfbBoiler/CfbCommonSetting/index.vue"; //常用设置组件
	import Cfbserve from "@/views/CfbBoiler/CfbOnlineService/index.vue"; //客服组件
		export default {
		    name: "CfbParameter3",
		    props:{
	        infoList:{
	            type:Object,
	            default: () =>{
	              return {}// 默认值
	            } 
	        }
	    },
		 components:{inputVal, Historical,Cfbserve,Cfbcysz},
		    data: () => {
		  return {
			  flag:4,
		      Manualname: "",
		      Manualnode: "",
		      ManualAname: "",
		      Manualtitname: "",
		      isComShow:false,
		      isHshow:false,
		      isMshow:false,
		      isRshow:false,
		      historyname:'',
		      node:'',
		      Lkname:'',
		      isIndex:'',
		      Firstcontrolname:'',
		      Firstcontroltitname:'',
		      Firstcontrolnode:'',
		      isFshow:false,
		      Rsfname:'',
		      Rsfnode:'',
		      Rsftitname:'',
		      projectData:'',
		      grouptime:null,
		      spotArr:[],
		      authInfo:[],
		      UserInfo:[],
		  }
		        
		 },
		   watch: {
	    infoList: {
	      handler(n, o) {
	        this.infoList = n;
			console.log(this.infoList)
	      },
	      deep: true, // 深度监听父组件传过来对象变化
	    },
	  },
		  created(){
		    this.projectData = JSON.parse(localStorage.getItem("deviceType"))
		    this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
		    this.authInfo = JSON.parse(localStorage.getItem("autharr"))
		    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			 this.$bus.$on("sendMsg", (msg) => {
						// 兄弟组件发送来的消息
						this.flag = msg;
						});
		  },
		  methods:{
		      // 打开先控多窗口
		  toDetail(key,name,nodename,Aname,titname){
		   if(!this.authInfo[1]){
		    let str = this.authInfo[0]
		    // 判断是否为调试还是查看权限
		    if (str.charAt(str.length-1) == "r") {
		     this.$message.error('暂无调试权限');
		    }
		   }else{
		    let pathname=""
		    switch(key){
		     case 1:
		     pathname = 'firstcontol'
		     break
		     case 2:
		     pathname = 'Cfbkfirstcontol'
		     break
		     case 3:
		     pathname = 'CfbRfirstcontol'
		     break
		    }
		    const newurl = this.$router.resolve({name: pathname, query: {Firstcontrolname: name,Firstcontrolnode:nodename,Firstcontroltitname:titname}})
		    window.open(newurl.href,'_blank')
		   }
		  },
		         // 双击事件
		    Cclick(num,mark,node,name){
		      // 组装数据
		      clearTimeout(this.grouptime);
		      let spojobj = {
		        num:num,
		        mark:mark,
		        node:node,
		        name:name
		      }
		      this.spotArr.push(spojobj)
		      // 数组去重
		      let deWeightThree = () => {
		          let map = new Map();
		          for (let item of this.spotArr) {
		              if (!map.has(item.name)) {
		                 this.$message.closeAll()
		                  map.set(item.name, item);
		                 this.$message.success('已添加');
		              }else{
		                this.$message.closeAll()
		                this.$message.error('已添加到变量池里，切勿重复添加');
		              }
		          }
		       
		          return [...map.values()];
		      }
		      this.spotArr = deWeightThree();
		      localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
		    },
		    //    isshowfase(){
		    //    this.isComShow = false
		    //  },
		    closeCompon(){
		
		      this.$emit('sendStatus', 'Param041',false)
		    },
		    isClose(val,val1){
		      switch(val){
		        case 'Historical':
		          return this.isHshow = val1
				case 'CfbCommonSetting':
							case (this.flag == 5):	
							return  this.flag = 4,this.$bus.$emit("footersendMsg", 4)
							case 'Cfbserve':
							case (this.flag == 7):	
							return  this.flag = 4,this.$bus.$emit("footersendMsg", 4)
		      }
		    },
		    toIpt(data,name,historyname,node){
		
		      this.$refs.inputVal.open(data,name,historyname,node)
		    },
		    toCompon(key,name,name2,name3,titname){
		   this.isIndex = key
		   this.historyname = name
		   this.node = name2
		   this.Lkname = name3
		      clearTimeout(this.grouptime);
		      this.grouptime=setTimeout(() =>{
		          switch(key){
		      case 0:
		          return this.isMshow = true,this.Manualname = name,this.Manualnode = name2,this.ManualAname = name3,this.Manualtitname = titname
		        case 1 :
		          return this.isFshow = true,this.Firstcontrolname = name,this.Firstcontrolnode = name2,this.Firstcontroltitname = titname 
		        case 2:
		          return  this.isHshow = true
		        case 4:
		    return  this.isRshow = true,this.Rsfname = name,this.Rsfnode = name2,this.Rsftitname = titname
		   }
		      },300);    
		  },
		    } 
		}
</script>

<style lang="scss" scoped>
	.title-hang {
		height: 5vh;
		width: 96vw;
		font-family: MicrosoftYaHei;
		font-size: 2vh;
		font-weight: normal;
		font-stretch: normal;
		line-height: 2vh;
		letter-spacing: 0vh;
		.fl-t{
			float: left;
		}
		.fr-t {
			float: right;
		}
		.zhuangshizb {
			width: 14vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate06.png");
			background-size: 100% 100%;
			margin-left: 3.5vw;
			margin-top: 1.5vh;
		}
		.zhuangshizj {
			width: 68vw;
			height: 4vh;
			line-height:4vh;
			margin-top:1vh;
			text-align: center;
			// background-image: url("~@/assets/images/rfl_evaluate05.png");
			background-size: 100% 100%;
			// margin-left: 5vw;
			font-family: MicrosoftYaHei;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;
			color: #0ef7ff;	  
		}
		.zhuangshiyb {
			width: 14vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate04.png");
			background-size: 100% 100%;
			margin-left: 5vw;
			margin-top: 1.5vh;
		}
	}
	.table1{
		width: 95vw;
		height: 51vh;
		border:1px solid rgba(0,228,255,.2);
		box-sizing: border-box;
		font-family: PingFang-SC-Regular;
		font-weight: normal;
		font-stretch: normal;
		margin-top: 2.5vh;
		.title{
			width: 4vw;
			height: 51vh;
			border-right:1px solid rgba(0,228,255,.2);
			box-sizing: border-box;
			text-align: center;
			.title_context{
				margin-top: 19.8vh;
				font-size: 2.6vh;
				line-height: 3vh;
				letter-spacing: 0.5vh;
				color: #d5fffe;
			}
		}
		.table1_line1{
			width: 91vw;
			height: 36vh;
			border-bottom:1px solid rgba(0,228,255,.2);
			box-sizing: border-box;
			.table1_context{
				width: 16vw;
				height: 36vh;
				border-right:1px solid rgba(0,228,255,.2);
				box-sizing: border-box;
				.title1{
					width: 15.1vw;
					height: 3vh;
					border-bottom:1px solid rgba(0,228,255,.2);
					box-sizing: border-box;
					font-size: 1.9vh;
					line-height: 2.8vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					text-align: center;
				}
				.title1_context{
					width: 15.1vw;
					height: 33vh;
					.float1{
						margin-top: 0.7vh;
					}
					.column1{
						width: 10vw;
						font-size: 1vw;
						line-height: 2.6vh;
						letter-spacing: 0.1vh;
						text-align: right;
						color: #6acafb;
					}
					.column2{
						width: 4vw;
						font-size: 1vw;
						line-height: 2.6vh;
						letter-spacing: 0vh;
						color: #d9f8ff;
						text-align: center;
						cursor: pointer;
					}
				}
			}
		}
		.table1_line2{
			width: 91vw;
			height: 15vh;
			.form1{
				width: 30.5vw;
				height: 15vh;
				border-right:1px solid rgba(0,228,255,.2);
				box-sizing: border-box;
				.float1{
					margin-top: 6.6vh;
				}
				.line2{
					margin-left: 1vw;
				}
				.float2{
					margin-top: 4vh;
				}
				.column1{
					width: 10vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0.1vh;
					text-align: right;
					color: #6acafb;
				}
				.column2{
					width: 4vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #d9f8ff;
					text-align: center;
					cursor: pointer;
				}
			}
			.form2{
				width: 30.5vw;
				height: 15vh;
				border-right:1px solid rgba(0,228,255,.2);
				box-sizing: border-box;
				.form2_word{
					font-size: 1.9vh;
					line-height: 5.6vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					margin-top: 4vh;
					margin-left: 1vw;
				}
				.float1{
					margin-top: 2vh;
				}
				.column1{
					width: 9vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0.1vh;
					text-align: right;
					color: #6acafb;
				}
				.column2{
					width: 4vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #d9f8ff;
					text-align: center;
					cursor: pointer;
				}
			}
			.form3{
				width: 30.45vw;
				height: 7.45vh;
				border-right:1px solid rgba(0,228,255,.2);
				border-bottom:1px solid rgba(0,228,255,.2);
				box-sizing: border-box;
				.form3_word{
					font-size: 1.9vh;
					line-height: 5.6vh;
					letter-spacing: 0vh;
					color: #2fc3e3;
					margin-top: 1.5vh;
					margin-left: 1vw;
				}
				.float1{
					margin-top: 1vh;
				}
				.column1{
					width: 9vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0.1vh;
					text-align: right;
					color: #6acafb;
				}
				.column2{
					width: 3vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #d9f8ff;
					text-align: center;
					cursor: pointer;
				}
			}
		}
	}
	.word{
		margin-top: 4vh;
		.location{
			margin-left: 6vw;
			.column1{
				width: 9vw;
				font-size: 1vw;
				line-height: 2.6vh;
				letter-spacing: 0.1vh;
				text-align: right;
				color: #2fc3e3;
			}
			.column2{
				width: 3.5vw;
				font-size: 1vw;
				line-height: 2.6vh;
				letter-spacing: 0vh;
				color: #d9f8ff;
				text-align: center;
				cursor: pointer;
			}
		}
	}
	.table2{
		width: 95vw;
		height: 17vh;
		border:1px solid rgba(0,228,255,.2);
		box-sizing: border-box;
		margin-top: 0vh;
		.title{
			width: 4vw;
			height: 17vh;
			border-right:1px solid rgba(0,228,255,.2);
			box-sizing: border-box;
			text-align: center;
			.title_context{
				margin-top: 2.5vh;
				font-size: 2.6vh;
				line-height: 3vh;
				letter-spacing: 0.5vh;
				color: #d5fffe;
			}
		}
		.table2_line1{
			width: 15.1vw;
			height: 17vh;
			border-right:1px solid rgba(0,228,255,.2);
			box-sizing: border-box;
			.table2_line1_context{
				margin-top: 2vh;
			}
			.button{
				margin-left: 1vw;
				width: 3vw;
			}
			.column1{
				width: 7vw;
				font-size: 1vw;
				line-height: 2.6vh;
				letter-spacing: 0.1vh;
				text-align: right;
				color: #6acafb;
			}
			.column2{
				width: 3vw;
				font-size: 1vw;
				line-height: 2.6vh;
				letter-spacing: 0vh;
				color: #d9f8ff;
				text-align: center;
				margin-left: 0.3vw;
				cursor: pointer;
			}
		}
		.table2_line2{
			width: 30.45vw; 
			height: 17vh;
			// border-bottom:1px solid rgba(0,228,255,.2);
			// box-sizing: border-box;
			.first{
				border-bottom:1px solid rgba(0,228,255,.2);
				box-sizing: border-box;
				.button{
					margin-left: 3vw;
					width: 3vw;
				}
				.column1{
					width: 14vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0.1vh;
					text-align: right;
					color: #6acafb;
				}
				.column2{
					width: 6vw;
					font-size: 1vw;
					line-height: 2.6vh;
					letter-spacing: 0vh;
					color: #d9f8ff;
					text-align: center;
					cursor: pointer;
				}
			}
			.second{
				.explain_context{
					font-size: 1vw;
					line-height: 2.7vh;
					letter-spacing: 0.1vh;
					color: #6acafb;
					margin-top: 1.8vh;
					margin-left: 3.2vw;
					.blank{
						width: 1.8vw;
					}
				}
			}
		}
	}

  
 ::v-deep{
  .el-switch.is-disabled .el-switch__core{
                cursor: pointer !important;
            }
   .el-switch.is-disabled{
                           opacity: 1 !important;
       }
}
</style>
