<template>
	<div class="Security">
		<!-- 抬头标题 -->
		<div class="title-hang">
			<div class="fl-t">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<!-- <div class="zhuangshizb fl-t"></div> -->
			<div class="zhuangshizj fl-t">{{projectData.project}}_安全设置</div>
			<!-- <div class="zhuangshiyb fl-t"></div> -->
			<div class="fr-t">
				<div class="fl-t">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
				</div>
			</div>
		</div>
		<!-- 第一个表格 -->
		<div class="table first_table flex">
			<div class="table1 fl">
				<div class="first_main">安全<br />生产<br />阀门<br />设置</div>
			</div>
			<div class="table2 fl">
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">主给水阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SWAOH1,
                  '主给水阀门上限',
                  'SWAOH1',
                  infoList.SWAOH1_node_string
                )
              ">
							{{'SWAOH1' in infoList?  infoList.SWAOH1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">主给水阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SWAOL1,
                  '主给水阀门下限',
                  'SWAOL1',
                  infoList.SWAOL1_node_string
                )
              ">
							{{'SWAOL1' in infoList?  infoList.SWAOL1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#送风变频上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFBPAOH1,
                  '1#送风变频上限',
                  'SFBPAOH1',
                  infoList.SFBPAOH1_node_string
                )
              ">
							{{'SFBPAOH1' in infoList?  infoList.SFBPAOH1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#送风变频下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFBPAOL1,
                  '1#送风变频下限',
                  'SFBPAOL1',
                  infoList.SFBPAOL1_node_string
                )
              ">
							{{'SFBPAOL1' in infoList?  infoList.SFBPAOL1 :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">副给水阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SWAOH2,
                  '副给水阀门上限',
                  'SWAOH2',
                  infoList.SWAOH2_node_string
                )
              ">
							{{'SWAOH2' in infoList?  infoList.SWAOH2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">副给水阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SWAOL2,
                  '副给水阀门下限',
                  'SWAOL2',
                  infoList.SWAOL2_node_string
                )
              ">
							{{'SWAOL2' in infoList?  infoList.SWAOL2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#送风变频上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFBPAOH2,
                  '2#送风变频上限',
                  'SFBPAOH2',
                  infoList.SFBPAOH2_node_string
                )
              ">
							{{'SFBPAOH2' in infoList?  infoList.SFBPAOH2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#送风变频下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFBPAOL2,
                  '2#送风变频下限',
                  'SFBPAOL2',
                  infoList.SFBPAOL2_node_string
                )
              ">
							{{'SFBPAOL2' in infoList?  infoList.SFBPAOL2 :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">1#给水变频上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SSBPAOH1,
                  '1#给水变频上限',
                  'SSBPAOH1',
                  infoList.SSBPAOH1_node_string
                )
              ">
							{{'SSBPAOH1' in infoList?  infoList.SSBPAOH1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#给水变频下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SSBPAOL1,
                  '1#给水变频下限',
                  'SSBPAOL1',
                  infoList.SSBPAOL1_node_string
                )
              ">
							{{'SSBPAOL1' in infoList?  infoList.SSBPAOL1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#送风挡板上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFDBAOH1,
                  '1#送风挡板上限',
                  'SFDBAOH1',
                  infoList.SFDBAOH1_node_string
                )
              ">
							{{'SFDBAOH1' in infoList?  infoList.SFDBAOH1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#送风挡板下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFDBAOL1,
                  '1#送风挡板下限',
                  'SFDBAOL1',
                  infoList.SFDBAOL1_node_string
                )
              ">
							{{'SFDBAOL1' in infoList?  infoList.SFDBAOL1 :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">2#给水变频上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SSBPAOH2,
                  '2#给水变频上限',
                  'SSBPAOH2',
                  infoList.SSBPAOH2_node_string
                )
              ">
							{{'SSBPAOH2' in infoList?  infoList.SSBPAOH2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#给水变频下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SSBPAOL2,
                  '2#给水变频下限',
                  'SSBPAOL2',
                  infoList.SSBPAOL2_node_string
                )
              ">
							{{'SSBPAOL2' in infoList?  infoList.SSBPAOL2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#送风挡板上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFDBAOH2,
                  '2#送风挡板上限',
                  'SFDBAOH2',
                  infoList.SFDBAOH2_node_string
                )
              ">
							{{'SFDBAOH2' in infoList?  infoList.SFDBAOH2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#送风挡板下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFDBAOL2,
                  '2#送风挡板下限',
                  'SFDBAOL2',
                  infoList.SFDBAOL2_node_string
                )
              ">
							{{'SFDBAOL2' in infoList?  infoList.SFDBAOL2 :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">一甲减温阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.QWAOH1,
                  '一甲减温阀门上限',
                  'QWAOH1',
                  infoList.QWAOH1_node_string
                )
              ">
							{{'QWAOH1' in infoList?  infoList.QWAOH1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">一甲减温阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.QWAOL1,
                  '一甲减温阀门下限',
                  'QWAOL1',
                  infoList.QWAOL1_node_string
                )
              ">
							{{'QWAOL1' in infoList?  infoList.QWAOL1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#引风变频上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.YFBPAOH1,
                  '1#引风变频上限',
                  'YFBPAOH1',
                  infoList.YFBPAOH1_node_string
                )
              ">
							{{'YFBPAOH1' in infoList?  infoList.YFBPAOH1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#引风变频下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.YFBPAOL1,
                  '1#引风变频下限',
                  'YFBPAOL1',
                  infoList.YFBPAOL1_node_string
                )
              ">
							{{'YFBPAOL1' in infoList?  infoList.YFBPAOL1 :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">一乙减温阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.QWAOH2,
                  '一乙减温阀门上限',
                  'QWAOH2',
                  infoList.QWAOH2_node_string
                )
              ">
							{{'QWAOH2' in infoList?  infoList.QWAOH2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">一乙减温阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.QWAOL2,
                  '一乙减温阀门下限',
                  'QWAOL2',
                  infoList.QWAOL2_node_string
                )
              ">
							{{'QWAOL2' in infoList?  infoList.QWAOL2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#引风变频上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.YFBPAOH2,
                  '2#引风变频上限',
                  'YFBPAOH2',
                  infoList.YFBPAOH2_node_string
                )
              ">
							{{'YFBPAOH2' in infoList?  infoList.YFBPAOH2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#引风变频下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.YFBPAOL2,
                  '2#引风变频下限',
                  'YFBPAOL2',
                  infoList.YFBPAOL2_node_string
                )
              ">
							{{'YFBPAOL2' in infoList?  infoList.YFBPAOL2 :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">二甲减温阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.QWAOH3,
                  '二甲减温阀门上限',
                  'QWAOH3',
                  infoList.QWAOH3_node_string
                )
              ">
							{{'QWAOH3' in infoList?  infoList.QWAOH3 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">二甲减温阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.QWAOL3,
                  '二甲减温阀门下限',
                  'QWAOL3',
                  infoList.QWAOL3_node_string
                )
              ">
							{{'QWAOL3' in infoList?  infoList.QWAOL3 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#引风挡板上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.YFDBAOH1,
                  '1#引风挡板上限',
                  'YFDBAOH1',
                  infoList.YFDBAOH1_node_string
                )
              ">
							{{'YFDBAOH1' in infoList?  infoList.YFDBAOH1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#引风挡板下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.YFDBAOL1,
                  '1#引风挡板下限',
                  'YFDBAOL1',
                  infoList.YFDBAOL1_node_string
                )
              ">
							{{'YFDBAOL1' in infoList?  infoList.YFDBAOL1 :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">二乙减温阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.QWAOH4,
                  '二乙减温阀门上限',
                  'QWAOH4',
                  infoList.QWAOH4_node_string
                )
              ">
							{{'QWAOH4' in infoList?  infoList.QWAOH4 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">二乙减温阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.QWAOL4,
                  '二乙减温阀门下限',
                  'QWAOL4',
                  infoList.QWAOL4_node_string
                )
              ">
							{{'QWAOL4' in infoList?  infoList.QWAOL4 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#引风挡板上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.YFDBAOH2,
                  '2#引风挡板上限',
                  'YFDBAOH2',
                  infoList.YFDBAOH2_node_string
                )
              ">
							{{'YFDBAOH2' in infoList?  infoList.YFDBAOH2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#引风挡板下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.YFDBAOL2,
                  '2#引风挡板下限',
                  'YFDBAOL2',
                  infoList.YFDBAOL2_node_string
                )
              ">
							{{'YFDBAOL2' in infoList?  infoList.YFDBAOL2 :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">上层高煤上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.GLAOH3,
                  '上层高煤上限',
                  'GLAOH3',
                  infoList.GLAOH3_node_string
                )
              ">
							{{'GLAOH3' in infoList?  infoList.GLAOH3 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">上层高煤下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.GLAOL3,
                  '上层高煤下限',
                  'GLAOL3',
                  infoList.GLAOL3_node_string
                )
              ">
							{{'GLAOL3' in infoList?  infoList.GLAOL3 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">上层空气上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFCCAOH3,
                  '上层空气上限',
                  'SFCCAOH3',
                  infoList.SFCCAOH3_node_string
                )
              ">
							{{'SFCCAOH3' in infoList?  infoList.SFCCAOH3 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">上层空气下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFCCAOL3,
                  '上层空气下限',
                  'SFCCAOL3',
                  infoList.SFCCAOL3_node_string
                )
              ">
							{{'SFCCAOL3' in infoList?  infoList.SFCCAOL3 :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">中层高煤上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.GLAOH2,
                  '中层高煤上限',
                  'GLAOH2',
                  infoList.GLAOH2_node_string
                )
              ">
							{{'GLAOH2' in infoList?  infoList.GLAOH2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">中层高煤下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.GLAOL2,
                  '中层高煤下限',
                  'GLAOL2',
                  infoList.GLAOL2_node_string
                )
              ">
							{{'GLAOL2' in infoList?  infoList.GLAOL2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">中层空气上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFCCAOH2,
                  '中层空气上限',
                  'SFCCAOH2',
                  infoList.SFCCAOH2_node_string
                )
              ">
							{{'SFCCAOH2' in infoList?  infoList.SFCCAOH2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">中层空气下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFCCAOL2,
                  '中层空气下限',
                  'SFCCAOL2',
                  infoList.SFCCAOL2_node_string
                )
              ">
							{{'SFCCAOL2' in infoList?  infoList.SFCCAOL2 :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">下层高煤上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.GLAOH1,
                  '下层高煤上限',
                  'GLAOH1',
                  infoList.GLAOH1_node_string
                )
              ">
							{{'GLAOH1' in infoList?  infoList.GLAOH1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">下层高煤下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.GLAOL1,
                  '下层高煤下限',
                  'GLAOL1',
                  infoList.GLAOL1_node_string
                )
              ">
							{{'GLAOL1' in infoList?  infoList.GLAOL1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">下层空气上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFCCAOH1,
                  '下层空气上限',
                  'SFCCAOH1',
                  infoList.SFCCAOH1_node_string
                )
              ">
							{{'SFCCAOH1' in infoList?  infoList.SFCCAOH1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">下层空气下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SFCCAOL1,
                  '下层空气下限',
                  'SFCCAOL1',
                  infoList.SFCCAOL1_node_string
                )
              ">
							{{'SFCCAOL1' in infoList?  infoList.SFCCAOL1 :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">脱硝阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.NSAOH,
                  '脱硝阀门上限',
                  'NSAOH',
                  infoList.NSAOH_node_string
                )
              ">
							{{'NSAOH' in infoList?  infoList.NSAOH :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">脱硝阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.NSAOL,
                  '脱硝阀门下限',
                  'NSAOL',
                  infoList.NSAOL_node_string
                )
              ">
							{{'NSAOL' in infoList?  infoList.NSAOL :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">脱硝阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SO2AOH,
                  '脱硝阀门上限',
                  'SO2AOH',
                  infoList.SO2AOH_node_string
                )
              ">
							{{'SO2AOH' in infoList?  infoList.SO2AOH :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">脱硝阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.SO2AOL,
                  '脱硝阀门下限',
                  'SO2AOL',
                  infoList.SO2AOL_node_string
                )
              ">
							{{'SO2AOL' in infoList?  infoList.SO2AOL :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">除盐水阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.CYSAOH,
                  '除盐水阀门上限',
                  'CYSAOH',
                  infoList.CYSAOH_node_string
                )
              ">
							{{'CYSAOH' in infoList?  infoList.CYSAOH :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">除盐水阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.CYSAOL,
                  '除盐水阀门下限',
                  'CYSAOL',
                  infoList.CYSAOL_node_string
                )
              ">
							{{'CYSAOL' in infoList?  infoList.CYSAOL :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl"></div>
						<div class="first_line fl"></div>
					</div>
					<div class="flex">
						<div class="first_line1 fl"></div>
						<div class="first_line fl"></div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">1#送风机电流上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.ISFJ_H1,
                  '1#送风机电流上限',
                  'ISFJ_H1',
                  infoList.ISFJ_H1_node_string
                )
              ">
							{{'ISFJ_H1' in infoList?  infoList.ISFJ_H1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#送风机电流下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.ISFJ_H2,
                  '2#送风机电流下限',
                  'ISFJ_H2',
                  infoList.ISFJ_H2_node_string
                )
              ">
							{{'ISFJ_H2' in infoList?  infoList.ISFJ_H2 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">1#引风机电流下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.IYFJ_H1,
                  '1#引风机电流下限',
                  'IYFJ_H1',
                  infoList.IYFJ_H1_node_string
                )
              ">
							{{'IYFJ_H1' in infoList?  infoList.IYFJ_H1 :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">2#引风机电流上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.IYFJ_H2,
                  '2#引风机电流上限',
                  'IYFJ_H2',
                  infoList.IYFJ_H2_node_string
                )
              ">
							{{'IYFJ_H2' in infoList?  infoList.IYFJ_H2 :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="first_line1 fl">再热器阀门上限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.ZRAOH,
                  '再热器阀门上限',
                  'ZRAOH',
                  infoList.ZRAOH_node_string
                )
              ">
							{{'ZRAOH' in infoList?  infoList.ZRAOH :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl">再热器阀门下限</div>
						<div class="first_line fl" @click="
                toIpt(
                  infoList.ZRAOL,
                  '再热器阀门下限',
                  'ZRAOL',
                  infoList.ZRAOL_node_string
                )
              ">
							{{'ZRAOL' in infoList?  infoList.ZRAOL :0}}
						</div>
					</div>
					<div class="flex">
						<div class="first_line1 fl"></div>
						<div class="first_line fl"></div>
					</div>
					<div class="flex">
						<div class="first_line1 fl"></div>
						<div class="first_line fl"></div>
					</div>
				</div>
			</div>
		</div>
		<!-- 第二个表格 -->
		<div class="table second_table flex">
			<div class="table1 fl">
				<div class="second_main">控制点<br />限幅</div>
			</div>
			<div class="table2 fl">
				<div class="table_line flex">
					<div class="flex">
						<div class="second_line1 fl">水位控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.SWSP_H,
                  '水位控制点上限',
                  'SWSP_H',
                  infoList.SWSP_H_node_string
                )
              ">
							{{'SWSP_H' in infoList?  infoList.SWSP_H :0}}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">水位控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.SWSP_L,
                  '水位控制点下限',
                  'SWSP_L',
                  infoList.SWSP_L_node_string
                )
              ">
							{{'SWSP_L' in infoList?  infoList.SWSP_L :0}}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">汽温控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.QWSP_H,
                  '汽温控制点上限',
                  'QWSP_H',
                  infoList.QWSP_H_node_string
                )
              ">
							{{'QWSP_H' in infoList?  infoList.QWSP_H :0}}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">汽温控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.QWSP_L,
                  '汽温控制点下限',
                  'QWSP_L',
                  infoList.QWSP_L_node_string
                )
              ">
							{{'QWSP_L' in infoList?  infoList.QWSP_L :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="second_line1 fl">负压控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.FYSP_H,
                  '负压控制点上限',
                  'FYSP_H',
                  infoList.FYSP_H_node_string
                )
              ">
							{{'FYSP_H' in infoList?  infoList.FYSP_H :0}}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">负压控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.FYSP_L,
                  '负压控制点下限',
                  'FYSP_L',
                  infoList.FYSP_L_node_string
                )
              ">
							{{'FYSP_L' in infoList?  infoList.FYSP_L :0}}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">氧量控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.O2SPH,
                  '氧量控制点上限',
                  'O2SPH',
                  infoList.O2SPH_node_string
                )
              ">
							{{'O2SPH' in infoList?  infoList.O2SPH :0}}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">氧量控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.O2SPL,
                  '氧量控制点下限',
                  'O2SPL',
                  infoList.O2SPL_node_string
                )
              ">
							{{'O2SPL' in infoList?  infoList.O2SPL :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="second_line1 fl">汽压控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.QYPSPH,
                  '汽压控制点上限',
                  'QYPSPH',
                  infoList.QYPSPH_node_string
                )
              ">
							{{'' in infoList?  infoList.MCRANQI.QYPSPH :0}}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">汽压控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.QYPSPL,
                  '汽压控制点下限',
                  'QYPSPL',
                  infoList.QYPSPL_node_string
                )
              ">
							{{'QYPSPL' in infoList?  infoList.QYPSPL :0}}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">发电量控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.FDLSPH,
                  '发电量控制点上限',
                  'FDLSPH',
                  infoList.FDLSPH_node_string
                )
              ">
							{{'FDLSPH' in infoList?  infoList.FDLSPH :0}}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">发电量控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.FDLSPL,
                  '发电量控制点下限',
                  'FDLSPL',
                  infoList.FDLSPL_node_string
                )
              ">
							{{'FDLSPL' in infoList?  infoList.FDLSPL :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="second_line1 fl"></div>
						<div class="second_line fl"></div>
					</div>
					<div class="flex">
						<div class="second_line1 fl"></div>
						<div class="second_line fl"></div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">高煤压力控制点上限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.GLPSPH,
                  '高煤压力控制点上限',
                  'GLPSPH',
                  infoList.GLPSPH_node_string
                )
              ">
							{{'GLPSPH' in infoList?  infoList.GLPSPH :0}}
						</div>
					</div>
					<div class="flex">
						<div class="second_line1 fl">高煤压力控制点下限</div>
						<div class="second_line fl" @click="
                toIpt(
                  infoList.GLPSPL,
                  '高煤压力控制点下限',
                  'GLPSPL',
                  infoList.GLPSPL_node_string
                )
              ">
							{{'GLPSPL' in infoList?  infoList.GLPSPL :0}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 第三个表格 -->
		<div class="table third_table flex">
			<div class="table1 fl">
				<div class="third_main">安全<br />生产<br />报警<br />设置</div>
			</div>
			<div class="table2 fl">
				<div class="table_line flex">
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.SWYCEN,
                    '汽包液位上限',
                    'SWYCEN',
                    infoList.SWYCEN_node_string
                  )
                ">
								<el-switch v-model="infoList.SWYCEN" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">汽包液位上限</div>
						</div>
						<div class="third_line" @click="
                toIpt(
                  infoList.SWYC_H,
                  '汽包液位上限',
                  'SWYC_H',
                  infoList.SWYC_H_node_string
                )
              ">
							{{'SWYC_H' in infoList?  infoList.SWYC_H :0}}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1">汽包液位下限</div>
						<div class="third_line" @click="
                toIpt(
                  infoList.SWYC_L,
                  '汽包液位下限',
                  'SWYC_L',
                  infoList.SWYC_L_node_string
                )
              ">
							{{'SWYC_L' in infoList?  infoList.SWYC_L :0}}
						</div>
					</div>
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.QYYCEN,
                    '主汽压力上限',
                    'QYYCEN',
                    infoList.QYYCEN_node_string
                  )
                ">
								<el-switch v-model="infoList.QYYCEN" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">主汽压力上限</div>
						</div>
						<div class="third_line" @click="
                toIpt(
                  infoList.QYYC_H,
                  '主汽压力上限',
                  'QYYC_H',
                  infoList.QYYC_H_node_string
                )
              ">
							{{'QYYC_H' in infoList?  infoList.QYYC_H :0}}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1">主汽压力下限</div>
						<div class="third_line" @click="
                toIpt(
                  infoList.QYYC_L,
                  '主汽压力下限',
                  'QYYC_L',
                  infoList.QYYC_L_node_string
                )
              ">
							{{'QYYC_L' in infoList?  infoList.QYYC_L :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.QWYCEN,
                    '主汽温度上限',
                    'QWYCEN',
                    infoList.QWYCEN_node_string
                  )
                ">
								<el-switch v-model="infoList.QWYCEN" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">主汽温度上限</div>
						</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.QWYC_H,
                  '主汽温度上限',
                  'QWYC_H',
                  infoList.QWYC_H_node_string
                )
              ">
							{{'QWYC_H' in infoList?  infoList.QWYC_H :0}}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1 fl">主汽温度下限</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.QWYC_L,
                  '主汽温度下限',
                  'QWYC_L',
                  infoList.QWYC_L_node_string
                )
              ">
							{{'QWYC_L' in infoList?  infoList.QWYC_L :0}}
						</div>
					</div>
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.GLPYCEN,
                    '高煤压力上限',
                    'GLPYCEN',
                    infoList.GLPYCEN_node_string
                  )
                ">
								<el-switch v-model="infoList.GLPYCEN" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">高煤压力上限</div>
						</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.GLPYC_H,
                  '高煤压力上限',
                  'GLPYC_H',
                  infoList.GLPYC_H_node_string
                )
              ">
							{{'GLPYC_H' in infoList?  infoList.GLPYC_H :0}}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1 fl">高煤压力下限</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.GLPYC_L,
                  '高煤压力下限',
                  'GLPYC_L',
                  infoList.GLPYC_L_node_string
                )
              ">
							{{'GLPYC_L' in infoList?  infoList.GLPYC_L :0}}
						</div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.FYYCEN,
                    '炉膛负压上限',
                    'FYYCEN',
                    infoList.FYYCEN_node_string
                  )
                ">
								<el-switch v-model="infoList.FYYCEN" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">炉膛负压上限</div>
						</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.FYYC_H,
                  '炉膛负压上限',
                  'FYYC_H',
                  infoList.FYYC_H_node_string
                )
              ">
							{{'FYYC_H' in infoList?  infoList.FYYC_H :0}}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1 fl">炉膛负压下限</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.FYYC_L,
                  '炉膛负压下限',
                  'FYYC_L',
                  infoList.FYYC_L_node_string
                )
              ">
							{{'FYYC_L' in infoList?  infoList.FYYC_L :0}}
						</div>
					</div>
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.YHZEN1,
                    '优化站异常报警',
                    'YHZEN1',
                    infoList.YHZEN1_node_string
                  )
                ">
								<el-switch v-model="infoList.YHZEN1" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">优化站异常报警</div>
						</div>
						<div class="third_line fl"></div>
					</div>
					<div class="flex">
						<div class="third_line1 fl"></div>
						<div class="third_line fl"></div>
					</div>
				</div>
				<div class="table_line flex">
					<div class="flex">
						<div class="third_line2 flex">
							<div @click="
                  toIpt(
                    infoList.O2YCEN,
                    '烟气氧量上限',
                    'O2YCEN',
                    infoList.O2YCEN_node_string
                  )
                ">
								<el-switch v-model="infoList.O2YCEN" active-color="#29DFAE"
									inactive-color="red">
								</el-switch>
							</div>
							<div class="third_line3">烟气氧量上限</div>
						</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.O2YC_H,
                  '烟气氧量上限',
                  'O2YC_H',
                  infoList.O2YC_H_node_string
                )
              ">
							{{'O2YC_H' in infoList?  infoList.O2YC_H :0}}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1 fl">烟气氧量下限</div>
						<div class="third_line fl" @click="
                toIpt(
                  infoList.O2YC_L,
                  '烟气氧量下限',
                  'O2YC_L',
                  infoList.O2YC_L_node_string
                )
              ">
							{{'O2YC_L' in infoList?  infoList.O2YC_L :0}}
						</div>
					</div>
					<div class="flex">
						<div class="third_line1 fl"></div>
						<div class="third_line fl"></div>
					</div>
					<div class="flex">
						<div class="third_line1 fl"></div>
						<div class="third_line fl"></div>
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
		<Historical v-if="isHshow" @sendStatus="isHshow = false" :historyname="historyname" :node="node"
			:Lkname="Lkname" :infoList="infoList"></Historical>
		<RqCommonParameters v-if="flag == 4 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></RqCommonParameters>
		<RqSoftMeasurement v-if="flag == 6 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></RqSoftMeasurement>
		<RqOnlineService v-if="flag == 7 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></RqOnlineService>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historicaljrl.vue"; //历史趋势
	import RqCommonParameters from "@/views/RqBoiler/RqCommonParameters/index.vue"; //常用参数组件
	import RqSoftMeasurement from "@/views/RqBoiler/RqSoftNumber/index.vue"; //软测量数统
	import RqOnlineService from "@/views/RqBoiler/RqOnlineService/index.vue"; //客服
	export default {
		name: "Param11",
		props: {
			infoList: {
				type: Object,
				default: {}, // 默认值
			},
		},
		components: {
			inputVal,
			Historical,
			RqCommonParameters,
			RqSoftMeasurement,
			RqOnlineService,
		},
		data: () => {
			return {
				flag: 2,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: "",
				node: "",
				Lkname: "",
				isIndex: "",
				Firstcontrolname: "",
				Firstcontroltitname: "",
				Firstcontrolnode: "",
				isFshow: false,
				Rsfname: "",
				Rsfnode: "",
				Rsftitname: "",
				projectData: "",
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			};
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ?
				JSON.parse(localStorage.getItem("spotArr")) :
				[];
			this.authInfo = JSON.parse(localStorage.getItem("autharr"));
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
			this.$bus.$on("sendMsg", (msg) => {
				// 兄弟组件发送来的消息
				this.flag = msg;
			});
		},
		methods: {
			DataJson(data) {
				let strNode = data[0];
				let strMark = data[1];
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2];
				});
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0];
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error("暂无调试权限");
					}
				} else {
					let pathname = "";
					switch (key) {
						case 1:
							pathname = "firstcontol";
							break;
						case 2:
							pathname = "rqkfirstcontol";
							break;
						case 3:
							pathname = "RqRfirstcontol";
							break;
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname,
						},
					});
					window.open(newurl.href, "_blank");
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name,
				};
				this.spotArr.push(spojobj);
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll();
							map.set(item.name, item);
							this.$message.success("已添加");
						} else {
							this.$message.closeAll();
							this.$message.error("已添加到变量池里，切勿重复添加");
						}
					}

					return [...map.values()];
				};
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
			},
			isshowfase() {
				this.isComShow = false;
			},
			closeCompon() {
				this.$emit("sendStatus", "Param03", false);
			},
			isClose(val, val1) {
				switch (val) {
					case "Historical":
						return (this.isHshow = val1);
					case "RqOnlineService":
					case this.flag == 7:
						return (this.flag = 2), this.$bus.$emit("footersendMsg", 2);
					case "RqSoftNumber":
					case this.flag == 6:
						return (this.flag = 2), this.$bus.$emit("footersendMsg", 2);
					case "RqCommonParameters":
					case this.flag == 4:
						return (this.flag = 2), this.$bus.$emit("footersendMsg", 2);
				}
			},
			// 打开下置输入窗口
			toIpt(data, name, historyname, node, type, status) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0];
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error("暂无调试权限");
					}
				} else {
					this.$refs.inputVal.open(data, name, historyname, node, type, status);
				}
			},
			toCompon(key, name, name2, name3, titname) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							console.log(1);
							return (
								(this.isMshow = true),
								(this.Manualname = name),
								(this.Manualnode = name2),
								(this.ManualAname = name3),
								(this.Manualtitname = titname)
							);
						case 1:
							return (
								(this.isFshow = true),
								(this.Firstcontrolname = name),
								(this.Firstcontrolnode = name2),
								(this.Firstcontroltitname = titname)
							);
						case 2:
							return (this.isHshow = true);
						case 4:
							return (
								(this.isRshow = true),
								(this.Rsfname = name),
								(this.Rsfnode = name2),
								(this.Rsftitname = titname)
							);
					}
				}, 300);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.fl-t {
		float: left;
	}

	.fr-t {
		float: right;
	}

	.title-hang {
		height: 5vh;
		width: 96vw;
		font-family: MicrosoftYaHei;
		font-size: 1vw;
		font-weight: normal;
		font-stretch: normal;
		line-height: 2vh;
		letter-spacing: 0vh;
	}

	.zhuangshizb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate06.png");
		background-size: 100% 100%;
		margin-left: 3.5vw;
		margin-top: 1.5vh;
	}

	.zhuangshizj {
		width: 60vw;
		height: 4vh;
		line-height: 4vh;
		margin-top: 1vh;
		text-align: center;
		// background-image: url("~@/assets/images/rfl_evaluate05.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		font-family: MicrosoftYaHei;
		font-size: 1.6vw;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0vh;
		color: #0ef7ff;
	}

	.zhuangshiyb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate04.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		margin-top: 1.5vh;
	}

	.table {
		width: 95vw;
		margin-left: 1.5vw;
		border: 1px solid #00e4ff;
		box-sizing: border-box;
		margin-top: 1vh;
		font-family: PingFang-SC-Regular;
		font-size: 1vw;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0vh;
		color: #8aeaff;
	}

	.first_table {
		height: 45vh;
	}

	.first_table .table1 {
		width: 4vw;
		height: 45vh;
		border: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
	}

	.first_table .table1 .first_main {
		width: 4vw;
		height: 19vh;
		font-size: 1.3vw;
		line-height: 6vh;
		margin-top: 11vh;
		text-align: center;
	}

	.first_table .table2 {
		width: 91vw;
		height: 45vh;
		line-height: 3vh;
		border: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		text-align: center;
	}

	.first_table .table2 .table_line {
		width: 100%;
		height: 3vh;
		border-bottom: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;

		.flex {
			width: 22.75vw;
		}
	}

	.table .table2 .table_line .first_line1 {
		width: 19vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
	}

	.table .table2 .table_line .first_line {
		width: 3.75vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		cursor: pointer;
	}

	.first_line:last-child {
		border-right: none;
	}

	.second_table {
		height: 15vh;
	}

	.second_table .table1 {
		width: 4vw;
		border: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
	}

	.second_table .table1 .second_main {
		width: 4vw;
		font-size: 1.2vw;
		line-height: 3vh;
		margin-top: 4vh;
		text-align: center;
	}

	.second_table .table2 {
		width: 91vw;
		line-height: 3vh;
		border: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		text-align: center;
	}

	.second_table .table2 .table_line {
		width: 100%;
		height: 3.7vh;
		border-bottom: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;

		.flex {
			width: 22.75vw;
		}
	}

	.second_table .table2 .table_line .second_line1 {
		width: 19vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
	}

	.second_table .table2 .table_line .second_line {
		width: 3.75vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		cursor: pointer;
	}

	.second_line:last-child {
		border-right: none;
	}

	.third_table {
		height: 15vh;
	}

	.third_table .table1 {
		width: 4vw;
		border: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
	}

	.third_table .table1 .third_main {
		width: 4vw;
		font-size: 1.3vw;
		line-height: 3vh;
		margin-top: 1.3vh;
		text-align: center;
	}

	.third_table .table2 {
		width: 91vw;
		line-height: 3vh;
		border: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
	}

	.third_table .table2 .table_line {
		width: 100%;
		height: 3.7vh;
		border-bottom: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;

		.flex {
			width: 22.75vw;
		}

		position: relative;
	}

	.third_table .table2 .table_line .third_line1 {
		width: 19vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		text-align: center;
	}

	.third_table .table2 .table_line .third_line2 {
		width: 19vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		position: relative;
	}

	.third_table .table2 .table_line .third_line2 .third_line3 {
		width: 19vw;
		text-align: center;
		position: absolute;
	}

	.third_table .table2 .table_line .third_line {
		width: 3.75vw;
		border-right: 1px solid rgba(0, 228, 255, 0.2);
		box-sizing: border-box;
		text-align: center;
		cursor: pointer;
	}

	.third_line:last-child {
		border-right: none;
	}

	.el-switch {
		position: relative;
		margin-top: 0.8vh;
		margin-left: 1vw;
		z-index: 1 !important;
	}

	//  .el-switch.is-disabled{
	//     pacity: 1 !important;
	//  }
</style>
