import Config from "@/utils/config";
import { mapGetters } from "vuex";

import { getIndexInfo } from "@/api/index/index";
export default {
  name: "index",
  components: {},
  data: () => {
    return {
      timer: null,
      loadingAd: true,
      loadingFloor: true,
      isMshow: false,
      isFshow: false,
      isPshow: false,
      isPshow01: false,
      isPshow11: false,
      isPshow21: false,
      isPshow31: false,
      isPshow41: false,
      isIndex: "",

      info: {},
      isComShow: false,
      authInfo: [],
      UserInfo: [],
    };
  },
  watch: {},
  created() {
    this.deviceType = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {},
  methods: {
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "jrkfirstcontolJRL";
            break;
          case 3:
            pathname = "jrRfirstcontolJRL";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 打开组件
    toCompon(key, name, nodename, Aname, titname) {
      // console.log(key, name, nodename, Aname, titname)
      this.isIndex = key;
      this.Aname = Aname;
      (this.historyname = name), (this.nodename = nodename);
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        this.maskbok = true;
        switch (key) {
          case 0:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (
                (this.isMshow = true),
                (this.Manualname = name),
                (this.Manualnode = nodename),
                (this.ManualAname = Aname),
                (this.Manualtitname = titname)
              );
            }
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = nodename),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (
              (this.isHshow = true),
              (this.chName = titname),
              (this.maskbok = false)
            );
          case 3:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (this.JRLParam05 = true);
            }
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = nodename)
            );
          case 5:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (this.alllModel = true);
            }
          case 6:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (this.JRLParam01 = true);
            }
          case 7:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (this.JRLParam02 = true);
            }
          case 8:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (this.JRLParam03 = true);
            }
          case 9:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];
              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (this.JrparmsGroup = true), (this.canshu1 = name);
            }
          case 10:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];

              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
                this.maskbok = false; //把蒙版关闭
                return;
              }
            } else {
              return (this.JRLParam04 = true);
            }
        }
      }, 300);
    },
    isClose(val, val1) {
      this.maskbok = false;
      switch (val) {
        case "Manual":
          return (this.isMshow = val1);
        case "JrSecurity":
        case this.flag == 2:
          return (this.flag = 0), this.$bus.$emit("footersendMsg", 0);
        case "JrAssessment":
        case this.flag == 1:
          return (this.flag = 0), this.$bus.$emit("footersendMsg", 0);
        case "JRL_cysz":
        case this.flag == 4:
          return (this.flag = 0), this.$bus.$emit("footersendMsg", 0);
        case "Jrswitchpage":
        case this.flag == 3:
          return (this.flag = 0), this.$bus.$emit("footersendMsg", 0);
        case "Jrserve":
        case this.flag == 5:
          return (this.flag = 0), this.$bus.$emit("footersendMsg", 0);
        case "Firstcontrol":
          return (this.isFshow = val1);
        case "Historical":
          return (this.isHshow = val1);
        case "JRLParam05":
          return (this.JRLParam05 = val1);
        case "Rsf":
          return (this.isRshow = val1);
        case "JRL_pamrm01":
          return (this.JRLParam01 = val1);
        case "JrallModel":
          return (this.alllModel = val1);
        case "JRLParam02":
          return (this.JRLParam02 = val1);
        case "JRL_mqzg":
          return (this.JRLParam03 = val1);
        case "JrparmsGroup":
          return (this.JrparmsGroup = val1);
        case "JRL_myzg":
          return (this.JRLParam04 = val1);
      }
      // if(val = 'Manual'){
      // 	this.isMshow = val1
      // }
    },
    // 获取燃烧流程页面接口
    getInfo() {
      getIndexInfo({
        device: "test102",
        cp_type: "jrl",
      })
        .then((res) => {
			console.log(res,"1234567")
          this.fullscreenLoading = false;
          this.infoList = res.data;
          // this.VSP = res.data.V_S;
          localStorage.setItem("info", JSON.stringify(res.info));
          let projectData = JSON.parse(localStorage.getItem("info"));
          this.info = projectData;

          // 表格一数据
        })
        .catch((err) => {
          this.fullscreenLoading = false;
        });
    },
    // 监听滚动条
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (scrollTop >= 680) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    destroyed() {
      // 离开该页面需要移除这个监听的事件，不然会报错
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
