<template>
	<div class="total drag" v-draw id="RqParameter5">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="zhuangshizb"></div>
				<div class="zhuangshizj">{{projectData.project}}_参数5</div>
				<div class="zhuangshiyb" @click="closeCompon">
					<div class="icon"></div>
				</div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				煤气回路优化控制模型
			</div>
		</div>
		<!-- 内容 -->
		<div class="content flex">
			<div class="one">
				<div class="flex">
					<div class="word1">主汽压力控制</div>
					<div class="button1">
						<div class="button1_word">控制方式</div>
					</div>
				</div>
				<div class="shadow shadow1">
					<div class="flex">
						<div class="column1">A01-QYSPH</div>
						<div class="column2" @click="toIpt(infoList.QYSP_H,'A01-QYSPH','QYSP_H',infoList.QYSP_H_node_string)">
							{{'QYSP_H' in infoList?infoList.QYSP_H:0}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">A01-QYSPL</div>
						<div class="column2" @click="toIpt(infoList.QYSP_L,'A01-QYSPL','QYSP_L',infoList.QYSP_L_node_string)">
							{{'QYSP_L' in infoList?infoList.QYSP_L:0}}
						</div>
					</div>
				</div>
				<div class="shadow shadow2 float1 flex">
					<div class="column1">A01-QYSP</div>
					<div class="column2" @click="toIpt(infoList.QYSP,'A01-QYSP','QYSP',infoList.QYSP_node_string)">
						{{'QYSP' in infoList?infoList.QYSP:0}}
					</div>
				</div>
				<div class="shadow shadow2 flex">
					<div class="column1">A01-DETQY</div>
					<div class="column2" @click="toIpt(infoList.DETQYSP,'A01-DETQY','DETQYSP',infoList.DETQYSP_node_string)">
						{{'DETQYSP' in infoList?infoList.DETQYSP:0}}
					</div>
				</div>
				<div class="shadow shadow2 flex">
					<div class="column1">A01-QYAV</div>
					<div class="column3" @click="toCompon(2,'QYPV',infoList.QYPV_node_string,'QYPV_NG3','主汽压力测量值')"
						@dblclick="Cclick(infoList.QYPV,'QYPV',infoList.QYPV_node_string,'QYPV_NG3')">
						{{'QYPV' in infoList?infoList.QYPV:0}}
					</div>
				</div>
				<div class="word2">煤气总管压力控制</div>
				<div class="shadow shadow1">
					<div class="flex">
						<div class="column1">A02-GLPSPH</div>
						<div class="column2" @click="toIpt(infoList.GLP_H,'A02-GLPSPH','GLP_H',infoList.GLP_H_node_string)">
							{{'GLP_H' in infoList?infoList.GLP_H:0}}
						</div>
					</div>
					<div class="flex">
						<div class="column1">A02-GLPSPL</div>
						<div class="column2" @click="toIpt(infoList.GLP_L,'A02-GLPSPL','GLP_L',infoList.GLP_L_node_string)">
							{{'GLP_L' in infoList?infoList.GLP_L:0}}
						</div>
					</div>
				</div>
				<div class="shadow shadow2 float2 flex">
					<div class="column1">A02-GLPSP</div>
					<div class="column3" @click="toCompon(2,'SP',infoList.XK12_node_string,'XK12_SP_NG3','煤气母管压力先控调节','XK12')"
						@dblclick="Cclick(infoList.XK12.SP,'SP',infoList.XK12_node_string,'XK12_SP_NG3')">
						{{'XK12' in infoList?infoList.XK12.SP:0}}
					</div>
				</div>
				<div class="shadow shadow2 flex">
					<div class="column1">A02-GLPTC</div>
					<div class="column2" @click="toIpt(infoList.GLPTC,'A02-GLPTC','GLPTC',infoList.GLPTC_node_string)">
						{{'GLPTC' in infoList?infoList.GLPTC:0}}
					</div>
				</div>
				<div class="shadow shadow2 flex">
					<div class="column1">A02-GLPPV</div>
					<div class="column3"
						@click="toCompon(2,'YHZL',infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL_node_string,'YHMQL_NG3','优化煤气量','BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL')"
						@dblclick="Cclick(infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZL,'YHZL',infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL_node_string,'YHMQL_NG3')">
						{{'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL' in infoList?infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZL:0}}
					</div>
				</div>
				<div class="shadow shadow3 float2">
					<div class="flex">
						<div>
							<div class="flex">
								<div class="column1">A03-TOP</div>
								<div class="column2"
									@click="toIpt(infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.TOP,'A03-TOP','TOP',infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string)">
									{{'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL' in infoList?infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.TOP:0}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">A03-SOP</div>
								<div class="column2"
									@click="toIpt(infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.SOP,'A03-SOP','SOP',infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string)">
									{{'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL' in infoList?infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.SOP:0}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">A03-EOP</div>
								<div class="column2"
									@click="toIpt(infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.EOP,'A03-EOP','EOP',infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string)">
									{{'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL' in infoList?infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.EOP:0}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">A03-TC(m)</div>
								<div class="column2"
									@click="toIpt(infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHJG,'A03-TC(m)','YHJG',infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string)">
									{{'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL' in infoList?infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHJG:0}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">煤T</div>
								<div class="column3"
									@click="toCompon(2,'YHJGSYSJ',infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL_node_string,'SYSJ1_NG3','煤气优化剩余时间','BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL')"
									@dblclick="Cclick(infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHJGSYSJ,'YHJGSYSJ',infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL_node_string,'SYSJ1_NG3')">
									{{'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL' in infoList?infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHJGSYSJ:0}}
								</div>
							</div>
						</div>
						<div>
							<div class="flex">
								<div class="column1">A03-OH</div>
								<div class="column2"
									@click="toIpt(infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_H,'A03-TOP','YHZL_H',infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string)">
									{{'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL' in infoList?infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_H:0}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">A03-OL</div>
								<div class="column2"
									@click="toIpt(infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_L,'A03-TOP','YHZL_L',infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string)">
									{{'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL' in infoList?infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_L:0}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">A03-OT</div>
								<div class="column3"
									@click="toCompon(2,'YHZL',infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL_node_string,'YHMQL_NG3','优化煤气量','BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL')"
									@dblclick="Cclick(infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZL,'YHZL',infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL_node_string,'YHMQL_NG3')">
									{{'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL' in infoList?infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZL:0}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="shadow shadow2 float3 flex">
					<div class="column1">A04-FYH</div>
					<div class="column2"
						@click="toIpt(infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_L,'A03-TOP','YHZL_L',infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string)">
						{{'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL' in infoList?infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_L:0}}
					</div>
				</div>
				<div class="shadow shadow2 flex">
					<div class="column1">A04-FYPV</div>
					<div class="column3" @click="toCompon(2,'FYPV',infoList.FYPV_node_string,'FYPV_NG3','负压测量值')"
						@dblclick="Cclick(infoList.FYPV,'FYPV',infoList.FYPV_node_string,'FYPV_NG3')">
						{{'FYPV' in infoList?infoList.FYPV:0}}
					</div>
				</div>
				<div class="shadow shadow2 float2 flex">
					<div class="column1">A06-02LSP</div>
					<div class="column2" @click="toIpt(infoList.O2PV,'A06-02LSP','O2PV',infoList.O2PV_node_string)">
						{{'O2PV' in infoList?infoList.O2PV:0}}
					</div>
				</div>
				<div class="shadow shadow2 flex">
					<div class="column1">A06-02LPV</div>
					<div class="column3" @click="toCompon(2,'O2PV',infoList.O2PV_node_string,'O2PV_NG3','氧量设定值')"
						@dblclick="Cclick(infoList.O2PV,'O2PV',infoList.O2PV_node_string,'O2PV_NG3')">
						{{'O2PV' in infoList?infoList.O2PV:0}}
					</div>
				</div>
				<div class="shadow shadow2 float2 flex">
					<div class="column1">A07-YDSP</div>
					<div class="column3" @click="toCompon(2,'SP',infoList.RSF2_node_string,'RSF2_SP_NG3','多炉负荷协调软伺服','RSF2')"
						@dblclick="Cclick(infoList.RSF2.SP,'SP',infoList.RSF2_node_string,'RSF2_SP_NG3')">
						{{'RSF2' in infoList?infoList.RSF2.SP:0}}
					</div>
				</div>
				<div class="button2">
					<div class="button2_word">机炉协调启动</div>
				</div>
				<div class="shadow shadow2 float2 flex">
					<div class="column1">A06-02LPV</div>
					<div class="column3" @click="toIpt(infoList.QYSP_L,'A06-02LPV','QYSP_L',infoList.QYSP_L_node_string)">
						{{'QYSP_L' in infoList?infoList.QYSP_L:0}}
					</div>
				</div>
				<div class="shadow shadow2 float4 flex">
					<div class="column1">A07-YDSP</div>
					<div class="column3" @click="toCompon(2,'QYPV',infoList.QYPV_node_string,'QYPV_NG3','主汽压力测量值')"
						@dblclick="Cclick(infoList.QYPV,'QYPV',infoList.QYPV_node_string,'QYPV_NG3')">
						{{'QYPV' in infoList?infoList.QYPV:0}}
					</div>
				</div>
			</div>
			<div class="two flex">
				<div>
					<div class="flex">
						<div class="buttonx botton3" :style="{
								background:infoList.XK11 && !infoList.XK11.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.XK11
								? toDetail(1, 'XK11', infoList.XK11_node_string, '', '煤气回路优化控制模型'): ''">
							X
						</div>
						<div class="shadow shadow4 float5 flex">
							<div class="column1">A01-AV</div>
							<div class="column3" @click="toCompon(2,'AV',infoList.XK11_node_string,'XK11_AV_NG3','主汽压力先控调节','XK11')"
								@dblclick="Cclick(infoList.XK11.AV,'A01-AV','AV',infoList.XK11_node_string)">
								{{'XK11' in infoList?infoList.XK11.AV:0}}
							</div>
						</div>
					</div>
					<div class="shadow shadow5">
						<div class="flex">
							<div class="column1">A05-GLPL</div>
							<div class="column2" @click="toIpt(infoList.GLP_L,'A05-GLPL','GLP_L',infoList.GLP_L_node_string)">
								{{'GLP_L' in infoList?infoList.GLP_L:0}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">A05-02PVL</div>
							<div class="column2" @click="toIpt(infoList.O2PVL,'A05-02PVL','O2PVL',infoList.O2PVL_node_string)">
								{{'O2PVL' in infoList?infoList.O2PVL:0}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">A05-FMBL</div>
							<div class="column2"
								@click="toIpt(infoList.SJFMB_L,'A05-FMBL','SJFMB_L',infoList.SJFMB_L_node_string)">
								{{'SJFMB_L' in infoList?infoList.SJFMB_L:0}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">A05-FYH</div>
							<div class="column2" @click="toIpt(infoList.FYPV_H,'A05-FYH','FYPV_H',infoList.FYPV_H_node_string)">
								{{'FYPV_H' in infoList?infoList.FYPV_H:0}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">A05-FHH</div>
							<div class="column2" @click="toIpt(infoList.GLFH_H,'A05-FHH','GLFH_H',infoList.GLFH_H_node_string)">
								{{'GLFH_H' in infoList?infoList.GLFH_H:0}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">A05-QYH</div>
							<div class="column2" @click="toIpt(infoList.QYSP_H,'A05-QYH','QYSP_H',infoList.QYSP_H_node_string)">
								{{'QYSP_H' in infoList?infoList.QYSP_H:0}}
							</div>
						</div>
					</div>
					<div class="flex">
						<div class="buttonx botton4" :style="{
								background:infoList.XK12 && !infoList.XK12.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.XK12
								? toDetail(1, 'XK12', infoList.XK12_node_string, '', '煤气回路优化控制模型'): ''">
							X
						</div>
						<div class="shadow shadow4 float6 flex">
							<div class="column1">A02-AV</div>
							<div class="column3" @click="toCompon(2,'AV',infoList.XK12_node_string,'XK12_AV_NG3','煤气母管压力先控调节','XK12')"
								@dblclick="Cclick(infoList.XK12.AV,'AV',infoList.XK12_node_string,'XK12_AV_NG3')">
								{{'XK12' in infoList?infoList.XK12.AV:0}}
							</div>
						</div>
					</div>
					<div class="shadow shadow6">
						<div class="flex">
							<div class="column1">A05-QYL</div>
							<div class="column2" @click="toIpt(infoList.QYSP_L,'A05-QYL','QYSP_L',infoList.QYSP_L_node_string)">
								{{'QYSP_L' in infoList?infoList.QYSP_L:0}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">A05-FHL</div>
							<div class="column2" @click="toIpt(infoList.GLFH_L,'A05-FHL','GLFH_L',infoList.GLFH_L_node_string)">
								{{'GLFH_L' in infoList?infoList.GLFH_L:0}}
							</div>
						</div>
					</div>
					<div class="blue">
						<div class="blue_word">优化启动</div>
					</div>
					<div class="green">
						<div class="green_word">强制优化</div>
					</div>
					<div class="buttonr botton5" :style="{
							background:infoList.RSF3 && !infoList.RSF3.TS
					        ? '#2AFC30'
					        : 'red',}" @click="infoList.RSF3
							    ? toDetail(3,'RSF3',infoList.RSF3_node_string,'',''): ''">
						R
					</div>
					<div class="flex">
						<div class="buttonr botton6" :style="{
								background:infoList.RSF4 && !infoList.RSF4.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.RSF4
								    ? toDetail(3,'RSF4',infoList.RSF4_node_string,'',''): ''">
							R
						</div>
						<div class="word word3">氧含量过低保护</div>
					</div>
					<div class="flex">
						<div class="buttonr botton6" :style="{
								background:infoList.RSF5 && !infoList.RSF5.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.RSF5
								    ? toDetail(3,'RSF5',infoList.RSF5_node_string,'',''): ''">
							R
						</div>
						<div class="word word3">多炉协调</div>
						<div class="button7">
							<div class="button7_word">使能</div>
						</div>
					</div>
					<div class="flex">
						<div class="buttonr botton6" :style="{
								background:infoList.QK24 && !infoList.QK24.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.QK24
								    ? toDetail(2,'QK24',infoList.QK24_node_string,'',''): ''">
							K
						</div>
						<div class="word word3">高炉煤气压力前馈</div>
					</div>
					<div class="flex">
						<div class="buttonr botton6" :style="{
								background:infoList.QK13 && !infoList.QK13.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.QK13
								    ? toDetail(2,'QK13',infoList.QK13_node_string,'',''): ''">
							K
						</div>
						<div class="word word3">锅炉负荷前馈</div>
					</div>
					<div class="flex">
						<div class="word word4" @click="toCompon(2,'AV',infoList.QKMCFOP1_node_string,'QKMCFOP1_AV_NG3','','QKMCFOP1')"
							@dblclick="Cclick(infoList.QKMCFOP1.AV,'AV',infoList.QKMCFOP1_node_string,'QKMCFOP1_AV_NG3')">
							{{'QKMCFOP1' in infoList?infoList.QKMCFOP1.AV:0}}
						</div>
						<div class="word word5">前馈脉冲重叠</div>
						<div class="button8">
							<div class="button8_word">使能</div>
						</div>
					</div>
					<div class="flex">
						<div class="buttonx botton9" :style="{
								background:infoList.XK11 && !infoList.XK11.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.XK11
								? toDetail(1, 'XK11', infoList.XK11_node_string, '', '送风层操调节回路'): ''">
							X
						</div>
						<div class="buttonx botton10" :style="{
						     	background:infoList.MAN27.RM && infoList.MAN27.RM == 1
						        ? '#2AFC30'
						        : 'red',
						     	}" @click="infoList.MAN27
						     	? toCompon(0,'AV','MAN27','MAN27_AV_NG3','功率设定输出手操器','KF_127_B',infoList.MAN27_node_string,'KF_127_NG3'): ''">
							A
						</div>
					</div>
					<div class="shadow shadow7">
						<div class="flex">
							<div class="column1">A11-FDLSP</div>
							<div class="column2" @click="toIpt(infoList.FDLSP,'A11-FDLSP','FDLSP',infoList.FDLSP_node_string)">
								{{'FDLSP' in infoList?infoList.FDLSP:0}}
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="shadow shadow8 float7 flex">
						<div class="column1">A09-QWPV</div>
						<div class="column3" @click="toCompon(2,'QWPV',infoList.QWPV_node_string,'QWPV_NG3','主汽温度测量值')"
							@dblclick="Cclick(infoList.QWPV,'QWPV',infoList.QWPV_node_string,'QWPV_NG3')">
							{{'QWPV' in infoList?infoList.QWPV:0}}
						</div>
					</div>
					<div class="shadow shadow8 float8 flex">
						<div class="column1">A09-MQWDYZ</div>
						<div class="column2" @click="toIpt(infoList.GMQWYZ,'A09-MQWDYZ','GMQWYZ',infoList.GMQWYZ_node_string)">
							{{'GMQWYZ' in infoList?infoList.GMQWYZ:0}}
						</div>
					</div>
					<div class="shadow shadow8 float8 flex">
						<div class="column1">A09-QWSP</div>
						<div class="column2" @click="toIpt(infoList.QWSP,'A09-QWSP','QWSP',infoList.QWSP_node_string)">
							{{'QWSP' in infoList?infoList.QWSP:0}}
						</div>
					</div>
					<div class="shadow shadow8 float9 flex">
						<div class="column1">A08-GLJB</div>
						<div class="column2" @click="toIpt(infoList.GLJB,'A08-GLJB','GLJB',infoList.GLJB_node_string)">
							{{'GLJB' in infoList?infoList.GLJB:0}}
						</div>
					</div>
					<div class="button11">
						<div class="button11_word">定煤气量控制</div>
					</div>
					<div class="flex">
						<div class="shadow shadow8 float10 flex">
							<div class="column1">A08-ZGLSP</div>
							<div class="column2" @click="toIpt(infoList.GLSP,'A08-ZGLSP','GLSP',infoList.GLSP_node_string)">
								{{'GLSP' in infoList?infoList.GLSP:0}}
							</div>
						</div>
						<div class="buttonr botton12" :style="{
								background:infoList.QK8 && !infoList.QK8.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.QK8
								    ? toDetail(2,'QK8',infoList.QK8_node_string,'','送风层操调节回路'): ''">
							K
						</div>
					</div>
					<div class="flex">
						<div class="shadow shadow8 float10 flex">
							<div class="column1">A08-ZGLPV</div>
							<div class="column3" @click="toCompon(2,'ZGLPV',infoList.ZGLPV_node_string,'ZGLPV_NG3')"
								@dblclick="Cclick(infoList.ZGLPV,'ZGLPV',infoList.ZGLPV_node_string,'ZGLPV_NG3')">
								{{'ZGLPV' in infoList?infoList.ZGLPV:0}}
							</div>
						</div>
						<div class="buttonx botton13" :style="{
								background:infoList.XK16 && !infoList.XK16.RM
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.XK16
								? toDetail(1, 'XK16', infoList.XK16_node_string, '', ''): ''">
							X
						</div>
					</div>
					<div class="flex">
						<div class="buttonr botton14" :style="{
								background:infoList.QK6 && !infoList.QK6.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.QK6
								    ? toDetail(2,'QK6',infoList.QK6_node_string,'',''): ''">
							K
						</div>
						<div class="float11">
							<div class="shadow shadow8 flex">
								<div class="column1">A10-GLPV</div>
								<div class="column3" @click="toCompon(2,'GLPV',infoList.GLPV_node_string,'GLPV_NG3','高炉煤气流量')"
									@dblclick="Cclick(infoList.GLPV,'GLPV',infoList.GLPV_node_string,'GLPV_NG3')">
									{{'GLPV' in infoList?infoList.GLPV:0}}
								</div>
							</div>
							<div class="shadow shadow8 flex">
								<div class="column1">A10-GLTC</div>
								<div class="column2" @click="toIpt(infoList.GLTC,'A08-ZGLSP','GLTC',infoList.GLTC_node_string)">
									{{'GLTC' in infoList?infoList.GLTC:0}}
								</div>
							</div>
						</div>
					</div>
					<div class="flex">
						<div class="buttonr botton15" :style="{
								background:infoList.QK9 && !infoList.QK9.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.QK9
								    ? toDetail(2,'QK9',infoList.QK9_node_string,'',''): ''">
							K
						</div>
						<div class="float12">
							<div class="shadow shadow8 flex">
								<div class="column1">A10-ZLPV</div>
								<div class="column3" @click="toCompon(2,'ZLPV',infoList.ZLPV_node_string,'ZLPV_NG3','转炉煤气流量')"
									@dblclick="Cclick(infoList.ZLPV,'ZLPV',infoList.ZLPV_node_string,'ZLPV_NG3')">
									{{'ZLPV' in infoList?infoList.ZLPV:0}}
								</div>
							</div>
							<div class="shadow shadow8 flex">
								<div class="column1">A10-ZLTC</div>
								<div class="column2" @click="toIpt(infoList.ZLTC,'A08-ZGLSP','ZLTC',infoList.ZLTC_node_string)">
									{{'ZLTC' in infoList?infoList.ZLTC:0}}
								</div>
							</div>
						</div>
					</div>
					<div class="float13">
						<div class="shadow shadow8 flex">
							<div class="column1">A10-JLPV</div>
							<div class="column3" @click="toCompon(2,'JLPV',infoList.JLPV_node_string,'JLPV_NG3','焦炉煤气流量')"
								@dblclick="Cclick(infoList.JLPV,'JLPV',infoList.JLPV_node_string,'JLPV_NG3')">
								{{'JLPV' in infoList?infoList.JLPV:0}}
							</div>
						</div>
						<div class="shadow shadow8 flex">
							<div class="column1">A10-JLTC</div>
							<div class="column2" @click="toIpt(infoList.JLTC,'A08-ZGLSP','JLTC',infoList.JLTC_node_string)">
								{{'JLTC' in infoList?infoList.JLTC:0}}
							</div>
						</div>
					</div>
					<div class="table">
						<div class="line flex">
							<div class="table_column1">压差</div>
							<div class="table_column3">系数</div>
						</div>
						<div class="line flex">
							<div class="table_column1 flex">
								<div class="column1_one"></div>
								<div class="column1_two"> <= </div>
								<div class="column1_three"
									@click="toIpt(infoList.MQYLFD.IN_FD__ll__1__rr__,'压差','IN_FD__ll__1__rr__',infoList.MQYLFD_node_string)">
									{{'MQYLFD' in infoList?infoList.MQYLFD.IN_FD__ll__1__rr__:0}}
								</div>
							</div>
								<div class="table_column2"
									@click="toIpt(infoList.MQYLFD.AV_FD__ll__1__rr__,'系数','AV_FD__ll__1__rr__',infoList.MQYLFD_node_string)">
									{{'MQYLFD' in infoList?infoList.MQYLFD.AV_FD__ll__1__rr__:0}}
								</div>
							</div>
							<div class="line flex">
								<div class="table_column1 flex">
									<div class="column1_one"
										@click="toIpt(infoList.MQYLFD.IN_FD__ll__1__rr__,'压差','IN_FD__ll__1__rr__',infoList.MQYLFD_node_string)">
										{{'MQYLFD' in infoList?infoList.MQYLFD.IN_FD__ll__1__rr__:0}}
									</div>
									<div class="column1_two">---</div>
									<div class="column1_three"
										@click="toIpt(infoList.MQYLFD.IN_FD__ll__2__rr__,'压差','IN_FD__ll__2__rr__',infoList.MQYLFD_node_string)">
										{{'MQYLFD' in infoList?infoList.MQYLFD.IN_FD__ll__2__rr__:0}}
									</div>
								</div>
								<div class="table_column2"
									@click="toIpt(infoList.MQYLFD.AV_FD__ll__2__rr__,'系数','AV_FD__ll__2__rr__',infoList.MQYLFD_node_string)">
									{{'MQYLFD' in infoList?infoList.MQYLFD.AV_FD__ll__2__rr__:0}}
								</div>
							</div>
							<div class="line flex">
								<div class="table_column1 flex">
									<div class="column1_one"
										@click="toIpt(infoList.MQYLFD.IN_FD__ll__2__rr__,'压差','IN_FD__ll__2__rr__',infoList.MQYLFD_node_string)">
										{{'MQYLFD' in infoList?infoList.MQYLFD.IN_FD__ll__2__rr__:0}}
									</div>
									<div class="column1_two">---</div>
									<div class="column1_three"
										@click="toIpt(infoList.MQYLFD.IN_FD__ll__3__rr__,'压差','IN_FD__ll__3__rr__',infoList.MQYLFD_node_string)">
										{{'MQYLFD' in infoList?infoList.MQYLFD.IN_FD__ll__3__rr__:0}}
									</div>
								</div>
								<div class="table_column2"
									@click="toIpt(infoList.MQYLFD.AV_FD__ll__3__rr__,'系数','AV_FD__ll__3__rr__',infoList.MQYLFD_node_string)">
									{{'MQYLFD' in infoList?infoList.MQYLFD.AV_FD__ll__3__rr__:0}}
								</div>
							</div>
							<div class="line flex">
								<div class="table_column1 flex">
									<div class="column1_one"
										@click="toIpt(infoList.MQYLFD.IN_FD__ll__3__rr__,'压差','IN_FD__ll__3__rr__',infoList.MQYLFD_node_string)">
										{{'MQYLFD' in infoList?infoList.MQYLFD.IN_FD__ll__3__rr__:0}}
									</div>
									<div class="column1_two">---</div>
									<div class="column1_three"
										@click="toIpt(infoList.MQYLFD.IN_FD__ll__4__rr__,'压差','IN_FD__ll__4__rr__',infoList.MQYLFD_node_string)">
										{{'MQYLFD' in infoList?infoList.MQYLFD.IN_FD__ll__4__rr__:0}}
									</div>
								</div>
								<div class="table_column2"
									@click="toIpt(infoList.MQYLFD.AV_FD__ll__4__rr__,'系数','AV_FD__ll__4__rr__',infoList.MQYLFD_node_string)">
									{{'MQYLFD' in infoList?infoList.MQYLFD.AV_FD__ll__4__rr__:0}}
								</div>
							</div>
							<div class="line flex">
								<div class="table_column1 flex">
									<div class="column1_one"
										@click="toIpt(infoList.MQYLFD.IN_FD__ll__4__rr__,'压差','IN_FD__ll__4__rr__',infoList.MQYLFD_node_string)">
										{{'MQYLFD' in infoList?infoList.MQYLFD.IN_FD__ll__4__rr__:0}}
									</div>
									<div class="column1_two">>=</div>
									<div class="column1_three"
										@click="toIpt(infoList.MQYLFD.IN_FD__ll__4__rr__,'压差','IN_FD__ll__4__rr__',infoList.MQYLFD_node_string)">
										{{'MQYLFD' in infoList?infoList.MQYLFD.IN_FD__ll__4__rr__:0}}
									</div>
								</div>
								<div class="table_column2"
									@click="toIpt(infoList.MQYLFD.AV_FD__ll__5__rr__,'系数','AV_FD__ll__5__rr__',infoList.MQYLFD_node_string)">
									{{'MQYLFD' in infoList?infoList.MQYLFD.AV_FD__ll__5__rr__:0}}
								</div>
							</div>
						</div>
						<div class="location">
							<div class="shadow shadow1">
								<div class="flex">
									<div class="column1">A11-FDLSPH</div>
									<div class="column2"
										@click="toIpt(infoList.FDLSPH,'A11-FDLSPH','FDLSPH',infoList.FDLSPH_node_string)">
										{{'FDLSPH' in infoList?infoList.FDLSPH:0}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">A11-FDLSPL</div>
									<div class="column2"
										@click="toIpt(infoList.FDLSPL,'A11-FDLSPL','FDLSPL',infoList.FDLSPL_node_string)">
										{{'FDLSPL' in infoList?infoList.FDLSPL:0}}
									</div>
								</div>
							</div>
							<div class="shadow shadow2">
								<div class="flex">
									<div class="column1">A11-FDLSP</div>
									<div class="column3" @click="toCompon(2,'SP',infoList.XK30_node_string,'XK30_SP_NG3','发电量调节','XK30')"
										@dblclick="Cclick(infoList.XK30.SP,'SP',infoList.XK30_node_string,'XK30_SP_NG3')">
										{{'XK30' in infoList?infoList.XK30.SP:0}}
									</div>
								</div>
							</div>
							<div class="shadow shadow2">
								<div class="flex">
									<div class="column1">A11-FDLTC</div>
									<div class="column2"
										@click="toIpt(infoList.FDLTC,'A11-FDLTC','FDLTC',infoList.FDLTC_node_string)">
										{{'FDLTC' in infoList?infoList.FDLTC:0}}
									</div>
								</div>
							</div>
							<div class="shadow shadow2">
								<div class="flex">
									<div class="column1">A11-FDLPV</div>
									<div class="column3" @click="toCompon(2,'FDLPV',infoList.FDLPV_node_string,'FDLPV_NG3','处理后的发电量测量值')"
										@dblclick="Cclick(infoList.FDLPV,'FDLPV',infoList.FDLPV_node_string,'FDLPV_NG3')">
										{{'FDLPV' in infoList?infoList.FDLPV:0}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="three">
					<div class="flex">
						<div class="border1">
							<div class="border1_content">软/实际测量</div>
						</div>
						<div class="border2">
							<div class="border2_content" @click="Rccommon = true">高炉煤气软测量</div>
						</div>
					</div>
					<div class="shadow shadow9">
						<div class="flex">
							<div class="column1">煤气流量与煤气设定值对齐等待时间</div>
							<div class="column2"
								@click="toIpt(infoList.MQLLDQT,'煤气流量与煤气设定值对齐等待时间','MQLLDQT',infoList.MQLLDQT_node_string)">
								{{'MQLLDQT' in infoList?infoList.MQLLDQT:0}}
							</div>
						</div>
					</div>
					<div class="shadow shadow10 float1">
						<div class="flex">
							<div class="column1">A08-GLPZ1</div>
							<div class="column2" @click="toIpt(infoList.GLPZ1,'A08-GLPZ1','GLPZ1',infoList.GLPZ1_node_string)">
								{{'GLPZ1' in infoList?infoList.GLPZ1:0}}
							</div>
						</div>
					</div>
					<div class="buttonx botton1" :style="{
						background:infoList.XK13 && !infoList.XK13.RM
				        ? '#2AFC30'
				        : 'red',
						}" @click="infoList.XK13
						? toDetail(1, 'XK13', infoList.XK13_node_string, '', ''): ''">
						X
					</div>
					<div class="float2 flex">
						<div class="buttonx botton2" :style="{
					     	background:infoList.MAN20.RM && infoList.MAN20.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MAN20
					     	? toCompon(0,'AV','MAN20','MAN20_AV_NG3','三层高炉煤气手操器','KF_120_B',infoList.MAN20_node_string,'KF_120_NG3'): ''">
							A
						</div>
						<div class="shadow shadow10">
							<div class="flex">
								<div class="column1">A08-A03</div>
								<div class="column2" @click="toCompon(2,'AV',infoList.MAN20_node_string,'MAN20_AV_NG3','三层高炉煤气手操器','MAN20')"
									@dblclick="Cclick(infoList.MAN20.AV,'AV',infoList.MAN20_node_string,'MAN20_AV_NG3')">
									{{'MAN20' in infoList?infoList.MAN20.AV:0}}
								</div>
							</div>
						</div>
					</div>
					<div class="flex">
						<div class="shadow shadow10 float3 flex">
							<div class="column1">A08-GLPZ2</div>
							<div class="column2" @click="toIpt(infoList.GLPZ2,'A08-GLPZ2','GLPZ2',infoList.GLPZ2_node_string)">
								{{'GLPZ2' in infoList?infoList.GLPZ2:0}}
							</div>
						</div>
						<div class="table_total">
							<div class="table_title">煤气压力--流量系数</div>
							<div class="table">
								<div class="line flex">
									<div class="table_column1">煤气压力</div>
									<div class="table_column3">系数</div>
								</div>
								<div class="line flex">
									<div class="table_column1 flex">
										<div class="column1_one"></div>
										<div class="column1_two"> <= </div>
										<div class="column1_three"
											@click="toIpt(infoList.MQYFD.IN_FD__ll__1__rr__,'煤气压力','IN_FD__ll__1__rr__',infoList.MQYFD_node_string)">
											{{'MQYFD' in infoList?infoList.MQYFD.IN_FD__ll__1__rr__:0}}
										</div>
									</div>
										<div class="table_column2"
											@click="toIpt(infoList.MQYFD.AV_FD__ll__1__rr__,'系数','AV_FD__ll__1__rr__',infoList.MQYFD_node_string)">
											{{'MQYFD' in infoList?infoList.MQYFD.AV_FD__ll__1__rr__:0}}
										</div>
									</div>
									<div class="line flex">
										<div class="table_column1 flex">
											<div class="column1_one"
												@click="toIpt(infoList.MQYFD.IN_FD__ll__1__rr__,'煤气压力','IN_FD__ll__1__rr__',infoList.MQYFD_node_string)">
												{{'MQYFD' in infoList?infoList.MQYFD.IN_FD__ll__1__rr__:0}}
											</div>
											<div class="column1_two">---</div>
											<div class="column1_three"
												@click="toIpt(infoList.MQYFD.IN_FD__ll__2__rr__,'煤气压力','IN_FD__ll__2__rr__',infoList.MQYFD_node_string)">
												{{'MQYFD' in infoList?infoList.MQYFD.IN_FD__ll__2__rr__:0}}
											</div>
										</div>
										<div class="table_column2"
											@click="toIpt(infoList.MQYFD.AV_FD__ll__2__rr__,'系数','AV_FD__ll__2__rr__',infoList.MQYFD_node_string)">
											{{'MQYFD' in infoList?infoList.MQYFD.AV_FD__ll__2__rr__:0}}
										</div>
									</div>
									<div class="line flex">
										<div class="table_column1 flex">
											<div class="column1_one"
												@click="toIpt(infoList.MQYFD.IN_FD__ll__2__rr__,'煤气压力','IN_FD__ll__2__rr__',infoList.MQYFD_node_string)">
												{{'MQYFD' in infoList?infoList.MQYFD.IN_FD__ll__2__rr__:0}}
											</div>
											<div class="column1_two">---</div>
											<div class="column1_three"
												@click="toIpt(infoList.MQYFD.IN_FD__ll__3__rr__,'煤气压力','IN_FD__ll__3__rr__',infoList.MQYFD_node_string)">
												{{'MQYFD' in infoList?infoList.MQYFD.IN_FD__ll__3__rr__:0}}
											</div>
										</div>
										<div class="table_column2"
											@click="toIpt(infoList.MQYFD.AV_FD__ll__3__rr__,'系数','AV_FD__ll__3__rr__',infoList.MQYFD_node_string)">
											{{'MQYFD' in infoList?infoList.MQYFD.AV_FD__ll__3__rr__:0}}
										</div>
									</div>
									<div class="line flex">
										<div class="table_column1 flex">
											<div class="column1_one"
												@click="toIpt(infoList.MQYFD.IN_FD__ll__3__rr__,'煤气压力','IN_FD__ll__3__rr__',infoList.MQYFD_node_string)">
												{{'MQYFD' in infoList?infoList.MQYFD.IN_FD__ll__3__rr__:0}}
											</div>
											<div class="column1_two">---</div>
											<div class="column1_three"
												@click="toIpt(infoList.MQYFD.IN_FD__ll__4__rr__,'煤气压力','IN_FD__ll__4__rr__',infoList.MQYFD_node_string)">
												{{'MQYFD' in infoList?infoList.MQYFD.IN_FD__ll__4__rr__:0}}
											</div>
										</div>
										<div class="table_column2"
											@click="toIpt(infoList.MQYFD.AV_FD__ll__4__rr__,'系数','AV_FD__ll__4__rr__',infoList.MQYFD_node_string)">
											{{'MQYFD' in infoList?infoList.MQYFD.AV_FD__ll__4__rr__:0}}
										</div>
									</div>
									<div class="line flex">
										<div class="table_column1 flex">
											<div class="column1_one"
												@click="toIpt(infoList.MQYFD.IN_FD__ll__4__rr__,'煤气压力','IN_FD__ll__4__rr__',infoList.MQYFD_node_string)">
												{{'MQYFD' in infoList?infoList.MQYFD.IN_FD__ll__4__rr__:0}}
											</div>
											<div class="column1_two">>=</div>
											<div class="column1_three"
												@click="toIpt(infoList.MQYFD.IN_FD__ll__4__rr__,'煤气压力','IN_FD__ll__4__rr__',infoList.MQYFD_node_string)">
												{{'MQYFD' in infoList?infoList.MQYFD.IN_FD__ll__4__rr__:0}}
											</div>
										</div>
										<div class="table_column2"
											@click="toIpt(infoList.MQYFD.AV_FD__ll__5__rr__,'系数','AV_FD__ll__5__rr__',infoList.MQYFD_node_string)">
											{{'MQYFD' in infoList?infoList.MQYFD.AV_FD__ll__5__rr__:0}}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="flex">
							<div class="buttonx botton3" :style="{
					     	background:infoList.MAN19.RM && infoList.MAN19.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MAN19
					     	? toCompon(0,'AV','MAN19','MAN19_AV_NG3','二层高炉煤气手操器','KF_119_B',infoList.MAN19_node_string,'KF_119_NG3'): ''">
								A
							</div>
							<div class="shadow shadow10 float4">
								<div class="flex">
									<div class="column1">A08-A02</div>
									<div class="column2" @click="toCompon(2,'AV',infoList.MAN19_node_string,'MAN19_AV_NG3','二层高炉煤气手操器','MAN19')"
										@dblclick="Cclick(infoList.MAN19.AV,'AV',infoList.MAN19_node_string,'MAN19_AV_NG3')">
										{{'MAN19' in infoList?infoList.MAN19.AV:0}}
									</div>
								</div>
							</div>
						</div>

						<div class="flex">
							<div class="shadow shadow10 float5 flex">
								<div class="column1">A08-GLPZ2</div>
								<div class="column2" @click="toIpt(infoList.GLPZ3,'A08-A03','GLPZ3',infoList.GLPZ3_node_string)">
									{{'GLPZ3' in infoList?infoList.GLPZ3:0}}
								</div>
							</div>
							<div class="table_total">
								<div class="table">
									<div class="line flex">
										<div class="table_column1">阀门开度</div>
										<div class="table_column3">系数</div>
									</div>
									<div class="line flex">
										<div class="table_column1 flex">
											<div class="column1_one"></div>
											<div class="column1_two">
												<= </div>
													<div class="column1_three"
														@click="toIpt(infoList.MQFMFD.IN_FD__ll__1__rr__,'阀门开度','IN_FD__ll__1__rr__',infoList.MQFMFD_node_string)">
														{{'MQFMFD' in infoList?infoList.MQFMFD.IN_FD__ll__1__rr__:0}}
													</div>
											</div>
											<div class="table_column2"
												@click="toIpt(infoList.MQFMFD.AV_FD__ll__1__rr__,'系数','AV_FD__ll__1__rr__',infoList.MQFMFD_node_string)">
												{{'MQFMFD' in infoList?infoList.MQFMFD.AV_FD__ll__1__rr__:0}}
											</div>
										</div>
										<div class="line flex">
											<div class="table_column1 flex">
												<div class="column1_one"
													@click="toIpt(infoList.MQFMFD.IN_FD__ll__1__rr__,'阀门开度','IN_FD__ll__1__rr__',infoList.MQFMFD_node_string)">
													{{'MQFMFD' in infoList?infoList.MQFMFD.IN_FD__ll__1__rr__:0}}
												</div>
												<div class="column1_two">---</div>
												<div class="column1_three"
													@click="toIpt(infoList.MQFMFD.IN_FD__ll__2__rr__,'阀门开度','IN_FD__ll__2__rr__',infoList.MQFMFD_node_string)">
													{{'MQFMFD' in infoList?infoList.MQFMFD.IN_FD__ll__2__rr__:0}}
												</div>
											</div>
											<div class="table_column2"
												@click="toIpt(infoList.MQFMFD.AV_FD__ll__2__rr__,'系数','AV_FD__ll__2__rr__',infoList.MQFMFD_node_string)">
												{{'MQFMFD' in infoList?infoList.MQFMFD.AV_FD__ll__2__rr__:0}}
											</div>
										</div>
										<div class="line flex">
											<div class="table_column1 flex">
												<div class="column1_one"
													@click="toIpt(infoList.MQFMFD.IN_FD__ll__2__rr__,'阀门开度','IN_FD__ll__2__rr__',infoList.MQFMFD_node_string)">
													{{'MQFMFD' in infoList?infoList.MQFMFD.IN_FD__ll__2__rr__:0}}
												</div>
												<div class="column1_two">---</div>
												<div class="column1_three"
													@click="toIpt(infoList.MQFMFD.IN_FD__ll__3__rr__,'阀门开度','IN_FD__ll__3__rr__',infoList.MQFMFD_node_string)">
													{{'MQFMFD' in infoList?infoList.MQFMFD.IN_FD__ll__3__rr__:0}}
												</div>
											</div>
											<div class="table_column2"
												@click="toIpt(infoList.MQFMFD.AV_FD__ll__3__rr__,'系数','AV_FD__ll__3__rr__',infoList.MQFMFD_node_string)">
												{{'MQFMFD' in infoList?infoList.MQFMFD.AV_FD__ll__3__rr__:0}}
											</div>
										</div>
										<div class="line flex">
											<div class="table_column1 flex">
												<div class="column1_one"
													@click="toIpt(infoList.MQFMFD.IN_FD__ll__2__rr__,'阀门开度','IN_FD__ll__2__rr__',infoList.MQFMFD_node_string)">
													{{'MQFMFD' in infoList?infoList.MQFMFD.IN_FD__ll__2__rr__:0}}
												</div>
												<div class="column1_two">---</div>
												<div class="column1_three"
													@click="toIpt(infoList.MQFMFD.IN_FD__ll__3__rr__,'阀门开度','IN_FD__ll__3__rr__',infoList.MQFMFD_node_string)">
													{{'MQFMFD' in infoList?infoList.MQFMFD.IN_FD__ll__3__rr__:0}}
												</div>
											</div>
											<div class="table_column2"
												@click="toIpt(infoList.MQFMFD.AV_FD__ll__4__rr__,'系数','AV_FD__ll__4__rr__',infoList.MQFMFD_node_string)">
												{{'MQFMFD' in infoList?infoList.MQFMFD.AV_FD__ll__4__rr__:0}}
											</div>
										</div>
										<div class="line flex">
											<div class="table_column1 flex">
												<div class="column1_one"
													@click="toIpt(infoList.MQFMFD.IN_FD__ll__3__rr__,'阀门开度','IN_FD__ll__3__rr__',infoList.MQFMFD_node_string)">
													{{'MQFMFD' in infoList?infoList.MQFMFD.IN_FD__ll__3__rr__:0}}
												</div>
												<div class="column1_two">>=</div>
												<div class="column1_three"
													@click="toIpt(infoList.MQFMFD.IN_FD__ll__3__rr__,'阀门开度','IN_FD__ll__3__rr__',infoList.MQFMFD_node_string)">
													{{'MQFMFD' in infoList?infoList.MQFMFD.IN_FD__ll__3__rr__:0}}
												</div>
											</div>
											<div class="table_column2"
												@click="toIpt(infoList.MQFMFD.AV_FD__ll__5__rr__,'系数','AV_FD__ll__5__rr__',infoList.MQFMFD_node_string)">
												{{'MQFMFD' in infoList?infoList.MQFMFD.AV_FD__ll__5__rr__:0}}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="flex">
								<div class="buttonx botton4" :style="{
					     	background:infoList.MAN18.RM && infoList.MAN18.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MAN18
					     	? toCompon(0,'AV','MAN18','MAN18_AV_NG3','一层高炉煤气手操器','KF_118_B',infoList.MAN18_node_string,'KF_118_NG3'): ''">
									A
								</div>
								<div class="shadow shadow10 float6">
									<div class="flex">
										<div class="column1">A08-A01</div>
										<div class="column2" @click="toCompon(2,'AV',infoList.MAN18_node_string,'MAN18_AV_NG3','一层高炉煤气手操器','MAN18')"
											@dblclick="Cclick(infoList.MAN18.AV,'AV',infoList.MAN18_node_string,'MAN18_AV_NG3')">
											{{'MAN18' in infoList?infoList.MAN18.AV:0}}
										</div>
									</div>
								</div>
							</div>
							<div class="location1 flex">
								<div class="buttonx botton5" :style="{
							background:infoList.XK30 && !infoList.XK30.RM
					        ? '#2AFC30'
					        : 'red',
							}" @click="infoList.XK30
							? toDetail(1, 'XK30', infoList.XK30_node_string, '', ''): ''">
									X
								</div>
								<div class="shadow shadow10 float7">
									<div class="flex">
										<div class="column1">A11-AV</div>
										<div class="column2" @click="toCompon(2,'AV',infoList.XK30_node_string,'XK30_AV_NG3','发电量调节','XK30')"
											@dblclick="Cclick(infoList.XK30.AV,'AV',infoList.XK30_node_string,'XK30_AV_NG3')">
											{{'XK30' in infoList?infoList.XK30.AV:0}}
										</div>
									</div>
								</div>
								<div class="buttonx botton6" :style="{
					     	background:infoList.MAN35.RM && infoList.MAN35.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MAN35
					     	? toCompon(0,'AV','MAN35','MAN35_AV_NG3','燃气锅炉_MAN','KF_135_B',infoList.MAN35_node_string,'KF_135_NG3'): ''">
									A
								</div>
								<div class="shadow shadow10 float7">
									<div class="flex">
										<div class="column1">A11-A01</div>
										<div class="column2" @click="toCompon(2,'AV',infoList.MAN18_node_string,'MAN18_AV_NG3','一层高炉煤气手操器','MAN18')"
											@dblclick="Cclick(infoList.MAN18.AV,'AV',infoList.MAN18_node_string,'MAN18_AV_NG3')">
											{{'MAN18' in infoList?infoList.MAN18.AV:0}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 解释 -->
					<div class="explain">
						<div class="explain_content">
							<div class="explain1">
								说明：1. 主汽压力控制SADD：氧量下限、煤气压力下限、空燃比下限、负压上限、负荷上限、投入自动的阀门、引风机能力达到上限；SSUB: 投入自动的阀门达到下限；
							</div>
							<div class="explain2 flex">
								<div class="blank"></div>
								<div>2. 煤气压力控制SADD：氧量下限、主汽压力上限、空燃比下限、负压上限、负荷上限、投入自动的阀门、引风机能力达到上限；SSUB: 投入自动的阀门达到下限；
								</div>
							</div>
							<div class="explain2 flex">
								<div class="blank"></div>
								<div>3.
									主汽压力在压力控制区间范围内，设定值跟踪测量值；煤气压力在煤气压力控制区间范围内时，设定值跟踪测量值。当煤气阀门达到上限或者下限时，煤气流量设定值对齐煤气量测量值。
								</div>
							</div>
						</div>
					</div>
					<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
					<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node'
						:Lkname='Lkname' :chName="chName" :infoList='infoList' :node1='node1'></Historical>
					<Manual :key="isIndex" v-if="isMshow" @sendStatus="isMshow = false" :historyname="Manualname"
						:node="Manualnode" :Lkname="ManualAname" :titname="Manualtitname" :pmark="Panualmark"
						:pnode="Panualnode" :pnm="Panualnodem" :infoList="infoList">
					</Manual>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Rsf from "@/components/RSF.vue"; //阮伺服组件
import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
import Manual from "@/components/Manual1.vue"; //手操器组件
export default {
  name: "Param11",
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
  },
  components: {
    inputVal,
    Historical,
    Firstcontrol,
    Rsf,
    ParameterYh,
    Manual,
  },
  data: () => {
    return {
      chName: "",
      Panualmark: "",
      Panualnode: "",
      Panualnodem: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
      node1: "",
    };
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    DataJson(data) {
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "rqkfirstcontolRQL";
            break;
          case 3:
            pathname = "rqRfirstcontolRQL";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isshowfase() {
      this.isComShow = false;
    },
    closeCompon() {
      this.$emit("sendStatus", "Param05", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
      }
    },
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    toCompon(key, name, name2, name3, name4, titname, param1, param2, param3) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            console.log(1);
            return (
              (this.isMshow = true),
              (this.Manualname = name),
              (this.Manualnode = name2),
              (this.ManualAname = name3),
              (this.Manualtitname = titname),
              (this.Panualmark = param1),
              (this.Panualnode = param2),
              (this.Panualnodem = param3)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (
              (this.isHshow = true),
              (this.chName = name4),
              (this.node1 = titname)
            );
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.total {
  width: 83vw;
  height: 91vh;
  background-image: url("~@/assets/images/RqBoiler/servive_bg.png");
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -45.5vh 0 0 -40.6vw;
  z-index: 999;

  .title-hang {
    width: 53vw;
    height: 5vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    margin-left: 15vw;
  }

  .zhuangshizb {
    width: 16vw;
    height: 2vh;
    background-image: url("~@/assets/images/rfl_evaluate06.png");
    background-size: 100% 100%;
    margin-top: 2vh;
  }

  .zhuangshizj {
    width: 19vw;
    height: 5vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
    margin-top: 1vh;
    text-align: center;
    background-image: url("~@/assets/images/rfl_evaluate05.png");
    background-size: 100% 100%;
    margin-left: 1vw;
  }

  .zhuangshiyb {
    width: 16vw;
    height: 2vh;
    background-image: url("~@/assets/images/rfl_evaluate04.png");
    background-size: 100% 100%;
    margin-left: 1vw;
    margin-top: 2vh;
  }

  .icon {
    width: 2vw;
    height: 3.4vh;
    background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
    background-size: 100% 100%;
    margin-left: 28vw;
    margin-top: -0.5vh;
    cursor: pointer;
  }

  .main {
    width: 83vw;
    height: 3vh;
    margin-top: 3vh;

    .main_title {
      font-family: PingFang-SC-Regular;
      font-size: 3vh;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vh;
      letter-spacing: 1vh;
      color: #d5fffe;
      text-align: center;
    }
  }

  .content {
    width: 80vw;
    height: 71vh;
    margin-top: -3vh;
    margin-left: 1.5vw;

    .shadow {
      background-color: #001b2860;
      box-shadow: 0vw 0vw 0vw 0vw #15384c;
      border-radius: 0vw;
      border: 1px solid rgba(0, 228, 255, 0.2);
      box-sizing: border-box;
      font-family: PingFang-SC-Regular;
      font-size: 1vh;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vh;
      letter-spacing: 0vh;
    }

    .one {
      font-family: PingFang-SC-Regular;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0vh;

      .word1 {
        width: 5vw;
        height: 1vh;
        font-size: 1vh;
        line-height: 2vh;
        color: #d5fffe;
      }

      .button1 {
        width: 4vw;
        height: 2vh;
        background-color: #22fff7;
        border-radius: 0vh;
        text-align: center;
        margin-left: 0.95vw;
        cursor: pointer;

        .button1_word {
          width: 4vw;
          height: 1vh;
          font-size: 1vh;
          line-height: 2vh;
          letter-spacing: 0vh;
          color: #0a4c62;
          text-align: center;
        }
      }

      .shadow1 {
        width: 10vw;
        height: 4vh;

        .column1 {
          width: 5vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          margin-left: 0.3vw;
        }

        .column2 {
          width: 4vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }
      }

      .shadow2 {
        width: 10vw;
        height: 2.3vh;

        .column1 {
          width: 5vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          margin-left: 0.3vw;
        }

        .column2 {
          width: 4vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }

        .column3 {
          width: 4vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00e4ff;
          text-align: right;
          cursor: pointer;
        }
      }

      .word2 {
        width: 6vw;
        height: 2vh;
        font-size: 1vh;
        line-height: 2vh;
        color: #d5fffe;
        margin-top: 0vh;
      }

      .shadow3 {
        width: 18vw;
        height: 9.6vh;

        .column1 {
          width: 5vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          margin-left: 0.6vw;
        }

        .column2 {
          width: 3vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }

        .column3 {
          width: 3vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00e4ff;
          text-align: right;
          cursor: pointer;
        }
      }

      .float1 {
        margin-top: 0.7vh;
      }

      .float2 {
        margin-top: 0.4vh;
      }

      .float3 {
        margin-top: 1.5vh;
      }

      .float4 {
        margin-top: 2.2vh;
      }

      .button2 {
        width: 9vw;
        height: 2vh;
        background-color: #22fff7;
        border-radius: 0vh;
        text-align: center;
        margin-top: 13.3vh;
        cursor: pointer;

        .button2_word {
          width: 9vw;
          height: 1vh;
          font-size: 1vh;
          line-height: 2vh;
          letter-spacing: 0vh;
          color: #0a4c62;
          text-align: center;
        }
      }
    }

    .two {
      width: 59.22vw;
      height: 68.33vh;
      background-image: url("~@/assets/images/RqBoiler/para5.png");
      background-size: 100% 100%;
      margin-left: -8vw;
      margin-top: 4vh;

      .buttonx {
        width: 1vw;
        height: 2vh;
        border: 2px solid rgb(217, 243, 145);
        text-align: center;
        line-height: 2vh;
        background-color: #00e4ff;
        color: white;
        cursor: pointer;
      }

      .buttonr {
        width: 1vw;
        height: 2vh;
        border: 2px solid rgb(217, 243, 145);
        text-align: center;
        line-height: 2vh;
        background-color: #04ff57;
        color: white;
        cursor: pointer;
      }

      .shadow4 {
        width: 8vw;
        height: 2vh;

        .column1 {
          width: 5vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          margin-left: 0.6vw;
        }

        .column2 {
          width: 2vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }

        .column3 {
          width: 2vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00e4ff;
          text-align: right;
          cursor: pointer;
        }
      }

      .botton3 {
        margin-top: 2.5vh;
        margin-left: 4.8vw;
        cursor: pointer;
      }

      .float5 {
        margin-top: 2.8vh;
        margin-left: 0.2vw;
      }

      .shadow5 {
        width: 8vw;
        height: 11vh;
        margin-left: 6.3vw;

        .column1 {
          width: 5vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          margin-left: 0.6vw;
        }

        .column2 {
          width: 2vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }

        .column3 {
          width: 2vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00e4ff;
          text-align: right;
          cursor: pointer;
        }
      }

      .botton4 {
        margin-top: 0.6vh;
        margin-left: 4.8vw;
        cursor: pointer;
      }

      .float6 {
        margin-top: 0.8vh;
        margin-left: 0.2vw;
      }

      .shadow6 {
        width: 8vw;
        height: 4vh;
        margin-left: 6.3vw;

        .column1 {
          width: 5vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          margin-left: 0.6vw;
        }

        .column2 {
          width: 2vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }
      }

      .blue {
        width: 4vw;
        height: 2vh;
        background-color: #17b6ff;
        border-radius: 0vh;
        font-size: 1vh;
        line-height: 2vh;
        letter-spacing: 0vh;
        text-align: center;
        margin-top: 0.8vh;
        margin-left: 8.3vw;
        cursor: pointer;

        .blue_word {
          color: #ffffff;
        }
      }

      .green {
        width: 4vw;
        height: 2vh;
        background-color: #04ff57;
        border-radius: 0vh;
        font-size: 1vh;
        line-height: 2vh;
        letter-spacing: 0vh;
        text-align: center;
        margin-top: 0.2vh;
        margin-left: 8.3vw;
        cursor: pointer;

        .green_word {
          color: #0a4c62;
        }
      }

      .botton5 {
        margin-top: 6.6vh;
        margin-left: 4.8vw;
        cursor: pointer;
      }

      .botton6 {
        margin-top: 2.2vh;
        margin-left: 4.8vw;
        cursor: pointer;
      }

      .word {
        width: 6vw;
        height: 1vh;
        font-size: 1vh;
        line-height: 2vh;
        color: #8aeaff;
      }

      .word3 {
        margin-top: 1.3vh;
        margin-left: 4.8vw;
      }

      .button7 {
        width: 3vw;
        height: 2vh;
        border: 1px solid #22fff7;
        box-sizing: border-box;
        text-align: center;
        margin-top: 0.8vh;
        margin-left: 2vw;
        cursor: pointer;

        .button7_word {
          font-size: 1vh;
          line-height: 1.8vh;
          color: #22fff7;
        }
      }

      .word4 {
        width: 2vw;
        height: 1vh;
        font-size: 1vh;
        line-height: 2vh;
        color: #00ffb4;
        margin-top: 1.8vh;
        margin-left: 6.6vw;
      }

      .word5 {
        margin-top: 0.5vh;
        margin-left: 2.2vw;
      }

      .button8 {
        width: 3vw;
        height: 2vh;
        background-color: #22fff7;
        border-radius: 0vh;
        text-align: center;
        margin-left: 2vw;
        cursor: pointer;

        .button8_word {
          font-size: 1vh;
          line-height: 1.8vh;
          color: #0a4c62;
        }
      }

      .botton9 {
        margin-top: 4.2vh;
        margin-left: 14.5vw;
        cursor: pointer;
      }

      .botton10 {
        margin-top: 4.2vh;
        margin-left: 8.8vw;
        cursor: pointer;
      }

      .shadow7 {
        width: 9vw;
        height: 2vh;
        margin-top: 2vh;
        margin-left: 20.8vw;

        .column1 {
          width: 5vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          margin-left: 0.6vw;
        }

        .column2 {
          width: 3vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }
      }

      .shadow8 {
        width: 9vw;
        height: 2vh;
        margin-top: 0vh;
        margin-left: 0vw;

        .column1 {
          width: 5.5vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          margin-left: 0.3vw;
        }

        .column2 {
          width: 2.7vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }

        .column3 {
          width: 2.7vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00e4ff;
          text-align: right;
          cursor: pointer;
        }
      }

      .float7 {
        margin-top: 1.6vh;
        margin-left: 5.5vw;
      }

      .float8 {
        margin-left: 5.5vw;
        margin-top: 0.3vh;
      }

      .float9 {
        margin-left: -4vw;
        margin-top: 1vh;
      }

      .button11 {
        width: 9vw;
        height: 2vh;
        background-color: #22fff7;
        border-radius: 0vh;
        text-align: center;
        margin-left: 1.8vw;
        margin-top: 1vh;
        cursor: pointer;

        .button11_word {
          width: 9vw;
          height: 1vh;
          font-size: 1vh;
          line-height: 2vh;
          letter-spacing: 0vh;
          color: #0a4c62;
          text-align: center;
        }
      }

      .float10 {
        margin-left: 1.8vw;
        margin-top: 0.5vh;
      }

      .botton12 {
        margin-left: 2.5vw;
        cursor: pointer;
      }

      .botton13 {
        margin-left: 2.5vw;
        cursor: pointer;
      }

      .botton14 {
        margin-left: -1vw;
        margin-top: 1.7vh;
        cursor: pointer;
      }

      .float11 {
        margin-left: 1.5vw;
        margin-top: 1vh;
        cursor: pointer;
      }

      .botton15 {
        margin-left: -1vw;
        margin-top: 3.2vh;
        cursor: pointer;
      }

      .float12 {
        margin-left: 1.5vw;
        margin-top: 1vh;
      }

      .float13 {
        margin-left: 1.7vw;
        margin-top: -0.5vh;
      }

      .table {
        width: 10vw;
        height: 14vh;
        font-size: 1vh;
        line-height: 2vh;
        border: 1px solid rgba(0, 228, 255, 0.2);
        box-sizing: border-box;
        margin-left: 1.2vw;
        margin-top: 3vh;

        .table_column1 {
          width: 7vw;
          height: 2.33vh;
          color: #00e4ff;
          border-bottom: 1px solid rgba(0, 228, 255, 0.2);
          box-sizing: border-box;
          text-align: center;

          .column1_one {
            width: 2vw;
            color: #00ffb4;
            text-align: right;
            cursor: pointer;
          }

          .column1_two {
            width: 3vw;
            color: #8aeaff;
            text-align: center;
          }

          .column1_three {
            width: 2vw;
            color: #00ffb4;
            text-align: left;
            cursor: pointer;
          }
        }

        .table_column2 {
          width: 3vw;
          color: #00ffb4;
          border-left: 1px solid rgba(0, 228, 255, 0.2);
          border-bottom: 1px solid rgba(0, 228, 255, 0.2);
          box-sizing: border-box;
          text-align: center;
          cursor: pointer;
        }

        .table_column3 {
          width: 3vw;
          color: #00e4ff;
          border-left: 1px solid rgba(0, 228, 255, 0.2);
          border-bottom: 1px solid rgba(0, 228, 255, 0.2);
          box-sizing: border-box;
          text-align: center;
          cursor: pointer;
        }
      }

      .shadow1 {
        width: 10vw;
        height: 4vh;

        .column1 {
          width: 5vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          margin-left: 0.3vw;
        }

        .column2 {
          width: 4vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }

        .column3 {
          width: 4vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00e4ff;
          text-align: right;
          cursor: pointer;
        }
      }

      .shadow2 {
        width: 10vw;
        height: 2.3vh;

        .column1 {
          width: 5vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          margin-left: 0.3vw;
        }

        .column2 {
          width: 4vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }

        .column3 {
          width: 4vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00e4ff;
          text-align: right;
          cursor: pointer;
        }
      }

      .location {
        margin-left: 1.5vw;
        margin-top: 7.9vh;
      }
    }

    .three {
      .buttonx {
        width: 1vw;
        height: 2vh;
        border: 2px solid rgb(217, 243, 145);
        text-align: center;
        line-height: 2vh;
        background-color: #00e4ff;
        color: white;
        cursor: pointer;
      }

      .border1 {
        width: 5vw;
        height: 2vh;
        border: 1px solid rgba(0, 228, 255, 0.2);
        box-sizing: border-box;
        margin-top: -2vh;
        margin-left: -2vw;
        cursor: pointer;

        .border1_content {
          font-size: 1vh;
          line-height: 2vh;
          color: #34b3dd;
          text-align: center;
        }
      }

      .border2 {
        width: 7vw;
        height: 2vh;
        background-color: #22afff;
        border-radius: 0vh;
        margin-top: -2vh;
        margin-left: 0.3vw;
        cursor: pointer;

        .border2_content {
          font-size: 1vh;
          line-height: 2vh;
          color: #feffff;
          text-align: center;
        }
      }

      .shadow9 {
        width: 15vw;
        height: 2vh;
        margin-left: -8vw;
        margin-top: 4vh;

        .column1 {
          width: 12vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          margin-left: 0.3vw;
        }

        .column2 {
          width: 2vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }
      }

      .shadow10 {
        width: 8vw;
        height: 2vh;

        .column1 {
          width: 5vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          margin-left: 0.3vw;
        }

        .column2 {
          width: 2vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }

        .column3 {
          width: 2vw;
          font-size: 1vh;
          line-height: 1.8vh;
          letter-spacing: 0vh;
          color: #00e4ff;
          text-align: right;
          cursor: pointer;
        }
      }

      .float1 {
        margin-left: -8vw;
      }

      .botton1 {
        margin-left: -9vw;
        margin-top: 2vh;
        cursor: pointer;
      }

      .float2 {
        margin-top: 3.6vh;
      }

      .table_total {
        margin-left: 0.3vw;
        margin-top: 1.5vh;

        .table_title {
          width: 10vw;
          font-size: 1vh;
          line-height: 2vh;
          color: #8aeaff;
          text-align: center;
        }

        .table {
          width: 10vw;
          height: 14vh;
          font-size: 1vh;
          line-height: 2vh;
          border: 1px solid rgba(0, 228, 255, 0.2);
          box-sizing: border-box;
          margin-left: 0vw;
          margin-top: 0vh;

          .table_column1 {
            width: 7vw;
            height: 2.33vh;
            color: #00e4ff;
            border-bottom: 1px solid rgba(0, 228, 255, 0.2);
            box-sizing: border-box;
            text-align: center;

            .column1_one {
              width: 2vw;
              color: #00ffb4;
              text-align: right;
              cursor: pointer;
            }

            .column1_two {
              width: 3vw;
              color: #8aeaff;
              text-align: center;
            }

            .column1_three {
              width: 2vw;
              color: #00ffb4;
              text-align: left;
              cursor: pointer;
            }
          }

          .table_column2 {
            width: 3vw;
            color: #00ffb4;
            border-left: 1px solid rgba(0, 228, 255, 0.2);
            border-bottom: 1px solid rgba(0, 228, 255, 0.2);
            box-sizing: border-box;
            text-align: center;
            cursor: pointer;
          }

          .table_column3 {
            width: 3vw;
            color: #00e4ff;
            border-left: 1px solid rgba(0, 228, 255, 0.2);
            border-bottom: 1px solid rgba(0, 228, 255, 0.2);
            box-sizing: border-box;
            text-align: center;
          }
        }
      }

      .float3 {
        margin-left: -9vw;
        margin-top: 14vh;
      }

      .botton3 {
        margin-top: 0.7vh;
        cursor: pointer;
      }

      .float4 {
        margin-top: 1vh;
      }

      .botton4 {
        margin-top: 2vh;
        cursor: pointer;
      }

      .float5 {
        margin-left: -9vw;
        margin-top: 13vh;
      }

      .float6 {
        margin-top: 2vh;
      }

      .location1 {
        margin-top: 7.4vh;

        .botton5 {
          margin-left: -10.5vw;
        }

        .botton6 {
          margin-left: 1.5vw;
        }
      }
    }
  }

  .explain {
    width: 83vw;
    height: 8vh;
    margin-top: 3vh;
    border-top: 2px solid #bbd6e5;
    font-family: PingFang-SC-Regular;
    font-size: 1vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #4d91a7;

    .explain_content {
      margin-top: 2vh;
      margin-left: 4vw;

      .explain2 {
        .blank {
          width: 2.1vw;
        }
      }
    }
  }
}
</style>
