var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"JrparmsGroup drag",attrs:{"id":"JrparmsGroup"}},[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(" "+_vm._s(_vm.projectData.project)+"_参数11_"+_vm._s(_vm.datatype)+" ")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})]),_c('div',{staticClass:"JrparmsGroup-data"},[_c('div',{staticClass:"JrparmsGroup-data-tit"},[_vm._v(_vm._s(_vm.tit))]),_c('div',{staticClass:"JrparmsGroup-data-top"},[_c('div',{staticClass:"top-text1"},[_vm._v(" 停炉时间设置S "),_c('span',{on:{"click":function($event){_vm.toIpt(
              _vm.infoList['T_TSBZ' + String(_vm.datatype)],
              '停炉时间设置S',
              'T_TSBZ' + _vm.datatype,
              _vm.infoList['T_TSBZ' + String(_vm.datatype) + '_node_string']
            )}}},[_vm._v(" "+_vm._s(_vm.infoList["T_TSBZ" + String(_vm.datatype)])+" ")])]),_c('div',{staticClass:"top-text1"},[_vm._v(" 启炉时间设置S "),_c('span',{on:{"click":function($event){_vm.toIpt(
              _vm.infoList['Q_TSBZ' + String(_vm.datatype)],
              '启炉时间设置S',
              'Q_TSBZ' + _vm.datatype,
              _vm.infoList['Q_TSBZ' + String(_vm.datatype) + '_node_string']
            )}}},[_vm._v(" "+_vm._s(_vm.infoList["Q_TSBZ" + String(_vm.datatype)])+" ")])]),_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("A01-TSP")]),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
              _vm.infoList['T' + _vm.datatype + '1SP'],
              '温度设定值',
              'T' + _vm.datatype + '1SP',
              _vm.infoList['T' + _vm.datatype + '1SP' + '_node_string']
            )}}},[_vm._v(" "+_vm._s(_vm.infoList["T" + _vm.datatype + "1SP"])+" ")])]),_c('div',{staticClass:"left-box1 left-box3 flex align-cen"},[_c('div',[_vm._v("A01-FMQ")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){_vm.toCompon(
              2,
              'AV',
             _vm.infoList['XK' +
                (_vm.datatype == 1
                  ? '10_node_string'
                  : _vm.datatype == 2
                  ? '7_node_string'
                  : _vm.datatype == 3
                  ? '4_node_string'
                  : '1_node_string')] ,
              'XK' +
                +(_vm.datatype == 1
                  ? '10'
                  : _vm.datatype == 2
                  ? '7'
                  : _vm.datatype == 3
                  ? '4'
                  : '1') +
                '_AV_J1'
            )},"dblclick":function($event){_vm.Cclick(
              _vm.infoList[
                'XK' +
                  (_vm.datatype == 1
                    ? '10'
                    : _vm.datatype == 2
                    ? '7'
                    : _vm.datatype == 3
                    ? '4'
                    : '1')
              ].AV,
              'AV',
              _vm.infoList['XK' +
                (_vm.datatype == 1
                  ? '10_node_string'
                  : _vm.datatype == 2
                  ? '7_node_string'
                  : _vm.datatype == 3
                  ? '4_node_string'
                  : '1_node_string')],
              'XK' +
                +(_vm.datatype == 1
                  ? '10'
                  : _vm.datatype == 2
                  ? '7'
                  : _vm.datatype == 3
                  ? '4'
                  : '1') +
                '_AV_J1'
            )}}},[_vm._v(" "+_vm._s(_vm.infoList[ "XK" + (_vm.datatype == 1 ? "10" : _vm.datatype == 2 ? "7" : _vm.datatype == 3 ? "4" : "1") ].AV)+" ")])]),_c('div',{staticClass:"child-dataX child-databtn",style:({
          background:
            _vm.infoList[
              'XK' +
                (_vm.datatype == 1
                  ? '10'
                  : _vm.datatype == 2
                  ? '7'
                  : _vm.datatype == 3
                  ? '4'
                  : '1')
            ] &&
            _vm.infoList[
              'XK' +
                (_vm.datatype == 1
                  ? '10'
                  : _vm.datatype == 2
                  ? '7'
                  : _vm.datatype == 3
                  ? '4'
                  : '1')
            ].RM == 1
              ? '#2AFC30'
              : 'red',
        }),on:{"click":function($event){_vm.infoList[
            'XK' +
              (_vm.datatype == 1
                ? '10'
                : _vm.datatype == 2
                ? '7'
                : _vm.datatype == 3
                ? '4'
                : '1')
          ]
            ? _vm.toDetail(
                1,
                'XK' +
                  (_vm.datatype == 1
                    ? '10'
                    : _vm.datatype == 2
                    ? '7'
                    : _vm.datatype == 3
                    ? '4'
                    : '1'),
                _vm.infoList['XK' +
                  (_vm.datatype == 1
                    ? '10_node_string'
                    : _vm.datatype == 2
                    ? '7_node_string'
                    : _vm.datatype == 3
                    ? '4_node_string'
                    : '1_node_string')],
                '',
                (_vm.datatype == 1
                  ? '一加'
                  : _vm.datatype == 2
                  ? '二加'
                  : _vm.datatype == 3
                  ? '三加'
                  : '均') + '热段炉膛温度控制XK'
              )
            : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"left-box1 left-box4 flex align-cen"},[_c('div',[_vm._v("上限防抖")]),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
              _vm.infoList['MQ' + _vm.datatype + '1_FDH'],
              '修改',
              'MQ' + _vm.datatype + '1_FDH',
              _vm.infoList['MQ' + _vm.datatype + '1_FDH' + '_node_string']
            )}}},[_vm._v(" "+_vm._s(_vm.infoList["MQ" + _vm.datatype + "1_FDH"])+" ")])]),_c('div',{staticClass:"btnright"},[_c('div',{staticClass:"child-dataX1 child-databtn",style:({
            background:
              _vm.infoList[
                'XK' +
                  (_vm.datatype == 1
                    ? '21'
                    : _vm.datatype == 2
                    ? '19'
                    : _vm.datatype == 3
                    ? '17'
                    : '15')
              ] &&
              _vm.infoList[
                'XK' +
                  (_vm.datatype == 1
                    ? '21'
                    : _vm.datatype == 2
                    ? '19'
                    : _vm.datatype == 3
                    ? '17'
                    : '15')
              ].RM == 1
                ? '#2AFC30'
                : 'red',
          }),on:{"click":function($event){_vm.infoList[
              'XK' +
                (_vm.datatype == 1
                  ? '21'
                  : _vm.datatype == 2
                  ? '19'
                  : _vm.datatype == 3
                  ? '17'
                  : '15')
            ]
              ? _vm.toDetail(
                  1,
                  'XK' +
                    (_vm.datatype == 1
                      ? '21'
                      : _vm.datatype == 2
                      ? '19'
                      : _vm.datatype == 3
                      ? '17'
                      : '15'),
                  _vm.infoList['XK' +
                    (_vm.datatype == 1
                      ? '21_node_string'
                      : _vm.datatype == 2
                      ? '19_node_string'
                      : _vm.datatype == 3
                      ? '17_node_string'
                      : '15_node_string')],
                  '',
                  (_vm.datatype == 1
                    ? '一加'
                    : _vm.datatype == 2
                    ? '二加'
                    : _vm.datatype == 3
                    ? '三加'
                    : '均') + '热煤气流量XK'
                )
              : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"child-dataA child-databtn",style:({
          background:
            _vm.infoList[
              'MAN' +
                (_vm.datatype == 1
                  ? '21'
                  : _vm.datatype == 2
                  ? '10'
                  : _vm.datatype == 3
                  ? '6'
                  : '2')
            ].RM == 1
              ? '#2AFC30'
              : 'red',
        }),on:{"click":function($event){_vm.infoList[
            'MAN' +
              (_vm.datatype == 1
                ? '21'
                : _vm.datatype == 2
                ? '10'
                : _vm.datatype == 3
                ? '6'
                : '2')
          ]
            ? _vm.toCompon(
                0,
                'AV',
                _vm.infoList['MAN' +
                  (_vm.datatype == 1
                    ? '21_node_string'
                    : _vm.datatype == 2
                    ? '10_node_string'
                    : _vm.datatype == 3
                    ? '6_node_string'
                    : '2_node_string')],
                'MAN' +
                  (_vm.datatype == 1
                    ? '21'
                    : _vm.datatype == 2
                    ? '10'
                    : _vm.datatype == 3
                    ? '6'
                    : '2') +
                  '_AV_J1',
                (_vm.datatype == 1
                  ? '一'
                  : _vm.datatype == 2
                  ? '二'
                  : _vm.datatype == 3
                  ? '三'
                  : '均') +
                  '热段煤气阀门手操器MAN' +
                  (_vm.datatype == 1
                    ? '21'
                    : _vm.datatype == 2
                    ? '10'
                    : _vm.datatype == 3
                    ? '6'
                    : '2')
              )
            : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"left-box1 left-box2 flex align-cen"},[_c('div',[_vm._v("A01-TSPSJ")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
              2,
              'T' + _vm.datatype + '1SPSJ',
              _vm.infoList['T' + _vm.datatype + '1SPSJ' + '_node_string'],
              'T' + _vm.datatype + '1SPSJ_J1',
              '实际温度控制点'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList['T' + _vm.datatype + '1SPSJ'],
              'T' + _vm.datatype + '1SPSJ',
              _vm.infoList['T' + _vm.datatype + '1SPSJ' + '_node_string'],
              'T' + _vm.datatype + '1SPSJ_J1'
            )}}},[_vm._v(" "+_vm._s(_vm.infoList["T" + _vm.datatype + "1SPSJ"])+" ")])]),_c('div',{staticClass:"box flex"},[_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("A02-YRBK")]),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){_vm.datatype == 1
                ? _vm.toIpt(
                    _vm.infoList.KCGT_YJ,
                    '一加热段修改',
                    'KCGT_YJ',
                    _vm.infoList.KCGT_YJ_node_string
                  )
                : _vm.datatype == 2
                ? _vm.toIpt(
                    _vm.infoList.KCGT_EJ,
                    '二加热段修改',
                    'KCGT_EJ',
                    _vm.infoList.KCGT_EJ_node_string
                  )
                : _vm.datatype == 3
                ? _vm.toIpt(
                    _vm.infoList.KCGT_SJ,
                    '三加热段修改',
                    'KCGT_SJ',
                    _vm.infoList.KCGT_SJ_node_string
                  )
                : _vm.toIpt(
                    _vm.infoList.KCGT_JR,
                    '均热段修改',
                    'KCGT_JR',
                    _vm.infoList.KCGT_JR_node_string
                  )}}},[_vm._v(" "+_vm._s(_vm.datatype == 1 ? _vm.infoList.KCGT_YJ : _vm.datatype == 2 ? _vm.infoList.KCGT_EJ : _vm.datatype == 3 ? _vm.infoList.KCGT_SJ : _vm.infoList.KCGT_JR)+" ")])]),_c('div',{staticClass:"child-dataR child-databtn",style:({
            background:
              _vm.infoList.RSF2 && !_vm.infoList.RSF2.TS ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.RSF2
              ? _vm.toDetail(
                  3,
                  'RSF2',
                  _vm.infoList.RSF2_node_string,
                  '',
                  '出钢温度软伺服'
                )
              : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"boxbtn",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.RSF2SEL,
              '按钮',
              'RSF2SEL',
              _vm.infoList.G_MCSOTHER_node_string
            )}}},[_vm._v(" "+_vm._s(_vm.infoList.RSF2SEL ? "投用" : "切除")+" ")]),_c('div',{staticClass:"boxbtn boxbtn1",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList['T' + _vm.datatype + '1SEL1'],
              '按钮',
              'T' + _vm.datatype + '1SEL1',
              _vm.infoList['T' + _vm.datatype + '1SEL1' + '_node_string']
            )}}},[_vm._v(" "+_vm._s(_vm.infoList["T" + _vm.datatype + "1SEL1"] ? "投用" : "切除")+" ")])]),_c('div',{staticClass:"left1-box-cen"},[_c('div',{staticClass:"box-cen1 flex justify-cen"},[_c('div',{staticClass:"flex align-cen"},[_vm._v(" A01-TCL1 "),_c('span',{staticClass:"ma-auto",style:({
                color:
                  _vm.infoList['T' + _vm.datatype + '1SEL'] == 1 ? '#01ffba' : '#ccc',
              }),on:{"click":function($event){return _vm.checkStu(_vm.datatype, 1)}}},[_vm._v(" "+_vm._s(_vm.infoList[ "TICA_10" + (_vm.datatype == 1 ? "1" : _vm.datatype == 1 ? "7" : _vm.datatype == 2 ? "5" : _vm.datatype == 3 ? "3" : "2") + "A_B" ]))])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A01-TPV "),_c('span',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'T' + _vm.datatype + '1PV',
                  _vm.infoList['T' + _vm.datatype + '1PV' + '_node_string'],
                  'T' + _vm.datatype + '1PV' + '_J1',
                  '均热段上部温度均值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList['T' + _vm.datatype + '1PV'],
                  'T' + _vm.datatype + '1PV',
                  _vm.infoList['T' + _vm.datatype + '1PV' + '_node_string'],
                  'T' + _vm.datatype + '1PV' + '_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["T" + _vm.datatype + "1PV"]))])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A01-T11TC "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['T' + _vm.datatype + '1TC'],
                  '修改',
                  'T' + _vm.datatype + '1TC',
                  _vm.infoList['T' + _vm.datatype + '1TC' + '_node_string']
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["T" + _vm.datatype + "1TC"]))])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A01-LBSJ "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['TYYC' + _vm.datatype].LBSJ,
                  '修改',
                  'LBSJ',
                  _vm.infoList['TYYC' + _vm.datatype + '_node_strin']
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["TYYC" + _vm.datatype].LBSJ))])])]),_c('div',{staticClass:"box-cen1 flex justify-cen"},[_c('div',{staticClass:"flex align-cen"},[_vm._v(" A01-TCL1 "),_c('span',{staticClass:"ma-auto",style:({
                color:
                  _vm.infoList['T' + _vm.datatype + '1SEL'] == 2 ? '#01ffba' : '#ccc',
              }),on:{"click":function($event){return _vm.checkStu(_vm.datatype, 2)}}},[_vm._v(" "+_vm._s(_vm.infoList[ "TICA_10" + (_vm.datatype == 1 ? "1" : _vm.datatype == 1 ? "7" : _vm.datatype == 2 ? "5" : _vm.datatype == 3 ? "3" : "2") + "B_B" ]))])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A01-HZCL "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['T' + _vm.datatype + '1_C'],
                  '修改',
                  'T' + _vm.datatype + '1_C',
                  _vm.infoList['T' + _vm.datatype + '1_C' + '_node_string']
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["T" + _vm.datatype + "1_C"]))])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A01-CYZQ "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['TYYC' + _vm.datatype].CYZQ,
                  '修改',
                  'CYZQ',
                  _vm.infoList['TYYC' + _vm.datatype + '_node_string']
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["TYYC" + _vm.datatype].CYZQ))])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A01-YCSJ "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['TYYC' + _vm.datatype].YCSJ,
                  '修改',
                  'YCSJ',
                  _vm.infoList['TYYC' + _vm.datatype + '_node_string']
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["TYYC" + _vm.datatype].YCSJ))])])])]),_c('div',{staticClass:"left1-box-cen1"},[_c('div',{staticClass:"boxbtn2",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList[_vm.commonLet2].BCSYHQY,
              '按钮',
              'BCSYHQY',
              _vm.infoList[_vm.commonLet2 + '_node_string']
            )}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet2].BCSYHQY ? "投用" : "切除")+" ")]),_c('div',{staticClass:"spbt flex align-cen"},[_c('span',[_vm._v("入炉钢温前馈补偿")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
              background:
                _vm.infoList[_vm.commonLet3] && !_vm.infoList[_vm.commonLet3].TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList[_vm.commonLet3]
                ? _vm.toDetail(
                    2,
                    'QK' +
                      (_vm.datatype == 1
                        ? '17'
                        : _vm.datatype == 2
                        ? '18'
                        : _vm.datatype == 3
                        ? '19'
                        : '20'),
                    _vm.infoList[_vm.commonLet3 + '_node_string'],
                    '',
                    (_vm.datatype == 1
                      ? '一加'
                      : _vm.datatype == 2
                      ? '二加'
                      : _vm.datatype == 3
                      ? '三加'
                      : '均') + '热入炉钢温前馈'
                  )
                : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"box-cen1-top flex"},[_c('div',{staticClass:"lf"},[_c('div',{staticClass:"flex align-cen"},[_vm._v(" AO3-TOP "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(_vm.infoList[_vm.commonLet].TOP, '修改', 'TOP', _vm.infoList[_vm.commonLet+'_node_string'])}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet].TOP)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A03-SOP "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(_vm.infoList[_vm.commonLet].SOP, '修改', 'SOP', _vm.infoList[_vm.commonLet+'_node_string'])}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet].SOP)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A03-EOP "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(_vm.infoList[_vm.commonLet].EOP, '修改', 'EOP', _vm.infoList[_vm.commonLet+'_node_string'])}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet].EOP)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A03-MQLJ "),_c('span',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'YHMQLJ_B' + _vm.datatype + '1',
                    _vm.infoList['YHMQLJ_B' + _vm.datatype + '1'+'_node_string'],
                    'YHMQLJ_B' + _vm.datatype + '1' + '_J1',
                    '优化煤气累积'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList['YHMQLJ_B' + _vm.datatype + '1'],
                    'YHMQLJ_B' + _vm.datatype + '1',
                    _vm.infoList['YHMQLJ_B' + _vm.datatype + '1'+'_node_string'],
                    'YHMQLJ_B' + _vm.datatype + '1' + '_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList["YHMQLJ_B" + _vm.datatype + "1"])+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A03-SYT(m) "),_c('span',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'YHJGSYSJ',
                    _vm.infoList[_vm.commonLet1+'_node_string'],
                    'TIMELAP2_B' + _vm.datatype + '1' + '_J1',
                    '均热煤气优化剩余时间'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList[_vm.commonLet1].YHJGSYSJ,
                    'YHJGSYSJ',
                    _vm.infoList[_vm.commonLet1+'_node_string'],
                    'TIMELAP2_B' + _vm.datatype + '1' + '_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet1].YHJGSYSJ)+" ")])])]),_c('div',{staticClass:"lf"},[_c('div',{staticClass:"flex align-cen"},[_vm._v(" AO3-OH "),_c('span',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'YHZL_H',
                    _vm.infoList[_vm.commonLet+'_node_string'],
                    'BCS_MH_B' + _vm.datatype + '1_J1',
                    '煤气优化上限'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList[_vm.commonLet].YHZL_H,
                    'YHZL_H',
                    _vm.infoList[_vm.commonLet+'_node_string'],
                    'BCS_MH_B' + _vm.datatype + '1_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet].YHZL_H)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" AO3-OL "),_c('span',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'YHZL_L',
                    _vm.infoList[_vm.commonLet+'_node_string'],
                    'BCS_ML_B' + _vm.datatype + '1_J1',
                    '煤气优化下限'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList[_vm.commonLet].YHZL_L,
                    'YHZL_L',
                    _vm.infoList[_vm.commonLet+'_node_string'],
                    'BCS_ML_B' + _vm.datatype + '1_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet].YHZL_L)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A03-JG(m) "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(_vm.infoList[_vm.commonLet].YHJG, '修改', 'YHJG', _vm.infoList[_vm.commonLet+'_node_string'])}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet].YHJG)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A03-OT "),_c('span',{staticClass:"ma-auto",on:{"click":function($event){_vm.toCompon(
                    2,
                    'YHZL',
                    _vm.infoList[_vm.commonLet1+'_node_string'],
                    'AV' +
                      (_vm.datatype == 1
                        ? '8'
                        : _vm.datatype == 2
                        ? '6'
                        : _vm.datatype == 3
                        ? '4'
                        : '2') +
                      '_J1'
                  )},"dblclick":function($event){_vm.Cclick(
                    _vm.infoList[_vm.commonLet1].YHZL,
                    'YHZL',
                    _vm.infoList[_vm.commonLet1+'_node_string'],
                    'AV' +
                      (_vm.datatype == 1
                        ? '8'
                        : _vm.datatype == 2
                        ? '6'
                        : _vm.datatype == 3
                        ? '4'
                        : '2') +
                      '_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet1].YHZL)+" ")])]),_c('div')])]),_c('div',{staticClass:"box-cen1-bot flex align-cen"},[_c('div',{staticClass:"lf",style:([
              {
                background: _vm.infoList[_vm.commonLet].RESET ? 'red' : '#2AFC30',
              },
              {
                color: _vm.infoList[_vm.commonLet].RESET ? '#fff' : '',
              } ]),on:{"click":function($event){return _vm.toIpt(_vm.infoList[_vm.commonLet].RESET, '按钮', 'RESET', _vm.infoList[_vm.commonLet+'_node_string'])}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet].RESET ? "强制优化" : "自动优化")+" ")]),_c('div',{staticClass:"ma-auto"},[_c('div',{staticClass:"dpr"},[_c('div',{staticClass:"npr",style:({
                  width: _vm.infoList[_vm.commonLet1].PV + '%',
                })})])])])]),_c('div',{staticClass:"left1-box-cen2 flex"},[_c('div',{staticClass:"flex"},[_vm._v(" A04-MQSPK "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":String(_vm.infoList['MQ' + _vm.datatype + '1SP_K']),"placement":"top"}},[_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['MQ' + _vm.datatype + '1SP_K'],
                  '修改',
                  'MQ' + _vm.datatype + '1SP_K',
                  _vm.infoList['MQ' + _vm.datatype + '1SP_K'+ '_node_string']
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["MQ" + _vm.datatype + "1SP_K"])+" ")])])],1),_c('div',{staticClass:"flex"},[_vm._v(" A04-MQJBSP "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":String(_vm.infoList['MQ' + _vm.datatype + '1JBSP']),"placement":"top"}},[_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['MQ' + _vm.datatype + '1JBSP'],
                  '修改',
                  'MQ' + _vm.datatype + '1JBSP',
                  _vm.infoList['MQ' + _vm.datatype + '1JBSP'+ '_node_string']
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["MQ" + _vm.datatype + "1JBSP"])+" ")])])],1)]),_c('div',{staticClass:"left1-box-cen3"},[_c('div',{staticClass:"flex align-cen"},[_vm._v(" A05-AV "),_c('span',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'KX_1' + _vm.commonLet4 + '_B',
                _vm.infoList[_vm.commonLet4+'_node_string'],
                'KX_1' + _vm.commonLet4 + '_J1'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['KX_1' + _vm.commonLet4 + '_B'],
                'KX_1' + _vm.commonLet4 + '_B',
                _vm.infoList[_vm.commonLet4+'_node_string'],
                'KX_1' + _vm.commonLet4 + '_J1'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["KX_1" + _vm.commonLet4 + "_B"])+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A05-AOH "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['MAN' + _vm.commonLet14].OutT,
                '修改',
                'OutT',
                'MAN' + _vm.commonLet14
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["MAN" + _vm.commonLet14].OutT)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A05-AOL "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['MAN' + _vm.commonLet14].OutB,
                '修改',
                'OutB',
                'MAN' + _vm.commonLet14
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["MAN" + _vm.commonLet14].OutB)+" ")])])]),_c('div',{staticClass:"left1-box-cen4"},[_c('div',{staticClass:"tr"},[_c('div',[_vm._v("二级炉温补偿使能")]),_c('div',{staticClass:"boxbtn",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList[_vm.commonLet5 + 'WBCEN_EJ'],
                '按钮',
                _vm.commonLet5 + 'WBCEN_EJ',
                _vm.infoList[_vm.commonLet5 + 'WBCEN_EJ_node_string']
              )}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet5 + "WBCEN_EJ"] ? "投用" : "切除")+" ")])]),_c('div',{staticClass:"tr"},[_c('div',[_vm._v("二级煤气补偿使能")]),_c('div',{staticClass:"boxbtn",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList[_vm.commonLet6 + 'QBCEN_EJ'],
                '按钮',
                _vm.commonLet6 + 'QBCEN_EJ',
                _vm.infoList[_vm.commonLet6 + 'QBCEN_EJ_node_string']
              )}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet6 + "QBCEN_EJ"] ? "投用" : "切除")+" ")])])]),_c('div',{staticClass:"left1-box-cen5"},[_c('div',{staticClass:"tr"},[_c('div',{staticClass:"flex align-cen"},[_vm._v(" A05-FMQ "),_c('span',{staticClass:"spdata",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'FTMQ_B' + _vm.datatype + '1',
                  _vm.infoList['FTMQ_B' + _vm.datatype + '1_node_string'],
                  'FTMQ_B' + _vm.datatype + '1_J1',
                  '参与优化煤气量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList['FTMQ_B' + _vm.datatype + '1'],
                  'FTMQ_B' + _vm.datatype + '1',
                  _vm.infoList['FTMQ_B' + _vm.datatype + '1_node_string'],
                  'FTMQ_B' + _vm.datatype + '1_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["FTMQ_B" + _vm.datatype + "1"])+" ")]),_c('div',{staticClass:"ma-auto",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['FFQH_B' + _vm.datatype + '1'],
                  '按钮',
                  'FFQH_B' + _vm.datatype + '1',
                  _vm.infoList['FFQH_B' + _vm.datatype + '1_node_string']
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["FFQH_B" + _vm.datatype + "1"] ? "软测量" : "实际测量")+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A05-MQTC "),_c('span',{staticClass:"spdata",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['FM' + _vm.datatype + '1TC'],
                  '修改',
                  'FM' + _vm.datatype + '1TC',
                  _vm.infoList['FM' + _vm.datatype + '1TC_node_string']
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["FM" + _vm.datatype + "1TC"])+" ")]),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4","cursor":"pointer"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['MQS_B' + _vm.datatype + '1'],
                  '修改',
                  'MQS_B' + _vm.datatype + '1',
                  _vm.infoList['MQS_B' + _vm.datatype + '1_node_string']
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["MQS_B" + _vm.datatype + "1"])+" ")])])]),_c('div',{staticClass:"tr tr1"},[_c('div',{staticClass:"flex align-cen"},[_c('span'),_c('div',{staticClass:"ma-auto",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['HX' + _vm.datatype + 'SEL'],
                  '按钮',
                  'HX' + _vm.datatype + 'SEL',
                  _vm.infoList['HX' + _vm.datatype + 'SEL_node_string']
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["HX" + _vm.datatype + "SEL"] ? "换向处理" : "换向不处理")+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" 换向处理延迟时间 "),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4","cursor":"pointer"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['T_DELAY_HX' + _vm.datatype],
                  '修改',
                  'T_DELAY_HX' + _vm.datatype,
                  _vm.infoList['T_DELAY_HX' + _vm.datatype + '_node_string']
                )}}},[_vm._v(" "+_vm._s(_vm.infoList["T_DELAY_HX" + _vm.datatype])+" ")])])])]),_c('div',{staticClass:"left1-box-cen6 flex align-cen"},[_c('span',[_vm._v("煤气总管压力补偿")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
            background:
              _vm.infoList['QK' + _vm.commonLet7] &&
              !_vm.infoList['QK' + _vm.commonLet7].TS
                ? '#2AFC30'
                : 'red',
          }),on:{"click":function($event){_vm.infoList['QK' + _vm.commonLet7]
              ? _vm.toDetail(
                  2,
                  'QK' + _vm.commonLet7,
                  _vm.infoList['QK' + _vm.commonLet7+'_node_string'],
                  '',
                  (_vm.datatype == 1
                    ? '一加'
                    : _vm.datatype == 2
                    ? '二加'
                    : _vm.datatype == 3
                    ? '三加'
                    : '均') + '热段煤气压力前馈'
                )
              : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"left1-box-cen7"},[_c('div',{staticClass:"flex align-cen"},[_vm._v(" 煤气停烧阀位 "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList[_vm.commonLet8 + 'SMF'],
                '修改',
                _vm.commonLet8 + 'SMF',
                _vm.infoList[_vm.commonLet8 + 'SMF_node_string']
              )}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet8 + "SMF"])+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" 空气停烧阀位 "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList[_vm.commonLet8 + 'SKF'],
                '修改',
                _vm.commonLet8 + 'SKF',
                _vm.infoList[_vm.commonLet8 + 'SKF_node_string']
              )}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet8 + "SKF"])+" ")])])])]),_c('div',{staticClass:"JrparmsGroup-data-tit JrparmsGroup-data-tit1"},[_vm._v(_vm._s(_vm.tit1))]),_c('div',{staticClass:"JrparmsGroup-data-bot"},[_c('div',{staticClass:"bot-box1"},[_c('div',{staticClass:"box-cen1-top flex"},[_c('div',{staticClass:"lf"},[_c('div',{staticClass:"flex align-cen"},[_vm._v(" AO7-TOP "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(_vm.infoList[_vm.commonLet9].TOP, '修改', 'TOP', _vm.infoList[_vm.commonLet9+'_node_string'])}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet9].TOP)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A07-SOP "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(_vm.infoList[_vm.commonLet9].SOP, '修改', 'SOP', _vm.infoList[_vm.commonLet9+'_node_string'])}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet9].SOP)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A07-EOP "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(_vm.infoList[_vm.commonLet9].EOP, '修改', 'EOP', _vm.infoList[_vm.commonLet9+'_node_string'])}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet9].EOP)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A07-SYT(m) "),_c('span',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'YHJGSYSJ',
                    _vm.infoList[_vm.commonLet10+'_node_string'],
                    'TIMELAP_B' + _vm.datatype + '1_J1',
                    '优化剩余时间'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList[_vm.commonLet10].YHJGSYSJ,
                    'YHJGSYSJ',
                    _vm.infoList[_vm.commonLet10+'_node_string'],
                    'TIMELAP_B' + _vm.datatype + '1_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet10].YHJGSYSJ)+" ")])])]),_c('div',{staticClass:"lf"},[_c('div',{staticClass:"flex align-cen"},[_vm._v(" AO7-OH "),_c('span',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'YHZL_H',
                    _vm.infoList[_vm.commonLet9+'_node_string'],
                    'BCS_KH_B' + _vm.datatype + '1_J1',
                    '空燃比优化上限'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList[_vm.commonLet9].YHZL_H,
                    'YHZL_H',
                    _vm.infoList[_vm.commonLet9+'_node_string'],
                    'BCS_KH_B' + _vm.datatype + '1_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet9].YHZL_H)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" AO7-OL "),_c('span',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'YHZL_L',
                    _vm.infoList[_vm.commonLet9+'_node_string'],
                    'BCS_KL_B' + _vm.datatype + '1_J1',
                    '空燃比优化下限'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList[_vm.commonLet9].YHZL_H,
                    'YHZL_L',
                    _vm.infoList[_vm.commonLet9+'_node_string'],
                    'BCS_KL_B' + _vm.datatype + '1_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet9].YHZL_L)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A07-JG(m) "),_c('span',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(_vm.infoList[_vm.commonLet9].YHJG, '修改', 'YHJG', _vm.infoList[_vm.commonLet9+'_node_string'])}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet9].YHJG)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A07-OT "),_c('span',{staticClass:"ma-auto",on:{"click":function($event){_vm.toCompon(
                    2,
                    'YHZL',
                    _vm.infoList[_vm.commonLet10+'_node_string'],
                    'AV' +
                      (_vm.datatype == 1
                        ? '7'
                        : _vm.datatype == 2
                        ? '5'
                        : _vm.datatype == 3
                        ? '3'
                        : '1') +
                      '_J1'
                  )},"dblclick":function($event){_vm.Cclick(
                    _vm.infoList[_vm.commonLet10].YHZL,
                    'YHZL',
                    _vm.infoList[_vm.commonLet10+'_node_string'],
                    'AV' +
                      (_vm.datatype == 1
                        ? '7'
                        : _vm.datatype == 2
                        ? '5'
                        : _vm.datatype == 3
                        ? '3'
                        : '1') +
                      '_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet10].YHZL)+" ")])]),_c('div')])]),_c('div',{staticClass:"box-cen1-bot flex align-cen"},[_c('div',{staticClass:"lf",style:([
              {
                background: _vm.infoList[_vm.commonLet9].RESET ? 'red' : '#2AFC30',
              },
              {
                color: _vm.infoList[_vm.commonLet9].RESET ? '#fff' : '',
              } ]),on:{"click":function($event){return _vm.toIpt(_vm.infoList[_vm.commonLet9].RESET, '按钮', 'RESET', _vm.infoList[_vm.commonLet9+'_node_string'])}}},[_vm._v(" "+_vm._s(_vm.infoList[_vm.commonLet9].RESET ? "强制优化" : "自动优化")+" ")]),_c('div',{staticClass:"ma-auto"},[_c('div',{staticClass:"dpr"},[_c('div',{staticClass:"npr",style:({
                  width: _vm.infoList[_vm.commonLet10].PV + '%',
                })})])])])]),_c('div',{staticClass:"boxbtnxz",on:{"click":function($event){return _vm.toIpt(
            _vm.infoList['BCSYH_SHM1__p__BCSYHn__ll__' + _vm.datatype + '__rr__']
              .BCSYHQY,
            '按钮',
            'BCSYHQY',
            _vm.infoList['BCSYH_SHM1__p__BCSYHn__ll__' + _vm.datatype + '__rr___node_string']
          )}}},[_vm._v(" "+_vm._s(_vm.infoList["BCSYH_SHM1__p__BCSYHn__ll__" + _vm.datatype + "__rr__"] .BCSYHQY ? "投用" : "切除")+" ")]),_c('div',{staticClass:"bot-box2"},[_c('div',{staticClass:"flex align-cen"},[_vm._v(" A07-DETMQH "),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4","cursor":"pointer"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['DETMQH' + _vm.datatype + '1'],
                '修改',
                'DETMQH' + _vm.datatype + '1',
                _vm.infoList['DETMQH' + _vm.datatype + '1_node_string']
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["DETMQH" + _vm.datatype + "1"])+" ")]),_c('div',{staticClass:"ma-auto"})]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A07-DETT "),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4","cursor":"pointer"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['DETT' + _vm.datatype + '1'],
                '修改',
                'DETT' + _vm.datatype + '1',
                _vm.infoList['DETT' + _vm.datatype + '1_node_string']
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["DETT" + _vm.datatype + "1"])+" ")]),_c('div',{staticClass:"ma-auto"},[_vm._v("S")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A07-WAITT "),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4","cursor":"pointer"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['WAITT' + _vm.datatype + '1'],
                '修改',
                'WAITT' + _vm.datatype + '1',
                _vm.infoList['WAITT' + _vm.datatype + '1_node_string']
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["WAITT" + _vm.datatype + "1"])+" ")]),_c('div',{staticClass:"ma-auto"},[_vm._v("S")])])]),_c('div',{staticClass:"bot-box3 flex align-cen"},[_vm._v(" A09-FTMQ "),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
              2,
              'FTMQ_B' + _vm.datatype + '1',
              _vm.infoList['FTMQ_B' + _vm.datatype + '1_node_string'],
              'FTMQ_B' + _vm.datatype + '1_J1',
              '参与优化煤气量'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList['FTMQ_B' + _vm.datatype + '1'],
              'FTMQ_B' + _vm.datatype + '1',
              _vm.infoList['FTMQ_B' + _vm.datatype + '1_node_string'],
              'FTMQ_B' + _vm.datatype + '1_J1'
            )}}},[_vm._v(" "+_vm._s(_vm.infoList["FTMQ_B" + _vm.datatype + "1"])+" ")])]),_c('div',{staticClass:"bot-box4"},[_c('div',{staticClass:"flex align-cen"},[_vm._v(" A08-FMBH "),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['JBFMBH_B' + _vm.datatype + '1'],
                '修改',
                'JBFMBH_B' + _vm.datatype + '1',
                _vm.infoList['JBFMBH_B' + _vm.datatype + '1_node_string']
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["JBFMBH_B" + _vm.datatype + "1"])+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A08-FMBL "),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['JBFMBL_B' + _vm.datatype + '1'],
                '修改',
                'JBFMBL_B' + _vm.datatype + '1',
                _vm.infoList['JBFMBL_B' + _vm.datatype + '1_node_string']
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["JBFMBL_B" + _vm.datatype + "1"])+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A08-YHKRB "),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'YHFMB_B' + _vm.datatype + '1',
                _vm.infoList['YHFMB_B' + _vm.datatype + '1_node_string'],
                'YHFMB_B' + _vm.datatype + '1_J1',
                '优化风煤比'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['YHFMB_B' + _vm.datatype + '1'],
                'YHFMB_B' + _vm.datatype + '1',
                _vm.infoList['YHFMB_B' + _vm.datatype + '1_node_string'],
                'YHFMB_B' + _vm.datatype + '1_J1'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["YHFMB_B" + _vm.datatype + "1"])+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A08-JBFMB "),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['JBFMB_B' + _vm.datatype + '1'],
                '修改',
                'JBFMB_B' + _vm.datatype + '1',
                _vm.infoList['JBFMB_B' + _vm.datatype + '1'+'_node_string']
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["JBFMB_B" + _vm.datatype + "1"])+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A08-SJFMB "),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'SJFMB_B' + _vm.datatype + '1',
                _vm.infoList['SJFMB_B' + _vm.datatype + '1'+'_node_string'],
                'SJFMB_B' + _vm.datatype + '1_J1',
                '实际风煤比'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['SJFMB_B' + _vm.datatype + '1'],
                'SJFMB_B' + _vm.datatype + '1',
                _vm.infoList['SJFMB_B' + _vm.datatype + '1'+'_node_string'],
                'SJFMB_B' + _vm.datatype + '1_J1'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["SJFMB_B" + _vm.datatype + "1"])+" ")])])]),_c('div',{staticClass:"bot-box5"},[_c('div',{staticClass:"flex align-cen"},[_vm._v(" A10-FKQ "),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'FTKQ_B' + _vm.datatype + '1',
                _vm.infoList['FTKQ_B' + _vm.datatype + '1_node_string'],
                'FTKQ_B' + _vm.datatype + '1_J1',
                '参与优化空气量'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['FTKQ_B' + _vm.datatype + '1'],
                'FTKQ_B' + _vm.datatype + '1',
                _vm.infoList['FTKQ_B' + _vm.datatype + '1_node_string'],
                'FTKQ_B' + _vm.datatype + '1_J1'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["FTKQ_B" + _vm.datatype + "1"])+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A10-KQTC "),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['FK' + _vm.datatype + '1TC'],
                '修改',
                'FK' + _vm.datatype + '1TC',
                _vm.infoList['FK' + _vm.datatype + '1TC_node_string']
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["FK" + _vm.datatype + "1TC"])+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" "+_vm._s(_vm.infoList["FFQH2_B" + _vm.datatype + "1"] ? "软测量" : "实际测量")+" "),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['KQS_B' + _vm.datatype + '1'],
                '修改',
                'KQS_B' + _vm.datatype + '1',
                _vm.infoList['KQS_B' + _vm.datatype + '1_node_string']
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["KQS_B" + _vm.datatype + "1"])+" ")])])]),_c('div',{staticClass:"bot-box3 bot-box6 flex align-cen"},[_vm._v(" A10-KQSP "),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
              2,
              'KQ' + _vm.datatype + '1SP',
              _vm.infoList['KQ' + _vm.datatype + '1SP_node_string'],
              'KQ' + _vm.datatype + '1SP' + '_J1'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList['KQ' + _vm.datatype + '1SP'],
              'KQ' + _vm.datatype + '1SP',
              _vm.infoList['KQ' + _vm.datatype + '1SP_node_string'],
              'KQ' + _vm.datatype + '1SP' + '1_J1'
            )}}},[_vm._v(" "+_vm._s(_vm.infoList["KQ" + _vm.datatype + "1SP"])+" ")])]),_c('div',{staticClass:"bot-box5 bot-box7"},[_c('div',{staticClass:"flex align-cen"},[_vm._v(" A10-AV "),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'KX_1' + _vm.commonLet11 + '_B',
                _vm.infoList['KX_1' + _vm.commonLet11 + '_B_node_string'],
                'KX_1' + _vm.commonLet11 + '_J1'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['KX_1' + _vm.commonLet11 + '_B'],
                'KX_1' + _vm.commonLet11 + '_B',
                _vm.infoList['KX_1' + _vm.commonLet11 + '_B_node_string'],
                'KX_1' + _vm.commonLet11 + '_J1'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["KX_1" + _vm.commonLet11 + "_B"])+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A10-AOH "),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['MAN' + _vm.commonLet12].OutT,
                '修改',
                'OutT',
                'MAN' + _vm.commonLet12
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["MAN" + _vm.commonLet12].OutT)+" ")])]),_c('div',{staticClass:"flex align-cen"},[_vm._v(" A10-AOL "),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['MAN' + _vm.commonLet12].OutB,
                '修改',
                'OutB',
                'MAN' + _vm.commonLet12
              )}}},[_vm._v(" "+_vm._s(_vm.infoList["MAN" + _vm.commonLet12].OutB)+" ")])])]),_c('div',{staticClass:"left-box1 left-box5 flex align-cen"},[_c('div',[_vm._v("上限防抖")]),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
              _vm.infoList['KQ' + _vm.datatype + '1_FDH'],
              '修改',
              'KQ' + _vm.datatype + '1_FDH',
              _vm.infoList['KQ' + _vm.datatype + '1_FDH' + '_node_string']
            )}}},[_vm._v(" "+_vm._s(_vm.infoList["KQ" + _vm.datatype + "1_FDH"])+" ")])]),_c('div',{staticClass:"child-dataX2 child-databtn",style:({
          background:
            _vm.infoList['XK' + _vm.commonLet13] &&
            _vm.infoList['XK' + _vm.commonLet13].RM == 1
              ? '#2AFC30'
              : 'red',
        }),on:{"click":function($event){_vm.infoList['XK' + _vm.commonLet13]
            ? _vm.toDetail(
                1,
                'XK' + _vm.commonLet13,
                _vm.infoList['XK' + _vm.commonLet13+'_node_string'],
                '',
                (_vm.datatype == 1
                  ? '一加'
                  : _vm.datatype == 2
                  ? '二加'
                  : _vm.datatype == 3
                  ? '三加'
                  : '均') + '热空气流量XK'
              )
            : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA1 child-databtn",style:({
          background:
            _vm.infoList['MAN' + _vm.commonLet12] &&
            _vm.infoList['MAN' + _vm.commonLet12].RM == 1
              ? '#2AFC30'
              : 'red',
        }),on:{"click":function($event){_vm.infoList['MAN' + _vm.commonLet12]
            ? _vm.toCompon(
                0,
                'AV',
                'MAN' + _vm.commonLet12,
                'MAN' + _vm.commonLet12 + '_AV_J1',
                (_vm.datatype == 1
                  ? '一'
                  : _vm.datatype == 2
                  ? '二'
                  : _vm.datatype == 3
                  ? '三'
                  : '均') +
                  '热段空气阀门手操器MAN' +
                  _vm.commonLet12
              )
            : ''}}},[_vm._v(" A ")])])]),_c('inputVal',{ref:"inputVal",on:{"getTreeData":function($event){return _vm.DataJson(arguments)}}}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.isFshow)?_c('Firstcontrol',{attrs:{"infoList":_vm.infoList,"isIndex":_vm.isIndex,"historyname":_vm.Firstcontrolname,"titname":_vm.Firstcontroltitname,"node":_vm.Firstcontrolnode},on:{"sendStatus":function($event){_vm.isFshow = false}}}):_vm._e(),(_vm.isRshow)?_c('Rsf',{attrs:{"titname":_vm.Rsftitname,"infoList":_vm.infoList,"historyname":_vm.Rsfname,"node":_vm.Rsfnode},on:{"sendStatus":function($event){_vm.isRshow = false}}}):_vm._e(),(_vm.isComShow)?_c('ParameterYh',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isshowfase}}):_vm._e(),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isMshow = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }