var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"rqltltx"},[_c('div',{staticClass:"bgmain"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+"脱硫脱硝系统")]),_c('div',{staticClass:"row1-main"},[_c('div',{staticClass:"leftmain fl"},[_c('div',{staticClass:"leftrow1"},[_vm._v("炉膛口")]),_vm._m(0),_c('div',{staticClass:"leftrow3"},[_vm._v("压缩空气")]),_c('div',{staticClass:"leftrow4"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx fl",style:({
                  background:
                    _vm.infoList.QK20 && !_vm.infoList.QK20.TS ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.QK20
                    ? _vm.toDetail(
                        2,
                        'QK20',
                        _vm.infoList.QK20_node_string,
                        '',
                        '燃料量-脱硝前馈'
                      )
                    : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"bottonx fl",style:({
                  background:
                    _vm.infoList.XK23 && !_vm.infoList.XK23.RM ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.XK23
                    ? _vm.toDetail(
                        1,
                        'XK23',
                        _vm.infoList.XK23_node_string,
                        '',
                        'NOx控制'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl",staticStyle:{"margin-left":"0.01vw"},style:({
                  background:
                    _vm.infoList.MAN30 && _vm.infoList.MAN30.RM == 1
                      ? '#2AFC30'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.MAN30
                    ? _vm.toCompon(
                        0,
                        'RM',
                        'MAN30',
                        'MAN30_AV_NG3',
                        '尿素溶液手操器',
                        'KF_TX_B',
                        _vm.infoList.KF_TX_B_node_string,
                        'KF_TX_NG3'
                      )
                    : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"down leftrow4jg",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.NOXSP,
                    'NOx设定点',
                    'NOXSP',
                    _vm.infoList.NOXSP_node_string
                  )}}},[_vm._v(" "+_vm._s("NOXSP" in _vm.infoList ? _vm.infoList.NOXSP : 0)+" ")]),_c('div',{staticClass:"leftrow4jg1",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'NOXSPSJ',
                    'MCSO2NOX',
                    'NOXSPSJ_NG3',
                    '氮氧化物实际控制点'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.NOXSPSJ,
                    'NOXSPSJ',
                    _vm.infoList.NOXSPSJ_node_string,
                    'NOXSPSJ_NG3'
                  )}}},[_vm._v(" "+_vm._s("NOXSPSJ" in _vm.infoList ? _vm.infoList.NOXSPSJ : 0)+" ")])]),_c('div',{staticClass:"fl leftrow4jg2"},[_c('div',{staticClass:"bottonx fl",style:({
                  background:
                    _vm.infoList.XK27 && !_vm.infoList.XK27.RM ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.XK27
                    ? _vm.toDetail(
                        1,
                        'XK27',
                        _vm.infoList.XK27_node_string,
                        '',
                        '化水除盐水流量控制'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl",staticStyle:{"margin-left":"0.01vw"},style:({
                  background:
                    _vm.infoList.MAN32 && _vm.infoList.MAN32.RM == 1
                      ? '#2AFC30'
                      : 'red',
                }),on:{"click":function($event){_vm.infoList.MAN32
                    ? _vm.toCompon(
                        0,
                        'RM',
                        'MAN32',
                        'MAN32_AV_NG3',
                        '脱硝除盐水手操器',
                        'KF_CYS_B',
                        _vm.infoList.KF_CYS_B_node_string,
                        'KF_CYS_NG3'
                      )
                    : ''}}},[_vm._v(" A ")])])]),_c('div',{staticClass:"leftrow5"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"leftrow5wd"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'F_NSRY_B',
                      _vm.infoList.F_NSRY_B_node_string,
                      'F_NSRY_NG3',
                      '尿素溶液流量'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.F_NSRY_B,
                      'F_NSRY_B',
                      _vm.infoList.F_NSRY_B_node_string,
                      'F_NSRY_NG3'
                    )}}},[_vm._v(" "+_vm._s("F_NSRY_B" in _vm.infoList ? _vm.infoList.F_NSRY_B : 0)+" t/h ")])]),_c('div',{staticClass:"leftrow5wd"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KF_TX_B',
                      _vm.infoList.KF_TX_B_node_string,
                      'KF_TX_NG3',
                      '炉内SNCR脱硝脱硝剂控制阀门反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.KF_TX_B,
                      'KF_TX_B',
                      _vm.infoList.KF_TX_B_node_string,
                      'KF_TX_NG3'
                    )}}},[_vm._v(" "+_vm._s("KF_TX_B" in _vm.infoList ? _vm.infoList.KF_TX_B : 0)+" % ")])])]),_c('div',{staticClass:"fl leftrow5jg"},[_c('div',{staticClass:"leftrow5wd"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'F_CYS_B',
                      _vm.infoList.F_CYS_B_node_string,
                      'F_CYS_NG3',
                      '化水除盐水流量'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.F_CYS_B,
                      'F_CYS_B',
                      _vm.infoList.F_CYS_B_node_string,
                      'F_CYS_NG3'
                    )}}},[_vm._v(" "+_vm._s("F_CYS_B" in _vm.infoList ? _vm.infoList.F_CYS_B : 0)+" t/h ")])]),_c('div',{staticClass:"leftrow5wd"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KF_CYS_B',
                      _vm.infoList.KF_CYS_B_node_string,
                      'KF_CYS_NG3',
                      '化水除盐水阀门反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.KF_CYS_B,
                      'KF_CYS_B',
                      _vm.infoList.KF_CYS_B_node_string,
                      'KF_CYS_NG3'
                    )}}},[_vm._v(" "+_vm._s("KF_CYS_B" in _vm.infoList ? _vm.infoList.KF_CYS_B : 0)+" % ")])])])]),_vm._m(1)]),_c('div',{staticClass:"centermain fl"},[_c('div',{staticClass:"cen-top"},[_c('div',{staticClass:"fl centerrow1"},[_c('div',{staticClass:"cen-co1"},[_vm._v("原烟气监测")]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("SO₂浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ASO2_Y_B',
                        _vm.infoList.ASO2_Y_B_node_string,
                        'ASO2_Y_NG3',
                        '锅炉出口SO2含量'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.ASO2_Y_B,
                        'ASO2_Y_B',
                        _vm.infoList.ASO2_Y_B_node_string,
                        'ASO2_Y_NG3'
                      )}}},[_vm._v(" "+_vm._s("ASO2_Y_B" in _vm.infoList ? _vm.infoList.ASO2_Y_B : 0)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("NOx浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ANOX_Y_B',
                        _vm.infoList.ANOX_Y_B_node_string,
                        'ANOX_Y_NG3',
                        '原烟气NOX浓度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.ANOX_Y_B,
                        'ANOX_Y_B',
                        _vm.infoList.ANOX_Y_B_node_string,
                        'ANOX_Y_NG3'
                      )}}},[_vm._v(" "+_vm._s("ANOX_Y_B" in _vm.infoList ? _vm.infoList.ANOX_Y_B : 0)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("O₂浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ATO2_Y_B',
                        _vm.infoList.ATO2_Y_B_node_string,
                        'ATO2_Y_NG3',
                        '原烟气含氧量'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.ATO2_Y_B,
                        'ATO2_Y_B',
                        _vm.infoList.ATO2_Y_B_node_string,
                        'ATO2_Y_NG3'
                      )}}},[_vm._v(" "+_vm._s("ATO2_Y_B" in _vm.infoList ? _vm.infoList.ATO2_Y_B : 0)+" % ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("DUST浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ATDUST_Y_B',
                        _vm.infoList.ATDUST_Y_B_node_string,
                        'ATDUST_Y_NG3',
                        '原烟气含尘量'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.ATDUST_Y_B,
                        'ATDUST_Y_B',
                        _vm.infoList.ATDUST_Y_B_node_string,
                        'ATDUST_Y_NG3'
                      )}}},[_vm._v(" "+_vm._s("ATDUST_Y_B" in _vm.infoList ? _vm.infoList.ATDUST_Y_B : 0)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("烟气温度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TYQ_Y_B',
                        _vm.infoList.TYQ_Y_B_node_string,
                        'TYQ_Y_NG3',
                        '原烟气烟气温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TYQ_Y_B,
                        'TYQ_Y_B',
                        _vm.infoList.TYQ_Y_B_node_string,
                        'TYQ_Y_NG3'
                      )}}},[_vm._v(" "+_vm._s("TYQ_Y_B" in _vm.infoList ? _vm.infoList.TYQ_Y_B : 0)+" ℃ ")])]),_c('div',{staticClass:"leftrow5wd"})])]),_c('div',{staticClass:"fl centerrow1"},[_c('div',{staticClass:"cen-co1"},[_vm._v("净烟气监测")]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("SO₂浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ASO2_B',
                        _vm.infoList.ASO2_B_node_string,
                        'ASO2_NG3',
                        'SO2浓度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.ASO2_B,
                        'ASO2_B',
                        _vm.infoList.ASO2_B_node_string,
                        'ASO2_NG3'
                      )}}},[_vm._v(" "+_vm._s("ASO2_B" in _vm.infoList ? _vm.infoList.ASO2_B : 0)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("NOx浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ANOX_B',
                        _vm.infoList.ANOX_B_node_string,
                        'ANOX_NG3',
                        'nox浓度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.ANOX_B,
                        'ANOX_B',
                        _vm.infoList.ANOX_B_node_string,
                        'ANOX_NG3'
                      )}}},[_vm._v(" "+_vm._s("ANOX_B" in _vm.infoList ? _vm.infoList.ANOX_B : 0)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("O₂浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ATO2_B',
                        _vm.infoList.ATO2_B_node_string,
                        'ATO2_NG3',
                        '净烟气烟气含氧量'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.ATO2_B,
                        'ATO2_B',
                        _vm.infoList.ATO2_B_node_string,
                        'ATO2_NG3'
                      )}}},[_vm._v(" "+_vm._s("ATO2_B" in _vm.infoList ? _vm.infoList.ATO2_B : 0)+" % ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("DUST浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'ATDUST_B',
                        _vm.infoList.ATDUST_B_node_string,
                        'ATDUST_NG3',
                        '净烟气烟尘浓度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.ATDUST_B,
                        'ATDUST_B',
                        _vm.infoList.ATDUST_B_node_string,
                        'ATDUST_NG3'
                      )}}},[_vm._v(" "+_vm._s("ATDUST_B" in _vm.infoList ? _vm.infoList.ATDUST_B : 0)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("烟气温度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'T_YQ_B',
                        _vm.infoList.T_YQ_B_node_string,
                        'T_YQ_NG3',
                        '净烟气烟气温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.T_YQ_B,
                        'T_YQ_B',
                        _vm.infoList.T_YQ_B_node_string,
                        'T_YQ_NG3'
                      )}}},[_vm._v(" "+_vm._s("T_YQ_B" in _vm.infoList ? _vm.infoList.T_YQ_B : 0)+" ℃ ")])]),_c('div',{staticClass:"leftrow5wd"})])])]),_c('div',{staticClass:"cen-lable"},[_vm._v("工艺水")]),_c('div',{staticClass:"cen-lable1"},[_vm._v("原烟气")]),_c('div',{staticClass:"cen-lable2"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                  2,
                  'TYQ_Y_B',
                  _vm.infoList.TYQ_Y_B_node_string,
                  'TYQ_Y_NG3',
                  '原烟气烟气温度'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.TYQ_Y_B,
                  'TYQ_Y_B',
                  _vm.infoList.TYQ_Y_B_node_string,
                  'TYQ_Y_NG3'
                )}}},[_vm._v(" "+_vm._s("TYQ_Y_B" in _vm.infoList ? _vm.infoList.TYQ_Y_B : 0)+" ℃ ")])]),_c('div',{staticClass:"cen-lable3"},[_vm._v("循环泵A")]),_c('div',{staticClass:"cen-lable3"},[_vm._v("循环泵B")]),_c('div',{staticClass:"cen-lable3"},[_vm._v("循环泵C")]),_c('div',{staticClass:"cen-lable3"},[_vm._v("循环泵D")])]),_c('div',{staticClass:"rightmain fl"},[_c('div',{staticClass:"rig-left fl"},[_c('div',{staticClass:"rig-lable1"},[_vm._v("脱硫塔")]),_c('div',{staticClass:"rig-lable1 rig-jge"},[_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'T_XST1_B',
                      _vm.infoList.T_XST1_B_node_string,
                      'T_XST1_NG3',
                      '吸收塔内温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.T_XST1_B,
                      'T_XST1_B',
                      _vm.infoList.T_XST1_B_node_string,
                      'T_XST1_NG3'
                    )}}},[_vm._v(" "+_vm._s("T_XST1_B" in _vm.infoList ? _vm.infoList.T_XST1_B : 0)+" ℃ ")])]),_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'T_XST2_B',
                      _vm.infoList.T_XST2_B_node_string,
                      'T_XST2_NG3',
                      '吸收塔内温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.T_XST2_B,
                      'T_XST2_B',
                      _vm.infoList.T_XST2_B_node_string,
                      'T_XST2_NG3'
                    )}}},[_vm._v(" "+_vm._s("T_XST2_B" in _vm.infoList ? _vm.infoList.T_XST2_B : 0)+" ℃ ")])])]),_c('div',{staticClass:"rig-lable1 rig-jge1"},[_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'LTJYC1_B',
                      _vm.infoList.LTJYC1_B_node_string,
                      'LTJYC1_NG3',
                      '吸收塔浆液池液位'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.LTJYC1_B,
                      'LTJYC1_B',
                      _vm.infoList.LTJYC1_B_node_string,
                      'LTJYC1_NG3'
                    )}}},[_vm._v(" "+_vm._s("LTJYC1_B" in _vm.infoList ? _vm.infoList.LTJYC1_B : 0)+" m ")])]),_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'LTJYC2_B',
                      _vm.infoList.LTJYC2_B_node_string,
                      'LTJYC2_NG3',
                      '吸收塔浆液池液位'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.LTJYC2_B,
                      'LTJYC2_B',
                      _vm.infoList.LTJYC2_B_node_string,
                      'LTJYC2_NG3'
                    )}}},[_vm._v(" "+_vm._s("LTJYC2_B" in _vm.infoList ? _vm.infoList.LTJYC2_B : 0)+" m ")])])]),_c('div',{staticClass:"rig-lable1 rig-jge2",staticStyle:{"margin-left":"0.5vw"}},[_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'DTJYC1_B',
                      _vm.infoList.DTJYC1_B_node_string,
                      'DTJYC1_NG3',
                      '吸收塔浆液池密度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.DTJYC1_B,
                      'DTJYC1_B',
                      _vm.infoList.DTJYC1_B_node_string,
                      'DTJYC1_NG3'
                    )}}},[_vm._v(" "+_vm._s("DTJYC1_B" in _vm.infoList ? _vm.infoList.DTJYC1_B : 0)+" g/m3 ")])]),_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'DTJYC2_B',
                      _vm.infoList.DTJYC2_B_node_string,
                      'DTJYC2_NG3',
                      '吸收塔浆液池密度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.DTJYC2_B,
                      'DTJYC2_B',
                      _vm.infoList.DTJYC2_B_node_string,
                      'DTJYC2_NG3'
                    )}}},[_vm._v(" "+_vm._s("DTJYC2_B" in _vm.infoList ? _vm.infoList.DTJYC2_B : 0)+" g/m3 ")])])]),_c('div',{staticClass:"rig-lable1 rig-jge2"},[_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'PH1_B',
                      _vm.infoList.PH1_B_node_string,
                      'PH1_NG3',
                      '吸收塔浆液池PH值'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.PH1_B,
                      'PH1_B',
                      _vm.infoList.PH1_B_node_string,
                      'PH1_NG3'
                    )}}},[_vm._v(" "+_vm._s("PH1_B" in _vm.infoList ? _vm.infoList.PH1_B : 0)+" ph ")])]),_c('div',{staticClass:"rig-row1 fl"},[_c('div',{on:{"click":function($event){return _vm.toCompon(2, 'PH2_B', _vm.infoList.PH2_B_node_string, 'PH2_B')},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.PH2_B,
                      'PH2_B',
                      _vm.infoList.PH2_B_node_string,
                      'PH2_B'
                    )}}},[_vm._v(" "+_vm._s("PH2_B" in _vm.infoList ? _vm.infoList.PH2_B : 0)+" ph ")])])]),_c('div',{staticClass:"rig-lable1 rig-jge2"},[_c('div',{staticClass:"rig-row1 fl"},[_c('div',{staticClass:"down",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.PH_H,
                      'PH值上限设定',
                      'PH_H',
                      _vm.infoList.PH_H_node_string
                    )}}},[_vm._v(" "+_vm._s("PH_H" in _vm.infoList ? _vm.infoList.PH_H : 0)+" ")])]),_c('div',{staticClass:"rig-row1 fl"},[_c('div',{staticClass:"down",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.PH_L,
                      'PH值下限设定',
                      'PH_L',
                      _vm.infoList.PH_L_node_string
                    )}}},[_vm._v(" "+_vm._s("PH_L" in _vm.infoList ? _vm.infoList.PH_L : 0)+" ")])])])]),_c('div',{staticClass:"rig-right fl"},[_c('div',{staticClass:"rig-lable1"},[_c('div',{staticClass:"rig-rightjg",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'T_YQ_B',
                    _vm.infoList.T_YQ_B_node_string,
                    'T_YQ_NG3',
                    '净烟气烟气温度'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.T_YQ_B,
                    'T_YQ_B',
                    _vm.infoList.T_YQ_B_node_string,
                    'T_YQ_NG3'
                  )}}},[_vm._v(" "+_vm._s("T_YQ_B" in _vm.infoList ? _vm.infoList.T_YQ_B : 0)+" ℃ ")])]),_c('div',{staticClass:"righ-lable2"},[_vm._v("除雾器冲洗水")]),_c('div',{staticClass:"righ-lable3"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"righ-col1"},[_c('div',{staticClass:"righ-col1-lb fl down",on:{"click":function($event){return _vm.toIpt(
                        _vm.infoList.SO2SP,
                        'SO2设定点',
                        'SO2SP',
                        _vm.infoList.SO2SP_node_string
                      )}}},[_vm._v(" "+_vm._s("SO2SP" in _vm.infoList ? _vm.infoList.SO2SP : 0)+" ")]),_c('div',{staticClass:"righ-col1-lb fl",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'SO2SPSJ',
                        _vm.infoList.SO2SPSJ_node_string,
                        'SO2SPSJ_NG3',
                        '二氧化硫实际控制点'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.SO2SPSJ,
                        'SO2SPSJ',
                        _vm.infoList.SO2SPSJ_node_string,
                        'SO2SPSJ_NG3'
                      )}}},[_vm._v(" "+_vm._s("SO2SPSJ" in _vm.infoList ? _vm.infoList.SO2SPSJ : 0)+" ")])]),_c('div',{staticClass:"righ-col1 righ-col1-jg"},[_c('div',{staticClass:"righ-col1-lb fl",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'F_SHJY_B',
                        'MCSOUREC',
                        'F_SHJY_NG3',
                        '石灰浆液流量'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.MCSOUREC.F_SHJY_B,
                        'F_SHJY_B',
                        'MCSOUREC',
                        'F_SHJY_NG3'
                      )}}},[_vm._v(" "+_vm._s("" in _vm.infoList ? _vm.infoList.MCSOUREC.F_SHJY_B : 0)+" m3/h ")]),_c('div',{staticClass:"righ-col1-lb righ-col1-jg2 fl",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'KF_TL_B',
                        _vm.infoList.KF_TL_B_node_string,
                        'KF_TL_NG3',
                        '脱硫石灰浆液流量控制阀门反馈'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.KF_TL_B,
                        'KF_TL_B',
                        _vm.infoList.KF_TL_B_node_string,
                        'KF_TL_NG3'
                      )}}},[_vm._v(" "+_vm._s("KF_TL_B" in _vm.infoList ? _vm.infoList.KF_TL_B : 0)+" % ")])]),_c('div',{staticClass:"righ-col1 righ-col1-jg3"},[_c('div',{staticClass:"bottonx fl jgxx",style:({
                      background:
                        _vm.infoList.QK21 && !_vm.infoList.QK21.TS
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.QK21
                        ? _vm.toDetail(
                            2,
                            'QK21',
                            _vm.infoList.QK21_node_string,
                            '',
                            '燃料量-脱硫前馈'
                          )
                        : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"bottonx fl",style:({
                      background:
                        _vm.infoList.QK22 && !_vm.infoList.QK22.TS
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.QK22
                        ? _vm.toDetail(
                            2,
                            'QK22',
                            _vm.infoList.QK22_node_string,
                            '',
                            '出口SO2-脱硫前馈'
                          )
                        : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"bottonx fl",style:({
                      background:
                        _vm.infoList.XK26 && !_vm.infoList.XK26.RM
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.XK26
                        ? _vm.toDetail(
                            1,
                            'XK26',
                            _vm.infoList.XK26_node_string,
                            '',
                            '吸收塔浆液池PH范围控制'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl",style:({
                      background:
                        _vm.infoList.XK25 && !_vm.infoList.XK25.RM
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.XK25
                        ? _vm.toDetail(1, 'XK25', _vm.infoList.XK25_node_string, '', 'SO2控制')
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl",style:({
                      background:
                        _vm.infoList.XK24 && !_vm.infoList.XK24.RM
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.XK24
                        ? _vm.toDetail(
                            1,
                            'XK24',
                            _vm.infoList.XK24_node_string,
                            '',
                            '石灰浆液流量控制'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl",style:({
                      background:
                        _vm.infoList.MAN31 &&
                        _vm.infoList.MAN31.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.MAN31
                        ? _vm.toCompon(
                            0,
                            'RM',
                            'MAN31',
                            _vm.infoList.MAN31_node_string,
                            '脱硫石灰浆液手操器',
                            'KF_TL_B',
                            _vm.infoList.KF_TL_B_node_string,
                            'KF_TL_NG3'
                          )
                        : ''}}},[_vm._v(" A ")])])]),_vm._m(2)]),_vm._m(3)])])])])]),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"pmark":_vm.Panualmark,"pnode":_vm.Panualnode,"pnm":_vm.Panualnodem,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.nodename,"Lkname":_vm.Aname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),_c('inputVal',{ref:"inputVal"}),(_vm.flag == 4 ? (_vm.isComShow = true) : (_vm.isComShow = false))?_c('RqCommonParameters',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.flag == 6 ? (_vm.isComShow = true) : (_vm.isComShow = false))?_c('RqSoftMeasurement',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.flag == 7 ? (_vm.isComShow = true) : (_vm.isComShow = false))?_c('RqOnlineService',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leftrow2"},[_c('div',{staticClass:"fl left-lable"},[_vm._v("1#尿素分配")]),_c('div',{staticClass:"fl left-lable leftjg"},[_vm._v("2#尿素分配")]),_c('div',{staticClass:"fl left-lable leftjg"},[_vm._v("3#尿素分配")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leftrow5"},[_c('div',{staticClass:"fl leftrow5jg3"},[_vm._v("尿素溶液")]),_c('div',{staticClass:"fl leftrow5jg2"},[_vm._v("化水除盐水")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fl jange"},[_c('div',{staticClass:"mag-top1"},[_vm._v("石灰石旋流站")]),_c('div',{staticClass:"mag-top2"},[_vm._v("事故浆液罐")]),_c('div',{staticClass:"mag-top3"},[_vm._v("集水坑")]),_c('div',{staticClass:"mag-top1"},[_vm._v("石灰石浆液")]),_c('div',{staticClass:"mag-top4"},[_vm._v("氧化空气")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"righ-lable4"},[_c('div',{staticClass:"righ-col21 fl"},[_c('div',{staticClass:"jianh"},[_vm._v("石膏排除泵A")]),_c('div',{staticClass:"jianh1"},[_vm._v("石膏排除泵B")])]),_c('div',{staticClass:"righ-col21 jianh3 fl"},[_c('div',{},[_vm._v("工艺水")]),_c('div',{staticClass:"jianh4"},[_vm._v("事故浆液罐")]),_c('div',{staticClass:"jianh4"},[_vm._v("石膏旋流站")])])])}]

export { render, staticRenderFns }