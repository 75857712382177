var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"jrl_kqzg drag",attrs:{"id":"Jrlkq"}},[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(_vm._s(_vm.projectData.project)+"_参数02")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})]),_c('div',{staticClass:"param02-tab"},[_c('div',{staticClass:"param11-tab-tit"},[_vm._v("空气总管压力控制参数")]),_c('div',{staticClass:"paeam02-list1 flex"},[_c('div',{staticClass:"paeam02-list1-left"},[_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("A01-TSP")]),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.PKQ_SP1,
                '空气总管压力设定值',
                'PKQ_SP1',
                _vm.infoList.PKQ_SP1_node_string
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.PKQ_SP1)+" ")])]),_c('div',{staticClass:"left-box1 left-box2 flex align-cen"},[_c('div',[_vm._v("A01-TPV")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'PIA_101_B',
                _vm.infoList.PIA_101_B_node_string,
                'PIA_101_J1',
                '均热段空气压力'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.PIA_101_B,
                'PIA_101_B',
                _vm.infoList.PIA_101_B_node_string,
                'PIA_101_J1'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.PIA_101_B)+" ")])]),_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("PKQTC1")]),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.PKQTC1,
                '空气总管压力1滤波系数',
                'PKQTC1',
                _vm.infoList.PKQTC1_node_string
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.PKQTC1)+" ")])])]),_c('div',{staticClass:"paeam02-list1-right"},[_c('div',{staticClass:"right-cen"},[_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("A02-SUMPV")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'PV',
                  _vm.infoList.QK6_node_string,
                  'QK6_PV_J1',
                  '鼓风机空气阀门前馈'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.QK6.PV,
                  'PV',
                  _vm.infoList.QK6_node_string,
                  'QK6_PV_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.QK6.PV)+" ")])]),_c('div',{staticClass:"child-dataK child-databtn",style:({
              background:
                _vm.infoList.QK6 && !_vm.infoList.QK6.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.QK6
                ? _vm.toDetail(
                    2,
                    'QK6',
                    _vm.infoList.QK6_node_string,
                    '',
                    '鼓风机空气阀门前馈'
                  )
                : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
              background:
                _vm.infoList.XK23 && _vm.infoList.XK23.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK23
                ? _vm.toDetail(
                    1,
                    'XK23',
                    _vm.infoList.XK23_node_string,
                    '',
                    '空气总管压力XK'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
              background:
                _vm.infoList.MAN16 &&
                _vm.infoList.MAN16.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN16
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN16',
                    'MAN16_AV_J1',
                    '均热段鼓风机手操器MAN16'
                  )
                : ''}}},[_vm._v(" A ")])])])]),_c('div',{staticClass:"paeam02-list1 flex paeam02-list2"},[_c('div',{staticClass:"paeam02-list1-left"},[_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("A01-TSP")]),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(_vm.infoList.PKQ_SP2, '修改', 'PKQ_SP2', _vm.infoList.PKQ_SP2_node_string)}}},[_vm._v(" "+_vm._s(_vm.infoList.PKQ_SP2)+" ")])]),_c('div',{staticClass:"left-box1 left-box2 flex align-cen"},[_c('div',[_vm._v("A01-TPV")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'PIA_102_B',
                _vm.infoList.PIA_102_B_node_string,
                'PIA_102_J1',
                '三加热段压力'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.PIA_102_B,
                'PIA_102_B',
                _vm.infoList.PIA_102_B_node_string,
                'PIA_102_J1'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.PIA_102_B)+" ")])]),_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("PKQTC2")]),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.PKQTC2,
                '空气总管压力2滤波系数',
                'PKQTC2',
                _vm.infoList.PKQTC2_node_string
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.PKQTC2)+" ")])])]),_c('div',{staticClass:"paeam02-list1-right"},[_c('div',{staticClass:"right-cen"},[_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("A02-SUMPV")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'PV',
                  _vm.infoList.QK6_node_string,
                  'QK6_PV_J1',
                  '鼓风机空气阀门前馈'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.QK6.PV,
                  'PV',
                  _vm.infoList.QK6_node_string,
                  'QK6_PV_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.QK6.PV)+" ")])]),_c('div',{staticClass:"child-dataK child-databtn",style:({
              background:
                _vm.infoList.QK6 && !_vm.infoList.QK6.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.QK6
                ? _vm.toDetail(
                    2,
                    'QK6',
                    _vm.infoList.QK6_node_string,
                    '',
                    '鼓风机空气阀门前馈'
                  )
                : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
              background:
                _vm.infoList.XK24 && _vm.infoList.XK24.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK24
                ? _vm.toDetail(
                    1,
                    'XK24',
                    _vm.infoList.XK24_node_string,
                    '',
                    '空气总管压力XK'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
              background:
                _vm.infoList.MAN17 &&
                _vm.infoList.MAN17.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN17
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN17',
                    'MAN17_AV_J1',
                    '三加热段鼓风机手操器MAN17'
                  )
                : ''}}},[_vm._v(" A ")])])])]),_c('div',{staticClass:"paeam02-list1 flex paeam02-list2"},[_c('div',{staticClass:"paeam02-list1-left"},[_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("A01-TSP")]),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(_vm.infoList.PKQ_SP3, '修改', 'PKQ_SP3', _vm.infoList.PKQ_SP3_node_string)}}},[_vm._v(" "+_vm._s(_vm.infoList.PKQ_SP3)+" ")])]),_c('div',{staticClass:"left-box1 left-box2 flex align-cen"},[_c('div',[_vm._v("A01-TPV")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'PIA_103_B',
                _vm.infoList.PIA_103_B_node_string,
                'PIA_103_J1',
                '二加热段空气压力'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.PIA_103_B,
                'PIA_103_B',
                _vm.infoList.PIA_103_B_node_string,
                'PIA_103_J1'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.PIA_103_B)+" ")])]),_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("PKQTC3")]),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.PKQTC3,
                '空气总管压力3滤波系数',
                'PKQTC3',
                _vm.infoList.PKQTC3_node_string
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.PKQTC3)+" ")])])]),_c('div',{staticClass:"paeam02-list1-right"},[_c('div',{staticClass:"right-cen"},[_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("A02-SUMPV")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'PV',
                  _vm.infoList.QK6_node_string,
                  'QK6_PV_J1',
                  '鼓风机空气阀门前馈'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.QK6.PV,
                  'PV',
                  _vm.infoList.QK6_node_string,
                  'QK6_PV_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.QK6.PV)+" ")])]),_c('div',{staticClass:"child-dataK child-databtn",style:({
              background:
                _vm.infoList.QK6 && !_vm.infoList.QK6.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.QK6
                ? _vm.toDetail(
                    2,
                    'QK6',
                    _vm.infoList.QK6_node_string,
                    '',
                    '鼓风机空气阀门前馈'
                  )
                : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
              background:
                _vm.infoList.XK25 && _vm.infoList.XK25.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK25
                ? _vm.toDetail(
                    1,
                    'XK25',
                    _vm.infoList.XK25_node_string,
                    '',
                    '空气总管压力XK'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
              background:
                _vm.infoList.MAN18 &&
                _vm.infoList.MAN18.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN18
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN18',
                    'MAN18_AV_J1',
                    '二热段鼓风机手操器MAN18'
                  )
                : ''}}},[_vm._v(" A ")])])])]),_c('div',{staticClass:"paeam02-list1 flex paeam02-list2"},[_c('div',{staticClass:"paeam02-list1-left"},[_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("A01-TSP")]),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(_vm.infoList.PKQ_SP4, '修改', 'PKQ_SP4', _vm.infoList.PKQ_SP4_node_string)}}},[_vm._v(" "+_vm._s(_vm.infoList.PKQ_SP4)+" ")])]),_c('div',{staticClass:"left-box1 left-box2 flex align-cen"},[_c('div',[_vm._v("A01-TPV")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(2, 'PIA_104_B', _vm.infoList.PIA_104_B_node_string, 'PIA_104_B_J1')},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.PIA_104_B,
                'PIA_104_B',
                _vm.infoList.PIA_104_B_node_string,
                'PIA_104_J1'
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.PIA_104_B)+" ")])]),_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("PKQTC4")]),_c('div',{staticClass:"ma-auto",staticStyle:{"color":"#00ffb4"},on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.PKQTC4,
                '空气总管压力4滤波系数',
                'PKQTC4',
                _vm.infoList.PKQTC4_node_string
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.PKQTC4)+" ")])])]),_c('div',{staticClass:"paeam02-list1-right"},[_c('div',{staticClass:"right-cen"},[_c('div',{staticClass:"left-box1 flex align-cen"},[_c('div',[_vm._v("A02-SUMPV")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'PV',
                  _vm.infoList.QK6_node_string,
                  'QK6_PV_J1',
                  '鼓风机空气阀门前馈'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.QK6.PV,
                  'PV',
                  _vm.infoList.QK6_node_string,
                  'QK6_PV_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.QK6.PV)+" ")])]),_c('div',{staticClass:"child-dataK child-databtn",style:({
              background:
                _vm.infoList.QK6 && !_vm.infoList.QK6.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.QK6
                ? _vm.toDetail(
                    2,
                    'QK6',
                    _vm.infoList.QK6_node_string,
                    '',
                    '鼓风机空气阀门前馈'
                  )
                : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
              background:
                _vm.infoList.XK26 && _vm.infoList.XK26.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK26
                ? _vm.toDetail(
                    1,
                    'XK26',
                    _vm.infoList.XK26_node_string,
                    '',
                    '空气总管压力XK'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
              background:
                _vm.infoList.MAN19 &&
                _vm.infoList.MAN19.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN19
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN19',
                    'MAN19_AV_J1',
                    '一热段鼓风机手操器MAN19'
                  )
                : ''}}},[_vm._v(" A ")])])])])]),_c('inputVal',{ref:"inputVal"}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.isFshow)?_c('Firstcontrol',{attrs:{"infoList":_vm.infoList,"isIndex":_vm.isIndex,"historyname":_vm.Firstcontrolname,"titname":_vm.Firstcontroltitname,"node":_vm.Firstcontrolnode},on:{"sendStatus":function($event){_vm.isFshow = false}}}):_vm._e(),(_vm.isRshow)?_c('Rsf',{attrs:{"titname":_vm.Rsftitname,"infoList":_vm.infoList,"historyname":_vm.Rsfname,"node":_vm.Rsfnode},on:{"sendStatus":function($event){_vm.isRshow = false}}}):_vm._e(),(_vm.isComShow)?_c('ParameterYh',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isshowfase}}):_vm._e(),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isMshow = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }