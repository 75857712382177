<template>
  <div
    class="Jrindex"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="系统正在初始化"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.6)"
    style="height: 100%"
  >
    <div class="jrindex-conitaner" :style="scaleHeight" ref="bigbox">
      <div class="maskbok" v-if="maskbok"></div>
      <div class="title">
        {{ projectData.project }}
        <!-- 加热炉燃烧优化控制系统 -->
      </div>
      <div class="Jrindex-tit">
        <div class="Jrindex-tit-top flex">
          <div class="text-row flex">
            <div>煤气总流量</div>
            <div
              class="ma-auto"
              @click="
                toCompon(
                  2,
                  'FICA_117_B',
                  infoList.FICA_117_B_node_string,
                  'FICA_117_J1',
                  '煤气总管流量'
                )
              "
              @dblclick="
                Cclick(
                  infoList.FICA_117_B,
                  'FICA_117_B',
                  infoList.FICA_117_B_node_string,
                  'FICA_117_J1'
                )
              "
            >
              {{ 'FICA_117_B' in infoList?infoList.FICA_117_B :0}} m³/h
            </div>
          </div>
          <div class="text-row flex">
            <div>入炉钢坯数量(PLC)</div>
            <div
              class="ma-auto"
              @click="
                toCompon(
                  2,
                  'CGNO_B',
                  infoList.CGNO_B_node_string,
                  'GPRLNUM_J1',
                  '入炉板坯数量'
                )
              "
              @dblclick="
                Cclick(
                  infoList.CGNO_B,
                  'CGNO_B',
                  infoList.CGNO_B_node_string,
                  'GPRLNUM_J1'
                )
              "
            >
              {{ 'CGNO_B' in infoList?infoList.CGNO_B :0}} 根
            </div>
          </div>
          <div class="text-row flex">
            <div>入炉钢坯数量(BCS)</div>
            <div
              class="ma-auto"
              @click="
                toCompon(
                  2,
                  'KHGGLJB',
                  infoList.KHGGLJB_node_string,
                  'KHGGLJB_J1',
                  '过钢量班累积'
                )
              "
              @dblclick="
                Cclick(
                  infoList.KHGGLJB,
                  'KHGGLJB',
                  infoList.KHGGLJB_node_string,
                  'KHGGLJB_J1'
                )
              "
            >
              {{ 'KHGGLJB' in infoList?infoList.KHGGLJB :0}} 根
            </div>
          </div>
          <div class="text-row flex">
            <div>入炉钢温</div>
            <div
              class="ma-auto"
              @click="
                toCompon(
                  2,
                  'RGT_B',
                  infoList.RGT_B_node_string,
                  'RGT_J1',
                  '入钢温度'
                )
              "
              @dblclick="
                Cclick(
                  infoList.RGT_B,
                  'RGT_B',
                  infoList.RGT_B_node_string,
                  'RGT_J1'
                )
              "
            >
              {{ 'RGT_B' in infoList?infoList.RGT_B :0}} ℃
            </div>
          </div>
          <div class="text-row flex">
            <div>开轧温度(PLC)</div>
            <div
              class="ma-auto"
              @click="
                toCompon(
                  2,
                  'CGT_B',
                  infoList.CGT_B_node_string,
                  'CGT_J1',
                  '开轧温度'
                )
              "
              @dblclick="
                Cclick(
                  infoList.CGT_B,
                  'CGT_B',
                  infoList.CGT_B_node_string,
                  'CGT_J1'
                )
              "
            >
              {{ 'CGT_B' in infoList?infoList.CGT_B :0}} ℃
            </div>
          </div>
          <div class="text-row flex align-cen">
            <div>开轧温度(BCS)</div>
            <div
              class="ma-auto"
              @click="
                toCompon(
                  2,
                  'CGT_B',
                  infoList.CGT_B_node_string,
                  'CGT_J1',
                  '开轧温度'
                )
              "
              @dblclick="
                Cclick(
                  infoList.CGT_B,
                  'CGT_B',
                  infoList.CGT_B_node_string,
                  'CGT_J1'
                )
              "
            >
              {{ 'CGT_B' in infoList?infoList.CGT_B :0}} ℃
            </div>
            <div
              class="child-dataR child-databtn ma-auto"
              :style="{
                background:
                  infoList.RSF2 && !infoList.RSF2.TS ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.RSF2
                  ? toDetail(
                      3,
                      'RSF2',
                      infoList.RSF2_node_string,
                      '',
                      '出钢温度软伺服'
                    )
                  : ''
              "
            >
              R
            </div>
          </div>
        </div>
        <div class="Jrindex-tit-bot">
          <span @click="toCompon(6)">钢损模型</span>
          <span
            @click="
              toCompon(
                2,
                'GSM',
                infoList.GSMX1_node_string,
                'GSMX_GSM_J1',
                '钢损模型氧化烧损量'
              )
            "
            @dblclick="
              Cclick(
                infoList.GSMX1.GSM,
                'GSM',
                infoList.GSMX1_node_string,
                'GSMX_GSM_J1'
              )
            "
            >{{ 'GSMX1' in infoList?infoList.GSMX1.GSM :0}}%</span
          >
        </div>
        <!-- <div class="canshu canshu1 fl" @click="qsGroup()">趋势组图</div> -->
        <!-- <div class="canshu canshu2 fl" @click="qsGroup1()">常用设置</div> -->
      </div>
      <div class="el-container-box" :style="conHeight">
        <div class="el-container-box-cen">
          <!-- 第一部分 -->
          <div class="el-container-box-cen-coulm1">
            <div class="coulm1-top flex align-cen">
              <div
                class="child-dataX child-databtn"
                :style="{
                  background:
                    infoList.MAN13 && infoList.MAN13.RM == 1
                      ? '#2AFC30'
                      : 'red',
                }"
                @click="
                  infoList.MAN13
                    ? toCompon(
                        0,
                        'AV',
                        'MAN13',
                        'MAN13_AV_J1',
                        '空烟气总管阀BCS手操器MAN13'
                      )
                    : ''
                "
              >
                A
              </div>
              <img
                src="../../assets/images/rts_jrlimg.png"
                alt=""
                class="jrlimg"
                @click="toCompon(3)"
                style="cursor: pointer"
              />
              <div class="line"></div>
              <img
                src="../../assets/images/rts_jrlimg1.png"
                alt=""
                class="jflimg1"
              />
              <div class="jrl-row flex">
                <div class="line1"></div>
                <div class="fam1">
                  <img
                    src="../../assets/images/jflimg2.png"
                    alt=""
                    class="jflimg2"
                  />
                  <p
                    @click="
                      toCompon(
                        2,
                        'KF_113_B',
                        infoList.KF_113_B_node_string,
                        'KF_113_J1',
                        '空烟气总管阀反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_113_B,
                        'KF_113_B',
                        infoList.KF_113_B_node_string,
                        'KF_113_J1'
                      )
                    "
                  >
                    {{ 'KF_113_B' in infoList?infoList.KF_113_B :0}}%
                  </p>
                </div>
                <div class="jrl_auto flex">
                  <div class="list-auto1 flex">
                    <div class="text1">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.YJRKPTSP,
                            '修改',
                            'YJRKPTSP',
                            infoList.YJRKPTSP_node_string
                          )
                        "
                      >
                        {{ 'YJRKPTSP' in infoList?infoList.YJRKPTSP :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T42PV1',
                            infoList.T42PV1_node_string,
                            'T42PV1_J1',
                            '一加热空烟温度处理值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.T42PV1,
                            'T42PV1',
                            infoList.T42PV1_node_string,
                            'T42PV1_J1'
                          )
                        "
                      >
                        {{ 'T42PV1' in infoList?infoList.T42PV1 :0}}℃
                      </div>
                    </div>
                    <div class="text1">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.YJRMPTSP,
                            '修改',
                            'YJRMPTSP',
                            infoList.YJRMPTSP_node_string
                          )
                        "
                      >
                        {{ 'YJRMPTSP' in infoList?infoList.YJRMPTSP :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T42PV2',
                            infoList.T42PV2_node_string,
                            'T42PV2_J1',
                            '一加热段煤烟温度处理值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.T42PV2,
                            'T42PV2',
                            infoList.T42PV2_node_string,
                            'T42PV2_J1'
                          )
                        "
                      >
                        {{ 'T42PV2' in infoList?infoList.T42PV2 :0}}℃
                      </div>
                    </div>
                    <div class="text2 flex align-cen">
                      <div class="text">烟气含氧量</div>
                      <div
                        style="color: rgb(0, 180, 255); padding-left: 2vw"
                        @click="
                          toIpt(
                            infoList.O2SP,
                            '修改',
                            'O2SP',
                            infoList.O2SP_node_string
                          )
                        "
                      >
                        {{ 'O2SP' in infoList?infoList.O2SP :0}}%
                      </div>
                      <div
                        style="color: #d9feff; padding-left: 1vw"
                        @click="
                          toCompon(
                            2,
                            'CO2_B',
                            infoList.CO2_B_node_string,
                            'CO2_J1',
                            '烟气含氧量'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.CO2_B,
                            'CO2_B',
                            infoList.CO2_B_node_string,
                            'CO2_J1'
                          )
                        "
                      >
                        {{ 'CO2_B' in infoList?infoList.CO2_B :0}}%
                      </div>
                      <div
                        class="child-dataR child-databtn"
                        :style="{
                          background:
                            infoList.RSF5 && !infoList.RSF5.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.RSF5
                            ? toDetail(
                                3,
                                'RSF5',
                                infoList.RSF5_node_string,
                                '',
                                '备用'
                              )
                            : ''
                        "
                      >
                        R
                      </div>
                    </div>
                  </div>
                  <div class="list-auto1 flex">
                    <div class="text1">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.EJRKPTSP,
                            '修改',
                            'EJRKPTSP',
                            infoList.EJRKPTSP_node_string
                          )
                        "
                      >
                        {{ 'EJRKPTSP' in infoList?infoList.EJRKPTSP :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T32PV1',
                            infoList.T32PV1_node_string,
                            'T32PV1_J1',
                            '二加热段空烟温度处理值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.T32PV1,
                            'T32PV1',
                            infoList.T32PV1_node_string,
                            'T32PV1_J1'
                          )
                        "
                      >
                        {{ 'T32PV1' in infoList?infoList.T32PV1 :0}}℃
                      </div>
                    </div>
                    <div class="text1">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.EJRMPTSP,
                            '修改',
                            'EJRMPTSP',
                            infoList.EJRMPTSP_node_string
                          )
                        "
                      >
                        {{ 'EJRMPTSP' in infoList?infoList.EJRMPTSP :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T32PV2',
                            infoList.T32PV2_node_string,
                            'T32PV2_J1',
                            '二加热段煤烟温度处理值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.T32PV2,
                            'T32PV2',
                            infoList.T32PV2_node_string,
                            'T32PV2_J1'
                          )
                        "
                      >
                        {{ 'T32PV2' in infoList?infoList.T32PV2 :0}}℃
                      </div>
                    </div>
                  </div>
                  <div class="list-auto1 flex">
                    <div class="text1">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.SJRKPTSP,
                            '修改',
                            'SJRKPTSP',
                            infoList.SJRKPTSP_node_string
                          )
                        "
                      >
                        {{ 'SJRKPTSP' in infoList?infoList.SJRKPTSP :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T22PV1',
                            infoList.T22PV1_node_string,
                            'T22PV1_J1',
                            '三加热段空烟温度处理值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.T22PV1,
                            'T22PV1',
                            infoList.T22PV1_node_string,
                            'T22PV1_J1'
                          )
                        "
                      >
                        {{ 'T22PV1' in infoList?infoList.T22PV1 :0}}℃
                      </div>
                    </div>
                    <div class="text1">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.SJRMPTSP,
                            '修改',
                            'SJRMPTSP',
                            infoList.SJRMPTSP_node_string
                          )
                        "
                      >
                        {{ 'SJRMPTSP' in infoList?infoList.SJRMPTSP :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T22PV2',
                            infoList.T22PV2_node_string,
                            'T22PV2_J1',
                            '三加热段煤烟温度处理值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.T22PV2,
                            'T22PV2',
                            infoList.T22PV2_node_string,
                            'T22PV2_J1'
                          )
                        "
                      >
                        {{ 'T22PV2' in infoList?infoList.T22PV2 :0}}℃
                      </div>
                    </div>
                    <div class="text2 th-text2 flex align-cen">
                      <div
                        style="
                          color: rgb(0, 180, 255);
                          position: absolute;
                          left: -6vw;
                        "
                        @click="
                          toIpt(
                            infoList.FYSP_L,
                            '修改',
                            'FYSP_L',
                            infoList.FYSP_L_node_string
                          )
                        "
                      >
                        {{ 'FYSP_L' in infoList?infoList.FYSP_L :0}}Pa
                      </div>
                      <div
                        class="th-child-dataR child-databtn"
                        :style="{
                          background:
                            infoList.RSF3 && !infoList.RSF3.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.RSF3
                            ? toDetail(
                                3,
                                'RSF3',
                                infoList.RSF3_node_string,
                                '',
                                '负压空废煤废温度补偿'
                              )
                            : ''
                        "
                      >
                        R
                      </div>
                      <div class="th-text" @click="toCompon(10)">空烟管道</div>
                      <div class="my-text th-text" @click="toCompon(10)">
                        煤烟管道
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="thfamright flex">
                <div class="text1">
                  <div
                    style="color: rgb(0, 180, 255)"
                    @click="
                      toIpt(
                        infoList.JRDKPTSP,
                        '修改',
                        'JRDKPTSP',
                        infoList.JRDKPTSP_node_string
                      )
                    "
                  >
                    {{ 'JRDKPTSP' in infoList?infoList.JRDKPTSP :0}}℃
                  </div>
                  <div
                    @click="
                      toCompon(
                        2,
                        'T12PV1',
                        infoList.T12PV1_node_string,
                        'T12PV1_J1',
                        '均热段空烟温度处理值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.T12PV1,
                        'T12PV1',
                        infoList.T12PV1_node_string,
                        'T12PV1_J1'
                      )
                    "
                  >
                    {{ 'T12PV1' in infoList?infoList.T12PV1 :0}}℃
                  </div>
                </div>
                <div class="text1">
                  <div
                    style="color: rgb(0, 180, 255)"
                    @click="
                      toIpt(
                        infoList.JRDMPTSP,
                        '修改',
                        'JRDMPTSP',
                        infoList.JRDMPTSP_node_string
                      )
                    "
                  >
                    {{ 'JRDMPTSP' in infoList?infoList.JRDMPTSP :0}}℃
                  </div>
                  <div
                    @click="
                      toCompon(
                        2,
                        'T12PV2',
                        infoList.T12PV2_node_string,
                        'T12PV2_J1',
                        '均热段煤烟温度处理值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.T12PV2,
                        'T12PV2',
                        infoList.T12PV2_node_string,
                        'T12PV2_J1'
                      )
                    "
                  >
                    {{ 'T12PV2' in infoList?infoList.T12PV2 :0}}℃
                  </div>
                </div>
              </div>
            </div>
            <div class="coulm1-cen flex align-cen">
              <div
                class="child-dataX child-databtn"
                :style="{
                  background:
                    infoList.XK13 && infoList.XK13.RM == 1 ? '#2AFC30' : 'red',
                }"
                @click="
                  infoList.XK13
                    ? toDetail(
                        1,
                        'XK13',
                        infoList.XK13_node_string,
                        '',
                        '炉膛压力控制XK'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="child-dataR child-databtn"
                :style="{
                  background:
                    infoList.RSF1 && !infoList.RSF1.TS ? '#2AFC30' : 'red',
                }"
                @click="
                  infoList.RSF1
                    ? toDetail(
                        3,
                        'RSF1',
                        infoList.RSF1_node_string,
                        '',
                        '空烟煤协调软伺服'
                      )
                    : ''
                "
              >
                R
              </div>
              <div
                class="child-dataK child-databtn"
                :style="{
                  background:
                    infoList.QK1 && !infoList.QK1.TS ? '#2AFC30' : 'red',
                }"
                @click="
                  infoList.QK1
                    ? toDetail(
                        2,
                        'QK1',
                        infoList.QK1_node_string,
                        '',
                        '空气阀门前馈'
                      )
                    : ''
                "
              >
                K
              </div>
              <div
                class="child-dataK child-databtn"
                :style="{
                  background:
                    infoList.QK3 && !infoList.QK3.TS ? '#2AFC30' : 'red',
                }"
                @click="
                  infoList.QK3
                    ? toDetail(
                        2,
                        'QK3',
                        infoList.QK3_node_string,
                        '',
                        '煤气阀门前馈'
                      )
                    : ''
                "
              >
                K
              </div>
            </div>
            <div class="coulm1-bot flex align-cen">
              <div
                class="child-dataX child-databtn"
                :style="{
                  background:
                    infoList.MAN14 && infoList.MAN14.RM == 1
                      ? '#2AFC30'
                      : 'red',
                }"
                @click="
                  infoList.MAN14
                    ? toCompon(
                        0,
                        'AV',
                        'MAN14',
                        'MAN14_AV_J1',
                        '煤烟气总管阀BCS手操器MAN14'
                      )
                    : ''
                "
              >
                A
              </div>
              <img
                src="../../assets/images/rts_jrlimg.png"
                alt=""
                class="jrlimg"
                @click="toCompon(3)"
                style="cursor: pointer"
              />
              <div class="bot-wiidth flex">
                <div class="coulm1-bot-line"></div>
                <img
                  src="../../assets/images/rts_jrlimg1.png"
                  alt=""
                  class="coulm1-bot-img1"
                />
                <div class="coulm1-bot-line1"></div>
                <div class="coulm1-bot-line2"></div>
                <div
                  class="pore"
                  @click="
                    toCompon(
                      2,
                      'KF_114_B',
                      infoList.KF_114_B_node_string,
                      'KF_114_J1',
                      '煤烟气总管阀反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_114_B,
                      'KF_114_B',
                      infoList.KF_114_B_node_string,
                      'KF_114_J1'
                    )
                  "
                >
                  {{ 'KF_114_B' in infoList?infoList.KF_114_B :0}}%
                </div>
                <div class="coulm1-bot-right flex">
                  <div class="fam1">
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="jflimg2"
                    />
                  </div>
                  <div class="famright flex">
                    <div class="famright-list flex">
                      <div class="famright-list-left">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK15 && !infoList.QK15.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK15
                                ? toDetail(
                                    2,
                                    'QK15',
                                    infoList.QK15_node_string,
                                    '',
                                    '一加热空烟空气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK12 && infoList.XK12.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK12
                                ? toDetail(
                                    1,
                                    'XK12',
                                    infoList.XK12_node_string,
                                    '',
                                    '一加热段空烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.MAN22 && infoList.MAN22.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.MAN22
                                ? toCompon(
                                    0,
                                    'AV',
                                    'MAN22',
                                    'MAN22_AV_J1',
                                    '一加热段空烟气阀门手操器MAN22'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2"
                          />
                          <p
                            @click="
                              toCompon(
                                2,
                                'KF_122_B',
                                infoList.KF_122_B_node_string,
                                'KF_122_J1',
                                '一热段空烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.KF_122_B,
                                'KF_122_B',
                                infoList.KF_122_B_node_string,
                                'KF_122_J1'
                              )
                            "
                          >
                            {{ 'KF_122_B' in infoList?infoList.KF_122_B :0}}%
                          </p>
                        </div>
                      </div>
                      <div class="famright-list-right famright-list-left">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK16 && !infoList.QK16.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK16
                                ? toDetail(
                                    2,
                                    'QK16',
                                    infoList.QK16_node_string,
                                    '',
                                    '一加热煤烟煤气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK11 && infoList.XK11.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK11
                                ? toDetail(
                                    1,
                                    'XK11',
                                    infoList.XK11_node_string,
                                    '',
                                    '一加热段煤烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.MAN23 && infoList.MAN23.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.MAN23
                                ? toCompon(
                                    0,
                                    'AV',
                                    'MAN23',
                                    'MAN23_AV_J1',
                                    '一加热段煤烟气阀门手操器MAN23'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2"
                          />
                          <p
                            @click="
                              toCompon(
                                2,
                                'KF_123_B',
                                infoList.KF_123_B_node_string,
                                'KF_123_J1',
                                '一热段煤烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.KF_123_B,
                                'KF_123_B',
                                infoList.KF_123_B_node_string,
                                'KF_123_J1'
                              )
                            "
                          >
                            {{ 'KF_123_B' in infoList?infoList.KF_123_B :0}}%
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="famright-list flex">
                      <div class="famright-list-left">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK13 && !infoList.QK13.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK13
                                ? toDetail(
                                    2,
                                    'QK13',
                                    infoList.QK13_node_string,
                                    '',
                                    '二加热空烟空气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK9 && infoList.XK9.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK9
                                ? toDetail(
                                    1,
                                    'XK9',
                                    infoList.XK9_node_string,
                                    '',
                                    '二加热段空烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.MAN11 && infoList.MAN11.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.MAN11
                                ? toCompon(
                                    0,
                                    'AV',
                                    'MAN11',
                                    'MAN11_AV_J1',
                                    '二加热段空烟气阀门手操器MAN11'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2"
                          />
                          <p
                            @click="
                              toCompon(
                                2,
                                'KF_111_B',
                                infoList.KF_111_B_node_string,
                                'KF_111_J1',
                                '二热段空烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.KF_111_B,
                                'KF_111_B',
                                infoList.KF_111_B_node_string,
                                'KF_111_J1'
                              )
                            "
                          >
                            {{ 'KF_111_B' in infoList?infoList.KF_111_B :0}}%
                          </p>
                        </div>
                      </div>
                      <div class="famright-list-right famright-list-left">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK14 && !infoList.QK14.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK14
                                ? toDetail(
                                    2,
                                    'QK14',
                                    infoList.QK14_node_string,
                                    '',
                                    '二加热煤烟煤气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK8 && infoList.XK8.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK8
                                ? toDetail(
                                    1,
                                    'XK8',
                                    infoList.XK8_node_string,
                                    '',
                                    '二加热段煤烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.MAN12 && infoList.MAN12.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.MAN12
                                ? toCompon(
                                    0,
                                    'AV',
                                    'MAN12',
                                    'MAN12_AV_J1',
                                    '二加热段煤烟气阀门手操器MAN12'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2"
                          />
                          <p
                            @click="
                              toCompon(
                                2,
                                'KF_112_B',
                                infoList.KF_112_B_node_string,
                                'KF_112_J1',
                                '二热段煤烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.KF_112_B,
                                'KF_112_B',
                                infoList.KF_112_B_node_string,
                                'KF_112_J1'
                              )
                            "
                          >
                            {{ 'KF_112_B' in infoList?infoList.KF_112_B :0}}%
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="famright-list flex">
                      <div class="famright-list-left">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK11 && !infoList.QK11.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK11
                                ? toDetail(
                                    2,
                                    'QK11',
                                    infoList.QK11_node_string,
                                    '',
                                    '三加热空烟空气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK6 && infoList.XK6.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK6
                                ? toDetail(
                                    1,
                                    'XK6',
                                    infoList.XK6_node_string,
                                    '',
                                    '三加热段空烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.MAN7 && infoList.MAN7.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.MAN7
                                ? toCompon(
                                    0,
                                    'AV',
                                    'MAN7',
                                    'MAN7_AV_J1',
                                    '三加热段空烟气阀门手操器MAN7'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2 jflimgpo2"
                          />
                          <p
                            class="jflimgpop"
                            @click="
                              toCompon(
                                2,
                                'KF_107_B',
                                infoList.KF_107_B_node_string,
                                'KF_107_J1',
                                '三热段空烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.KF_107_B,
                                'KF_107_B',
                                infoList.KF_107_B_node_string,
                                'KF_107_J1'
                              )
                            "
                          >
                            {{ 'KF_107_B' in infoList?infoList.KF_107_B :0}}%
                          </p>
                        </div>
                      </div>
                      <div class="famright-list-right famright-list-left">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK12 && !infoList.QK12.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK12
                                ? toDetail(
                                    2,
                                    'QK12',
                                    infoList.QK12_node_string,
                                    '',
                                    '三加热煤烟煤气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK5 && infoList.XK5.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK5
                                ? toDetail(
                                    1,
                                    'XK5',
                                    infoList.XK5_node_string,
                                    '',
                                    '三加热段煤烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.MAN8 && infoList.MAN8.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.MAN8
                                ? toCompon(
                                    0,
                                    'AV',
                                    'MAN8',
                                    'MAN8_AV_J1',
                                    '三加热段煤烟气阀门手操器MAN8'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2"
                          />
                          <p
                            class=""
                            @click="
                              toCompon(
                                2,
                                'KF_108_B',
                                infoList.KF_108_B_node_string,
                                'KF_108_J1',
                                '三热段煤烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.KF_108_B,
                                'KF_108_B',
                                infoList.KF_108_B_node_string,
                                'KF_108_J1'
                              )
                            "
                          >
                            {{ 'KF_108_B' in infoList?infoList.KF_108_B :0}}%
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="thfamright1">
                      <div class="btnflex flex">
                        <div
                          class="child-dataK child-databtn"
                          :style="{
                            background:
                              infoList.QK9 && !infoList.QK9.TS
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.QK9
                              ? toDetail(
                                  2,
                                  'QK9',
                                  infoList.QK9_node_string,
                                  '',
                                  '均热段空烟空气阀门前馈'
                                )
                              : ''
                          "
                        >
                          K
                        </div>
                        <div
                          class="child-dataX child-databtn"
                          :style="{
                            background:
                              infoList.XK3 && infoList.XK3.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.XK3
                              ? toDetail(
                                  1,
                                  'XK3',
                                  infoList.XK3_node_string,
                                  '',
                                  '均热段空烟温度控制XK'
                                )
                              : ''
                          "
                        >
                          X
                        </div>
                        <div
                          class="child-dataA child-databtn"
                          :style="{
                            background:
                              infoList.MAN3 && infoList.MAN3.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.MAN3
                              ? toCompon(
                                  0,
                                  'AV',
                                  'MAN3',
                                  'MAN3_AV_J1',
                                  '均热段空烟气阀门手操器MAN3'
                                )
                              : ''
                          "
                        >
                          A
                        </div>
                      </div>
                      <div class="text3 flex">
                        <img
                          src="../../assets/images/jflimg2.png"
                          alt=""
                          class="jflimg2"
                        />
                        <p
                          class=""
                          @click="
                            toCompon(
                              2,
                              'KF_103_B',
                              infoList.KF_103_B_node_string,
                              'KF_103_J1',
                              '均热段空烟气阀反馈'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.KF_103_B,
                              'KF_103_B',
                              infoList.KF_103_B_node_string,
                              'KF_103_J1'
                            )
                          "
                        >
                          {{ 'KF_103_B' in infoList?infoList.KF_103_B :0}}%
                        </p>
                      </div>
                      <div class="thfamright2">
                        <div class="btnflex flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK10 && !infoList.QK10.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK10
                                ? toDetail(
                                    2,
                                    'QK10',
                                    infoList.QK10_node_string,
                                    '',
                                    '均热段煤烟煤气阀门前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK2 && infoList.XK2.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK2
                                ? toDetail(
                                    1,
                                    'XK2',
                                    infoList.XK2_node_string,
                                    '',
                                    '均热段煤烟温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.MAN4 && infoList.MAN4.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.MAN4
                                ? toCompon(
                                    0,
                                    'AV',
                                    'MAN4',
                                    'MAN4_AV_J1',
                                    '均热段煤烟气阀门手操器MAN4'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                        </div>
                        <div class="text3 flex">
                          <img
                            src="../../assets/images/jflimg2.png"
                            alt=""
                            class="jflimg2"
                          />
                          <p
                            class=""
                            @click="
                              toCompon(
                                2,
                                'KF_104_B',
                                infoList.KF_104_B_node_string,
                                'KF_104_J1',
                                '均热段煤烟气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.KF_104_B,
                                'KF_104_B',
                                infoList.KF_104_B_node_string,
                                'KF_104_J1'
                              )
                            "
                          >
                            {{ 'KF_104_B' in infoList?infoList.KF_104_B :0}}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 第二部分 -->
          <div class="el-container-box-cen-coulm2">
            <div class="coulm2-box flex align-cen">
              <div class="coulm2-box-left">
                <p>生产模式切换</p>
                <div
                  :class="infoList.MENLU == 2 ? 'active' : ''"
                  @click="chaneStu(2, 'MENLU', infoList.MENLU_node_string)"
                >
                  闷炉
                </div>
                <div
                  :class="infoList.MENLU == 3 ? 'active' : ''"
                  @click="chaneStu(3, 'MENLU', infoList.MENLU_node_string)"
                >
                  长时间闷炉
                </div>
                <div
                  :class="infoList.MENLU == 4 ? 'active' : ''"
                  @click="chaneStu(4, 'MENLU', infoList.MENLU_node_string)"
                >
                  正常烧炉
                </div>
              </div>
              <div class="coulm2-box-right flex">
                <div class="right-list">
                  <img
                    src="../../assets/images/jrlimg3.png"
                    alt=""
                    class="jrlimg3"
                  />
                  <img
                    src="../../assets/images/jrlimg4.png"
                    alt=""
                    class="jrlimg4"
                  />
                  <div class="coulm2-text1 flex">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_108A_B',
                          infoList.TICA_108A_B_node_string,
                          'TICA_108A_J1',
                          '一加热段空烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_108A_B,
                          'TICA_108A_B',
                          infoList.TICA_108A_B_node_string,
                          'TICA_108A_J1'
                        )
                      "
                    >
                      {{ 'TICA_108A_B' in infoList?infoList.TICA_108A_B :0}}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_108B_B',
                          infoList.TICA_108B_B_node_string,
                          'TICA_108B_J1',
                          '一加热段煤烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_108B_B,
                          'TICA_108B_B',
                          infoList.TICA_108B_B_node_string,
                          'TICA_108B_J1'
                        )
                      "
                    >
                      {{ 'TICA_108B_B' in infoList?infoList.TICA_108B_B :0}}℃
                    </div>
                  </div>
                  <div class="coulm2-text2 flex align-cen justify-content-cen">
                    <div class="coulm2-text2-row1">
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_107A_B',
                            infoList.TICA_107A_B_node_string,
                            'TICA_107A_J1',
                            '一加热段左侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.TICA_107A_B,
                            'TICA_107A_B',
                            infoList.TICA_107A_B_node_string,
                            'TICA_107A_J1'
                          )
                        "
                      >
                        {{ 'TICA_107A_B' in infoList?infoList.TICA_107A_B :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_107B_B',
                            infoList.TICA_107B_B_node_string,
                            'TICA_107B_J1',
                            '一加热段右侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.TICA_107B_B,
                            'TICA_107B_B',
                            infoList.TICA_107B_B_node_string,
                            'TICA_107B_J1'
                          )
                        "
                      >
                        {{ 'TICA_107B_B' in infoList?infoList.TICA_107B_B :0}}℃
                      </div>
                    </div>
                    <div class="coulm2-text2-row2" @click="toCompon(9, 1)">
                      <div>一加热段</div>
                    </div>
                    <div class="coulm2-text2-row3">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.T41SP,
                            '一加热段设定值',
                            'T41SP',
                            infoList.T41SP_node_string
                          )
                        "
                      >
                        {{ 'T41SP' in infoList?infoList.T41SP :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T41SPSJ',
                            infoList.T41SPSJ_node_string,
                            'T41SPSJ_J1'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.T41SPSJ,
                            'T41SPSJ',
                            infoList.T41SPSJ_node_string,
                            'T41SPSJ_J1'
                          )
                        "
                      >
                        {{ 'T41SPSJ' in infoList?infoList.T41SPSJ :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T41PV',
                            infoList.T41PV_node_string,
                            'T41PV_J1',
                            '一加热段上部温度均值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.T41PV,
                            'T41PV',
                            infoList.T41PV_node_string,
                            'T41PV_J1'
                          )
                        "
                      >
                        {{ 'T41PV' in infoList?infoList.T41PV :0}}℃
                      </div>
                    </div>
                  </div>
                  <div class="coulm2-text1 flex">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_108C_B',
                          infoList.TICA_108C_B_node_string,
                          'TICA_108C_J1',
                          '一加热段空烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_108C_B,
                          'TICA_108C_B',
                          infoList.TICA_108C_B_node_string,
                          'TICA_108C_J1'
                        )
                      "
                    >
                      {{ 'TICA_108C_B' in infoList?infoList.TICA_108C_B :0}}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_108D_B',
                          infoList.TICA_108D_B_node_string,
                          'TICA_108D_J1',
                          '一加热段煤烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_108D_B,
                          'TICA_108D_B',
                          infoList.TICA_108D_B_node_string,
                          'TICA_108D_J1'
                        )
                      "
                    >
                      {{ 'TICA_108D_B' in infoList?infoList.TICA_108D_B :0}}℃
                    </div>
                  </div>
                  <div
                    class="coulm2-text3"
                    @click="
                      toCompon(
                        2,
                        'YJRHXT_B',
                        infoList.YJRHXT_B_node_string,
                        'YJRHXT_J1',
                        '一加热换向时间'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.YJRHXT_B,
                        'YJRHXT_B',
                        infoList.YJRHXT_B_node_string,
                        'YJRHXT_J1'
                      )
                    "
                  >
                    {{ 'YJRHXT_B' in infoList?infoList.YJRHXT_B :0}}S
                  </div>
                  <div class="coulm2-line1"></div>
                  <div class="coulm2-line2"></div>
                </div>
                <div class="right-list">
                  <img
                    src="../../assets/images/jrlimg3.png"
                    alt=""
                    class="jrlimg3"
                  />
                  <img
                    src="../../assets/images/jrlimg4.png"
                    alt=""
                    class="jrlimg4"
                  />
                  <div class="coulm2-text1 flex">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_106A_B',
                          infoList.TICA_106A_B_node_string,
                          'TICA_106A_J1',
                          '二加热段空烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_106A_B,
                          'TICA_106A_B',
                          infoList.TICA_106A_B_node_string,
                          'TICA_106A_J1'
                        )
                      "
                    >
                      {{ 'TICA_106A_B' in infoList?infoList.TICA_106A_B :0}}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_106B_B',
                          infoList.TICA_106B_B_node_string,
                          'TICA_106B_J1',
                          '二加热段煤烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_106B_B,
                          'TICA_106B_B',
                          infoList.TICA_106B_B_node_string,
                          'TICA_106B_J1'
                        )
                      "
                    >
                      {{ 'TICA_106B_B' in infoList?infoList.TICA_106B_B :0}}℃
                    </div>
                  </div>
                  <div class="coulm2-text2 flex align-cen justify-content-cen">
                    <div class="coulm2-text2-row1">
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_105A_B',
                            infoList.TICA_105A_B_node_string,
                            'TICA_105A_J1',
                            '二加热段左侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.TICA_105A_B,
                            'TICA_105A_B',
                            infoList.TICA_105A_B_node_string,
                            'TICA_105A_J1'
                          )
                        "
                      >
                        {{ 'TICA_105A_B' in infoList?infoList.TICA_105A_B :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_105B_B',
                            infoList.TICA_105B_B_node_string,
                            'TICA_105B_J1',
                            '二加热段右侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.TICA_105B_B,
                            'TICA_105B_B',
                            infoList.TICA_105B_B_node_string,
                            'TICA_105B_J1'
                          )
                        "
                      >
                        {{ 'TICA_105B_B' in infoList?infoList.TICA_105B_B :0}}℃
                      </div>
                    </div>
                    <div class="coulm2-text2-row2" @click="toCompon(9, 2)">
                      <div>二加热段</div>
                    </div>
                    <div class="coulm2-text2-row3">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.T31SP,
                            '二加热段设定值',
                            'T31SP',
                            infoList.T31SP_node_string
                          )
                        "
                      >
                        {{ 'T31SP' in infoList?infoList.T31SP :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T31SPSJ',
                            infoList.T31SPSJ_node_string,
                            'T31SPSJ_J1'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.T31SPSJ,
                            'T31SPSJ',
                            infoList.T31SPSJ_node_string,
                            'T31SPSJ_J1'
                          )
                        "
                      >
                        {{ 'T31SPSJ' in infoList?infoList.T31SPSJ :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T31PV',
                            infoList.T31PV_node_string,
                            'T31PV_J1',
                            '二加热段上部温度均值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.T31PV,
                            'T31PV',
                            infoList.T31PV_node_string,
                            'T31PV_J1'
                          )
                        "
                      >
                        {{ 'T31PV' in infoList?infoList.T31PV :0}}℃
                      </div>
                    </div>

                    <div class="coulm2-line1"></div>
                    <div class="coulm2-line2"></div>
                  </div>
                  <div class="coulm2-text1 flex">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_106C_B',
                          infoList.TICA_106C_B_node_string,
                          'TICA_106C_J1',
                          '二加热段空烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_106C_B,
                          'TICA_106C_B',
                          infoList.TICA_106C_B_node_string,
                          'TICA_106C_J1'
                        )
                      "
                    >
                      {{ 'TICA_106C_B' in infoList?infoList.TICA_106C_B :0}}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_106D_B',
                          infoList.TICA_106D_B_node_string,
                          'TICA_106D_J1',
                          '二加热段煤烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_106D_B,
                          'TICA_106D_B',
                          infoList.TICA_106D_B_node_string,
                          'TICA_106D_J1'
                        )
                      "
                    >
                      {{ 'TICA_106D_B' in infoList?infoList.TICA_106D_B :0}}℃
                    </div>
                  </div>
                  <div
                    class="coulm2-text3"
                    @click="
                      toCompon(
                        2,
                        'EJRHXT_B',
                        infoList.EJRHXT_B_node_string,
                        'EJRHXT_J1',
                        '二加热换向时间'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.EJRHXT_B,
                        'EJRHXT_B',
                        infoList.EJRHXT_B_node_string,
                        'EJRHXT_J1'
                      )
                    "
                  >
                    {{ 'EJRHXT_B' in infoList?infoList.EJRHXT_B :0}}S
                  </div>
                </div>
                <div class="right-list">
                  <img
                    src="../../assets/images/jrlimg3.png"
                    alt=""
                    class="jrlimg3"
                  />
                  <img
                    src="../../assets/images/jrlimg4.png"
                    alt=""
                    class="jrlimg4"
                  />
                  <div class="coulm2-text1 flex" style="padding-left: 0.7vw">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_104A_B',
                          infoList.TICA_104A_B_node_string,
                          'TICA_104A_J1',
                          '三加热段空烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_104A_B,
                          'TICA_104A_B',
                          infoList.TICA_104A_B_node_string,
                          'TICA_104A_J1'
                        )
                      "
                    >
                      {{ 'TICA_104A_B' in infoList?infoList.TICA_104A_B :0}}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_104B_B',
                          infoList.TICA_104B_B_node_string,
                          'TICA_104B_J1',
                          '三加热段煤烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_104B_B,
                          'TICA_104B_B',
                          infoList.TICA_104B_B_node_string,
                          'TICA_104B_J1'
                        )
                      "
                    >
                      {{ 'TICA_104B_B' in infoList?infoList.TICA_104B_B :0}}℃
                    </div>
                  </div>
                  <div class="coulm2-text2 flex align-cen justify-content-cen">
                    <div class="coulm2-text2-row1">
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_103A_B',
                            infoList.TICA_103A_B_node_string,
                            'TICA_103A_J1',
                            '三加热段左侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.TICA_103A_B,
                            'TICA_103A_B',
                            infoList.TICA_103A_B_node_string,
                            'TICA_103A_J1'
                          )
                        "
                      >
                        {{ 'TICA_103A_B' in infoList?infoList.TICA_103A_B :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_103B_B',
                            infoList.TICA_103B_B_node_string,
                            'TICA_103B_J1',
                            '三加热段右侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.TICA_103B_B,
                            'TICA_103B_B',
                            infoList.TICA_103B_B_node_string,
                            'TICA_103B_J1'
                          )
                        "
                      >
                        {{ 'TICA_103B_B' in infoList?infoList.TICA_103B_B :0}}℃
                      </div>
                    </div>
                    <div class="coulm2-text2-row2" @click="toCompon(9, 3)">
                      <div>三加热段</div>
                    </div>
                    <div class="coulm2-text2-row3">
                      <div
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.T21SP,
                            '三加热段设定值',
                            'T21SP',
                            infoList.T21SP_node_string
                          )
                        "
                      >
                        {{ 'T21SP' in infoList?infoList.T21SP :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T21SPSJ',
                            infoList.T21SPSJ_node_string,
                            'T21SPSJ_J1',
                            '实际温度控制点'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.T21SPSJ,
                            'T21SPSJ',
                            infoList.T21SPSJ_node_string,
                            'T21SPSJ_J1'
                          )
                        "
                      >
                        {{ 'T21SPSJ' in infoList?infoList.T21SPSJ :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'T21PV',
                            infoList.T21PV_node_string,
                            'T21PV_J1',
                            '三加热段温度均值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.T21PV,
                            'T21PV',
                            infoList.T21PV_node_string,
                            'T21PV_J1'
                          )
                        "
                      >
                        {{ 'T21PV' in infoList?infoList.T21PV :0}}℃
                      </div>
                    </div>

                    <div class="coulm2-line1"></div>
                    <div class="coulm2-line2"></div>
                  </div>
                  <div class="coulm2-text1 flex" style="padding-left: 0.5vw">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_104C_B',
                          infoList.TICA_104C_B_node_string,
                          'TICA_104C_J1',
                          '三加热段空烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_104C_B,
                          'TICA_104C_B',
                          infoList.TICA_104C_B_node_string,
                          'TICA_104C_J1'
                        )
                      "
                    >
                      {{ 'TICA_104C_B' in infoList?infoList.TICA_104C_B :0}}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_104D_B',
                          infoList.TICA_104D_B_node_string,
                          'TICA_104D_J1',
                          '三加热段煤烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_104D_B,
                          'TICA_104D_B',
                          infoList.TICA_104D_B_node_string,
                          'TICA_104D_J1'
                        )
                      "
                    >
                      {{ 'TICA_104D_B' in infoList?infoList.TICA_104D_B :0}}℃
                    </div>
                  </div>
                  <div
                    class="coulm2-text3"
                    @click="
                      toCompon(
                        2,
                        'SJRHXT_B',
                        infoList.SJRHXT_B_node_string,
                        'SJRHXT_J1',
                        '三加热换向时间'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.SJRHXT_B,
                        'SJRHXT_B',
                        infoList.SJRHXT_B_node_string,
                        'SJRHXT_J1'
                      )
                    "
                  >
                    {{ 'SJRHXT_B' in infoList?infoList.SJRHXT_B :0}}S
                  </div>
                </div>
                <div class="right-list">
                  <img
                    src="../../assets/images/jrlimg3.png"
                    alt=""
                    class="jrlimg3"
                  />
                  <img
                    src="../../assets/images/jrlimg4.png"
                    alt=""
                    class="jrlimg4"
                  />
                  <div class="coulm2-text1 flex" style="padding-left: 0.7vw">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_102A_B',
                          infoList.TICA_102A_B_node_string,
                          'TICA_102A_J1',
                          '均热段空烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_102A_B,
                          'TICA_102A_B',
                          infoList.TICA_102A_B_node_string,
                          'TICA_102A_J1'
                        )
                      "
                    >
                      {{ 'TICA_102A_B' in infoList?infoList.TICA_102A_B :0}}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_102B_B',
                          infoList.TICA_102B_B_node_string,
                          'TICA_102B_J1',
                          '均热段煤烟排烟温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_102B_B,
                          'TICA_102B_B',
                          infoList.TICA_102B_B_node_string,
                          'TICA_102B_J1'
                        )
                      "
                    >
                      {{ 'TICA_102B_B' in infoList?infoList.TICA_102B_B :0}}℃
                    </div>
                  </div>
                  <div class="coulm2-text2 flex align-cen">
                    <div class="coulm2-text2-row1" style="padding: 1vw">
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_101A_B',
                            infoList.TICA_101A_B_node_string,
                            'TICA_101A_J1',
                            '均热段左侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.TICA_101A_B,
                            'TICA_101A_B',
                            infoList.TICA_101A_B_node_string,
                            'TICA_101A_J1'
                          )
                        "
                      >
                        {{ 'TICA_101A_B' in infoList?infoList.TICA_101A_B :0}}℃
                      </div>
                      <div
                        @click="
                          toCompon(
                            2,
                            'TICA_101B_B',
                            infoList.TICA_101B_B_node_string,
                            'TICA_101B_J1',
                            '均热段右侧温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.TICA_101B_B,
                            'TICA_101B_B',
                            infoList.TICA_101B_B_node_string,
                            'TICA_101B_J1'
                          )
                        "
                      >
                        {{ 'TICA_101B_B' in infoList?infoList.TICA_101B_B :0}}℃
                      </div>
                    </div>
                    <div
                      class="coulm2-text2-row2"
                      style="padding-left: 1vw; padding-right: 0"
                    >
                      <div @click="toCompon(9, 4)">均热段</div>
                    </div>
                    <div
                      class="coulm2-text2-row3 flex"
                      style="
                        position: absolute;
                        font-size: 0.8vw;
                        right: -0.7vw;
                        top: 4vh;
                      "
                    >
                      <div class="flex-row" style="padding-right: 2vw">
                        <div
                          style="color: rgb(0, 180, 255)"
                          @click="
                            toIpt(
                              infoList.T11SP,
                              '均热段设定值',
                              'T11SP',
                              infoList.T11SP_node_string
                            )
                          "
                        >
                          {{ 'T11SP' in infoList?infoList.T11SP :0}}℃
                        </div>
                        <div
                          @click="
                            toCompon(
                              2,
                              'T11SPSJ',
                              infoList.T11SPSJ_node_string,
                              'T11SPSJ_J1',
                              '实际温度控制点'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.T11SPSJ,
                              'T11SPSJ',
                              infoList.T11SPSJ_node_string,
                              'T11SPSJ_J1'
                            )
                          "
                        >
                          {{ 'T11SPSJ' in infoList?infoList.T11SPSJ :0}}℃
                        </div>
                        <div
                          @click="
                            toCompon(
                              2,
                              'T11PV',
                              infoList.T11PV_node_string,
                              'T11PV_J1',
                              '均热段上部温度均值'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.T11PV,
                              'T11PV',
                              infoList.T11PV_node_string,
                              'T11PV_J1'
                            )
                          "
                        >
                          {{ 'T11PV' in infoList?infoList.T11PV :0}}℃
                        </div>
                      </div>
                      <div class="flex-row">
                        <div
                          style="color: rgb(0, 180, 255)"
                          @click="
                            toIpt(
                              infoList.FYSP,
                              '负压控制点',
                              'FYSP',
                              infoList.FYSP_node_string
                            )
                          "
                        >
                          {{ 'FYSP' in infoList?infoList.FYSP :0}}Pa
                        </div>
                        <div
                          @click="
                            toCompon(
                              2,
                              'PV',
                              infoList.XK13_node_string,
                              'XK13_PV_J1',
                              '炉膛压力控制XK'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.XK13.PV,
                              'PV',
                              infoList.XK13_node_string,
                              'XK13_PV_J1'
                            )
                          "
                        >
                          {{ 'XK13' in infoList?infoList.XK13.PV :0}}Pa
                        </div>

                        <div
                          @click="
                            toCompon(
                              2,
                              'PICA_101_B',
                              infoList.PICA_101_B_node_string,
                              'PICA_101_J1',
                              '炉膛压力'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.PICA_101_B,
                              'PICA_101_B',
                              infoList.PICA_101_B_node_string,
                              'PICA_101_J1'
                            )
                          "
                        >
                          {{ 'PICA_101_B' in infoList?infoList.PICA_101_B :0}}℃
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="coulm2-text1 flex" style="padding-left: 0.7vw">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TICA_102C_B',
                          infoList.TICA_102C_B_node_string,
                          'TICA_102C_J1',
                          '均热段空烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_102C_B,
                          'TICA_102C_B',
                          infoList.TICA_102C_B_node_string,
                          'TICA_102C_J1'
                        )
                      "
                      style="padding: 0 0.5vw"
                    >
                      {{ 'TICA_102C_B' in infoList?infoList.TICA_102C_B :0}}℃
                    </div>
                    <div
                      class="right-auto"
                      @click="
                        toCompon(
                          2,
                          'TICA_102D_B',
                          infoList.TICA_102D_B_node_string,
                          'TICA_102D_J1',
                          '均热段煤烟排烟温度下'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TICA_102D_B,
                          'TICA_102D_B',
                          infoList.TICA_102D_B_node_string,
                          'TICA_102D_J1'
                        )
                      "
                      style="padding: 0 0.5vw"
                    >
                      {{ 'TICA_102D_B' in infoList?infoList.TICA_102D_B :0}}℃
                    </div>
                  </div>
                  <div
                    class="coulm2-text3"
                    style="left: 7.5vw"
                    @click="
                      toCompon(
                        2,
                        'JRDHXT_B',
                        infoList.JRDHXT_B_node_string,
                        'JRDHXT_J1',
                        '均热段换向时间'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.JRDHXT_B,
                        'JRDHXT_B',
                        infoList.JRDHXT_B_node_string,
                        'JRDHXT_J1'
                      )
                    "
                  >
                    {{ 'JRDHXT_B' in infoList?infoList.JRDHXT_B :0}}S
                  </div>
                  <div class="coulm2-line1"></div>
                  <div class="coulm2-line2"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- 第三部分 -->
          <div class="el-container-box-cen-coulm3">
            <div class="coulm3-box flex align-cen">
              <div class="coulm3-box-left">
                <div
                  class="left-text"
                  style="color: rgb(0, 180, 255)"
                  @click="
                    toIpt(
                      infoList.PKQ_SP1,
                      '空气总管压力设定值',
                      'PKQ_SP1',
                      infoList.PKQ_SP1_node_string
                    )
                  "
                >
                  {{ 'PKQ_SP1' in infoList?infoList.PKQ_SP1 :0}} KPa
                </div>
                <div
                  class="left-text"
                  @click="
                    toCompon(
                      2,
                      'PIA_101_B',
                      infoList.PIA_101_B_node_string,
                      'PIA_101_J1',
                      '均热段空气压力'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.PIA_101_B,
                      'PIA_101_B',
                      infoList.PIA_101_B_node_string,
                      'PIA_101_J1'
                    )
                  "
                >
                  {{ 'PIA_101_B' in infoList?infoList.PIA_101_B :0}} KPa
                </div>
                <div class="left1 flex align-cen">
                  <div class="line">
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="line-jflimg2"
                    />
                  </div>
                  <img
                    src="../../assets/images/rts_jrlimg1.png"
                    alt=""
                    class="left1-jflimg1"
                  />
                  <div class="line1"></div>
                  <div class="left-btn">
                    <div
                      class="child-dataK child-databtn"
                      :style="{
                        background:
                          infoList.QK6 && !infoList.QK6.TS ? '#2AFC30' : 'red',
                      }"
                      @click="
                        infoList.QK6
                          ? toDetail(
                              2,
                              'QK6',
                              infoList.QK6_node_string,
                              '',
                              '鼓风机空气阀门前馈'
                            )
                          : ''
                      "
                    >
                      K
                    </div>
                    <div
                      class="child-dataX child-databtn"
                      :style="{
                        background:
                          infoList.XK23 && infoList.XK23.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.XK23
                          ? toDetail(
                              1,
                              'XK23',
                              infoList.XK23_node_string,
                              '',
                              '空气总管压力XK'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="child-dataA child-databtn"
                      :style="{
                        background:
                          infoList.MAN16 && infoList.MAN16.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.MAN16
                          ? toCompon(
                              0,
                              'AV',
                              'MAN16',
                              'MAN16_AV_J1',
                              '均热鼓风机手操器MAN16'
                            )
                          : ''
                      "
                    >
                      A
                    </div>
                  </div>
                </div>
                <div
                  class="left-text left-text1"
                  @click="
                    toCompon(
                      2,
                      'KF_116_B',
                      infoList.KF_116_B_node_string,
                      'KF_116_J1',
                      '鼓风机(均热段)反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_116_B,
                      'KF_116_B',
                      infoList.KF_116_B_node_string,
                      'KF_116_J1'
                    )
                  "
                >
                  {{ 'KF_116_B' in infoList?infoList.KF_116_B :0}}%
                </div>
                <div class="left1 left2 flex align-cen">
                  <div class="line">
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="line-jflimg2"
                    />
                  </div>
                  <img
                    src="../../assets/images/rts_jrlimg1.png"
                    alt=""
                    class="left1-jflimg1"
                  />
                  <div class="line1"></div>
                  <div class="left-btn">
                    <div
                      class="child-dataX child-databtn"
                      :style="{
                        background:
                          infoList.XK24 && infoList.XK24.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.XK24
                          ? toDetail(
                              1,
                              'XK24',
                              infoList.XK24_node_string,
                              '',
                              '空气总管压力XK'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="child-dataA child-databtn"
                      :style="{
                        background:
                          infoList.MAN17 && infoList.MAN17.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.MAN17
                          ? toCompon(
                              0,
                              'AV',
                              'MAN17',
                              'MAN17_AV_J1',
                              '三热段鼓风机手操器MAN17'
                            )
                          : ''
                      "
                    >
                      A
                    </div>
                  </div>
                </div>
                <div
                  class="left-text left-text1"
                  @click="
                    toCompon(
                      2,
                      'KF_117_B',
                      infoList.KF_117_B_node_string,
                      'KF_117_J1',
                      '鼓风机(三加热段)反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_117_B,
                      'KF_117_B',
                      infoList.KF_117_B_node_string,
                      'KF_117_J1'
                    )
                  "
                >
                  {{ 'KF_117_B' in infoList?infoList.KF_117_B :0}}%
                </div>
              </div>
              <div class="line-box">
                <div class="line1"></div>
                <div class="line1 line2"></div>
              </div>
              <div class="coulm3-list">
                <div class="coulm3-list-top flex">
                  <div class="list-o">
                    <div class="list-text">
                      <div
                        class="text"
                        @click="
                          toCompon(
                            2,
                            'KF_120_B',
                            infoList.KF_120_B_node_string,
                            'KF_120_J1',
                            '一热段空气阀反馈'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.KF_120_B,
                            'KF_120_B',
                            infoList.KF_120_B_node_string,
                            'KF_120_J1'
                          )
                        "
                      >
                        {{ 'KF_120_B' in infoList?infoList.KF_120_B :0}}%
                      </div>
                      <div
                        class="text"
                        @click="
                          toCompon(
                            2,
                            'FICA_107_B',
                            infoList.FICA_107_B_node_string,
                            'FICA_107_J1',
                            '一加热段空气流量'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.FICA_107_B,
                            'FICA_107_B',
                            infoList.FICA_107_B_node_string,
                            'FICA_107_J1'
                          )
                        "
                      >
                        {{ 'FICA_107_B' in infoList?infoList.FICA_107_B :0}}m³/h
                      </div>
                      <div class="btn flex">
                        <div
                          class="child-dataX child-databtn"
                          :style="{
                            background:
                              infoList.XK22 && infoList.XK22.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.XK22
                              ? toDetail(
                                  1,
                                  'XK22',
                                  infoList.XK22_node_string,
                                  '',
                                  '一加热空气流量XK'
                                )
                              : ''
                          "
                        >
                          X
                        </div>
                        <div
                          class="child-dataA child-databtn"
                          :style="{
                            background:
                              infoList.MAN20 && infoList.MAN20.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.MAN20
                              ? toCompon(
                                  0,
                                  'AV',
                                  'MAN20',
                                  'MAN20_AV_J1',
                                  '一热段空气阀门手操器MAN20'
                                )
                              : ''
                          "
                        >
                          A
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list flex">
                    <div class="list-row1">
                      <img
                        src="../../assets/images/jflimg2.png"
                        alt=""
                        class="list-row1-jflimg2"
                      />
                    </div>
                    <div class="list-row1 list-row2">
                      <img
                        src="../../assets/images/jflimg2.png"
                        alt=""
                        class="list-row1-jflimg2"
                      />
                      <div class="list-o1">
                        <div class="list-text">
                          <div
                            class="text"
                            @click="
                              toCompon(
                                2,
                                'KF_109_B',
                                infoList.KF_109_B_node_string,
                                'KF_109_J1',
                                '二热段空气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.KF_109_B,
                                'KF_109_B',
                                infoList.KF_109_B_node_string,
                                'KF_109_J1'
                              )
                            "
                          >
                            {{ 'KF_109_B' in infoList?infoList.KF_109_B :0}}%
                          </div>
                          <div
                            class="text"
                            @click="
                              toCompon(
                                2,
                                'FICA_105_B',
                                infoList.FICA_105_B_node_string,
                                'FICA_105_J1',
                                '二加热段空气流量'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.FICA_105_B,
                                'FICA_105_B',
                                infoList.FICA_105_B_node_string,
                                'FICA_105_J1'
                              )
                            "
                          >
                            {{ 'FICA_105_B' in infoList?infoList.FICA_105_B :0}}m³/h
                          </div>
                          <div class="btn flex">
                            <div
                              class="child-dataX child-databtn"
                              :style="{
                                background:
                                  infoList.XK20 && infoList.XK20.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                              }"
                              @click="
                                infoList.XK20
                                  ? toDetail(
                                      1,
                                      'XK20',
                                      infoList.XK20_node_string,
                                      '',
                                      '二加热空气流量XK'
                                    )
                                  : ''
                              "
                            >
                              X
                            </div>
                            <div
                              class="child-dataA child-databtn"
                              :style="{
                                background:
                                  infoList.MAN9 && infoList.MAN9.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                              }"
                              @click="
                                infoList.MAN9
                                  ? toCompon(
                                      0,
                                      'AV',
                                      'MAN9',
                                      'MAN9_AV_J1',
                                      '二热段空气阀门手操器MAN9'
                                    )
                                  : ''
                              "
                            >
                              A
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list-row1 list-row2 list-row3">
                      <img
                        src="../../assets/images/jflimg2.png"
                        alt=""
                        class="list-row1-jflimg2"
                      />
                      <div class="list-o1">
                        <div class="list-text">
                          <div
                            class="text"
                            @click="
                              toCompon(
                                2,
                                'KF_105_B',
                                infoList.KF_105_B_node_string,
                                'KF_105_J1',
                                '三热段空气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.KF_105_B,
                                'KF_105_B',
                                infoList.KF_105_B_node_string,
                                'KF_105_J1'
                              )
                            "
                          >
                            {{ 'KF_105_B' in infoList?infoList.KF_105_B :0}}%
                          </div>
                          <div
                            class="text"
                            @click="
                              toCompon(
                                2,
                                'FICA_103_B',
                                infoList.FICA_103_B_node_string,
                                'FICA_103_J1',
                                '三加热段空气流量'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.FICA_103_B,
                                'FICA_103_B',
                                infoList.FICA_103_B_node_string,
                                'FICA_103_J1'
                              )
                            "
                          >
                            {{ 'FICA_103_B' in infoList?infoList.FICA_103_B :0}}m³/h
                          </div>
                          <div class="btn flex">
                            <div
                              class="child-dataX child-databtn"
                              :style="{
                                background:
                                  infoList.XK18 && infoList.XK18.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                              }"
                              @click="
                                infoList.XK18
                                  ? toDetail(
                                      1,
                                      'XK18',
                                      infoList.XK18_node_string,
                                      '',
                                      '三加热空气流量XK'
                                    )
                                  : ''
                              "
                            >
                              X
                            </div>
                            <div
                              class="child-dataA child-databtn"
                              :style="{
                                background:
                                  infoList.MAN5 && infoList.MAN5.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                              }"
                              @click="
                                infoList.MAN5
                                  ? toCompon(
                                      0,
                                      'AV',
                                      'MAN5',
                                      'MAN5_AV_J1',
                                      '三热段空气阀门手操器MAN5'
                                    )
                                  : ''
                              "
                            >
                              A
                            </div>
                          </div>
                        </div>
                      </div>
                      <img
                        src="../../assets/images/jflimg2.png"
                        alt=""
                        class="list-row2-jflimg3"
                      />
                      <div class="list-o2">
                        <div class="list-text">
                          <div
                            class="text"
                            @click="
                              toCompon(
                                2,
                                'KF_101_B',
                                infoList.KF_101_B_node_string,
                                'KF_101_J1',
                                '均热段空气阀反馈'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.KF_101_B,
                                'KF_101_B',
                                infoList.KF_101_B_node_string,
                                'KF_101_J1'
                              )
                            "
                          >
                            {{ 'KF_101_B' in infoList?infoList.KF_101_B :0}}%
                          </div>
                          <div
                            class="text"
                            @click="
                              toCompon(
                                2,
                                'FICA_101_B',
                                infoList.FICA_101_B_node_string,
                                'FICA_101_J1',
                                '均热段空气流量'
                              )
                            "
                            @dblclick="
                              Cclick(
                                infoList.FICA_101_B,
                                'FICA_101_B',
                                infoList.FICA_101_B_node_string,
                                'FICA_101_J1'
                              )
                            "
                          >
                            {{ 'FICA_101_B' in infoList?infoList.FICA_101_B :0}}m³/h
                          </div>
                          <div class="btn flex">
                            <div
                              class="child-dataX child-databtn"
                              :style="{
                                background:
                                  infoList.XK16 && infoList.XK16.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                              }"
                              @click="
                                infoList.XK16
                                  ? toDetail(
                                      1,
                                      'XK16',
                                      infoList.XK16_node_string,
                                      '',
                                      '均热空气流量XK'
                                    )
                                  : ''
                              "
                            >
                              X
                            </div>
                            <div
                              class="child-dataA child-databtn"
                              :style="{
                                background:
                                  infoList.MAN1 && infoList.MAN1.RM == 1
                                    ? '#2AFC30'
                                    : 'red',
                              }"
                              @click="
                                infoList.MAN1
                                  ? toCompon(
                                      0,
                                      'AV',
                                      'MAN1',
                                      'MAN1_AV_J1',
                                      '均热段空气阀门手操器MAN1'
                                    )
                                  : ''
                              "
                            >
                              A
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="coulm3-list-bot flex">
                  <div class="coulm3-list-line"></div>
                  <div class="coulm3-list-bot-list flex">
                    <div class="bot-text">
                      <div class="kq" @click="toCompon(7)">空气总管</div>
                      <div class="mq" @click="toCompon(8)">煤气总管</div>
                    </div>
                    <div class="maright ma-auto">
                      <img
                        src="../../assets/images/jflimg2.png"
                        alt=""
                        class="ma-auto-jflimg2"
                      />
                      <div
                        class="mapos"
                        @click="
                          toCompon(
                            2,
                            'KF_124_B',
                            infoList.KF_124_B_node_string,
                            'KF_124_J1',
                            '煤气总管压力调节阀反馈'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.KF_124_B,
                            'KF_124_B',
                            infoList.KF_124_B_node_string,
                            'KF_124_J1'
                          )
                        "
                      >
                        {{ 'KF_124_B' in infoList?infoList.KF_124_B :0}}%
                      </div>
                      <div
                        class="mapos1"
                        style="color: rgb(0, 180, 255)"
                        @click="
                          toIpt(
                            infoList.PMQ_SP,
                            '修改',
                            'PMQ_SP',
                            infoList.PMQ_SP_node_string
                          )
                        "
                      >
                        {{ 'PMQ_SP' in infoList?infoList.PMQ_SP :0}}Kpa
                      </div>
                      <div
                        class="mapos2"
                        @click="
                          toCompon(
                            2,
                            'PIA_105_B',
                            infoList.PIA_105_B_node_string,
                            'PIA_105_J1',
                            '煤气总管压力'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.PIA_105_B,
                            'PIA_105_B',
                            infoList.PIA_105_B_node_string,
                            'PIA_105_J1'
                          )
                        "
                      >
                        {{ 'PIA_105_B' in infoList?infoList.PIA_105_B :0}}Kpa
                      </div>
                      <div class="ma-btn flex justify-content-cen">
                        <div
                          class="child-dataK child-databtn"
                          :style="{
                            background:
                              infoList.QK7 && !infoList.QK7.TS
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.QK7
                              ? toDetail(
                                  2,
                                  'QK7',
                                  infoList.QK7_node_string,
                                  '',
                                  '煤气总管煤气阀门前馈'
                                )
                              : ''
                          "
                        >
                          K
                        </div>
                        <div
                          class="child-dataX child-databtn"
                          :style="{
                            background:
                              infoList.XK14 && infoList.XK14.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.XK14
                              ? toDetail(
                                  1,
                                  'XK14',
                                  infoList.XK14_node_string,
                                  '',
                                  '煤气总管压力控制XK'
                                )
                              : ''
                          "
                        >
                          X
                        </div>
                        <div
                          class="child-dataA child-databtn"
                          :style="{
                            background:
                              infoList.MAN24 && infoList.MAN24.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }"
                          @click="
                            infoList.MAN24
                              ? toCompon(
                                  0,
                                  'AV',
                                  'MAN24',
                                  'MAN24_AV_J1',
                                  '煤气总管压力手操器MAN24'
                                )
                              : ''
                          "
                        >
                          A
                        </div>
                      </div>
                    </div>
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="list-row1-jflimg2"
                    />
                    <div class="list-o1">
                      <div class="list-text">
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'KF_121_B',
                              infoList.KF_121_B_node_string,
                              'KF_121_J1',
                              '一热段煤气阀反馈'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.KF_121_B,
                              'KF_121_B',
                              infoList.KF_121_B_node_string,
                              'KF_121_J1'
                            )
                          "
                        >
                          {{ 'KF_121_B' in infoList?infoList.KF_121_B :0}}%
                        </div>
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'FICA_108_B',
                              infoList.FICA_108_B_node_string,
                              'FICA_108_J1',
                              '一加热段煤气流量'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.FICA_108_B,
                              'FICA_108_B',
                              infoList.FICA_108_B_node_string,
                              'FICA_108_J1'
                            )
                          "
                        >
                          {{ 'FICA_108_B' in infoList?infoList.FICA_108_B :0}}m³/h
                        </div>
                        <div class="btn flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK17 && !infoList.QK17.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK17
                                ? toDetail(
                                    2,
                                    'QK17',
                                    infoList.QK17_node_string,
                                    '',
                                    '一加热入炉钢温前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK10 && infoList.XK10.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK10
                                ? toDetail(
                                    1,
                                    'XK10',
                                    infoList.XK10_node_string,
                                    '',
                                    '一加热段炉膛温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK21 && infoList.XK21.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK21
                                ? toDetail(
                                    1,
                                    'XK21',
                                    infoList.XK21_node_string,
                                    '',
                                    '一加热煤气流量XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.MAN21 && infoList.MAN21.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.MAN21
                                ? toCompon(
                                    0,
                                    'AV',
                                    'MAN21',
                                    'MAN21_AV_J1',
                                    '一加热段煤气阀门手操器MAN21'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK21 && !infoList.QK21.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK21
                                ? toDetail(
                                    2,
                                    'QK21',
                                    infoList.QK21_node_string,
                                    '',
                                    '一加热段煤气压力前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="coulm3-list-bot-list coulm3-list-bot-list1 flex">
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="list-row1-jflimg2"
                    />
                    <div class="list-o1">
                      <div class="list-text">
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'KF_110_B',
                              infoList.KF_110_B_node_string,
                              'KF_110_J1',
                              '二热段煤气阀反馈'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.KF_110_B,
                              'KF_110_B',
                              infoList.KF_110_B_node_string,
                              'KF_110_J1'
                            )
                          "
                        >
                          {{ 'KF_110_B' in infoList?infoList.KF_110_B :0}}%
                        </div>
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'FICA_106_B',
                              infoList.FICA_106_B_node_string,
                              'FICA_106_J1',
                              '二加热段煤气流量'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.FICA_106_B,
                              'FICA_106_B',
                              infoList.FICA_106_B_node_string,
                              'FICA_106_J1'
                            )
                          "
                        >
                          {{ 'FICA_106_B' in infoList?infoList.FICA_106_B :0}}m³/h
                        </div>
                        <div class="btn flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK18 && !infoList.QK18.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK18
                                ? toDetail(
                                    2,
                                    'QK18',
                                    infoList.QK18_node_string,
                                    '',
                                    '二加热入炉钢温前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK7 && infoList.XK7.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK7
                                ? toDetail(
                                    1,
                                    'XK7',
                                    infoList.XK7_node_string,
                                    '',
                                    '二加热段炉膛温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK19 && infoList.XK19.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK19
                                ? toDetail(
                                    1,
                                    'XK19',
                                    infoList.XK19_node_string,
                                    '',
                                    '二加热煤气流量XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.MAN10 && infoList.MAN10.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.MAN10
                                ? toCompon(
                                    0,
                                    'AV',
                                    'MAN10',
                                    'MAN10_AV_J1',
                                    '二加热段煤气阀门手操器MAN10'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK22 && !infoList.QK22.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK22
                                ? toDetail(
                                    2,
                                    'QK22',
                                    infoList.QK22_node_string,
                                    '',
                                    '二加热段煤气压力前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="coulm3-list-bot-list coulm3-list-bot-list1 flex">
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="list-row1-jflimg2"
                    />
                    <div class="list-o1">
                      <div class="list-text">
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'KF_106_B',
                              infoList.KF_106_B_node_string,
                              'KF_106_J1',
                              '三热段煤气阀反馈'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.KF_106_B,
                              'KF_106_B',
                              infoList.KF_106_B_node_string,
                              'KF_106_J1'
                            )
                          "
                        >
                          {{ 'KF_106_B' in infoList?infoList.KF_106_B :0}}%
                        </div>
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'FICA_104_B',
                              infoList.FICA_104_B_node_string,
                              'FICA_104_J1',
                              '三加热段煤气流量'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.FICA_104_B,
                              'FICA_104_B',
                              infoList.FICA_104_B_node_string,
                              'FICA_104_J1'
                            )
                          "
                        >
                          {{ 'FICA_104_B' in infoList?infoList.FICA_104_B :0}}m³/h
                        </div>
                        <div class="btn flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK19 && !infoList.QK19.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK19
                                ? toDetail(
                                    2,
                                    'QK19',
                                    infoList.QK19_node_string,
                                    '',
                                    '三加热入炉钢温前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK4 && infoList.XK4.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK4
                                ? toDetail(
                                    1,
                                    'XK4',
                                    infoList.XK4_node_string,
                                    '',
                                    '三加热段炉膛温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK17 && infoList.XK17.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK17
                                ? toDetail(
                                    1,
                                    'XK17',
                                    infoList.XK17_node_string,
                                    '',
                                    '三加热煤气流量XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.MAN6 && infoList.MAN6.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.MAN6
                                ? toCompon(
                                    0,
                                    'AV',
                                    'MAN6',
                                    'MAN6_AV_J1',
                                    '三加热段煤气阀门手操器MAN6'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK23 && !infoList.QK23.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK23
                                ? toDetail(
                                    2,
                                    'QK23',
                                    infoList.QK23_node_string,
                                    '',
                                    '三加热段煤气压力前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="coulm3-list-bot-list coulm3-list-bot-list1 flex">
                    <img
                      src="../../assets/images/jflimg2.png"
                      alt=""
                      class="list-row1-jflimg2"
                    />
                    <div class="list-o1">
                      <div class="list-text">
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'KF_102_B',
                              infoList.KF_102_B_node_string,
                              'KF_102_J1',
                              '均热段煤气阀反馈'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.KF_102_B,
                              'KF_102_B',
                              infoList.KF_102_B_node_string,
                              'KF_102_J1'
                            )
                          "
                        >
                          {{ 'KF_102_B' in infoList?infoList.KF_102_B :0}}%
                        </div>
                        <div
                          class="text"
                          @click="
                            toCompon(
                              2,
                              'FICA_102_B',
                              infoList.FICA_102_B_node_string,
                              'FICA_102_J1',
                              '均热段煤气流量'
                            )
                          "
                          @dblclick="
                            Cclick(
                              infoList.FICA_102_B,
                              'FICA_102_B',
                              infoList.FICA_102_B_node_string,
                              'FICA_102_J1'
                            )
                          "
                        >
                          {{ 'FICA_102_B' in infoList?infoList.FICA_102_B :0}}m³/h
                        </div>
                        <div class="btn flex">
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK20 && !infoList.QK20.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK20
                                ? toDetail(
                                    2,
                                    'QK20',
                                    infoList.QK20_node_string,
                                    '',
                                    '均热入炉钢温前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK1 && infoList.XK1.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK1
                                ? toDetail(
                                    1,
                                    'XK1',
                                    infoList.XK1_node_string,
                                    '',
                                    '均热段炉膛温度控制XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataX child-databtn"
                            :style="{
                              background:
                                infoList.XK15 && infoList.XK15.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.XK15
                                ? toDetail(
                                    1,
                                    'XK15',
                                    infoList.XK15_node_string,
                                    '',
                                    '均热煤气流量XK'
                                  )
                                : ''
                            "
                          >
                            X
                          </div>
                          <div
                            class="child-dataA child-databtn"
                            :style="{
                              background:
                                infoList.MAN2 && infoList.MAN2.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.MAN2
                                ? toCompon(
                                    0,
                                    'AV',
                                    'MAN2',
                                    'MAN2_AV_J1',
                                    '均热段煤气阀门手操器MAN2'
                                  )
                                : ''
                            "
                          >
                            A
                          </div>
                          <div
                            class="child-dataK child-databtn"
                            :style="{
                              background:
                                infoList.QK24 && !infoList.QK24.TS
                                  ? '#2AFC30'
                                  : 'red',
                            }"
                            @click="
                              infoList.QK24
                                ? toDetail(
                                    2,
                                    'QK24',
                                    infoList.QK24_node_string,
                                    '',
                                    '均热段煤气压力前馈'
                                  )
                                : ''
                            "
                          >
                            K
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="coulm3-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <JrallModel
      v-if="alllModel"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JrallModel>
    <JRLParam01
      v-if="JRLParam01"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLParam01>
    <JRLParam02
      v-if="JRLParam02"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLParam02>
    <JRLParam03
      v-if="JRLParam03"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLParam03>
    <JRLParam04
      v-if="JRLParam04"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLParam04>
    <JRLParam05
      v-if="JRLParam05"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLParam05>
    <JRLcysz
      v-if="flag == 4 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLcysz>
    <JrparmsGroup
      v-if="JrparmsGroup"
      @sendStatus="isClose"
      :infoList="infoList"
      :datatype="historyname"
      :canshu1="canshu1"
    ></JrparmsGroup>
    <Jrwtdata
      v-if="Jrwtdata"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Jrwtdata>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>
  </div>
</template>
<script>
import index from "./_index/index.js";
import Manual from "@/components/Manual1.vue"; //手操器组件
import JrSecurity from "@/components/JrSecurity.vue"; //加热炉安全设置组件
import JrAssessment from "@/components/JrAssessment.vue"; //运行考核组件
import Jrswitchpage from "@/components/Jrswitchpage.vue"; //切换画面组件
import JrallModel from "@/components/JrallModel.vue"; //切换画面组件
import Jrserve from "@/components/Jrserve.vue"; //客服组件
import JRLParam01 from "@/components/JRL_Param01.vue"; //参数01组件
import JRLParam02 from "@/components/JRL_kqzg1.vue"; //参数02组件
import JRLParam03 from "@/components/JRL_mqzg1.vue"; //参数03组件
import JrparmsGroup from "@/components/JrparmsGroupjrl.vue"; //空气总管压力
import JRLParam04 from "@/components/JrallModel1.vue"; //参数04组件
import JRLParam05 from "@/components/JRL_Param05.vue"; //参数05组件
import JRLcysz from "@/components/JRL_cysz.vue"; //常用设置组件
import Jrwtdata from "@/components/Jrwtdata.vue"; //数据采集组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import inputVal from "@/components/inputVal.vue"; //输入框组件
import { downAction } from "@/api/index/index";
export default {
  name: "Jrindex",
  components: {
    Manual,
    JrSecurity,
    JrAssessment,
    Jrswitchpage,
    JrallModel,
    Jrserve,
    JRLParam01,
    JRLParam02,
    JRLParam03,
    JRLParam04,
    JrparmsGroup,
    Jrwtdata,
    Historical,
    inputVal,
    JRLcysz,
    JRLParam05,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      JRLParam05: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      conHeight: "",
      scaleHeight: "",
      alllModel: false,
      JRLParam01: false,
      JRLParam02: false,
      JRLParam03: false,
      JRLParam04: false,
      JrparmsGroup: false,
      Jrwtdata: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
    };
  },
  mixins: [index],
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  computed: {},
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));

    //   打开趋势组图
    // 获取燃烧流程接口信息
    // this.getInfo()
    this.$bus.$on("sendMsg", (msg, msg1) => {
      // 兄弟组件发送来的消息
      this.flag = msg;
      switch (this.flag) {
        case 0:
          return (this.JRLcysz = true), this.$router.push({ path: "/Jrindex" });
        case 1:
          return this.$router.push({ path: "/Jryxpj" });
        case 2:
          return this.$router.push({ path: "/Jraqsz" });
        case 3:
          return this.$router.push({ path: "/Jrswitch" });
        case 4:
          if (msg1) {
            return (this.JRLcysz = msg1);
          }
      }
    });
  },
  mounted() {},
  methods: {
    //   闷炉按钮切换
    chaneStu(val, mark, node) {
      downAction({
        device: this.projectData.device,
        mark: mark,
        node: node,
        value: val,
        cp_type: this.projectData.type,
      })
        .then((res) => {
          if (res.info.code == 200) {
            this.infoList[node][mark] = res.item.value;
          } else {
          }
        })
        .catch((err) => {});
    },
    //   打开趋势组图
    qsGroup() {
      this.$router.push({ path: "/trendGroup" });
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无操作权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_index/index.scss";
</style>
