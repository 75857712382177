var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.fullscreenLoading),expression:"fullscreenLoading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"Jrindex",staticStyle:{"height":"100%"},attrs:{"element-loading-text":"系统正在初始化","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(255, 255, 255, 0.6)"}},[_c('div',{ref:"bigbox",staticClass:"jrindex-conitaner",style:(_vm.scaleHeight)},[(_vm.maskbok)?_c('div',{staticClass:"maskbok"}):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.projectData.project)+" ")]),_c('div',{staticClass:"Jrindex-tit"},[_c('div',{staticClass:"Jrindex-tit-top flex"},[_c('div',{staticClass:"text-row flex"},[_c('div',[_vm._v("煤气总流量")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'FICA_117_B',
                _vm.infoList.FICA_117_B_node_string,
                'FICA_117_J1',
                '煤气总管流量'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.FICA_117_B,
                'FICA_117_B',
                _vm.infoList.FICA_117_B_node_string,
                'FICA_117_J1'
              )}}},[_vm._v(" "+_vm._s('FICA_117_B' in _vm.infoList?_vm.infoList.FICA_117_B :0)+" m³/h ")])]),_c('div',{staticClass:"text-row flex"},[_c('div',[_vm._v("入炉钢坯数量(PLC)")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'CGNO_B',
                _vm.infoList.CGNO_B_node_string,
                'GPRLNUM_J1',
                '入炉板坯数量'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.CGNO_B,
                'CGNO_B',
                _vm.infoList.CGNO_B_node_string,
                'GPRLNUM_J1'
              )}}},[_vm._v(" "+_vm._s('CGNO_B' in _vm.infoList?_vm.infoList.CGNO_B :0)+" 根 ")])]),_c('div',{staticClass:"text-row flex"},[_c('div',[_vm._v("入炉钢坯数量(BCS)")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'KHGGLJB',
                _vm.infoList.KHGGLJB_node_string,
                'KHGGLJB_J1',
                '过钢量班累积'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KHGGLJB,
                'KHGGLJB',
                _vm.infoList.KHGGLJB_node_string,
                'KHGGLJB_J1'
              )}}},[_vm._v(" "+_vm._s('KHGGLJB' in _vm.infoList?_vm.infoList.KHGGLJB :0)+" 根 ")])]),_c('div',{staticClass:"text-row flex"},[_c('div',[_vm._v("入炉钢温")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'RGT_B',
                _vm.infoList.RGT_B_node_string,
                'RGT_J1',
                '入钢温度'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.RGT_B,
                'RGT_B',
                _vm.infoList.RGT_B_node_string,
                'RGT_J1'
              )}}},[_vm._v(" "+_vm._s('RGT_B' in _vm.infoList?_vm.infoList.RGT_B :0)+" ℃ ")])]),_c('div',{staticClass:"text-row flex"},[_c('div',[_vm._v("开轧温度(PLC)")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'CGT_B',
                _vm.infoList.CGT_B_node_string,
                'CGT_J1',
                '开轧温度'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.CGT_B,
                'CGT_B',
                _vm.infoList.CGT_B_node_string,
                'CGT_J1'
              )}}},[_vm._v(" "+_vm._s('CGT_B' in _vm.infoList?_vm.infoList.CGT_B :0)+" ℃ ")])]),_c('div',{staticClass:"text-row flex align-cen"},[_c('div',[_vm._v("开轧温度(BCS)")]),_c('div',{staticClass:"ma-auto",on:{"click":function($event){return _vm.toCompon(
                2,
                'CGT_B',
                _vm.infoList.CGT_B_node_string,
                'CGT_J1',
                '开轧温度'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.CGT_B,
                'CGT_B',
                _vm.infoList.CGT_B_node_string,
                'CGT_J1'
              )}}},[_vm._v(" "+_vm._s('CGT_B' in _vm.infoList?_vm.infoList.CGT_B :0)+" ℃ ")]),_c('div',{staticClass:"child-dataR child-databtn ma-auto",style:({
              background:
                _vm.infoList.RSF2 && !_vm.infoList.RSF2.TS ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.RSF2
                ? _vm.toDetail(
                    3,
                    'RSF2',
                    _vm.infoList.RSF2_node_string,
                    '',
                    '出钢温度软伺服'
                  )
                : ''}}},[_vm._v(" R ")])])]),_c('div',{staticClass:"Jrindex-tit-bot"},[_c('span',{on:{"click":function($event){return _vm.toCompon(6)}}},[_vm._v("钢损模型")]),_c('span',{on:{"click":function($event){return _vm.toCompon(
              2,
              'GSM',
              _vm.infoList.GSMX1_node_string,
              'GSMX_GSM_J1',
              '钢损模型氧化烧损量'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.GSMX1.GSM,
              'GSM',
              _vm.infoList.GSMX1_node_string,
              'GSMX_GSM_J1'
            )}}},[_vm._v(_vm._s('GSMX1' in _vm.infoList?_vm.infoList.GSMX1.GSM :0)+"%")])])]),_c('div',{staticClass:"el-container-box",style:(_vm.conHeight)},[_c('div',{staticClass:"el-container-box-cen"},[_c('div',{staticClass:"el-container-box-cen-coulm1"},[_c('div',{staticClass:"coulm1-top flex align-cen"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                background:
                  _vm.infoList.MAN13 && _vm.infoList.MAN13.RM == 1
                    ? '#2AFC30'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.MAN13
                  ? _vm.toCompon(
                      0,
                      'AV',
                      'MAN13',
                      'MAN13_AV_J1',
                      '空烟气总管阀BCS手操器MAN13'
                    )
                  : ''}}},[_vm._v(" A ")]),_c('img',{staticClass:"jrlimg",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../assets/images/rts_jrlimg.png"),"alt":""},on:{"click":function($event){return _vm.toCompon(3)}}}),_c('div',{staticClass:"line"}),_c('img',{staticClass:"jflimg1",attrs:{"src":require("../../assets/images/rts_jrlimg1.png"),"alt":""}}),_c('div',{staticClass:"jrl-row flex"},[_c('div',{staticClass:"line1"}),_c('div',{staticClass:"fam1"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KF_113_B',
                      _vm.infoList.KF_113_B_node_string,
                      'KF_113_J1',
                      '空烟气总管阀反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.KF_113_B,
                      'KF_113_B',
                      _vm.infoList.KF_113_B_node_string,
                      'KF_113_J1'
                    )}}},[_vm._v(" "+_vm._s('KF_113_B' in _vm.infoList?_vm.infoList.KF_113_B :0)+"% ")])]),_c('div',{staticClass:"jrl_auto flex"},[_c('div',{staticClass:"list-auto1 flex"},[_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.YJRKPTSP,
                          '修改',
                          'YJRKPTSP',
                          _vm.infoList.YJRKPTSP_node_string
                        )}}},[_vm._v(" "+_vm._s('YJRKPTSP' in _vm.infoList?_vm.infoList.YJRKPTSP :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T42PV1',
                          _vm.infoList.T42PV1_node_string,
                          'T42PV1_J1',
                          '一加热空烟温度处理值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.T42PV1,
                          'T42PV1',
                          _vm.infoList.T42PV1_node_string,
                          'T42PV1_J1'
                        )}}},[_vm._v(" "+_vm._s('T42PV1' in _vm.infoList?_vm.infoList.T42PV1 :0)+"℃ ")])]),_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.YJRMPTSP,
                          '修改',
                          'YJRMPTSP',
                          _vm.infoList.YJRMPTSP_node_string
                        )}}},[_vm._v(" "+_vm._s('YJRMPTSP' in _vm.infoList?_vm.infoList.YJRMPTSP :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T42PV2',
                          _vm.infoList.T42PV2_node_string,
                          'T42PV2_J1',
                          '一加热段煤烟温度处理值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.T42PV2,
                          'T42PV2',
                          _vm.infoList.T42PV2_node_string,
                          'T42PV2_J1'
                        )}}},[_vm._v(" "+_vm._s('T42PV2' in _vm.infoList?_vm.infoList.T42PV2 :0)+"℃ ")])]),_c('div',{staticClass:"text2 flex align-cen"},[_c('div',{staticClass:"text"},[_vm._v("烟气含氧量")]),_c('div',{staticStyle:{"color":"rgb(0, 180, 255)","padding-left":"2vw"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.O2SP,
                          '修改',
                          'O2SP',
                          _vm.infoList.O2SP_node_string
                        )}}},[_vm._v(" "+_vm._s('O2SP' in _vm.infoList?_vm.infoList.O2SP :0)+"% ")]),_c('div',{staticStyle:{"color":"#d9feff","padding-left":"1vw"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'CO2_B',
                          _vm.infoList.CO2_B_node_string,
                          'CO2_J1',
                          '烟气含氧量'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.CO2_B,
                          'CO2_B',
                          _vm.infoList.CO2_B_node_string,
                          'CO2_J1'
                        )}}},[_vm._v(" "+_vm._s('CO2_B' in _vm.infoList?_vm.infoList.CO2_B :0)+"% ")]),_c('div',{staticClass:"child-dataR child-databtn",style:({
                        background:
                          _vm.infoList.RSF5 && !_vm.infoList.RSF5.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.RSF5
                          ? _vm.toDetail(
                              3,
                              'RSF5',
                              _vm.infoList.RSF5_node_string,
                              '',
                              '备用'
                            )
                          : ''}}},[_vm._v(" R ")])])]),_c('div',{staticClass:"list-auto1 flex"},[_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.EJRKPTSP,
                          '修改',
                          'EJRKPTSP',
                          _vm.infoList.EJRKPTSP_node_string
                        )}}},[_vm._v(" "+_vm._s('EJRKPTSP' in _vm.infoList?_vm.infoList.EJRKPTSP :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T32PV1',
                          _vm.infoList.T32PV1_node_string,
                          'T32PV1_J1',
                          '二加热段空烟温度处理值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.T32PV1,
                          'T32PV1',
                          _vm.infoList.T32PV1_node_string,
                          'T32PV1_J1'
                        )}}},[_vm._v(" "+_vm._s('T32PV1' in _vm.infoList?_vm.infoList.T32PV1 :0)+"℃ ")])]),_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.EJRMPTSP,
                          '修改',
                          'EJRMPTSP',
                          _vm.infoList.EJRMPTSP_node_string
                        )}}},[_vm._v(" "+_vm._s('EJRMPTSP' in _vm.infoList?_vm.infoList.EJRMPTSP :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T32PV2',
                          _vm.infoList.T32PV2_node_string,
                          'T32PV2_J1',
                          '二加热段煤烟温度处理值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.T32PV2,
                          'T32PV2',
                          _vm.infoList.T32PV2_node_string,
                          'T32PV2_J1'
                        )}}},[_vm._v(" "+_vm._s('T32PV2' in _vm.infoList?_vm.infoList.T32PV2 :0)+"℃ ")])])]),_c('div',{staticClass:"list-auto1 flex"},[_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.SJRKPTSP,
                          '修改',
                          'SJRKPTSP',
                          _vm.infoList.SJRKPTSP_node_string
                        )}}},[_vm._v(" "+_vm._s('SJRKPTSP' in _vm.infoList?_vm.infoList.SJRKPTSP :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T22PV1',
                          _vm.infoList.T22PV1_node_string,
                          'T22PV1_J1',
                          '三加热段空烟温度处理值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.T22PV1,
                          'T22PV1',
                          _vm.infoList.T22PV1_node_string,
                          'T22PV1_J1'
                        )}}},[_vm._v(" "+_vm._s('T22PV1' in _vm.infoList?_vm.infoList.T22PV1 :0)+"℃ ")])]),_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.SJRMPTSP,
                          '修改',
                          'SJRMPTSP',
                          _vm.infoList.SJRMPTSP_node_string
                        )}}},[_vm._v(" "+_vm._s('SJRMPTSP' in _vm.infoList?_vm.infoList.SJRMPTSP :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T22PV2',
                          _vm.infoList.T22PV2_node_string,
                          'T22PV2_J1',
                          '三加热段煤烟温度处理值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.T22PV2,
                          'T22PV2',
                          _vm.infoList.T22PV2_node_string,
                          'T22PV2_J1'
                        )}}},[_vm._v(" "+_vm._s('T22PV2' in _vm.infoList?_vm.infoList.T22PV2 :0)+"℃ ")])]),_c('div',{staticClass:"text2 th-text2 flex align-cen"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)","position":"absolute","left":"-6vw"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.FYSP_L,
                          '修改',
                          'FYSP_L',
                          _vm.infoList.FYSP_L_node_string
                        )}}},[_vm._v(" "+_vm._s('FYSP_L' in _vm.infoList?_vm.infoList.FYSP_L :0)+"Pa ")]),_c('div',{staticClass:"th-child-dataR child-databtn",style:({
                        background:
                          _vm.infoList.RSF3 && !_vm.infoList.RSF3.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.RSF3
                          ? _vm.toDetail(
                              3,
                              'RSF3',
                              _vm.infoList.RSF3_node_string,
                              '',
                              '负压空废煤废温度补偿'
                            )
                          : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"th-text",on:{"click":function($event){return _vm.toCompon(10)}}},[_vm._v("空烟管道")]),_c('div',{staticClass:"my-text th-text",on:{"click":function($event){return _vm.toCompon(10)}}},[_vm._v(" 煤烟管道 ")])])])])]),_c('div',{staticClass:"thfamright flex"},[_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.JRDKPTSP,
                      '修改',
                      'JRDKPTSP',
                      _vm.infoList.JRDKPTSP_node_string
                    )}}},[_vm._v(" "+_vm._s('JRDKPTSP' in _vm.infoList?_vm.infoList.JRDKPTSP :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'T12PV1',
                      _vm.infoList.T12PV1_node_string,
                      'T12PV1_J1',
                      '均热段空烟温度处理值'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.T12PV1,
                      'T12PV1',
                      _vm.infoList.T12PV1_node_string,
                      'T12PV1_J1'
                    )}}},[_vm._v(" "+_vm._s('T12PV1' in _vm.infoList?_vm.infoList.T12PV1 :0)+"℃ ")])]),_c('div',{staticClass:"text1"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList.JRDMPTSP,
                      '修改',
                      'JRDMPTSP',
                      _vm.infoList.JRDMPTSP_node_string
                    )}}},[_vm._v(" "+_vm._s('JRDMPTSP' in _vm.infoList?_vm.infoList.JRDMPTSP :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                      2,
                      'T12PV2',
                      _vm.infoList.T12PV2_node_string,
                      'T12PV2_J1',
                      '均热段煤烟温度处理值'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.T12PV2,
                      'T12PV2',
                      _vm.infoList.T12PV2_node_string,
                      'T12PV2_J1'
                    )}}},[_vm._v(" "+_vm._s('T12PV2' in _vm.infoList?_vm.infoList.T12PV2 :0)+"℃ ")])])])]),_c('div',{staticClass:"coulm1-cen flex align-cen"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                background:
                  _vm.infoList.XK13 && _vm.infoList.XK13.RM == 1 ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK13
                  ? _vm.toDetail(
                      1,
                      'XK13',
                      _vm.infoList.XK13_node_string,
                      '',
                      '炉膛压力控制XK'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataR child-databtn",style:({
                background:
                  _vm.infoList.RSF1 && !_vm.infoList.RSF1.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF1
                  ? _vm.toDetail(
                      3,
                      'RSF1',
                      _vm.infoList.RSF1_node_string,
                      '',
                      '空烟煤协调软伺服'
                    )
                  : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
                background:
                  _vm.infoList.QK1 && !_vm.infoList.QK1.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK1
                  ? _vm.toDetail(
                      2,
                      'QK1',
                      _vm.infoList.QK1_node_string,
                      '',
                      '空气阀门前馈'
                    )
                  : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
                background:
                  _vm.infoList.QK3 && !_vm.infoList.QK3.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK3
                  ? _vm.toDetail(
                      2,
                      'QK3',
                      _vm.infoList.QK3_node_string,
                      '',
                      '煤气阀门前馈'
                    )
                  : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"coulm1-bot flex align-cen"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                background:
                  _vm.infoList.MAN14 && _vm.infoList.MAN14.RM == 1
                    ? '#2AFC30'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.MAN14
                  ? _vm.toCompon(
                      0,
                      'AV',
                      'MAN14',
                      'MAN14_AV_J1',
                      '煤烟气总管阀BCS手操器MAN14'
                    )
                  : ''}}},[_vm._v(" A ")]),_c('img',{staticClass:"jrlimg",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../assets/images/rts_jrlimg.png"),"alt":""},on:{"click":function($event){return _vm.toCompon(3)}}}),_c('div',{staticClass:"bot-wiidth flex"},[_c('div',{staticClass:"coulm1-bot-line"}),_c('img',{staticClass:"coulm1-bot-img1",attrs:{"src":require("../../assets/images/rts_jrlimg1.png"),"alt":""}}),_c('div',{staticClass:"coulm1-bot-line1"}),_c('div',{staticClass:"coulm1-bot-line2"}),_c('div',{staticClass:"pore",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'KF_114_B',
                    _vm.infoList.KF_114_B_node_string,
                    'KF_114_J1',
                    '煤烟气总管阀反馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.KF_114_B,
                    'KF_114_B',
                    _vm.infoList.KF_114_B_node_string,
                    'KF_114_J1'
                  )}}},[_vm._v(" "+_vm._s('KF_114_B' in _vm.infoList?_vm.infoList.KF_114_B :0)+"% ")]),_c('div',{staticClass:"coulm1-bot-right flex"},[_vm._m(0),_c('div',{staticClass:"famright flex"},[_c('div',{staticClass:"famright-list flex"},[_c('div',{staticClass:"famright-list-left"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK15 && !_vm.infoList.QK15.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK15
                              ? _vm.toDetail(
                                  2,
                                  'QK15',
                                  _vm.infoList.QK15_node_string,
                                  '',
                                  '一加热空烟空气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK12 && _vm.infoList.XK12.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK12
                              ? _vm.toDetail(
                                  1,
                                  'XK12',
                                  _vm.infoList.XK12_node_string,
                                  '',
                                  '一加热段空烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.MAN22 && _vm.infoList.MAN22.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.MAN22
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'MAN22',
                                  'MAN22_AV_J1',
                                  '一加热段空烟气阀门手操器MAN22'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_122_B',
                              _vm.infoList.KF_122_B_node_string,
                              'KF_122_J1',
                              '一热段空烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.KF_122_B,
                              'KF_122_B',
                              _vm.infoList.KF_122_B_node_string,
                              'KF_122_J1'
                            )}}},[_vm._v(" "+_vm._s('KF_122_B' in _vm.infoList?_vm.infoList.KF_122_B :0)+"% ")])])]),_c('div',{staticClass:"famright-list-right famright-list-left"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK16 && !_vm.infoList.QK16.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK16
                              ? _vm.toDetail(
                                  2,
                                  'QK16',
                                  _vm.infoList.QK16_node_string,
                                  '',
                                  '一加热煤烟煤气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK11 && _vm.infoList.XK11.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK11
                              ? _vm.toDetail(
                                  1,
                                  'XK11',
                                  _vm.infoList.XK11_node_string,
                                  '',
                                  '一加热段煤烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.MAN23 && _vm.infoList.MAN23.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.MAN23
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'MAN23',
                                  'MAN23_AV_J1',
                                  '一加热段煤烟气阀门手操器MAN23'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_123_B',
                              _vm.infoList.KF_123_B_node_string,
                              'KF_123_J1',
                              '一热段煤烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.KF_123_B,
                              'KF_123_B',
                              _vm.infoList.KF_123_B_node_string,
                              'KF_123_J1'
                            )}}},[_vm._v(" "+_vm._s('KF_123_B' in _vm.infoList?_vm.infoList.KF_123_B :0)+"% ")])])])]),_c('div',{staticClass:"famright-list flex"},[_c('div',{staticClass:"famright-list-left"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK13 && !_vm.infoList.QK13.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK13
                              ? _vm.toDetail(
                                  2,
                                  'QK13',
                                  _vm.infoList.QK13_node_string,
                                  '',
                                  '二加热空烟空气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK9 && _vm.infoList.XK9.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK9
                              ? _vm.toDetail(
                                  1,
                                  'XK9',
                                  _vm.infoList.XK9_node_string,
                                  '',
                                  '二加热段空烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.MAN11 && _vm.infoList.MAN11.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.MAN11
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'MAN11',
                                  'MAN11_AV_J1',
                                  '二加热段空烟气阀门手操器MAN11'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_111_B',
                              _vm.infoList.KF_111_B_node_string,
                              'KF_111_J1',
                              '二热段空烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.KF_111_B,
                              'KF_111_B',
                              _vm.infoList.KF_111_B_node_string,
                              'KF_111_J1'
                            )}}},[_vm._v(" "+_vm._s('KF_111_B' in _vm.infoList?_vm.infoList.KF_111_B :0)+"% ")])])]),_c('div',{staticClass:"famright-list-right famright-list-left"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK14 && !_vm.infoList.QK14.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK14
                              ? _vm.toDetail(
                                  2,
                                  'QK14',
                                  _vm.infoList.QK14_node_string,
                                  '',
                                  '二加热煤烟煤气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK8 && _vm.infoList.XK8.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK8
                              ? _vm.toDetail(
                                  1,
                                  'XK8',
                                  _vm.infoList.XK8_node_string,
                                  '',
                                  '二加热段煤烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.MAN12 && _vm.infoList.MAN12.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.MAN12
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'MAN12',
                                  'MAN12_AV_J1',
                                  '二加热段煤烟气阀门手操器MAN12'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_112_B',
                              _vm.infoList.KF_112_B_node_string,
                              'KF_112_J1',
                              '二热段煤烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.KF_112_B,
                              'KF_112_B',
                              _vm.infoList.KF_112_B_node_string,
                              'KF_112_J1'
                            )}}},[_vm._v(" "+_vm._s('KF_112_B' in _vm.infoList?_vm.infoList.KF_112_B :0)+"% ")])])])]),_c('div',{staticClass:"famright-list flex"},[_c('div',{staticClass:"famright-list-left"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK11 && !_vm.infoList.QK11.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK11
                              ? _vm.toDetail(
                                  2,
                                  'QK11',
                                  _vm.infoList.QK11_node_string,
                                  '',
                                  '三加热空烟空气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK6 && _vm.infoList.XK6.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK6
                              ? _vm.toDetail(
                                  1,
                                  'XK6',
                                  _vm.infoList.XK6_node_string,
                                  '',
                                  '三加热段空烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.MAN7 && _vm.infoList.MAN7.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.MAN7
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'MAN7',
                                  'MAN7_AV_J1',
                                  '三加热段空烟气阀门手操器MAN7'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2 jflimgpo2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{staticClass:"jflimgpop",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_107_B',
                              _vm.infoList.KF_107_B_node_string,
                              'KF_107_J1',
                              '三热段空烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.KF_107_B,
                              'KF_107_B',
                              _vm.infoList.KF_107_B_node_string,
                              'KF_107_J1'
                            )}}},[_vm._v(" "+_vm._s('KF_107_B' in _vm.infoList?_vm.infoList.KF_107_B :0)+"% ")])])]),_c('div',{staticClass:"famright-list-right famright-list-left"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK12 && !_vm.infoList.QK12.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK12
                              ? _vm.toDetail(
                                  2,
                                  'QK12',
                                  _vm.infoList.QK12_node_string,
                                  '',
                                  '三加热煤烟煤气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK5 && _vm.infoList.XK5.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK5
                              ? _vm.toDetail(
                                  1,
                                  'XK5',
                                  _vm.infoList.XK5_node_string,
                                  '',
                                  '三加热段煤烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.MAN8 && _vm.infoList.MAN8.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.MAN8
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'MAN8',
                                  'MAN8_AV_J1',
                                  '三加热段煤烟气阀门手操器MAN8'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_108_B',
                              _vm.infoList.KF_108_B_node_string,
                              'KF_108_J1',
                              '三热段煤烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.KF_108_B,
                              'KF_108_B',
                              _vm.infoList.KF_108_B_node_string,
                              'KF_108_J1'
                            )}}},[_vm._v(" "+_vm._s('KF_108_B' in _vm.infoList?_vm.infoList.KF_108_B :0)+"% ")])])])]),_c('div',{staticClass:"thfamright1"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                          background:
                            _vm.infoList.QK9 && !_vm.infoList.QK9.TS
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.QK9
                            ? _vm.toDetail(
                                2,
                                'QK9',
                                _vm.infoList.QK9_node_string,
                                '',
                                '均热段空烟空气阀门前馈'
                              )
                            : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                          background:
                            _vm.infoList.XK3 && _vm.infoList.XK3.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.XK3
                            ? _vm.toDetail(
                                1,
                                'XK3',
                                _vm.infoList.XK3_node_string,
                                '',
                                '均热段空烟温度控制XK'
                              )
                            : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                          background:
                            _vm.infoList.MAN3 && _vm.infoList.MAN3.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.MAN3
                            ? _vm.toCompon(
                                0,
                                'AV',
                                'MAN3',
                                'MAN3_AV_J1',
                                '均热段空烟气阀门手操器MAN3'
                              )
                            : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                            2,
                            'KF_103_B',
                            _vm.infoList.KF_103_B_node_string,
                            'KF_103_J1',
                            '均热段空烟气阀反馈'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.KF_103_B,
                            'KF_103_B',
                            _vm.infoList.KF_103_B_node_string,
                            'KF_103_J1'
                          )}}},[_vm._v(" "+_vm._s('KF_103_B' in _vm.infoList?_vm.infoList.KF_103_B :0)+"% ")])]),_c('div',{staticClass:"thfamright2"},[_c('div',{staticClass:"btnflex flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK10 && !_vm.infoList.QK10.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK10
                              ? _vm.toDetail(
                                  2,
                                  'QK10',
                                  _vm.infoList.QK10_node_string,
                                  '',
                                  '均热段煤烟煤气阀门前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK2 && _vm.infoList.XK2.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK2
                              ? _vm.toDetail(
                                  1,
                                  'XK2',
                                  _vm.infoList.XK2_node_string,
                                  '',
                                  '均热段煤烟温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.MAN4 && _vm.infoList.MAN4.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.MAN4
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'MAN4',
                                  'MAN4_AV_J1',
                                  '均热段煤烟气阀门手操器MAN4'
                                )
                              : ''}}},[_vm._v(" A ")])]),_c('div',{staticClass:"text3 flex"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('p',{on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_104_B',
                              _vm.infoList.KF_104_B_node_string,
                              'KF_104_J1',
                              '均热段煤烟气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.KF_104_B,
                              'KF_104_B',
                              _vm.infoList.KF_104_B_node_string,
                              'KF_104_J1'
                            )}}},[_vm._v(" "+_vm._s('KF_104_B' in _vm.infoList?_vm.infoList.KF_104_B :0)+"% ")])])])])])])])])]),_c('div',{staticClass:"el-container-box-cen-coulm2"},[_c('div',{staticClass:"coulm2-box flex align-cen"},[_c('div',{staticClass:"coulm2-box-left"},[_c('p',[_vm._v("生产模式切换")]),_c('div',{class:_vm.infoList.MENLU == 2 ? 'active' : '',on:{"click":function($event){return _vm.chaneStu(2, 'MENLU', _vm.infoList.MENLU_node_string)}}},[_vm._v(" 闷炉 ")]),_c('div',{class:_vm.infoList.MENLU == 3 ? 'active' : '',on:{"click":function($event){return _vm.chaneStu(3, 'MENLU', _vm.infoList.MENLU_node_string)}}},[_vm._v(" 长时间闷炉 ")]),_c('div',{class:_vm.infoList.MENLU == 4 ? 'active' : '',on:{"click":function($event){return _vm.chaneStu(4, 'MENLU', _vm.infoList.MENLU_node_string)}}},[_vm._v(" 正常烧炉 ")])]),_c('div',{staticClass:"coulm2-box-right flex"},[_c('div',{staticClass:"right-list"},[_c('img',{staticClass:"jrlimg3",attrs:{"src":require("../../assets/images/jrlimg3.png"),"alt":""}}),_c('img',{staticClass:"jrlimg4",attrs:{"src":require("../../assets/images/jrlimg4.png"),"alt":""}}),_c('div',{staticClass:"coulm2-text1 flex"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_108A_B',
                        _vm.infoList.TICA_108A_B_node_string,
                        'TICA_108A_J1',
                        '一加热段空烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_108A_B,
                        'TICA_108A_B',
                        _vm.infoList.TICA_108A_B_node_string,
                        'TICA_108A_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_108A_B' in _vm.infoList?_vm.infoList.TICA_108A_B :0)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_108B_B',
                        _vm.infoList.TICA_108B_B_node_string,
                        'TICA_108B_J1',
                        '一加热段煤烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_108B_B,
                        'TICA_108B_B',
                        _vm.infoList.TICA_108B_B_node_string,
                        'TICA_108B_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_108B_B' in _vm.infoList?_vm.infoList.TICA_108B_B :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2 flex align-cen justify-content-cen"},[_c('div',{staticClass:"coulm2-text2-row1"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_107A_B',
                          _vm.infoList.TICA_107A_B_node_string,
                          'TICA_107A_J1',
                          '一加热段左侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.TICA_107A_B,
                          'TICA_107A_B',
                          _vm.infoList.TICA_107A_B_node_string,
                          'TICA_107A_J1'
                        )}}},[_vm._v(" "+_vm._s('TICA_107A_B' in _vm.infoList?_vm.infoList.TICA_107A_B :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_107B_B',
                          _vm.infoList.TICA_107B_B_node_string,
                          'TICA_107B_J1',
                          '一加热段右侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.TICA_107B_B,
                          'TICA_107B_B',
                          _vm.infoList.TICA_107B_B_node_string,
                          'TICA_107B_J1'
                        )}}},[_vm._v(" "+_vm._s('TICA_107B_B' in _vm.infoList?_vm.infoList.TICA_107B_B :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2-row2",on:{"click":function($event){return _vm.toCompon(9, 1)}}},[_c('div',[_vm._v("一加热段")])]),_c('div',{staticClass:"coulm2-text2-row3"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.T41SP,
                          '一加热段设定值',
                          'T41SP',
                          _vm.infoList.T41SP_node_string
                        )}}},[_vm._v(" "+_vm._s('T41SP' in _vm.infoList?_vm.infoList.T41SP :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T41SPSJ',
                          _vm.infoList.T41SPSJ_node_string,
                          'T41SPSJ_J1'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.T41SPSJ,
                          'T41SPSJ',
                          _vm.infoList.T41SPSJ_node_string,
                          'T41SPSJ_J1'
                        )}}},[_vm._v(" "+_vm._s('T41SPSJ' in _vm.infoList?_vm.infoList.T41SPSJ :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T41PV',
                          _vm.infoList.T41PV_node_string,
                          'T41PV_J1',
                          '一加热段上部温度均值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.T41PV,
                          'T41PV',
                          _vm.infoList.T41PV_node_string,
                          'T41PV_J1'
                        )}}},[_vm._v(" "+_vm._s('T41PV' in _vm.infoList?_vm.infoList.T41PV :0)+"℃ ")])])]),_c('div',{staticClass:"coulm2-text1 flex"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_108C_B',
                        _vm.infoList.TICA_108C_B_node_string,
                        'TICA_108C_J1',
                        '一加热段空烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_108C_B,
                        'TICA_108C_B',
                        _vm.infoList.TICA_108C_B_node_string,
                        'TICA_108C_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_108C_B' in _vm.infoList?_vm.infoList.TICA_108C_B :0)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_108D_B',
                        _vm.infoList.TICA_108D_B_node_string,
                        'TICA_108D_J1',
                        '一加热段煤烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_108D_B,
                        'TICA_108D_B',
                        _vm.infoList.TICA_108D_B_node_string,
                        'TICA_108D_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_108D_B' in _vm.infoList?_vm.infoList.TICA_108D_B :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-text3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'YJRHXT_B',
                      _vm.infoList.YJRHXT_B_node_string,
                      'YJRHXT_J1',
                      '一加热换向时间'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.YJRHXT_B,
                      'YJRHXT_B',
                      _vm.infoList.YJRHXT_B_node_string,
                      'YJRHXT_J1'
                    )}}},[_vm._v(" "+_vm._s('YJRHXT_B' in _vm.infoList?_vm.infoList.YJRHXT_B :0)+"S ")]),_c('div',{staticClass:"coulm2-line1"}),_c('div',{staticClass:"coulm2-line2"})]),_c('div',{staticClass:"right-list"},[_c('img',{staticClass:"jrlimg3",attrs:{"src":require("../../assets/images/jrlimg3.png"),"alt":""}}),_c('img',{staticClass:"jrlimg4",attrs:{"src":require("../../assets/images/jrlimg4.png"),"alt":""}}),_c('div',{staticClass:"coulm2-text1 flex"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_106A_B',
                        _vm.infoList.TICA_106A_B_node_string,
                        'TICA_106A_J1',
                        '二加热段空烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_106A_B,
                        'TICA_106A_B',
                        _vm.infoList.TICA_106A_B_node_string,
                        'TICA_106A_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_106A_B' in _vm.infoList?_vm.infoList.TICA_106A_B :0)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_106B_B',
                        _vm.infoList.TICA_106B_B_node_string,
                        'TICA_106B_J1',
                        '二加热段煤烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_106B_B,
                        'TICA_106B_B',
                        _vm.infoList.TICA_106B_B_node_string,
                        'TICA_106B_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_106B_B' in _vm.infoList?_vm.infoList.TICA_106B_B :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2 flex align-cen justify-content-cen"},[_c('div',{staticClass:"coulm2-text2-row1"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_105A_B',
                          _vm.infoList.TICA_105A_B_node_string,
                          'TICA_105A_J1',
                          '二加热段左侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.TICA_105A_B,
                          'TICA_105A_B',
                          _vm.infoList.TICA_105A_B_node_string,
                          'TICA_105A_J1'
                        )}}},[_vm._v(" "+_vm._s('TICA_105A_B' in _vm.infoList?_vm.infoList.TICA_105A_B :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_105B_B',
                          _vm.infoList.TICA_105B_B_node_string,
                          'TICA_105B_J1',
                          '二加热段右侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.TICA_105B_B,
                          'TICA_105B_B',
                          _vm.infoList.TICA_105B_B_node_string,
                          'TICA_105B_J1'
                        )}}},[_vm._v(" "+_vm._s('TICA_105B_B' in _vm.infoList?_vm.infoList.TICA_105B_B :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2-row2",on:{"click":function($event){return _vm.toCompon(9, 2)}}},[_c('div',[_vm._v("二加热段")])]),_c('div',{staticClass:"coulm2-text2-row3"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.T31SP,
                          '二加热段设定值',
                          'T31SP',
                          _vm.infoList.T31SP_node_string
                        )}}},[_vm._v(" "+_vm._s('T31SP' in _vm.infoList?_vm.infoList.T31SP :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T31SPSJ',
                          _vm.infoList.T31SPSJ_node_string,
                          'T31SPSJ_J1'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.T31SPSJ,
                          'T31SPSJ',
                          _vm.infoList.T31SPSJ_node_string,
                          'T31SPSJ_J1'
                        )}}},[_vm._v(" "+_vm._s('T31SPSJ' in _vm.infoList?_vm.infoList.T31SPSJ :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T31PV',
                          _vm.infoList.T31PV_node_string,
                          'T31PV_J1',
                          '二加热段上部温度均值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.T31PV,
                          'T31PV',
                          _vm.infoList.T31PV_node_string,
                          'T31PV_J1'
                        )}}},[_vm._v(" "+_vm._s('T31PV' in _vm.infoList?_vm.infoList.T31PV :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-line1"}),_c('div',{staticClass:"coulm2-line2"})]),_c('div',{staticClass:"coulm2-text1 flex"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_106C_B',
                        _vm.infoList.TICA_106C_B_node_string,
                        'TICA_106C_J1',
                        '二加热段空烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_106C_B,
                        'TICA_106C_B',
                        _vm.infoList.TICA_106C_B_node_string,
                        'TICA_106C_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_106C_B' in _vm.infoList?_vm.infoList.TICA_106C_B :0)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_106D_B',
                        _vm.infoList.TICA_106D_B_node_string,
                        'TICA_106D_J1',
                        '二加热段煤烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_106D_B,
                        'TICA_106D_B',
                        _vm.infoList.TICA_106D_B_node_string,
                        'TICA_106D_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_106D_B' in _vm.infoList?_vm.infoList.TICA_106D_B :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-text3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'EJRHXT_B',
                      _vm.infoList.EJRHXT_B_node_string,
                      'EJRHXT_J1',
                      '二加热换向时间'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.EJRHXT_B,
                      'EJRHXT_B',
                      _vm.infoList.EJRHXT_B_node_string,
                      'EJRHXT_J1'
                    )}}},[_vm._v(" "+_vm._s('EJRHXT_B' in _vm.infoList?_vm.infoList.EJRHXT_B :0)+"S ")])]),_c('div',{staticClass:"right-list"},[_c('img',{staticClass:"jrlimg3",attrs:{"src":require("../../assets/images/jrlimg3.png"),"alt":""}}),_c('img',{staticClass:"jrlimg4",attrs:{"src":require("../../assets/images/jrlimg4.png"),"alt":""}}),_c('div',{staticClass:"coulm2-text1 flex",staticStyle:{"padding-left":"0.7vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_104A_B',
                        _vm.infoList.TICA_104A_B_node_string,
                        'TICA_104A_J1',
                        '三加热段空烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_104A_B,
                        'TICA_104A_B',
                        _vm.infoList.TICA_104A_B_node_string,
                        'TICA_104A_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_104A_B' in _vm.infoList?_vm.infoList.TICA_104A_B :0)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_104B_B',
                        _vm.infoList.TICA_104B_B_node_string,
                        'TICA_104B_J1',
                        '三加热段煤烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_104B_B,
                        'TICA_104B_B',
                        _vm.infoList.TICA_104B_B_node_string,
                        'TICA_104B_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_104B_B' in _vm.infoList?_vm.infoList.TICA_104B_B :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2 flex align-cen justify-content-cen"},[_c('div',{staticClass:"coulm2-text2-row1"},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_103A_B',
                          _vm.infoList.TICA_103A_B_node_string,
                          'TICA_103A_J1',
                          '三加热段左侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.TICA_103A_B,
                          'TICA_103A_B',
                          _vm.infoList.TICA_103A_B_node_string,
                          'TICA_103A_J1'
                        )}}},[_vm._v(" "+_vm._s('TICA_103A_B' in _vm.infoList?_vm.infoList.TICA_103A_B :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_103B_B',
                          _vm.infoList.TICA_103B_B_node_string,
                          'TICA_103B_J1',
                          '三加热段右侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.TICA_103B_B,
                          'TICA_103B_B',
                          _vm.infoList.TICA_103B_B_node_string,
                          'TICA_103B_J1'
                        )}}},[_vm._v(" "+_vm._s('TICA_103B_B' in _vm.infoList?_vm.infoList.TICA_103B_B :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2-row2",on:{"click":function($event){return _vm.toCompon(9, 3)}}},[_c('div',[_vm._v("三加热段")])]),_c('div',{staticClass:"coulm2-text2-row3"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.T21SP,
                          '三加热段设定值',
                          'T21SP',
                          _vm.infoList.T21SP_node_string
                        )}}},[_vm._v(" "+_vm._s('T21SP' in _vm.infoList?_vm.infoList.T21SP :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T21SPSJ',
                          _vm.infoList.T21SPSJ_node_string,
                          'T21SPSJ_J1',
                          '实际温度控制点'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.T21SPSJ,
                          'T21SPSJ',
                          _vm.infoList.T21SPSJ_node_string,
                          'T21SPSJ_J1'
                        )}}},[_vm._v(" "+_vm._s('T21SPSJ' in _vm.infoList?_vm.infoList.T21SPSJ :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T21PV',
                          _vm.infoList.T21PV_node_string,
                          'T21PV_J1',
                          '三加热段温度均值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.T21PV,
                          'T21PV',
                          _vm.infoList.T21PV_node_string,
                          'T21PV_J1'
                        )}}},[_vm._v(" "+_vm._s('T21PV' in _vm.infoList?_vm.infoList.T21PV :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-line1"}),_c('div',{staticClass:"coulm2-line2"})]),_c('div',{staticClass:"coulm2-text1 flex",staticStyle:{"padding-left":"0.5vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_104C_B',
                        _vm.infoList.TICA_104C_B_node_string,
                        'TICA_104C_J1',
                        '三加热段空烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_104C_B,
                        'TICA_104C_B',
                        _vm.infoList.TICA_104C_B_node_string,
                        'TICA_104C_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_104C_B' in _vm.infoList?_vm.infoList.TICA_104C_B :0)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_104D_B',
                        _vm.infoList.TICA_104D_B_node_string,
                        'TICA_104D_J1',
                        '三加热段煤烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_104D_B,
                        'TICA_104D_B',
                        _vm.infoList.TICA_104D_B_node_string,
                        'TICA_104D_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_104D_B' in _vm.infoList?_vm.infoList.TICA_104D_B :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-text3",on:{"click":function($event){return _vm.toCompon(
                      2,
                      'SJRHXT_B',
                      _vm.infoList.SJRHXT_B_node_string,
                      'SJRHXT_J1',
                      '三加热换向时间'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.SJRHXT_B,
                      'SJRHXT_B',
                      _vm.infoList.SJRHXT_B_node_string,
                      'SJRHXT_J1'
                    )}}},[_vm._v(" "+_vm._s('SJRHXT_B' in _vm.infoList?_vm.infoList.SJRHXT_B :0)+"S ")])]),_c('div',{staticClass:"right-list"},[_c('img',{staticClass:"jrlimg3",attrs:{"src":require("../../assets/images/jrlimg3.png"),"alt":""}}),_c('img',{staticClass:"jrlimg4",attrs:{"src":require("../../assets/images/jrlimg4.png"),"alt":""}}),_c('div',{staticClass:"coulm2-text1 flex",staticStyle:{"padding-left":"0.7vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_102A_B',
                        _vm.infoList.TICA_102A_B_node_string,
                        'TICA_102A_J1',
                        '均热段空烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_102A_B,
                        'TICA_102A_B',
                        _vm.infoList.TICA_102A_B_node_string,
                        'TICA_102A_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_102A_B' in _vm.infoList?_vm.infoList.TICA_102A_B :0)+"℃ ")]),_c('div',{staticClass:"right-auto",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_102B_B',
                        _vm.infoList.TICA_102B_B_node_string,
                        'TICA_102B_J1',
                        '均热段煤烟排烟温度'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_102B_B,
                        'TICA_102B_B',
                        _vm.infoList.TICA_102B_B_node_string,
                        'TICA_102B_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_102B_B' in _vm.infoList?_vm.infoList.TICA_102B_B :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2 flex align-cen"},[_c('div',{staticClass:"coulm2-text2-row1",staticStyle:{"padding":"1vw"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_101A_B',
                          _vm.infoList.TICA_101A_B_node_string,
                          'TICA_101A_J1',
                          '均热段左侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.TICA_101A_B,
                          'TICA_101A_B',
                          _vm.infoList.TICA_101A_B_node_string,
                          'TICA_101A_J1'
                        )}}},[_vm._v(" "+_vm._s('TICA_101A_B' in _vm.infoList?_vm.infoList.TICA_101A_B :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TICA_101B_B',
                          _vm.infoList.TICA_101B_B_node_string,
                          'TICA_101B_J1',
                          '均热段右侧温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.TICA_101B_B,
                          'TICA_101B_B',
                          _vm.infoList.TICA_101B_B_node_string,
                          'TICA_101B_J1'
                        )}}},[_vm._v(" "+_vm._s('TICA_101B_B' in _vm.infoList?_vm.infoList.TICA_101B_B :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-text2-row2",staticStyle:{"padding-left":"1vw","padding-right":"0"}},[_c('div',{on:{"click":function($event){return _vm.toCompon(9, 4)}}},[_vm._v("均热段")])]),_c('div',{staticClass:"coulm2-text2-row3 flex",staticStyle:{"position":"absolute","font-size":"0.8vw","right":"-0.7vw","top":"4vh"}},[_c('div',{staticClass:"flex-row",staticStyle:{"padding-right":"2vw"}},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                            _vm.infoList.T11SP,
                            '均热段设定值',
                            'T11SP',
                            _vm.infoList.T11SP_node_string
                          )}}},[_vm._v(" "+_vm._s('T11SP' in _vm.infoList?_vm.infoList.T11SP :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                            2,
                            'T11SPSJ',
                            _vm.infoList.T11SPSJ_node_string,
                            'T11SPSJ_J1',
                            '实际温度控制点'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.T11SPSJ,
                            'T11SPSJ',
                            _vm.infoList.T11SPSJ_node_string,
                            'T11SPSJ_J1'
                          )}}},[_vm._v(" "+_vm._s('T11SPSJ' in _vm.infoList?_vm.infoList.T11SPSJ :0)+"℃ ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                            2,
                            'T11PV',
                            _vm.infoList.T11PV_node_string,
                            'T11PV_J1',
                            '均热段上部温度均值'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.T11PV,
                            'T11PV',
                            _vm.infoList.T11PV_node_string,
                            'T11PV_J1'
                          )}}},[_vm._v(" "+_vm._s('T11PV' in _vm.infoList?_vm.infoList.T11PV :0)+"℃ ")])]),_c('div',{staticClass:"flex-row"},[_c('div',{staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                            _vm.infoList.FYSP,
                            '负压控制点',
                            'FYSP',
                            _vm.infoList.FYSP_node_string
                          )}}},[_vm._v(" "+_vm._s('FYSP' in _vm.infoList?_vm.infoList.FYSP :0)+"Pa ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                            2,
                            'PV',
                            _vm.infoList.XK13_node_string,
                            'XK13_PV_J1',
                            '炉膛压力控制XK'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.XK13.PV,
                            'PV',
                            _vm.infoList.XK13_node_string,
                            'XK13_PV_J1'
                          )}}},[_vm._v(" "+_vm._s('XK13' in _vm.infoList?_vm.infoList.XK13.PV :0)+"Pa ")]),_c('div',{on:{"click":function($event){return _vm.toCompon(
                            2,
                            'PICA_101_B',
                            _vm.infoList.PICA_101_B_node_string,
                            'PICA_101_J1',
                            '炉膛压力'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.PICA_101_B,
                            'PICA_101_B',
                            _vm.infoList.PICA_101_B_node_string,
                            'PICA_101_J1'
                          )}}},[_vm._v(" "+_vm._s('PICA_101_B' in _vm.infoList?_vm.infoList.PICA_101_B :0)+"℃ ")])])])]),_c('div',{staticClass:"coulm2-text1 flex",staticStyle:{"padding-left":"0.7vw"}},[_c('div',{staticStyle:{"padding":"0 0.5vw"},on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_102C_B',
                        _vm.infoList.TICA_102C_B_node_string,
                        'TICA_102C_J1',
                        '均热段空烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_102C_B,
                        'TICA_102C_B',
                        _vm.infoList.TICA_102C_B_node_string,
                        'TICA_102C_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_102C_B' in _vm.infoList?_vm.infoList.TICA_102C_B :0)+"℃ ")]),_c('div',{staticClass:"right-auto",staticStyle:{"padding":"0 0.5vw"},on:{"click":function($event){return _vm.toCompon(
                        2,
                        'TICA_102D_B',
                        _vm.infoList.TICA_102D_B_node_string,
                        'TICA_102D_J1',
                        '均热段煤烟排烟温度下'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.TICA_102D_B,
                        'TICA_102D_B',
                        _vm.infoList.TICA_102D_B_node_string,
                        'TICA_102D_J1'
                      )}}},[_vm._v(" "+_vm._s('TICA_102D_B' in _vm.infoList?_vm.infoList.TICA_102D_B :0)+"℃ ")])]),_c('div',{staticClass:"coulm2-text3",staticStyle:{"left":"7.5vw"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'JRDHXT_B',
                      _vm.infoList.JRDHXT_B_node_string,
                      'JRDHXT_J1',
                      '均热段换向时间'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.JRDHXT_B,
                      'JRDHXT_B',
                      _vm.infoList.JRDHXT_B_node_string,
                      'JRDHXT_J1'
                    )}}},[_vm._v(" "+_vm._s('JRDHXT_B' in _vm.infoList?_vm.infoList.JRDHXT_B :0)+"S ")]),_c('div',{staticClass:"coulm2-line1"}),_c('div',{staticClass:"coulm2-line2"})])])])]),_c('div',{staticClass:"el-container-box-cen-coulm3"},[_c('div',{staticClass:"coulm3-box flex align-cen"},[_c('div',{staticClass:"coulm3-box-left"},[_c('div',{staticClass:"left-text",staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.PKQ_SP1,
                    '空气总管压力设定值',
                    'PKQ_SP1',
                    _vm.infoList.PKQ_SP1_node_string
                  )}}},[_vm._v(" "+_vm._s('PKQ_SP1' in _vm.infoList?_vm.infoList.PKQ_SP1 :0)+" KPa ")]),_c('div',{staticClass:"left-text",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'PIA_101_B',
                    _vm.infoList.PIA_101_B_node_string,
                    'PIA_101_J1',
                    '均热段空气压力'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.PIA_101_B,
                    'PIA_101_B',
                    _vm.infoList.PIA_101_B_node_string,
                    'PIA_101_J1'
                  )}}},[_vm._v(" "+_vm._s('PIA_101_B' in _vm.infoList?_vm.infoList.PIA_101_B :0)+" KPa ")]),_c('div',{staticClass:"left1 flex align-cen"},[_vm._m(1),_c('img',{staticClass:"left1-jflimg1",attrs:{"src":require("../../assets/images/rts_jrlimg1.png"),"alt":""}}),_c('div',{staticClass:"line1"}),_c('div',{staticClass:"left-btn"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                      background:
                        _vm.infoList.QK6 && !_vm.infoList.QK6.TS ? '#2AFC30' : 'red',
                    }),on:{"click":function($event){_vm.infoList.QK6
                        ? _vm.toDetail(
                            2,
                            'QK6',
                            _vm.infoList.QK6_node_string,
                            '',
                            '鼓风机空气阀门前馈'
                          )
                        : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                      background:
                        _vm.infoList.XK23 && _vm.infoList.XK23.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.XK23
                        ? _vm.toDetail(
                            1,
                            'XK23',
                            _vm.infoList.XK23_node_string,
                            '',
                            '空气总管压力XK'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                      background:
                        _vm.infoList.MAN16 && _vm.infoList.MAN16.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.MAN16
                        ? _vm.toCompon(
                            0,
                            'AV',
                            'MAN16',
                            'MAN16_AV_J1',
                            '均热鼓风机手操器MAN16'
                          )
                        : ''}}},[_vm._v(" A ")])])]),_c('div',{staticClass:"left-text left-text1",on:{"click":function($event){_vm.toCompon(
                    2,
                    'KF_116_B',
                    _vm.infoList.KF_116_B_node_string,
                    'KF_116_J1',
                    '鼓风机(均热段)反馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.KF_116_B,
                    'KF_116_B',
                    _vm.infoList.KF_116_B_node_string,
                    'KF_116_J1'
                  )}}},[_vm._v(" "+_vm._s('KF_116_B' in _vm.infoList?_vm.infoList.KF_116_B :0)+"% ")]),_c('div',{staticClass:"left1 left2 flex align-cen"},[_vm._m(2),_c('img',{staticClass:"left1-jflimg1",attrs:{"src":require("../../assets/images/rts_jrlimg1.png"),"alt":""}}),_c('div',{staticClass:"line1"}),_c('div',{staticClass:"left-btn"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                      background:
                        _vm.infoList.XK24 && _vm.infoList.XK24.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.XK24
                        ? _vm.toDetail(
                            1,
                            'XK24',
                            _vm.infoList.XK24_node_string,
                            '',
                            '空气总管压力XK'
                          )
                        : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                      background:
                        _vm.infoList.MAN17 && _vm.infoList.MAN17.RM == 1
                          ? '#2AFC30'
                          : 'red',
                    }),on:{"click":function($event){_vm.infoList.MAN17
                        ? _vm.toCompon(
                            0,
                            'AV',
                            'MAN17',
                            'MAN17_AV_J1',
                            '三热段鼓风机手操器MAN17'
                          )
                        : ''}}},[_vm._v(" A ")])])]),_c('div',{staticClass:"left-text left-text1",on:{"click":function($event){_vm.toCompon(
                    2,
                    'KF_117_B',
                    _vm.infoList.KF_117_B_node_string,
                    'KF_117_J1',
                    '鼓风机(三加热段)反馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.KF_117_B,
                    'KF_117_B',
                    _vm.infoList.KF_117_B_node_string,
                    'KF_117_J1'
                  )}}},[_vm._v(" "+_vm._s('KF_117_B' in _vm.infoList?_vm.infoList.KF_117_B :0)+"% ")])]),_vm._m(3),_c('div',{staticClass:"coulm3-list"},[_c('div',{staticClass:"coulm3-list-top flex"},[_c('div',{staticClass:"list-o"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KF_120_B',
                          _vm.infoList.KF_120_B_node_string,
                          'KF_120_J1',
                          '一热段空气阀反馈'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.KF_120_B,
                          'KF_120_B',
                          _vm.infoList.KF_120_B_node_string,
                          'KF_120_J1'
                        )}}},[_vm._v(" "+_vm._s('KF_120_B' in _vm.infoList?_vm.infoList.KF_120_B :0)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'FICA_107_B',
                          _vm.infoList.FICA_107_B_node_string,
                          'FICA_107_J1',
                          '一加热段空气流量'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.FICA_107_B,
                          'FICA_107_B',
                          _vm.infoList.FICA_107_B_node_string,
                          'FICA_107_J1'
                        )}}},[_vm._v(" "+_vm._s('FICA_107_B' in _vm.infoList?_vm.infoList.FICA_107_B :0)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                          background:
                            _vm.infoList.XK22 && _vm.infoList.XK22.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.XK22
                            ? _vm.toDetail(
                                1,
                                'XK22',
                                _vm.infoList.XK22_node_string,
                                '',
                                '一加热空气流量XK'
                              )
                            : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                          background:
                            _vm.infoList.MAN20 && _vm.infoList.MAN20.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.MAN20
                            ? _vm.toCompon(
                                0,
                                'AV',
                                'MAN20',
                                'MAN20_AV_J1',
                                '一热段空气阀门手操器MAN20'
                              )
                            : ''}}},[_vm._v(" A ")])])])]),_c('div',{staticClass:"list flex"},[_vm._m(4),_c('div',{staticClass:"list-row1 list-row2"},[_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o1"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_109_B',
                              _vm.infoList.KF_109_B_node_string,
                              'KF_109_J1',
                              '二热段空气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.KF_109_B,
                              'KF_109_B',
                              _vm.infoList.KF_109_B_node_string,
                              'KF_109_J1'
                            )}}},[_vm._v(" "+_vm._s('KF_109_B' in _vm.infoList?_vm.infoList.KF_109_B :0)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'FICA_105_B',
                              _vm.infoList.FICA_105_B_node_string,
                              'FICA_105_J1',
                              '二加热段空气流量'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.FICA_105_B,
                              'FICA_105_B',
                              _vm.infoList.FICA_105_B_node_string,
                              'FICA_105_J1'
                            )}}},[_vm._v(" "+_vm._s('FICA_105_B' in _vm.infoList?_vm.infoList.FICA_105_B :0)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                              background:
                                _vm.infoList.XK20 && _vm.infoList.XK20.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }),on:{"click":function($event){_vm.infoList.XK20
                                ? _vm.toDetail(
                                    1,
                                    'XK20',
                                    _vm.infoList.XK20_node_string,
                                    '',
                                    '二加热空气流量XK'
                                  )
                                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                              background:
                                _vm.infoList.MAN9 && _vm.infoList.MAN9.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }),on:{"click":function($event){_vm.infoList.MAN9
                                ? _vm.toCompon(
                                    0,
                                    'AV',
                                    'MAN9',
                                    'MAN9_AV_J1',
                                    '二热段空气阀门手操器MAN9'
                                  )
                                : ''}}},[_vm._v(" A ")])])])])]),_c('div',{staticClass:"list-row1 list-row2 list-row3"},[_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o1"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_105_B',
                              _vm.infoList.KF_105_B_node_string,
                              'KF_105_J1',
                              '三热段空气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.KF_105_B,
                              'KF_105_B',
                              _vm.infoList.KF_105_B_node_string,
                              'KF_105_J1'
                            )}}},[_vm._v(" "+_vm._s('KF_105_B' in _vm.infoList?_vm.infoList.KF_105_B :0)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'FICA_103_B',
                              _vm.infoList.FICA_103_B_node_string,
                              'FICA_103_J1',
                              '三加热段空气流量'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.FICA_103_B,
                              'FICA_103_B',
                              _vm.infoList.FICA_103_B_node_string,
                              'FICA_103_J1'
                            )}}},[_vm._v(" "+_vm._s('FICA_103_B' in _vm.infoList?_vm.infoList.FICA_103_B :0)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                              background:
                                _vm.infoList.XK18 && _vm.infoList.XK18.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }),on:{"click":function($event){_vm.infoList.XK18
                                ? _vm.toDetail(
                                    1,
                                    'XK18',
                                    _vm.infoList.XK18_node_string,
                                    '',
                                    '三加热空气流量XK'
                                  )
                                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                              background:
                                _vm.infoList.MAN5 && _vm.infoList.MAN5.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }),on:{"click":function($event){_vm.infoList.MAN5
                                ? _vm.toCompon(
                                    0,
                                    'AV',
                                    'MAN5',
                                    'MAN5_AV_J1',
                                    '三热段空气阀门手操器MAN5'
                                  )
                                : ''}}},[_vm._v(" A ")])])])]),_c('img',{staticClass:"list-row2-jflimg3",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o2"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'KF_101_B',
                              _vm.infoList.KF_101_B_node_string,
                              'KF_101_J1',
                              '均热段空气阀反馈'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.KF_101_B,
                              'KF_101_B',
                              _vm.infoList.KF_101_B_node_string,
                              'KF_101_J1'
                            )}}},[_vm._v(" "+_vm._s('KF_101_B' in _vm.infoList?_vm.infoList.KF_101_B :0)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                              2,
                              'FICA_101_B',
                              _vm.infoList.FICA_101_B_node_string,
                              'FICA_101_J1',
                              '均热段空气流量'
                            )},"dblclick":function($event){return _vm.Cclick(
                              _vm.infoList.FICA_101_B,
                              'FICA_101_B',
                              _vm.infoList.FICA_101_B_node_string,
                              'FICA_101_J1'
                            )}}},[_vm._v(" "+_vm._s('FICA_101_B' in _vm.infoList?_vm.infoList.FICA_101_B :0)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataX child-databtn",style:({
                              background:
                                _vm.infoList.XK16 && _vm.infoList.XK16.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }),on:{"click":function($event){_vm.infoList.XK16
                                ? _vm.toDetail(
                                    1,
                                    'XK16',
                                    _vm.infoList.XK16_node_string,
                                    '',
                                    '均热空气流量XK'
                                  )
                                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                              background:
                                _vm.infoList.MAN1 && _vm.infoList.MAN1.RM == 1
                                  ? '#2AFC30'
                                  : 'red',
                            }),on:{"click":function($event){_vm.infoList.MAN1
                                ? _vm.toCompon(
                                    0,
                                    'AV',
                                    'MAN1',
                                    'MAN1_AV_J1',
                                    '均热段空气阀门手操器MAN1'
                                  )
                                : ''}}},[_vm._v(" A ")])])])])])])]),_c('div',{staticClass:"coulm3-list-bot flex"},[_c('div',{staticClass:"coulm3-list-line"}),_c('div',{staticClass:"coulm3-list-bot-list flex"},[_c('div',{staticClass:"bot-text"},[_c('div',{staticClass:"kq",on:{"click":function($event){return _vm.toCompon(7)}}},[_vm._v("空气总管")]),_c('div',{staticClass:"mq",on:{"click":function($event){return _vm.toCompon(8)}}},[_vm._v("煤气总管")])]),_c('div',{staticClass:"maright ma-auto"},[_c('img',{staticClass:"ma-auto-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"mapos",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KF_124_B',
                          _vm.infoList.KF_124_B_node_string,
                          'KF_124_J1',
                          '煤气总管压力调节阀反馈'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.KF_124_B,
                          'KF_124_B',
                          _vm.infoList.KF_124_B_node_string,
                          'KF_124_J1'
                        )}}},[_vm._v(" "+_vm._s('KF_124_B' in _vm.infoList?_vm.infoList.KF_124_B :0)+"% ")]),_c('div',{staticClass:"mapos1",staticStyle:{"color":"rgb(0, 180, 255)"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.PMQ_SP,
                          '修改',
                          'PMQ_SP',
                          _vm.infoList.PMQ_SP_node_string
                        )}}},[_vm._v(" "+_vm._s('PMQ_SP' in _vm.infoList?_vm.infoList.PMQ_SP :0)+"Kpa ")]),_c('div',{staticClass:"mapos2",on:{"click":function($event){return _vm.toCompon(
                          2,
                          'PIA_105_B',
                          _vm.infoList.PIA_105_B_node_string,
                          'PIA_105_J1',
                          '煤气总管压力'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.PIA_105_B,
                          'PIA_105_B',
                          _vm.infoList.PIA_105_B_node_string,
                          'PIA_105_J1'
                        )}}},[_vm._v(" "+_vm._s('PIA_105_B' in _vm.infoList?_vm.infoList.PIA_105_B :0)+"Kpa ")]),_c('div',{staticClass:"ma-btn flex justify-content-cen"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                          background:
                            _vm.infoList.QK7 && !_vm.infoList.QK7.TS
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.QK7
                            ? _vm.toDetail(
                                2,
                                'QK7',
                                _vm.infoList.QK7_node_string,
                                '',
                                '煤气总管煤气阀门前馈'
                              )
                            : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                          background:
                            _vm.infoList.XK14 && _vm.infoList.XK14.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.XK14
                            ? _vm.toDetail(
                                1,
                                'XK14',
                                _vm.infoList.XK14_node_string,
                                '',
                                '煤气总管压力控制XK'
                              )
                            : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                          background:
                            _vm.infoList.MAN24 && _vm.infoList.MAN24.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }),on:{"click":function($event){_vm.infoList.MAN24
                            ? _vm.toCompon(
                                0,
                                'AV',
                                'MAN24',
                                'MAN24_AV_J1',
                                '煤气总管压力手操器MAN24'
                              )
                            : ''}}},[_vm._v(" A ")])])]),_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o1"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'KF_121_B',
                            _vm.infoList.KF_121_B_node_string,
                            'KF_121_J1',
                            '一热段煤气阀反馈'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.KF_121_B,
                            'KF_121_B',
                            _vm.infoList.KF_121_B_node_string,
                            'KF_121_J1'
                          )}}},[_vm._v(" "+_vm._s('KF_121_B' in _vm.infoList?_vm.infoList.KF_121_B :0)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'FICA_108_B',
                            _vm.infoList.FICA_108_B_node_string,
                            'FICA_108_J1',
                            '一加热段煤气流量'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.FICA_108_B,
                            'FICA_108_B',
                            _vm.infoList.FICA_108_B_node_string,
                            'FICA_108_J1'
                          )}}},[_vm._v(" "+_vm._s('FICA_108_B' in _vm.infoList?_vm.infoList.FICA_108_B :0)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK17 && !_vm.infoList.QK17.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK17
                              ? _vm.toDetail(
                                  2,
                                  'QK17',
                                  _vm.infoList.QK17_node_string,
                                  '',
                                  '一加热入炉钢温前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK10 && _vm.infoList.XK10.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK10
                              ? _vm.toDetail(
                                  1,
                                  'XK10',
                                  _vm.infoList.XK10_node_string,
                                  '',
                                  '一加热段炉膛温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK21 && _vm.infoList.XK21.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK21
                              ? _vm.toDetail(
                                  1,
                                  'XK21',
                                  _vm.infoList.XK21_node_string,
                                  '',
                                  '一加热煤气流量XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.MAN21 && _vm.infoList.MAN21.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.MAN21
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'MAN21',
                                  'MAN21_AV_J1',
                                  '一加热段煤气阀门手操器MAN21'
                                )
                              : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK21 && !_vm.infoList.QK21.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK21
                              ? _vm.toDetail(
                                  2,
                                  'QK21',
                                  _vm.infoList.QK21_node_string,
                                  '',
                                  '一加热段煤气压力前馈'
                                )
                              : ''}}},[_vm._v(" K ")])])])])]),_c('div',{staticClass:"coulm3-list-bot-list coulm3-list-bot-list1 flex"},[_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o1"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'KF_110_B',
                            _vm.infoList.KF_110_B_node_string,
                            'KF_110_J1',
                            '二热段煤气阀反馈'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.KF_110_B,
                            'KF_110_B',
                            _vm.infoList.KF_110_B_node_string,
                            'KF_110_J1'
                          )}}},[_vm._v(" "+_vm._s('KF_110_B' in _vm.infoList?_vm.infoList.KF_110_B :0)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'FICA_106_B',
                            _vm.infoList.FICA_106_B_node_string,
                            'FICA_106_J1',
                            '二加热段煤气流量'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.FICA_106_B,
                            'FICA_106_B',
                            _vm.infoList.FICA_106_B_node_string,
                            'FICA_106_J1'
                          )}}},[_vm._v(" "+_vm._s('FICA_106_B' in _vm.infoList?_vm.infoList.FICA_106_B :0)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK18 && !_vm.infoList.QK18.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK18
                              ? _vm.toDetail(
                                  2,
                                  'QK18',
                                  _vm.infoList.QK18_node_string,
                                  '',
                                  '二加热入炉钢温前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK7 && _vm.infoList.XK7.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK7
                              ? _vm.toDetail(
                                  1,
                                  'XK7',
                                  _vm.infoList.XK7_node_string,
                                  '',
                                  '二加热段炉膛温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK19 && _vm.infoList.XK19.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK19
                              ? _vm.toDetail(
                                  1,
                                  'XK19',
                                  _vm.infoList.XK19_node_string,
                                  '',
                                  '二加热煤气流量XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.MAN10 && _vm.infoList.MAN10.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.MAN10
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'MAN10',
                                  'MAN10_AV_J1',
                                  '二加热段煤气阀门手操器MAN10'
                                )
                              : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK22 && !_vm.infoList.QK22.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK22
                              ? _vm.toDetail(
                                  2,
                                  'QK22',
                                  _vm.infoList.QK22_node_string,
                                  '',
                                  '二加热段煤气压力前馈'
                                )
                              : ''}}},[_vm._v(" K ")])])])])]),_c('div',{staticClass:"coulm3-list-bot-list coulm3-list-bot-list1 flex"},[_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o1"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'KF_106_B',
                            _vm.infoList.KF_106_B_node_string,
                            'KF_106_J1',
                            '三热段煤气阀反馈'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.KF_106_B,
                            'KF_106_B',
                            _vm.infoList.KF_106_B_node_string,
                            'KF_106_J1'
                          )}}},[_vm._v(" "+_vm._s('KF_106_B' in _vm.infoList?_vm.infoList.KF_106_B :0)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'FICA_104_B',
                            _vm.infoList.FICA_104_B_node_string,
                            'FICA_104_J1',
                            '三加热段煤气流量'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.FICA_104_B,
                            'FICA_104_B',
                            _vm.infoList.FICA_104_B_node_string,
                            'FICA_104_J1'
                          )}}},[_vm._v(" "+_vm._s('FICA_104_B' in _vm.infoList?_vm.infoList.FICA_104_B :0)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK19 && !_vm.infoList.QK19.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK19
                              ? _vm.toDetail(
                                  2,
                                  'QK19',
                                  _vm.infoList.QK19_node_string,
                                  '',
                                  '三加热入炉钢温前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK4 && _vm.infoList.XK4.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK4
                              ? _vm.toDetail(
                                  1,
                                  'XK4',
                                  _vm.infoList.XK4_node_string,
                                  '',
                                  '三加热段炉膛温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK17 && _vm.infoList.XK17.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK17
                              ? _vm.toDetail(
                                  1,
                                  'XK17',
                                  _vm.infoList.XK17_node_string,
                                  '',
                                  '三加热煤气流量XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.MAN6 && _vm.infoList.MAN6.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.MAN6
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'MAN6',
                                  'MAN6_AV_J1',
                                  '三加热段煤气阀门手操器MAN6'
                                )
                              : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK23 && !_vm.infoList.QK23.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK23
                              ? _vm.toDetail(
                                  2,
                                  'QK23',
                                  _vm.infoList.QK23_node_string,
                                  '',
                                  '三加热段煤气压力前馈'
                                )
                              : ''}}},[_vm._v(" K ")])])])])]),_c('div',{staticClass:"coulm3-list-bot-list coulm3-list-bot-list1 flex"},[_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}}),_c('div',{staticClass:"list-o1"},[_c('div',{staticClass:"list-text"},[_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'KF_102_B',
                            _vm.infoList.KF_102_B_node_string,
                            'KF_102_J1',
                            '均热段煤气阀反馈'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.KF_102_B,
                            'KF_102_B',
                            _vm.infoList.KF_102_B_node_string,
                            'KF_102_J1'
                          )}}},[_vm._v(" "+_vm._s('KF_102_B' in _vm.infoList?_vm.infoList.KF_102_B :0)+"% ")]),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.toCompon(
                            2,
                            'FICA_102_B',
                            _vm.infoList.FICA_102_B_node_string,
                            'FICA_102_J1',
                            '均热段煤气流量'
                          )},"dblclick":function($event){return _vm.Cclick(
                            _vm.infoList.FICA_102_B,
                            'FICA_102_B',
                            _vm.infoList.FICA_102_B_node_string,
                            'FICA_102_J1'
                          )}}},[_vm._v(" "+_vm._s('FICA_102_B' in _vm.infoList?_vm.infoList.FICA_102_B :0)+"m³/h ")]),_c('div',{staticClass:"btn flex"},[_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK20 && !_vm.infoList.QK20.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK20
                              ? _vm.toDetail(
                                  2,
                                  'QK20',
                                  _vm.infoList.QK20_node_string,
                                  '',
                                  '均热入炉钢温前馈'
                                )
                              : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK1 && _vm.infoList.XK1.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK1
                              ? _vm.toDetail(
                                  1,
                                  'XK1',
                                  _vm.infoList.XK1_node_string,
                                  '',
                                  '均热段炉膛温度控制XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataX child-databtn",style:({
                            background:
                              _vm.infoList.XK15 && _vm.infoList.XK15.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.XK15
                              ? _vm.toDetail(
                                  1,
                                  'XK15',
                                  _vm.infoList.XK15_node_string,
                                  '',
                                  '均热煤气流量XK'
                                )
                              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"child-dataA child-databtn",style:({
                            background:
                              _vm.infoList.MAN2 && _vm.infoList.MAN2.RM == 1
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.MAN2
                              ? _vm.toCompon(
                                  0,
                                  'AV',
                                  'MAN2',
                                  'MAN2_AV_J1',
                                  '均热段煤气阀门手操器MAN2'
                                )
                              : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"child-dataK child-databtn",style:({
                            background:
                              _vm.infoList.QK24 && !_vm.infoList.QK24.TS
                                ? '#2AFC30'
                                : 'red',
                          }),on:{"click":function($event){_vm.infoList.QK24
                              ? _vm.toDetail(
                                  2,
                                  'QK24',
                                  _vm.infoList.QK24_node_string,
                                  '',
                                  '均热段煤气压力前馈'
                                )
                              : ''}}},[_vm._v(" K ")])])])])])]),_c('div',{staticClass:"coulm3-line"})])])])])])]),(_vm.alllModel)?_c('JrallModel',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.JRLParam01)?_c('JRLParam01',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.JRLParam02)?_c('JRLParam02',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.JRLParam03)?_c('JRLParam03',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.JRLParam04)?_c('JRLParam04',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.JRLParam05)?_c('JRLParam05',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.flag == 4 ? (_vm.isComShow = true) : (_vm.isComShow = false))?_c('JRLcysz',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.JrparmsGroup)?_c('JrparmsGroup',{attrs:{"infoList":_vm.infoList,"datatype":_vm.historyname,"canshu1":_vm.canshu1},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.Jrwtdata)?_c('Jrwtdata',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.nodename,"Lkname":_vm.Aname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),_c('inputVal',{ref:"inputVal"}),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fam1"},[_c('img',{staticClass:"jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line"},[_c('img',{staticClass:"line-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line"},[_c('img',{staticClass:"line-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line-box"},[_c('div',{staticClass:"line1"}),_c('div',{staticClass:"line1 line2"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-row1"},[_c('img',{staticClass:"list-row1-jflimg2",attrs:{"src":require("../../assets/images/jflimg2.png"),"alt":""}})])}]

export { render, staticRenderFns }