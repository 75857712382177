<template>
  <div class="jrlpamrm01 drag" id="jrlpamrm01"  v-draw>
    <!-- 标题部分 -->
   <div>
    <div class="title-hang" fl> 
     {{ projectData.project}}_参数01
    </div>
         <div class="param11-header-icon"  fr @click="closeCompon">
        <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
    </div>
  <div class="titlelable">钢坯钢损模型</div>
  <div class="lable1">钢损模型</div>
  <div class="main-top">
    <div class="main-row1">
      <div class="row1 fl ">
     <div class="col1" > A01-T_RG</div>
     <div class="col1"> A01-T_CG</div>
     <div class="col1"> A01-C02</div>
     <div class="col1"> A01-GGL</div>
      </div>
            <div class="row2 fl ">
     <div class="col2" @click="toCompon(2, 'T_RG_PV', infoList.T_RG_PV_node_string, 'GSMX_RGT_J1','钢损模型入钢温度')">{{"T_RG_PV" in infoList ?infoList.T_RG_PV:0}}</div>
     <div class="col2" @click="toCompon(2, 'T_CG_PV', infoList.T_CG_PV_node_string, 'GSMX_CGT_J1','钢损模型出钢温度')">{{"T_CG_PV" in infoList ?infoList.T_CG_PV:0}}</div>
     <div class="col2" @click="toCompon(2, 'CO2_PV', infoList.CO2_PV_node_string, 'GSMX_CO2_J1','钢损模型烟气氧量')">{{"CO2_PV" in infoList ?infoList.CO2_PV:0}}</div>
     <div class="col2" @click="toCompon(2, 'GGL', infoList.GGL_node_string, 'GSMX_GGL_J1','钢损模型过钢量')">{{"GGL" in infoList ?infoList.GGL:0}}</div> 
     </div>
           <div class="row1 fl ">
     <div class="col1" > A01-K</div>
     <div class="col1"> A01-P_ALFA</div>
     <div class="col1"> A01-K_ALFA</div>
     <div class="col1"> A01-KP</div>
      </div>
            <div class="row2 color fl ">
     <div class="col2 "   @click="
                  toIpt(
                    infoList.GSMX1.K,
                    ' A01-K',
                    'K',
                    GSMX1_node_string,
                    
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.K :0}}</div>
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.P_ALFA,
                    'A01-P_ALFA',
                     'P_ALFA',
                     GSMX1_node_string,
                   
                  )
                "
        >
        {{"GSMX1" in  infoList ?  infoList.GSMX1.P_ALFA :0}}</div>
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.K_ALFA,
                    'A01-K_ALFA',
                    'K_ALFA',
                      GSMX1_node_string,
                    
                  )
                "
        >
        {{"GSMX1" in  infoList ?  infoList.GSMX1.K_ALFA :0}}</div>
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.KP,
                    'A01-KP',
                      'KP',
                    GSMX1_node_string,
                  
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.KP :0}}</div>
      </div>
           <div class="row1 fl ">
     <div class="col1" > A01-PP</div>
     <div class="col1"> A01-E00</div>
     <div class="col1"> A01-BP_L</div>
     <div class="col1"> A01-BP_W</div>
      </div>
            <div class="row2 color fl ">
     <div class="col2"  @click="
                  toIpt(
                    infoList.GSMX1.PP,
                    'A01-PP',
                     'PP',
                    GSMX1_node_string,
                   
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.PP :0}}</div>
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.E00,
                    'A01-E00',
                     'E00',
                    GSMX1_node_string,
                   
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.E00 :0}}</div>
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.BP_L,
                    'A01-BP_L',
                    'BP_L',
                    GSMX1_node_string,
                    
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.BP_L :0}}</div>
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.BP_W,
                    'A01-BP_W',
                     'BP_W',
                    GSMX1_node_string,
                   
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.BP_W :0}}</div>
      </div>
           <div class="row1 fl ">
     <div class="col1" >A01-BP_H</div>
     <div class="col1"> A01-BP_R</div>

      </div>
            <div class="row2 color fl ">
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.BP_H,
                    'A01-BP_H',
                     'BP_H',
                    GSMX1_node_string,
                   
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.BP_H :0}}</div>
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.BP_R,
                    'A01-BP_H',
                     'BP_R',
                    GSMX1_node_string,
                   
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.BP_R :0}}</div>

      </div>
    </div>
  
    <div class="main-row2">
      <div class="row1 fl ">
     <div class="col1" > A01-T00</div>
     <div class="col1"> A01-Har</div>
     <div class="col1"> A01-Oar</div>
     <div class="col1"> A01-Car</div>
      </div>
            <div class="row2 fl ">
     <div class="col2 color" @click="
                  toIpt(
                    infoList.GSMX1.T00,
                    'A01-T00',
                     'T00',
                    GSMX1_node_string,
                   
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.T00 :0}}</div>
     <div class="col2 color" @click="
                  toIpt(
                    infoList.GSMX1.Har,
                    'A01-Har',
                     'Har',
                    GSMX1_node_string,
                   
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.Har :0}}</div>
     <div class="col2 color" @click="
                  toIpt(
                    infoList.GSMX1.Oar,
                    'A01-A01-Oar',
                    'Oar',
                    GSMX1_node_string,
                    
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.Oar :0}}</div>
     <div class="col2 color" @click="
                  toIpt(
                    infoList.GSMX1.Car,
                    'A01-Car',
                     'Car',
                    GSMX1_node_string,
                   
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.Car :0}}</div>
      </div>
           <div class="row1 fl ">
     <div class="col1" > A01-E_REAL</div>
     <div class="col1"> A01-KSAI</div>
     <div class="col1"> A01-GP_NUM</div>
     <div class="col1"> A01-T_RG_YZ</div>
      </div>
            <div class="row2 color fl ">
     <div class="col2 " @click="
                  toIpt(
                    infoList.GSMX1.E_REAL,
                    'A01-E_REAL',
                     'E_REAL',
                    GSMX1_node_string,
                   
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.E_REAL :0}}</div>
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.KSAI,
                    'A01-KSAI',
                    'KSAI',
                    GSMX1_node_string,
                    
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.KSAI :0}}</div>
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.GP_NUM,
                    'A01-GP_NUM',
                    'GP_NUM',
                    GSMX1_node_string,
                    
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.GP_NUM :0}}</div>
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.T_RG_YZ,
                    'A01-T_RG_YZ',
                    'T_RG_YZ',
                    GSMX1_node_string,
                    
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.T_RG_YZ :0}}</div>
      </div>
           <div class="row1 fl ">
     <div class="col1" > A01-T_CG_YZ</div>
     <div class="col1"> A01-T0_L</div>
     <div class="col1"> A01-T_L</div>
     <div class="col1"> A01-TIME_L</div>
      </div>
            <div class="row2 color fl ">
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.T_CG_YZ,
                    ' A01-T_CG_YZ',
                     'T_CG_YZ',
                    GSMX1_node_string,
                   
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.T_CG_YZ :0}}</div>
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.T0_L,
                    'A01-T0_L',
                    'T0_L',
                    GSMX1_node_string,
                    
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.T0_L :0}}</div>
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.T_L,
                    'A01-T_L',
                    'T_L',
                    GSMX1_node_string,
                    
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.T_L :0}}</div>
     <div class="col2" @click="
                  toIpt(
                    infoList.GSMX1.TIME_L,
                    'A01-TIME_L',
                    'TIME_L',
                    GSMX1_node_string,
                    
                  )
                "
        >
          {{"GSMX1" in  infoList ?  infoList.GSMX1.TIME_L :0}}</div>
      </div>
          
    </div>

  </div>
  <div class="main-bottom">
    <div class="bottom1 fl">
      <div class="lbl fl">A02-GSP</div>
      <div class="lb2 fl" @click="toCompon(2, 'GSP', GSMX1_node_string, 'GSMX_GSP_J1','钢损模型氧化烧损率')">{{"GSMX1" in infoList ?infoList.GSMX1.GSP:0}}</div>
    </div> 
        <div class="bottom1 fl">
      <div class="lbl fl">A02-GSM</div>
      <div class="lb2 fl"  @click="toCompon(2, 'GSM', GSMX1_node_string, 'GSMX_GSM_J1','钢损模型氧化烧损量')">{{"GSMX1" in infoList ?infoList.GSMX1.GSM:0}}</div>
    </div>
  </div>
   <div class="anniu">
    <div class="button1 fl"
    @click="toIpt(infoList.RUN, '煤气压力','RUN',infoList.RUN_node_string)">{{"RUN" in infoList ?infoList.RUN?'已启动':'已停止':0}}</div>
    <div class="button2 fl"
    @click="toIpt(infoList.INITIALIZE, '初始化','INITIALIZE',infoList.INITIALIZE_node_string)">{{"GSMX1" in infoList ?infoList.INITIALIZE?'正在初始化':'初始化结束':0}}</div>
    <div class="button2 fl"
    @click="toIpt(infoList.MXJZ, '模型校准','MXJZ',infoList.MXJZ_node_string)">{{"GSMX1" in infoList ?infoList.MXJZ?'模型校准中':'校准结束':0}}</div>
    <div class="button2 fl"
    @click="toIpt(infoList.L_R, '模式','L_R',infoList.L_R_node_string)">{{"GSMX1" in infoList ?infoList.L_R?'在线模式':'离线模式':0}}</div>

  </div>
 
  
     <Historical
      v-if="isHshow"
      @sendStatus="isClose1"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
	  :chName="chName" 
      :infoList="infoList"
    ></Historical>
  <inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
  </div>
</template>
<script>
import Historical from "@/components/Historicaljrl";
import inputVal from "@/components/inputVal"; //输入框组件
export default {
  name: "JRL_pamrm01",
  components: {
    Historical,
    inputVal,
  },
     props:{
        infoList:{
            type:Object,
            default: () =>{
              return {}// 默认值
            } 
        }
    },
  data: () => {
    return {
		chName: '',
      headerStyle: {
        "font-weight": "500",
        "font-size": "1.8vh",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      flag: 0,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      config: {
        data: [10],
        shape: "round",
        colors: ["#43ff44", "#43ff44"],
        waveOpacity: "1",
        waveNum: "50",
        formatter: "{value}",
      },
      rightHeight: "",
    };
  },
     watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
  },
  computed: {},
  mounted() {},
  methods: {
         DataJson(data){
      let strNode = data[0]
      let strMark = data[1]
        this.$nextTick(() => {
       this.infoList[strNode][strMark] = data[2]     
      });
    },
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    closeCompon() {
      this.$emit("sendStatus", "JRL_pamrm01", false);
    },
    isClose1() {
      this.isHshow = false;
    },
    toCompon(key, name, name2, name3, name4,titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      switch (key) {
        case 0:
          return (
            (this.isMshow = true),
            (this.Manualname = name),
            (this.Manualnode = name2),
            (this.ManualAname = name3),
            (this.Manualtitname = titname)
          );
        case 1:
          return (
            (this.isFshow = true),
            (this.Firstcontrolname = name),
            (this.Firstcontrolnode = name2),
            (this.Firstcontroltitname = titname)
          );
        case 2:
          return (
          	(this.isHshow = true),
          	(this.chName = name4)
          );
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.jrlpamrm01 {
  width: 53vw;
  height: 67vh;
  //  background: #e9f7ff;
  background-image: url("~@/assets/images/jrl-param.png");
    background-size: 100% 100%;
  //  cursor:move;
  border-radius: 5px;
  .ziti {
    font-size:0.9vw;
  }
  .zhuangshi1 {
    width: 30vw;
    height: 10vh;
    background-color: #0ef7ff;
    opacity: 0.66;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .title-hang {
    height: 5vh;
    width:53vw;
    font-family: MicrosoftYaHei;
    font-size:1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    //  padding-top:1vh;
  }

  .title1 {
    width: 8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #ffffff;
    margin-top: 1vh;
    margin-left: 1vw;
  }

 
  .title-hang {
    width: 100%;
    height: 6vh;
    text-align: center;
    	font-family: MicrosoftYaHei;
	font-size: 1.5vw;
	font-weight: normal;
	font-stretch: normal;
	line-height: 6vh;
	letter-spacing: 0vh;
	color: #0ef7ff;
  }
  .lable1{
    	font-family: PingFang-SC-Regular;
	font-size: 1.1vw;
	font-weight: normal;
	font-stretch: normal;
	line-height: 2.5vh;
	letter-spacing: 0vh;
	color: #d5fffe;
  margin-left:2.5vw;
  margin-top:1vh;
  }
  .titlelable{
    width:53vw;
	height: 3vh;
	font-family: PingFang-SC-Regular;
	font-size: 1.5vw;
	font-weight: normal;
	font-stretch: normal;
	line-height:  3vh;
	letter-spacing: 0.3vw;
	color: #d5fffe;
  text-align: center;
  margin-top:3vh;
  }
  .main-top{
	width: 49vw;
	height: 32vh;
	border: #00e4ff20 1px solid;
  margin-left:2.5vw;
  margin-top:1vh;
  }
  .main-row1{
    color: #8aeaff;
    	width: 48vw;
    	height: 16vh;
      	font-size: 1.5vw;
  }
    .main-row2{
   width:100%;
    	height: 0.1vh;
      	border-top: #00e4ff20 1px solid;

  }
 .row1{
   padding-top:1vh;
   padding-left:1vw;
   width:6vw;
   height: 16vh;
   	font-family: PingFang-SC-Regular;
	font-size: 1.5vw;
	font-weight: normal;
	font-stretch: normal;
	line-height:2vh;
	letter-spacing: 0vw;
	color: #8aeaff;
  text-align:center;
 }
 .row2{
    padding-top:1vh;
   padding-left:1vw;
   width:4vw;
   height: 16vh;
   	font-family: PingFang-SC-Regular;
	font-size: 1vw;
	font-weight: normal;
	font-stretch: normal;
	line-height:2vh;
	letter-spacing: 0vw;
	color: #8aeaff;
 }
 .col1{
   height: 3vh;
   line-height: 3vh;
   width:8vw;
   font-size: 1vw;
  //  border: #008db1 1px solid;
   text-align: center;
   
 }
  .col2{
   height: 3vh;
   line-height: 3vh;
   width:5vw;
   font-size: 1vw;
  //  border: #008db1 1px solid;
   text-align: center;
   
 }
 .color{
   color: #00ffb4;
 }
 .divx{
   width:100%;
   height: 0.1vh;
   	border-top: #00e4ff20 1px solid;
 }
 .bottom1{
   margin-top:2vh;
   margin-left:2.5vw;
   	width: 12vw;
	height: 4vh;
	background-color: #001b28;
	border-radius: 0vw;
	border: solid 1px #236f8d;
  	font-family: PingFang-SC-Regular;
	font-size: 1vw;
	font-weight: normal;
	font-stretch: normal;
	line-height: 2vw;
	letter-spacing: 0vw;
 }
 .lbl {
	width: 6vw;
	height: 1vw;
	color: #8aeaff;
  margin-left:2vw;
}
 .lb2 {
	width: 4vw;
	height: 1vw;
	color: #2fc3e3;
}
.anniu{
  width:100%;
  text-align: center;
  margin-top:10vh;
   font-size: 1vw;

  color: #fff;
}
      .param11-header-icon {
      width: 2vw;
      height: 2vh;
      right: 1vw;
      margin-top: -5vh;
      cursor: pointer;
      margin-left:50vw;
    }
.button1{
  width: 6vw;
	height:4vh;
 	background-color: #ff0d1e;
	border-radius: 0vw;
	border: solid 0vw #ff0d1e;
	opacity: 0.6;
  line-height: 4vh;
  margin-left:11vw;
}
.button2{
 width: 6vw;
	height:4vh;
 margin-left:2vw;
  line-height: 4vh;;
background-color: #008db1;
}
}
</style>