<template>
	<div class="SwitchPage">
		<!-- 抬头标题 -->
		<div class="title-hang">
			<div class="fl-t">
				<div class="button">
					<div class="button_word" @click="
              toIpt(infoList.BCSALL_B, '按钮', 'BCSALL_B', infoList.BCSALL_B_node_string)
            ">
						{{ "BCSALL_B" in infoList ? "一键全切" : "一键全开" }}
					</div>
				</div>
			</div>
			<!-- <div class="zhuangshizb fl-t"></div> -->
			<div class="zhuangshizj fl-t">{{projectData.project}}_切换页面</div>
			<!-- <div class="zhuangshiyb fl-t"></div> -->
			<div class="fr-t">
				<!-- <div class="button">
				    <div class="button_word">
						    远程请求
					</div>
				</div> -->
			</div>
		</div>
		<!-- 左边框框-->
		<div class="left fl-t">
			<div class="title">
				<div class="left_title">切换开关</div>
			</div>
			<div class="left_content">
				<div class="left_line flex">
					<div class="left_line1 flex">
						主给水阀门<br />
						副给水阀门<br />
						1#给水变频<br />
						2#给水变频<br />
						一甲减温<br />
						一乙减温<br />
						二甲减温<br />
						二乙减温<br />
						引风变频1#<br />
						引风变频2#<br />
						引风挡板1#<br />
						引风挡板2#<br />
						石灰浆液阀<br />
						脱硝喷氨阀<br />
						脱硝除盐水<br />
					</div>
					<div class="left_line2">
						<div class="text-status" :style="{
                background: 'BCS101_B' in infoList ?infoList.BCS101_B ? 'red' : '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS102_B' in infoList?infoList.BCS102_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS128_B' in infoList?infoList.BCS128_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS129_B' in infoList?infoList.BCS129_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS103_B' in infoList?infoList.BCS103_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS104_B' in infoList?infoList.BCS104_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS105_B' in infoList?infoList.BCS105_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS106_B' in infoList?infoList.BCS106_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS107_B' in infoList?infoList.BCS107_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS108_B' in infoList?infoList.BCS108_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS109_B' in infoList?infoList.BCS109_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'' in infoList?infoList.BCS110_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS130_B' in infoList?infoList.BCS130_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS131_B' in infoList?infoList.BCS131_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS132_B' in infoList?infoList.BCS132_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
					</div>
					<div class="left_line3 flex">
						上层煤气层操<br />
						上层空气层操<br />
						中层煤气层操<br />
						中层空气层操<br />
						下层煤气层操<br />
						下层空气层操<br />
						送风变频1#<br />
						送风变频2#<br />
						送风挡板1#<br />
						送风挡板2#<br />
						DEH功率控制<br />
						汽机主调门控制<br />
						再热器挡板<br />
						省煤器挡板<br />
					</div>
					<div class="left_line4">
						<div class="text-status" :style="{
                background:'BCS118_B' in infoList?infoList.BCS118_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS117_B' in infoList?infoList.BCS117_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS118_B' in infoList?infoList.BCS118_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS116_B' in infoList?infoList.BCS116_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS118_B' in infoList?infoList.BCS118_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS115_B' in infoList?infoList.BCS115_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'' in infoList?infoList.BCS111_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS112_B' in infoList?infoList.BCS112_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS113_B' in infoList?infoList.BCS113_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS114_B' in infoList?infoList.BCS114_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS127_B' in infoList?infoList.BCS127_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS135_B' in infoList?infoList.BCS135_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS133_B' in infoList?infoList.BCS133_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
						<div class="text-status" :style="{
                background:'BCS134_B' in infoList?infoList.BCS134_B ? 'red' :  '#29DFAE' :'#29DFAE'
              }"></div>
					</div>
				</div>
			</div>
		</div>

		<!-- 中间部分 -->
		<div class="right fl-t">
			<!-- 中间上方框框 -->
			<div class="middle_up">
				<div class="title">
					<div class="middle_up_title">控制点设定</div>
				</div>
				<div class="middle_up_content">
					<div class="middle_line flex">
						<div class="middle_bg middle_line1">
							<div>
								<div class="middle_num" @click="
                    toIpt(
                      infoList.SWSP,
                      '汽包液位SP',
                      'SWSP',
                      infoList.SWSP_node_string,
                    )
                  ">
									{{ 'SWSP' in infoList ? infoList.SWSP==null?0:infoList.SWSP:0}}
								</div>
								<div class="middle_word">汽包液位SP</div>
							</div>
						</div>
						<div class="middle_bg middle_line2">
							<div>
								<div class="middle_num" @click="
                    toIpt(
                      infoList.QYSP_H,
                      '主汽压力上限',
                      'QYSP_H',
                      infoList.QYSP_H_node_string,
                    )
                  ">
									{{ "QYSP_H" in infoList ? infoList.QYSP_H ==null?0:infoList.QYSP_H:0}}
								</div>
								<div class="middle_word">主汽压力上限</div>
							</div>
						</div>
						<div class="middle_bg middle_line3">
							<div class="middle_num" @click="
                  toIpt(
                    infoList.SO2SP,
                    'SO₂浓度设定值',
                    'SO2SP',
                    infoList.SO2SP_node_string
                  )
                ">
								{{ "SO2SP" in infoList ? infoList.SO2SP ==null?0:infoList.SO2SP:0}}
							</div>
							<div class="middle_word">SO₂浓度设定值</div>
						</div>
					</div>
					<div class="middle_line flex">
						<div class="middle_bg middle_line1">
							<div class="middle_num" @click="
                  toIpt(
                    infoList.FYSP,
                    '炉膛负压SP',
                    'FYSP',
					infoList.FYSP_node_string
                  )
                ">
								{{ "FYSP" in infoList ? infoList.FYSP ==null?0:infoList.FYSP:0}}
							</div>
							<div class="middle_word">炉膛负压SP</div>
						</div>
						<div class="middle_bg middle_line2">
							<div class="middle_num" @click="
                  toIpt(
                    infoList.MCRANQI,
                    '主汽压力下限',
                    'QYSP_L',
					infoList.MCRANQI_node_string
                  )
                ">
								{{ "MCRANQI" in infoList ? infoList.MCRANQI ==null?0:infoList.MCRANQI:0}}
							</div>
							<div class="middle_word">主汽压力下限</div>
						</div>
						<div class="middle_bg middle_line3">
							<div class="middle_num" @click="
                  toIpt(
                    infoList.NOXSP,
                    'NOx浓度设定值',
                    'NOXSP',
                    infoList.NOXSP_node_string
                  )
                ">
								{{ "NOXSP" in infoList ? infoList.NOXSP ==null?0:infoList.NOXSP:0}}
							</div>
							<div class="middle_word">NOx浓度设定值</div>
						</div>
					</div>
					<div class="middle_line flex">
						<div class="middle_bg middle_line1">
							<div class="middle_num" @click="
                  toIpt(infoList.QWSP, '主汽温度SP', 'QWSP', infoList.QWSP_node_string)
                ">
								{{ "QWSP" in infoList ? infoList.QWSP ==null?0:infoList.QWSP:0}}
							</div>
							<div class="middle_word">主汽温度SP</div>
						</div>
						<div class="middle_bg middle_line2">
							<div class="middle_num" @click="
                  toIpt(
                    infoList.GLP_H,
                    '高煤压力上限',
                    'GLP_H',
					infoList.GLP_H_node_string
                  )
                ">
								{{ "GLP_H" in infoList ? infoList.GLP_H ==null?0:infoList.GLP_H:0}}
							</div>
							<div class="middle_word">高煤压力上限</div>
						</div>
						<div class="middle_bg middle_line3">
							<div class="middle_num" @click="
                  toIpt(
                    infoList.ZRSP,
                    '再热温度设定值',
                    'ZRSP',
					infoList.ZRSP_node_string
                  )
                ">
								{{ "ZRSP" in infoList ? infoList.ZRSP ==null?0:infoList.ZRSP:0}}
							</div>
							<div class="middle_word">再热温度设定值</div>
						</div>
					</div>
					<div class="middle_line flex">
						<div class="middle_bg middle_line1">
							<div class="middle_num" @click="
                  toIpt(infoList.QYSP, '主汽压力SP', 'QYSP', infoList.QYSP_node_string)
                ">
								{{ "QYSP" in infoList ? infoList.QYSP ==null?0:infoList.QYSP:0}}
							</div>
							<div class="middle_word">主汽压力SP</div>
						</div>
						<div class="middle_bg middle_line3">
							<div class="middle_num" @click="
                  toIpt(
                    infoList.GLP_L,
                    '高煤压力下限',
                    'GLP_L',
                    infoList.GLP_L_node_string
                  )
                ">
								{{ "GLP_L" in infoList ? infoList.GLP_L ==null?0:infoList.GLP_L:0}}
							</div>
							<div class="middle_word">高煤压力下限</div>
						</div>
						<div class="middle_bg middle_line3">
							<div class="middle_num" @click="
                  toIpt(
                    infoList.FDLSP,
                    '发电量设定值',
                    'FDLSP',
					infoList.FDLSP_node_string
                  )
                ">
								{{ "FDLSP" in infoList ? infoList.FDLSP ==null?0:infoList.FDLSP:0}}
							</div>
							<div class="middle_word">发电量设定值</div>
						</div>
					</div>
					<div class="middle_line flex">
						<div class="middle_bg middle_line1">
							<div class="middle_num" @click="
                  toIpt(
                    infoList.O2SP,
                    '炉膛氧量SP',
                    'O2SP',
                    infoList.O2SP_node_string
                  )
                ">
								{{ "O2SP" in infoList ? infoList.O2SP ==null?0:infoList.O2SP:0}}
							</div>
							<div class="middle_word">炉膛氧量SP</div>
						</div>
						<div class="middle_line2 flex">
							<div class="middle_word1">PLC控制</div>
							<div class="middle_button"></div>
						</div>
						<div class="middle_line3 flex">
							<div class="middle_word1">BCS控制</div>
							<div class="middle_button"></div>
						</div>
					</div>
				</div>
			</div>
			<!-- 中间下方框框 -->
			<div class="middle_bottom">
				<div class="title">
					<div class="middle_bottom_title">重要参数</div>
				</div>
				<div class="middle_bottom_content flex">
					<div class="circle">
						<div class="circle_content">
							<div class="middle_num1">0.00</div>
							<!-- 
							@click="toIpt(infoList.,'本班煤气消耗','','')">
							{{infoList.==null?0:infoList.:0}} -->

							<div class="middle_word1">本班煤气消耗</div>
						</div>
					</div>
					<div class="word">
						<div class="middle_word2 flex">
							<div class="middle_word3">多炉协调使能</div>
							<div class="middle_button middle_button1">协调切除</div>
						</div>
						<div class="middle_word2 flex">
							<div class="middle_word3">锅炉运行状态</div>
							<div class="middle_button middle_button2">备用</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 右边部分 -->
		<div class="right fl-t">
			<!-- 右边上方框框 -->
			<div class="right_up">
				<div class="title">
					<div class="right_up_title">拱顶信号</div>
				</div>
				<div class="right_up_content">
					<div class="flex">
						<div class="right_up_line1"></div>
						<div class="right_up_line2"></div>
						<div class="right_up_line3">开度上限</div>
						<div class="right_up_line4">开度下限</div>
					</div>
					<div class="flex">
						<div class="right_up_line1">1#引风变频</div>
						<div class="right_up_line2">
							<div class="text-status" :style="{
                  background:'BCS107_B' in infoList?infoList.BCS107_B ? 'red' :  '#29DFAE' :'#29DFAE'
                }"></div>
						</div>
						<div class="right_up_line3" @click="
                toIpt(
                  infoList.YFBPAOH1,
                  '1#引风变频开度上限',
                  'YFBPAOH1',
				  infoList.YFBPAOH1_node_string
                )
              ">
							{{ "YFBPAOH1" in infoList ? infoList.YFBPAOH1==null ?0:infoList.YFBPAOH1:0}}
						</div>
						<div class="right_up_line4" @click="
                toIpt(
                  infoList.YFBPAOL1,
                  '1#引风变频开度下限',
                  'YFBPAOL1',
				  infoList.YFBPAOL1_node_string
                )
              ">
							{{ "" in infoList ? infoList.YFBPAOL1 ==null?0:infoList.YFBPAOL1:0}}
						</div>
					</div>
					<div class="flex">
						<div class="right_up_line1">2#引风变频</div>
						<div class="right_up_line2">
							<div class="text-status" :style="{
                  background:'BCS108_B' in infoList?infoList.BCS108_B ? 'red' :  '#29DFAE' :'#29DFAE'
                }"></div>
						</div>
						<div class="right_up_line3" @click="
                toIpt(
                  infoList.YFBPAOH2,
                  '2#引风变频开度上限',
                  'YFBPAOH2',
				  infoList.YFBPAOH2_node_string
                )
              ">
							{{ "YFBPAOH2" in infoList ? infoList.YFBPAOH2 ==null?0:infoList.YFBPAOH2:0}}
						</div>
						<div class="right_up_line4" @click="
                toIpt(
                  infoList.YFBPAOL2,
                  '2#引风变频开度下限',
                  'YFBPAOL2',
                  infoList.YFBPAOL2_node_string
                )
              ">
							{{ "YFBPAOL2" in infoList ? infoList.YFBPAOL2 ==null?0:infoList.YFBPAOL2:0}}
						</div>
					</div>
					<div class="flex">
						<div class="right_up_line1">1#引风挡板</div>
						<div class="right_up_line2">
							<div class="text-status" :style="{
                  background:'' in infoList?infoList.BCS109_B ? 'red' :  '#29DFAE' :'#29DFAE'
                }"></div>
						</div>
						<div class="right_up_line3" @click="
                toIpt(
                  infoList.YFDBAOH1,
                  '1#引风挡板开度上限',
                  'YFDBAOH1',
				  infoList.YFDBAOH1_node_string
                )
              ">
							{{ "YFDBAOH1" in infoList ? infoList.YFDBAOH1 ==null?0:infoList.YFDBAOH1:0}}
						</div>
						<div class="right_up_line4" @click="
                toIpt(
                  infoList.YFDBAOL1,
                  '1#引风挡板开度下限',
                  'YFDBAOL1',
                  infoList.YFDBAOL1_node_string
                )
              ">
							{{ "YFDBAOL1" in infoList ? infoList.YFDBAOL1 ==null?0:infoList.YFDBAOL1:0}}
						</div>
					</div>
					<div class="flex">
						<div class="right_up_line1">2#引风挡板</div>
						<div class="right_up_line2">
							<div class="text-status" :style="{
                  background:'BCS110_B' in infoList?infoList.BCS110_B ? 'red' :  '#29DFAE' :'#29DFAE'
                }"></div>
						</div>
						<div class="right_up_line3" @click="
                toIpt(
                  infoList.YFDBAOH2,
                  '2#引风挡板开度上限',
                  'YFDBAOH2',
				  infoList.YFDBAOH2_node_string
                )
              ">
							{{ "YFDBAOH2" in infoList ? infoList.YFDBAOH2 ==null?0:infoList.YFDBAOH2:0}}
						</div>
						<div class="right_up_line4" @click="
                toIpt(
                  infoList.YFDBAOL2,
                  '2#引风挡板开度下限',
                  'YFDBAOL2',
                  'MCYINFENG'
                )
              ">
							{{ "YFDBAOL2" in infoList ? infoList.YFDBAOL2 ==null?0:infoList.YFDBAOL2:0}}
						</div>
					</div>
					<div class="flex">
						<div class="right_up_line1">1#送风变频</div>
						<div class="right_up_line2">
							<div class="text-status" :style="{
                  background:'BCS111_B' in infoList?infoList.BCS111_B ? 'red' :  '#29DFAE' :'#29DFAE'
                }"></div>
						</div>
						<div class="right_up_line3" @click="
                toIpt(
                  infoList.SFBPAOH1,
                  '1#送风变频开度上限',
                  'SFBPAOH1',
				  infoList.SFBPAOH1_node_string
                )
              ">
							{{ "SFBPAOH1" in infoList ? infoList.SFBPAOH1 ==null?0:infoList.SFBPAOH1:0}}
						</div>
						<div class="right_up_line4" @click="
                toIpt(
                  infoList.SFBPAOL1,
                  '1#送风变频开度下限',
                  'SFBPAOL1',
                  infoList.SFBPAOL1_node_string
                )
              ">
							{{ "SFBPAOL1" in infoList ? infoList.SFBPAOL1 ==null?0:infoList.SFBPAOL1:0}}
						</div>
					</div>
					<div class="flex">
						<div class="right_up_line1">2#送风变频</div>
						<div class="right_up_line2">
							<div class="text-status" :style="{
                  background:'' in infoList?infoList.BCS112_B ? 'red' :  '#29DFAE' :'#29DFAE'
                }"></div>
						</div>
						<div class="right_up_line3" @click="
                toIpt(
                  infoList.SFBPAOH2,
                  '2#送风变频开度上限',
                  'SFBPAOH2',
                  infoList.SFBPAOH2_node_string
                )
              ">
							{{ "SFBPAOH2" in infoList ? infoList.SFBPAOH2 ==null?0:infoList.SFBPAOH2:0}}
						</div>
						<div class="right_up_line4" @click="
                toIpt(
                  infoList.SFBPAOL2,
                  '2#送风变频开度下限',
                  'SFBPAOL2',
				  infoList.SFBPAOL2_node_string
                )
              ">
							{{ "SFBPAOL2" in infoList ? infoList.SFBPAOL2 ==null?0:infoList.SFBPAOL2:0}}
						</div>
					</div>
					<div class="flex">
						<div class="right_up_line1">1#送风挡板</div>
						<div class="right_up_line2">
							<div class="text-status" :style="{
                  background:'BCS113_B' in infoList?infoList.BCS113_B ? 'red' :  '#29DFAE' :'#29DFAE'
                }"></div>
						</div>
						<div class="right_up_line3" @click="
                toIpt(
                  infoList.SFDBAOH1,
                  '1#送风挡板开度上限',
                  'SFDBAOH1',
                  infoList.SFDBAOH1_node_string
                )
              ">
							{{ "SFDBAOH1" in infoList ? infoList.SFDBAOH1 ==null?0:infoList.SFDBAOH1:0}}
						</div>
						<div class="right_up_line4" @click="
                toIpt(
                  infoList.SFDBAOL1,
                  '1#送风挡板开度下限',
                  'SFDBAOL1',
                  infoList.SFDBAOL1_node_string
                )
              ">
							{{ "SFDBAOL1" in infoList ? infoList.SFDBAOL1 ==null?0:infoList.SFDBAOL1:0}}
						</div>
					</div>
					<div class="flex">
						<div class="right_up_line1">2#送风挡板</div>
						<div class="right_up_line2">
							<div class="text-status" :style="{
                  background:'BCS114_B' in infoList?infoList.BCS114_B ? 'red' :  '#29DFAE' :'#29DFAE'
                }"></div>
						</div>
						<div class="right_up_line3" @click="
                toIpt(
                  infoList.SFDBAOH2,
                  '2#送风挡板开度上限',
                  'SFDBAOH2',
				  infoList.SFDBAOH2_node_string
                )
              ">
							{{ "SFDBAOH2" in infoList ? infoList.SFDBAOH2 ==null?0:infoList.SFDBAOH2:0}}
						</div>
						<div class="right_up_line4" @click="
                toIpt(
                  infoList.SFDBAOL2,
                  '2#送风挡板开度上限',
                  'SFDBAOL2',
				  infoList.SFDBAOL2_node_string
                )
              ">
							{{ "SFDBAOL2" in infoList ? infoList.SFDBAOL2 ==null?0:infoList.SFDBAOL2:0}}
						</div>
					</div>
				</div>
			</div>
			<!-- 右边下方框框 -->
			<div class="right_bottom">
				<div class="title">
					<div class="right_bottom_title">系统监控</div>
				</div>
				<div class="right_bottom_content flex">
					<div class="right_bottom_circle flex">
						<div class="circle">
							<div class="right_word">BCS</div>
							<div class="right_num"
								@click="toCompon(2, 'HEART_1A_B',infoList.HEART_1AR_B_node_string, 'HEART_1B_NG3','2#心跳数据')" @dblclick="
                  Cclick(
                    infoList.HEART_1AR_B,
                    'HEART_1AR_B',
					infoList.HEART_1AR_B_node_string,
                    'HEART_1B_NG3'
                  )
                ">
								{{ "HEART_1AR_B" in infoList ? infoList.HEART_1AR_B ==null?0:infoList.HEART_1AR_B:0}}
							</div>
						</div>
						<div class="circle circle1">
							<div class="right_word">DCS</div>
							<div class="right_num"
								@click="toCompon(2, 'HEART_1A_B', infoList.HEART_1A_B_node_string, 'HEART_1A_NG3','1#心跳数据')" @dblclick="
                  Cclick(
                    infoList.HEART_1A_B,
                    'HEART_1A_B',
                    infoList.HEART_1A_B_node_string,
                    'HEART_1A_NG3'
                  )
                ">
								{{ "HEART_1A_B" in infoList ? infoList.HEART_1A_B ==null?0:infoList.HEART_1A_B:0}}
							</div>
						</div>
					</div>
					<div class="right_bottom_normal">
						<div class="normal">
							{{ "YHZYC" in infoList ? infoList.YHZYC ? "通讯故障" : "通讯正常" ==null?0:infoList.YHZYC:0}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
		<Historical v-if="isHshow" @sendStatus="isHshow = false" :historyname="historyname" :node="node"
			:chName="chName" :Lkname="Lkname" :infoList="infoList"></Historical>
		<RqCommonParameters v-if="flag == 4 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></RqCommonParameters>
		<RqSoftMeasurement v-if="flag == 6 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></RqSoftMeasurement>
		<RqOnlineService v-if="flag == 7 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></RqOnlineService>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historicaljrl.vue"; //历史趋势
	import RqCommonParameters from "@/views/RqBoiler/RqCommonParameters/index.vue"; //常用参数组件
	import RqSoftMeasurement from "@/views/RqBoiler/RqSoftNumber/index.vue"; //软测量数统
	import RqOnlineService from "@/views/RqBoiler/RqOnlineService/index.vue"; //客服
	export default {
		name: "Param11",
		props: {
			infoList: {
				type: Object,
				default: {}, // 默认值
			},
		},
		components: {
			inputVal,
			Historical,
			RqCommonParameters,
			RqSoftMeasurement,
			RqOnlineService,
		},
		data: () => {
			return {
				chName: '',
				flag: 3,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: "",
				node: "",
				Lkname: "",
				isIndex: "",
				Firstcontrolname: "",
				Firstcontroltitname: "",
				Firstcontrolnode: "",
				isFshow: false,
				Rsfname: "",
				Rsfnode: "",
				Rsftitname: "",
				projectData: "",
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			};
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ?
				JSON.parse(localStorage.getItem("spotArr")) : [];
			this.authInfo = JSON.parse(localStorage.getItem("autharr"));
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
			this.$bus.$on("sendMsg", (msg) => {
				// 兄弟组件发送来的消息
				this.flag = msg;
			});
		},
		methods: {
			DataJson(data) {
				let strNode = data[0];
				let strMark = data[1];
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2];
				});
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0];
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error("暂无调试权限");
					}
				} else {
					let pathname = "";
					switch (key) {
						case 1:
							pathname = "firstcontol";
							break;
						case 2:
							pathname = "rqkfirstcontol";
							break;
						case 3:
							pathname = "RqRfirstcontol";
							break;
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname,
						},
					});
					window.open(newurl.href, "_blank");
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name,
				};
				this.spotArr.push(spojobj);
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll();
							map.set(item.name, item);
							this.$message.success("已添加");
						} else {
							this.$message.closeAll();
							this.$message.error("已添加到变量池里，切勿重复添加");
						}
					}

					return [...map.values()];
				};
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
			},
			isshowfase() {
				this.isComShow = false;
			},
			closeCompon() {
				this.$emit("sendStatus", "Param03", false);
			},
			isClose(val, val1) {
				switch (val) {
					case "Historical":
						return (this.isHshow = val1);
					case "RqOnlineService":
					case this.flag == 7:
						return (this.flag = 3), this.$bus.$emit("footersendMsg", 3);
					case "RqSoftNumber":
					case this.flag == 6:
						return (this.flag = 3), this.$bus.$emit("footersendMsg", 3);
					case "RqCommonParameters":
					case this.flag == 4:
						return (this.flag = 3), this.$bus.$emit("footersendMsg", 3);
				}
			},
			// 打开下置输入窗口
			toIpt(data, name, historyname, node, type, status) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0];
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error("暂无调试权限");
					}
				} else {
					this.$refs.inputVal.open(data, name, historyname, node, type, status);
				}
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							console.log(1);
							return (
								(this.isMshow = true),
								(this.Manualname = name),
								(this.Manualnode = name2),
								(this.ManualAname = name3),
								(this.Manualtitname = titname)
							);
						case 1:
							return (
								(this.isFshow = true),
								(this.Firstcontrolname = name),
								(this.Firstcontrolnode = name2),
								(this.Firstcontroltitname = titname)
							);
						case 2:
							return (this.isHshow = true, this.chName = name4);
						case 4:
							return (
								(this.isRshow = true),
								(this.Rsfname = name),
								(this.Rsfnode = name2),
								(this.Rsftitname = titname)
							);
					}
				}, 300);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.fl-t {
		float: left;
	}

	.fr-t {
		float: right;
	}

	.button {
		width: 8vw;
		height: 4vh;
		margin-left: 1.5vw;
		background-color: #ff2850;
		border-radius: 0vh;
		border: solid 0vh #ff2850;
		margin-top: 4vh;
		cursor: pointer;

		.button_word {
			font-family: FZLTZHK--GBK1-0;
			font-size: 1.2vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #ffe2e7;
			text-align: center;
		}
	}

	.title-hang {
		height: 9vh;
		width: 96vw;
		font-family: MicrosoftYaHei;
		font-size: 1.4vw;
		font-weight: normal;
		font-stretch: normal;
		line-height: 2vh;
		letter-spacing: 0vh;
	}

	.zhuangshizb {
		width: 16vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate06.png");
		background-size: 100% 100%;
		margin-left: 9.5vw;
		margin-top: 1.5vh;
	}

	.zhuangshizj {
		width: 78vw;
		height: 4vh;
		line-height: 4vh;
		margin-top: 1vh;
		text-align: center;
		// background-image: url("~@/assets/images/rfl_evaluate05.png");
		background-size: 100% 100%;
		// margin-left: 5vw;
		font-family: MicrosoftYaHei;
		font-size: 1.5vw;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0vh;
		color: #0ef7ff;
	}

	.zhuangshiyb {
		width: 16vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate04.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		margin-top: 1.5vh;
	}

	.text-status {
		width: 4vw;
		height: 1.8vh;
		margin-top: 1.5vh;
		margin-left: 0vw;
	}

	.left {
		width: 28vw;
		height: 74vh;
		margin-left: 1.5vw;
		background: url("~@/assets/images/RqBoiler/bg_left.png") no-repeat 0 0;
		background-size: 100% 100%;
		border-style: solid;
		border-width: 0vw;
		border-image-source: linear-gradient(180deg, #000000 0%, #23526d 100%);
		border-image-slice: 1;
		position: relative;

		.title {
			height: 3vh;
			// margin-left: 1.5vw;

			.left_title {
				color: #ffffff;
				font-size: 1.1vw;
				position: absolute;
				top: 0;
				left: 2vw;
			}
		}

		.left_content {
			width: 22vw;
			height: 64vh;
			margin-top: 3vh;
			margin-left: 2vw;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4.3vh;
			letter-spacing: 0vh;
			color: #8aeaff;

			.text-status {
				width: 4vw;
				height: 1.8vh;
				margin-top: 2.5vh;
			}

			.left_line1 {
				width: 6vw;
				text-align: right;
			}

			.left_line2 {
				width: 2vw;
				margin-left: 1vw;
				margin-top: -1.1vh;
				cursor: pointer;
			}

			.left_line3 {
				width: 7.5vw;
				text-align: right;
				margin-left: 3vw;
			}

			.left_line4 {
				width: 2vw;
				margin-left: 1vw;
				margin-top: -1.1vh;
				cursor: pointer;
			}
		}
	}

	.middle_up {
		width: 37vw;
		height: 52vh;
		margin-left: 1vw;
		background: url("~@/assets/images/RqBoiler/bg_right.png") no-repeat 0 0;
		background-size: 100% 100%;
		border-style: solid;
		border-width: 0vw;
		border-image-source: linear-gradient(180deg, #000000 0%, #23526d 100%);
		border-image-slice: 1;
		position: relative;

		.title {
			width: 37vw;
			height: 3vh;

			.middle_up_title {
				color: #ffffff;
				font-size: 1.1vw;
				position: absolute;
				top: 0;
				left: 2vw;
			}
		}

		.middle_up_content {
			width: 32vw;
			height: 41vh;
			margin-top: 3vh;
			margin-left: 3vw;

			.middle_line {
				height: 9vh;

				.middle_bg {
					width: 9vw;
					height: 6vh;
					background: url("~@/assets/images/RqBoiler/middle_up.png") no-repeat 0 0;
					background-size: 100% 100%;
					border-style: solid;
					border-width: 0vw;
					border-image-source: linear-gradient(180deg, #000000 0%, #23526d 100%);
					border-image-slice: 1;
					position: relative;

					.middle_num {
						font-family: PingFang-SC-Regular;
						font-size: 1vw;
						font-weight: normal;
						font-stretch: normal;
						line-height: 2.7vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						text-align: center;
						cursor: pointer;
					}

					.middle_word {
						font-family: PingFang-SC-Regular;
						font-size: 1vw;
						font-weight: normal;
						font-stretch: normal;
						letter-spacing: 0vh;
						color: #8aeaff;
						text-align: center;
					}
				}

				.middle_line1 {}

				.middle_line2 {
					margin-left: 2vw;

					.middle_word1 {
						font-family: PingFang-SC-Regular;
						font-size: 1.2vw;
						font-weight: normal;
						font-stretch: normal;
						line-height: 3vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 1vw;
						margin-top: 3vh;
					}

					.middle_button {
						cursor: not-allowed;
						width: 3vw;
						height: 1.8vh;
						margin-top: 3.6vh;
						margin-left: 0.2vw;
						background-color: #29dfae;
					}
				}

				.middle_line3 {
					margin-left: 2vw;

					.middle_word1 {
						font-family: PingFang-SC-Regular;
						font-size: 1.2vw;
						font-weight: normal;
						font-stretch: normal;
						line-height: 3vh;
						letter-spacing: 0vh;
						color: #8aeaff;
						margin-left: 1vw;
						margin-top: 3vh;
					}

					.middle_button {
						cursor: not-allowed;
						width: 3vw;
						height: 1.8vh;
						margin-top: 3.6vh;
						margin-left: 0.2vw;
						background-color: red;
					}
				}
			}
		}
	}

	.middle_bottom {
		width: 37vw;
		height: 20vh;
		margin-left: 1vw;
		margin-top: 2vh;
		background: url("~@/assets/images/RqBoiler/bg_middle_down.png") no-repeat 0 0;
		background-size: 100% 100%;
		border-style: solid;
		border-width: 0vw;
		border-image-source: linear-gradient(180deg, #000000 0%, #23526d 100%);
		border-image-slice: 1;
		position: relative;

		.title {
			width: 37vw;
			height: 3vh;

			.middle_bottom_title {
				color: #ffffff;
				font-size: 1vw;
				position: absolute;
				top: 0;
				left: 3vw;
			}
		}

		.middle_bottom_content {
			.circle {
				width: 7vw;
				height: 13vh;
				background: url("~@/assets/images/RqBoiler/boiler.png") no-repeat 0 0;
				background-size: 100% 100%;
				margin-left: 5vw;
				margin-top: 3vh;

				.circle_content {
					font-family: PingFang-SC-Regular;
					font-weight: normal;
					font-stretch: normal;
					letter-spacing: 0vh;
					text-align: center;
					margin-top: 3.5vh;

					.middle_num1 {
						font-size: 1.5vw;
						line-height: 3vh;
						color: #ffffff;
						cursor: pointer;
					}

					.middle_word1 {
						font-size: 0.5vw;
						line-height: 2vh;
						color: #8aeaff;
					}
				}
			}

			.word {
				font-family: PingFang-SC-Regular;
				font-size: 1.1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 0vh;
				color: #8aeaff;

				.middle_word2 {
					margin-left: 5vw;
					margin-top: 2vh;
					line-height: 4vh;

					.middle_word3 {}

					.middle_button {
						width: 6vw;
						height: 4vh;
						border-radius: 0vh;
						font-family: FZLTZHK--GBK1-0;
						font-size: 1.1vw;
						font-weight: normal;
						font-stretch: normal;
						line-height: 4vh;
						letter-spacing: 0vh;
						color: #ffffff;
						text-align: center;
						margin-left: 2vw;
						cursor: pointer;
					}

					.middle_button1 {
						background-color: #00afdc;
					}

					.middle_button2 {
						background-color: #057b99;
					}
				}
			}
		}
	}

	.right_up {
		width: 28vw;
		height: 52vh;
		margin-left: 1vw;
		background: url("~@/assets/images/RqBoiler/bg_middle.png") no-repeat 0 0;
		background-size: 100% 100%;
		border-style: solid;
		border-width: 0vw;
		border-image-source: linear-gradient(180deg, #000000 0%, #23526d 100%);
		border-image-slice: 1;
		position: relative;

		.title {
			width: 28vw;
			height: 3vh;

			.right_up_title {
				color: #ffffff;
				font-size: 1.1vw;
				position: absolute;
				top: 0;
				left: 3vw;
			}
		}

		.right_up_content {
			width: 24vw;
			height: 42vh;
			margin-left: 2vw;
			margin-top: 1vh;
			line-height: 5vh;

			.right_up_line1 {
				width: 6vw;
				font-family: PingFang-SC-Regular;
				font-size: 1.1vw;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0vh;
				color: #8aeaff;
			}

			.right_up_line2 {
				width: 2vw;
				margin-left: 1vw;
				cursor: pointer;
			}

			.right_up_line3 {
				width: 4vw;
				margin-left: 3vw;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0vw;
				color: #8aeaff;
				text-align: center;
				cursor: pointer;
			}

			.right_up_line4 {
				width: 4vw;
				margin-left: 3vw;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0vw;
				color: #8aeaff;
				text-align: center;
				cursor: pointer;
			}
		}
	}

	.right_bottom {
		width: 28vw;
		height: 20vh;
		margin-left: 1vw;
		margin-top: 2vh;
		background: url("~@/assets/images/RqBoiler/bg_right_down.png") no-repeat 0 0;
		background-size: 100% 100%;
		border-style: solid;
		border-width: 0vw;
		border-image-source: linear-gradient(180deg, #000000 0%, #23526d 100%);
		border-image-slice: 1;
		position: relative;

		.title {
			width: 28vw;
			height: 3vh;

			.right_bottom_title {
				color: #ffffff;
				font-size: 1.1vw;
				position: absolute;
				top: 0;
				left: 3vw;
			}
		}

		.right_bottom_content {
			.right_bottom_circle {
				margin-left: 3vw;
				margin-top: 4vh;

				.circle {
					width: 5vw;
					height: 9vh;
					background: url("~@/assets/images/RqBoiler/right_down.png") no-repeat 0 0;
					background-size: 100% 100%;
					font-family: PingFang-SC-Regular;
					font-size: 1vw;
					font-weight: normal;
					font-stretch: normal;
					line-height: 3vh;
					letter-spacing: 0vh;
					text-align: center;

					.right_word {
						margin-top: 1vh;
						color: #6acafb;
					}

					.right_num {
						color: #ffffff;
						cursor: pointer;
					}
				}

				.circle1 {
					margin-left: 1vw;
				}
			}

			.right_bottom_normal {
				width: 8vw;
				height: 4vh;
				background: url("~@/assets/images/RqBoiler/normal.png") no-repeat 0 0;
				background-size: 100% 100%;
				margin-left: 3vw;
				margin-top: 6vh;
				cursor: pointer;

				.normal {
					font-family: FZLTZHK--GBK1-0;
					font-size: 1vw;
					font-weight: normal;
					font-stretch: normal;
					line-height: 4vh;
					letter-spacing: 0vh;
					color: #45ffb2;
					text-align: center;
				}
			}
		}
	}
</style>
