<template>
	<div class="total drag" v-draw id="RqCommonParameters">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<!-- <div class="zhuangshizb"></div> -->
				<div class="zhuangshizj">{{projectData.project}}_基础参数</div>
				<!-- <div class="zhuangshiyb" @click="closeCompon"></div> -->
			</div>
			<div class="icon" @click="closeCompon"></div>
		</div>

		<div class="parameters">
			<div class="parameters_title">
				锅炉基础参数设置
			</div>
		</div>

		<div class="table">
			<div class="line_short flex">
				<div class="column1">锅炉状态判定</div>
				<div class="column2">初始模型参数</div>
			</div>
			<div class="line_long flex">
				<div class="left_line">
					<div class="content">
						<div class="flex">
							<div class="left_line_word">运行汽温</div>
							<div class="left_line_num"
								@click="toIpt(infoList.GLYX_QW,'运行汽温','GLYX_QW',infoList.GLYX_QW_node_string)">
								{{ "GLYX_QW" in infoList?infoList.GLYX_QW:0 }}
							</div>
						</div>
						<div class="flex">
							<div class="left_line_word">运行流量</div>
							<div class="left_line_num"
								@click="toIpt(infoList.GLYX_FH,'运行流量','GLYX_FH',infoList.GLYX_FH_node_string)">
								{{ "GLYX_FH" in infoList?infoList.GLYX_FH:0 }}
							</div>
						</div>
						<div class="flex">
							<div class="left_line_word">状态指示</div>
							<div class="left_line_num" style="color: black"
								@click="toIpt(infoList.GLYX,'按钮','GLYX',infoList.GLYX_node_string)">
								{{ "GLYX" in infoList?infoList.GLYX ? "运行" : "备用":0 }}
							</div>
						</div>
					</div>
				</div>
				<div class="right_line">
					<div class="content">
						<div class="flex">
							<div class="left_line_word">锅炉设计负荷</div>
							<div class="left_line_num"
								@click="toIpt(infoList.GLSJFH,'锅炉设计负荷','GLSJFH',infoList.GLSJFH_node_string)">
								{{ "GLSJFH" in infoList?infoList.GLSJFH:0 }}
							</div>
						</div>
						<div class="flex">
							<div class="left_line_word">锅炉设计汽压</div>
							<div class="left_line_num"
								@click="toIpt(infoList.GLSJQY,'锅炉设计汽压','GLSJQY',infoList.GLSJQY_node_string)">
								{{ "GLSJQY" in infoList?infoList.GLSJQY:0 }}
							</div>
						</div>
						<div class="flex">
							<div class="left_line_word">锅炉设计汽温</div>
							<div class="left_line_num"
								@click="toIpt(infoList.GLSJQW,'锅炉设计汽温','GLSJQW',infoList.GLSJQW_node_string)">
								{{ "GLSJQW" in infoList?infoList.GLSJQW:0 }}
							</div>
						</div>
						<div class="flex">
							<div class="left_line_word">锅炉设计煤耗</div>
							<div class="left_line_num"
								@click="toIpt(infoList.GLSJMQ,'锅炉设计煤耗','GLSJMQ',infoList.GLSJMQ_node_string)">
								{{ "GLSJMQ" in infoList?infoList.GLSJMQ:0 }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="line_short line_short1 flex">
				<div class="column1">煤气热值</div>
				<div class="column2">BCS基础参数</div>
			</div>
			<div class="line_long flex">
				<div class="left_line">
					<div class="content">
						<div class="flex">
							<div class="left_line_word">高炉煤气热值</div>
							<div class="left_line_num"
								@click="toIpt(infoList.GLMQRZ,'高炉煤气热值','GLMQRZ',infoList.GLMQRZ_node_string)">
								{{ "GLMQRZ" in infoList?infoList.GLMQRZ:0 }}
							</div>
						</div>
						<div class="flex">
							<div class="left_line_word">转炉煤气热值</div>
							<div class="left_line_num"
								@click="toIpt(infoList.ZLMQRZ,'转炉煤气热值','ZLMQRZ',infoList.ZLMQRZ_node_string)">
								{{ "ZLMQRZ" in infoList?infoList.ZLMQRZ:0 }}
							</div>
						</div>
						<div class="flex">
							<div class="left_line_word">焦炉煤气热值</div>
							<div class="left_line_num"
								@click="toIpt(infoList.JLMQRZ,'焦炉煤气热值','JLMQRZ',infoList.JLMQRZ_node_string)">
								{{ "JLMQRZ" in infoList?infoList.JLMQRZ:0 }}
							</div>
						</div>
					</div>
				</div>
				<div class="right_line">
					<div class="content">
						<div class="flex">
							<div class="left_line_word">自控率报警阈值</div>
							<!-- <div class="left_line_num"
							@click="toIpt(infoList.,'自控率报警阈值','','')">
								{{ "" in infoList?infoList.:0 }}
							</div> -->
							<div class="left_line_num">5</div>
						</div>
						<div class="flex">
							<div class="left_line_word">负荷范围上限</div>
							<div class="left_line_num"
								@click="toIpt(infoList.GLFH_H,'负荷范围上限','GLFH_H',infoList.GLFH_H_node_string)">
								{{ "GLFH_H" in infoList?infoList.GLFH_H:0 }}
							</div>
						</div>
						<div class="flex">
							<div class="left_line_word">负荷范围下限</div>
							<div class="left_line_num"
								@click="toIpt(infoList.GLFH_L,'负荷范围下限','GLFH_L',infoList.GLFH_L_node_string)">
								{{ "GLFH_L" in infoList?infoList.GLFH_L:0 }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
		<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<Firstcontrol :infoList='infoList' :isIndex='isIndex' :historyname='Firstcontrolname'
			:titname='Firstcontroltitname' :node='Firstcontrolnode' v-if="isFshow" @sendStatus='isFshow=false'>
		</Firstcontrol>
		<Rsf v-if="isRshow" @sendStatus='isRshow=false' :titname='Rsftitname' :infoList='infoList'
			:historyname='Rsfname' :node='Rsfnode'></Rsf>
		<ParameterYh v-if="isComShow" :infoList='infoList' @sendStatus='isshowfase'></ParameterYh>
		<Manual :key="isIndex" v-if="isMshow" @sendStatus="isMshow = false" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :titname="Manualtitname" :infoList="infoList">
		</Manual>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Rsf from "@/components/RSF.vue"; //阮伺服组件
	import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "Param11",
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},
		components: {
			inputVal,
			Historical,
			Firstcontrol,
			Rsf,
			ParameterYh,
			Manual
		},
		data: () => {
			return {
				chName: '',
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
		},
		methods: {
			DataJson(data) {
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'rqkfirstcontol'
							break
						case 3:
							pathname = 'RqRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'RqCommonParameters', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
				}
			},
			// 打开下置输入窗口
			toIpt(data, name, historyname, node, type, status) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0];
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error("暂无调试权限");
					}
				} else {
					this.$refs.inputVal.open(data, name, historyname, node, type, status);
				}
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							console.log(1)
							return this.isMshow = true, this.Manualname = name, this.Manualnode = name2, this
								.ManualAname = name3, this.Manualtitname = titname
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>


<style lang="scss" scoped>
	#RqCommonParameters {
		width: 36vw;
		height: 54vh;
		background-image: url("~@/assets/images/RqBoiler/servive_bg.png");
		background-size: 100% 100%;

		// margin-left: 32vw;
		// margin-top: 16vh;
		.title-hang {
			width: 30vw;
			height: 3vh;
			font-family: MicrosoftYaHei;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
		}

		.zhuangshizb {
			width: 9.5vw;
			height: 1vh;
			background-image: url("~@/assets/images/rfl_evaluate06.png");
			background-size: 100% 100%;
			margin-left: 4vw;
			margin-top: 2vh;
		}

		.zhuangshizj {
			width: 45vw;
			height: 3vh;
			font-family: MicrosoftYaHei;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.8vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			background-image: url("~@/assets/images/rfl_evaluate05.png");
			background-size: 100% 100%;
			margin-left: 1vw;
		}

		.zhuangshiyb {
			width: 9.5vw;
			height: 1vh;
			background-image: url("~@/assets/images/rfl_evaluate04.png");
			background-size: 100% 100%;
			margin-left: 1vw;
			margin-top: 2vh;
		}

		.icon {
			width: 2vw;
			height: 4vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-top: 1vh;
			margin-left: 3vw;
			cursor: pointer;
		}

		.parameters {
			width: 36vw;
			height: 2vh;
			margin-top: 3vh;

			.parameters_title {
				font-family: PingFang-SC-Regular;
				font-size: 1.5vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}

		.table {
			margin-top: 2vh;
			margin-left: 1vw;
			width: 34vw;
			height: 40vh;
			border: 1px solid rgba(0, 228, 255, .2);
			box-sizing: border-box;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;

			.line_short {
				height: 4vh;
				line-height: 2.2vw;
				color: #d5fffe;
				text-align: center;
				border-bottom: 1px solid rgba(0, 228, 255, .2);

				.column1 {
					width: 17vw;
					border-right: 1px solid rgba(0, 228, 255, .2);
				}

				.column2 {
					width: 17vw;
				}
			}

			.line_short1 {
				border-top: 1px solid rgba(0, 228, 255, .2);
			}

			.line_long {
				height: 16vh;
				line-height: 2vw;
				color: #8aeaff;
				font-family: PingFang-SC-Regular;
				font-weight: normal;
				font-stretch: normal;
				line-height: 3vh;
				letter-spacing: 0vh;

				.left_line {
					width: 17vw;
					border-right: 1px solid rgba(0, 228, 255, .2);
				}

				.content {
					margin-top: 1vh;
				}

				.right_line {
					width: 17vw;
				}

				.left_line_word {
					width: 9vw;
					margin-left: 2vw;
					font-size: 1vw;
					color: #8aeaff;
				}

				.left_line_num {
					width: 3vw;
					text-align: right;
					font-size: 1.2vw;
					color: #14a4ff;
					cursor: pointer;
				}
			}
		}
	}
</style>
