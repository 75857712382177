<template>
  <div>
    <div class="rqlindex" :infoList="infoList">
      <div class="maskbok" v-if="maskbok"></div>
      <div class="title">
        {{ projectData.project }}
        <!-- 燃气锅炉优化控制系统 -->
      </div>
      <div class="row1-main">
        <div class="row11 fl">
          <div class="fl ztitl">
            <div class="widthls">
              <div>固定煤气量设定</div>
              <div class="widthls">
                <div
                  class="fl labletx"
                  @click="
                    toIpt(
                      infoList.GLSP,
                      '固定煤气量设定',
                      'GLSP',
                      infoList.GLSP_node_string
                    )
                  "
                >
                  <span class="down">
                    {{ "GLSP" in infoList ? infoList.GLSP : 0 }}</span
                  >
                </div>
                <div class="fl danwei">m³/h</div>
              </div>
            </div>
            <div class="gaomei">
              <div class="fl gmsj1" @click="toCompon(3)">高煤</div>
              <div class="fl gmsj2">
                <div>
                  <div
                    class="fl lwidth"
                    @click="
                      toCompon(
                        2,
                        'PICA_104_B',
                        infoList.PICA_104_B_node_string,
                        'PICA_104_NG3',
                        '高炉煤气总管压力'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.PICA_104_B,
                        'PICA_104_B',
                        infoList.PICA_104_B_node_string,
                        'PICA_104_NG3'
                      )
                    "
                  >
                    {{ "PICA_104_B" in infoList ? infoList.PICA_104_B : 0 }}
                  </div>
                  <div class="fl danwei">KPa</div>
                </div>
                <div>
                  <div
                    class="fl lwidthxx"
                    @click="
                      toCompon(
                        2,
                        'FICA_105_B',
                        infoList.FICA_105_B_node_string,
                        'FICA_105_NG3',
                        '高炉煤气流量'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.FICA_105_B,
                        'FICA_105_B',
                        infoList.FICA_105_B_node_string,
                        'FICA_105_NG3'
                      )
                    "
                  >
                    {{ "FICA_105_B" in infoList ? infoList.FICA_105_B : 0 }}
                  </div>

                  <div class="fl danwei">m³/h</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 红色箭头部分 -->
          <div class="fl hguandao">
            <div class="fl col3">
              <div>发电量设定</div>
              <div>
                <div class="fl">
                  <span
                    class="down"
                    @click="
                      toIpt(
                        infoList.FDLSP,
                        '发电量设定',
                        'FDLSP',
                        infoList.FDLSP_node_string
                      )
                    "
                  >
                    {{ "FDLSP" in infoList ? infoList.FDLSP : 0 }}</span
                  >
                </div>
                <div class="danwei fl">MW</div>
              </div>
              <div class="col1">
                <div
                  class="fl"
                  @click="
                    toCompon(
                      2,
                      'FDLSP',
                      infoList.FDLSP_node_string,
                      'FDLSP_NG3',
                      '发电量设定值'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.FDLSP,
                      'FDLSP',
                      infoList.FDLSP_node_string,
                      'FDLSP_NG3'
                    )
                  "
                >
                  {{ "FDLSP" in infoList ? infoList.FDLSP : 0 }}
                </div>
                <div class="danwei fl">MW</div>
              </div>
            </div>
            <div class="fl col2">
              <div class="fl">
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      'XK30' in infoList
                        ? infoList.XK30 && infoList.XK30.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK30
                      ? toDetail(
                          1,
                          'XK30',
                          infoList.XK30_node_string,
                          '',
                          '发电量调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      'XK21' in infoList
                        ? infoList.XK21 && infoList.XK21.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK21
                      ? toDetail(
                          1,
                          'XK21',
                          infoList.XK21_node_string,
                          '',
                          '汽机功率控制'
                        )
                      : ''
                  "
                >
                  X
                </div>
              </div>

              <div class="fl flexwz">
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      'MAN35' in infoList
                        ? infoList.MAN35 && infoList.MAN35.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN35
                      ? toCompon(
                          0,
                          'AV',
                          'MAN35',
                          'MAN35_AV_NG3',
                          '燃气锅炉_MAN',
                          'KF_135_B',
                          infoList.MAN35_node_string,
                          'KF_135_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      'MAN27' in infoList
                        ? infoList.MAN27 && infoList.MAN27.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN27
                      ? toCompon(
                          0,
                          'AV',
                          'MAN27',
                          'MAN27_AV_NG3',
                          '功率设定输出手操器',
                          'KF_127_B',
                          infoList.MAN27_node_string,
                          'KF_127_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
              </div>
            </div>
            <div class="fl col3">
              <div>主汽压力设定</div>
              <div>
                <div class="fl">
                  <span
                    class="down"
                    @click="
                      toIpt(
                        infoList.QYSP,
                        '主汽压力设定',
                        'QYSP',
                        infoList.QYSP_node_string
                      )
                    "
                    >{{ "QYSP" in infoList ? infoList.QYSP : 0 }}</span
                  >
                </div>
                <div class="danwei fl">MPa</div>
              </div>
              <div class="col1">
                <div
                  class="fl"
                  @click="
                    toCompon(
                      2,
                      'PICA_102_B',
                      infoList.PICA_102_B_node_string,
                      'PICA_102_NG3',
                      '主汽压力1'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.PICA_102_B,
                      'PICA_102_B',
                      infoList.PICA_102_B_node_string,
                      'PICA_102_NG3'
                    )
                  "
                >
                  {{ "PICA_102_B" in infoList ? infoList.PICA_102_B : 0 }}
                </div>
                <div class="danwei fl">MPa</div>
              </div>
            </div>
            <div class="fl col4">
              <div
                class="fl"
                @click="
                  toCompon(
                    2,
                    'FICA_102_B',
                    infoList.FICA_102_B_node_string,
                    'FICA_102_NG3',
                    '蒸汽流量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.FICA_102_B,
                    'FICA_102_B',
                    infoList.FICA_102_B_node_string,
                    'FICA_102_NG3'
                  )
                "
              >
                {{ "FICA_102_B" in infoList ? infoList.FICA_102_B : 0 }}
              </div>
              <div class="fl danwei">t/h</div>
            </div>
            <div class="fl col3">
              <div>主汽温度设定</div>
              <div>
                <div class="fl">
                  <span
                    class="down"
                    @click="
                      toIpt(
                        infoList.QWSP,
                        '主汽温度设定',
                        'QWSP',
                        infoList.QWSP_node_string
                      )
                    "
                    >{{ "QWSP" in infoList ? infoList.QWSP : 0 }}</span
                  >
                </div>
                <div class="danwei fl">℃</div>
              </div>
              <div class="col1">
                <div
                  class="fl"
                  @click="
                    toCompon(
                      2,
                      'TICA_102_B',
                      infoList.TICA_102_B_node_string,
                      'TICA_102_NG3',
                      '主汽温度1'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.TICA_102_B,
                      'TICA_102_B',
                      infoList.TICA_102_B_node_string,
                      'TICA_102_NG3'
                    )
                  "
                >
                  {{ "TICA_102_B" in infoList ? infoList.TICA_102_B : 0 }}
                </div>
                <div class="danwei fl">℃</div>
              </div>
            </div>
          </div>
          <div class="zhuanmei">
            <div class="fl zmsj1">转煤</div>
            <div class="fl jmsj2">
              <div>
                <div
                  class="fl lwidthxx"
                  @click="
                    toCompon(
                      2,
                      'PICA_105_B',
                      infoList.PICA_105_B_node_string,
                      'PICA_105_NG3',
                      '转炉煤气总管压力'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.PICA_105_B,
                      'PICA_105_B',
                      infoList.PICA_105_B_node_string,
                      'PICA_105_NG3'
                    )
                  "
                >
                  {{ "PICA_105_B" in infoList ? infoList.PICA_105_B : 0 }}
                </div>
                <div class="fl danwei">KPa</div>
              </div>
              <div>
                <div
                  class="fl lwidthxx"
                  @click="
                    toCompon(
                      2,
                      'FICA_106_B',
                      infoList.FICA_106_B_node_string,
                      'FICA_106_NG3',
                      '转炉煤气流量'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.FICA_106_B,
                      'FICA_106_B',
                      infoList.FICA_106_B_node_string,
                      'FICA_106_NG3'
                    )
                  "
                >
                  {{ "FICA_106_B" in infoList ? infoList.FICA_106_B : 0 }}
                </div>
                <div class="fl danwei">m³/h</div>
              </div>
            </div>
          </div>
          <div class="jiaomei">
            <div class="fl jmsj1">焦煤</div>
            <div class="fl jmsj2">
              <div>
                <div
                  class="fl lwidthxx"
                  @click="
                    toCompon(
                      2,
                      'PICA_106_B',
                      infoList.PICA_106_B_node_string,
                      'PICA_106_NG3',
                      '焦炉煤气总管压力'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.PICA_106_B,
                      'PICA_106_B',
                      infoList.PICA_106_B_node_string,
                      'PICA_106_NG3'
                    )
                  "
                >
                  {{ "PICA_106_B" in infoList ? infoList.PICA_106_B : 0 }}
                </div>
                <div class="fl danwei">KPa</div>
              </div>
              <div>
                <div
                  class="fl lwidthxx"
                  @click="
                    toCompon(
                      2,
                      'FICA_107_B',
                      infoList.FICA_107_B_node_string,
                      'FICA_107_NG3',
                      '焦炉煤气流量流量'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.FICA_107_B,
                      'FICA_107_B',
                      infoList.FICA_107_B_node_string,
                      'FICA_107_NG3'
                    )
                  "
                >
                  {{ "FICA_107_B" in infoList ? infoList.FICA_107_B : 0 }}
                </div>
                <div class="fl danwei">m³/h</div>
              </div>
            </div>
          </div>
          <!-- 汽包部分 -->
          <div class="qibao fl">
            <div class="fl qb-col1">
              <div class="qb-sw">
                <div
                  class="qb-sw-col1 fl"
                  :style="{
                    background:
                      'SWSEL' in infoList
                        ? infoList.SWSEL == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    toIpt(
                      '1',
                      '水位1',
                      'SWSEL',
                      infoList.SWSEL_node_string,
                      'true'
                    )
                  "
                >
                  水位1
                </div>

                <div
                  class="qb-sw-col2 fl"
                  @click="
                    toCompon(
                      2,
                      'LT101_B',
                      infoList.LT101_B_node_string,
                      'LT101_NG3',
                      '汽包水位1'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.LT101_B,
                      'LT101_B',
                      infoList.LT101_B_node_string,
                      'LT101_NG3'
                    )
                  "
                >
                  {{ "LT101_B" in infoList ? infoList.LT101_B : 0 }}
                </div>
                <div class="qb-sw-col3 fl danwei">mm</div>
              </div>
              <div class="qb-sw">
                <div
                  class="qb-sw-col1 fl"
                  :style="{
                    background:
                      'SWSEL' in infoList
                        ? infoList.SWSEL == 2
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    toIpt(
                      '2',
                      '水位2',
                      'SWSEL',
                      infoList.SWSEL_node_string,
                      'true'
                    )
                  "
                >
                  水位2
                </div>
                <div
                  class="qb-sw-col2 fl"
                  @click="
                    toCompon(
                      2,
                      'LT102_B',
                      infoList.LT102_B_node_string,
                      'LT102_NG3',
                      '汽包水位2'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.LT102_B,
                      'LT102_B',
                      infoList.LT102_B_node_string,
                      'LT102_NG3'
                    )
                  "
                >
                  {{ "LT102_B" in infoList ? infoList.LT102_B : 0 }}
                </div>
                <div class="qb-sw-col3 fl danwei">mm</div>
              </div>
              <div class="qb-sw">
                <div
                  class="qb-sw-col1 fl"
                  :style="{
                    background:
                      'SWSEL' in infoList
                        ? infoList.SWSEL == 4
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    toIpt(
                      '4',
                      '水位3',
                      'SWSEL',
                      infoList.SWSEL_node_string,
                      'true'
                    )
                  "
                >
                  水位3
                </div>
                <div
                  class="qb-sw-col2 fl"
                  @click="
                    toCompon(
                      2,
                      'LT103_B',
                      infoList.LT103_B_node_string,
                      'LT103_NG3',
                      '汽包水位3'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.LT103_B,
                      'LT103_B',
                      infoList.LT103_B_node_string,
                      'LT103_NG3'
                    )
                  "
                >
                  {{ "LT103_B" in infoList ? infoList.LT103_B : 0 }}
                </div>
                <div class="qb-sw-col3 fl danwei">mm</div>
              </div>
            </div>
            <div
              class="fl qb-col2"
              @click="
                toCompon(
                  2,
                  'PICA_125_B',
                  infoList.PICA_125_B_node_string,
                  'PICA_125_NG3',
                  '汽包压力1'
                )
              "
            >
              <dv-water-level-pond
                :config="config"
                style="width: 110px; height: 110px"
              />
              <!-- this.infoList.PICA_125_B -->
              <div
                class="laction"
                @click.stop="
                  toIpt(infoList.SWSP, '', 'SWSP', infoList.SWSP_node_string)
                "
                @dblclick.stop="
                  Cclick(
                    infoList.SWSP,
                    'SWSP',
                    infoList.SWSP_node_string,
                    'SWSP_NG3'
                  )
                "
              >
                <span style="color: #14a4ff; cursor: pointer"
                  >{{ "SWSP" in infoList ? infoList.SWSP : 0 }}mm</span
                >
              </div>
            </div>
            <div class="fl qb-col1">
              <div class="qb-sw">
                <!-- <div class="qb-sw-col1 fl">气温1</div>
                <div class="qb-sw-col2 fl">0.0000</div>
                <div class="qb-sw-col3 fl danwei">℃</div> -->
              </div>
              <div class="qb-sw">
                <!-- <div class="qb-sw-col1 fl">气温2</div>
                <div class="qb-sw-col2 fl">0.00</div>
                <div class="qb-sw-col3 fl danwei">℃</div> -->
              </div>
              <div class="qb-sw">
                <!-- <div class="qb-sw-col1 fl">气温3</div>
                <div class="qb-sw-col2 fl">0.00</div>
                <div class="qb-sw-col3 fl danwei">℃</div> -->
              </div>
            </div>
          </div>
          <div class="ltwd">
            <div class="ltwdkd fl">
              <div
                class="fl"
                @click="
                  toCompon(
                    2,
                    'PICA_125_B',
                    infoList.PICA_125_B_node_string,
                    'PICA_125_NG3',
                    '汽包压力1'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.PICA_125_B,
                    'PICA_125_B',
                    infoList.PICA_125_B_node_string,
                    'PICA_125_NG3'
                  )
                "
              >
                {{ "PICA_125_B" in infoList ? infoList.PICA_125_B : 0 }}
              </div>
              <div class="fl danwei">MPa</div>

              <div
                class="qb-sw-col2 fl"
                style="margin-left: 2vw"
                @click="
                  toCompon(
                    2,
                    'TICA_104_B',
                    infoList.TICA_104_B_node_string,
                    'TICA_104_NG3',
                    '炉膛出口左侧温度'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.TICA_104_B,
                    'TICA_104_B',
                    infoList.TICA_104_B_node_string,
                    'TICA_104_NG3'
                  )
                "
              >
                {{ "TICA_104_B" in infoList ? infoList.TICA_104_B : 0 }}
              </div>
              <div class="qb-sw-col3 fl danwei">℃</div>
            </div>

            <div class="ltwdkd fl" style="margin-top: 2vh">
              <div
                class="fl"
                @click="
                  toCompon(
                    2,
                    'PICA_125_B',
                    infoList.PICA_125_B_node_string,
                    'PICA_125_NG3',
                    '汽包压力1'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.PICA_125_B,
                    'PICA_125_B',
                    infoList.PICA_125_B_node_string,
                    'PICA_125_NG3'
                  )
                "
              >
                {{ "PICA_125_B" in infoList ? infoList.PICA_125_B : 0 }}
              </div>
              <div class="fl danwei">MPa</div>
              <div
                class="qb-sw-col2 fl"
                style="margin-left: 2vw"
                @click="
                  toCompon(
                    2,
                    'TICA_105_B',
                    infoList.TICA_105_B_node_string,
                    'TICA_105_NG3',
                    '炉膛出口右侧温度'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.TICA_105_B,
                    'TICA_105_B',
                    infoList.TICA_105_B_node_string,
                    'TICA_105_NG3'
                  )
                "
              >
                {{ "TICA_105_B" in infoList ? infoList.TICA_105_B : 0 }}
              </div>
              <div class="qb-sw-col3 fl danwei">℃</div>
            </div>
          </div>
          <!-- 左边按钮 -->
          <div class="downmain">
            <div class="leftbutton fl">
              <div
                class="bottonx buttonje"
                :style="{
                  background:
                    'RSF2' in infoList
                      ? !infoList.RSF2.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.RSF2
                    ? toDetail(
                        3,
                        'RSF2',
                        infoList.RSF2_node_string,
                        '',
                        '多炉负荷协调软伺服'
                      )
                    : ''
                "
              >
                R
              </div>
              <div
                class="bottonx buttonje"
                :style="{
                  background:
                    'RSF3' in infoList
                      ? !infoList.RSF3.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.RSF3
                    ? toDetail(
                        3,
                        'RSF3',
                        infoList.RSF3_node_string,
                        '',
                        '引风机上限减煤气'
                      )
                    : ''
                "
              >
                R
              </div>
              <div
                class="bottonx buttonje"
                :style="{
                  background:
                    'RSF4' in infoList
                      ? !infoList.RSF4.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.RSF4
                    ? toDetail(
                        3,
                        'RSF4',
                        infoList.RSF4_node_string,
                        '',
                        '氧量保护软伺服'
                      )
                    : ''
                "
              >
                R
              </div>
              <div
                class="bottonx buttonje"
                :style="{
                  background:
                    'QK24' in infoList
                      ? infoList.QK24 && !infoList.QK24.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.QK24
                    ? toDetail(
                        2,
                        'QK24',
                        infoList.QK24_node_string,
                        '',
                        '高炉压力—高煤流量'
                      )
                    : ''
                "
              >
                K
              </div>
              <div
                class="bottonx buttonje"
                :style="{
                  background:
                    'QK13' in infoList
                      ? infoList.QK13 && !infoList.QK13.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.QK13
                    ? toDetail(
                        2,
                        'QK13',
                        infoList.QK13_node_string,
                        '',
                        '负荷-高煤前馈'
                      )
                    : ''
                "
              >
                K
              </div>
              <div
                class="bottonx buttonje"
                :style="{
                  background:
                    'XK11' in infoList
                      ? infoList.XK11 && infoList.XK11.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK11
                    ? toDetail(
                        1,
                        'XK11',
                        infoList.XK11_node_string,
                        '',
                        '主汽压力先控调节'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="bottonx buttonje"
                :style="{
                  background:
                    'XK12' in infoList
                      ? infoList.XK12 && infoList.XK12.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK12
                    ? toDetail(
                        1,
                        'XK12',
                        infoList.XK12_node_string,
                        '',
                        '煤气母管压力先控调节'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="bottonx buttonje"
                :style="{
                  background:
                    'QK6' in infoList
                      ? infoList.QK6 && !infoList.QK6.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.QK6
                    ? toDetail(
                        2,
                        'QK6',
                        infoList.QK6_node_string,
                        '',
                        '一层转煤-高煤前馈'
                      )
                    : ''
                "
              >
                K
              </div>

              <div
                class="bottonx buttonje"
                :style="{
                  background:
                    'QK9' in infoList
                      ? infoList.QK9 && !infoList.QK9.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.QK9
                    ? toDetail(
                        2,
                        'QK9',
                        infoList.QK9_node_string,
                        '',
                        '一层焦煤-高煤前馈'
                      )
                    : ''
                "
              >
                K
              </div>
            </div>
            <!-- <div class="fl">22</div>
            <div class="fl">33</div>
            <div class="fl">44</div>
            <div class="fl">55</div>
            <div class="fl">66</div> -->
            <!-- 左侧阀门 -->
            <div class="leftfm fl">
              <div class="leftfm-col1">
                <div
                  class="bottonx fl rightfm-col2x"
                  :style="{
                    background:
                      'QK8' in infoList
                        ? infoList.QK8 && !infoList.QK8.TS
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.QK8
                      ? toDetail(
                          2,
                          'QK8',
                          infoList.QK8_node_string,
                          '',
                          '一层高煤压力前馈'
                        )
                      : ''
                  "
                >
                  K
                </div>

                <div
                  class="bottonx fl rightfm-col2x"
                  style="margin-left: 0.3vw"
                  :style="{
                    background:
                      'XK16' in infoList
                        ? infoList.XK16 && infoList.XK16.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK16
                      ? toDetail(
                          1,
                          'XK16',
                          infoList.XK16_node_string,
                          '',
                          '一层高炉煤气流量调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl rightfm-col2x"
                  style="margin-left: 0.01vw"
                  :style="{
                    background:
                      'XK13' in infoList
                        ? infoList.XK13 && infoList.XK13.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK13
                      ? toDetail(
                          1,
                          'XK13',
                          infoList.XK13_node_string,
                          '',
                          '主汽温度-三层高煤'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl rightfm-col2x"
                  style="margin-left: 0.01vw"
                  :style="{
                    background:
                      'MAN20' in infoList
                        ? infoList.MAN20 && infoList.MAN20.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN20
                      ? toCompon(
                          0,
                          'AV',
                          'MAN20',
                          'MAN20_AV_NG3',
                          '三层高炉煤气手操器',
                          'KF_120_B',
                          infoList.MAN20_node_string,
                          'KF_120_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
                <div
                  class="fl"
                  @click="
                    toCompon(
                      2,
                      'KF_120_B',
                      infoList.KF_120_B_node_string,
                      'KF_120_NG3',
                      '3层高炉煤气层操反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_120_B,
                      'KF_120_B',
                      infoList.KF_120_B_node_string,
                      'KF_120_NG3'
                    )
                  "
                >
                  {{ "KF_120_B" in infoList ? infoList.KF_120_B : 0 }}
                </div>
                <div class="fl">%</div>
              </div>
              <div class="leftfm-col2">
                <div
                  class="bottonx fl"
                  style="margin-left: 0.01vw; margin-top: 0.55vh"
                  :style="{
                    background:
                      'MAN19' in infoList
                        ? infoList.MAN19 && infoList.MAN19.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN19
                      ? toCompon(
                          0,
                          'AV',
                          'MAN19',
                          'MAN19_AV_NG3',
                          '二层高炉煤气手操器',
                          'KF_119_B',
                          infoList.MAN19_node_string,
                          'KF_119_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
                <div
                  class="fl"
                  @click="
                    toCompon(
                      2,
                      'KF_119_B',
                      infoList.KF_119_B_node_string,
                      'KF_119_NG3',
                      '2层高炉煤气层操反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_119_B,
                      'KF_119_B',
                      infoList.KF_119_B_node_string,
                      'KF_119_NG3'
                    )
                  "
                >
                  {{ "KF_119_B" in infoList ? infoList.KF_119_B : 0 }}
                </div>
                <div class="fl">%</div>
              </div>
              <div class="leftfm-col3">
                <div
                  class="bottonx fl"
                  style="margin-left: 0.01vw; margin-top: 0.5vh"
                  :style="{
                    background:
                      'MAN18' in infoList
                        ? infoList.MAN18 && infoList.MAN18.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN18
                      ? toCompon(
                          0,
                          'AV',
                          'MAN18',
                          'MAN18_AV_NG3',
                          '一层高炉煤气手操器',
                          'KF_118_B',
                          infoList.MAN18_node_string,
                          'KF_118_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
                <div
                  class="fl"
                  @click="
                    toCompon(
                      2,
                      'KF_118_B',
                      infoList.KF_118_B_node_string,
                      'KF_118_NG3',
                      '1层高炉煤气层操反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_118_B,
                      'KF_118_B',
                      infoList.KF_118_B_node_string,
                      'KF_118_NG3'
                    )
                  "
                >
                  {{ "KF_118_B" in infoList ? infoList.KF_118_B : 0 }}
                </div>
                <div class="fl">%</div>
              </div>
            </div>
            <div class="ltyl fl">
              <div
                class="ltyl-col1 down"
                @click="
                  toIpt(
                    infoList.FYSP,
                    '压力设定',
                    'FYSP',
                    infoList.FYSP_node_string
                  )
                "
              >
                {{ "FYSP" in infoList ? infoList.FYSP : 0 }} &nbsp;Pa
              </div>

              <div class="ltyl-col2">
                <div class="fl ltyl-col1-row1">
                  <div class="dise">
                    <div
                      class="fl"
                      @click="
                        toCompon(
                          2,
                          'PICA_107_B',
                          infoList.PICA_107_B_node_string,
                          'PICA_107_NG3',
                          '炉膛负压1'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.PICA_107_B,
                          'PICA_107_B',
                          infoList.PICA_107_B_node_string,
                          'PICA_107_NG3'
                        )
                      "
                    >
                      {{ "PICA_107_B" in infoList ? infoList.PICA_107_B : 0 }}
                    </div>
                    <div class="fl danwei">Pa</div>
                  </div>
                  <div
                    style="width: 4vw; margin-left: 1vw"
                    :style="{
                      background:
                        'FYSEL' in infoList
                          ? infoList.FYSEL == 1
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      toIpt(
                        1,
                        '负压1',
                        'FYSEL',
                        infoList.FYSEL_node_string,
                        'true'
                      )
                    "
                  >
                    负压1
                  </div>
                  <div class="dise">
                    <div
                      class="fl"
                      @click="
                        toCompon(
                          2,
                          'PICA_109_B',
                          infoList.PICA_109_B_node_string,
                          'PICA_109_NG3',
                          '炉膛负压3'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.PICA_109_B,
                          'PICA_109_B',
                          infoList.PICA_109_B_node_string,
                          'PICA_109_NG3'
                        )
                      "
                    >
                      {{ "PICA_109_B" in infoList ? infoList.PICA_109_B : 0 }}
                    </div>
                    <div class="fl danwei">Pa</div>
                  </div>
                  <div
                    style="width: 4vw; margin-left: 1vw"
                    :style="{
                      background:
                        'FYSEL' in infoList
                          ? infoList.FYSEL == 4
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      toIpt(
                        4,
                        '负压3',
                        'FYSEL',
                        infoList.FYSEL_node_string,
                        'true'
                      )
                    "
                  >
                    负压3
                  </div>
                </div>
                <div class="fl ltyl-col1-row1">
                  <div class="dise">
                    <div
                      class="fl"
                      @click="
                        toCompon(
                          2,
                          'PICA_108_B',
                          infoList.PICA_108_B_node_string,
                          'PICA_108_NG3',
                          '炉膛负压2'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.PICA_108_B,
                          'PICA_108_B',
                          infoList.PICA_108_B_node_string,
                          'PICA_108_NG3'
                        )
                      "
                    >
                      {{ "PICA_108_B" in infoList ? infoList.PICA_108_B : 0 }}
                    </div>
                    <div class="fl danwei">Pa</div>
                  </div>
                  <div
                    style="width: 4vw; margin-left: 1vw"
                    :style="{
                      background:
                        'FYSEL' in infoList
                          ? infoList.FYSEL == 2
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      toIpt(
                        2,
                        '负压2',
                        'FYSEL',
                        infoList.FYSEL_node_string,
                        'true'
                      )
                    "
                  >
                    负压2
                  </div>
                  <div class="dise">
                    <div
                      class="fl"
                      @click="
                        toCompon(
                          2,
                          'PICA_110_B',
                          infoList.PICA_110_B_node_string,
                          'PICA_110_NG3',
                          '炉膛负压4'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.PICA_110_B,
                          'PICA_110_B',
                          infoList.PICA_110_B_node_string,
                          'PICA_110_NG3'
                        )
                      "
                    >
                      {{ "PICA_110_B" in infoList ? infoList.PICA_110_B : 0 }}
                    </div>
                    <div class="fl danwei">Pa</div>
                  </div>
                  <div
                    style="width: 4vw; margin-left: 1vw"
                    :style="{
                      background:
                        'FYSEL' in infoList
                          ? infoList.FYSEL == 8
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      toIpt(
                        8,
                        '负压4',
                        'FYSEL',
                        infoList.FYSEL_node_string,
                        'true'
                      )
                    "
                  >
                    负压4
                  </div>
                </div>
              </div>
              <div class="huomiao"></div>
            </div>
            <!-- 右侧阀门 -->
            <div class="rightfm fl">
              <div class="leftfm-col1">
                <div
                  class="fl rightfm-col1"
                  @click="
                    toCompon(
                      2,
                      'KF_117_B',
                      infoList.KF_117_B_node_string,
                      'KF_117_NG3',
                      '3层空气层操反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_117_B,
                      'KF_117_B',
                      infoList.KF_117_B_node_string,
                      'KF_117_NG3'
                    )
                  "
                >
                  {{ "KF_117_B" in infoList ? infoList.KF_117_B : 0 }}
                </div>
                <div class="fl">%</div>
                <div
                  class="bottonx fl rightfm-col2x"
                  :style="{
                    background:
                      'XK10' in infoList
                        ? infoList.XK10 && infoList.XK10.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK10
                      ? toDetail(
                          1,
                          'XK10',
                          infoList.XK10_node_string,
                          '',
                          '三层空气层操先控调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl rightfm-col2x"
                  :style="{
                    background:
                      'QK5' in infoList
                        ? infoList.QK5 && !infoList.QK5.TS
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.QK5
                      ? toDetail(
                          2,
                          'QK5',
                          infoList.QK5_node_string,
                          '',
                          '送风三层空气层操前馈'
                        )
                      : ''
                  "
                >
                  K
                </div>
                <div
                  class="bottonx fl rightfm-col2x"
                  style="margin-left: 0.01vw"
                  :style="{
                    background:
                      'MAN17' in infoList
                        ? infoList.MAN17 && infoList.MAN17.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN17
                      ? toCompon(
                          0,
                          'AV',
                          'MAN17',
                          'MAN17_AV_NG3',
                          '三层操空气手操器',
                          'KF_117_B',
                          infoList.MAN17_node_string,
                          'KF_117_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
              </div>
              <div class="rightfm-col2">
                <div
                  class="fl"
                  @click="
                    toCompon(
                      2,
                      'KF_116_B',
                      infoList.KF_116_B_node_string,
                      'KF_116_NG3',
                      '2层空气层操反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_116_B,
                      'KF_116_B',
                      infoList.KF_116_B_node_string,
                      'KF_116_NG3'
                    )
                  "
                >
                  {{ "KF_116_B" in infoList ? infoList.KF_116_B : 0 }}
                </div>
                <div class="fl">%</div>
                <div
                  class="bottonx fl rightfm-col2x"
                  :style="{
                    background:
                      'XK9' in infoList
                        ? infoList.XK9 && infoList.XK9.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK9
                      ? toDetail(
                          1,
                          'XK9',
                          infoList.XK9_node_string,
                          '',
                          '二层空气层操先控调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl rightfm-col2x"
                  :style="{
                    background:
                      'QK4' in infoList
                        ? infoList.QK4 && !infoList.QK4.TS
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.QK4
                      ? toDetail(
                          2,
                          'QK4',
                          infoList.QK4_node_string,
                          '',
                          '送风二层空气层操前馈'
                        )
                      : ''
                  "
                >
                  K
                </div>

                <div
                  class="bottonx fl rightfm-col2x"
                  style="margin-left: 0.01vw"
                  :style="{
                    background:
                      'MAN16' in infoList
                        ? infoList.MAN16 && infoList.MAN16.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN16
                      ? toCompon(
                          0,
                          'AV',
                          'MAN16',
                          'MAN16_AV_NG3',
                          '二层操空气手操器',
                          'KF_116_B',
                          infoList.MAN16_node_string,
                          'KF_116_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
              </div>
              <div class="rightfm-col3">
                <div
                  class="fl"
                  @click="
                    toCompon(
                      2,
                      'KF_115_B',
                      infoList.KF_115_B_node_string,
                      'KF_115_NG3',
                      '1层空气层操反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_115_B,
                      'KF_115_B',
                      infoList.KF_115_B_node_string,
                      'KF_115_NG3'
                    )
                  "
                >
                  {{ "KF_115_B" in infoList ? infoList.KF_115_B : 0 }}
                </div>
                <div class="fl">%</div>
                <div
                  class="bottonx fl rightfm-col2x"
                  :style="{
                    background:
                      'XK8' in infoList
                        ? infoList.XK8 && infoList.XK8.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK8
                      ? toDetail(
                          1,
                          'XK8',
                          infoList.XK8_node_string,
                          '',
                          '一层空气层操先控调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl rightfm-col2x"
                  :style="{
                    background:
                      'QK3' in infoList
                        ? infoList.QK3 && !infoList.QK3.TS
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.QK3
                      ? toDetail(
                          2,
                          'QK3',
                          infoList.QK3_node_string,
                          '',
                          '送风一层空气层操前馈'
                        )
                      : ''
                  "
                >
                  K
                </div>

                <div
                  class="bottonx fl rightfm-col2x"
                  :style="{
                    background:
                      'MAN15' in infoList
                        ? infoList.MAN15 && infoList.MAN15.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN15
                      ? toCompon(
                          0,
                          'AV',
                          'MAN15',
                          'MAN5_RM_NG3',
                          '一层操空气手操器',
                          'KF_115_B',
                          infoList.MAN15_node_string,
                          'KF_115_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 中间部分 -->
        <div class="row12 fl">
          <div class="yqsd fl">
            <div class="labwt">
              <div class="yqljg">氧量设定</div>
              <div>
                <div
                  class="fl down yqljg"
                  @click="
                    toIpt(
                      infoList.O2SP,
                      '氧量设定',
                      'O2SP',
                      infoList.O2SP_node_string
                    )
                  "
                >
                  {{ "O2SP" in infoList ? infoList.O2SP : 0 }}
                </div>
                <div class="fl danwei yqljg">%</div>
              </div>
            </div>
            <div class="hanyangl">
              <div
                class="fl yqljg"
                :style="{
                  background:
                    'O2SEL' in infoList
                      ? infoList.O2SEL == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  toIpt(1, '氧量1', 'O2SEL', infoList.O2SEL_node_string, 'true')
                "
              >
                氧量1
              </div>
              <div
                class="fl yqljg1"
                @click="
                  toCompon(
                    2,
                    'AT101A_B',
                    infoList.AT101A_B_node_string,
                    'AT101A_NG3',
                    'A侧锅炉转向室烟气含氧量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.AT101A_B,
                    'AT101A_B',
                    infoList.AT101A_B_node_string,
                    'AT101A_NG3'
                  )
                "
              >
                {{ "AT101A_B" in infoList ? infoList.AT101A_B : 0 }}
              </div>
              <div class="fl danwei">%</div>
            </div>
            <div class="hanyangl">
              <div
                class="fl yqljg"
                :style="{
                  background:
                    'O2SEL' in infoList
                      ? infoList.O2SEL == 2
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  toIpt(2, '氧量2', 'O2SEL', infoList.O2SEL_node_string, 'true')
                "
              >
                氧量2
              </div>
              <div
                class="fl yqljg1"
                @click="
                  toCompon(
                    2,
                    'AT101B_B',
                    infoList.AT101B_B_node_string,
                    'AT101B_NG3',
                    'B侧锅炉转向室烟气含氧量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.AT101B_B,
                    'AT101B_B',
                    infoList.AT101B_B_node_string,
                    'AT101B_NG3'
                  )
                "
              >
                {{ "AT101B_B" in infoList ? infoList.AT101B_B : 0 }}
              </div>
              <div class="fl danwei">%</div>
            </div>
            <div class="labwt1">
              <div class="yqljg2">再热温度设定</div>
              <div class="lbwidth">
                <div
                  class="fl down yqljg"
                  @click="
                    toIpt(
                      infoList.ZRSP,
                      '再热温度设定',
                      'ZRSP',
                      infoList.ZRSP_node_string
                    )
                  "
                >
                  {{ "ZRSP" in infoList ? infoList.ZRSP : 0 }}
                </div>
                <div class="fl danwei yqljg">℃</div>
              </div>
              <div class="lbwidth">
                <div
                  class="fl yqljg"
                  @click="
                    toCompon(
                      2,
                      'TICA_132_B',
                      infoList.TICA_132_B_node_string,
                      'TICA_132_NG3',
                      '再热温度'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.TICA_132_B,
                      'TICA_132_B',
                      infoList.TICA_132_B_node_string,
                      'TICA_132_NG3'
                    )
                  "
                >
                  {{ "TICA_132_B" in infoList ? infoList.TICA_132_B : 0 }}
                </div>
                <div class="fl danwei yqljg">℃</div>
              </div>
            </div>
            <div class="labwt2">
              <div>
                <div
                  class="fl yqljg3"
                  @click="
                    toCompon(
                      2,
                      'SFRCL1',
                      infoList.SFRCL1_node_string,
                      'SFRCL1_NG3',
                      '1#送风软测量'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.SFRCL1,
                      'SFRCL1',
                      infoList.SFRCL1_node_string,
                      'SFRCL1_NG3'
                    )
                  "
                >
                  {{ "SFRCL1" in infoList ? infoList.SFRCL1 : 0 }}
                </div>
                <div class="fl danwei yqljg">m³/h</div>
              </div>
              <div>
                <div
                  class="fl yqljg3"
                  @click="
                    toCompon(
                      2,
                      'SFRCL2',
                      infoList.SFRCL2_node_string,
                      'SFRCL2_NG3',
                      '2#送风软测量'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.SFRCL2,
                      'SFRCL2',
                      infoList.SFRCL2_node_string,
                      'SFRCL2_NG3'
                    )
                  "
                >
                  {{ "SFRCL2" in infoList ? infoList.SFRCL2 : 0 }}
                </div>
                <div class="fl danwei yqljg">m³/h</div>
              </div>
            </div>
          </div>
          <!--过热器部分-->
          <div class="fl grzz">
            <div class="grzz-col1">高温过热器</div>
            <div class="grzz-col1 grzz-col1-jg" style="margin-top: 6.2vh">
              低温过热器
            </div>
            <div class="grzz-col1 grzz-col1-jg">高温省煤器</div>
            <div class="grzz-col1 grzz-col1-jg1">
              <div
                class="bottonx fl"
                :style="{
                  background:
                    'QK23' in infoList
                      ? infoList.QK23 && !infoList.QK23.TS
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.QK23
                    ? toDetail(
                        2,
                        'QK23',
                        infoList.QK23_node_string,
                        '',
                        '总煤气量—再热器前馈'
                      )
                    : ''
                "
              >
                K
              </div>

              <div
                class="bottonx fl"
                :style="{
                  background:
                    'XK29' in infoList
                      ? infoList.XK29 && infoList.XK29.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK29
                    ? toDetail(
                        1,
                        'XK29',
                        infoList.XK29_node_string,
                        '',
                        '再热器温度调节'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="bottonx fl"
                :style="{
                  background:
                    'MAN33' in infoList
                      ? infoList.MAN33 && infoList.MAN33.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.MAN33
                    ? toCompon(
                        0,
                        'AV',
                        'MAN33',
                        'MAN33_AV_NG3',
                        '再热CD',
                        'KF_ZR_B',
                        infoList.MAN33_node_string,
                        'KF_ZR_NG3'
                      )
                    : ''
                "
              >
                A
              </div>
              <div
                class="bottonx fl"
                :style="{
                  background:
                    'MAN34' in infoList
                      ? infoList.MAN34 && infoList.MAN34.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.MAN34
                    ? toCompon(
                        0,
                        'AV',
                        'MAN34',
                        'MAN34_AV_NG3',
                        '省煤AB',
                        'KF_SM_B',
                        infoList.MAN34_node_string,
                        'KF_SM_NG3'
                      )
                    : ''
                "
              >
                A
              </div>
            </div>
            <div class="grzz-col1">
              <div class="grzz-col2">
                <div
                  class="fl"
                  @click="
                    toCompon(
                      2,
                      'KF_ZR_B',
                      infoList.KF_ZR_B_node_string,
                      'KF_ZR_NG3',
                      '再热器器CD阀门反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_ZR_B,
                      'KF_ZR_B',
                      infoList.KF_ZR_B_node_string,
                      'KF_ZR_NG3'
                    )
                  "
                >
                  {{ "KF_ZR_B" in infoList ? infoList.KF_ZR_B : 0 }}
                </div>
                <div class="fl">%</div>
                <div
                  class="fl grzz-col2-jg"
                  @click="
                    toCompon(
                      2,
                      'KF_SM_B',
                      infoList.KF_SM_B_node_string,
                      'KF_SM_NG3',
                      '省煤器AB阀门反馈'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.KF_SM_B,
                      'KF_SM_B',
                      infoList.KF_SM_B_node_string,
                      'KF_SM_NG3'
                    )
                  "
                >
                  {{ "KF_SM_B" in infoList ? infoList.KF_SM_B : 0 }}
                </div>
                <div class="fl">%</div>
              </div>
            </div>
            <div class="grzz-col1 grzz-col1-jg2">低温省煤器</div>
            <div class="grzz-col1 grzz-col1-jg">空气预热器</div>

            <div class="grzz-col1 grzz-col1-jg3">
              <div class="grzz-col2">
                <div
                  class="fl"
                  @click="
                    toCompon(
                      2,
                      'TICA_110_B',
                      infoList.TICA_110_B_node_string,
                      'TICA_110_NG3',
                      '排烟温度1'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.TICA_110_B,
                      'TICA_110_B',
                      infoList.TICA_110_B_node_string,
                      'TICA_110_NG3'
                    )
                  "
                >
                  {{ "TICA_110_B" in infoList ? infoList.TICA_110_B : 0 }}
                </div>
                <div class="fl">℃</div>
                <div
                  class="fl grzz-col2-jg"
                  @click="
                    toCompon(
                      2,
                      'TICA_111_B',
                      infoList.TICA_111_B_node_string,
                      'TICA_111_NG3',
                      '排烟温度2'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.TICA_111_B,
                      'TICA_111_B',
                      infoList.TICA_111_B_node_string,
                      'TICA_111_NG3'
                    )
                  "
                >
                  {{ "TICA_111_B" in infoList ? infoList.TICA_111_B : 0 }}
                </div>
                <div class="fl">℃</div>
              </div>
            </div>
          </div>
          <!-- <div class="grzz1 fl">
            <div class="grlable">高温过热器</div>
            <div class="grlable" style="margin-top: 2vh">低温过热器</div>
          </div> -->
        </div>
        <div class="row13 fl">
          <!-- 右侧部分 -->
          <div class="row13-top">
            <div class="row13-top-col1 fl">
              <div class="row13-top-col1-jg">主汽压力设定</div>
              <div>煤气压力设定</div>
            </div>
            <div class="row13-top-col1 fl">
              <div>SPH</div>
              <div
                class="down"
                @click="
                  toIpt(
                    infoList.QYSP_H,
                    '主汽压力设定-SPH',
                    'QYSP_H',
                    infoList.QYSP_H_node_string
                  )
                "
              >
                {{ "QYSP_H" in infoList ? infoList.QYSP_H : 0 }}
              </div>
              <div
                class="down"
                @click="
                  toIpt(
                    infoList.GLP_H,
                    '煤气压力设定-SPH',
                    'GLP_H',
                    infoList.GLP_H_node_string
                  )
                "
              >
                {{ "GLP_H" in infoList ? infoList.GLP_H : 0 }}
              </div>
            </div>
            <div class="row13-top-col1 fl">
              <div>SPL</div>
              <div
                class="down"
                @click="
                  toIpt(
                    infoList.QYSP_L,
                    '主汽压力设定-SPL',
                    'QYSP_L',
                    infoList.QYSP_L_node_string
                  )
                "
              >
                {{ "QYSP_L" in infoList ? infoList.QYSP_L : 0 }}
              </div>
              <div
                class="down"
                @click="
                  toIpt(
                    infoList.GLP_L,
                    '煤气压力设定-SPL',
                    'GLP_L',
                    infoList.GLP_L_node_string
                  )
                "
              >
                {{ "GLP_L" in infoList ? infoList.GLP_L : 0 }}
              </div>
            </div>
            <div class="row13-top-col2 fl">
              <div class="row13-top-col1-jg">一级主汽温度设定</div>
              <div
                class="down"
                @click="
                  toIpt(
                    infoList.QWSP2,
                    '一级主汽温度设定',
                    'QWSP2',
                    infoList.QWSP2_node_string
                  )
                "
              >
                {{ "QWSP2" in infoList ? infoList.QWSP2 : 0 }}
              </div>
            </div>
          </div>
          <div class="row13-center">
            <div class="row13-center-colo1">
              <div
                class="row13-center-colo1-row1 fl"
                @click="
                  toCompon(
                    2,
                    'FICA_110_B',
                    infoList.FICA_110_B_node_string,
                    'FICA_110_NG3',
                    '二减甲流量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.FICA_110_B,
                    'FICA_110_B',
                    infoList.FICA_110_B_node_string,
                    'FICA_110_NG3'
                  )
                "
              >
                {{ "FICA_110_B" in infoList ? infoList.FICA_110_B : 0 }}t/h
              </div>
              <div
                class="row13-center-colo1-row1 fl"
                @click="
                  toCompon(
                    2,
                    'KF_105_B',
                    infoList.KF_105_B_node_string,
                    'KF_105_NG3',
                    '二级甲减温阀门反馈'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.KF_105_B,
                    'KF_105_B',
                    infoList.KF_105_B_node_string,
                    'KF_105_NG3'
                  )
                "
              >
                {{ "KF_105_B" in infoList ? infoList.KF_105_B : 0 }}%
              </div>
              <div class="click" @click="toCompon(7)"></div>
              <div
                class="row13-center-colo1-row2 fl"
                @click="
                  toCompon(
                    2,
                    'TICA_113_B',
                    infoList.TICA_113_B_node_string,
                    'TICA_113_NG3',
                    '二甲出口蒸汽温度'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.TICA_113_B,
                    'TICA_113_B',
                    infoList.TICA_113_B_node_string,
                    'TICA_113_NG3'
                  )
                "
              >
                {{ "TICA_113_B" in infoList ? infoList.TICA_113_B : 0 }}℃
              </div>
              <div class="fl" style="margin-top: -1.8vh">
                <div
                  class="bottonx"
                  style="margin-bottom: 0.2vh"
                  :style="{
                    background:
                      'QK1' in infoList
                        ? infoList.QK1 && !infoList.QK1.TS
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.QK1
                      ? toDetail(
                          2,
                          'QK1',
                          infoList.QK1_node_string,
                          '',
                          '总煤气量前馈调节'
                        )
                      : ''
                  "
                >
                  K
                </div>

                <div
                  class="bottonx"
                  :style="{
                    background:
                      'QK16' in infoList
                        ? infoList.QK16 && !infoList.QK16.TS
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.QK16
                      ? toDetail(
                          2,
                          'QK16',
                          infoList.QK16_node_string,
                          '',
                          '二级甲入口蒸汽温度前馈'
                        )
                      : ''
                  "
                >
                  K
                </div>
              </div>

              <div
                class="bottonx fl"
                style="margin-top: 0.6vh"
                :style="{
                  background:
                    'XK2' in infoList
                      ? infoList.XK2 && infoList.XK2.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK2
                    ? toDetail(
                        1,
                        'XK2',
                        infoList.XK2_node_string,
                        '',
                        '二级甲减温先控调节'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="bottonx right-buttonjg1 fl"
                :style="{
                  background:
                    'MAN5' in infoList
                      ? infoList.MAN5 == 1 && infoList.MAN5.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.MAN5
                    ? toCompon(
                        0,
                        'AV',
                        'MAN5',
                        'MAN5_AV_NG3',
                        '二级甲减温水手操器',
                        'KF_105_B',
                        infoList.MAN5_node_string,
                        'KF_105_NG3'
                      )
                    : ''
                "
              >
                A
              </div>
              <div class="gslable fl">二甲</div>
            </div>
            <div class="row13-center-colo2">
              <div
                class="row13-center-colo1-row1 fl"
                @click="
                  toCompon(
                    2,
                    'FICA_111_B',
                    infoList.FICA_111_B_node_string,
                    'FICA_111_NG3',
                    '二减乙流量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.FICA_111_B,
                    'FICA_111_B',
                    infoList.FICA_111_B_node_string,
                    'FICA_111_NG3'
                  )
                "
              >
                {{ "FICA_111_B" in infoList ? infoList.FICA_111_B : 0 }}t/h
              </div>
              <div
                class="row13-center-colo1-row1 fl"
                @click="
                  toCompon(
                    2,
                    'KF_106_B',
                    infoList.KF_106_B_node_string,
                    'KF_106_NG3',
                    '二级乙减温阀门反馈'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.KF_106_B,
                    'KF_106_B',
                    infoList.KF_106_B_node_string,
                    'KF_106_NG3'
                  )
                "
              >
                {{ "KF_106_B" in infoList ? infoList.KF_106_B : 0 }}%
              </div>
              <div class="click" @click="toCompon(7)"></div>
              <div
                class="row13-center-colo1-row2 fl"
                @click="
                  toCompon(
                    2,
                    'TICA_115_B',
                    infoList.TICA_115_B_node_string,
                    'TICA_115_NG3',
                    '二乙出口蒸汽温度'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.TICA_115_B,
                    'TICA_115_B',
                    infoList.TICA_115_B_node_string,
                    'TICA_115_NG3'
                  )
                "
              >
                {{ "TICA_115_B" in infoList ? infoList.TICA_115_B : 0 }}℃
              </div>
              <div class="fl" style="margin-top: 0.3vh">
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      'QK17' in infoList
                        ? infoList.QK17 && !infoList.QK17.TS
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.QK17
                      ? toDetail(
                          2,
                          'QK17',
                          infoList.QK17_node_string,
                          '',
                          '二级乙入口蒸汽温度前馈'
                        )
                      : ''
                  "
                >
                  K
                </div>

                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      'XK3' in infoList
                        ? infoList.XK3 && infoList.XK3.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK3
                      ? toDetail(
                          1,
                          'XK3',
                          infoList.XK3_node_string,
                          '',
                          '二级乙减温先控调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx right-buttonjg1 fl"
                  :style="{
                    background:
                      'MAN6' in infoList
                        ? infoList.MAN6 && infoList.MAN6.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN6
                      ? toCompon(
                          0,
                          'AV',
                          'MAN6',
                          'MAN6_AV_NG3',
                          '二乙甲减温水手操器',
                          'KF_106_B',
                          infoList.MAN6_node_string,
                          'KF_106_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
                <div class="gslable fl">二乙</div>
              </div>
            </div>
            <div class="row13-center-colo2">
              <div
                class="row13-center-colo1-row1 fl"
                @click="
                  toCompon(
                    2,
                    'FICA_108_B',
                    infoList.FICA_108_B_node_string,
                    'FICA_108_NG3',
                    '一减甲流量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.FICA_108_B,
                    'FICA_108_B',
                    infoList.FICA_108_B_node_string,
                    'FICA_108_NG3'
                  )
                "
              >
                {{ "FICA_108_B" in infoList ? infoList.FICA_108_B : 0 }}t/h
              </div>
              <div
                class="row13-center-colo1-row1 fl"
                @click="
                  toCompon(
                    2,
                    'KF_103_B',
                    infoList.KF_103_B_node_string,
                    'KF_103_NG3',
                    '一级甲减温阀门反馈'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.KF_103_B,
                    'KF_103_B',
                    infoList.KF_103_B_node_string,
                    'KF_103_NG3'
                  )
                "
              >
                {{ "KF_103_B" in infoList ? infoList.KF_103_B : 0 }}%
              </div>
              <div class="click" @click="toCompon(7)"></div>
              <div
                class="row13-center-colo1-row2 fl"
                @click="
                  toCompon(
                    2,
                    'TICA_117_B',
                    infoList.TICA_117_B_node_string,
                    'TICA_117_NG3',
                    '一甲出口蒸汽温度'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.TICA_117_B,
                    'TICA_117_B',
                    infoList.TICA_117_B_node_string,
                    'TICA_117_NG3'
                  )
                "
              >
                {{ "TICA_117_B" in infoList ? infoList.TICA_117_B : 0 }}℃
              </div>
              <div class="fl">
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      'QK14' in infoList
                        ? infoList.QK14 && !infoList.QK14.TS
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.QK14
                      ? toDetail(
                          2,
                          'QK14',
                          infoList.QK14_node_string,
                          '',
                          '一级甲入口蒸汽温度前馈'
                        )
                      : ''
                  "
                >
                  K
                </div>

                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      'XK14' in infoList
                        ? infoList.XK14 && infoList.XK14.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK14
                      ? toDetail(
                          1,
                          'XK14',
                          infoList.XK14_node_string,
                          '',
                          '一级甲主汽温度'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx right-buttonjg1 fl"
                  :style="{
                    background:
                      'MAN3' in infoList
                        ? infoList.MAN3 && infoList.MAN3.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN3
                      ? toCompon(
                          0,
                          'AV',
                          'MAN3',
                          'MAN3_AV_NG3',
                          '一级甲减温水手操器',
                          'KF_103_B',
                          infoList.MAN3_node_string,
                          'KF_103_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
                <div class="gslable fl">一甲</div>
              </div>
            </div>
            <div class="row13-center-colo2">
              <div
                class="row13-center-colo1-row1 fl"
                @click="
                  toCompon(
                    2,
                    'FICA_109_B',
                    infoList.FICA_109_B_node_string,
                    'FICA_109_NG3',
                    '一减乙流量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.FICA_109_B,
                    'FICA_109_B',
                    infoList.FICA_109_B_node_string,
                    'FICA_109_NG3'
                  )
                "
              >
                {{ "FICA_109_B" in infoList ? infoList.FICA_109_B : 0 }}t/h
              </div>
              <div
                class="row13-center-colo1-row1 fl"
                @click="
                  toCompon(
                    2,
                    'KF_104_B',
                    infoList.KF_104_B_node_string,
                    'KF_104_NG3',
                    '一级乙减温阀门反馈'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.KF_104_B,
                    'KF_104_B',
                    infoList.KF_104_B_node_string,
                    'KF_104_NG3'
                  )
                "
              >
                {{ "KF_104_B" in infoList ? infoList.KF_104_B : 0 }}%
              </div>
              <div class="click" @click="toCompon(7)"></div>
              <div
                class="row13-center-colo1-row2 fl"
                @click="
                  toCompon(
                    2,
                    'TICA_119_B',
                    infoList.TICA_119_B_node_string,
                    'TICA_119_NG3',
                    '一乙出口蒸汽温度'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.TICA_119_B,
                    'TICA_119_B',
                    infoList.TICA_119_B_node_string,
                    'TICA_119_NG3'
                  )
                "
              >
                {{ "TICA_119_B" in infoList ? infoList.TICA_119_B : 0 }}℃
              </div>
              <div class="fl">
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      'QK15' in infoList
                        ? infoList.QK15 && !infoList.QK15.TS
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.QK15
                      ? toDetail(
                          2,
                          'QK15',
                          infoList.QK15_node_string,
                          '',
                          '一级乙入口蒸汽温度前馈'
                        )
                      : ''
                  "
                >
                  K
                </div>

                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      'XK15' in infoList
                        ? infoList.XK15 && infoList.XK15.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.XK15
                      ? toDetail(
                          1,
                          'XK15',
                          infoList.XK15_node_string,
                          '',
                          '一级乙主汽温度'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx right-buttonjg1 fl"
                  :style="{
                    background:
                      'MAN4' in infoList
                        ? infoList.MAN4 && infoList.MAN4.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN4
                      ? toCompon(
                          0,
                          'AV',
                          'MAN4',
                          'MAN4_AV_NG3',
                          '一级乙减温水手操器',
                          'KF_104_B',
                          infoList.MAN4_node_string,
                          'KF_104_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
                <div class="gslable fl">一乙</div>
              </div>
            </div>
            <!-- 上水部分 -->
            <div class="row13-center-colo3">
              <div
                class="row13-center-colo3-row1 fl"
                @click="
                  toCompon(
                    2,
                    'KB_101_B',
                    infoList.KB_101_B_node_string,
                    'KB_101_NG3',
                    '主给水阀门DCS输出'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.KB_101_B,
                    'KB_101_B',
                    infoList.KB_101_B_node_string,
                    'KB_101_NG3'
                  )
                "
              >
                {{ "KB_101_B" in infoList ? infoList.KB_101_B : 0 }}%
              </div>
              <div class="click click1" @click="toCompon(6)"></div>
              <div
                class="bottonx fl row13-center-colo3-row1-jg colo3-jg1"
                :style="{
                  background:
                    'XK1' in infoList
                      ? infoList.XK1 && infoList.XK1.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK1
                    ? toDetail(
                        1,
                        'XK1',
                        infoList.XK1_node_string,
                        '',
                        '汽包水位主调节先控'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="bottonx fl row13-center-colo3-row1-jg"
                :style="{
                  background:
                    'XK19' in infoList
                      ? infoList.XK19 && infoList.XK19.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK19
                    ? toDetail(
                        1,
                        'XK19',
                        infoList.XK19_node_string,
                        '',
                        '主给水副调节'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="bottonx fl row13-center-colo3-row1-jg"
                :style="{
                  background:
                    'MAN1' in infoList
                      ? infoList.MAN1 && infoList.MAN1.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.MAN1
                    ? toCompon(
                        0,
                        'AV',
                        'MAN1',
                        'MAN1_AV_NG3',
                        '主给水手操器',
                        'KF_101_B',
                        infoList.MAN1_node_string,
                        'KF_101_NG3'
                      )
                    : ''
                "
              >
                A
              </div>
              <div
                class="bottonx fl row13-center-colo3-row1-jg colo3-jg2"
                :style="{
                  background:
                    'XK22' in infoList
                      ? infoList.XK22 && infoList.XK22.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK22
                    ? toDetail(
                        1,
                        'XK22',
                        infoList.XK22_node_string,
                        '',
                        '上水压力控制'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="bottonx fl row13-center-colo3-row1-jg"
                :style="{
                  background:
                    'MAN28' in infoList
                      ? infoList.MAN28 && infoList.MAN28.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.MAN28
                    ? toCompon(
                        0,
                        'AV',
                        'MAN28',
                        'MAN28_AV_NG3',
                        '上水变频泵1手操器',
                        'KF_128_B',
                        infoList.MAN28_node_string,
                        'KF_128_NG3'
                      )
                    : ''
                "
              >
                A
              </div>
              <div
                class="bottonx fl row13-center-colo3-row1-jg"
                :style="{
                  background:
                    'MAN29' in infoList
                      ? infoList.MAN29 && infoList.MAN29.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.MAN29
                    ? toCompon(
                        0,
                        'AV',
                        'MAN29',
                        'MAN29_AV_NG3',
                        '上水变频泵2手操器',
                        'KF_129_B',
                        infoList.MAN29_node_string,
                        'KF_129_NG3'
                      )
                    : ''
                "
              >
                A
              </div>
              <div class="fl colo3-jg2">
                <div class="colo3-jg4">
                  <div
                    class="fl"
                    @click="
                      toCompon(
                        2,
                        'TICA_101_B',
                        infoList.TICA_101_B_node_string,
                        'TICA_101_NG3',
                        '给水温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.TICA_101_B,
                        'TICA_101_B',
                        infoList.TICA_101_B_node_string,
                        'TICA_101_NG3'
                      )
                    "
                  >
                    {{ "TICA_101_B" in infoList ? infoList.TICA_101_B : 0 }}
                  </div>
                  <div class="fl danwei">℃</div>
                </div>
                <div class="colo3-jg4">
                  <div
                    class="fl"
                    @click="
                      toCompon(
                        2,
                        'FICA_101_B',
                        infoList.FICA_101_B_node_string,
                        'FICA_101_NG3',
                        '给水流量'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.FICA_101_B,
                        'FICA_101_B',
                        infoList.FICA_101_B_node_string,
                        'FICA_101_NG3'
                      )
                    "
                  >
                    {{ "FICA_101_B" in infoList ? infoList.FICA_101_B : 0 }}
                  </div>
                  <div class="fl danwei">t/h</div>
                </div>
                <div class="colo3-jg4">
                  <div
                    class="fl rightfm-col2x"
                    @click="
                      toCompon(
                        2,
                        'PICA_101_B',
                        infoList.PICA_101_B_node_string,
                        'PICA_101_NG3',
                        '给水压力'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.PICA_101_B,
                        'PICA_101_B',
                        infoList.PICA_101_B_node_string,
                        'PICA_101_NG3'
                      )
                    "
                  >
                    {{ "PICA_101_B" in infoList ? infoList.PICA_101_B : 0 }}
                  </div>
                  <div class="fl danwei rightfm-col2x">MPa</div>
                </div>
              </div>
              <div class="gslable1 fl">上水</div>
            </div>
            <div class="row13-center-colo4">
              <div
                class="row13-center-colo3-row1 fl"
                @click="
                  toCompon(
                    2,
                    'KB_102_B',
                    infoList.KB_102_B_node_string,
                    'KB_102_NG3',
                    '副给水阀门DCS输出'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.KB_102_B,
                    'KB_102_B',
                    infoList.KB_102_B_node_string,
                    'KB_102_NG3'
                  )
                "
              >
                {{ "KB_102_B" in infoList ? infoList.KB_102_B : 0 }}%
              </div>
              <div class="click click1" @click="toCompon(6)"></div>
              <div
                class="bottonx fl row13-center-colo4-row1-jg"
                :style="{
                  background:
                    'XK20' in infoList
                      ? infoList.XK20 && infoList.XK20.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.XK20
                    ? toDetail(
                        1,
                        'XK20',
                        infoList.XK20_node_string,
                        '',
                        '副给水副调节'
                      )
                    : ''
                "
              >
                X
              </div>
              <div
                class="bottonx fl row13-center-colo3-row1-jg"
                :style="{
                  background:
                    'MAN2' in infoList
                      ? infoList.MAN2 && infoList.MAN2.RM == 1
                        ? '#2AFC30'
                        : 'red'
                      : 'red',
                }"
                @click="
                  infoList.MAN2
                    ? toCompon(
                        0,
                        'AV',
                        'MAN2',
                        'MAN2_AV_NG3',
                        '副给水手操器',
                        'KF_102_B',
                        infoList.MAN2_node_string,
                        'KF_102_NG3'
                      )
                    : ''
                "
              >
                A
              </div>
            </div>
            <!-- 引风机1 -->
            <div class="row13-center-colo5">
              <div class="row13-center-colo5-row1 fl">
                <div
                  @click="
                    toCompon(
                      2,
                      'FICA_103_B',
                      infoList.FICA_103_B_node_string,
                      'FICA_103_NG3',
                      '1#送风量'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.FICA_103_B,
                      'FICA_103_B',
                      infoList.FICA_103_B_node_string,
                      'FICA_103_NG3'
                    )
                  "
                >
                  {{ "FICA_103_B" in infoList ? infoList.FICA_103_B : 0 }} m³/h
                </div>
                <div
                  @click="
                    toCompon(
                      2,
                      'FICA_104_B',
                      infoList.FICA_104_B_node_string,
                      'FICA_104_NG3',
                      '2#送风量'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.FICA_104_B,
                      'FICA_104_B',
                      infoList.FICA_104_B_node_string,
                      'FICA_104_NG3'
                    )
                  "
                >
                  {{ "FICA_104_B" in infoList ? infoList.FICA_104_B : 0 }} m³/h
                </div>
              </div>
              <div class="row13-center-colo5-row2 fl">
                <div>
                  <div
                    class="bottonx fl"
                    :style="{
                      background:
                        'QK19' in infoList
                          ? infoList.QK19 && !infoList.QK19.TS
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      infoList.QK19
                        ? toDetail(
                            2,
                            'QK19',
                            infoList.QK19_node_string,
                            '',
                            '折高煤气-送风机k挡板开度前馈'
                          )
                        : ''
                    "
                  >
                    K
                  </div>
                  <div
                    class="bottonx fl"
                    :style="{
                      background:
                        'XK6' in infoList
                          ? infoList.XK6 && infoList.XK6.RM == 1
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      infoList.XK6
                        ? toDetail(
                            1,
                            'XK6',
                            infoList.XK6_node_string,
                            '',
                            '送风变频先控调节'
                          )
                        : ''
                    "
                  >
                    X
                  </div>
                  <div
                    class="bottonx fl"
                    :style="{
                      background:
                        'MAN11' in infoList
                          ? infoList.MAN11 && infoList.MAN11.RM == 1
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      infoList.MAN11
                        ? toCompon(
                            0,
                            'AV',
                            'MAN11',
                            'MAN11_AV_NG3',
                            '1#送风机变频手操器',
                            'KF_111_B',
                            infoList.MAN11_node_string,
                            'KF_111_NG3'
                          )
                        : ''
                    "
                  >
                    A
                  </div>
                </div>
                <div
                  class="colo5-row2-jg22"
                  @click="
                    toCompon(
                      2,
                      'PICA_123_B',
                      infoList.PICA_123_B_node_string,
                      'PICA_123_NG3',
                      '1#送风机出口压力'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.PICA_123_B,
                      'PICA_123_B',
                      infoList.PICA_123_B_node_string,
                      'PICA_123_NG3'
                    )
                  "
                >
                  {{ "PICA_123_B" in infoList ? infoList.PICA_123_B : 0 }}KPa
                </div>
                <div
                  class="bottonx colo5-row2-jgx"
                  :style="{
                    background:
                      'MAN12' in infoList
                        ? infoList.MAN12 && infoList.MAN12.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN12
                      ? toCompon(
                          0,
                          'AV',
                          'MAN12',
                          'MAN12_AV_NG3',
                          '2#送风机变频手操器',
                          'KF_112_B',
                          infoList.MAN12_node_string,
                          'KF_112_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
                <div
                  class="colo5-row2-jg222"
                  @click="
                    toCompon(
                      2,
                      'PICA_124_B',
                      infoList.PICA_124_B_node_string,
                      'PICA_124_NG3',
                      '2#送风机出口压力'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.PICA_124_B,
                      'PICA_124_B',
                      infoList.PICA_124_B_node_string,
                      'PICA_124_NG3'
                    )
                  "
                >
                  {{ "PICA_124_B" in infoList ? infoList.PICA_124_B : 0 }}KPa
                </div>
              </div>
              <div class="row13-center-colo5-row3 fl">
                <div
                  class="colo5-row3-jg1"
                  @click="
                    toCompon(
                      2,
                      'ISFJ1_B',
                      infoList.ISFJ1_B_node_string,
                      'ISFJ1_NG3',
                      '送风机电流1'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.ISFJ1_B,
                      'ISFJ1_B',
                      infoList.ISFJ1_B_node_string,
                      'ISFJ1_NG3'
                    )
                  "
                >
                  {{ "ISFJ1_B" in infoList ? infoList.ISFJ1_B : 0 }}A
                </div>
                <div
                  class="colo5-row3-jg2"
                  @click="
                    toCompon(
                      2,
                      'ISFJ2_B',
                      infoList.ISFJ2_B_node_string,
                      'ISFJ2_NG3',
                      '送风机电流2'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.ISFJ2_B,
                      'ISFJ2_B',
                      infoList.ISFJ2_B_node_string,
                      'ISFJ2_NG3'
                    )
                  "
                >
                  {{ "ISFJ2_B" in infoList ? infoList.ISFJ2_B : 0 }}A
                </div>
              </div>
              <div class="row13-center-colo5-row4 fl">
                <div class="colo5-row4">
                  <div
                    class="bottonx fl"
                    :style="{
                      background:
                        'MAN13' in infoList
                          ? infoList.MAN13 && infoList.MAN13.RM == 1
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      infoList.MAN13
                        ? toCompon(
                            0,
                            'AV',
                            'MAN13',
                            'MAN13_AV_NG3',
                            '1#送风机挡板手操器',
                            'KF_113_B',
                            infoList.MAN13_node_string,
                            'KF_113_NG3'
                          )
                        : ''
                    "
                  >
                    A
                  </div>
                  <div
                    class="bottonx fl"
                    :style="{
                      background:
                        'QK11' in infoList
                          ? infoList.QK11 && !infoList.QK11.TS
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      infoList.QK11
                        ? toDetail(
                            2,
                            'QK11',
                            infoList.QK11_node_string,
                            '',
                            '折高煤气-送风机变频开度前馈'
                          )
                        : ''
                    "
                  >
                    K
                  </div>

                  <div
                    class="bottonx fl"
                    :style="{
                      background:
                        'XK7' in infoList
                          ? infoList.XK7 && infoList.XK7.RM == 1
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      infoList.XK7
                        ? toDetail(
                            1,
                            'XK7',
                            infoList.XK7_node_string,
                            '',
                            '送风挡板先控调节'
                          )
                        : ''
                    "
                  >
                    X
                  </div>
                </div>
                <div class="click click2" @click="toCompon(9)"></div>
                <div class="click click3" @click="toCompon(10)"></div>
                <div class="colo5-row3-jg3">
                  <div
                    class="fl colo5-row2-jg2"
                    @click="
                      toCompon(
                        2,
                        'KF_111_B',
                        infoList.KF_111_B_node_string,
                        'KF_111_NG3',
                        '1#送风变频反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_111_B,
                        'KF_111_B',
                        infoList.KF_111_B_node_string,
                        'KF_111_NG3'
                      )
                    "
                  >
                    {{ "KF_111_B" in infoList ? infoList.KF_111_B : 0 }}Hz
                  </div>
                  <div
                    class="fl colo5-row3-jg4 colo5-row2-jg2"
                    @click="
                      toCompon(
                        2,
                        'KF_113_B',
                        infoList.KF_113_B_node_string,
                        'KF_113_NG3',
                        '1#送风挡板反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_113_B,
                        'KF_113_B',
                        infoList.KF_113_B_node_string,
                        'KF_113_NG3'
                      )
                    "
                  >
                    {{ "KF_113_B" in infoList ? infoList.KF_113_B : 0 }}%
                  </div>
                </div>
                <div class="colo5-row3-jg34">
                  <div
                    class="bottonx fl"
                    :style="{
                      background:
                        'MAN14' in infoList
                          ? infoList.MAN14 && infoList.MAN14.RM == 1
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      infoList.MAN14
                        ? toCompon(
                            0,
                            'AV',
                            'MAN14',
                            'MAN14_AV_NG3',
                            '2#送风机挡板手操器',
                            'KF_114_B',
                            infoList.MAN14_node_string,
                            'KF_114_NG3'
                          )
                        : ''
                    "
                  >
                    A
                  </div>
                </div>
                <div class="colo5-row3-jg3">
                  <div
                    class="fl colo5-row2-jg2"
                    @click="
                      toCompon(
                        2,
                        'KF_112_B',
                        infoList.KF_112_B_node_string,
                        'KF_112_NG3',
                        '2#送风变频反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_112_B,
                        'KF_112_B',
                        infoList.KF_112_B_node_string,
                        'KF_112_NG3'
                      )
                    "
                  >
                    {{ "KF_112_B" in infoList ? infoList.KF_112_B : 0 }}Hz
                  </div>
                  <div
                    class="fl colo5-row3-jg4 colo5-row2-jg2"
                    @click="
                      toCompon(
                        2,
                        'KF_114_B',
                        infoList.KF_114_B_node_string,
                        'KF_114_NG3',
                        '2#送风挡板反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_114_B,
                        'KF_114_B',
                        infoList.KF_114_B_node_string,
                        'KF_114_NG3'
                      )
                    "
                  >
                    {{ "KF_114_B" in infoList ? infoList.KF_114_B : 0 }}%
                  </div>
                </div>
              </div>
              <div class="row13-center-colo5-row5 fl">
                <div class="colo5-row5-jg">1#</div>
                <div class="colo5-row5-jg2">2#</div>
              </div>
            </div>
            <!-- 引风机2 -->
            <div class="row13-center-colo6">
              <div class="row13-center-colo5-row1 fl">
                <!-- <div>0.00 m³/h</div>
                    <div>0.000  m³/h</div> -->
              </div>
              <div class="row13-center-colo5-row2 fl">
                <div>
                  <div
                    class="bottonx fl"
                    :style="{
                      background:
                        'QK2' in infoList
                          ? infoList.QK2 && !infoList.QK2.TS
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      infoList.QK2
                        ? toDetail(
                            2,
                            'QK2',
                            infoList.QK2_node_string,
                            '',
                            '引风前馈调节'
                          )
                        : ''
                    "
                  >
                    K
                  </div>

                  <div
                    class="bottonx fl"
                    :style="{
                      background:
                        'XK4' in infoList
                          ? infoList.XK4 && infoList.XK4.RM == 1
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      infoList.XK4
                        ? toDetail(
                            1,
                            'XK4',
                            infoList.XK4_node_string,
                            '',
                            '引风变频先控调节'
                          )
                        : ''
                    "
                  >
                    X
                  </div>
                  <div
                    class="bottonx fl"
                    :style="{
                      background:
                        'MAN7' in infoList
                          ? infoList.MAN7 && infoList.MAN7.RM == 1
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      infoList.MAN7
                        ? toCompon(
                            0,
                            'AV',
                            'MAN7',
                            'MAN7_AV_NG3',
                            '1#引风机变频手操器',
                            'KF_107_B',
                            infoList.MAN7_node_string,
                            'KF_107_NG3'
                          )
                        : ''
                    "
                  >
                    A
                  </div>
                </div>
                <div
                  class="bottonx colo6-row2-jg"
                  :style="{
                    background:
                      'MAN8' in infoList
                        ? infoList.MAN8 && infoList.MAN8.RM == 1
                          ? '#2AFC30'
                          : 'red'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN8
                      ? toCompon(
                          0,
                          'AV',
                          'MAN8',
                          'MAN8_AV_NG3',
                          '2#引风机变频手操器',
                          'KF_108_B',
                          infoList.MAN8_node_string,
                          'KF_108_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
                <!-- <div class="colo5-row2-jg2" @click="toCompon(2, 'KF_114_B', infoList._node_string, 'KF_114_NG3')" >{{'' in infoList?infoList.KF_114_B :0}}A</div> -->
              </div>
              <div class="row13-center-colo5-row3 fl">
                <div
                  class="colo5-row3-jg1"
                  @click="
                    toCompon(
                      2,
                      'IYFJ1_B',
                      infoList.IYFJ1_B_node_string,
                      'IYFJ1_NG3',
                      '引风机电流1'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.IYFJ1_B,
                      'IYFJ1_B',
                      infoList.IYFJ1_B_node_string,
                      'IYFJ1_NG3'
                    )
                  "
                >
                  {{ "IYFJ1_B" in infoList ? infoList.IYFJ1_B : 0 }}A
                </div>
                <div
                  class="colo6-row3-jg2"
                  @click="
                    toCompon(
                      2,
                      'IYFJ2_B',
                      infoList.IYFJ2_B_node_string,
                      'IYFJ2_NG3',
                      '引风机电流2'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.IYFJ2_B,
                      'IYFJ2_B',
                      infoList.IYFJ2_B_node_string,
                      'IYFJ2_NG3'
                    )
                  "
                >
                  {{ "IYFJ2_B" in infoList ? infoList.IYFJ2_B : 0 }}A
                </div>
              </div>
              <div class="row13-center-colo5-row4 fl">
                <div class="colo5-row4">
                  <div
                    class="bottonx fl"
                    :style="{
                      background:
                        'MAN9' in infoList
                          ? infoList.MAN9 && infoList.MAN9.RM == 1
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      infoList.MAN9
                        ? toCompon(
                            0,
                            'AV',
                            'MAN9',
                            'MAN9_AV_NG3',
                            '1#引风机挡板手操器',
                            'KF_109_B',
                            infoList.MAN9_node_string,
                            'KF_109_NG3'
                          )
                        : ''
                    "
                  >
                    A
                  </div>
                  <!-- <div class="bottonx fl ">K</div>   -->
                  <div
                    class="bottonx fl"
                    :style="{
                      background:
                        'XK5' in infoList
                          ? infoList.XK5 && infoList.XK5.RM == 1
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      infoList.XK5
                        ? toDetail(
                            1,
                            'XK5',
                            infoList.XK5_node_string,
                            '',
                            '引风挡板先控调节'
                          )
                        : ''
                    "
                  >
                    X
                  </div>
                </div>
                <div class="click click2" @click="toCompon(8)"></div>
                <div class="click click4" @click="toCompon(8)"></div>
                <div class="colo5-row3-jg3">
                  <div
                    class="fl colo5-row2-jg2"
                    @click="
                      toCompon(
                        2,
                        'KF_107_B',
                        infoList.KF_107_B_node_string,
                        'KF_107_NG3',
                        '1#引风变频反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_107_B,
                        'KF_107_B',
                        infoList.KF_107_B_node_string,
                        'KF_107_NG3'
                      )
                    "
                  >
                    {{ "KF_107_B" in infoList ? infoList.KF_107_B : 0 }}Hz
                  </div>
                  <div
                    class="fl colo5-row3-jg4 colo5-row2-jg2"
                    @click="
                      toCompon(
                        2,
                        'KF_109_B',
                        infoList.KF_109_B_node_string,
                        'KF_109_NG3',
                        '1#引风挡板反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_109_B,
                        'KF_109_B',
                        infoList.KF_109_B_node_string,
                        'KF_109_NG3'
                      )
                    "
                  >
                    {{ "KF_109_B" in infoList ? infoList.KF_109_B : 0 }}%
                  </div>
                </div>
                <div class="colo6-row3-jg34">
                  <div
                    class="bottonx fl"
                    :style="{
                      background:
                        'MAN10' in infoList
                          ? infoList.MAN10 && infoList.MAN10.RM == 1
                            ? '#2AFC30'
                            : 'red'
                          : 'red',
                    }"
                    @click="
                      infoList.MAN10
                        ? toCompon(
                            0,
                            'AV',
                            'MAN10',
                            'MAN10_AV_NG3',
                            '2#引风机挡板手操器',
                            'KF_110_B',
                            infoList.MAN10_node_string,
                            'KF_110_NG3'
                          )
                        : ''
                    "
                  >
                    A
                  </div>
                </div>
                <div class="colo5-row3-jg3">
                  <div
                    class="fl colo5-row2-jg2"
                    @click="
                      toCompon(
                        2,
                        'KF_108_B',
                        infoList.KF_108_B_node_string,
                        'KF_108_NG3',
                        '2#引风变频反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_108_B,
                        'KF_108_B',
                        infoList.KF_108_B_node_string,
                        'KF_108_NG3'
                      )
                    "
                  >
                    {{ "KF_108_B" in infoList ? infoList.KF_108_B : 0 }}Hz
                  </div>
                  <div
                    class="fl colo5-row3-jg4 colo5-row2-jg2"
                    @click="
                      toCompon(
                        2,
                        'KF_110_B',
                        infoList.KF_110_B_node_string,
                        'KF_110_NG3',
                        '2#引风挡板反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_110_B,
                        'KF_110_B',
                        infoList.KF_110_B_node_string,
                        'KF_110_NG3'
                      )
                    "
                  >
                    {{ "KF_110_B" in infoList ? infoList.KF_110_B : 0 }}%
                  </div>
                </div>
              </div>
              <div class="row13-center-colo5-row5 fl">
                <div class="colo5-row5-jg">1#</div>
                <div class="colo5-row6-jg2">2#</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :pmark="Panualmark"
      :pnode="Panualnode"
      :pnm="Panualnodem"
      :infoList="infoList"
    ></Manual>

    <Firstcontrol
      :infoList="infoList"
      :isIndex="isIndex"
      :historyname="Firstcontrolname"
      :titname="Firstcontroltitname"
      :node="Firstcontrolnode"
      v-if="isFshow"
      @sendStatus="isClose"
    >
    </Firstcontrol>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
    <Observer
      v-if="isOshow"
      @sendStatus="isClose"
      :infoList="infoList"
      :historyname="Observername"
      :node="Observernode"
    ></Observer>
    <publicParam
      v-if="isPshow"
      @sendStatus="isClose"
      :infoList="infoList"
    ></publicParam>
    <Param02
      v-if="Param02"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param02>
    <Param01
      v-if="Param01"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param01>
    <Param03
      v-if="Param03"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param03>
    <Param04
      v-if="Param04"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param04>
    <Param041
      v-if="Param041"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param041>
    <Param05
      v-if="Param05"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param05>
    <RqCommonParameters
      v-if="flag == 4 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></RqCommonParameters>
    <RqSoftNumber
      v-if="flag == 6 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></RqSoftNumber>
    <RqOnlineService
      v-if="flag == 7 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></RqOnlineService>
  </div>
</template>

<script>
import index from "./_index/index.js";
import Manual from "@/components/Manual1.vue"; //手操器组件
//import Evaluate from "@/components/Evaluate.vue"; //运行评价组件
//import Security from "@/components/Security.vue"; //安全设置组件
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
// import Switchpage from "@/components/Switchpage.vue"; //切换画面组件
import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Observer from "@/components/Observer.vue"; //观测器模版组件
import Rsf from "@/components/RSF.vue"; //阮伺服组件
import publicParam from "@/components/Param.vue"; //公共参数组件
import Param01 from "@/views/RqBoiler/RqSteamDrum/index.vue"; //参数01组件
import Param02 from "@/views/RqBoiler/RqMainTemperature/index.vue"; //参数02组件
import Param03 from "@/views/RqBoiler/RqParameter3/index.vue"; //参数03组件
import Param04 from "@/views/RqBoiler/RqParameter4/index.vue"; //参数04_0组件
import Param041 from "@/views/RqBoiler/RqAirSupply/index.vue"; //参数04_01组件
import Param05 from "@/views/RqBoiler/RqParameter5/index.vue"; //参数05组件
import RqCommonParameters from "@/views/RqBoiler/RqCommonParameters/index.vue"; //常用参数组件
import RqSoftNumber from "@/views/RqBoiler/RqSoftNumber/index.vue"; //软测量数统
import RqOnlineService from "@/views/RqBoiler/RqOnlineService/index.vue"; //客服
export default {
  name: "Rqindex",
  components: {
    Manual,
    Firstcontrol,
    ParameterYh,
    Historical,
    inputVal,
    Observer,
    Rsf,
    publicParam,
    Param01,
    Param02,
    Param03,
    Param04,
    Param041,
    Param05,
    RqCommonParameters,
    RqSoftNumber,
    RqOnlineService,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data() {
    let self = this;
    return {
      chName: "",
      maskbok: false,
      Param02: false,
      Param01: false,
      isMshow: false,
      Param03: false,
      Param04: false,
      Param041: false,
      Param05: false,
      headerStyle: {
        "font-weight": "500",
        "font-size": "1.8vh",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      flag: 0,
      Panualmark: "",
      Panualnode: "",
      Panualnodem: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      rightHeight: "",
      spotArr: [],
      grouptime: null,
      config: {},
    };
  },
  mixins: [index],
  created() {
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
    this.config = {
      data: [this.infoList.PICA_125_B || 0],
      shape: "round",
      colors: ["#43ff44", "#43ff44"],
      waveOpacity: "1",
      waveNum: "50",
      formatter: "{value}mpa",
    };
    this.$bus.$on("sendMsg", (msg) => {
      // 底部组件发送来的消息
      this.flag = msg;
      if (this.flag == 0) {
        this.$router.push({
          path: "/Rqindex",
        });
      } else if (this.flag == 1) {
        this.$router.push({
          path: "/RqOperationAssessment",
        });
      } else if (this.flag == 2) {
        this.$router.push({
          path: "/RqSecurity",
        });
      } else if (this.flag == 3) {
        this.$router.push({
          path: "/RqSwitchPage",
        });
      } else if (this.flag == 5) {
        this.$router.push({
          path: "/RqDesulphurization",
        });
      }
    });
  },
  computed: {},
  mounted() {},
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
        this.config = {
          data: [n.PICA_125_B] || 0,
          shape: "round",
          colors: ["#43ff44", "#43ff44"],
          waveOpacity: "1",
          waveNum: "50",
          formatter: "{value}mpa",
        };
      },

      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    utilFun() {
      let that = this;
      this.$nextTick(function () {
        console.log(that.infoList.PICA_125_B);
      });
      return [this.infoList.PICA_125_B] || [0];
    },
    //   打开趋势组图
    qsGroup() {
      this.$router.push({
        path: "/trendGroup",
      });
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type, status) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type, status);
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
  filters: {
    // 图片过滤器
    typeFifter: (val) => {
      if (val < 5) {
        return require("../../../assets/images/rts_bigimg3_end.png");
      } else if (val > 5) {
        return require("../../../assets/images/rts_bigimg3_start.png");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rqlindex {
  width: 100vw;
  height: 87vh;
  margin-left: 0vw;
  overflow-x: hidden;
  overflow-y: hidden;
  background-image: url("~@/assets/images/rql1.png");
  background-size: 100% 100%;
  font-size: 0.9vw;
  color: #fff;

  .maskbok {
    width: 102vw;
    height: 100vh;
    margin-left: -2vw;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 99;
  }

  .fl {
    float: left;
  }

  .canshu {
    width: 7vw;
    line-height: 3vh;
    background-color: #0a1417;
    border-radius: 0vw;
    opacity: 0.56;
    text-align: center;
    font-size: 0.9vw;
    cursor: pointer;
    position: absolute;
    right: 2vw;
    color: #fff;
    top: 10vh;
  }

  .title {
    width: 100vw;
    // margin-left: 36vw;
    margin-top: 1vh;
    margin-bottom: 14vh;
    height: 4vh;
    font-family: MicrosoftYaHei;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 4vh;
    letter-spacing: 0vw;
    text-align: center;
    color: #ffffff;
    box-shadow: 0vw 0vw 0vw 0vw #009ed2;
  }

  .row1-main {
    width: 98vw;
    height: 78vh;
    // border: #ffffff 1px solid;
    margin-top: -13vh;
    cursor: pointer;
  }

  .row11 {
    width: 48vw;
    height: 78vh;
    // border: hsl(86, 74%, 52%) 1px solid;
  }

  .row12 {
    width: 18vw;
    height: 78vh;
    // border: hsl(84, 24%, 96%) 1px solid;
  }

  .row13 {
    width: 31vw;
    height: 78vh;
    // border: hsl(84, 24%, 96%) 1px solid;
  }

  .yqsd {
    width: 9.5vw;
    height: 27vh;

    margin-top: 25vh;
    // margin-left: 6.5vw;
    // border: hsl(86, 74%, 52%) 1px solid;
    // text-align: center;
  }

  .labwt {
    width: 5vw;
    // border: hsl(246, 74%, 52%) 1px solid;
    height: 4vh;
    padding-left: 3vw;
    line-height: 4vh;
  }

  .colo5-row2-jg22 {
    margin-top: 3vh;
    margin-left: 0.3vw;
    cursor: pointer;
  }

  .colo5-row2-jg222 {
    margin-top: 0.5vh;
    margin-left: 0.3vw;
    cursor: pointer;
  }

  .labwt1 {
    width: 5vw;
    // border: hsl(246, 74%, 52%) 1px solid;
    height: 6vh;
    padding-left: 2vw;
    line-height: 6vh;
  }

  .labwt2 {
    width: 6vw;
    // border: hsl(246, 74%, 52%) 1px solid;
    height: 4vh;
    padding-left: 1.5vw;
    line-height: 4vh;
    margin-top: 2.5vh;
    cursor: pointer;
  }

  .yqljg {
    // margin-left:1vw;
    height: 2vh;
    line-height: 2vh;
    cursor: pointer;
    // width:5vw;
  }

  .yqljg3 {
    // margin-left:1vw;
    height: 2vh;
    line-height: 2vh;
    width: 4vw;
    text-align: right;
    cursor: pointer;
  }

  .yqljg2 {
    // margin-left:1vw;
    height: 2vh;
    line-height: 2vh;
    width: 7vw;
  }

  .yqljg1 {
    margin-left: 1vw;
    height: 2vh;
    line-height: 2vh;
  }

  .yqlheight {
    height: 1.5vh;
  }

  .hanyangl {
    width: 8vw;
    // border: hsl(246, 74%, 52%) 1px solid;
    height: 3vh;
    padding-left: 0.5vw;
    line-height: 3vh;
    text-align: center;
  }

  .lbwidth {
    width: 4.8vw;
  }

  .grzz {
    width: 8vw;
    height: 50.2vh;
    // margin-left: 1vw;
    margin-top: 15.4vh;
    //  line-height: 11vh;;
    // border: hsl(246, 74%, 52%) 1px solid;
  }

  .grzz-col1 {
    width: 8vw;
    height: 2vh;
    font-family: MicrosoftYaHei;
    font-size: 0.9vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vw;
    color: #ffffff;
    text-align: center;
    margin-top: 1.3vh;
    margin-left: 0.3vw;
  }

  .grzz-col2 {
    width: 8vw;
    height: 1.5vh;
    font-family: MicrosoftYaHei;
    font-size: 0.9vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.5vh;
    letter-spacing: 0vw;
    color: #ffffff;
    text-align: center;
    // margin-top: 1.3vh;
    margin-left: 1.4vw;
    margin-top: -0.7vh;
  }

  .grzz-col2-jg {
    margin-left: 0.1vw;
  }

  .grzz-col1-jg {
    margin-top: 5.9vh;
  }

  .grzz-col1-jg {
    margin-top: 5.8vh;
  }

  .grzz-col1-jg1 {
    margin-left: 1.8vw;
    margin-top: 1vh;
  }

  .grzz-col1-jg2 {
    margin-top: 0.3vh;
  }

  .grzz-col1-jg3 {
    margin-top: 12.3vh;
    margin-left: -0.6vw;
  }

  .grlable {
    font-family: MicrosoftYaHei;
    font-size: 0.9vw;
    font-weight: normal;
    font-stretch: normal;
    // line-height:5.6vh;
    letter-spacing: 0vh;
    color: #ffffff;
    height: 5.6vh;
  }

  .ltwd {
    margin-top: -1.5vw;
    // margin-left:4vw;
    margin-left: 32vw;
    width: 15vw;
    height: 9vh;
    line-height: 2.3vh;
    // border: #f14ef7 1px solid;
  }

  .ltwdkd {
    width: 15vw;
    //  margin-top:-1vh;
    text-align: right;
    //  border: 1px solid saddlebrown;
    position: absolute;
  }

  .ztitl {
    width: 7vw;
    height: 10vh;
    text-align: center;
    // border: #009ed2 1px solid;
  }

  .widthls {
    width: 8vw;
    height: 2vh;
    line-height: 2vh;
    margin-left: 1vw;
    // border: #009ed2 1px solid;
  }

  .downmain {
    width: 48vw;
    height: 44vh;
    // border: #009ed2 1px solid;
    // margin-top: 1vh;
  }

  .buttonje {
    margin-top: 1vh;
  }

  .leftbutton {
    height: 44vh;
    width: 2vw;
    // border: #009ed2 1px solid;
    margin-left: 1.3vw;
    //  margin-top:4vh;
    text-align: center;
  }

  .zmsj1 {
    width: 1vw;
    height: 5vh;
    padding-left: 1vw;
    color: #878787;
  }

  .jmsj1 {
    width: 1vw;
    height: 5vh;
    padding-left: 1vw;
    color: #f3f951;
  }

  .qibao {
    width: 29vw;
    // height: 21vh;
    // border: #009ed2 1px solid;
    margin-top: -16vh;
    margin-left: 15vw;
  }

  .qb-col1 {
    width: 12vw;
    height: 5vh;
  }

  .qb-col2 {
    margin-left: 2.9vw;
    // height:16vh;
    margin-top: 2vh;
    position: relative;

    .laction {
      position: absolute;
      top: 3vh;
      font-size: 13px;
      width: 100%;
      text-align: center;
    }
  }

  .qb-yl {
    width: 15vw;
    height: 3vh;
    // border: #009ed2 1px solid;
    margin-left: 6vw;
    // margin-top:0.5vh;
  }

  .qb-yl1 {
    width: 6vw;
    background-color: #00000020;
    text-align: center;
  }

  .qb-yl2 {
    width: 6vw;
    background-color: #00000020;
    text-align: center;
    margin-left: 0.2vw;
  }

  .qb-sw {
    width: 9vw;
  }

  .qb-sw-col1 {
    width: 3vw;
    color: #fafcfc;
    height: 2vh;
    line-height: 2vh;
    text-align: center;
  }

  .qb-sw-col2 {
    width: 3vw;
    text-align: center;
  }

  .qb-sw-col3 {
    width: 1.8vw;
  }

  .qb-col1 {
    margin-top: 2vh;
    width: 5vw;
    height: 2.2vh;
    line-height: 2.2vh;
  }

  .gaomei {
    width: 8vw;
    height: 6vh;
    // border: rgb(20, 214, 37) 1px solid;
    margin-left: 1vw;
    margin-top: 3vh;
  }

  .zhuanmei {
    width: 8vw;
    height: 6vh;
    // border: #fff 1px solid;
    margin-left: 3vw;
    margin-top: 12vh;
  }

  .jiaomei {
    width: 8vw;
    height: 6vh;
    // border: #fff 1px solid;
    margin-left: 4.4vw;
    margin-top: 1vh;
  }

  .gmsj1 {
    width: 1vw;
    // height: 5.2vh;
    padding-left: 1vw;
    color: #f14ef7;
    //  border: #009ed2 1px solid;
  }

  .gmsj2 {
    width: 8vw;
    // height: 2.2vh;
    margin-left: 2.2vw;
    margin-top: -6vh;
    // border: #009ed2 1px solid;
  }

  .jmsj2 {
    width: 7.2vw;
    height: 2.2vh;
    margin-left: 1.8vw;
    margin-top: -4.8vh;
    // border: #009ed2 1px solid;
  }

  .lwidth {
    width: 2.5vw;
    height: 2.2vh;
    text-align: right;
  }

  .lwidthxx {
    width: 4.5vw;
    height: 2.2vh;
    text-align: right;
  }

  .labletx {
    // margin-left: 1vw;
    width: 4vw;
    text-align: right;
  }

  .hguandao {
    width: 29vw;
    height: 12vh;
    // border: hsl(258, 83%, 50%) 1px solid;
    margin-left: 5.6vw;
    margin-top: 1.2vh;
  }

  .col1 {
    margin-top: 2.8vh;
    width: 7vw;
    height: 2.5vh;
    line-height: 2.5vh;
  }

  .colo5-row2-jgx {
    margin-top: 1.5vh;
    margin-left: 2.3vw;
  }

  .col2 {
    width: 3vw;
    height: 2.3vh;
    line-height: 2.3vh;
    margin-top: 2.6vh;
  }

  .col3 {
    // margin-top:0.8vh;
    width: 7vw;
    height: 2.5vh;
    line-height: 2.5vh;
  }

  .col4 {
    // margin-top:0.8vh;
    width: 4.5vw;
    height: 2.6vh;
    line-height: 2.6vh;
    padding-top: 5.5vh;
  }

  .flexwz {
    margin-top: 1.5vh;
  }

  .glmq {
    width: 10vw;
    height: 2.3vh;
    margin-left: 1vw;
    margin-top: 0vh;
  }

  .danwei {
    padding-left: 0.3vw;
    width: 1vw;
  }

  .flexwz1 {
    margin-top: 1vh;
  }

  .bottonx {
    width: 0.9vw;
    height: 1.8vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 0.9vw;
    margin-right: 0.1vw;
    // color: #000000;
  }

  .ltyl {
    width: 12.3vw;
    height: 45vh;
    // border: 2px solid rgb(217, 243, 145);
    margin-left: 5.6vw;
    text-align: center;
  }

  .ltyl-col1 {
    width: 12.3vw;
    height: 2vh;
    line-height: 2vh;
    text-align: center;
    // border: 2px solid rgb(217, 243, 145);
  }

  .ltyl-col2 {
    width: 12.3vw;
    height: 2vh;
    line-height: 2vh;
    text-align: center;
    margin-top: 0.5vh;
    // border: 2px solid rgb(217, 243, 145);
  }

  .huomiao {
    width: 14.5vw;
    height: 26.2vh;
    line-height: 2vh;
    text-align: center;
    margin-top: 10.2vh;
    margin-left: -1vw;
    // border: 2px solid rgb(217, 243, 145);
    background-image: url("~@/assets/images/rql_hm.png");
    background-size: 100% 100%;
  }

  .dise {
    width: 4.8vw;
    height: 2.5vh;
    line-height: 2.5vh;
    background-color: #000000;
    opacity: 0.2;
    margin-left: 1vw;
    margin-top: 0.2vh;
    text-align: center;
  }

  .ltyl-col1-row1 {
    width: 5vw;
  }

  .leftfm {
    width: 9vw;
    height: 28vh;
    //  border: 2px solid rgb(217, 243, 145);
    margin-left: 2.4vw;
    margin-top: 10vh;
  }

  .rightfm {
    width: 9vw;
    height: 28vh;
    //  border: 2px solid rgb(217, 243, 145);
    margin-left: 6vw;
    margin-top: 10.3vh;
  }

  .leftfm-col1 {
    width: 9vw;
    margin-top: 0.5vh;
  }

  .rightfm-col1 {
    margin-left: 1.8vw;
    // margin-top: 0.5vh;
  }

  .rightfm-col2 {
    margin-top: 9.5vh;
    margin-left: 1.8vw;
  }

  .rightfm-col2x {
    margin-top: 0.5vh;
  }

  .leftfm-col2 {
    width: 9vw;
    margin-top: 9.6vh;
    margin-left: 4.4vw;
  }

  .leftfm-col3 {
    width: 9vw;
    margin-top: 18.7vh;
    margin-left: 4.4vw;
  }

  .rightfm-col3 {
    width: 9vw;
    margin-top: 18.5vh;
    margin-left: 1.8vw;
  }

  .row13-top {
    margin-left: 2vw;
    margin-top: 1vh;
    height: 6vh;
    //  border: #f14ef7 1px solid;
  }

  .row13-top-col1 {
    width: 6vw;
    height: 2vh;
    line-height: 2vh;
    text-align: center;
  }

  .row13-top-col2 {
    width: 8vw;
    height: 1.8vh;
    line-height: 1.8vh;
    text-align: center;
  }

  .row13-top-col1-jg {
    margin-top: 1.8vh;
    height: 2.5vh;
    line-height: 2.5vh;
  }

  .row13-center-colo1 {
    margin-top: 2.8vh;
    width: 28vw;
    // border: #57ff14 1px solid;
    height: 3vh;
    position: relative;
  }

  .click {
    width: 4vw;
    margin-left: 6vw;
    position: absolute;
    left: 4vw;
    height: 4vh;
  }

  .click1 {
    width: 2vw;
    margin-left: 6vw;
    position: absolute;
    left: 3.8vw;
    height: 6vh;
  }

  .click2 {
    width: 2vw;
    margin-left: -2vw;
    position: absolute;
    left: 3.8vw;
    height: 4vh;
  }

  .click3 {
    width: 2vw;
    margin-left: -2vw;
    position: absolute;
    left: 4vw;
    height: 4vh;
    top: 8vh;
  }

  .click4 {
    width: 2vw;
    margin-left: -2vw;
    position: absolute;
    left: 4vw;
    height: 4vh;
    top: 13vh;
  }

  .row13-center-colo2 {
    margin-top: 1vh;
    width: 28vw;
    //  padding-top:1vh;
    height: 3vh;
    position: relative;
    //  border: #14a4ff 1px solid;
  }

  .row13-center-colo3 {
    margin-top: 1vh;
    width: 30vw;
    //  padding-top:1vh;
    height: 3.8vh;
    position: relative;
    //  border: #14a4ff 1px solid;
  }

  .row13-center-colo4 {
    margin-top: 3vh;
    width: 30vw;
    //  padding-top:1vh;
    height: 3.8vh;
    position: relative;
    //  border: #14a4ff 1px solid;
  }

  .row13-center-colo5 {
    margin-top: 3.8vh;
    width: 30vw;
    //  padding-top:1vh;
    height: 15vh;
    position: relative;
    //  border: #14a4ff 1px solid;
  }

  .row13-center-colo5-row1 {
    margin-left: 4vw;
    margin-top: 4.6vh;
    width: 7vw;
  }

  .row13-center-colo5-row2 {
    // margin-left:1vw;
    margin-top: 1vh;
    width: 5vw;
  }

  .row13-center-colo5-row3 {
    margin-left: 1.3vw;
    margin-top: 1vh;
    width: 3vw;
  }

  .row13-center-colo5-row4 {
    margin-left: 0vw;
    // margin-top:4vh;
    width: 8vw;
    // border:#f14ef7 1px solid;
    position: relative;
  }

  .colo5-row2-jg2 {
    margin-top: 1vh;
    margin-left: 0.1vw;
  }

  .colo5-row5-jg {
    margin-top: 2vh;
    color: #5ca4a6;
  }

  .colo5-row5-jgx {
    margin-top: 1vh;
    color: #5ca4a6;
  }

  .colo5-row5-jg2 {
    margin-top: 4vh;
    color: #5ca4a6;
  }

  .colo5-row6-jg2 {
    margin-top: 9vh;
    margin-left: -0.5vw;
    color: #5ca4a6;
  }

  .colo5-row2-jg {
    margin-top: 7vh;
    margin-left: 2vw;
  }

  .colo5-row3-jg3 {
    margin-top: 2vh;
    width: 8vw;
    margin-left: 0vw;
  }

  .colo5-row3-jg34 {
    margin-top: 8vh;
    width: 6vw;
    margin-left: 5vw;
  }

  .colo5-row4 {
    margin-left: 4vw;
    margin-top: 1vh;
  }

  .colo5-row3-jg4 {
    // margin-top:7vh;
    // width:5vw;
    margin-left: 0.4vw;
  }

  .colo5-row3-jg2 {
    margin-top: 4vh;
    width: 3vw;
  }

  .colo6-row3-jg2 {
    margin-top: 9.5vh;
    width: 3vw;
  }

  .row13-center-colo3-row1 {
    width: 3.2vw;
    margin-top: 1.2vh;
    margin-left: 5.8vw;
    padding-left: 1vw;
  }

  .row13-center-colo3-row1-jg {
    margin-top: 1.1vh;
  }

  .row13-center-colo4-row1-jg {
    margin-top: 1.1vh;
    margin-left: 2vw;
  }

  .colo3-jg1 {
    margin-left: 1.8vw;
  }

  .colo3-jg2 {
    margin-left: 0.8vw;
  }

  .colo3-jg4 {
    width: 5vw;
    height: 2vh;
    line-height: 2vh;
    margin-left: 0.2vw;
  }

  .row13-center-colo1-row1 {
    margin-left: 1.8vw;
  }

  .row13-center-colo1-row2 {
    margin-left: 4.4vw;
    width: 3.8vw;
    text-align: right;
  }

  .right-buttonjg {
    margin-left: 0.8vw;
  }

  .right-buttonjg1 {
    margin-left: 1.9vw;
  }

  .down {
    color: #14a4ff;
  }

  .gslable {
    color: #5ca4a6;
    font-size: 0.9vw;
    margin-left: 1vw;
  }

  .gslable1 {
    color: #5ca4a6;
    font-size: 0.9vw;
    margin-left: 1.5vw;
    margin-top: 1.8vh;
  }

  .row13-jg {
    margin-top: 2vh;
  }

  .row13-center-colo6 {
    margin-top: 2.5vh;
    width: 30vw;
    //  padding-top:1vh;
    height: 20vh;
    //  border: #14a4ff 1px solid;
  }

  .colo6-row2-jg {
    margin-top: 12.5vh;
    margin-left: 2vw;
  }

  .colo6-row3-jg34 {
    margin-top: 13.5vh;
    width: 6vw;
    margin-left: 5vw;
  }

  .row13-center-colo6-row3 {
    margin-left: 1vw;
    margin-top: 12.5vh;
    width: 3vw;
    position: relative;
  }
}

::v-deep {
  .dv-water-pond-level text {
    font-size: 13px;
    font-weight: normal;
    dominant-baseline: hanging;
  }
}
</style>
