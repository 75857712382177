var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"param11 drag",attrs:{"id":"param1-4"}},[_c('div',{staticClass:"param11-header"},[_c('span',{staticClass:"param11-header-title"},[_vm._v(_vm._s(_vm.projectData.project)+"_参数"+_vm._s(_vm.datatype)+"1")]),_c('div',{staticClass:"param11-header-icon",on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"param11-tab"},[_c('div',{staticClass:"param11-tab-tit"},[_vm._v(_vm._s(_vm.pkey)+"#炉控制参数")]),_vm._m(0),_c('div',{staticClass:"param11-row-data"},[_c('div',{staticClass:"data-t flex"},[_c('div',{staticClass:"data-le"},[_c('div',{staticClass:"le1"},[_c('div',{staticClass:"le-tit"},[_vm._v("初期增加量")]),_c('div',{staticClass:"le-tit1",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['QHSJ_B' + _vm.pkey],
                    '初期增加量',
                    'QHSJ_B' + _vm.pkey,
                    _vm.infoList['QHSJ_B' + _vm.pkey + '_node_string']
                  )}}},[_vm._v(" "+_vm._s(("QHSJ_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["QHSJ_B" + _vm.pkey] : 0)+"℃ ")])]),_c('div',{staticClass:"le1",staticStyle:{"border":"none"}},[_c('div',{staticClass:"le-tit"},[_vm._v("调整时间")]),_c('div',{staticClass:"le-tit1",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['YWZJSJ_B' + _vm.pkey],
                    '调整时间',
                    'YWZJSJ_B' + _vm.pkey,
                    _vm.infoList['YWZJSJ_B' + _vm.pkey + '_node_string']
                  )}}},[_vm._v(" "+_vm._s(("YWZJSJ_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["YWZJSJ_B" + _vm.pkey] : 0)+"m ")])])]),_c('div',{staticClass:"data-le1"},[_c('div',{staticClass:"le-tit"},[_vm._v("预测烟温")]),_c('div',{staticClass:"le-tit1",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'YCFQWD_B' + _vm.pkey,
                  _vm.infoList['YCFQWD_B' + _vm.pkey + '_node_string'],
                  'YCFQWD_B' + _vm.pkey + '_WF',
                  '1#炉预测废气温度'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList['YCFQWD_B' + _vm.pkey],
                  'YCFQWD_B' + _vm.pkey,
                  _vm.infoList['YCFQWD_B' + _vm.pkey + '_node_string'],
                  'YCFQWD_B' + _vm.pkey + '_WF'
                )}}},[_vm._v(" "+_vm._s(("" + _vm.pkey) in _vm.infoList ? _vm.infoList["YCFQWD_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"data-le2"},[_c('div',{staticClass:"le-tit"},[_vm._v("煤气设定")]),_c('div',{staticClass:"le-tit1",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['FQSP_B' + _vm.pkey],
                  '煤气设定',
                  'FQSP_B' + _vm.pkey,
                  _vm.infoList['FQSP_B' + _vm.pkey + '_node_string']
                )}}},[_vm._v(" "+_vm._s(("FQSP_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["FQSP_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"data-le3"},[_c('div',{staticClass:"buttonx buttonx1",style:({
                background: !_vm.infoList['RSFB' + _vm.pkey + '__p__XK04'].TS
                  ? '#2AFC30'
                  : 'red',
              }),on:{"click":function($event){_vm.infoList['RSFB' + _vm.pkey + '__p__XK04']
                  ? _vm.toDetail(
                      1,
                      'RSFB' + _vm.pkey + '__p__XK04',
                      _vm.infoList['RSFB' + _vm.pkey + '__p__XK04' + '_node_string'],
                      '',
                      _vm.pkey + '#废气温度调节先控'
                    )
                  : ''}}},[_vm._v(" X ")])]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":String(_vm.infoList['MQSP_B' + _vm.pkey]),"placement":"top"}},[_c('div',{staticClass:"data-le4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'MQSP_B' + _vm.pkey,
                  _vm.infoList['MQSP_B' + _vm.pkey + '_node_string'],
                  'MQSP_B' + _vm.pkey + '_WF',
                  '1#煤气设定值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList['MQSP_B' + _vm.pkey],
                  'MQSP_B' + _vm.pkey,
                  _vm.infoList['MQSP_B' + _vm.pkey + '_node_string'],
                  'MQSP_B' + _vm.pkey + '_WF'
                )}}},[_vm._v(" "+_vm._s(("MQSP_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["MQSP_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"data-le",staticStyle:{"margin-left":"4vw","width":"4vw"}},[_c('div',{staticClass:"le1"},[_c('div',{staticClass:"le-tit"},[_vm._v("初始强度")]),_c('div',{staticClass:"le-tit1",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['TGY_B' + _vm.pkey],
                    '初始强度',
                    'TGY_B' + _vm.pkey,
                    _vm.infoList['TGY_B' + _vm.pkey + '_node_string']
                  )}}},[_vm._v(" "+_vm._s(("TGY_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["TGY_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"le1",staticStyle:{"border":"none"}},[_c('div',{staticClass:"le-tit"},[_vm._v("修正常数")]),_c('div',{staticClass:"le-tit1",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['JBKG02_B' + _vm.pkey],
                    '修正常数',
                    'JBKG02_B' + _vm.pkey,
                    _vm.infoList['JBKG02_B' + _vm.pkey + '_node_string']
                  )}}},[_vm._v(" "+_vm._s(("JBKG02_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["JBKG02_B" + _vm.pkey] : 0)+" ")])])]),_c('div',{staticClass:"data-le5"},[_c('div',{staticClass:"le-tit"},[_vm._v("烧炉强度")]),_c('div',{staticClass:"le-tit1",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['KQSP02_B' + _vm.pkey],
                  '烧炉强度',
                  'KQSP02_B' + _vm.pkey,
                  _vm.infoList['KQSP02_B' + _vm.pkey + '_node_string']
                )}}},[_vm._v(" "+_vm._s(("KQSP02_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["KQSP02_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"data-le6 ma-auto flex"},[_vm._m(1),_c('div',{staticClass:"list"},[_c('div',{staticClass:"le6-row1-list flex"},[_c('div',{staticClass:"tit"},[_vm._v("阀位")]),_vm._l((_vm.commonArray),function(item,index){return _c('el-tooltip',{key:index,staticClass:"item",attrs:{"effect":"dark","placement":"top","content":String(_vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC3'][
                      'X__ll__' + item + '__rr__'
                    ])}},[_c('div',{on:{"click":function($event){return _vm.toIpt(
                        _vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC3'][
                          'X__ll__' + item + '__rr__'
                        ],
                        '阀位',
                        'X__ll__' + item + '__rr__',
                        'RSFB' + _vm.pkey + '__p__HSCHARC3'
                      )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__HSCHARC3") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__HSCHARC3"][ "X__ll__" + item + "__rr__" ] : 0)+" ")])])})],2),_c('div',{staticClass:"le6-row1-list flex"},[_c('div',{staticClass:"tit"},[_vm._v("系数")]),_vm._l((_vm.commonArray),function(item,index){return _c('el-tooltip',{key:index,staticClass:"item",attrs:{"effect":"dark","placement":"top","content":String(_vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC3'][
                      'Y__ll__' + item + '__rr__'
                    ])}},[_c('div',{on:{"click":function($event){return _vm.toIpt(
                        _vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC3'][
                          'Y__ll__' + item + '__rr__'
                        ],
                        '系数',
                        'Y__ll__' + item + '__rr__',
                        'RSFB' + _vm.pkey + '__p__HSCHARC3'
                      )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__HSCHARC3") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__HSCHARC3"][ "Y__ll__" + item + "__rr__" ] : 0)+" ")])])})],2),_c('div',{staticClass:"le6-row1-list flex"},[_c('div',{staticClass:"tit"},[_vm._v("压力")]),_vm._l((_vm.commonArray),function(item,index){return _c('el-tooltip',{key:index,staticClass:"item",attrs:{"effect":"dark","placement":"top","content":String(_vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC4'][
                      'X__ll__' + item + '__rr__'
                    ])}},[_c('div',{on:{"click":function($event){return _vm.toIpt(
                        _vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC4'][
                          'X__ll__' + item + '__rr__'
                        ],
                        '压力',
                        'X__ll__' + item + '__rr__',
                        'RSFB' + _vm.pkey + '__p__HSCHARC4'
                      )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__HSCHARC4") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__HSCHARC4"][ "X__ll__" + item + "__rr__" ] : 0)+" ")])])})],2),_c('div',{staticClass:"le6-row1-list flex"},[_c('div',{staticClass:"tit"},[_vm._v("系数")]),_vm._l((_vm.commonArray),function(item,index){return _c('el-tooltip',{key:index,staticClass:"item",attrs:{"effect":"dark","placement":"top","content":String(_vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC4'][
                      'Y__ll__' + item + '__rr__'
                    ])}},[_c('div',{on:{"click":function($event){return _vm.toIpt(
                        _vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC4'][
                          'Y__ll__' + item + '__rr__'
                        ],
                        '系数',
                        'Y__ll__' + item + '__rr__',
                        'RSFB' + _vm.pkey + '__p__HSCHARC4'
                      )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__HSCHARC4") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__HSCHARC4"][ "Y__ll__" + item + "__rr__" ] : 0)+" ")])])})],2)])])],1),_c('div',{staticClass:"data-c flex align-cen"},[_c('div',{staticClass:"datac-le"},[_c('div',{staticClass:"datac-le-tit"},[_vm._v("协调减少量")]),_c('div',{staticClass:"datac-le-tit1",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'KQSP01_B' + _vm.pkey,
                  _vm.infoList['KQSP01_B' + _vm.pkey + '_node_string'],
                  'KQSP01_B' + _vm.pkey + '_WF',
                  '1#炉优先烧炉减少煤气量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList['KQSP01_B' + _vm.pkey],
                  'KQSP01_B' + _vm.pkey,
                  _vm.infoList['KQSP01_B' + _vm.pkey + '_node_string'],
                  'KQSP01_B' + _vm.pkey + '_WF'
                )}}},[_vm._v(" "+_vm._s(("KQSP01_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["KQSP01_B" + _vm.pkey] : 0)+" ")])]),_vm._m(2),_c('div',{staticClass:"datac-le"},[_c('div',{staticClass:"datac-le-tit"},[_vm._v("基本强度")]),_c('div',{staticClass:"datac-le-tit1",staticStyle:{"color":"#01ffba"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['JBMQL_B' + _vm.pkey],
                  '基本强度',
                  'JBMQL_B' + _vm.pkey,
                  _vm.infoList['JBMQL_B' + _vm.pkey + '_node_string']
                )}}},[_vm._v(" "+_vm._s(("JBMQL_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["JBMQL_B" + _vm.pkey] : 0)+" ")])]),_vm._m(3),_c('div',{staticClass:"datac-le"},[_c('div',{staticClass:"datac-le-tit"},[_vm._v("单烧增量")]),_c('div',{staticClass:"datac-le-tit1",staticStyle:{"color":"#01ffba"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['DSZL_B' + _vm.pkey],
                  '单烧增量',
                  'DSZL_B' + _vm.pkey,
                  _vm.infoList['DSZL_B' + _vm.pkey + '_node_string']
                )}}},[_vm._v(" "+_vm._s(("DSZL_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["DSZL_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"datac-le"},[_c('div',{staticClass:"datac-le-tit"},[_vm._v("保留系数")]),_c('div',{staticClass:"datac-le-tit1",staticStyle:{"color":"#01ffba"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['FSPM_B' + _vm.pkey],
                  '保留系数',
                  'FSPM_B2' + _vm.pkey,
                  _vm.infoList['FSPM_B2' + _vm.pkey + '_node_string']
                )}}},[_vm._v(" "+_vm._s(("FSPM_B2" + _vm.pkey) in _vm.infoList ? _vm.infoList["FSPM_B" + _vm.pkey] : 0)+" ")])])]),_c('div',{staticClass:"data-c-r"},[_c('div',{staticClass:"rt flex"},[_c('div',{staticClass:"rt-t"},[_c('div',{staticClass:"rt-le"},[_vm._v("空气流量设定值")]),_c('div',{staticClass:"rt-le1",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'KQSP_B' + _vm.pkey,
                    _vm.infoList['KQSP_B' + _vm.pkey + '_node_string'],
                    'KQSP_B' + _vm.pkey + '_WF',
                    '1#空气设定值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList['KQSP_B' + _vm.pkey],
                    'KQSP_B' + _vm.pkey,
                    _vm.infoList['KQSP_B' + _vm.pkey + '_node_string'],
                    'KQSP_B' + _vm.pkey + '_WF'
                  )}}},[_vm._v(" "+_vm._s(("KQSP_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["KQSP_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"rt-ma ma-auto"},[_c('div',{staticClass:"buttonx buttonx2",style:({
                  background: !_vm.infoList['RSFB' + _vm.pkey + '__p__XK02'].TS
                    ? '#2AFC30'
                    : 'red',
                }),on:{"click":function($event){_vm.infoList['RSFB' + _vm.pkey + '__p__XK02']
                    ? _vm.toDetail(
                        1,
                        'RSFB' + _vm.pkey + '__p__XK02',
                        _vm.infoList['RSFB' + _vm.pkey + '__p__XK02' + '_node_string'],
                        '',
                        _vm.pkey + '#空气流量先控'
                      )
                    : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"rt-ma1 ma-auto",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  'RSFB' + _vm.pkey + '__p__XK02',
                  _vm.commonParam1 + '_AV_WF',
                  '1#助燃风机压力先控总输出'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList['RSFB' + _vm.pkey + '__p__XK02'].AV,
                  'AV',
                  'RSFB' + _vm.pkey + '__p__XK02',
                  _vm.commonParam1 + '_AV_WF'
                )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__XK02") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__XK02"].AV : 0)+" ")])]),_c('div',{staticClass:"rb flex"},[_c('div',{staticClass:"data-le",staticStyle:{"margin-top":"1vh","width":"4vw","margin-left":"-1vw"}},[_c('div',{staticClass:"le1"},[_c('div',{staticClass:"le-tit"},[_vm._v("空气上限")]),_c('div',{staticClass:"le-tit1",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList['HLLIMH' + _vm.pkey + '2'],
                      '空气上限',
                      'HLLIMH' + _vm.pkey + '2',
                      _vm.infoList['' + _vm.pkey + '_node_string']
                    )}}},[_vm._v(" "+_vm._s(("HLLIMH" + _vm.pkey + "2") in _vm.infoList ? _vm.infoList["HLLIMH" + _vm.pkey + "2"] : 0)+" ")])]),_c('div',{staticClass:"le1",staticStyle:{"border":"none"}},[_c('div',{staticClass:"le-tit"},[_vm._v("空气下限")]),_c('div',{staticClass:"le-tit1",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList['HLLIML' + _vm.pkey + '2'],
                      '空气下限',
                      'HLLIML' + _vm.pkey + '2',
                      _vm.infoList['' + _vm.pkey + '_node_string']
                    )}}},[_vm._v(" "+_vm._s(("HLLIML" + _vm.pkey + "2") in _vm.infoList ? _vm.infoList["HLLIML" + _vm.pkey + "2"] : 0)+" ")])])]),_c('div',{staticClass:"rt-t",staticStyle:{"margin-top":"4vh","margin-left":"1vw","cursor":"pointer"}},[_c('div',{staticClass:"rt-le"},[_vm._v("空气流量")]),_c('div',{staticClass:"rt-le1",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'PV',
                    'RSFB' + _vm.pkey + '__p__XK02',
                    _vm.commonParam1 + '_PV_WF',
                    '1#助燃风机压力先控测量值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList['RSFB' + _vm.pkey + '__p__XK02'].PV,
                    'PV',
                    'RSFB' + _vm.pkey + '__p__XK02',
                    _vm.commonParam1 + '_PV_WF'
                  )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__XK02") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__XK02"].PV : 0)+" ")])]),_c('div',{staticClass:"rb-ma ma-auto"},[_c('div',{staticClass:"rb-ma-row flex"},[_c('div',{staticClass:"text"},[_vm._v("初始阀位增量")]),_c('div',{staticClass:"ipt textov",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList['CSKQFWZL_B' + _vm.pkey],
                      '初始阀位增量',
                      'CSKQFWZL_B' + _vm.pkey,
                      _vm.infoList['CSKQFWZL_B' + _vm.pkey + '_node_string']
                    )}}},[_vm._v(" "+_vm._s(("CSKQFWZL_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["CSKQFWZL_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"rb-ma-row flex"},[_c('div',{staticClass:"text"},[_vm._v("空压前馈系数")]),_c('div',{staticClass:"ipt textov",on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList['KM_KQ_B' + _vm.pkey],
                      '空压前馈系数',
                      'KM_KQ_B' + _vm.pkey,
                      _vm.infoList['KM_KQ_B' + _vm.pkey + '_node_string']
                    )}}},[_vm._v(" "+_vm._s(("KM_KQ_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["KM_KQ_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"rb-ma-row flex"},[_c('div',{staticClass:"text"},[_vm._v("前馈输出")]),_c('div',{staticClass:"ipt textov",staticStyle:{"color":"#8aeaff"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'FV_HH_B' + _vm.pkey,
                      _vm.infoList['FV_HH_B' + _vm.pkey + '_node_string'],
                      'FV_HH_B' + _vm.pkey + '_WF',
                      '1#炉空气阀位前馈量'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList['FV_HH_B' + _vm.pkey],
                      'FV_HH_B' + _vm.pkey,
                      _vm.infoList['FV_HH_B' + _vm.pkey + '_node_string'],
                      'FV_HH_B' + _vm.pkey + '_WF'
                    )}}},[_vm._v(" "+_vm._s(("FV_HH_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["FV_HH_B" + _vm.pkey] : 0)+" ")])])])])]),_c('div',{staticClass:"data-b"},[_c('div',{staticClass:"rt-t",staticStyle:{"margin-left":"22.5vw","margin-top":"-2vh","width":"4vw","line-height":"2.5vh"}},[_c('div',{staticClass:"rt-le textov"},[_vm._v("优化风煤比")]),_c('div',{staticClass:"rt-le1",staticStyle:{"text-align":"center","cursor":"pointer","color":"#01ffba"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList['YHFMB_B' + _vm.pkey],
                  '优化风煤比',
                  'YHFMB_B' + _vm.pkey,
                  _vm.infoList['YHFMB_B' + _vm.pkey + '_node_string']
                )}}},[_vm._v(" "+_vm._s(("YHFMB_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["YHFMB_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"data-b-c flex"},[_c('div',{staticClass:"rt-t"},[_c('div',{staticClass:"rt-le"},[_vm._v("含氧量设定")]),_c('div',{staticClass:"rt-le1",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['ZJZQ_B' + _vm.pkey],
                    '含氧量设定',
                    'ZJZQ_B' + _vm.pkey,
                    _vm.infoList['ZJZQ_B' + _vm.pkey + '_node_string']
                  )}}},[_vm._v(" "+_vm._s(("ZJZQ_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["ZJZQ_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"buttonx buttonx3",style:({
                background: !_vm.infoList['RSFB' + _vm.pkey + '__p__XK05'].TS
                  ? '#2AFC30'
                  : 'red',
              }),on:{"click":function($event){_vm.infoList['RSFB' + _vm.pkey + '__p__XK05']
                  ? _vm.toDetail(
                      1,
                      'RSFB' + _vm.pkey + '__p__XK05',
                      _vm.infoList['RSFB' + _vm.pkey + '__p__XK05' + '_node_string'],
                      '',
                      _vm.pkey + '#拱顶温度调节空燃比先控'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"rt-t1"},[_c('div',{staticClass:"rt-le"},[_vm._v("顶温下降调配比系数")]),_c('div',{staticClass:"rt-le1",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['GDBL_B' + _vm.pkey],
                    '顶温下降调配比系数',
                    'GDBL_B' + _vm.pkey,
                    _vm.infoList['GDBL_B' + _vm.pkey + '_node_string']
                  )}}},[_vm._v(" "+_vm._s(("GDBL_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["GDBL_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"rt-t1",staticStyle:{"margin-left":"1.5vw"}},[_c('div',{staticClass:"rt-le"},[_vm._v("干预风煤比")]),_c('div',{staticClass:"rt-le1",staticStyle:{"color":"#2fc3e3"},on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    'RSFB' + _vm.pkey + '__p__XK05',
                    _vm.commonParam2 + '_AV_WF'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList['RSFB' + _vm.pkey + '__p__XK05'].AV,
                    'AV',
                    'RSFB' + _vm.pkey + '__p__XK05',
                    _vm.commonParam2 + '_AV_WF'
                  )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__XK05") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__XK05"].AV == 0 ? "0.00" : _vm.infoList["RSFB" + _vm.pkey + "__p__XK05"].AV : 0)+" ")])])])]),_c('div',{staticClass:"data-b1"},[_c('div',{staticClass:"rt-t"},[_c('div',{staticClass:"rt-le"},[_vm._v("废气含氧量")]),_c('div',{staticClass:"rt-le1",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'TE_14_B' + _vm.pkey,
                  _vm.infoList['TE_14_B' + _vm.pkey + '_node_string'],
                  'TE_14_B' + _vm.pkey + '_WF',
                  '1#热风炉废气含氧量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList['TE_14_B' + _vm.pkey],
                  'TE_14_B' + _vm.pkey,
                  _vm.infoList['TE_14_B' + _vm.pkey + '_node_string'],
                  'TE_14_B' + _vm.pkey + '_WF'
                )}}},[_vm._v(" "+_vm._s(("TE_14_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["TE_14_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"buttonx buttonr",style:({
              background: !_vm.infoList['RSFB' + _vm.pkey + '__p__RSF1'].TS
                ? '#2AFC30'
                : 'red',
            }),on:{"click":function($event){_vm.infoList['RSFB' + _vm.pkey + '__p__RSF1']
                ? _vm.toDetail(2,
				'RSFB' + _vm.pkey + '__p__RSF1',
				_vm.infoList['RSFB' + _vm.pkey + '__p__RSF1' + '_node_string'],
                  '',
                  _vm.pkey + '#废气含氧量高调节')
                : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"buttonx buttonr1",style:({
              background: !_vm.infoList['RSFB' + _vm.pkey + '__p__RSF2'].TS
                ? '#2AFC30'
                : 'red',
            }),on:{"click":function($event){_vm.infoList['RSFB' + _vm.pkey + '__p__RSF2']
                ? _vm.toDetail(
                    2,
                    'RSFB' + _vm.pkey + '__p__RSF2',
                    _vm.infoList['RSFB' + _vm.pkey + '__p__RSF2' + '_node_string'],
                    '',
                    _vm.pkey + '#废气含氧量低调节'
                  )
                : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"data-b1-num",on:{"click":function($event){return _vm.toCompon(
                2,
                'AV',
                'RSFB' + _vm.pkey + '__p__RSF1',
                _vm.commonParam3 + '_AV_WF'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['RSFB' + _vm.pkey + '__p__RSF1'].AV,
                'AV',
                'RSFB' + _vm.pkey + '__p__RSF1',
                _vm.commonParam3 + '_AV_WF'
              )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__RSF1") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__RSF1"].AV : 0)+" ")]),_c('div',{staticClass:"data-b1-num1",on:{"click":function($event){return _vm.toCompon(
                2,
                'AV',
                'RSFB' + _vm.pkey + '__p__RSF2',
                _vm.commonParam4 + '_AV_WF'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['RSFB' + _vm.pkey + '__p__RSF2'].AV,
                'AV',
                'RSFB' + _vm.pkey + '__p__RSF2',
                _vm.commonParam4 + '_AV_WF'
              )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__RSF2") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__RSF2"].AV : 0)+" ")]),_c('div',{staticClass:"data-b1-btn",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList['SPQHSJ_B' + _vm.pkey],
                '按钮',
                'SPQHSJ_B' + _vm.pkey,
                _vm.infoList['SPQHSJ_B' + _vm.pkey + '_node_string']
              )}}},[_vm._v(" "+_vm._s(("SPQHSJ_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["SPQHSJ_B" + _vm.pkey] ? "启动" : "屏蔽" : 0)+" ")]),_c('div',{staticClass:"data-b1-num2",on:{"click":function($event){return _vm.toCompon(
                2,
                'MQSP_B' + _vm.pkey,
                _vm.infoList['MQSP_B' + _vm.pkey + '_node_string'],
                'MQSP_B' + _vm.pkey + '_WF',
                '2#煤气设定值'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.iinfoList['MQSP_B' + _vm.pkey],
                'MQSP_B' + _vm.pkey,
                _vm.infoList['MQSP_B' + _vm.pkey + '_node_string'],
                'MQSP_B' + _vm.pkey + '_WF'
              )}}},[_vm._v(" "+_vm._s(("MQSP_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["MQSP_B" + _vm.pkey] : 0)+" ")]),_c('div',{staticClass:"buttonx buttonx4",style:({
              background: !_vm.infoList['RSFB' + _vm.pkey + '__p__XK01'].TS
                ? '#2AFC30'
                : 'red',
            }),on:{"click":function($event){_vm.infoList['RSFB' + _vm.pkey + '__p__XK01']
                ? _vm.toDetail(
                    1,
                    'RSFB' + _vm.pkey + '__p__XK01',
                    _vm.infoList['RSFB' + _vm.pkey + '__p__XK01' + '_node_string'],
                    '',
                    _vm.pkey + '#煤气流量先控'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"data-b1-num3",on:{"click":function($event){return _vm.toCompon(
                2,
                'AV',
                'RSFB' + _vm.pkey + '__p__XK01',
                _vm.commonParam5 + '_AV_WF',
                '煤气总管压力先控总输出'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['RSFB' + _vm.pkey + '__p__XK01'].AV,
                'AV',
                'RSFB' + _vm.pkey + '__p__XK01',
                _vm.commonParam5 + '_AV_WF'
              )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__XK01") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__XK01"].AV == 0 ? "0.00" : _vm.infoList["RSFB" + _vm.pkey + "__p__XK01"].AV : 0)+" ")]),_c('div',{staticClass:"otherhis",on:{"click":function($event){return _vm.toCompon(
                2,
                'LINGPAI',
                'RSFB' + _vm.pkey,
                'LINGPAI' + _vm.pkey + '_WF',
                '令牌'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList['RSFB' + _vm.pkey].LINGPAI,
                'LINGPAI',
                'RSFB' + _vm.pkey,
                'LINGPAI' + _vm.pkey + '_WF'
              )}}},[_vm._v(" 其他 ")]),_c('div',{staticClass:"data-le data-b1-le"},[_c('div',{staticClass:"le1"},[_c('div',{staticClass:"le-tit"},[_vm._v("煤气上限")]),_c('div',{staticClass:"le-tit1",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['HLLIMH' + _vm.pkey + '1'],
                    '煤气上限',
                    'HLLIMH' + _vm.pkey + '1',
                    _vm.infoList['HLLIMH' + _vm.pkey + '1' + '_node_string']
                  )}}},[_vm._v(" "+_vm._s(("HLLIML" + _vm.pkey + "1") in _vm.infoList ? _vm.infoList["HLLIMH" + _vm.pkey + "1"] : 0)+" ")])]),_c('div',{staticClass:"le1",staticStyle:{"border":"none"}},[_c('div',{staticClass:"le-tit"},[_vm._v("煤气下限")]),_c('div',{staticClass:"le-tit1",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['HLLIML' + _vm.pkey + '1'],
                    '煤气下限',
                    'HLLIML' + _vm.pkey + '',
                    _vm.infoList['' + _vm.pkey + '_node_string']
                  )}}},[_vm._v(" "+_vm._s(("HLLIML" + _vm.pkey + "1") in _vm.infoList ? _vm.infoList["HLLIML" + _vm.pkey + "1"] : 0)+" ")])])]),_c('div',{staticClass:"rt-t1"},[_c('div',{staticClass:"rt-le"},[_vm._v("煤气流量")]),_c('div',{staticClass:"rt-le1",staticStyle:{"color":"#2fc3e3"},on:{"click":function($event){return _vm.toCompon(
                  2,
                  'PV',
                  'RSFB' + _vm.pkey + '__p__XK01',
                  _vm.commonParam5 + '_PV_WF',
                  '煤气总管压力先控测量值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList['RSFB' + _vm.pkey + '__p__XK01'].PV,
                  'PV',
                  'RSFB' + _vm.pkey + '__p__XK01',
                  _vm.commonParam5 + '_PV_WF'
                )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__XK01") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__XK01"].PV : 0)+" ")]),_c('div',{staticClass:"startbutton2",staticStyle:{"position":"relative"}},[_vm._v(" "+_vm._s(("SEL" + _vm.pkey + "06") in _vm.infoList ? _vm.infoList["SEL" + _vm.pkey + "06"] ? "补偿启停" : "停止补偿" : 0)+" ")])]),_c('div',{staticClass:"rb-ma"},[_c('div',{staticClass:"rb-ma-row flex"},[_c('div',{staticClass:"text"},[_vm._v("初始阀位增量")]),_c('div',{staticClass:"ipt textov",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['CSMQFWZL_B' + _vm.pkey],
                    '初始阀位增量',
                    'CSMQFWZL_B' + _vm.pkey,
                    _vm.infoList['' + _vm.pkey + '_node_string']
                  )}}},[_vm._v(" "+_vm._s(("CSMQFWZL_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["CSMQFWZL_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"rb-ma-row flex"},[_c('div',{staticClass:"text"},[_vm._v("煤压前馈系数")]),_c('div',{staticClass:"ipt textov",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList['ZJSJ_B' + _vm.pkey],
                    '煤压前馈系数',
                    'ZJSJ_B' + _vm.pkey,
                    _vm.infoList['ZJSJ_B' + _vm.pkey + '_node_string']
                  )}}},[_vm._v(" "+_vm._s(("ZJSJ_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["ZJSJ_B" + _vm.pkey] : 0)+" ")])]),_c('div',{staticClass:"rb-ma-row flex"},[_c('div',{staticClass:"text"},[_vm._v("前馈输出")]),_c('div',{staticClass:"ipt textov",staticStyle:{"color":"#8aeaff"},on:{"click":function($event){return _vm.toCompon(
                    2,
                    'ZJC_B' + _vm.pkey,
                    _vm.infoList['ZJC_B' + _vm.pkey + '_node_string'],
                    'ZJC_B' + _vm.pkey + '_WF',
                    '专家常数'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList['ZJC_B' + _vm.pkey],
                    'ZJC_B' + _vm.pkey,
                    _vm.infoList['' + _vm.pkey + '_node_string'],
                    'ZJC_B' + _vm.pkey + '_WF'
                  )}}},[_vm._v(" "+_vm._s(("ZJC_B" + _vm.pkey) in _vm.infoList ? _vm.infoList["ZJC_B" + _vm.pkey] : 0)+" ")])])])])]),_c('div',{staticClass:"param11-row-bottom"},[_c('div',{staticClass:"data-le6 flex"},[_vm._m(4),_c('div',{staticClass:"list"},[_c('div',{staticClass:"le6-row1-list flex"},[_c('div',{staticClass:"tit"},[_vm._v("阀位")]),_vm._l((_vm.commonArray),function(item,index){return _c('el-tooltip',{key:index,staticClass:"item",attrs:{"effect":"dark","placement":"top","content":String(_vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC1'][
                    'X__ll__' + item + '__rr__'
                  ])}},[_c('div',{on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC1'][
                        'X__ll__' + item + '__rr__'
                      ],
                      '阀位',
                      'X__ll__' + item + '__rr__',
                      'RSFB' + _vm.pkey + '__p__HSCHARC1'
                    )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__HSCHARC1") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__HSCHARC1"][ "X__ll__" + item + "__rr__" ] : 0)+" ")])])})],2),_c('div',{staticClass:"le6-row1-list flex"},[_c('div',{staticClass:"tit"},[_vm._v("系数")]),_vm._l((_vm.commonArray),function(item,index){return _c('el-tooltip',{key:index,staticClass:"item",attrs:{"effect":"dark","placement":"top","content":String(_vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC1'][
                    'Y__ll__' + item + '__rr__'
                  ])}},[_c('div',{on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC1'][
                        'Y__ll__' + item + '__rr__'
                      ],
                      '系数',
                      'Y__ll__' + item + '__rr__',
                      'RSFB' + _vm.pkey + '__p__HSCHARC1'
                    )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__HSCHARC1") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__HSCHARC1"][ "Y__ll__" + item + "__rr__" ] : 0)+" ")])])})],2),_c('div',{staticClass:"le6-row1-list flex"},[_c('div',{staticClass:"tit"},[_vm._v("压力")]),_vm._l((_vm.commonArray),function(item,index){return _c('el-tooltip',{key:index,staticClass:"item",attrs:{"effect":"dark","placement":"top","content":String(_vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC2'][
                    'X__ll__' + item + '__rr__'
                  ])}},[_c('div',{on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC2'][
                        'X__ll__' + item + '__rr__'
                      ],
                      '压力',
                      'X__ll__' + item + '__rr__',
                      'RSFB' + _vm.pkey + '__p__HSCHARC2'
                    )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__HSCHARC2") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__HSCHARC2"][ "X__ll__" + item + "__rr__" ] : 0)+" ")])])})],2),_c('div',{staticClass:"le6-row1-list flex"},[_c('div',{staticClass:"tit"},[_vm._v("系数")]),_vm._l((_vm.commonArray),function(item,index){return _c('el-tooltip',{key:index,staticClass:"item",attrs:{"effect":"dark","placement":"top","content":String(_vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC2'][
                    'Y__ll__' + item + '__rr__'
                  ])}},[_c('div',{on:{"click":function($event){return _vm.toIpt(
                      _vm.infoList['RSFB' + _vm.pkey + '__p__HSCHARC2'][
                        'Y__ll__' + item + '__rr__'
                      ],
                      '系数',
                      'Y__ll__' + item + '__rr__',
                      'RSFB' + _vm.pkey + '__p__HSCHARC2'
                    )}}},[_vm._v(" "+_vm._s(("RSFB" + _vm.pkey + "__p__HSCHARC2") in _vm.infoList ? _vm.infoList["RSFB" + _vm.pkey + "__p__HSCHARC2"][ "Y__ll__" + item + "__rr__" ] : 0)+" ")])])})],2)])]),_c('div',{staticClass:"bottom-yh",on:{"click":function($event){_vm.isComShow = true}}},[_vm._v("优化参数")])])]),_c('inputVal',{ref:"inputVal",on:{"getTreeData":function($event){return _vm.DataJson(arguments)}}}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"chName":_vm.chName,"Lkname":_vm.Lkname,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.isFshow)?_c('Firstcontrol',{attrs:{"infoList":_vm.infoList,"isIndex":_vm.isIndex,"historyname":_vm.Firstcontrolname,"titname":_vm.Firstcontroltitname,"node":_vm.Firstcontrolnode},on:{"sendStatus":function($event){_vm.isFshow = false}}}):_vm._e(),(_vm.isRshow)?_c('Rsf',{attrs:{"titname":_vm.Rsftitname,"infoList":_vm.infoList,"historyname":_vm.Rsfname,"node":_vm.Rsfnode},on:{"sendStatus":function($event){_vm.isRshow = false}}}):_vm._e(),(_vm.isComShow)?_c('ParameterYh',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isshowfase}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"param11-row-bg"},[_c('img',{attrs:{"src":require("@/assets/images/rfl1-4.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"le6-row1-le"},[_vm._v("空"),_c('br'),_vm._v("气")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datac-le-img"},[_c('img',{attrs:{"src":require("@/assets/images/rfl-add.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datac-le-img"},[_c('img',{attrs:{"src":require("@/assets/images/rfl-add.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"le6-row1-le"},[_vm._v("煤"),_c('br'),_vm._v("气")])}]

export { render, staticRenderFns }