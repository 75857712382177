<template>
	<div class="all">
		<!-- 抬头标题 -->
		<div class="title-hang">
			<!-- <div class="zhuangshizb fl-t"></div> -->
			<div class="zhuangshizj fl-t">{{projectData.project}}_运行考核</div>
			<!-- <div class="zhuangshiyb fl-t"></div> -->
		</div>
		<!-- 主要内容 -->
		<div class="flex">
			<div class="left">
				<!-- 标题 -->
				<div class="title">
					<div class="left_title">数据显示</div>
				</div>
				<!-- 内容 -->
				<div class="left_content">
					<div class="flex">
						<div class="left_line0">序号</div>
						<div class="left_line2">班运行</div>
						<div class="left_line3">天运行</div>
						<div class="left_line4">月运行</div>
						<div class="left_line5">自定义运行</div>
					</div>
					<div class="flex">
						<div class="left_line1">给水累计/t</div>
						<div class="left_line2" @click="toCompon(2,'KH_GSLJB',infoList.KH_GSLJB_node_string,'KH_GSLJB_NG3','给水班累计')"
							@dblclick="Cclick(infoList.KH_GSLJB,'KH_GSLJB',infoList.KH_GSLJB_node_string,'KH_GSLJB_NG3')">
							{{'KH_GSLJB' in infoList? infoList.KH_GSLJB:0}}
						</div>
						<div class="left_line3" @click="toCompon(2,'KH_GSLJT',infoList.KH_GSLJT_node_string,'KH_GSLJT_NG3','给水天累计')"
							@dblclick="Cclick(infoList.KH_GSLJT,'KH_GSLJT',infoList.KH_GSLJT_node_string,'KH_GSLJT_NG3')">
							{{'KH_GSLJT' in infoList? infoList.KH_GSLJT:0}}
						</div>
						<div class="left_line4" @click="toCompon(2,'KH_GSLJY',infoList.KH_GSLJY_node_string,'KH_GSLJY_NG3','给水月累计')"
							@dblclick="Cclick(infoList.KH_GSLJY,'KH_GSLJY',infoList.KH_GSLJY_node_string,'KH_GSLJY_NG3')">
							{{'KH_GSLJY' in infoList? infoList.KH_GSLJY:0}}
						</div>
						<div class="left_line5" @click="toCompon(2,'KH_GSLJS',infoList.KH_GSLJS_node_string,'KH_GSLJS_NG3','给水自定义考核累计')"
							@dblclick="Cclick(infoList.KH_GSLJS,'KH_GSLJS',infoList.KH_GSLJS_node_string,'KH_GSLJS_NG3')">
							{{'KH_GSLJS' in infoList? infoList.KH_GSLJS:0}}
						</div>
					</div>
					<div class="flex">
						<div class="left_line1">蒸汽累计/t</div>
						<div class="left_line2" @click="toCompon(2,'KH_ZQLJB',infoList.KH_ZQLJB_node_string,'KH_ZQLJB_NG3','蒸汽班累计')"
							@dblclick="Cclick(infoList.KH_ZQLJB,'KH_ZQLJB',infoList.KH_ZQLJB_node_string,'KH_ZQLJB_NG3')">
							{{'KH_ZQLJB' in infoList? infoList.KH_ZQLJB:0}}
						</div>
						<div class="left_line3" @click="toCompon(2,'KH_ZQLJT',infoList.KH_ZQLJT_node_string,'KH_ZQLJT_NG3','蒸汽天累计')"
							@dblclick="Cclick(infoList.KH_ZQLJT,'KH_ZQLJT',infoList.KH_ZQLJT_node_string,'KH_ZQLJT_NG3')">
							{{'KH_ZQLJT' in infoList? infoList.KH_ZQLJT:0}}
						</div>
						<div class="left_line4"
							@click="toCompon(2,'KH_ZQLJY',infoList.KH_ZQLJY_node_string,'KH_ZQLJY','KH_ZQLJY_NG3','蒸汽月累计')"
							@dblclick="Cclick(infoList.KH_ZQLJY,'KH_ZQLJY',infoList.KH_ZQLJY_node_string,'KH_ZQLJY_NG3')">
							{{'KH_ZQLJY' in infoList? infoList.KH_ZQLJY:0}}
						</div>
						<div class="left_line5" @click="toCompon(2,'KH_ZQLJS',infoList.KH_ZQLJS_node_string,'KH_ZQLJS_NG3','蒸汽自定义考核累计')"
							@dblclick="Cclick(infoList.KH_ZQLJS,'KH_ZQLJS',infoList.KH_ZQLJS_node_string,'KH_ZQLJS_NG3')">
							{{'KH_ZQLJS' in infoList? infoList.KH_ZQLJS:0}}
						</div>
					</div>
					<div class="flex">
						<div class="left_line1">高煤/万m3</div>
						<div class="left_line2" @click="toCompon(2,'KH_GLLJB',infoList.KH_GLLJB_node_string,'KH_GLLJB_NG3','高炉煤气班累计')"
							@dblclick="Cclick(infoList.KH_GLLJB,'KH_GLLJB',infoList.KH_GLLJB_node_string,'KH_GLLJB_NG3')">
							{{'KH_GLLJB' in infoList? infoList.KH_GLLJB:0}}
						</div>
						<div class="left_line3" @click="toCompon(2,'KH_GLLJT',infoList.KH_GLLJT_node_string,'KH_GLLJT_NG3','高炉煤气天累计')"
							@dblclick="Cclick(infoList.KH_GLLJT,'KH_GLLJT',infoList.KH_GLLJT_node_string,'KH_GLLJT_NG3')">
							{{'KH_GLLJT' in infoList? infoList.KH_GLLJT:0}}
						</div>
						<div class="left_line4" @click="toCompon(2,'KH_GLLJY',infoList.KH_GLLJY_node_string,'KH_GLLJY_NG3','高炉煤气月累计')"
							@dblclick="Cclick(infoList.KH_GLLJY,'KH_GLLJY',infoList.KH_GLLJY_node_string,'KH_GLLJY_NG3')">
							{{'KH_GLLJY' in infoList? infoList.KH_GLLJY:0}}
						</div>
						<div class="left_line5" @click="toCompon(2,'KH_GLLJS',infoList.KH_GLLJS_node_string,'KH_GLLJS_NG3','高炉煤气自定义考核累计')"
							@dblclick="Cclick(infoList.KH_GLLJS,'KH_GLLJS',infoList.KH_GLLJS_node_string,'KH_GLLJS_NG3')">
							{{'KH_GLLJS' in infoList? infoList.KH_GLLJS:0}}
						</div>
					</div>
					<div class="flex">
						<div class="left_line1">转煤/万m3</div>
						<div class="left_line2" @click="toCompon(2,'KH_ZLLJB',infoList.KH_ZLLJB_node_string,'KH_ZLLJB_NG3','转炉煤气班累计')"
							@dblclick="Cclick(infoList.KH_ZLLJB,'KH_ZLLJB',infoList.KH_ZLLJB_node_string,'KH_ZLLJB_NG3')">
							{{'KH_ZLLJB' in infoList? infoList.KH_ZLLJB:0}}
						</div>
						<div class="left_line3" @click="toCompon(2,'KH_ZLLJT',infoList.KH_ZLLJT_node_string,'KH_ZLLJT_NG3','转炉煤气天累计')"
							@dblclick="Cclick(infoList.KH_ZLLJT,'KH_ZLLJT',infoList.KH_ZLLJT_node_string,'KH_ZLLJT_NG3')">
							{{'KH_ZLLJT' in infoList? infoList.KH_ZLLJT:0}}
						</div>
						<div class="left_line4" @click="toCompon(2,'KH_ZLLJY',infoList.KH_ZLLJY_node_string,'KH_ZLLJY_NG3','转炉煤气月累计')"
							@dblclick="Cclick(infoList.KH_ZLLJY,'KH_ZLLJY',infoList.KH_ZLLJY_node_string,'KH_ZLLJY_NG3')">
							{{'KH_ZLLJY' in infoList? infoList.KH_ZLLJY:0}}
						</div>
						<div class="left_line5" @click="toCompon(2,'KH_ZLLJS',infoList.KH_ZLLJS_node_string,'KH_ZLLJS_NG3','转炉煤气自定义考核累计')"
							@dblclick="Cclick(infoList.KH_ZLLJS,'KH_ZLLJS',infoList.KH_ZLLJS_node_string,'KH_ZLLJS_NG3')">
							{{'KH_ZLLJS' in infoList? infoList.KH_ZLLJS:0}}
						</div>
					</div>
					<div class="flex">
						<div class="left_line1">焦煤/万m3</div>
						<div class="left_line2" @click="toCompon(2,'KH_JLLJB',infoList.KH_JLLJB_node_string,'KH_JLLJB_NG3','焦炉煤气班累计')"
							@dblclick="Cclick(infoList.KH_JLLJB,'KH_JLLJB',infoList.KH_JLLJB_node_string,'KH_JLLJB_NG3')">
							{{'KH_JLLJB' in infoList? infoList.KH_JLLJB:0}}
						</div>
						<div class="left_line3" @click="toCompon(2,'KH_JLLJT',infoList.KH_JLLJT_node_string,'KH_JLLJT_NG3','焦炉煤气天累计')"
							@dblclick="Cclick(infoList.KH_JLLJT,'KH_JLLJT',infoList.KH_JLLJT_node_string,'KH_JLLJT_NG3')">
							{{'KH_JLLJT' in infoList? infoList.KH_JLLJT:0}}
						</div>
						<div class="left_line4" @click="toCompon(2,'KH_JLLJY',infoList.KH_JLLJY_node_string,'KH_JLLJY_NG3','焦炉煤气月累计')"
							@dblclick="Cclick(infoList.KH_JLLJY,'KH_JLLJY',infoList.KH_JLLJY_node_string,'KH_JLLJY_NG3')">
							{{'KH_JLLJY' in infoList? infoList.KH_JLLJY:0}}
						</div>
						<div class="left_line5" @click="toCompon(2,'KH_JLLJS',infoList.KH_JLLJS_node_string,'KH_JLLJS_NG3','焦炉煤气自定义考核累计')"
							@dblclick="Cclick(infoList.KH_JLLJS,'KH_JLLJS',infoList.KH_JLLJS_node_string,'KH_JLLJS_NG3')">
							{{'KH_JLLJS' in infoList? infoList.KH_JLLJS:0}}
						</div>
					</div>
					<div class="flex">
						<div class="left_line1">主汽温度</div>
						<div class="left_line2" @click="toCompon(2,'KH_QWB',infoList.KH_QWB_node_string,'KH_QWB_NG3','主汽温度班累计')"
							@dblclick="Cclick(infoList.KH_QWB,'KH_QWB',infoList.KH_QWB_node_string,'KH_QWB_NG3')">
							{{'KH_QWB' in infoList? infoList.KH_QWB:0}}
						</div>
						<div class="left_line3" @click="toCompon(2,'KH_QWT',infoList.KH_QWT_node_string,'KH_QWT_NG3','主汽温度天累计')"
							@dblclick="Cclick(infoList.KH_QWT,'KH_QWT',infoList.KH_QWT_node_string,'KH_QWT_NG3')">
							{{'KH_QWT' in infoList? infoList.KH_QWT:0}}
						</div>
						<div class="left_line4" @click="toCompon(2,'KH_QWT',infoList.KH_QWT_node_string,'KH_QWY_NG3','主汽温度月累计')"
							@dblclick="Cclick(infoList.KH_QWT,'KH_QWT',infoList.KH_QWT_node_string,'KH_QWY_NG3')">
							{{'KH_QWT' in infoList? infoList.KH_QWT:0}}
						</div>
						<div class="left_line5" @click="toCompon(2,'KH_QWS',infoList.KH_QWS_node_string,'KH_QWS_NG3','自主汽温度定义考核累计')"
							@dblclick="Cclick(infoList.KH_QWS,'KH_QWS',infoList.KH_QWS_node_string,'KH_QWS_NG3')">
							{{'KH_QWS' in infoList? infoList.KH_QWS:0}}
						</div>
					</div>
					<div class="flex">
						<div class="left_line1">主汽压力</div>
						<div class="left_line2" @click="toCompon(2,'KH_QYB',infoList.KH_QYB_node_string,'KH_QYB_NG3','主汽压力班累计')"
							@dblclick="Cclick(infoList.KH_QYB,'KH_QYB',infoList.KH_QYB_node_string,'KH_QYB_NG3')">
							{{'KH_QYB' in infoList? infoList.KH_QYB:0}}
						</div>
						<div class="left_line3" @click="toCompon(2,'KH_QYT',infoList.KH_QYT_node_string,'KH_QYT_NG3','主汽压力天累计')"
							@dblclick="Cclick(infoList.KH_QYT,'KH_QYT',infoList.KH_QYT_node_string,'KH_QYT_NG3')">
							{{'KH_QYT' in infoList? infoList.KH_QYT:0}}
						</div>
						<div class="left_line4" @click="toCompon(2,'KH_QYY',infoList.KH_QYY_node_string,'KH_QYY_NG3','主汽压力月累计')"
							@dblclick="Cclick(infoList.KH_QYY,'KH_QYY',infoList.KH_QYY_node_string,'KH_QYY_NG3')">
							{{'KH_QYY' in infoList? infoList.KH_QYY:0}}
						</div>
						<div class="left_line5" @click="toCompon(2,'KH_QYS',infoList.KH_QYS_node_string,'KH_QYS_NG3','主汽压力定义考核累计')"
							@dblclick="Cclick(infoList.KH_QYS,'KH_QYS',infoList.KH_QYS_node_string,'KH_QYS_NG3')">
							{{'KH_QYS' in infoList? infoList.KH_QYS:0}}
						</div>
					</div>
					<div class="flex">
						<div class="left_line1">炉膛压力</div>
						<div class="left_line2" @click="toCompon(2,'KH_FYB',infoList.KH_FYB_node_string,'KH_FYB_NG3','炉膛压力班累计')"
							@dblclick="Cclick(infoList.KH_FYB,'KH_FYB',infoList.KH_FYB_node_string,'KH_FYB_NG3')">
							{{'KH_FYB' in infoList? infoList.KH_FYB:0}}
						</div>
						<div class="left_line3" @click="toCompon(2,'KH_FYT',infoList.KH_FYT_node_string,'KH_FYT_NG3','炉膛压力天累计')"
							@dblclick="Cclick(infoList.KH_FYT,'KH_FYT',infoList.KH_FYT_node_string,'KH_FYT_NG3')">
							{{'KH_FYT' in infoList? infoList.KH_FYT:0}}
						</div>
						<div class="left_line4" @click="toCompon(2,'KH_FYY',infoList.KH_FYY_node_string,'KH_FYY_NG3','炉膛压力月累计')"
							@dblclick="Cclick(infoList.KH_FYY,'KH_FYY',infoList.KH_FYY_node_string,'KH_FYY_NG3')">
							{{'KH_FYY' in infoList? infoList.KH_FYY:0}}
						</div>
						<div class="left_line5" @click="toCompon(2,'KH_FYS',infoList.KH_FYS_node_string,'KH_FYS_NG3','炉膛压力定义考核累计')"
							@dblclick="Cclick(infoList.KH_FYS,'KH_FYS',infoList.KH_FYS_node_string,'KH_FYS_NG3')">
							{{'KH_FYS' in infoList? infoList.KH_FYS:0}}
						</div>
					</div>
					<div class="flex">
						<div class="left_line1">排烟温度</div>
						<div class="left_line2" @click="toCompon(2,'KH_PYB',infoList.KH_PYB_node_string,'KH_PYB_NG3','排烟温度班累计')"
							@dblclick="Cclick(infoList.KH_PYB,'KH_PYB',infoList.KH_PYB_node_string,'KH_PYB_NG3')">
							{{'KH_PYB' in infoList? infoList.KH_PYB:0}}
						</div>
						<div class="left_line3" @click="toCompon(2,'KH_PYT',infoList.KH_PYT_node_string,'KH_PYT_NG3','排烟温度天累计')"
							@dblclick="Cclick(infoList.KH_PYT,'KH_PYT',infoList.KH_PYT_node_string,'KH_PYT_NG3')">
							{{'KH_PYT' in infoList? infoList.KH_PYT:0}}
						</div>
						<div class="left_line4" @click="toCompon(2,'KH_PYY',infoList.KH_PYY_node_string,'KH_PYY_NG3','排烟温度月累计')"
							@dblclick="Cclick(infoList.KH_PYY,'KH_PYY',infoList.KH_PYY_node_string,'KH_PYY_NG3')">
							{{'KH_PYY' in infoList? infoList.KH_PYY:0}}
						</div>
						<div class="left_line5" @click="toCompon(2,'KH_PYS',infoList.KH_PYS_node_string,'KH_PYS_NG3','排烟温度定义考核累计')"
							@dblclick="Cclick(infoList.KH_PYS,'KH_PYS',infoList.KH_PYS_node_string,'KH_PYS_NG3')">
							{{'KH_PYS' in infoList? infoList.KH_PYS:0}}
						</div>
					</div>
					<div class="flex">
						<div class="left_line1">吨汽耗（高煤）</div>
						<div class="left_line2" @click="toCompon(2,'KH_MQHB',infoList.KH_MQHB_node_string,'KH_MQHBB_NG3','吨汽耗（高煤）班累计')"
							@dblclick="Cclick(infoList.KH_MQHB,'KH_MQHB',infoList.KH_MQHB_node_string,'KH_MQHBB_NG3')">
							{{'KH_MQHB' in infoList? infoList.KH_MQHB:0}}
						</div>
						<div class="left_line3" @click="toCompon(2,'KH_MQHT',infoList.KH_MQHT_node_string,'KH_MQHT_NG','吨汽耗（高煤）天累计')"
							@dblclick="Cclick(infoList.KH_MQHT,'KH_MQHT',infoList.KH_MQHT_node_string,'KH_MQHT_NG')">
							{{'KH_MQHT' in infoList? infoList.KH_MQHT:0}}
						</div>
						<div class="left_line4" @click="toCompon(2,'KH_MQHY',infoList.KH_MQHY_node_string,'KH_MQHY_NG','吨汽耗（高煤）月累计')"
							@dblclick="Cclick(infoList.KH_MQHY,'KH_MQHY',infoList.KH_MQHY_node_string,'KH_MQHY_NG')">
							{{'KH_MQHY' in infoList? infoList.KH_MQHY:0}}
						</div>
						<div class="left_line5" @click="toCompon(2,'KH_MQHS',infoList.KH_MQHS_node_string,'KH_MQHS_NG','吨汽耗（高煤）定义考核累计')"
							@dblclick="Cclick(infoList.KH_MQHS,'KH_MQHS',infoList.KH_MQHS_node_string,'KH_MQHS_NG')">
							{{'KH_MQHS' in infoList? infoList.KH_MQHS:0}}
						</div>
					</div>
					<div class="flex">
						<div class="left_line1">电耗（度）</div>
						<div class="left_line2" @click="toCompon(2,'KH_DHB',infoList.KH_DHB_node_string,'KH_DHB_NG3','电耗班累计')"
							@dblclick="Cclick(infoList.KH_DHB,'KH_DHB',infoList.KH_DHB_node_string,'KH_DHB_NG3')">
							{{'KH_DHB' in infoList? infoList.KH_DHB:0}}
						</div>
						<div class="left_line3" @click="toCompon(2,'KH_DHT',infoList.KH_DHT_node_string,'KH_DHT_NG3','电耗天累计')"
							@dblclick="Cclick(infoList.KH_DHT,'KH_DHT',infoList.KH_DHT_node_string,'KH_DHT_NG3')">
							{{'KH_DHT' in infoList? infoList.KH_DHT:0}}
						</div>
						<div class="left_line4" @click="toCompon(2,'KH_DHY',infoList.KH_DHY_node_string,'KH_DHY_NG3','电耗月累计')"
							@dblclick="Cclick(infoList.KH_DHY,'KH_DHY',infoList.KH_DHY_node_string,'KH_DHY_NG3')">
							{{'KH_DHY' in infoList? infoList.KH_DHY:0}}
						</div>
						<div class="left_line5" @click="toCompon(2,'KH_DHS',infoList.KH_DHS_node_string,'KH_DHS_NG3','电耗定义累计')"
							@dblclick="Cclick(infoList.KH_DHS,'KH_DHS',infoList.KH_DHS_node_string,'KH_DHS_NG3')">
							{{'KH_DHS' in infoList? infoList.KH_DHS:0}}
						</div>
					</div>
					<div class="flex">
						<div class="left_line1">自控率（%）</div>
						<div class="left_line2" @click="toCompon(2,'KH_ZKLB',infoList.KH_ZKLB_node_string,'KH_ZKLB_NG3','班自控率')"
							@dblclick="Cclick(infoList.KH_ZKLB,'KH_ZKLB',infoList.KH_ZKLB_node_string,'KH_ZKLB_NG3')">
							{{'KH_ZKLB' in infoList? infoList.KH_ZKLB:0}}
						</div>
						<div class="left_line3" @click="toCompon(2,'KH_ZKLT',infoList.KH_ZKLT_node_string,'KH_ZKLT_NG3','天自控率')"
							@dblclick="Cclick(infoList.KH_ZKLT,'KH_ZKLT',infoList.KH_ZKLT_node_string,'KH_ZKLT_NG3')">
							{{'KH_ZKLT' in infoList? infoList.KH_ZKLT:0}}
						</div>
						<div class="left_line4" @click="toCompon(2,'KH_ZKLY',infoList.KH_ZKLY_node_string,'KH_ZKLY_NG3','天自控率')"
							@dblclick="Cclick(infoList.KH_ZKLY,'KH_ZKLY',infoList.KH_ZKLY_node_string,'KH_ZKLY_NG3')">
							{{'KH_ZKLY' in infoList? infoList.KH_ZKLY:0}}
						</div>
						<div class="left_line5" @click="toCompon(2,'KH_ZKLS',infoList.KH_ZKLS_node_string,'KH_ZKLS_NG3','定义考核自控率')"
							@dblclick="Cclick(infoList.KH_ZKLS,'KH_ZKLS',infoList.KH_ZKLS_node_string,'KH_ZKLS_NG3')">
							{{'KH_ZKLS' in infoList? infoList.KH_ZKLS:0}}
						</div>
					</div>
					<div class="flex">
						<div class="left_line1">稳定性（%）</div>
						<div class="left_line2" @click="toCompon(2,'KH_WDXB',infoList.KH_WDXB_node_string,'KH_WDXB_NG3','班稳定性')"
							@dblclick="Cclick(infoList.KH_WDXB,'KH_WDXB',infoList.KH_WDXB_node_string,'KH_WDXB_NG3')">
							{{'KH_WDXB' in infoList? infoList.KH_WDXB:0}}
						</div>
						<div class="left_line3" @click="toCompon(2,'KH_WDXT',infoList.KH_WDXT_node_string,'KH_WDXT_NG3','天稳定性')"
							@dblclick="Cclick(infoList.KH_WDXT,'KH_WDXT',infoList.KH_WDXT_node_string,'KH_WDXT_NG3')">
							{{'KH_WDXT' in infoList? infoList.KH_WDXT:0}}
						</div>
						<div class="left_line4" @click="toCompon(2,'KH_WDXY',infoList.KH_WDXY_node_string,'KH_WDXY_NG3','月稳定性')"
							@dblclick="Cclick(infoList.KH_WDXY,'KH_WDXY',infoList.KH_WDXY_node_string,'KH_WDXY_NG3')">
							{{'KH_WDXY' in infoList? infoList.KH_WDXY:0}}
						</div>
						<div class="left_line5" @click="toCompon(2,'KH_WDXS',infoList.KH_WDXS_node_string,'KH_WDXS_NG3','定义考核稳定性')"
							@dblclick="Cclick(infoList.KH_WDXS,'KH_WDXS',infoList.KH_WDXS_node_string,'KH_WDXS_NG3')">
							{{'KH_WDXS' in infoList? infoList.KH_WDXS:0}}
						</div>
					</div>
				</div>
			</div>
			<div class="middle">
				<div class="middle_up">
					<div class="status">
						<div class="flex">
							<div class="status_style red" @click="toIpt(infoList.JNKHKS,'按钮','JNKHKS',infoList.JNKHKS_node_string)">
								{{'JNKHKS' in infoList? (infoList.JNKHKS ? "正在考核" : "等待考核"):0}}
							</div>
							<div class="status_style blue"
								@click="toIpt(infoList.KHFSXZ,'按钮','KHFSXZ',infoList.KHFSXZ_node_string)">
								{{'KHFSXZ' in infoList? (infoList.KHFSXZ ? "班运行考核" : "随机考核"):0}}
							</div>
							<div class="status_style green"
								@click="toIpt(infoList.JNKHKS,'按钮','JNKHKS',infoList.JNKHKS_node_string)">
								{{'JNKHKS' in infoList? (infoList.JNKHKS ? "开始考核" : "结束考核"):0}}
							</div>
						</div>
					</div>
					<div class="middle_up_times">
						<div class="flex">
							<div class="box up_box">
								<div class="box_words">考核时间设定</div>
								<div class="box_time" @click="toIpt(infoList.KH_SJ,'考核时间设定','KH_SJ',infoList.KH_SJ_node_string);
								        toCompon(2,'KH_SJ',infoList.KH_SJ_node_string,'KH_SJ_NG3','考核时间')"
									@dblclick="Cclick(infoList.KH_SJ,'KH_SJ',infoList.KH_SJ_node_string,'KH_SJ_NG3')">
									{{'KH_SJ' in infoList? infoList.KH_SJ:0}}
								</div>
							</div>
							<div class="box up_box">
								<div class="box_words">考核开始时间</div>
								<!-- <div class="box_time">08:00:00</div> -->
							</div>
						</div>
						<div class="flex">
							<div class="box down_box">
								<div class="box_words">考核进行时间</div>
								<div class="box_time" @click="toCompon(2,'KH_JXSJ',infoList.KH_JXSJ_node_string,'KH_JXSJ_NG3','考核_已进行时间')"
									@dblclick="Cclick(infoList.KH_JXSJ,'KH_JXSJ',infoList.KH_JXSJ_node_string,'KH_JXSJ_NG3')">
									{{'KH_JXSJ' in infoList? infoList.KH_JXSJ:0}}
								</div>
							</div>
							<div class="box down_box">
								<div class="box_words">考核结束时间</div>
								<div class="box_time">2022/3/21 15:56:49</div>
							</div>
						</div>
					</div>

				</div>
				<div class="middle_down">
					<!-- 标题 -->
					<div class="title">
						<div class="left_title">稳定性指标上下限</div>
					</div>
					<!-- 内容 -->
					<div class="middle_down_content">
						<div class="weight flex">
							<div class="weight_words">权重</div>
							<div class="weight_point"></div>
						</div>
						<div class="precision">
							<div class="precision_line flex">
								<div class="precision_box">
									<div class="precision_num"
										@click="toIpt(infoList.KH_SWH,'水位精度','KH_SWH',infoList.KH_SWH_node_string)">
										{{'KH_SWH' in infoList? infoList.KH_SWH:0}}
									</div>
									<div class="precision_name">水位精度</div>
								</div>
								<div class="precision_box">
									<div class="precision_num"
										@click="toIpt(infoList.KH_QWH,'汽温精度','KH_QWH',infoList.KH_QWH_node_string)">
										{{'KH_QWH' in infoList? infoList.KH_QWH:0}}
									</div>
									<div class="precision_name">汽温精度</div>
								</div>
							</div>
							<div class="precision_line flex">
								<div class="precision_box">
									<div class="precision_num"
										@click="toIpt(infoList.KH_FYH,'负压精度','KH_FYH',infoList.KH_FYH_node_string)">
										{{'KH_FYH' in infoList? infoList.KH_FYH:0}}
									</div>
									<div class="precision_name">负压精度</div>
								</div>
								<div class="precision_box">
									<div class="precision_num"
										@click="toIpt(infoList.KH_O2H,'氧量精度','KH_O2H',infoList.KH_O2H_node_string)">
										{{'KH_O2H' in infoList? infoList.KH_O2H:0}}
									</div>
									<div class="precision_name">氧量精度</div>
								</div>
							</div>
							<div class="precision_line flex">
								<div class="precision_box">
									<div class="precision_num"
										@click="toIpt(infoList.KH_QYH,'汽压精度','KH_QYH',infoList.KH_QYH_node_string)">
										{{'KH_QYH' in infoList? infoList.KH_QYH:0}}
									</div>
									<div class="precision_name">汽压精度</div>
								</div>
								<div class="precision_box">
									<div class="precision_num"
										@click="toIpt(infoList.KH_SWL,'汽水偏差','KH_SWL',infoList.KH_SWL_node_string)">
										{{'KH_SWL' in infoList? infoList.KH_SWL:0}}
									</div>
									<div class="precision_name">汽水偏差</div>
								</div>
							</div>
							<div class="precision_line flex">
								<div class="precision_box">
									<div class="precision_num"
										@click="toIpt(infoList.KH_O2L,'风量偏差','KH_O2L',infoList.KH_O2L_node_string)">
										{{'KH_O2L' in infoList? infoList.KH_O2L:0}}
									</div>
									<div class="precision_name">风量偏差</div>
								</div>
								<div class="precision_box">
									<div class="precision_num"
										@click="toIpt(infoList.KH_QYL,'煤气偏差','KH_QYL',infoList.KH_QYL_node_string)">
										{{'KH_QYL' in infoList? infoList.KH_QYL:0}}
									</div>
									<div class="precision_name">煤气偏差</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="right_up">
					<!-- 标题 -->
					<div class="title">
						<div class="left_title">自控率%</div>
					</div>
					<!-- 内容 -->
					<div class="right_up_content flex">
						<div class="content_left">
							<div class="content_left_line flex">
								<div class="words">水位控制回路</div>
								<div class="num" @click="toCompon(2,'KH_GSZKL',infoList.KH_GSZKL_node_string,'KH_GSZKL_NG3','给水自控率')"
									@dblclick="Cclick(infoList.KH_GSZKL,'KH_GSZKL',infoList.KH_GSZKL_node_string,'KH_GSZKL_NG3')">
									{{'KH_GSZKL' in infoList? infoList.KH_GSZKL:0}}
								</div>
								<div class="statistical">不统计</div>
							</div>
							<div class="content_left_line flex">
								<div class="words">汽温控制回路</div>
								<div class="num" @click="toCompon(2,'KH_QWZKL',infoList.KH_QWZKL_node_string,'KH_QWZKL_NG3','汽温自控率')"
									@dblclick="Cclick(infoList.KH_QWZKL,'KH_QWZKL',infoList.KH_QWZKL_node_string,'KH_QWZKL_NG3')">
									{{'KH_QWZKL' in infoList? infoList.KH_QWZKL:0}}
								</div>
								<div class="statistical">不统计</div>
							</div>
							<div class="content_left_line flex">
								<div class="words">引风控制回路</div>
								<div class="num" @click="toCompon(2,'KH_FYZKL',infoList.KH_FYZKL_node_string,'KH_FYZKL_NG3','负压自控率')"
									@dblclick="Cclick(infoList.KH_FYZKL,'KH_FYZKL',infoList.KH_FYZKL_node_string,'KH_FYZKL_NG3')">
									{{'KH_FYZKL' in infoList? infoList.KH_FYZKL:0}}
								</div>
								<div class="statistical">不统计</div>
							</div>
							<div class="content_left_line flex">
								<div class="words">送风控制回路</div>
								<div class="num" @click="toCompon(2,'KH_SFZKL',infoList.KH_SFZKL_node_string,'KH_SFZKL_NG3','送风自控率')"
									@dblclick="Cclick(infoList.KH_SFZKL,'KH_SFZKL',infoList.KH_SFZKL_node_string,'KH_SFZKL_NG3')">
									{{'KH_SFZKL' in infoList? infoList.KH_SFZKL:0}}
								</div>
								<div class="statistical">不统计</div>
							</div>
							<div class="content_left_line flex">
								<div class="words">负荷控制回路</div>
								<div class="num" @click="toCompon(2,'KH_MQZKL',infoList.KH_MQZKL_node_string,'KH_MQZKL_NG3','煤气自控率')"
									@dblclick="Cclick(infoList.KH_MQZKL,'KH_MQZKL',infoList.KH_MQZKL_node_string,'KH_MQZKL_NG3')">
									{{'KH_MQZKL' in infoList? infoList.KH_MQZKL:0}}
								</div>
								<div class="statistical">不统计</div>
							</div>
							<div class="content_left_line flex">
								<div class="words">二次风控制回路</div>
								<div class="num"></div>
								<div class="statistical">不统计</div>
							</div>
						</div>
						<div class="content_right">
							<div>
								<el-progress type="circle" :percentage="0" :show-text="false"></el-progress>
							</div>
							<div class="control_rate">
								<div class="control_rate_num"
									@click="toCompon(2,'KH_ZZKL',infoList.KH_ZZKL_node_string,'KH_ZZKL_NG3','总自控率')"
									@dblclick="Cclick(infoList.KH_ZZKL,'KH_ZZKL',infoList.KH_ZZKL_node_string,'KH_ZZKL_NG3')">
									{{'KH_ZZKL' in infoList? infoList.KH_ZZKL:0}}
								</div>
								<div class="control_rate_words">总自控率</div>
							</div>
						</div>
					</div>
				</div>
				<div class="right_middle">
					<!-- 标题 -->
					<div class="title">
						<div class="left_title">交班时间</div>
					</div>
					<!-- 内容 -->
					<div class="right_middle_content">
						<div class="nail flex">
							<div class="nail_line nail_line1">
								<div class="nail_word">甲</div>
								<div class="nail_time flex">
									<div class="first_column"
										@click="toIpt(infoList.KH_Hour1,'时','KH_Hour1',infoList.KH_Hour1_node_string)">
										{{'KH_Hour1' in infoList? infoList.KH_Hour1:0}}
									</div>
									<div>时</div>
									<div @click="toIpt(infoList.KH_MIN1,'分','KH_MIN1',infoList.KH_MIN1_node_string)">
										{{'KH_MIN1' in infoList? infoList.KH_MIN1:0}}
									</div>
									<div>分</div>
								</div>
							</div>
							<div class="nail_line">
								<div class="nail_word">乙</div>
								<div class="nail_time flex">
									<div class="first_column"
										@click="toIpt(infoList.KH_Hour2,'时','KH_Hour2',infoList.KH_Hour2_node_string)">
										{{'KH_Hour2' in infoList? infoList.KH_Hour2:0}}
									</div>
									<div>时</div>
									<div @click="toIpt(infoList.KH_MIN2,'分','KH_MIN2',infoList.KH_MIN2_node_string)">
										{{'KH_MIN2' in infoList? infoList.KH_MIN2:0}}
									</div>
									<div>分</div>
								</div>
							</div>
							<div class="nail_line">
								<div class="nail_word">丙</div>
								<div class="nail_time flex">
									<div class="first_column"
										@click="toIpt(infoList.KH_Hour3,'时','KH_Hour3',infoList.KH_Hour3_node_string)">
										{{'KH_Hour3' in infoList? infoList.KH_Hour3:0}}
									</div>
									<div>时</div>
									<div @click="toIpt(infoList.KH_MIN3,'分','KH_MIN3',infoList.KH_MIN3_node_string)">
										{{'KH_MIN3' in infoList? infoList.KH_MIN3:0}}
									</div>
									<div>分</div>
								</div>
							</div>
						</div>
						<div class="time_record flex">
							<div class="day flex">
								<div class="day_words">天记录时间：</div>
								<div class="day_record flex">
									<div class="first_column"
										@click="toIpt(infoList.KH_T_Hour,'时','KH_T_Hour',infoList.KH_T_Hour_node_string)">
										{{'KH_T_Hour' in infoList? infoList.KH_T_Hour:0}}
									</div>
									<div>时</div>
									<div @click="toIpt(infoList.KH_T_MIN,'分','KH_T_MIN',infoList.KH_T_MIN_node_string)">
										{{'KH_T_MIN' in infoList? infoList.KH_T_MIN:0}}
									</div>
									<div>分</div>
								</div>
							</div>
							<div class="month flex">
								<div class="month_words">月记录时间：</div>
								<div class="month_record flex">
									<div @click="toIpt(infoList.KH_Y,'日','KH_Y',infoList.KH_Y_node_string)">
										{{'KH_Y' in infoList? infoList.KH_Y:0}}
									</div>
									<div>日</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="right_down">
					<!-- 标题 -->
					<div class="title">
						<div class="left_title">综合统计参数</div>
					</div>
					<!-- 内容 -->
					<div class="right_down_content flex">
						<div class="right_down_left flex">
							<div class="light"></div>
							<div class="per_ton">
								<div class="per_ton_num" @click="toCompon(2,'MQHS',infoList.MQHS_node_string,'MQHS_NG3','瞬时吨汽耗')"
									@dblclick="Cclick(infoList.MQHS,'MQHS',infoList.MQHS_node_string,'MQHS_NG3')">
									{{'MQHS' in infoList? infoList.MQHS:0}}
								</div>
								<div class="per_ton_words">瞬时吨汽耗</div>
							</div>
						</div>
						<div class="right_down_right">
							<div class="com_evaluation">综合运行评价</div>
							<div class="stars" @click="toCompon(2,'CEI_LEVEL',infoList.CEI_LEVEL_node_string,'CEI_NG3','综合运行评价')"
								@dblclick="Cclick(infoList.CEI_LEVEL,'CEI_LEVEL',infoList.CEI_LEVEL_node_string,'CEI_NG3')">
								{{'CEI_LEVEL' in infoList? infoList.CEI_LEVEL:0}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
		<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<RqCommonParameters v-if="flag == 4 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></RqCommonParameters>
		<RqSoftMeasurement v-if="flag == 6 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></RqSoftMeasurement>
		<RqOnlineService v-if="flag == 7 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></RqOnlineService>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historicaljrl.vue" //历史趋势
	import RqCommonParameters from "@/views/RqBoiler/RqCommonParameters/index.vue"; //常用参数组件
	import RqSoftMeasurement from "@/views/RqBoiler/RqSoftNumber/index.vue"; //软测量数统
	import RqOnlineService from "@/views/RqBoiler/RqOnlineService/index.vue"; //客服
	export default {
		name: "Param11",
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},
		components: {
			inputVal,
			Historical,
			RqCommonParameters,
			RqSoftMeasurement,
			RqOnlineService
		},
		data: () => {
			return {
				chName: '',
				flag: 1,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			this.$bus.$on("sendMsg", (msg) => {
				// 兄弟组件发送来的消息
				this.flag = msg;
			});
		},
		methods: {
			DataJson(data) {
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'rqkfirstcontol'
							break
						case 3:
							pathname = 'RqRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'Param03', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
					case 'RqOnlineService':
					case (this.flag == 7):
						return this.flag = 1, this.$bus.$emit("footersendMsg", 1)
					case 'RqSoftNumber':
					case (this.flag == 6):
						return this.flag = 1, this.$bus.$emit("footersendMsg", 1)
					case 'RqCommonParameters':
					case (this.flag == 4):
						return this.flag = 1, this.$bus.$emit("footersendMsg", 1)
				}
			},
			// 打开下置输入窗口
			toIpt(data, name, historyname, node, type, status) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0];
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error("暂无调试权限");
					}
				} else {
					this.$refs.inputVal.open(data, name, historyname, node, type, status);
				}
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							console.log(1)
							return this.isMshow = true, this.Manualname = name, this.Manualnode = name2, this
								.ManualAname = name3, this.Manualtitname = titname
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.fl-t {
		float: left;
	}

	.fr-t {
		float: right;
	}

	.title-hang {
		height: 9vh;
		width: 96vw;
		font-family: MicrosoftYaHei;
		font-size: 2vh;
		font-weight: normal;
		font-stretch: normal;
		line-height: 2vh;
		letter-spacing: 0vh;
	}

	.zhuangshizb {
		width: 16vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate06.png");
		background-size: 100% 100%;
		margin-left: 13vw;
		margin-top: 1.5vh;
	}

	.zhuangshizj {
		width: 98vw;
		height: 4vh;
		line-height: 4vh;
		margin-top: 1vh;
		text-align: center;
		// background-image: url("~@/assets/images/rfl_evaluate05.png");
		background-size: 100% 100%;
		// margin-left: 5vw;
		font-family: MicrosoftYaHei;
		font-size: 3vh;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0vh;
		color: #0ef7ff;
	}

	.zhuangshiyb {
		width: 16vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate04.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		margin-top: 1.5vh;
	}

	.all {
		font-family: PingFang-SC-Regular;
		font-weight: normal;
		font-stretch: normal;
	}

	.title {
		width: 11vw;
		font-size: 2vh;
		line-height: 3vh;
		letter-spacing: 0vh;
		color: #ffffff;
		letter-spacing: 0vh;
		text-align: center;
	}

	.left {
		width: 33vw;
		height: 74vh;
		margin-left: 1.5vw;
		background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/left.png");
		background-size: 100% 100%;

		.left_content {
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vw;
			color: #00e4ff;
			text-align: center;

			.left_line0 {
				margin-left: 2vw;
				width: 8vw;
			}

			.left_line1 {
				margin-left: 2vw;
				width: 8vw;
				color: #8aeaff;
			}

			.left_line2 {
				width: 5vw;
				cursor: pointer;
			}

			.left_line3 {
				width: 5vw;
				cursor: pointer;
			}

			.left_line4 {
				width: 5vw;
				cursor: pointer;
			}

			.left_line5 {
				width: 6vw;
				cursor: pointer;
			}
		}
	}

	.middle {
		width: 28vw;
		margin-left: 1vw;

		.middle_up {
			height: 20vh;
			background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/middle1.png");
			background-size: 100% 100%;

			.status {
				margin-left: 0.5vw;
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 4vh;
				letter-spacing: 0vw;

				.status_style {
					margin-top: 2vh;
					margin-left: 1vw;
					width: 7.5vw;
					height: 4vh;
					text-align: center;
				}

				.red {
					border: 1px solid #ff0d1e;
					color: #ff0d1e;
					cursor: pointer;
				}

				.blue {
					border: 1px solid #0085eb;
					color: #0085eb;
					cursor: pointer;
				}

				.green {
					border: 1px solid #00ff8a;
					border-radius: 0vh;
					color: #09bd51;
					cursor: pointer;
				}
			}

			.middle_up_times {
				font-size: 1vw;
				line-height: 2.5vh;
				margin-left: 1.5vw;

				.box {
					width: 12vw;
					height: 5vh;
					text-align: center;
				}

				.up_box {
					margin-top: 2vh;
				}

				.box_words {

					color: #458ca4;
				}

				.box_time {
					color: #ffffff;
					cursor: pointer;
				}
			}
		}

		.middle_down {
			height: 52vh;
			background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/middle2.png");
			background-size: 100% 100%;
			margin-top: 2vh;

			.weight {
				font-family: PingFang-SC-Regular;
				font-size: 2vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 4vh;
				letter-spacing: 0vh;

				.weight_words {
					margin-left: 21vw;
					color: #458ca4;
				}

				.weight_point {
					margin-top: 1.5vh;
					margin-left: 0.5vw;
					width: 3vw;
					height: 0.8vh;
					background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/points.png");
					background-size: 100% 100%;
				}
			}

			.precision {
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vw;
				letter-spacing: 0vw;

				.precision_line {
					margin-top: 2vh;

					.precision_box {
						width: 10vw;
						height: 8vh;
						background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/stable_bg.png");
						background-size: 100% 100%;
						margin-left: 2.8vw;
						text-align: right;

						.precision_num {
							width: 9vw;
							color: #00ffb4;
							cursor: pointer;
						}

						.precision_name {
							width: 9vw;
							color: #8aeaff;

						}
					}
				}
			}
		}
	}

	.right {
		width: 33vw;
		margin-left: 1vw;

		.right_up {
			height: 36vh;
			background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/right1.png");
			background-size: 100% 100%;

			.right_up_content {
				.content_left {
					margin-top: 1vw;

					.content_left_line {
						font-family: PingFang-SC-Regular;
						font-weight: normal;
						font-stretch: normal;
						letter-spacing: 0vh;
						text-align: center;
						line-height: 5vh;
						font-size: 1.1vw;

						.words {
							margin-left: 2vw;
							width: 8vw;
							color: #8aeaff;
						}

						.num {
							margin-left: 2vw;
							width: 2vw;
							color: #00e4ff;
							cursor: pointer;
						}

						.statistical {
							margin-left: 2vw;
							width: 5vw;
							color: #1082a8;
						}
					}
				}

				.content_right {
					.el-progress {
						margin-left: 2vw;
						margin-top: 5vh;
						background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/stable_content.png");
						background-size: 100% 100%;
					}

					.control_rate {
						width: 10vw;
						height: 9vh;
						background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/stable_total.png");
						background-size: 100% 100%;
						margin-left: 1vw;
						font-family: PingFang-SC-Regular;
						font-weight: normal;
						font-stretch: normal;
						line-height: 2vw;
						letter-spacing: 0vw;
						text-align: center;

						.control_rate_num {
							font-size: 2vw;
							line-height: 3vw;
							color: #00e4ff;
							cursor: pointer;
						}

						.control_rate_words {
							font-size: 1vw;
							color: #8aeaff;
						}
					}
				}
			}

		}

		.right_middle {
			height: 17vh;
			background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/right2.png");
			background-size: 100% 100%;
			margin-top: 2vh;

			.right_middle_content {
				font-family: PingFang-SC-Regular;
				font-size: 1vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vw;

				.nail {
					letter-spacing: 0.5vw;

					.nail_line1 {
						margin-left: 1.2vw;
					}

					.nail_line {
						margin-top: 1vh;
						text-align: center;

						.nail_word {
							width: 10vw;
							color: #8aeaff;
						}

						.nail_time {
							width: 10vw;
							color: #00e4ff;
							cursor: pointer;

							.first_column {
								margin-left: 2vw;
							}
						}
					}
				}

				.time_record {
					margin-top: 1.5vh;
					margin-left: 2vw;
					font-family: PingFang-SC-Regular;
					font-size: 1vw;
					font-weight: normal;
					font-stretch: normal;
					line-height: 1vw;

					.day {
						.day_words {
							letter-spacing: 0vw;
							color: #458ca4;
						}

						.day_record {
							letter-spacing: 0.5vw;
							color: #00e4ff;
							cursor: pointer;
						}
					}

					.month {
						margin-left: 5vw;

						.month_words {
							letter-spacing: 0vw;
							color: #458ca4;
						}

						.month_record {
							letter-spacing: 0.5vw;
							color: #00e4ff;
							cursor: pointer;
						}
					}
				}
			}
		}

		.right_down {
			height: 17vh;
			background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/right3.png");
			background-size: 100% 100%;
			margin-top: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3vh;
			letter-spacing: 0vh;

			.right_down_left {
				.light {
					width: 3vw;
					height: 6vh;
					background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/statistics.png");
					background-size: 100% 100%;
					margin-top: 4vh;
					margin-left: 3vw;
				}

				.per_ton {
					margin-top: 4vh;
					margin-left: 2vw;

					.per_ton_num {
						color: #00e4ff;
						cursor: pointer;
					}

					.per_ton_words {
						color: #8aeaff;
					}
				}
			}

			.right_down_right {
				width: 10vw;
				margin-left: 6vw;
				margin-top: 2vh;

				.com_evaluation {
					text-align: center;
					color: #8aeaff;
				}

				.stars {
					text-align: center;
					font-family: PingFang-SC-Regular;
					font-size: 2vw;
					font-weight: normal;
					font-stretch: normal;
					line-height: 3vw;
					letter-spacing: 0vw;
					color: #00e4ff;
					cursor: pointer;
					// width: 8vw;
					// height: 3vh;
					// background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/star1.png");
				}
			}
		}
	}
</style>
