<template>
  <div class="jrlEvaluate" id="jrlEvaluate">
    <!-- 标题部分 -->
    <div class="title-hang">
      <div class="fl">
        <dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
      </div>
      <div class="fl"></div>
      <div class="zhuangshizj fl">{{ projectData.project }}_运行考核</div>
      <div class="fl">
        <div class="fl">
          <dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
        </div>
      </div>
    </div>
    <!-- 下半部分 -->
    <div class="mainall">
      <!-- 左边 -->
      <div class="fl leftmain">
        <div class="leftmain-row1">数据显示</div>
        <div class="leftmain-row2">
          <div class="leftmain-row2-col fl">序号</div>
          <div class="leftmain-row2-col1 fl" style="padding-left: 1vw">
            班运行
          </div>
          <div class="leftmain-row2-col1 fl">天运行</div>
          <div class="leftmain-row2-col1 fl">月运行</div>
          <div class="leftmain-row2-col1 fl">自定义运行</div>
        </div>
        <div class="leftmain-row3">
          <div class="leftmain-row3-col fl">过钢累计(根)</div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHGGLJB',
                infoList.KHGGLJB_node_string,
                'KHGGLJB_J1',
                '过钢量班累积'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHGGLJB,
                'KHGGLJB',
                infoList.KHGGLJB_node_string,
                'KHGGLJB_J1'
              )
            "
          >
            {{ 'KHGGLJB' in infoList?infoList.KHGGLJB :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHGGLJT',
                infoList.KHGGLJT_node_string,
                'KHGGLJT_J1',
                '过钢量天累积'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHGGLJT,
                'KHGGLJT',
                infoList.KHGGLJT_node_string,
                'KHGGLJT_J1'
              )
            "
          >
            {{ 'KHGGLJT' in infoList?infoList.KHGGLJT :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHGGLJY',
                infoList.KHGGLJY_node_string,
                'HGGLJY_J1',
                '过钢量月累积'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHGGLJY,
                'KHGGLJY',
                infoList.KHGGLJY_node_string,
                'HGGLJY_J1'
              )
            "
          >
            {{ 'KHGGLJY' in infoList?infoList.KHGGLJY :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHGGLJS',
                infoList.KHGGLJS_node_string,
                'KHGGLJS_J1',
                '过钢量月累积'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHGGLJS,
                'KHGGLJS',
                infoList.KHGGLJS_node_string,
                'KHGGLJS_J1'
              )
            "
          >
            {{ 'KHGGLJS' in infoList?infoList.KHGGLJS :0}}
          </div>
        </div>
        <div class="leftmain-row3">
          <div class="leftmain-row3-col fl">钢重累计(T)</div>
          <div class="leftmain-row3-col1 fl">{{ this.gzlj1 }}</div>
          <div class="leftmain-row3-col1 fl">{{ this.gzlj2 }}</div>
          <div class="leftmain-row3-col1 fl">{{ this.gzlj3 }}</div>
          <div class="leftmain-row3-col1 fl">{{ this.gzlj4 }}</div>
        </div>
        <div class="leftmain-row3">
          <div class="leftmain-row3-col fl">煤气累计(Wm³)</div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHMQLJB',
                infoList.KHMQLJB_node_string,
                'KHMQLJB_J1',
                '煤气班累积'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHMQLJB,
                'KHMQLJB',
                infoList.KHMQLJB_node_string,
                'KHMQLJB_J1'
              )
            "
          >
            {{ 'KHMQLJB' in infoList?infoList.KHMQLJB :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHMQLJT',
                infoList.KHMQLJT_node_string,
                'KHMQLJT_J1',
                '煤气天累积'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHMQLJT,
                'KHMQLJT',
                infoList.KHMQLJT_node_string,
                'KHMQLJT_J1'
              )
            "
          >
            {{ 'KHMQLJT' in infoList?infoList.KHMQLJT :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHMQLJY',
                infoList.KHMQLJY_node_string,
                'KHMQLJY_J1',
                '煤气月累积'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHMQLJY,
                'KHMQLJY',
                infoList.KHMQLJY_node_string,
                'KHMQLJY_J1'
              )
            "
          >
            {{ 'KHMQLJY' in infoList?infoList.KHMQLJY :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHMQLJS',
                infoList.KHMQLJS_node_string,
                'KHMQLJS_J1',
                '煤气月累积'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHMQLJS,
                'KHMQLJS',
                infoList.KHMQLJS_node_string,
                'KHMQLJS_J1'
              )
            "
          >
            {{ 'KHMQLJS' in infoList?infoList.KHMQLJS :0}}
          </div>
        </div>

        <div class="leftmain-row3">
          <div class="leftmain-row3-col fl">炉膛均压</div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(2, 'KHPLTB', infoList.KHPLTB_node_string, 'KHPLTB_J1')
            "
            @dblclick="
              Cclick(
                infoList.KHPLTB,
                'KHPLTB',
                infoList.KHPLTB_node_string,
                'KHPLTB_J1'
              )
            "
          >
            {{ 'KHPLTB' in infoList?infoList.KHPLTB :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(2, 'KHPLTT', infoList.KHPLTT_node_string, 'KHPLTT_J1')
            "
            @dblclick="
              Cclick(
                infoList.KHPLTT,
                'KHPLTT',
                infoList.KHPLTT_node_string,
                'KHPLTT_J1'
              )
            "
          >
            {{ 'KHPLTT' in infoList?infoList.KHPLTT :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(2, 'KHPLTY', infoList.KHPLTY_node_string, 'KHPLTY_J1')
            "
            @dblclick="
              Cclick(
                infoList.KHPLTY,
                'KHPLTY',
                infoList.KHPLTY_node_string,
                'KHPLTY_J1'
              )
            "
          >
            {{ 'KHPLTY' in infoList?infoList.KHPLTY :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(2, 'KHPLTS', infoList.KHPLTS_node_string, 'KHPLTS_J1')
            "
            @dblclick="
              Cclick(
                infoList.KHPLTS,
                'KHPLTS',
                infoList.KHPLTS_node_string,
                'KHPLTS_J1'
              )
            "
          >
            {{ 'KHPLTS' in infoList?infoList.KHPLTS :0}}
          </div>
        </div>
        <div class="leftmain-row3">
          <div class="leftmain-row3-col fl">均热均温</div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(2, 'KHT11B', infoList.KHT11B_node_string, 'KHT11B_J1')
            "
            @dblclick="
              Cclick(
                infoList.KHT11B,
                'KHT11B',
                infoList.KHT11B_node_string,
                'KHT11B_J1'
              )
            "
          >
            {{ 'KHT11B' in infoList?infoList.KHT11B :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(2, 'KHT11T', infoList.KHT11T_node_string, 'KHT11T_J1')
            "
            @dblclick="
              Cclick(
                infoList.KHT11T,
                'KHT11T',
                infoList.KHT11T_node_string,
                'KHT11T_J1'
              )
            "
          >
            {{ 'KHT11T' in infoList?infoList.KHT11T :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(2, 'KHT11Y', infoList.KHT11Y_node_string, 'KHPLTY_J1')
            "
            @dblclick="
              Cclick(
                infoList.KHT11Y,
                'KHT11Y',
                infoList.KHT11Y_node_string,
                'KHPLTY_J1'
              )
            "
          >
            {{ 'KHT11Y' in infoList?infoList.KHT11Y :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(2, 'KHT11S', infoList.KHT11S_node_string, 'KHPLTS_J1')
            "
            @dblclick="
              Cclick(
                infoList.KHT11S,
                'KHT11S',
                infoList.KHT11S_node_string,
                'KHPLTS_J1'
              )
            "
          >
            {{ 'KHT11S' in infoList?infoList.KHT11S :0}}
          </div>
        </div>
        <div class="leftmain-row3">
          <div class="leftmain-row3-col fl">出钢均温</div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(2, 'KHTCGB', infoList.KHTCGB_node_string, 'KHTCGB_J1')
            "
            @dblclick="
              Cclick(
                infoList.KHTCGB,
                'KHTCGB',
                infoList.KHTCGB_node_string,
                'KHTCGB_J1'
              )
            "
          >
            {{ 'KHTCGB' in infoList?infoList.KHTCGB :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(2, 'KHTCGT', infoList.KHTCGT_node_string, 'KHTCGT_J1')
            "
            @dblclick="
              Cclick(
                infoList.KHTCGT,
                'KHTCGT',
                infoList.KHTCGT_node_string,
                'KHTCGT_J1'
              )
            "
          >
            {{ 'KHTCGT' in infoList?infoList.KHTCGT :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(2, 'KHTCGY', infoList.KHTCGY_node_string, 'KHTCGY_J1')
            "
            @dblclick="
              Cclick(
                infoList.KHTCGY,
                'KHTCGY',
                infoList.KHTCGY_node_string,
                'KHTCGY_J1'
              )
            "
          >
            {{ 'KHTCGY' in infoList?infoList.KHTCGY :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(2, 'KHTCGS', infoList.KHTCGS_node_string, 'KHTCGS_J1')
            "
            @dblclick="
              Cclick(
                infoList.KHTCGS,
                'KHTCGS',
                infoList.KHTCGS_node_string,
                'KHTCGS_J1'
              )
            "
          >
            {{ 'KHTCGS' in infoList?infoList.KHTCGS :0}}
          </div>
        </div>
        <div class="leftmain-row3">
          <div class="leftmain-row3-col fl">入钢均温</div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHTRGB',
                infoList.KHTRGB_node_string,
                'KHTRGB_J1',
                '入炉钢温班平均值'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHTRGB,
                'KHTRGB',
                infoList.KHTRGB_node_string,
                'KHTRGB_J1'
              )
            "
          >
            {{ 'KHTRGB' in infoList?infoList.KHTRGB :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHTRGT',
                infoList.KHTRGT_node_string,
                'KHTRGT_J1',
                '入炉钢温天平均值'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHTRGT,
                'KHTRGT',
                infoList.KHTRGT_node_string,
                'KHTRGT_J1'
              )
            "
          >
            {{ 'KHTRGT' in infoList?infoList.KHTRGT :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHTRGY',
                infoList.KHTRGY_node_string,
                'KHTRGY_J1',
                '入炉钢温班平均值'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHTRGY,
                'KHTRGY',
                infoList.KHTRGY_node_string,
                'KHTRGY_J1'
              )
            "
          >
            {{ 'KHTRGY' in infoList?infoList.KHTRGY :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHTRGS',
                infoList.KHTRGS_node_string,
                'KHTRGS_J1',
                '入炉钢温-随机考核平均值'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHTRGS,
                'KHTRGS',
                infoList.KHTRGS_node_string,
                'KHTRGS_J1'
              )
            "
          >
            {{ 'KHTRGS' in infoList?infoList.KHTRGS :0}}
          </div>
        </div>
        <div class="leftmain-row3">
          <div class="leftmain-row3-col fl">热装率%</div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHRZLB',
                infoList.KHRZLB_node_string,
                'KHRZLB_J1',
                '热装率-班统计'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHRZLB,
                'KHRZLB',
                infoList.KHRZLB_node_string,
                'KHRZLB_J1'
              )
            "
          >
            {{ 'KHRZLB' in infoList?infoList.KHRZLB :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHRZLT',
                infoList.KHRZLT_node_string,
                'KHRZLT_J1',
                '热装率-天统计'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHRZLT,
                'KHRZLT',
                infoList.KHRZLT_node_string,
                'KHRZLT_J1'
              )
            "
          >
            {{ 'KHRZLT' in infoList?infoList.KHRZLT :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHRZLY',
                infoList.KHRZLY_node_string,
                'KHRZLY_J1',
                '热装率-月统计'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHRZLY,
                'KHRZLY',
                infoList.KHRZLY_node_string,
                'KHRZLY_J1'
              )
            "
          >
            {{ 'KHRZLY' in infoList?infoList.KHRZLY :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHRZLS',
                infoList.KHRZLS_node_string,
                'KHRZLS_J1',
                '热装率-随机考核统计'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHRZLS,
                'KHRZLS',
                infoList.KHRZLS_node_string,
                'KHRZLS_J1'
              )
            "
          >
            {{ 'KHRZLS' in infoList?infoList.KHRZLS :0}}
          </div>
        </div>
        <div class="leftmain-row3">
          <div class="leftmain-row3-col fl">单耗(m³/t)</div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHMQGGB',
                infoList.KHMQGGB_node_string,
                'KHMQGGB_J1',
                '本班吨钢耗煤气'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHMQGGB,
                'KHMQGGB',
                infoList.KHMQGGB_node_string,
                'KHMQGGB_J1'
              )
            "
          >
            {{ 'KHMQGGB' in infoList?infoList.KHMQGGB :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHMQGGT',
                infoList.KHMQGGT_node_string,
                'KHMQGGT_J1',
                '当天吨钢耗煤气'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHMQGGT,
                'KHMQGGT',
                infoList.KHMQGGT_node_string,
                'KHMQGGT_J1'
              )
            "
          >
            {{ 'KHMQGGT' in infoList?infoList.KHMQGGT :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHMQGGY',
                infoList.KHMQGGY_node_string,
                'KHMQGGY_J1',
                '本月吨钢耗煤气'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHMQGGY,
                'KHMQGGY',
                infoList.KHMQGGY_node_string,
                'KHMQGGY_J1'
              )
            "
          >
            {{ 'KHMQGGY' in infoList?infoList.KHMQGGY :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHMQGGS',
                infoList.KHMQGGS_node_string,
                'KHMQGGS_J1',
                '上月吨钢耗煤气'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHMQGGS,
                'KHMQGGS',
                infoList.KHMQGGS_node_string,
                'KHMQGGS_J1'
              )
            "
          >
            {{ 'KHMQGGS' in infoList?infoList.KHMQGGS :0}}
          </div>
        </div>
        <div class="leftmain-row3">
          <div class="leftmain-row3-col fl">自控率累计%</div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHZKLB',
                infoList.KHZKLB_node_string,
                'KHZKLB_J1',
                '班总自控率累计'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHZKLB,
                'KHZKLB',
                infoList.KHZKLB_node_string,
                'KHZKLB_J1'
              )
            "
          >
            {{ 'KHZKLB' in infoList?infoList.KHZKLB :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHZKLT',
                infoList.KHZKLT_node_string,
                'KHZKLT_J1',
                '天总自控率累计'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHZKLT,
                'KHZKLT',
                infoList.KHZKLT_node_string,
                'KHZKLT_J1'
              )
            "
          >
            {{ 'KHZKLT' in infoList?infoList.KHZKLT :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHZKLY',
                infoList.KHZKLY_node_string,
                'KHZKLY_J1',
                '月总自控率累计'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHZKLY,
                'KHZKLY',
                infoList.KHZKLY_node_string,
                'KHZKLY_J1'
              )
            "
          >
            {{ 'KHZKLY' in infoList?infoList.KHZKLY :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHZKLS',
                infoList.KHZKLS_node_string,
                'KHZKLS_J1',
                '自定义总自控率累计'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHZKLS,
                'KHZKLS',
                infoList.KHZKLS_node_string,
                'KHZKLS_J1'
              )
            "
          >
            {{ 'KHZKLS' in infoList?infoList.KHZKLS :0}}
          </div>
        </div>
        <div class="leftmain-row3">
          <div class="leftmain-row3-col fl">稳定性（%）</div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHWDXB',
                infoList.KHWDXB_node_string,
                'KHWDXB_J1',
                '班稳定性考核'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHWDXB,
                'KHWDXB',
                infoList.KHWDXB_node_string,
                'KHWDXB_J1'
              )
            "
          >
            {{ 'KHWDXB' in infoList?infoList.KHWDXB :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHWDXT',
                infoList.KHWDXT_node_string,
                'KHWDXT_J1',
                '天稳定性考核'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHWDXT,
                'KHWDXT',
                infoList.KHWDXT_node_string,
                'KHWDXT_J1'
              )
            "
          >
            {{ 'KHWDXT' in infoList?infoList.KHWDXT :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHWDXY',
                infoList.KHWDXY_node_string,
                'KHWDXY_J1',
                '月稳定性考核'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHWDXY,
                'KHWDXY',
                infoList.KHWDXY_node_string,
                'KHWDXY_J1'
              )
            "
          >
            {{ 'KHWDXY' in infoList?infoList.KHWDXY :0}}
          </div>
          <div
            class="leftmain-row3-col1 fl"
            @click="
              toCompon(
                2,
                'KHWDXS',
                infoList.KHWDXS_node_string,
                'KHWDXS_J1',
                '自定义稳定性考核'
              )
            "
            @dblclick="
              Cclick(
                infoList.KHWDXS,
                'KHWDXS',
                infoList.KHWDXS_node_string,
                'KHWDXS_J1'
              )
            "
          >
            {{ 'KHWDXS' in infoList?infoList.KHWDXS :0}}
          </div>
        </div>
      </div>
    </div>
    <!-- 中间部分 -->
    <div class="fl center-main">
      <div class="center-main-top">
        <div class="center-main-top-col">
          <div class="center-main-top1 fl">等待考核</div>
          <div class="center-main-top2 fl">自定义考核</div>
          <div class="center-main-top3 fl">结束考核</div>
        </div>
        <div>
          <div class="fl center-main-top1-col">
            <div class="col1">考核时间设定</div>
            <div
              class="col2"
              @click="
                toIpt(
                  infoList.KH_SJ,
                  '考核时间设定',
                  'KH_SJ',
                  infoList.KH_SJ_node_string
                )
              "
            >
              {{ 'KH_SJ' in infoList?infoList.KH_SJ :0}}
            </div>
            <div class="col1">考核进行时间</div>
            <div class="col2">{{ 'KH_JXSJ' in infoList?infoList.KH_JXSJ :0}}</div>
          </div>
          <div class="fl center-main-top1-col">
            <div class="col1">考核开始时间</div>
            <div class="col2"></div>
            <div class="col1">考核结束时间</div>
            <div class="col2"></div>
          </div>
        </div>
      </div>
      <div class="center-main-bottom">
        <div class="centermain-row1">指标名称和精度</div>
        <div class="centermain-row2 fl">
          <div class="fl centermain-row3">
            <div
              class="centermain-row3-lable"
              @click="
                toIpt(
                  infoList.KH_MQPSP,
                  '煤气压力',
                  'KH_MQPSP',
                  infoList.KH_MQPSP_node_string
                )
              "
            >
              {{ 'KH_MQPSP' in infoList?infoList.KH_MQPSP :0}}
            </div>
            <div class="centermain-row3-lable1">煤气压力</div>
          </div>
          <div class="fl centermain-row3 centermain-row3-jg">
            <div
              class="centermain-row3-lable"
              @click="
                toIpt(
                  infoList.KH_FY5SP,
                  '炉膛压力',
                  'KH_FY5SP',
                  infoList.KH_FY5SP_node_string
                )
              "
            >
              {{ 'KH_FY5SP' in infoList?infoList.KH_FY5SP :0}}
            </div>
            <div class="centermain-row3-lable1">炉膛压力</div>
          </div>
          <div class="fl centermain-row3 centermain-row3-jg">
            <div
              class="centermain-row3-lable"
              @click="
                toIpt(
                  infoList.KH_T12SP,
                  '均热烟温',
                  'KH_T12SP',
                  infoList.KH_T12SP_node_string
                )
              "
            >
              {{ 'KH_T12SP' in infoList?infoList.KH_T12SP :0}}
            </div>
            <div class="centermain-row3-lable1">均热烟温</div>
          </div>
          <div class="fl centermain-row3 centermain-row3-jg">
            <div
              class="centermain-row3-lable"
              @click="
                toIpt(
                  infoList.KH_T22SP,
                  '三加烟温',
                  'KH_T22SP',
                  infoList.KH_T22SP_node_string
                )
              "
            >
              {{ 'KH_T22SP' in infoList?infoList.KH_T22SP :0}}
            </div>
            <div class="centermain-row3-lable1">三加烟温</div>
          </div>
          <div class="fl centermain-row3 centermain-row3-jg">
            <div
              class="centermain-row3-lable"
              @click="
                toIpt(
                  infoList.KH_T32SP,
                  '二加烟温',
                  'KH_T32SP',
                  infoList.KH_T32SP_node_string
                )
              "
            >
              {{ 'KH_T32SP' in infoList?infoList.KH_T32SP :0}}
            </div>
            <div class="centermain-row3-lable1">二加烟温</div>
          </div>
          <div class="fl centermain-row3 centermain-row3-jg">
            <div
              class="centermain-row3-lable"
              @click="
                toIpt(
                  infoList.KH_T42SP,
                  '一加烟温',
                  'KH_T42SP',
                  infoList.KH_T42SP_node_string
                )
              "
            >
              {{ 'KH_T42SP' in infoList?infoList.KH_T42SP :0}}
            </div>
            <div class="centermain-row3-lable1">一加烟温</div>
          </div>
        </div>
        <div class="centermain-row2 fl centermain-row2-jg">
          <div class="fl centermain-row3">
            <div
              class="centermain-row3-lable"
              @click="
                toIpt(
                  infoList.KH_KQPSP,
                  '空气压力',
                  'KH_KQPSP',
                  infoList.KH_KQPSP_node_string
                )
              "
            >
              {{ 'KH_KQPSP' in infoList?infoList.KH_KQPSP :0}}
            </div>
            <div class="centermain-row3-lable1">空气压力</div>
          </div>
          <div class="fl centermain-row3 centermain-row3-jg">
            <div
              class="centermain-row3-lable"
              @click="
                toIpt(
                  infoList.KH_T11SP,
                  '均热温度',
                  'KH_T11SP',
                  infoList.KH_T11SP_node_string
                )
              "
            >
              {{ 'KH_T11SP' in infoList?infoList.KH_T11SP :0}}
            </div>
            <div class="centermain-row3-lable1">均热温度</div>
          </div>
          <div class="fl centermain-row3 centermain-row3-jg">
            <div
              class="centermain-row3-lable"
              @click="
                toIpt(
                  infoList.KH_T21SP,
                  '三加温度',
                  'KH_T21SP',
                  infoList.KH_T21SP_node_string
                )
              "
            >
              {{ 'KH_T21SP' in infoList?infoList.KH_T21SP :0}}
            </div>
            <div class="centermain-row3-lable1">三加温度</div>
          </div>
          <div class="fl centermain-row3 centermain-row3-jg">
            <div
              class="centermain-row3-lable"
              @click="
                toIpt(
                  infoList.KH_T31SP,
                  '二加温度',
                  'KH_T31SP',
                  infoList.KH_T31SP_node_string
                )
              "
            >
              {{ 'KH_T31SP' in infoList?infoList.KH_T31SP :0}}
            </div>
            <div class="centermain-row3-lable1">二加温度</div>
          </div>
          <div class="fl centermain-row3 centermain-row3-jg">
            <div
              class="centermain-row3-lable"
              @click="
                toIpt(
                  infoList.KH_T41SP,
                  '一加温度',
                  'KH_T41SP',
                  infoList.KH_T41SP_node_string
                )
              "
            >
              {{ 'KH_T41SP' in infoList?infoList.KH_T41SP :0}}
            </div>
            <div class="centermain-row3-lable1">一加温度</div>
          </div>
          <div class="fl centermain-row3 centermain-row3-jg">
            <div
              class="centermain-row3-lable"
              @click="
                toIpt(
                  infoList.KHTRZ,
                  '热装温度',
                  'KHTRZ',
                  infoList.KHTRZ_node_string
                )
              "
            >
              {{ 'KHTRZ' in infoList?infoList.KHTRZ :0}}
            </div>
            <div class="centermain-row3-lable1">热装温度</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右边 -->
    <div class="fl righttmain">
      <div class="rightmain-top">
        <div class="centermain-row1">自控率%</div>
        <div class="centermain-top2">
          <div class="centermain-top2-col1 fl">煤气压力</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL9',
                infoList.ZKL9_node_string,
                'ZKL9_J1',
                '煤气总管压力自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL9,
                'ZKL9',
                infoList.ZKL9_node_string,
                'ZKL9_J1'
              )
            "
          >
            {{ 'ZKL9' in infoList?infoList.ZKL9 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(infoList.EN9, '煤气压力', 'EN9', infoList.EN9_node_string)
            "
          >
            {{ 'EN9' in infoList?infoList.EN9 ? "统计使能" : "统计切除" :0}}
          </div>
          <div class="centermain-top2-col2 fl">三加煤烟</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL4',
                infoList.ZKL4_node_string,
                'ZKL4_J1',
                '三加热段煤烟自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL4,
                'ZKL4',
                infoList.ZKL4_node_string,
                'ZKL4_J1'
              )
            "
          >
            {{ 'ZKL4' in infoList?infoList.ZKL4 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(infoList.EN4, '三加煤烟', 'EN4', infoList.EN4_node_string)
            "
          >
            {{ 'EN4' in infoList?infoList.EN4 ? "统计使能" : "统计切除" :0}}
          </div>
        </div>
        <div class="centermain-top2">
          <div class="centermain-top2-col1 fl">空气压力</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL10',
                infoList.ZKL10_node_string,
                'ZKL10_J1',
                '鼓风自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL10,
                'ZKL10',
                infoList.ZKL10_node_string,
                'ZKL10_J1'
              )
            "
          >
            {{ 'ZKL10' in infoList?infoList.ZKL10 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(
                infoList.EN10,
                '空气压力',
                'EN10',
                infoList.EN10_node_string
              )
            "
          >
            {{ 'EN10' in infoList?infoList.EN10 ? "统计使能" : "统计切除" :0}}
          </div>
          <div class="centermain-top2-col2 fl">二加空气</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL31',
                infoList.ZKL31_node_string,
                'ZKL31_J1',
                '二加热段空气自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL31,
                'ZKL31',
                infoList.ZKL31_node_string,
                'ZKL31_J1'
              )
            "
          >
            {{ 'ZKL31' in infoList?infoList.ZKL31 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(
                infoList.EN31,
                '二加空气',
                'EN31',
                infoList.EN31_node_string
              )
            "
          >
            {{ 'EN31' in infoList?infoList.EN31 ? "统计使能" : "统计切除" :0}}
          </div>
        </div>
        <div class="centermain-top2">
          <div class="centermain-top2-col1 fl">炉膛压力</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL20',
                infoList.ZKL20_node_string,
                'ZKL20_J1',
                '炉膛压力自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL20,
                'ZKL20',
                infoList.ZKL20_node_string,
                'ZKL20_J1'
              )
            "
          >
            {{ 'ZKL20' in infoList?infoList.ZKL20 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(
                infoList.EN20,
                '炉膛压力',
                'EN20',
                infoList.EN20_node_string
              )
            "
          >
            {{ 'EN20' in infoList?infoList.EN20 ? "统计使能" : "统计切除" :0}}
          </div>
          <div class="centermain-top2-col2 fl">二加煤气</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL32',
                infoList.ZKL32_node_string,
                'ZKL32_J1',
                '二加热段煤气自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL32,
                'ZKL32',
                infoList.ZKL32_node_string,
                'ZKL32_J1'
              )
            "
          >
            {{ 'ZKL32' in infoList?infoList.ZKL32 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(
                infoList.EN32,
                '二加煤气',
                'EN32',
                infoList.EN32_node_string
              )
            "
          >
            {{ 'EN32' in infoList?infoList.EN32 ? "统计使能" : "统计切除" :0}}
          </div>
        </div>
        <div class="centermain-top2">
          <div class="centermain-top2-col1 fl">均热空气</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL11',
                infoList.ZKL11_node_string,
                'ZKL11_J1',
                '均热段空气自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL11,
                'ZKL11',
                infoList.ZKL11_node_string,
                'ZKL11_J1'
              )
            "
          >
            {{ 'ZKL11' in infoList?infoList.ZKL11 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(
                infoList.EN11,
                '均热空气',
                'EN11',
                infoList.EN11_node_string
              )
            "
          >
            {{ 'EN11' in infoList?infoList.EN11 ? "统计使能" : "统计切除" :0}}
          </div>
          <div class="centermain-top2-col2 fl">二加空烟</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL5',
                infoList.ZKL5_node_string,
                'ZKL5_J1',
                '二加热段空烟自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL5,
                'ZKL5',
                infoList.ZKL5_node_string,
                'ZKL5_J1'
              )
            "
          >
            {{ 'ZKL5' in infoList?infoList.ZKL5 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(infoList.EN5, '二加空烟', 'EN5', infoList.EN5_node_string)
            "
          >
            {{ 'EN5' in infoList?infoList.EN5 ? "统计使能" : "统计切除" :0}}
          </div>
        </div>
        <div class="centermain-top2">
          <div class="centermain-top2-col1 fl">均热煤气</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL12',
                infoList.ZKL12_node_string,
                'ZKL12_J1',
                '均热段煤气自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL12,
                'ZKL12',
                infoList.ZKL12_node_string,
                'ZKL12_J1'
              )
            "
          >
            {{ 'ZKL12' in infoList?infoList.ZKL12 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(
                infoList.EN12,
                '均热煤气',
                'EN12',
                infoList.EN12_node_string
              )
            "
          >
            {{ 'EN12' in infoList?infoList.EN12 ? "统计使能" : "统计切除" :0}}
          </div>
          <div class="centermain-top2-col2 fl">二加煤烟</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL6',
                infoList.ZKL6_node_string,
                'ZKL6_J1',
                '二加热段煤烟自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL6,
                'ZKL6',
                infoList.ZKL6_node_string,
                'ZKL6_J1'
              )
            "
          >
            {{ 'ZKL6' in infoList?infoList.ZKL6 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(infoList.EN6, '二加煤烟', 'EN6', infoList.EN6_node_string)
            "
          >
            {{ 'EN6' in infoList?infoList.EN6 ? "统计使能" : "统计切除" :0}}
          </div>
        </div>
        <div class="centermain-top2">
          <div class="centermain-top2-col1 fl">均热空烟</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL1',
                infoList.ZKL1_node_string,
                'ZKL1_J1',
                '均热段空烟自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL1,
                'ZKL1',
                infoList.ZKL1_node_string,
                'ZKL1_J1'
              )
            "
          >
            {{ 'ZKL1' in infoList?infoList.ZKL1 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(infoList.EN1, '均热空烟', 'EN1', infoList.EN1_node_string)
            "
          >
            {{ 'EN1' in infoList?infoList.EN1 ? "统计使能" : "统计切除" :0}}
          </div>
          <div class="centermain-top2-col2 fl">一加空气</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL41',
                infoList.ZKL41_node_string,
                'ZKL41_J1',
                '一加热段空气自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL41,
                'ZKL41',
                infoList.ZKL41_node_string,
                'ZKL41_J1'
              )
            "
          >
            {{ 'ZKL41' in infoList?infoList.ZKL41 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(
                infoList.EN41,
                '一加空气',
                'EN41',
                infoList.EN41_node_string
              )
            "
          >
            {{ 'EN41' in infoList?infoList.EN41 ? "统计使能" : "统计切除" :0}}
          </div>
        </div>
        <div class="centermain-top2">
          <div class="centermain-top2-col1 fl">均热煤烟</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL2',
                infoList.ZKL2_node_string,
                'ZKL2_J1',
                '均热段煤烟自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL2,
                'ZKL2',
                infoList.ZKL2_node_string,
                'ZKL2_J1'
              )
            "
          >
            {{ 'ZKL2' in infoList?infoList.ZKL2 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(infoList.EN2, '均热煤烟', 'EN2', infoList.EN2_node_string)
            "
          >
            {{ 'EN2' in infoList?infoList.EN2 ? "统计使能" : "统计切除" :0}}
          </div>
          <div class="centermain-top2-col2 fl">一加煤气</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL42',
                infoList.ZKL42_node_string,
                'ZKL42_J1',
                '一加热段煤气自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL42,
                'ZKL42',
                infoList.ZKL42_node_string,
                'ZKL42_J1'
              )
            "
          >
            {{ 'ZKL42' in infoList?infoList.ZKL42 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(
                infoList.EN42,
                '一加煤气',
                'EN42',
                infoList.EN42_node_string
              )
            "
          >
            {{ 'EN42' in infoList?infoList.EN42 ? "统计使能" : "统计切除" :0}}
          </div>
        </div>
        <div class="centermain-top2">
          <div class="centermain-top2-col1 fl">三加空气</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL21',
                infoList.ZKL21_node_string,
                'ZKL21_J1',
                '三加热段空气自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL21,
                'ZKL21',
                infoList.ZKL21_node_string,
                'ZKL21_J1'
              )
            "
          >
            {{ 'ZKL21' in infoList?infoList.ZKL21 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(
                infoList.EN21,
                '三加空气',
                'EN21',
                infoList.EN21_node_string
              )
            "
          >
            {{ 'EN21' in infoList?infoList.EN21 ? "统计使能" : "统计切除" :0}}
          </div>
          <div class="centermain-top2-col2 fl">一加空烟</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL7',
                infoList.ZKL7_node_string,
                'ZKL7_J1',
                '一加热段空烟自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL7,
                'ZKL7',
                infoList.ZKL7_node_string,
                'ZKL7_J1'
              )
            "
          >
            {{ 'ZKL7' in infoList?infoList.ZKL7 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(infoList.EN7, '一加空烟', 'EN7', infoList.EN7_node_string)
            "
          >
            {{ 'EN7' in infoList?infoList.EN7 ? "统计使能" : "统计切除" :0}}
          </div>
        </div>
        <div class="centermain-top2">
          <div class="centermain-top2-col1 fl">三加煤气</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL22',
                infoList.ZKL22_node_string,
                'ZKL22_J1',
                '三加热段煤气自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL22,
                'ZKL22',
                infoList.ZKL22_node_string,
                'ZKL22_J1'
              )
            "
          >
            {{ 'ZKL22' in infoList?infoList.ZKL22 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(
                infoList.EN22,
                '三加煤气',
                'EN22',
                infoList.EN22_node_string
              )
            "
          >
            {{ 'EN22' in infoList?infoList.EN22 ? "统计使能" : "统计切除" :0}}
          </div>
          <div class="centermain-top2-col2 fl">一加煤烟</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL8',
                infoList.ZKL8_node_string,
                'ZKL8_J1',
                '一加热段煤烟自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL8,
                'ZKL8',
                infoList.ZKL8_node_string,
                'ZKL8_J1'
              )
            "
          >
            {{ 'ZKL8' in infoList?infoList.ZKL8 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(infoList.EN8, '一加煤烟', 'EN8', infoList.EN8_node_string)
            "
          >
            {{ 'EN8' in infoList?infoList.EN8 ? "统计使能" : "统计切除" :0}}
          </div>
        </div>
        <div class="centermain-top2">
          <div class="centermain-top2-col1 fl">三加空烟</div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toCompon(
                2,
                'ZKL3',
                infoList.ZKL3_node_string,
                'ZKL3_J1',
                '三加热段空烟自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZKL3,
                'ZKL3',
                infoList.ZKL3_node_string,
                'ZKL3_J1'
              )
            "
          >
            {{ 'ZKL3' in infoList?infoList.ZKL3 :0}}
          </div>
          <div
            class="centermain-top2-col1 fl"
            @click="
              toIpt(infoList.EN3, '三加空烟', 'EN3', infoList.EN3_node_string)
            "
          >
            {{ 'EN3' in infoList?infoList.EN3 ? "统计使能" : "统计切除" :0}}
          </div>
        </div>
      </div>
      <div class="rightmain-center">
        <div class="centermain-row1">综合统计参数</div>
        <div class="center1 fl"></div>
        <div class="center2 fl">
          <div
            class="lable11"
            @click="
              toCompon(
                2,
                'ZZKL',
                infoList.ZZKL_node_string,
                'ZZKL_J1',
                '总自控率'
              )
            "
            @dblclick="
              Cclick(
                infoList.ZZKL,
                'ZZKL',
                infoList.ZZKL_node_string,
                'ZZKL_J1'
              )
            "
          >
            {{ 'ZZKL' in infoList?infoList.ZZKL :0}}
          </div>
          <div>总自控率</div>
        </div>
        <div class="center3 fl">
          <div
            class="lable22"
            @click="
              toCompon(
                2,
                'CEI_LEVEL',
                infoList.CEI_LEVEL_node_string,
                'CEI_PV_J1',
                '综合运行评价指标'
              )
            "
            @dblclick="
              Cclick(
                infoList.CEI_LEVEL,
                'CEI_LEVEL',
                infoList.CEI_LEVEL_node_string,
                'CEI_PV_J1'
              )
            "
          >
            {{ 'CEI_LEVEL' in infoList?infoList.CEI_LEVEL :0}}
          </div>
          <div>综合性能指标</div>
        </div>
      </div>
      <div class="rightmain-bottom">
        <div class="centermain-row1">数据统计</div>
        <div class="centermain-xxx1">
          <div class="fl">
            <div class="centermain-row2-wid">经济性</div>
            <div
              class="centermain-row2-wid"
              @click="
                toCompon(
                  2,
                  'KHJJXB',
                  infoList.KHJJXB_node_string,
                  'KHJJXB',
                  '经济性判据'
                )
              "
              @dblclick="
                Cclick(
                  infoList.KHJJXB,
                  'KHJJXB',
                  infoList.KHJJXB_node_string,
                  'KHJJXB'
                )
              "
            >
              {{ 'KHJJXB' in infoList?infoList.KHJJXB :0}}
            </div>
          </div>
          <div class="fl jgx">
            <div class="centermain-row2-wid">运行效果</div>
            <div
              class="centermain-row2-wid"
              @click="
                toCompon(
                  2,
                  'KHYXXGPJ',
                  infoList.KHYXXGPJ_node_string,
                  'KHYXXGPJ',
                  '运行效果综合判据'
                )
              "
              @dblclick="
                Cclick(
                  infoList.KHYXXGPJ,
                  'KHYXXGPJ',
                  infoList.KHYXXGPJ_node_string,
                  'KHYXXGPJ'
                )
              "
            >
              {{ 'KHYXXGPJ' in infoList?infoList.KHYXXGPJ :0}}
            </div>
          </div>
          <div class="fl jgx">
            <div class="centermain-row2-wid">煤气时间单耗</div>
            <div
              class="centermain-row2-wid"
              @click="
                toCompon(
                  2,
                  'MQSJDH',
                  infoList.MQSJDH_node_string,
                  'MQSJDH',
                  '煤气时间单耗'
                )
              "
              @dblclick="
                Cclick(
                  infoList.MQSJDH,
                  'MQSJDH',
                  infoList.MQSJDH_node_string,
                  'MQSJDH'
                )
              "
            >
              {{ 'MQSJDH' in infoList?infoList.MQSJDH :0}}
            </div>
          </div>
        </div>
        <div class="centermain-xxx2">
          <div class="fl lable33">班清零时间1：</div>
          <div class="fl lable33">
            <div
              class="fl kdwd"
              @click="
                toIpt(
                  infoList.BQL_H1,
                  '小时',
                  'BQL_H1',
                  infoList.BQL_H1_node_string
                )
              "
            >
              {{ 'BQL_H1' in infoList?infoList.BQL_H1 :0}}:
            </div>
            <div
              class="fl"
              @click="
                toIpt(
                  infoList.BQL_M1,
                  '分钟',
                  'BQL_M1',
                  infoList.BQL_M1_node_string
                )
              "
            >
              {{ 'BQL_M1' in infoList?infoList.BQL_M1 :0}}
            </div>
          </div>
          <div class="fl lable33">班清零时间2：</div>
          <div class="fl lable33">
            <div
              class="fl kdwd"
              @click="
                toIpt(
                  infoList.BQL_H2,
                  '小时',
                  'BQL_H2',
                  infoList.BQL_H2_node_string
                )
              "
            >
              {{ 'BQL_H2' in infoList?infoList.BQL_H2 :0}}:
            </div>
            <div
              class="fl"
              @click="
                toIpt(
                  infoList.BQL_M2,
                  '分钟',
                  'BQL_M2',
                  infoList.BQL_M2_node_string
                )
              "
            >
              {{ 'BQL_M2' in infoList?infoList.BQL_M2 :0}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <Historical
      v-if="isHshow"
      @sendStatus="isClose1"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
    <JRLcysz
      v-if="flag == 4 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    >
    </JRLcysz>
  </div>
</template>
<script>
import Historical from "@/components/Historicaljrl";
import inputVal from "@/components/inputVal"; //输入框组件
import JRLcysz from "@/components/JRL_cysz.vue"; //常用设置组件
export default {
  name: "index",
  components: {
    Historical,
    inputVal,
    JRLcysz,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      headerStyle: {
        "font-weight": "500",
        "font-size": "0.9vw",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      flag: 0,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      config: {
        data: [10],
        shape: "round",
        colors: ["#43ff44", "#43ff44"],
        waveOpacity: "1",
        waveNum: "50",
        formatter: "{value}",
      },
      rightHeight: "",
    };
  },

  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.$bus.$on("sendMsg", (msg, msg1) => {
      // 兄弟组件发送来的消息
      this.flag = msg;
    });
  },
  computed: {
    gzlj1: function () {
      return this.infoList.KHGGLJB * this.infoList.GPDZ;
    },
    gzlj2: function () {
      return this.infoList.KHGGLJT * this.infoList.GPDZ;
    },
    gzlj3: function () {
      return this.infoList.KHGGLJY * this.infoList.GPDZ;
    },
    gzlj4: function () {
      return this.infoList.KHGGLJY * this.infoList.GPDZ;
    },
  },
  mounted() {},
  methods: {
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无操作权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
    },
    closeCompon() {
      this.$emit("sendStatus", "Param01", false);
    },
    isClose() {
      (this.flag = 1), this.$bus.$emit("footersendMsg", 1);
    },
    isClose1() {
      this.isHshow = false;
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            return (
              (this.isMshow = true),
              (this.Manualname = name),
              (this.Manualnode = name2),
              (this.ManualAname = name3),
              (this.Manualtitname = titname)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = name4);
        }
      }, 300);
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.jrlEvaluate {
  width: 96vw;
  height: 100%;
  margin-left: 2.2vw;
  //  background: #e9f7ff;
  background-image: linear-gradient(#0e3449 60%, #0e3146 61%, #0c2a38 100%),
    linear-gradient(#0e3449, #0e3449);
  background-blend-mode: normal, normal;
  overflow: hidden;
  //  cursor:move;
  border-radius: 5px;

  .ziti {
    font-size: 0.9vw;
  }

  .zhuangshi1 {
    width: 30vw;
    height: 10vh;
    background-color: #0ef7ff;
    opacity: 0.66;
  }

  .fl {
    float: left;
  }

  .fr {
    float: right;
  }

  .title-hang {
    height: 5vh;
    width: 96vw;
    font-family: MicrosoftYaHei;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
  }

  .conter1 {
    margin-left: 1vw;
    width: 17vw;
    height: 29vh;
    line-height: 29vh;
    background-image: url("~@/assets/images/rfl_evaluate01.png");
    background-size: 100% 100%;
  }

  .conter {
    height: 29vh;
    width: 100%;
    margin-top: 3vh;
  }

  .conter1 {
    margin-left: 1vw;
    width: 17vw;
    height: 29vh;
    line-height: 29vh;
    background-image: url("~@/assets/images/rfl_evaluate01.png");
    background-size: 100% 100%;
  }

  .conter2 {
    margin-left: 1vw;
    width: 29vw;
    height: 29vh;
    // line-height: 29vh;
    background-image: url("~@/assets/images/rfl_evaluate03.png");
    background-size: 100% 100%;
  }

  .title1 {
    width: 8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #ffffff;
    margin-top: 1vh;
    margin-left: 1vw;
  }

  .divcei {
    margin-top: 3vh;
    margin-left: 4vw;
    width: 9vw;
    height: 20vh;
    line-height: 20vh;
    background-image: url("~@/assets/images/rfl_evaluate02.png");
    background-size: 100% 100%;
    font-size: 2vw;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0vw;
    color: #ffffff;
    text-align: center;
  }

  .divjjx {
    margin-top: 3vh;
    margin-left: 4vw;
    width: 9vw;
    height: 26vh;
    line-height: 26vh;
    font-size: 1.6vw;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0vw;
    color: #ffffff;
    text-align: center;
  }

  .zhuangshizb {
    width: 14vw;
    height: 3vh;
    background-image: url("~@/assets/images/rfl_evaluate06.png");
    background-size: 100% 100%;
    margin-left: 3.5vw;
    margin-top: 1.6vh;
  }

  .zhuangshizj {
    // background-color: pink;
    width: 68vw;
    height: 5vh;
    line-height: 5vh;
    margin-top: 1vh;
    text-align: center;
    // background-image: url("~@/assets/images/rfl_evaluate05.png");
    background-size: 100% 100%;
    // margin-left: 1.5vw;
    font-family: MicrosoftYaHei;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0vh;
    color: #0ef7ff;
  }

  .zhuangshiyb {
    width: 14vw;
    height: 1.5vw;
    background-image: url("~@/assets/images/rfl_evaluate04.png");
    background-size: 100% 100%;
    // margin-left: 2vw;
    margin-top: 1.6vh;
  }

  .title-hang {
    width: 100%;
  }

  .mainall {
    margin-top: 3vh;
  }

  .leftmain {
    width: 33vw;
    height: 74vh;
    // margin-left: 1vw;
    //  border: 1px salmon solid;
    background-image: url("~@/assets/images/jrl-evaluate.png");
    background-size: 100% 100%;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0vw;
  }

  .leftmain-row1 {
    margin-left: 2vw;
    margin-top: 1vh;
    color: #ffffff;
  }

  .centermain-xxx1 {
    margin-left: 1vw;
    margin-top: 2vh;
    color: #ffffff;
  }

  .leftmain-row2-col {
    width: 7vw;
    height: 2vh;
    text-align: center;
  }

  .centermain-row2 {
    width: 8vw;
    height: 50vh;
    margin-left: 3vw;
    margin-top: 3vh;
  }

  .centermain-row3 {
    width: 8vw;
    height: 6.5vh;
    background-image: url("~@/assets/images/jrl-evaluate3.png");
    background-size: 100% 100%;
    // margin-left:2vw;
  }

  .centermain-row3-lable {
    width: 5vw;
    height: 2vh;
    margin-left: 5vw;
    color: #00ffb4;
    cursor: pointer;
  }

  .centermain-row3-lable1 {
    width: 5vw;
    height: 2vh;
    margin-left: 3.5vw;
    margin-top: 0.8vh;
    color: #70c2d6;
  }

  .centermain-row2-jg {
    margin-left: 5vw;
  }

  .centermain-row3-jg {
    margin-top: 1vh;
  }

  .leftmain-row2-col1 {
    width: 6vw;
    height: 2vh;
    text-align: center;
  }

  .leftmain-row3-col {
    width: 8vw;
    height: 5.5vh;
    text-align: center;
    line-height: 5.5vh;
  }

  .leftmain-row3-col1 {
    width: 6vw;
    height: 5.5vh;
    text-align: center;
    line-height: 5.5vh;
    cursor: pointer;
  }

  .leftmain-row3 {
    color: #70c2d6;
    margin-top: 5vh;
  }

  .leftmain-row2 {
    color: #70c2d6;
    margin-top: 1vh;
  }

  .leftmain-row2-jg {
    margin-top: 10vh;
  }

  .center-main {
    width: 28vw;
    height: 74vh;
    font-size: 1vw;
  }

  .righttmain {
    font-size: 1vw;
  }

  .center-main-top {
    width: 28vw;
    height: 20vh;
    // margin-top:2vh;
    margin-left: 1vw;
    background-image: url("~@/assets/images/jrl-evaluate1.png");
    background-size: 100% 100%;
  }

  .center-main-top1 {
    width: 8vw;
    height: 4vh;
    background-color: #bb1d36;
    border-radius: 0vh;
    text-align: center;
    color: #fff;
  }

  .center-main-top2 {
    width: 8vw;
    height: 4vh;
    background-color: #0085eb40;
    border-radius: 0vh;
    border: solid 0vh #0085eb;
    margin-left: 0.3vw;
    text-align: center;
    color: #fff;
  }

  .center-main-top3 {
    width: 8vw;
    height: 4vh;
    background-color: #00ff8a20;
    border-radius: 0vh;
    border: solid 0vh #00fc98;
    margin-left: 0.3vw;
    text-align: center;
    color: #fff;
  }

  .kdwd {
    width: 3vw;
  }

  .center-main-top-col {
    padding-top: 1vh;
    margin-left: 2vw;
  }

  .rightmain-top {
    margin-left: 2vw;
    width: 33vw;
    height: 35vh;
    background-image: url("~@/assets/images/jrl-evaluate2.png");
    background-size: 100% 100%;
  }

  .center-main-top1-col {
    margin-left: 2vw;
    width: 12vw;
    text-align: center;
  }

  .col1 {
    color: #70c2d6;
    height: 3.5vh;
  }

  .col2 {
    color: #fff;
    height: 3.5vh;
    cursor: pointer;
  }

  .center-main-bottom {
    width: 28vw;
    height: 52vh;
    margin-top: 2vh;
    margin-left: 1vw;
    background-image: url("~@/assets/images/jrl-evaluate2.png");
    background-size: 100% 100%;
  }

  .centermain-row1 {
    color: #fff;
    margin-left: 1vw;
  }

  .centermain-top2 {
    font-size: 1vw;
    margin-left: 0.5vw;
  }

  .centermain-top2-col1 {
    width: 5vw;
    height: 3vh;
    text-align: center;
    color: #70c2d6;
    cursor: pointer;
  }

  .centermain-top2-col2 {
    width: 5vw;
    height: 3vh;
    text-align: center;
    color: #70c2d6;
    margin-left: 2vw;
  }

  .rightmain-center {
    margin-left: 2vw;
    width: 33vw;
    height: 18.5vh;
    margin-top: 1vh;
    background-image: url("~@/assets/images/jrl-evaluate6.png");
    background-size: 100% 100%;
  }

  .rightmain-bottom {
    margin-left: 2vw;
    width: 33vw;
    height: 18.5vh;
    margin-top: 1vh;
    background-image: url("~@/assets/images/jrl-evaluate7.png");
    background-size: 100% 100%;
  }

  .center1 {
    margin-left: 2vw;
    width: 5vw;
    height: 11vh;
    margin-top: 1vh;
    background-image: url("~@/assets/images/jrl-evaluate9.png");
    background-size: 100% 100%;
  }

  .center2 {
    margin-left: 1vw;
    width: 7vw;
    height: 9vh;
    margin-top: 1vh;
    background-image: url("~@/assets/images/jrl-evaluate8.png");
    background-size: 100% 100%;
    text-align: center;
    font-size: 1.1vw;
    color: #8aeaff;
  }

  .lable11 {
    font-size: 1.5vw;
    height: 4vh;
    cursor: pointer;
  }

  .center3 {
    margin-left: 5vw;
    width: 10vw;
    height: 12vh;
    margin-top: 1vh;
    background-image: url("~@/assets/images/jrl-evaluate10.png");
    background-size: 100% 100%;
    text-align: center;
    font-size: 1.1vw;
    color: #8aeaff;
  }

  .lable22 {
    font-size: 1.5vw;
    height: 5vh;
    cursor: pointer;
  }

  .lable33 {
    font-size: 1vw;
    height: 2vh;
    width: 8vw;
    color: #70c2d6;
    text-align: center;
    margin-top: 1vh;
    cursor: pointer;
  }

  .centermain-row2 {
    color: #70c2d6;
  }

  .centermain-row2-wid {
    width: 8vw;
    height: 3vh;
    text-align: center;
    color: #70c2d6;
    font-size: 1.1vw;
    cursor: pointer;
  }

  .jgx {
    margin-left: 3vw;
  }
}
</style>
