var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"total drag",attrs:{"id":"RqAirSupply"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title-hang flex"},[_c('div',{staticClass:"zhuangshizb"}),_c('div',{staticClass:"zhuangshizj"},[_vm._v(_vm._s(_vm.projectData.project)+"_4_1")]),_c('div',{staticClass:"zhuangshiyb"},[_c('div',{staticClass:"icon",on:{"click":_vm.closeCompon}})])])]),_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"first"},[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"table table1 flex"},[_c('div',{staticClass:"words"},[_vm._v("GLPV3")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'GLPV3',
                  _vm.infoList.GLPV3_node_string,
                  'GLPV3_NG3',
                  '三层高炉没气量虚拟值'
                )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.GLPV3, 'GLPV3', 'GLPV3', _vm.infoList.GLPV3_node_string)}}},[_vm._v(" "+_vm._s('GLPV3' in _vm.infoList?_vm.infoList.GLPV3 :0)+" ")])]),_c('div',{staticClass:"star"},[_vm._v("*")]),_c('div',{staticClass:"table table2 flex"},[_c('div',{staticClass:"words"},[_vm._v("YHFMB11")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string,
                  'YHFMB_B11_NG3',
                  '优化风煤比'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.YHFMB_B11,
                  'YHFMB11',
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string
                )}}},[_vm._v(" "+_vm._s('YHFMB_B11' in _vm.infoList?_vm.infoList.YHFMB_B11 :0)+" ")])]),_c('div',{staticClass:"table table3 flex"},[_c('div',{staticClass:"words"},[_vm._v("SFSP3")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'SFSP3',
                  _vm.infoList.SFSP3_node_string,
                  'SFSP3_NG3',
                  '三层空气量设定值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.SFSP3,
                  'SFSP3',
                  'SFSP3',
                  _vm.infoList.SFSP3_node_string
                )}}},[_vm._v(" "+_vm._s('SFSP3' in _vm.infoList?_vm.infoList.SFSP3 :0)+" ")])]),_c('div',{staticClass:"button table4",style:({
              background:
                _vm.infoList.XK10 && !_vm.infoList.XK10.RM
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK10
                ? _vm.toDetail(
                    1,
                    'XK10',
                    _vm.infoList.XK10_node_string,
                    '',
                    '三层空气层操先控调节'
                  )
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button table5",style:({
              background:
                _vm.infoList.MAN17.RM && _vm.infoList.MAN17.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN17
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN17',
                    'MAN17_AV_NG3',
                    '三层操空气手操器MAN17',
                    'KF_117_B',
                    _vm.infoList.MAN17_node_string,
                    'KF_117_NG3'
                  )
                : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"table table6 flex"},[_c('div',{staticClass:"words"},[_vm._v("B02-A03")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.MAN17_node_string,
                  'MAN17_AV_NG3',
                  '三层操空气手操器',
				  'MAN17'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.MAN17.AV,
                  'B02-A03',
                  'AV',
                  _vm.infoList.MAN17_node_string
                )}}},[_vm._v(" "+_vm._s('MAN17' in _vm.infoList?_vm.infoList.MAN17.AV :0)+" ")])])]),_c('div',{staticClass:"line2 flex"},[_c('div',{staticClass:"table1 flex"},[_c('div',{staticClass:"words"},[_vm._v("ZLPV3")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZLPV3',
                  _vm.infoList.ZLPV3_node_string,
                  'ZLPV3_NG3',
                  '三层转炉没气量虚拟值'
                )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.ZLPV3, 'ZLPV3', 'ZLPV3', _vm.infoList.ZLPV3_node_string)}}},[_vm._v(" "+_vm._s('ZLPV3' in _vm.infoList?_vm.infoList.ZLPV3 :0)+" ")])]),_c('div',{staticClass:"star"},[_vm._v("*")]),_vm._m(1),_c('div',{staticClass:"star"},[_vm._v("*")]),_c('div',{staticClass:"table3 flex"},[_c('div',{staticClass:"words"},[_vm._v("YHFMB11")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string,
                  'YHFMB_B11_NG3',
                  '优化风煤比'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.YHFMB_B11,
                  'YHFMB11',
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string
                )}}},[_vm._v(" "+_vm._s('YHFMB_B11' in _vm.infoList?_vm.infoList.YHFMB_B11 :0)+" ")])]),_c('div',{staticClass:"table table4 flex"},[_c('div',{staticClass:"words"},[_vm._v("SFPV3")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'SFPV3',
                  _vm.infoList.SFPV3_node_string,
                  'SFPV3_NG3',
                  '三层空气量设定值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.SFPV3,
                  'SFPV3',
                  'SFPV3',
                  _vm.infoList.SFPV3_node_string
                )}}},[_vm._v(" "+_vm._s('SFPV3' in _vm.infoList?_vm.infoList.SFPV3 :0)+" ")])]),_c('div',{staticClass:"buttonk table4",style:({
              background:
                _vm.infoList.QK5 && !_vm.infoList.QK5.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.QK5
                ? _vm.toDetail(2, 'QK5', _vm.infoList.QK5_node_string, '', '送风三层空气层操前馈')
                : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"line2 flex"},[_c('div',{staticClass:"table1 flex"},[_c('div',{staticClass:"words"},[_vm._v("JLPV3")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'JLPV3',
                  _vm.infoList.JLPV3_node_string,
                  'JLPV3_NG3',
                  '三层焦炉没气量虚拟值'
                )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.JLPV3, 'JLPV3', 'JLPV3', _vm.infoList.JLPV3_node_string)}}},[_vm._v(" "+_vm._s('JLPV3' in _vm.infoList?_vm.infoList.JLPV3 :0)+" ")])]),_c('div',{staticClass:"star"},[_vm._v("*")]),_vm._m(2),_c('div',{staticClass:"star"},[_vm._v("*")]),_c('div',{staticClass:"table3 flex"},[_c('div',{staticClass:"words"},[_vm._v("YHFMB11")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string,
                  'YHFMB_B11_NG3',
                  '优化风煤比'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.YHFMB_B11,
                  'YHFMB11',
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string
                )}}},[_vm._v(" "+_vm._s('YHFMB_B11' in _vm.infoList?_vm.infoList.YHFMB_B11 :0)+" ")])])])]),_c('div',{staticClass:"first second"},[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"table table1 flex"},[_c('div',{staticClass:"words"},[_vm._v("GLPV2")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'GLPV2',
                  _vm.infoList.GLPV2_node_string,
                  'GLPV2_NG3',
                  '二层高炉没气量虚拟值'
                )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.GLPV2, 'GLPV2', 'GLPV2', _vm.infoList.GLPV2_node_string)}}},[_vm._v(" "+_vm._s('GLPV2' in _vm.infoList?_vm.infoList.GLPV2 :0)+" ")])]),_c('div',{staticClass:"star"},[_vm._v("*")]),_c('div',{staticClass:"table table2 flex"},[_c('div',{staticClass:"words"},[_vm._v("YHFMB11")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(2, 'YHFMB_B11', _vm.infoList.YHFMB_B11_node_string, 'YHFMB_B11_NG3')},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.YHFMB_B11,
                  'YHFMB11',
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string
                )}}},[_vm._v(" "+_vm._s('YHFMB_B11' in _vm.infoList?_vm.infoList.YHFMB_B11 :0)+" ")])]),_c('div',{staticClass:"table table3 flex"},[_c('div',{staticClass:"words"},[_vm._v("SFSP2")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'SFSP2',
                  _vm.infoList.SFSP2_node_string,
                  'SFSP2_NG3',
                  '二层空气量设定值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.SFSP2,
                  'SFSP2',
                  'SFSP2',
                  _vm.infoList.SFSP2_node_string
                )}}},[_vm._v(" "+_vm._s('SFSP2' in _vm.infoList?_vm.infoList.SFSP2 :0)+" ")])]),_c('div',{staticClass:"button table4",style:({
              background:
                _vm.infoList.XK9 && !_vm.infoList.XK9.RM
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK9
                ? _vm.toDetail(1, 'XK9', _vm.infoList.XK9_node_string, '', '二层空气层操先控调节')
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button table5",style:({
              background:
                _vm.infoList.MAN16.RM && _vm.infoList.MAN16.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN16
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN16',
                    'MAN16_RM_NG3',
                    '二层操空气手操器MAN16',
                    'KF_116_B',
                    _vm.infoList.MAN16_node_string,
                    'KF_116_NG3'
                  )
                : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"table table6 flex"},[_c('div',{staticClass:"words"},[_vm._v("B02-A02")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.MAN16_node_string,
                  'MAN16_AV_NG3',
                  '二层操空气手操器',
				  'MAN16'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.MAN16.AV,
                  'B02-A02',
                  'AV',
                  _vm.infoList.MAN16_node_string
                )}}},[_vm._v(" "+_vm._s('MAN16' in _vm.infoList?_vm.infoList.MAN16.AV :0)+" ")])])]),_c('div',{staticClass:"line2 flex"},[_c('div',{staticClass:"table1 flex"},[_c('div',{staticClass:"words"},[_vm._v("ZLPV2")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZLPV2',
                  _vm.infoList.ZLPV2_node_string,
                  'ZLPV2_NG3',
                  '二层转炉没气量虚拟值'
                )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.ZLPV2, 'ZLPV2', 'ZLPV2', _vm.infoList.ZLPV2_node_string)}}},[_vm._v(" "+_vm._s('ZLPV2' in _vm.infoList?_vm.infoList.ZLPV2 :0)+" ")])]),_c('div',{staticClass:"star"},[_vm._v("*")]),_vm._m(3),_c('div',{staticClass:"star"},[_vm._v("*")]),_c('div',{staticClass:"table3 flex"},[_c('div',{staticClass:"words"},[_vm._v("YHFMB11")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string,
                  'YHFMB_B11_NG3',
                  '优化风煤比'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.YHFMB_B11,
                  'YHFMB11',
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string
                )}}},[_vm._v(" "+_vm._s('YHFMB_B11' in _vm.infoList?_vm.infoList.YHFMB_B11 :0)+" ")])]),_c('div',{staticClass:"table table4 flex"},[_c('div',{staticClass:"words"},[_vm._v("SFPV2")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'SFPV1',
                  _vm.infoList.SFPV1_node_string,
                  'SFPV1_NG3',
                  '一层空气量设定值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.SFPV1,
                  'SFPV2',
                  'SFPV1',
                  _vm.infoList.SFPV1_node_string
                )}}},[_vm._v(" "+_vm._s('SFPV1' in _vm.infoList?_vm.infoList.SFPV1 :0)+" ")])]),_c('div',{staticClass:"buttonk table4",style:({
              background:
                _vm.infoList.QK4 && !_vm.infoList.QK4.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.QK4
                ? _vm.toDetail(2, 'QK4', _vm.infoList.QK4_node_string, '', '送风二层空气层操前馈')
                : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"line2 flex"},[_c('div',{staticClass:"table1 flex"},[_c('div',{staticClass:"words"},[_vm._v("JLPV2")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'JLPV2',
                  _vm.infoList.JLPV2_node_string,
                  'JLPV2_NG3',
                  '二层焦炉没气量虚拟值'
                )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.JLPV2, 'JLPV2', 'JLPV2', _vm.infoList.JLPV2_node_string)}}},[_vm._v(" "+_vm._s('JLPV2' in _vm.infoList?_vm.infoList.JLPV2 :0)+" ")])]),_c('div',{staticClass:"star"},[_vm._v("*")]),_vm._m(4),_c('div',{staticClass:"star"},[_vm._v("*")]),_c('div',{staticClass:"table3 flex"},[_c('div',{staticClass:"words"},[_vm._v("YHFMB11")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string,
                  'YHFMB_B11_NG3',
                  '优化风煤比'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.YHFMB_B11,
                  'YHFMB11',
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string
                )}}},[_vm._v(" "+_vm._s('YHFMB_B11' in _vm.infoList?_vm.infoList.YHFMB_B11 :0)+" ")])])])]),_c('div',{staticClass:"first second"},[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"line1 flex"},[_c('div',{staticClass:"table table1 flex"},[_c('div',{staticClass:"words"},[_vm._v("GLPV1")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'GLPV1',
                  _vm.infoList.GLPV1_node_string,
                  'GLPV1_NG3',
                  '一层高炉没气量虚拟值'
                )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.GLPV1, 'GLPV1', 'GLPV1', _vm.infoList.GLPV1_node_string)}}},[_vm._v(" "+_vm._s('GLPV1' in _vm.infoList?_vm.infoList.GLPV1 :0)+" ")])]),_c('div',{staticClass:"star"},[_vm._v("*")]),_c('div',{staticClass:"table table2 flex"},[_c('div',{staticClass:"words"},[_vm._v("YHFMB11")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string,
                  'YHFMB_B11_NG3',
                  '优化风煤比'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.YHFMB_B11,
                  'YHFMB11',
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string
                )}}},[_vm._v(" "+_vm._s('YHFMB_B11' in _vm.infoList?_vm.infoList.YHFMB_B11 :0)+" ")])]),_c('div',{staticClass:"table table3 flex"},[_c('div',{staticClass:"words"},[_vm._v("SFSP1")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'SFSP1',
                  _vm.infoList.SFSP1_node_string,
                  'SFSP1_NG3',
                  '一层空气量设定值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.SFSP1,
                  'SFSP1',
                  'SFSP1',
                  _vm.infoList.SFSP1_node_string
                )}}},[_vm._v(" "+_vm._s('SFSP1' in _vm.infoList?_vm.infoList.SFSP1 :0)+" ")])]),_c('div',{staticClass:"button table4",style:({
              background:
                _vm.infoList.XK8 && !_vm.infoList.XK8.RM
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.XK8
                ? _vm.toDetail(1, 'XK8', _vm.infoList.XK8_node_string, '', '一层空气层操先控调节')
                : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button table5",style:({
              background:
                _vm.infoList.MAN15.RM && _vm.infoList.MAN15.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN15
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN15',
                    'MAN15_AV_NG3',
                    '一层操空气手操器MAN15',
                    'KF_115_B',
                    _vm.infoList.MAN15_node_string,
                    'KF_115_NG3'
                  )
                : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"table table6 flex"},[_c('div',{staticClass:"words"},[_vm._v("B02-A01")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.MAN15_node_string,
                  'MAN15_AV_NG3',
                  '一层操空气手操器',
				  'MAN15'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.MAN15.AV,
                  'B02-A01',
                  'AV',
                  _vm.infoList.MAN15_node_string
                )}}},[_vm._v(" "+_vm._s('MAN15' in _vm.infoList?_vm.infoList.MAN15.AV :0)+" ")])])]),_c('div',{staticClass:"line2 flex"},[_c('div',{staticClass:"table1 flex"},[_c('div',{staticClass:"words"},[_vm._v("ZLPV1")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ZLPV1',
                  _vm.infoList.ZLPV1_node_string,
                  'ZLPV1_NG3',
                  '一层转炉没气量虚拟值'
                )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.ZLPV1, 'ZLPV1', 'ZLPV1', _vm.infoList.ZLPV1_node_string)}}},[_vm._v(" "+_vm._s('ZLPV1' in _vm.infoList?_vm.infoList.ZLPV1 :0)+" ")])]),_c('div',{staticClass:"star"},[_vm._v("*")]),_vm._m(5),_c('div',{staticClass:"star"},[_vm._v("*")]),_c('div',{staticClass:"table3 flex"},[_c('div',{staticClass:"words"},[_vm._v("YHFMB11")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string,
                  'YHFMB_B11_NG3',
                  '优化风煤比'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.YHFMB_B11,
                  'YHFMB11',
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string
                )}}},[_vm._v(" "+_vm._s('YHFMB_B11' in _vm.infoList?_vm.infoList.YHFMB_B11 :0)+" ")])]),_c('div',{staticClass:"table table4 flex"},[_c('div',{staticClass:"words"},[_vm._v("SFPV2")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'SFPV1',
                  _vm.infoList.SFPV1_node_string,
                  'SFPV1_NG3',
                  '一层空气量设定值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.SFPV1,
                  'SFPV2',
                  'SFPV1',
                  _vm.infoList.SFPV1_node_string
                )}}},[_vm._v(" "+_vm._s('SFPV1' in _vm.infoList?_vm.infoList.SFPV1 :0)+" ")])]),_c('div',{staticClass:"buttonk table4",style:({
              background:
                _vm.infoList.QK3 && !_vm.infoList.QK3.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.QK3
                ? _vm.toDetail(2, 'QK3', _vm.infoList.QK3_node_string, '', '送风一层空气层操前馈')
                : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"line2 flex"},[_c('div',{staticClass:"table1 flex"},[_c('div',{staticClass:"words"},[_vm._v("JLPV1")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'JLPV1',
                  _vm.infoList.JLPV1_node_string,
                  'JLPV1_NG3',
                  '一层焦炉没气量虚拟值'
                )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.JLPV1, 'JLPV1', 'JLPV1', _vm.infoList.JLPV1_node_string)}}},[_vm._v(" "+_vm._s('JLPV1' in _vm.infoList?_vm.infoList.JLPV1 :0)+" ")])]),_c('div',{staticClass:"star"},[_vm._v("*")]),_vm._m(6),_c('div',{staticClass:"star"},[_vm._v("*")]),_c('div',{staticClass:"table3 flex"},[_c('div',{staticClass:"words"},[_vm._v("YHFMB11")]),_c('div',{staticClass:"num",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string,
                  'YHFMB_B11_NG3',
                  '优化风煤比'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.YHFMB_B11,
                  'YHFMB11',
                  'YHFMB_B11',
                  _vm.infoList.YHFMB_B11_node_string
                )}}},[_vm._v(" "+_vm._s('YHFMB_B11' in _vm.infoList?_vm.infoList.YHFMB_B11 :0)+" ")])])])])]),_c('inputVal',{ref:"inputVal",on:{"getTreeData":function($event){return _vm.DataJson(arguments)}}}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"node1":_vm.node1,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"pmark":_vm.Panualmark,"pnode":_vm.Panualnode,"pnm":_vm.Panualnodem,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isMshow = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"air_supply"},[_c('div',{staticClass:"air_supply_title"},[_vm._v("送风层操调节回路")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table2 flex"},[_c('div',{staticClass:"words"},[_vm._v("ZL-GL")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table2"},[_c('div',{staticClass:"words"},[_vm._v("JL-GL")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table2 flex"},[_c('div',{staticClass:"words"},[_vm._v("ZL-GL")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table2 flex"},[_c('div',{staticClass:"words"},[_vm._v("JL-GL")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table2 flex"},[_c('div',{staticClass:"words"},[_vm._v("ZL-GL")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table2 flex"},[_c('div',{staticClass:"words"},[_vm._v("JL-GL")])])}]

export { render, staticRenderFns }