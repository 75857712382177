<template>
  <div class="total drag" v-draw id="RqSteamDrum">
    <!-- 抬头标题 -->
    <div class="flex">
      <div class="title-hang flex">
        <div class="zhuangshizb"></div>
        <div class="zhuangshizj">{{ projectData.project }}_参数1</div>
        <div class="zhuangshiyb" @click="closeCompon">
          <div class="icon" @click="closeCompon"></div>
        </div>
      </div>
    </div>
    <!-- 标题 -->
    <div class="main">
      <div class="main_title">汽包水位优化控制模型</div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="line1">
        <div class="flex">
          <div class="words">A04-02SPH</div>
          <div class="shadow flex">
            <div
              class="num1"
              @click="
                toCompon(2, 'LT101_B', infoList.LT101_B_node_string, 'LT101_NG3', '汽包水位1')
              "
              @dblclick="
                Cclick(
                  infoList.LT101_B,
                  'LT101_B',
                  infoList.LT101_B_node_string,
                  'LT101_NG3'
                )
              "
            >
              {{'LT101_B' in infoList?infoList.LT101_B :0}}
            </div>
            <div class="mm">mm</div>
          </div>
        </div>
        <div class="flex">
          <div class="words">A04-02SPL</div>
          <div class="shadow flex">
            <div
              class="num1"
              @click="
                toCompon(2, 'LT102_B', infoList.LT101_B_node_string, 'LT102_NG3', '汽包水位2')
              "
              @dblclick="
                Cclick(
                  infoList.LT102_B,
                  'LT102_B',
                  infoList.LT101_B_node_string,
                  'LT102_NG3'
                )
              "
            >
              {{'LT102_B' in infoList?infoList.LT102_B :0}}
            </div>
            <div class="mm">mm</div>
          </div>
        </div>
        <div class="flex">
          <div class="words">A04-02TC</div>
          <div class="shadow flex">
            <div
              class="num1"
              @click="
                toCompon(2, 'LT103_B', infoList.LT103_B_node_string, 'LT103_NG3', '汽包水位3')
              "
              @dblclick="
                Cclick(
                  infoList.LT103_B,
                  'LT103_B',
                  infoList.LT103_B_node_string,
                  'LT103_NG3'
                )
              "
            >
              {{'LT103_B' in infoList?infoList.LT103_B :0}}
            </div>
            <div class="mm">mm</div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="left">
          <!-- 上面一个 -->
          <div class="line2">
            <div class="flex">
              <div class="words">A02-SWTC</div>
              <div
                class="shadow num2 color"
                @click="
                  toIpt(
                    infoList.SWTC,
                    'A02-SWTC',
                    'SWTC',
                    infoList.SWTC_node_string
                  )
                "
              >
                {{'SWTC' in infoList?infoList.SWTC :0}}
              </div>
            </div>
            <div class="flex">
              <div class="words">A02-HYYZ</div>
              <div
                class="shadow num2 color"
                @click="
                  toIpt(
                    infoList.SW_C,
                    'A02-HYYZ',
                    'SW_C',
                    infoList.SW_C_node_string
                  )
                "
              >
                {{'SW_C' in infoList?infoList.SW_C :0}}
              </div>
            </div>
            <div class="flex">
              <div class="words">A02-SWPV</div>
              <div
                class="shadow num2"
                @click="
                  toCompon(
                    2,
                    'SWPV',
                    infoList.SWPV_node_string,
                    'SWPV_NG3',
                    '汽包水位实际测量值'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.SWPV,
                    'SWPV',
                    infoList.SWPV_node_string,
                    'SWPV_NG3'
                  )
                "
              >
                {{'SWPV' in infoList?infoList.SWPV :0}}
              </div>
            </div>
          </div>
          <!-- 下面一个 -->
          <div class="line3">
            <div class="flex">
              <div class="words">A04-FGS</div>
              <div
                class="shadow num2"
                @click="
                  toCompon(
                    2,
                    'FICA_101_B',
                    infoList.FICA_101_B_node_string,
                    'FICA_101_NG3',
                    '给水流量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.FICA_101_B,
                    'FICA_101_B',
                    infoList.FICA_101_B_node_string,
                    'FICA_101_NG3'
                  )
                "
              >
                {{'FICA_101_B' in infoList?infoList.FICA_101_B :0}}
              </div>
            </div>
            <div class="flex">
              <div class="words">A04-FZQ</div>
              <div
                class="shadow num2"
                @click="
                  toCompon(
                    2,
                    'FICA_102_B',
                    infoList.FICA_102_B_node_string,
                    'FICA_102_NG3',
                    '蒸汽流量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.FICA_102_B,
                    'FICA_102_B',
                    infoList.FICA_102_B_node_string,
                    'FICA_102_NG3'
                  )
                "
              >
                {{'FICA_102_B' in infoList?infoList.FICA_102_B :0}}
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="up">
            <div class="up_content flex">
              <div class="words">A01-SWSP</div>
              <div
                class="shadow num3 color"
                @click="
                  toIpt(
                    infoList.SWSP,
                    'A01-SWSP',
                    'SWSP',
                    infoList.SWSP_node_string
                  )
                "
              >
                {{'SWSP' in infoList?infoList.SWSP :0}}
              </div>
            </div>
          </div>
          <div class="down">
            <div
              class="button button1"
              :style="{
                background:
                  infoList.XK1 && !infoList.XK1.RM
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.XK1
                  ? toDetail(1, 'XK1', infoList.XK1_node_string, '', '汽包水位主调节先控')
                  : ''
              "
            >
              X
            </div>
            <div class="flex">
              <div class="line">
                <div class="flex">
                  <div class="words">A04-QSTC</div>
                  <div
                    class="shadow num4 color"
                    @click="
                      toIpt(
                        infoList.QSTC,
                        'A04-QSTC',
                        'QSTC',
                        infoList.QSTC_node_string
                      )
                    "
                  >
                    {{'QSTC' in infoList?infoList.QSTC :0}}
                  </div>
                </div>
                <div class="flex">
                  <div class="words">A04-QSPC</div>
                  <div
                    class="shadow num4"
                    @click="
                      toCompon(
                        2,
                        'QSPV',
                        infoList.QSPV_node_string,
                        'QSPV_NG3',
                        '汽水偏差计算值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.QSPV,
                        'A04-QSPC',
                        'QSPV',
                        infoList.QSPV_node_string
                      )
                    "
                  >
                    {{'QSPV' in infoList?infoList.QSPV :0}}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="button button2"
              :style="{
                background:
                  infoList.XK19 && !infoList.XK19.RM
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.XK19
                  ? toDetail(1, 'XK19', infoList.XK19_node_string, '', '主给水副调节')
                  : ''
              "
            >
              X
            </div>
            <div
              class="button button3"
              :style="{
                background:
                  infoList.XK20 && !infoList.XK20.RM
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.XK20
                  ? toDetail(1, 'XK20', infoList.XK20_node_string, '', '副给水副调节')
                  : ''
              "
            >
              X
            </div>
          </div>
        </div>
        <div class="right_last">
          <div class="one flex">
            <div
              class="button"
              :style="{
                background:
                  infoList.MAN1.RM && infoList.MAN1.RM == 1
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.MAN1
                  ? toCompon(
                      0,
                      'AV',
                      'MAN1',
                      'MAN1_AV_NG3',
                      '主给水手操器MAN1',
                      'KF_101_B',
                      infoList.MAN1_node_string,
                      'KF_101_NG3'
                    )
                  : ''
              "
            >
              A
            </div>
            <div class="words word2">A05-A01</div>
            <div
              class="shadow num4"
              @click="
                toCompon(
                  2,
                  'AV',
                  infoList.MAN1_node_string,
                  'MAN1_AV_NG3',
                  '主给水手操器',
				  'MAN1'
                )
              "
              @dblclick="
                Cclick(
                  infoList.MAN1.AV,
                  'AV',
                  infoList.MAN1_node_string,
                  'MAN1_AV_NG3'
                )
              "
            >
              {{'MAN1' in infoList?infoList.MAN1.AV :0}}
            </div>
          </div>
          <div class="two flex">
            <div
              class="button button4"
              :style="{
                background:
                  infoList.MAN2.RM && infoList.MAN2.RM == 1
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.MAN2
                  ? toCompon(
                      0,
                      'AV',
                      'MAN2',
                      'MAN2_AV_NG3',
                      '副给水手操器MAN2',
                      'KF_102_B',
                      infoList.MAN2_node_string,
                      'KF_102_NG3'
                    )
                  : ''
              "
            >
              A
            </div>
            <div class="words word2">A05-A02</div>
            <div
              class="shadow num4"
              @click="
                toCompon(
                  2,
                  'AV',
                  infoList.MAN2_node_string,
                  'MAN2_AV_NG3',
                  '副给水手操器',
				  'MAN2'
                )
              "
              @dblclick="
                Cclick(
                  infoList.MAN2.AV,
                  'AV',
                  infoList.MAN2_node_string,
                  'MAN2_AV_NG3'
                )
              "
            >
              {{'MAN2' in infoList?infoList.MAN2.AV :0}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 说明 -->
    <div class="explain">
      <div class="explain_content flex">
        <div>说明：</div>
        <div>
          <div class="explain1 flex">
            <div>1、汽包液位主调节SADD：投入自动的阀门均达到安全限幅上限</div>
            <div class="blank"></div>
            <div>SSUB: 投入自动的阀门均达到安全限幅下限</div>
          </div>
          <div class="explain2 flex">
            <div>2、主给水调节SADD：主给水调节阀门达到安全限幅上限</div>
            <div class="blank"></div>
            <div>SSUB: 主给水调节阀门达到安全限幅下限</div>
          </div>
          <div class="explain3 flex">
            <div>3、副给水调节SADD：副给水调节阀门达到安全限幅上限</div>
            <div class="blank"></div>
            <div>SSUB: 副给水调节阀门达到安全限幅下限</div>
          </div>
        </div>
      </div>
    </div>
    <inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
    <Historical
      v-if="isHshow"
      @sendStatus="isHshow = false"
      :historyname="historyname"
      :node="node"
      :node1="node1"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isMshow = false"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :pmark="Panualmark"
      :pnode="Panualnode"
      :pnm="Panualnodem"
      :infoList="infoList"
    >
    </Manual>
  </div>
</template>

<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import Manual from "@/components/Manual1.vue"; //手操器组件
export default {
  name: "Param11",
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
  },
  components: {
    inputVal,
    Historical,
    Manual,
  },
  data: () => {
    return {
      chName: "",
      Panualmark: "",
      Panualnode: "",
      Panualnodem: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
	  node1:''
    };
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    DataJson(data) {
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
			case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "rqkfirstcontolRQL";
            break;
          case 3:
            pathname = "rqRfirstcontolRQL";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isshowfase() {
      this.isComShow = false;
    },
    closeCompon() {
      this.$emit("sendStatus", "Param01", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
      }
    },
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    toCompon(key, name, name2, name3, name4, titname, param1, param2, param3) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            console.log(1);
            return (
              (this.isMshow = true),
              (this.Manualname = name),
              (this.Manualnode = name2),
              (this.ManualAname = name3),
              (this.Manualtitname = titname),
              (this.Panualmark = param1),
              (this.Panualnode = param2),
              (this.Panualnodem = param3)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = name4),(this.node1 = titname);
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.total {
  width: 53vw;
  height: 67vh;
  background-image: url("~@/assets/images/RqBoiler/servive_bg.png");
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -33.5vh 0 0 -26.5vw;
  z-index: 999;

  .title-hang {
    width: 53vw;
    height: 5vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
  }

  .zhuangshizb {
    width: 16vw;
    height: 2vh;
    background-image: url("~@/assets/images/rfl_evaluate06.png");
    background-size: 100% 100%;
    margin-top: 2vh;
  }

  .zhuangshizj {
    width: 19vw;
    height: 5vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
    margin-top: 1vh;
    text-align: center;
    background-image: url("~@/assets/images/rfl_evaluate05.png");
    background-size: 100% 100%;
    margin-left: 1vw;
  }

  .zhuangshiyb {
    width: 16vw;
    height: 2vh;
    background-image: url("~@/assets/images/rfl_evaluate04.png");
    background-size: 100% 100%;
    margin-left: 1vw;
    margin-top: 2vh;
  }

  .icon {
    width: 2vw;
    height: 3.4vh;
    background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
    background-size: 100% 100%;
    margin-left: 13vw;
    margin-top: -0.5vh;
    cursor: pointer;
  }

  .main {
    width: 53vw;
    height: 2vh;
    margin-top: 3vh;

    .main_title {
      font-family: PingFang-SC-Regular;
      font-size: 1.7vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vh;
      letter-spacing: 1vh;
      color: #d5fffe;
      text-align: center;
    }
  }

  .content {
    width: 48vw;
    height: 40vh;
    margin-left: 2vw;
    margin-top: 1vh;

    .words {
      width: 5.5vw;
      height: 3vh;
      font-family: PingFang-SC-Regular;
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 3vh;
      letter-spacing: 0vh;
      color: #8aeaff;
      text-align: right;
    }

    .shadow {
      height: 2vh;
      background-color: #001b2860;
      box-shadow: 0vw 0vw 0vw 0vw #15384c;
      border-radius: 0vw;
      opacity: 0.4;
      text-align: center;
      color: #00ffb4;
      font-family: PingFang-SC-Regular;
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vh;
      letter-spacing: 0vh;
      margin-top: 0.5vh;
      margin-left: 0.5vw;
    }

    .color {
      color: #8aeaff;
    }

    .line1 {
      .num1 {
        width: 3vw;
        cursor: pointer;
      }

      .mm {
        width: 2vw;
      }
    }

    .line2 {
      margin-top: 2vh;

      .num2 {
        width: 3vw;
        cursor: pointer;
      }
    }

    .line3 {
      margin-top: 2.6vh;

      .num2 {
        width: 3vw;
        cursor: pointer;
      }
    }

    .button {
      width: 1vw;
      height: 2vh;
      border: 2px solid rgb(217, 243, 145);
      text-align: center;
      line-height: 2vh;
      background-color: #00e4ff;
      color: white;
      cursor: pointer;
    }

    .right {
      .up {
        .up_content {
          margin-left: 7vw;
          margin-top: 3vh;

          .num3 {
            width: 3vw;
            cursor: pointer;
          }
        }
      }

      .down {
        width: 29vw;
        height: 26vh;
        background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/steam_drum.png");
        background-size: 100% 100%;
        position: absolute;

        .button1 {
          margin-left: 11.5vw;
          margin-top: 2.5vh;
          position: relative;
        }

        .num4 {
          width: 3vw;
          cursor: pointer;
        }

        .line {
          margin-left: 2vw;
          margin-top: 1vh;
        }

        .button2 {
          margin-left: 17.2vw;
          margin-top: -2.5vh;
        }

        .button3 {
          margin-left: 22.2vw;
          margin-top: 12.5vh;
        }
      }
    }

    .right_last {
      margin-left: 13.8vw;
      margin-top: 15.5vh;

      .two {
        margin-top: 12vh;

        .button4 {
          margin-left: 0vw;
        }
      }

      .num4 {
        width: 3vw;
        margin-left: -0.5vw;
      }

      .word2 {
        text-align: center;
        line-height: 2vh;
      }

      .num4 {
        margin-top: 0vh;
        cursor: pointer;
      }
    }
  }

  .explain {
    width: 53vw;
    border-top: 2px solid #bbd6e5;
    margin-top: 5vh;
    font-family: PingFang-SC-Regular;
    font-size: 0.9vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #4d91a7;

    .blank {
      width: 2.2vw;
    }

    .explain_content {
      margin-top: 2vh;
      margin-left: 2vw;
    }
  }
}
</style>
