<template>
	<div class="total">
		<div class="maskbok" v-if="maskbok"></div>
		<!-- 抬头标题 -->
		<div class="title-hang">
			<div class="fl-t">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<!-- <div class="zhuangshizb fl-t"></div> -->
			<div class="zhuangshizj fl-t">{{projectData.project}}
				<!-- _CFB优化控制系统 -->
			</div>
			<div class="fr-t">
				<div class="fl-t">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
				</div>
			</div>
		</div>
		<!-- 主要内容 -->
		<div class="part1 flex">
			<div class="float1">
				<div class="words">锅炉负荷</div>
				<div class="num1" @click="toCompon(2,'BYMNL59','MCSBY','BYMNL59VV1','主蒸汽流量')"
					@dblclick="Cclick(infoList.MCSBY.BYMNL59,'BYMNL59','MCSBY','BYMNL59VV1')">
					{{infoList.MCSBY.BYMNL59}}&nbsp;t/h
				</div>
			</div>
			<div class="float2">
				<div class="words">汽压控制点</div>
				<div class="num2" @click="toIpt(infoList.MCSGEIMEI.XTPR,'汽压控制点','XTPR','MCSGEIMEI')">
					{{infoList.MCSGEIMEI.XTPR}}&nbsp;MPa
				</div>
			</div>
			<div class="float3">
				<div class="words">汽温控制点</div>
				<div class="num2" @click="toIpt(infoList.MCSQIWEN.ZQTSP_B1,'汽温控制点','ZQTSP_B1','MCSQIWEN')">
					{{infoList.MCSQIWEN.ZQTSP_B1}}&nbsp;℃
				</div>
			</div>
			<div class="float4">
				<div class="words">水位控制点</div>
				<div class="num2" @click="toIpt(infoList.MCSGEISHUI.SWSP_B1,'水位控制点','SWSP_B1','MCSGEISHUI')">
					{{infoList.MCSGEISHUI.SWSP_B1}}&nbsp;mmm
				</div>
			</div>
		</div>
		<div class="part2 flex">
			<div class="left">
				<div class="block1 flex">
					<div class="float1">
						<div class="num1" @click="toCompon(2,'FT102D_B','MCSSOURCE','FT102D_BVV1','1#主蒸汽流量')"
							@dblclick="Cclick(infoList.MCSSOURCE.FT102D_B,'FT102D_B','MCSSOURCE','FT102D_BVV1')">
							{{infoList.MCSSOURCE.FT102D_B}}&nbsp;t/h
						</div>
					</div>
					<div class="float2">
						<div class="num1" @click="toCompon(2,'BYMNL39','MCSBY','BYMNL39VV1','主蒸汽压力平均值')"
							@dblclick="Cclick(infoList.MCSBY.BYMNL39,'BYMNL39','MCSBY','BYMNL39VV1')">
							{{infoList.MCSBY.BYMNL39}}&nbsp;MPa
						</div>
					</div>
					<div class="float3">
						<div class="num1" @click="toCompon(2,'PT103D_B','MCSSOURCE','PT103D_BVV1','一级主蒸汽压力')"
							@dblclick="Cclick(infoList.MCSSOURCE.PT103D_B,'PT103D_B','MCSSOURCE','PT103D_BVV1')">
							{{infoList.MCSSOURCE.PT103D_B}}&nbsp;MPa
						</div>
					</div>
					<div class="float4">
						<div class="num1" @click="toCompon(2,'TE165_B','MCSSOURCE','TE165_BVV1','二级甲减温器蒸汽出温度')"
							@dblclick="Cclick(infoList.MCSSOURCE.TE165_B,'TE165_B','MCSSOURCE','TE165_BVV1')">
							{{infoList.MCSSOURCE.TE165_B}}&nbsp;℃
						</div>
					</div>
					<div class="float5">
						<div class="num1" @click="toCompon(2,'TE166_B','MCSSOURCE','TE166_BVV1','二级乙减温器蒸汽出温度')"
							@dblclick="Cclick(infoList.MCSSOURCE.TE166_B,'TE166_B','MCSSOURCE','TE166_BVV1')">
							{{infoList.MCSSOURCE.TE166_B}}&nbsp;℃
						</div>
					</div>
				</div>
				<div class="block2 flex">
					<div class="device" @click="toCompon(5)">煤斗</div>
					<div class="water_level_ball flex">
						<div class="water_level">
							<div class="top flex">
								<!-- <div class="words">水位1</div> -->
								<div class="words"
									:style="{background:infoList.MCSGEISHUI.SWHSEL == 1 ? '#2AFC30' : 'red',}"
									@click="toIpt('1', '水位1','SWHSEL','MCSGEISHUI','true')">
									水位1
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'LT101D_B','MCSSOURCE','LT101D_BVV1','汽包水位1')"
										@dblclick="Cclick(infoList.MCSSOURCE.LT101D_B,'LT101D_B','MCSSOURCE','LT101D_BVV1')">
										{{infoList.MCSSOURCE.LT101D_B}}&nbsp;mm
									</div>
								</div>
							</div>
							<div class="top flex">
								<div class="words"
									:style="{background:infoList.MCSGEISHUI.SWHSEL == 2 ? '#2AFC30' : 'red',}"
									@click="toIpt('2', '水位2','SWHSEL','MCSGEISHUI','true')">
									水位2
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'LT102D_B','MCSSOURCE','LT102D_BVV1','汽包水位2')"
										@dblclick="Cclick(infoList.MCSSOURCE.LT102D_B,'LT102D_B','MCSSOURCE','LT102D_BVV1')">
										{{infoList.MCSSOURCE.LT102D_B}}&nbsp;mm
									</div>
								</div>
							</div>
							<div class="top flex">
								<div class="words"
									:style="{background:infoList.MCSGEISHUI.SWHSEL == 4 ? '#2AFC30' : 'red',}"
									@click="toIpt('4', '水位3','SWHSEL','MCSGEISHUI','true')">
									水位3
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'LT103D_B','MCSSOURCE','LT103D_BVV1','汽包水位3')"
										@dblclick="Cclick(infoList.MCSSOURCE.LT103D_B,'LT103D_B','MCSSOURCE','LT103D_BVV1')">
										{{infoList.MCSSOURCE.LT103D_B}}&nbsp;mm
									</div>
								</div>
							</div>
							<div class="top flex">
								<div class="words"
									:style="{background:infoList.MCSGEISHUI.SWHSEL == 8 ? '#2AFC30' : 'red',}"
									@click="toIpt('8', '水位4','SWHSEL','MCSGEISHUI','true')">
									水位4
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'LT104D_B','MCSSOURCE','LT104D_BVV1','汽包水位4')"
										@dblclick="Cclick(infoList.MCSSOURCE.LT104D_B,'LT104D_B','MCSSOURCE','LT104D_BVV1')">
										{{infoList.MCSSOURCE.LT104D_B}}&nbsp;mm
									</div>
								</div>
							</div>
						</div>
						<div class="ball" @click="toCompon(11)">
							<dv-water-level-pond :config=config style="width: 110px; height: 110px"
								@click.stop="toCompon(2,'SWSJPV','MCSGEISHUI','SWSJPVVV1')" />
						</div>
						<div class="location">
							<div class="num1" @click.stop="toCompon(2,'SWSJPV','MCSGEISHUI','SWSJPVVV1','汽包水位均值')"
								@dblclick.stop="Cclick(infoList.MCSGEISHUI.SWSJPV,'SWSJPV','MCSGEISHUI','SWSJPVVV1')">
								{{infoList.MCSGEISHUI.SWSJPV}}&nbsp;mm
							</div>
							<!-- <div class="num1"
						        @click="toCompon(2,'PT102D1_B','MCSSOURCE','PT102D1_BVV1')"
						        @dblclick="Cclick(infoList.MCSSOURCE.PT102D1_B,'PT102D1_B','MCSSOURCE','PT102D1_BVV1')">
						            {{infoList.MCSSOURCE.PT102D1_B}}&nbsp;MPa
						    </div> -->
						</div>
					</div>
					<div class="location1">
						<div class="flex">
							<div class="num1" @click="toCompon(2,'TE136_D_B','MCSSOURCE','TE136_D_BVV1','二级甲入口蒸汽温度（左)')"
								@dblclick="Cclick(infoList.MCSSOURCE.TE136_D_B,'TE136_D_B','MCSSOURCE','TE136_D_BVV1')">
								{{infoList.MCSSOURCE.TE136_D_B}}&nbsp;℃
							</div>
						</div>
						<div class="flex">
							<div class="num1" @click="toCompon(2,'TE137_D_B','MCSSOURCE','TE137_D_BVV1','二级乙入口蒸汽温度（右)')"
								@dblclick="Cclick(infoList.MCSSOURCE.TE137_D_B,'TE137_D_B','MCSSOURCE','TE137_D_BVV1')">
								{{infoList.MCSSOURCE.TE137_D_B}}&nbsp;℃
							</div>
						</div>
					</div>
					<div class="location2">
						<div class="flex">
							<div class="num1" @click="toCompon(2,'TE138_D_B','MCSSOURCE','TE138_D_BVV1','一级甲入口蒸汽温度（左)')"
								@dblclick="Cclick(infoList.MCSSOURCE.TE138_D_B,'TE138_D_B','MCSSOURCE','TE138_D_BVV1')">
								{{infoList.MCSSOURCE.TE138_D_B}}&nbsp;℃
							</div>
						</div>
						<div class="flex">
							<div class="num1" @click="toCompon(2,'TE139_D_B','MCSSOURCE','TE139_D_BVV1','一级乙入口蒸汽温度（右)')"
								@dblclick="Cclick(infoList.MCSSOURCE.TE139_D_B,'TE139_D_B','MCSSOURCE','TE139_D_BVV1')">
								{{infoList.MCSSOURCE.TE139_D_B}}&nbsp;℃
							</div>
						</div>
					</div>
				</div>
				<div class="block3 flex">
					<div class="zy" @click="toCompon(10)"></div>
					<div class="one flex">
						<div class="valve"></div>
						<div class="valve float1"></div>
						<div class="valve float2"></div>
						<div class="valve float3"></div>
						<div class="valve float4"></div>
						<div class="valve float5"></div>
					</div>
					<div class="two">
						<div class="location1">
							<div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'KF_GM6_B','MCSSOURCE','KF_GM6_BVV1','6#给煤机反馈')"
										@dblclick="Cclick(infoList.MCSSOURCE.KF_GM6_B,'KF_GM6_B','MCSSOURCE','KF_GM6_BVV1')">
										{{infoList.MCSSOURCE.KF_GM6_B}}&nbsp;%
									</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'MLZ6','MCSYCL','MLZ6VV1','6#给煤量选择后的值')"
										@dblclick="Cclick(infoList.MCSYCL.MLZ6,'MLZ6','MCSYCL','MLZ6VV1')">
										{{infoList.MCSYCL.MLZ6}}&nbsp;t/h
									</div>
								</div>
							</div>
							<div class="float2">
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'KF_GM5_B','MCSSOURCE','KF_GM5_BVV1','5#给煤机反馈')"
										@dblclick="Cclick(infoList.MCSSOURCE.KF_GM5_B,'KF_GM5_B','MCSSOURCE','KF_GM5_BVV1')">
										{{infoList.MCSSOURCE.KF_GM5_B}}&nbsp;%
									</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'MLZ5','MCSYCL','MLZ5VV1','3#给煤量选择后的值')"
										@dblclick="Cclick(infoList.MCSYCL.MLZ5,'MLZ5','MCSYCL','MLZ5VV1')">
										{{infoList.MCSYCL.MLZ5}}&nbsp;t/h
									</div>
								</div>
							</div>
							<div class="float3">
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'KF_GM4_B','MCSSOURCE','KF_GM4_BVV1','4#给煤机反馈')"
										@dblclick="Cclick(infoList.MCSSOURCE.KF_GM4_B,'KF_GM4_B','MCSSOURCE','KF_GM4_BVV1')">
										{{infoList.MCSSOURCE.KF_GM4_B}}&nbsp;%
									</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'MLZ4','MCSYCL','MLZ4VV1','4#给煤量选择后的值')"
										@dblclick="Cclick(infoList.MCSYCL.MLZ4,'MLZ4','MCSYCL','MLZ4VV1')">
										{{infoList.MCSYCL.MLZ4}}&nbsp;t/h
									</div>
								</div>
							</div>
							<div class="float4">
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'KF_GM3_B','MCSSOURCE','KF_GM3_BVV1','3#给煤机反馈')"
										@dblclick="Cclick(infoList.MCSSOURCE.KF_GM3_B,'KF_GM3_B','MCSSOURCE','KF_GM3_BVV1')">
										{{infoList.MCSSOURCE.KF_GM3_B}}&nbsp;%
									</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'MLZ3','MCSYCL','MLZ3VV1','3#给煤量选择后的值')"
										@dblclick="Cclick(infoList.MCSYCL.MLZ3,'MLZ3','MCSYCL','MLZ3VV1')">
										{{infoList.MCSYCL.MLZ3}}&nbsp;t/h
									</div>
								</div>
							</div>
							<div class="float5">
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'KF_GM2_B','MCSSOURCE','KF_GM2_BVV1','2#给煤机反馈')"
										@dblclick="Cclick(infoList.MCSSOURCE.KF_GM2_B,'KF_GM2_B','MCSSOURCE','KF_GM2_BVV1')">
										{{infoList.MCSSOURCE.KF_GM2_B}}&nbsp;%
									</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'MLZ2','MCSYCL','MLZ2VV1','2#给煤量选择后的值')"
										@dblclick="Cclick(infoList.MCSYCL.MLZ2,'MLZ2','MCSYCL','MLZ2VV1')">
										{{infoList.MCSYCL.MLZ2}}&nbsp;t/h
									</div>
								</div>
							</div>
							<div class="float6">
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'KF_GM1_B','MCSSOURCE','KF_GM1_BVV1','1#给煤机反馈')"
										@dblclick="Cclick(infoList.MCSSOURCE.KF_GM1_B,'KF_GM1_B','MCSSOURCE','KF_GM1_BVV1')">
										{{infoList.MCSSOURCE.KF_GM1_B}}&nbsp;%
									</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'MLZ1','MCSYCL','MLZ1VV1','1#给煤量选择后的值')"
										@dblclick="Cclick(infoList.MCSYCL.MLZ1,'MLZ1','MCSYCL','MLZ1VV1')">
										{{infoList.MCSYCL.MLZ1}}&nbsp;t/h
									</div>
								</div>
							</div>
						</div>
						<div class="location2">
							<div class="words">实际给煤量</div>
							<div class="flex">
								<div class="num1" @click="toCompon(2,'ZGML','MCSYCL','ZGMLVV1','总给煤量')"
									@dblclick="Cclick(infoList.MCSYCL.ZGML,'ZGML','MCSYCL','ZGMLVV1')">
									{{infoList.MCSYCL.ZGML}}&nbsp;t/h
								</div>
							</div>
							<div class="float1 flex">
								<div class="buttonx"
									:style="{background:infoList.MCSXK__p__XK0O &&!infoList.MCSXK__p__XK0O.RM ? '#2AFC30' : 'red',}"
									@click="infoList.MCSXK__p__XK0O ? toDetail(1,'XK0O','MCSXK__p__XK0O','','主汽压力广义预测调节先控' ): ''">
									X
								</div>
								<div class="buttonx"
									:style="{background:infoList.MCSXK__p__XK0P &&!infoList.MCSXK__p__XK0P.RM ? '#2AFC30' : 'red',}"
									@click="infoList.MCSXK__p__XK0P ? toDetail(1,'XK0P','MCSXK__p__XK0P','','广义预测流量偏差调节先控' ): ''">
									X
								</div>
							</div>
							<div class="flex">
								<div class="buttonr"
									:style="{background:infoList.MCSRPQ__p__QK07 &&!infoList.MCSRPQ__p__QK07.TS? '#2AFC30': 'red',}"
									@click="infoList.MCSRPQ__p__QK07 ? toDetail(2,'QK07','MCSRPQ__p__QK07','','主汽流量前馈' ): ''">
									K
								</div>
								<div class="buttonx"
									:style="{background:infoList.MCSXK__p__XK03 &&!infoList.MCSXK__p__XK03.RM ? '#2AFC30' : 'red',}"
									@click="infoList.MCSXK__p__XK03 ? toDetail(1,'XK03','MCSXK__p__XK03','','主汽压力调节' ): ''">
									X
								</div>
								<div class="buttonx"
									:style="{background:infoList.MCSXK__p__XK04 &&infoList.MCSXK__p__XK04.RM==1 ? '#2AFC30' : 'red',}"
									@click="infoList.MCSXK__p__XK04 ? toDetail(1,'XK04','MCSXK__p__XK04','','床温调节' ): ''">
									X
								</div>
								<div class="buttonx"
									:style="{background:infoList.MCSXK__p__XK02 &&infoList.MCSXK__p__XK02.RM==1 ? '#2AFC30' : 'red',}"
									@click="infoList.MCSXK__p__XK02 ? toDetail(1,'XK02','MCSXK__p__XK02','','给煤流量调节' ): ''">
									X
								</div>
								<div class="buttonx" :style="{
									background:
									infoList.MCSMAN__p__MAN1.RM  == 1 || infoList.MCSMAN__p__MAN2.RM == 1 || infoList.MCSMAN__p__MAN3.RM == 1 || infoList.MCSMAN__p__MAN4.RM == 1 || infoList.MCSMAN__p__MAN23.RM == 1 || infoList.MCSMAN__p__MAN24.RM == 1 ? '#2AFC30' : 'red'
								}" @click=" toCompon(0,'CFB_MANGM',6)">A</div>
							</div>
						</div>
					</div>
					<div class="three">
						<div class="location1">
							<div class="float1 flex">
								<div>
									<div class="shadow flex">
										<div class="num1"
											@click="toCompon(2,'PT124D_B','MCSSOURCE','PT124D_BVV1','炉膛负压1')"
											@dblclick="Cclick(infoList.MCSSOURCE.PT124D_B,'PT124D_B','MCSSOURCE','PT124D_BVV1')">
											{{infoList.MCSSOURCE.PT124D_B}}&nbsp;Pa
										</div>
									</div>
									<div class="words" @click="chanereast(
											infoList[node].SELYFVV1 && infoList[node].SELYFVV1 == 1 ? '254' : '1',
											'是否改变自整定功能的选择',
											'SELYFVV1',
											node
										)">
										负压1
									</div>
								</div>
								<div>
									<div class="flex">
										<div class="num4"
											@click="toIpt(infoList.MCSFENG.FYSP_B1,'汽压控制点','FYSP_B1','MCSFENG')">
											{{infoList.MCSFENG.FYSP_B1}}&nbsp;Pa
										</div>
									</div>
									<div class="flex">
										<div class="num6" @click="toCompon(2,'LTPJZ','MCSYCL','LTPJZVV1','炉膛负压均值')"
											@dblclick="Cclick(infoList.MCSYCL.LTPJZ,'LTPJZ','MCSYCL','LTPJZVV1')">
											{{infoList.MCSYCL.LTPJZ}}&nbsp;Pa
										</div>
									</div>
								</div>
								<div>
									<div class="shadow flex">
										<div class="num1"
											@click="toCompon(2,'PT125D_B','MCSSOURCE','PT125D_BVV1','炉膛负压2')"
											@dblclick="Cclick(infoList.MCSSOURCE.PT125D_B,'PT125D_B','MCSSOURCE','PT125D_BVV1')">
											{{infoList.MCSSOURCE.PT125D_B}}&nbsp;Pa
										</div>
									</div>
									<div class="words" @click="chanereast(
											infoList[node].SELYFVV1 && infoList[node].SELYFVV1 == 1 ? '254' : '1',
											'是否改变自整定功能的选择',
											'SELYFVV1',
											node
										)">
										负压2
									</div>
								</div>
							</div>
							<div class="flex">
								<div>
									<div class="shadow flex">
										<div class="num1"
											@click="toCompon(2,'PT126D_B','MCSSOURCE','PT126D_BVV1','炉膛负压3')"
											@dblclick="Cclick(infoList.MCSSOURCE.PT126D_B,'PT126D_B','MCSSOURCE','PT126D_BVV1')">
											{{infoList.MCSSOURCE.PT126D_B}}&nbsp;Pa
										</div>
									</div>
									<div class="words" @click="chanereast(
											infoList[node].SELYFVV1 && infoList[node].SELYFVV1 == 1 ? '254' : '1',
											'是否改变自整定功能的选择',
											'SELYFVV1',
											node
										)">
										负压3
									</div>
								</div>
								<div class="float2">
									<div class="shadow flex">
										<div class="num1"
											@click="toCompon(2,'PT127D_B','MCSSOURCE','PT127D_BVV1','炉膛负压4')"
											@dblclick="Cclick(infoList.MCSSOURCE.PT127D_B,'PT127D_B','MCSSOURCE','PT127D_BVV1')">
											{{infoList.MCSSOURCE.PT127D_B}}&nbsp;Pa
										</div>
									</div>
									<div class="words" @click="chanereast(
											infoList[node].SELYFVV1 && infoList[node].SELYFVV1 == 1 ? '254' : '1',
											'是否改变自整定功能的选择',
											'SELYFVV1',
											node
										)">
										负压4
									</div>
								</div>
							</div>
						</div>
						<div class="location2 flex">
							<div class="left">
								<div class="flex">
									<div class="num1" @click="toCompon(2,'TE133D_B','MCSSOURCE','TE133D_BVV1','炉床上部温度')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE133D_B,'TE133D_B','MCSSOURCE','TE133D_BVV1')">
										{{infoList.MCSSOURCE.TE133D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'TE132D_B','MCSSOURCE','TE132D_BVV1','炉床上部温度')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE132D_B,'TE132D_B','MCSSOURCE','TE132D_BVV1')">
										{{infoList.MCSSOURCE.TE132D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'TE129D_B','MCSSOURCE','TE129D_BVV1','炉床上部温度')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE129D_B,'TE129D_B','MCSSOURCE','TE129D_BVV1')">
										{{infoList.MCSSOURCE.TE129D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'TE128D_B','MCSSOURCE','TE128D_BVV1','炉床上部温度')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE128D_B,'TE128D_B','MCSSOURCE','TE128D_BVV1')">
										{{infoList.MCSSOURCE.TE128D_B}}&nbsp;℃
									</div>
								</div>
							</div>
							<div class="right">
								<div class="flex">
									<div class="num1" @click="toCompon(2,'TE125D_B','MCSSOURCE','TE125D_BVV1','炉床上部温度')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE125D_B,'TE125D_B','MCSSOURCE','TE125D_BVV1')">
										{{infoList.MCSSOURCE.TE125D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'TE124D_B','MCSSOURCE','TE124D_BVV1','炉床上部温度')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE124D_B,'TE124D_B','MCSSOURCE','TE124D_BVV1')">
										{{infoList.MCSSOURCE.TE124D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'TE123D_B','MCSSOURCE','TE123D_BVV1','炉床上部温度')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE123D_B,'TE123D_B','MCSSOURCE','TE123D_BVV1')">
										{{infoList.MCSSOURCE.TE123D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'TE122D_B','MCSSOURCE','TE122D_BVV1','炉床上部温度')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE122D_B,'TE122D_B','MCSSOURCE','TE122D_BVV1')">
										{{infoList.MCSSOURCE.TE122D_B}}&nbsp;℃
									</div>
								</div>
							</div>
						</div>
						<div class="location3">
							<div class="float1 flex">
								<div class="num3" @click="toCompon(2,'ZJAV1','MCSYCL','ZJAV1VV1','床温变化显示')"
									@dblclick="Cclick(infoList.MCSYCL.ZJAV1,'ZJAV1','MCSYCL','ZJAV1VV1')">
									{{infoList.MCSYCL.ZJAV1}}&nbsp;℃/min
								</div>
							</div>
							<div class="flex">
								<div class="flex">
									<div class="num4"
										@click="toIpt(infoList.MCSCWKZ.CWSP_B1,'汽压控制点','CWSP_B1','MCSCWKZ')">
										{{infoList.MCSCWKZ.CWSP_B1}}&nbsp;℃
									</div>
								</div>
								<div class="float6 flex">
									<div class="num1" @click="toCompon(2,'CWSP_SJ','MCSCWKZ','CWSP_SJVV1','床温实际控制点')"
										@dblclick="Cclick(infoList.MCSCWKZ.CWSP_SJ,'CWSP_SJ','MCSCWKZ','CWSP_SJVV1')">
										{{infoList.MCSCWKZ.CWSP_SJ}}&nbsp;℃
									</div>
								</div>
							</div>
							<div class="float2 flex">
								<div class="num1" @click="toCompon(2,'MAXCW','MCSYCL','MAXCWVV1','床温最大值')"
									@dblclick="Cclick(infoList.MCSYCL.MAXCW,'MAXCW','MCSYCL','MAXCWVV1')">
									{{infoList.MCSYCL.MAXCW}}&nbsp;℃
								</div>
							</div>
							<div class="flex">
								<div class="float3 flex">
									<div class="num1" @click="toCompon(2,'CWJZ_Z','MCSYCL','CWJZ_ZVV1','左侧床温均值')"
										@dblclick="Cclick(infoList.MCSYCL.CWJZ_Z,'CWJZ_Z','MCSYCL','CWJZ_ZVV1')">
										{{infoList.MCSYCL.CWJZ_Z}}&nbsp;℃
									</div>
								</div>
								<div class="float4 flex">
									<div class="num1" @click="toCompon(2,'CWJZ','MCSCWKZ','CWJZVV1','床温均值')"
										@dblclick="Cclick(infoList.MCSCWKZ.CWJZ,'CWJZ','MCSCWKZ','CWJZVV1')">
										{{infoList.MCSCWKZ.CWJZ}}&nbsp;℃
									</div>
								</div>
								<div class="float5 flex">
									<div class="num1" @click="toCompon(2,'CWJZ_Y','MCSYCL','CWJZ_YVV1','右侧床温均值')"
										@dblclick="Cclick(infoList.MCSYCL.CWJZ_Y,'CWJZ_Y','MCSYCL','CWJZ_YVV1')">
										{{infoList.MCSYCL.CWJZ_Y}}&nbsp;℃
									</div>
								</div>
							</div>
						</div>
						<div class="location7 flex">
							<div class="left">
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE110D_B','MCSSOURCE','TE110D_BVV1','炉床温度5-左侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE110D_B,'TE110D_B','MCSSOURCE','TE110D_BVV1')">
										{{infoList.MCSSOURCE.TE110D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE111D_B','MCSSOURCE','TE111D_BVV1','炉床温度6-右侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE111D_B,'TE111D_B','MCSSOURCE','TE111D_BVV1')">
										{{infoList.MCSSOURCE.TE111D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE112D_B','MCSSOURCE','TE112D_BVV1','炉床温度7-左侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE112D_B,'TE112D_B','MCSSOURCE','TE112D_BVV1')">
										{{infoList.MCSSOURCE.TE112D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE113D_B','MCSSOURCE','TE113D_BVV1','炉床温度8-右侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE113D_B,'TE113D_B','MCSSOURCE','TE113D_BVV1')">
										{{infoList.MCSSOURCE.TE113D_B}}&nbsp;℃
									</div>
								</div>
							</div>
							<div class="left">
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE116D_B','MCSSOURCE','TE116D_BVV1','炉床温度11-左侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE116D_B,'TE116D_B','MCSSOURCE','TE116D_BVV1')">
										{{infoList.MCSSOURCE.TE116D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE117D_B','MCSSOURCE','TE117D_BVV1','炉床温度12-右侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE117D_B,'TE117D_B','MCSSOURCE','TE117D_BVV1')">
										{{infoList.MCSSOURCE.TE117D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE118D_B','MCSSOURCE','TE118D_BVV1','炉床温度13-左侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE118D_B,'TE118D_B','MCSSOURCE','TE118D_BVV1')">
										{{infoList.MCSSOURCE.TE118D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE120D_B','MCSSOURCE','TE120D_BVV1','炉床温度15-左侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE120D_B,'TE120D_B','MCSSOURCE','TE120D_BVV1')">
										{{infoList.MCSSOURCE.TE120D_B}}&nbsp;℃
									</div>
								</div>
							</div>
							<div class="right">
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE106D_B','MCSSOURCE','TE106D_BVV1','炉床温度1-左侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE106D_B,'TE106D_B','MCSSOURCE','TE106D_BVV1')">
										{{infoList.MCSSOURCE.TE106D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE107D_B','MCSSOURCE','TE107D_BVV1','炉床温度2-右侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE107D_B,'TE107D_B','MCSSOURCE','TE107D_BVV1')">
										{{infoList.MCSSOURCE.TE107D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE108D_B','MCSSOURCE','TE108D_BVV1','炉床温度3-左侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE108D_B,'TE108D_B','MCSSOURCE','TE108D_BVV1')">
										{{infoList.MCSSOURCE.TE108D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE109D_B','MCSSOURCE','TE109D_BVV1','炉床温度4-右侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE109D_B,'TE109D_B','MCSSOURCE','TE109D_BVV1')">
										{{infoList.MCSSOURCE.TE109D_B}}&nbsp;℃
									</div>
								</div>
							</div>
							<div class="right">
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE114D_B','MCSSOURCE','TE114D_BVV1','炉床温度9-左侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE114D_B,'TE114D_B','MCSSOURCE','TE114D_BVV1')">
										{{infoList.MCSSOURCE.TE114D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE115D_B','MCSSOURCE','TE115D_BVV1','炉床温度10-右侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE115D_B,'TE115D_B','MCSSOURCE','TE115D_BVV1')">
										{{infoList.MCSSOURCE.TE115D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE111D_B','MCSSOURCE','TE111D_BVV1','炉床温度6-右侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE111D_B,'TE111D_B','MCSSOURCE','TE111D_BVV1')">
										{{infoList.MCSSOURCE.TE111D_B}}&nbsp;℃
									</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TE113D_B','MCSSOURCE','TE113D_BVV1','炉床温度8-右侧')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE113D_B,'TE113D_B','MCSSOURCE','TE113D_BVV1')">
										{{infoList.MCSSOURCE.TE113D_B}}&nbsp;℃
									</div>
								</div>
							</div>
						</div>
						<div class="location4">
							<div class="flex">
								<div class="num4" @click="toIpt(infoList.MCSCZKZ.LCSP_B1,'汽压控制点','LCSP_B1','MCSCZKZ')">
									{{infoList.MCSCZKZ.LCSP_B1}}
								</div>
								<div class="num5">KPa</div>
							</div>
						</div>
						<div class="location5 flex">
							<div class="left flex">
								<div class="num1" @click="toCompon(2,'DPT101D_B','MCSSOURCE','DPT101D_BVV1','左侧料层差压')"
									@dblclick="Cclick(infoList.MCSSOURCE.DPT101D_B,'DPT101D_B','MCSSOURCE','DPT101D_BVV1')">
									{{infoList.MCSSOURCE.DPT101D_B}}
								</div>
								<div class="num3">KPa</div>
							</div>
							<div class="right flex">
								<div class="num1" @click="toCompon(2,'DPT102D_B','MCSSOURCE','DPT102D_BVV1','右侧料层差压')"
									@dblclick="Cclick(infoList.MCSSOURCE.DPT102D_B,'DPT102D_B','MCSSOURCE','DPT102D_BVV1')">
									{{infoList.MCSSOURCE.DPT102D_B}}
								</div>
								<div class="num3">KPa</div>
							</div>
						</div>
						<div class="location6 flex">
							<div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'TLZQ1_B','MCSSOURCE','TLZQ1_BVV1','冷渣器水温')"
										@dblclick="Cclick(infoList.MCSSOURCE.TLZQ1_B,'TLZQ1_B','MCSSOURCE','TLZQ1_BVV1')">
										{{infoList.MCSSOURCE.TLZQ1_B}}
									</div>
									<div class="num3">℃</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'KF_CZ1_B','MCSSOURCE','KF_CZ1_BVV1','1#除渣机调节反馈')"
										@dblclick="Cclick(infoList.MCSSOURCE.KF_CZ1_B,'KF_CZ1_B','MCSSOURCE','KF_CZ1_BVV1')">
										{{infoList.MCSSOURCE.KF_CZ1_B}}
									</div>
									<div class="num3">%</div>
								</div>
							</div>
							<div class="float1">
								<div class="flex">
									<div class="num1" @click="toCompon(2,'TLZQ2_B','MCSSOURCE','TLZQ2_BVV1','冷渣器水温')"
										@dblclick="Cclick(infoList.MCSSOURCE.TLZQ2_B,'TLZQ2_B','MCSSOURCE','TLZQ2_BVV1')">
										{{infoList.MCSSOURCE.TLZQ2_B}}
									</div>
									<div class="num3">℃</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'KF_CZ2_B','MCSSOURCE','KF_CZ2_BVV1','2#除渣机调节反馈')"
										@dblclick="Cclick(infoList.MCSSOURCE.KF_CZ2_B,'KF_CZ2_B','MCSSOURCE','KF_CZ2_BVV1')">
										{{infoList.MCSSOURCE.KF_CZ2_B}}
									</div>
									<div class="num3">%</div>
								</div>
							</div>
							<div class="float1">
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TLZQ3_B','MCSSOURCE','TLZQ3_BVV1','右侧回料器1#调节风门')"
										@dblclick="Cclick(infoList.MCSSOURCE.TLZQ3_B,'TLZQ3_B','MCSSOURCE','TLZQ3_BVV1')">
										{{infoList.MCSSOURCE.TLZQ3_B}}
									</div>
									<div class="num3">℃</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'KF_CZ3_B','MCSSOURCE','KF_CZ3_BVV1','3#除渣反馈')"
										@dblclick="Cclick(infoList.MCSSOURCE.KF_CZ3_B,'KF_CZ3_B','MCSSOURCE','KF_CZ3_BVV1')">
										{{infoList.MCSSOURCE.KF_CZ3_B}}
									</div>
									<div class="num3">%</div>
								</div>
							</div>
							<div class="float1">
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'TLZQ4_B','MCSSOURCE','TLZQ4_BVV1','右侧回料器2#调节风门')"
										@dblclick="Cclick(infoList.MCSSOURCE.TLZQ4_B,'TLZQ4_B','MCSSOURCE','TLZQ4_BVV1')">
										{{infoList.MCSSOURCE.TLZQ4_B}}
									</div>
									<div class="num3">℃</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'KF_CZ4_B','MCSSOURCE','KF_CZ4_BVV1','4#除渣反馈')"
										@dblclick="Cclick(infoList.MCSSOURCE.KF_CZ4_B,'KF_CZ4_B','MCSSOURCE','KF_CZ4_BVV1')">
										{{infoList.MCSSOURCE.KF_CZ4_B}}
									</div>
									<div class="num3">%</div>
								</div>
							</div>
						</div>
					</div>
					<div class="four">
						<div class="location1">
							<div class="flex">
								<div class="words">TC</div>
								<div class="num2" @click="toIpt(infoList.MCSFENG.ECFPKP_TC,'TC','ECFPKP_TC','MCSFENG')">
									{{infoList.MCSFENG.ECFPKP_TC}}
								</div>
							</div>
							<div class="words">二次风压</div>
							<div class="flex">
								<div class="num4"
									@click="toIpt(infoList.MCSCWKZ.BCSBY1SP_B,'二次风压','BCSBY1SP_B','MCSCWKZ')">
									{{infoList.MCSCWKZ.BCSBY1SP_B}}
								</div>
								<div class="num5">KPa</div>
							</div>
							<div class="buttonr" :style="{background:(!infoList.MCSRPQ__p__RSF0G.TS ?'#2AFC30':'red')}"
								@click="infoList.MCSRPQ__p__RSF0G ? toDetail(3,'RSF0G','MCSRPQ__p__RSF0G','','软伺服' ): ''">
								R
							</div>
							<div class="buttonx float1" :style="{
									background:
									infoList.MCSMAN__p__MAN31.RM  == 1 || infoList.MCSMAN__p__MAN32.RM == 1 ? '#2AFC30' : 'red'
								}" @click=" toCompon(0,'CFB_MANECFY',2)">A</div>
						</div>
						<div class="location2">
							<div class="flex">
								<div class="buttonx"
									:style="{background:infoList.MCSXK__p__XK0C &&infoList.MCSXK__p__XK0C.RM==1 ? '#2AFC30' : 'red',}"
									@click="infoList.MCSXK__p__XK0C ? toDetail(1,'XK0C','MCSXK__p__XK0C','','料层调节' ): ''">
									X
								</div>
								<div class="buttonx float1" :style="{
									background:
									infoList.MCSMAN__p__MAN13.RM  == 1 || infoList.MCSMAN__p__MAN14.RM == 1 || infoList.MCSMAN__p__MAN28.RM == 1 || infoList.MCSMAN__p__MAN29.RM == 1 ? '#2AFC30' : 'red'
								}" @click=" toCompon(0,'CFB_MANCZ',4)">A</div>
							</div>
							<div class="words" @click="toCompon(4)" style="cursor: pointer;">冷渣机</div>
						</div>
					</div>
				</div>
			</div>
			<div class="middle">
				<div class="location1">
					<div class="flex">
						<div class="num1" @click="toCompon(2,'TE140_D_B','MCSSOURCE','TE140_D_BVV1','高过再热入口蒸汽温度（左)')"
							@dblclick="Cclick(infoList.MCSSOURCE.TE140_D_B,'TE140_D_B','MCSSOURCE','TE140_D_BVV1')">
							{{infoList.MCSSOURCE.TE140_D_B}}&nbsp;℃
						</div>
					</div>
					<div class="flex">
						<div class="num1" @click="toCompon(2,'TE141_D_B','MCSSOURCE','TE141_D_BVV1','高过再热入口蒸汽温度（右)')"
							@dblclick="Cclick(infoList.MCSSOURCE.TE141_D_B,'TE141_D_B','MCSSOURCE','TE141_D_BVV1')">
							{{infoList.MCSSOURCE.TE141_D_B}}&nbsp;℃
						</div>
					</div>
				</div>
				<div class="location2">
					<div class="flex">
						<div class="buttonr"
							:style="{background:infoList.MCSRPQ__p__QK05 &&!infoList.MCSRPQ__p__QK05.TS? '#2AFC30': 'red',}"
							@click="infoList.MCSRPQ__p__QK05 ? toDetail(2,'QK05','MCSRPQ__p__QK05','','二级左再热前馈' ): ''">
							K
						</div>
						<div class="buttonx right"
							:style="{background:infoList.MCSXK__p__XK0A &&infoList.MCSXK__p__XK0A.RM==1? '#2AFC30' : 'red',}"
							@click="infoList.MCSXK__p__XK0A ? toDetail(1,'XK0A','MCSXK__p__XK0A','','右1烟气挡板调节' ): ''">
							X
						</div>
					</div>
					<div class="flex up">
						<div class="buttonr"
							:style="{background:infoList.MCSRPQ__p__QK06 &&!infoList.MCSRPQ__p__QK06.TS? '#2AFC30': 'red',}"
							@click="infoList.MCSRPQ__p__QK06 ? toDetail(2,'QK06','MCSRPQ__p__QK06','','二级右再热前馈' ): ''">
							K
						</div>
						<div class="buttonx right"
							:style="{background:infoList.MCSXK__p__XK0B &&infoList.MCSXK__p__XK0B.RM==1 ? '#2AFC30' : 'red',}"
							@click="infoList.MCSXK__p__XK0B ? toDetail(1,'XK0B','MCSXK__p__XK0B','','右2烟气挡板调节' ): ''">
							X
						</div>
					</div>
					<div class="flex up">
						<div class="buttonr"
							:style="{background:infoList.MCSRPQ__p__QK0A &&!infoList.MCSRPQ__p__QK0A.TS? '#2AFC30': 'red',}"
							@click="infoList.MCSRPQ__p__QK0A ? toDetail(2,'QK0A','MCSRPQ__p__QK0A','','QK0A设定值' ): ''">
							K
						</div>
						<div class="buttonx right"
							:style="{background:infoList.MCSXK__p__XK0K &&infoList.MCSXK__p__XK0K.RM==1 ? '#2AFC30' : 'red',}"
							@click="infoList.MCSXK__p__XK0K ? toDetail(1,'XK0K','MCSXK__p__XK0K','','左1烟气挡板调节' ): ''">
							X
						</div>
					</div>
					<div class="flex up">
						<div class="buttonr"
							:style="{background:infoList.MCSRPQ__p__QK0B &&!infoList.MCSRPQ__p__QK0B.TS? '#2AFC30': 'red',}"
							@click="infoList.MCSRPQ__p__QK0B ? toDetail(2,'QK0B','MCSRPQ__p__QK0B','','QK0B设定值' ): ''">
							K
						</div>
						<div class="buttonx right"
							:style="{background:infoList.MCSXK__p__XK0L &&infoList.MCSXK__p__XK0L.RM==1 ? '#2AFC30' : 'red',}"
							@click="infoList.MCSXK__p__XK0L ? toDetail(1,'XK0L','MCSXK__p__XK0L','','左1烟气挡板调节' ): ''">
							X
						</div>
					</div>
				</div>
				<div class="location3 flex">
					<div>
						<div class="words" @click="toCompon(3)" style="cursor: pointer;">再热汽温</div>
						<div class="flex">
							<div class="num4" @click="toIpt(infoList.MCSQIWEN.ZQTSP_B2,'再热汽温','ZQTSP_B2','MCSQIWEN')">
								{{infoList.MCSQIWEN.ZQTSP_B2}}&nbsp;℃
							</div>
						</div>
					</div>
					<div class="buttonx float1" :style="{
						background:
						infoList.MCSMAN__p__MAN33.RM  == 1 || infoList.MCSMAN__p__MAN34.RM == 1 || infoList.MCSMAN__p__MAN35.RM == 1 || infoList.MCSMAN__p__MAN36.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'CFB_MANQWZ',4)">A</div>
				</div>
				<div class="location4">
					<div class="float1">
						<div class="flex">
							<div class="num1"
								@click="toCompon(2,'TE142_D_B','MCSSOURCE','TE142_D_BVV1','低过再热入口蒸汽温度（左)')"
								@dblclick="Cclick(infoList.MCSSOURCE.TE142_D_B,'TE142_D_B','MCSSOURCE','TE142_D_BVV1')">
								{{infoList.MCSSOURCE.TE142_D_B}}&nbsp;℃
							</div>
						</div>
						<div class="flex">
							<div class="num6" @click="toIpt(infoList.MCSFENG.C_O2,'低温过热器','C_O2','MCSFENG')">
								{{infoList.MCSFENG.C_O2}}&nbsp;%
							</div>
						</div>
					</div>
					<div class="flex">
						<div class="flex">
							<div class="num1" @click="toCompon(2,'AT101AD_B','MCSSOURCE','AT101AD_BVV1','烟气含氧量')"
								@dblclick="Cclick(infoList.MCSSOURCE.AT101AD_B,'AT101AD_B','MCSSOURCE','AT101AD_BVV1')">
								{{infoList.MCSSOURCE.AT101AD_B}}&nbsp;%
							</div>
						</div>
						<div class="float2 flex">
							<div class="num1" @click="toCompon(2,'AT101BD_B','MCSSOURCE','AT101BD_BVV1','烟气含氧量')"
								@dblclick="Cclick(infoList.MCSSOURCE.AT101BD_B,'AT101BD_B','MCSSOURCE','AT101BD_BVV1')">
								{{infoList.MCSSOURCE.AT101BD_B}}&nbsp;%
							</div>
						</div>
					</div>
					<div class="flex">
						<div class="button1">
							<div class="word1" :style="{background:infoList.MCSYCL.SEL_O2 == 1 ? '#2AFC30' : 'red',}"
								@click="toIpt('1', '氧量左','SEL_O2','MCSYCL','true')">
								氧量左
							</div>
						</div>
						<div class="button1 float3">
							<div class="word1" :style="{background:infoList.MCSYCL.SEL_O2 == 2 ? '#2AFC30' : 'red',}"
								@click="toIpt('2', '氧量右','SEL_O2','MCSYCL','true')">
								氧量右
							</div>
						</div>
					</div>
					<div class="float4 flex">
						<div class="flex">
							<div class="num1" @click="toCompon(2,'TE154_D_B','MCSSOURCE','TE154_D_BVV1','省煤器后烟气温度')"
								@dblclick="Cclick(infoList.MCSSOURCE.TE154_D_B,'TE154_D_B','MCSSOURCE','TE154_D_BVV1')">
								{{infoList.MCSSOURCE.TE154_D_B}}&nbsp;℃
							</div>
						</div>
						<div class="float2 flex">
							<div class="num1" @click="toCompon(2,'PT148_D_B','MCSSOURCE','PT148_D_BVV1','省煤器后烟气负压')"
								@dblclick="Cclick(infoList.MCSSOURCE.PT148_D_B,'PT148_D_B','MCSSOURCE','PT148_D_BVV1')">
								{{infoList.MCSSOURCE.PT148_D_B}}&nbsp;℃
							</div>
						</div>
					</div>
				</div>
				<div class="location5">
					<div class="flex">
						<div class="words">目标值</div>
						<div class="flex">
							<div class="num1" @click="toCompon(2,'FGFSP_B2','MCSFENG','FGFSP_B2VV1','风量目标值')"
								@dblclick="Cclick(infoList.MCSFENG.FGFSP_B2,'FGFSP_B2','MCSFENG','FGFSP_B2VV1')">
								{{infoList.MCSFENG.FGFSP_B2}}&nbsp;m3/h
							</div>
						</div>
					</div>
					<div class="flex">
						<div class="words">测量值</div>
						<div class="flex">
							<div class="num1" @click="toCompon(2,'ECFL','MCSYCL','ECFLVV1','二次风量')"
								@dblclick="Cclick(infoList.MCSYCL.ECFL,'ECFL','MCSYCL','ECFLVV1')">
								{{infoList.MCSYCL.ECFL}}&nbsp;m3/h
							</div>
						</div>
					</div>
				</div>
				<div class="location6">
					<div class="flex">
						<div class="words">目标值</div>
						<div class="flex">
							<div class="num1" @click="toCompon(2,'FGFSP_B1','MCSFENG','FGFSP_B1VV1','风量目标值')"
								@dblclick="Cclick(infoList.MCSFENG.FGFSP_B1,'FGFSP_B1','MCSFENG','FGFSP_B1VV1')">
								{{infoList.MCSFENG.FGFSP_B1}}&nbsp;m3/h
							</div>
						</div>
					</div>
					<div class="flex">
						<div class="words">测量值</div>
						<div class="flex">
							<div class="num1" @click="toCompon(2,'JSFL','MCSYCL','JSFLVV1','一次风量')"
								@dblclick="Cclick(infoList.MCSYCL.JSFL,'JSFL','MCSYCL','JSFLVV1')">
								{{infoList.MCSYCL.JSFL}}&nbsp;m3/h
							</div>
						</div>
					</div>
				</div>
				<div class="location7 flex">
					<div class="flex">
						<div class="num1" @click="toCompon(2,'TE158_D_B','MCSSOURCE','TE158_D_BVV1','左侧排烟温度')"
							@dblclick="Cclick(infoList.MCSSOURCE.TE158_D_B,'TE158_D_B','MCSSOURCE','TE158_D_BVV1')">
							{{infoList.MCSSOURCE.TE158_D_B}}&nbsp;℃
						</div>
					</div>
					<div class="float1 flex">
						<div class="num1" @click="toCompon(2,'TE159_D_B','MCSSOURCE','TE159_D_BVV1','右侧排烟温度')"
							@dblclick="Cclick(infoList.MCSSOURCE.TE159_D_B,'TE159_D_B','MCSSOURCE','TE159_D_BVV1')">
							{{infoList.MCSSOURCE.TE159_D_B}}&nbsp;℃
						</div>
					</div>
				</div>
				<div class="location8 flex">
					<div class="flex">
						<div class="num4" @click="toCompon(2,'PT152_D_B','MCSSOURCE','PT152_D_BVV1','引风机入口压力')"
							@dblclick="Cclick(infoList.MCSSOURCE.PT152_D_B,'PT152_D_B','MCSSOURCE','PT152_D_BVV1')">
							{{infoList.MCSSOURCE.PT152_D_B}}&nbsp;KPa
						</div>
					</div>
				</div>
			</div>
			<div class="rightt">
				<div class="one flex">
					<div class="tap">
						<div class="flex">
							<div class="flex">
								<div class="num1" @click="toCompon(2,'TE165_B','MCSSOURCE','TE165_BVV1','二级甲减温器蒸汽出温度')"
									@dblclick="Cclick(infoList.MCSSOURCE.TE165_B,'TE165_B','MCSSOURCE','TE165_BVV1')">
									{{infoList.MCSSOURCE.TE165_B}}
								</div>
								<div class="num3">℃</div>
							</div>
							<div class="valve float1"></div>
							<div class="float2 flex">
								<div class="num1" @click="toCompon(2,'KF_QW3_B','MCSSOURCE','KF_QW3_BVV1','3#减温调节阀反馈')"
									@dblclick="Cclick(infoList.MCSSOURCE.KF_QW3_B,'KF_QW3_B','MCSSOURCE','KF_QW3_BVV1')">
									{{infoList.MCSSOURCE.KF_QW3_B}}
								</div>
								<div class="num3">%</div>
							</div>
						</div>
						<div class="line1 flex">
							<div class="flex">
								<div class="num1" @click="toCompon(2,'TE166_B','MCSSOURCE','TE166_BVV1','二级乙减温器蒸汽出温度')"
									@dblclick="Cclick(infoList.MCSSOURCE.TE166_B,'TE166_B','MCSSOURCE','TE166_BVV1')">
									{{infoList.MCSSOURCE.TE166_B}}
								</div>
								<div class="num3">℃</div>
							</div>
							<div class="valve float1"></div>
							<div class="float2 flex">
								<div class="num1" @click="toCompon(2,'KF_QW4_B','MCSSOURCE','KF_QW4_BVV1','4#减温调节阀反馈')"
									@dblclick="Cclick(infoList.MCSSOURCE.KF_QW4_B,'KF_QW4_B','MCSSOURCE','KF_QW4_BVV1')">
									{{infoList.MCSSOURCE.KF_QW4_B}}
								</div>
								<div class="num3">%</div>
							</div>
						</div>
						<div class="line2 flex">
							<div class="flex">
								<div class="num1" @click="toCompon(2,'TE163_B','MCSSOURCE','TE163_BVV1','一级甲减温器蒸汽出温度')"
									@dblclick="Cclick(infoList.MCSSOURCE.TE163_B,'TE163_B','MCSSOURCE','TE163_BVV1')">
									{{infoList.MCSSOURCE.TE163_B}}
								</div>
								<div class="num3">℃</div>
							</div>
							<div class="valve float3"></div>
							<div class="float2 flex">
								<div class="num1" @click="toCompon(2,'KF_QW1_B','MCSSOURCE','KF_QW1_BVV1','1#减温调节阀反馈')"
									@dblclick="Cclick(infoList.MCSSOURCE.KF_QW1_B,'KF_QW1_B','MCSSOURCE','KF_QW1_BVV1')">
									{{infoList.MCSSOURCE.KF_QW1_B}}
								</div>
								<div class="num3">%</div>
							</div>
						</div>
						<div class="line3 flex">
							<div class="flex">
								<div class="num1" @click="toCompon(2,'TE164_B','MCSSOURCE','TE164_BVV1','一级乙减温器蒸汽出温度')"
									@dblclick="Cclick(infoList.MCSSOURCE.TE164_B,'TE164_B','MCSSOURCE','TE164_BVV1')">
									{{infoList.MCSSOURCE.TE164_B}}
								</div>
								<div class="num3">℃</div>
							</div>
							<div class="valve float4"></div>
							<div class="float2 flex">
								<div class="num1" @click="toCompon(2,'KF_QW2_B','MCSSOURCE','KF_QW2_BVV1','2#减温调节阀反馈')"
									@dblclick="Cclick(infoList.MCSSOURCE.KF_QW2_B,'KF_QW2_B','MCSSOURCE','KF_QW2_BVV1')">
									{{infoList.MCSSOURCE.KF_QW2_B}}
								</div>
								<div class="num3">%</div>
							</div>
						</div>
						<div class="line4 flex">
							<div class="flex">
								<div class="num1" @click="toCompon(2,'KF_SW1_B','MCSSOURCE','KF_SW1_BVV1','汽包水位A调节阀反馈')"
									@dblclick="Cclick(infoList.MCSSOURCE.KF_SW1_B,'KF_SW1_B','MCSSOURCE','KF_SW1_BVV1')">
									{{infoList.MCSSOURCE.KF_SW1_B}}
								</div>
								<div class="num3">%</div>
							</div>
							<div class="float3 valve"></div>
						</div>
						<div class="line5 flex">
							<div class="flex">
								<div class="num1" @click="toCompon(2,'KF_SW2_B','MCSSOURCE','KF_SW2_BVV1','汽包水位B调节阀反馈')"
									@dblclick="Cclick(infoList.MCSSOURCE.KF_SW2_B,'KF_SW2_B','MCSSOURCE','KF_SW2_BVV1')">
									{{infoList.MCSSOURCE.KF_SW2_B}}
								</div>
								<div class="num3">%</div>
							</div>
							<div class="float5 valve"></div>
						</div>
					</div>
					<div class="switch">
						<div class="flex">
							<div class="buttonr" :style="{
								background:infoList.MCSRPQ__p__QK01 && !infoList.MCSRPQ__p__QK01.TS
					    	    ? '#2AFC30'
					    	    : 'red',}" @click="infoList.MCSRPQ__p__QK01
								? toDetail(2,'QK01','MCSRPQ__p__QK01','','电负荷前馈'): ''">
								K
							</div>
							<!-- <div class="buttonr right">R</div> -->
						</div>
						<div class="top flex">
							<div class="buttonr" :style="{
								background:infoList.MCSRPQ__p__QK02 && !infoList.MCSRPQ__p__QK02.TS
					    	    ? '#2AFC30'
					    	    : 'red',}" @click="infoList.MCSRPQ__p__QK02
								? toDetail(2,'QK02','MCSRPQ__p__QK02','','QK02设定值'): ''">
								K
							</div>
							<div class="buttonx right"
								:style="{background:infoList.MCSXK__p__XK07 &&infoList.MCSXK__p__XK07.RM==1 ? '#2AFC30' : 'red',}"
								@click="infoList.MCSXK__p__XK07 ? toDetail(1,'XK07','MCSXK__p__XK07','','二级左主汽温度调节' ): ''">
								X
							</div>
							<!-- <div class="buttonx right">X</div> -->
						</div>
						<div class="top flex">
							<div class="buttonr" :style="{
								background:infoList.MCSRPQ__p__QK03 && !infoList.MCSRPQ__p__QK03.TS
					    	    ? '#2AFC30'
					    	    : 'red',}" @click="infoList.MCSRPQ__p__QK03
								? toDetail(2,'QK03','MCSRPQ__p__QK03','','QK03设定值'): ''">
								K
							</div>
							<div class="buttonx right"
								:style="{background:infoList.MCSXK__p__XK08 &&infoList.MCSXK__p__XK08.RM==1 ? '#2AFC30' : 'red',}"
								@click="infoList.MCSXK__p__XK08 ? toDetail(1,'XK08','MCSXK__p__XK08','','二级右主汽温度调节' ): ''">
								X
							</div>
							<!-- <div class="buttonx right">X</div> -->
							<div class="words" @click="toCompon(6)" style="cursor: pointer;">减温水</div>
						</div>
						<div class="top flex">
							<div class="buttonr" :style="{
								background:infoList.MCSRPQ__p__QK08 && !infoList.MCSRPQ__p__QK08.TS
					    	    ? '#2AFC30'
					    	    : 'red',}" @click="infoList.MCSRPQ__p__QK08
								? toDetail(2,'QK08','MCSRPQ__p__QK08','','QK08设定值'): ''">
								K
							</div>
							<div class="buttonx right"
								:style="{background:infoList.MCSXK__p__XK0I &&!infoList.MCSXK__p__XK0I.RM ? '#2AFC30' : 'red',}"
								@click="infoList.MCSXK__p__XK0I ? toDetail(1,'XK0I','MCSXK__p__XK0I','','一级左主汽温度调节' ): ''">
								X
							</div>
							<!-- <div class="buttonx right">X</div> -->
						</div>
						<div class="top flex">
							<div class="buttonr" :style="{
								background:infoList.MCSRPQ__p__QK09 && !infoList.MCSRPQ__p__QK09.TS
					    	    ? '#2AFC30'
					    	    : 'red',}" @click="infoList.MCSRPQ__p__QK09
								? toDetail(2,'QK09','MCSRPQ__p__QK09','','QK09设定值'): ''">
								K
							</div>
							<div class="buttonx right"
								:style="{background:infoList.MCSXK__p__XK0J &&!infoList.MCSXK__p__XK0J.RM ? '#2AFC30' : 'red',}"
								@click="infoList.MCSXK__p__XK0J ? toDetail(1,'XK0J','MCSXK__p__XK0J','','一级右主汽温度调节' ): ''">
								X
							</div>
							<!-- <div class="buttonx right">X</div> -->
							<div class="buttonr right" :style="{
							background:
							infoList.MCSMAN__p__MAN7.RM  == 1 || infoList.MCSMAN__p__MAN8.RM == 1 || infoList.MCSMAN__p__MAN9.RM == 1 || infoList.MCSMAN__p__MAN10.RM == 1 ? '#2AFC30' : 'red'
							}" @click="toCompon(0,'CFB_MANQW',4)">A</div>
						</div>
						<div class="float1 flex">
							<div class="valve"></div>
							<div class="float2">
								<div class=" flex">
									<div class="num1" @click="toCompon(2,'TE101D_B','MCSSOURCE','TE101D_BVV1','给水温度')"
										@dblclick="Cclick(infoList.MCSSOURCE.TE101D_B,'TE101D_B','MCSSOURCE','TE101D_BVV1')">
										{{infoList.MCSSOURCE.TE101D_B}}
									</div>
									<div class="num3">℃</div>
								</div>
								<div class="flex">
									<div class="num1" @click="toCompon(2,'PT101D_B','MCSSOURCE','PT101D_BVV1','给水压力')"
										@dblclick="Cclick(infoList.MCSSOURCE.PT101D_B,'PT101D_B','MCSSOURCE','PT101D_BVV1')">
										{{infoList.MCSSOURCE.PT101D_B}}
									</div>
									<div class="num3">MPa</div>
								</div>
							</div>
						</div>
						<div class="float3 flex">
							<div class="buttonx"
								:style="{background:infoList.MCSXK__p__XK01 &&infoList.MCSXK__p__XK01.RM==1 ? '#2AFC30' : 'red',}"
								@click="infoList.MCSXK__p__XK01 ? toDetail(1,'XK01','MCSXK__p__XK01','','汽包液位调节' ): ''">
								X
							</div>
							<div class="buttonr right"
								:style="{background:infoList.MCSXK__p__XK0M &&infoList.MCSXK__p__XK0M.RM==1 ? '#2AFC30' : 'red',}"
								@click="infoList.MCSXK__p__XK0M ? toDetail(1,'XK0M','MCSXK__p__XK0M','','主给水阀门调节先控' ): ''">
								X
							</div>
							<div class="buttonr right"
								:style="{background:infoList.MCSXK__p__XK0N &&infoList.MCSXK__p__XK0N.RM==1 ? '#2AFC30' : 'red',}"
								@click="infoList.MCSXK__p__XK0N ? toDetail(1,'XK0N','MCSXK__p__XK0N','','副给水阀门调节先控' ): ''">
								X
							</div>
							<div class="buttonr right" :style="{
						background:
						infoList.MCSMAN__p__MAN5.RM  == 1 || infoList.MCSMAN__p__MAN6.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'CFB_MANGS',2)">A</div>
							<div class="float4 flex">
								<div class="num1" @click="toCompon(2,'FT101D_B','MCSSOURCE','FT101D_BVV1','给水流量')"
									@dblclick="Cclick(infoList.MCSSOURCE.FT101D_B,'FT101D_B','MCSSOURCE','FT101D_BVV1')">
									{{infoList.MCSSOURCE.FT101D_B}}
								</div>
								<div class="num3">t/h</div>
							</div>
						</div>

					</div>
				</div>
				<div class="two flex">
					<div>
						<div class="up">
							<div class="flex">
								<div class="float1 flex">
									<div class="num1"
										@click="toCompon(2,'KF_ECFBP_B','MCSSOURCE','KF_ECFBP_BVV1','二次风机变频反馈')"
										@dblclick="Cclick(infoList.MCSSOURCE.KF_ECFBP_B,'KF_ECFBP_B','MCSSOURCE','KF_ECFBP_BVV1')">
										{{infoList.MCSSOURCE.KF_ECFBP_B}}
									</div>
									<div class="num3">m3/h</div>
								</div>
								<div class="float2">
									<div class="flex">
										<div class="num1"
											@click="toCompon(2,'ECFBPAO_B','MCSAO','ECFBPAO_BVV1','1#二次风机变频阀位输出')"
											@dblclick="Cclick(infoList.MCSAO.ECFBPAO_B,'ECFBPAO_B','MCSAO','ECFBPAO_BVV1')">
											{{infoList.MCSAO.ECFBPAO_B}}
										</div>
										<div class="num3">%</div>
									</div>
									<div class="flex">
										<div class="num1"
											@click="toCompon(2,'KF_ECFDB_B','MCSSOURCE','KF_ECFDB_BVV1','二次风机挡板反馈')"
											@dblclick="Cclick(infoList.MCSSOURCE.KF_ECFDB_B,'KF_ECFDB_B','MCSSOURCE','KF_ECFDB_BVV1')">
											{{infoList.MCSSOURCE.KF_ECFDB_B}}
										</div>
										<div class="num3">%</div>
									</div>
								</div>
								<div class="fan float3"></div>
								<div class="float4 flex">
									<div class="num1"
										@click="toCompon(2,'II_ECFJ_B','MCSSOURCE','II_ECFJ_BVV1','1#二次风机电流')"
										@dblclick="Cclick(infoList.MCSSOURCE.II_ECFJ_B,'II_ECFJ_B','MCSSOURCE','II_ECFJ_BVV1')">
										{{infoList.MCSSOURCE.II_ECFJ_B}}
									</div>
									<div class="num3">A</div>
								</div>
								<div>
									<div class="valve float5"></div>
									<div class="float6">
										<div class="flex">
											<div class="num1"
												@click="toCompon(2,'ECFDBAO_B','MCSAO','ECFDBAO_BVV1','1#二次风机挡板阀位输出')"
												@dblclick="Cclick(infoList.MCSAO.ECFDBAO_B,'ECFDBAO_B','MCSAO','ECFDBAO_BVV1')">
												{{infoList.MCSAO.ECFDBAO_B}}
											</div>
											<div class="num3">%</div>
										</div>
										<div class="flex">
											<div class="num1"
												@click="toCompon(2,'ECFDBAO_B','MCSAO','ECFDBAO_BVV1','1#二次风机挡板阀位输出')"
												@dblclick="Cclick(infoList.MCSAO.ECFDBAO_B,'ECFDBAO_B','MCSAO','ECFDBAO_BVV1')">
												{{infoList.MCSAO.ECFDBAO_B}}
											</div>
											<div class="num3">%</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="down">
							<div class="flex">
								<div class="float1 flex">
									<div class="num1">0.0</div>
									<div class="num3">m3/h</div>
								</div>
								<div class="float2">
									<div class="flex">
										<div class="num1"
											@click="toCompon(2,'KF_ECFBP2_B','MCSSOURCE','KF_ECFBP2_BVV1','2#二次风变频反馈')"
											@dblclick="Cclick(infoList.MCSSOURCE.KF_ECFBP2_B,'KF_ECFBP2_B','MCSSOURCE','KF_ECFBP2_BVV1')">
											{{infoList.MCSSOURCE.KF_ECFBP2_B}}
										</div>
										<div class="num3">%</div>
									</div>
									<div class="flex">
										<div class="num1"
											@click="toCompon(2,'ECFBPAO2_B','MCSAO','ECFBPAO2_BVV1','2#二次风机变频阀位输出')"
											@dblclick="Cclick(infoList.MCSAO.ECFBPAO2_B,'ECFBPAO2_B','MCSAO','ECFBPAO2_BVV1')">
											{{infoList.MCSAO.ECFBPAO2_B}}
										</div>
										<div class="num3">%</div>
									</div>
								</div>
								<div class="fan float3"></div>
								<div class="float4 flex">
									<div class="num1"
										@click="toCompon(2,'II_ECFJ2_B','MCSSOURCE','II_ECFJ2_BVV1','2#二次风机电流')"
										@dblclick="Cclick(infoList.MCSSOURCE.II_ECFJ2_B,'II_ECFJ2_B','MCSSOURCE','II_ECFJ2_BVV1')">
										{{infoList.MCSSOURCE.II_ECFJ2_B}}
									</div>
									<div class="num3">A</div>
								</div>
								<div>
									<div class="valve float5"></div>
									<div class="float6">
										<div class="flex">
											<div class="num1"
												@click="toCompon(2,'KF_ECFDB2_B','MCSSOURCE','KF_ECFDB2_BVV1','2#二次风挡板反馈')"
												@dblclick="Cclick(infoList.MCSSOURCE.KF_ECFDB2_B,'KF_ECFDB2_B','MCSSOURCE','KF_ECFDB2_BVV1')">
												{{infoList.MCSSOURCE.KF_ECFDB2_B}}
											</div>
											<div class="num3">%</div>
										</div>
										<div class="flex">
											<div class="num1"
												@click="toCompon(2,'ECFDBAO2_B','MCSAO','ECFDBAO2_BVV1','2#二次风机挡板阀位输出')"
												@dblclick="Cclick(infoList.MCSAO.ECFDBAO2_B,'ECFDBAO2_B','MCSAO','ECFDBAO2_BVV1')">
												{{infoList.MCSAO.ECFDBAO2_B}}
											</div>
											<div class="num3">%</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="buttons">
						<div class="flex">
							<div class="button1">
								<div class="word1"
									@click="toIpt(infoList.MCSFENG.O2_ECF_QY,'按钮','O2_ECF_QY','MCSFENG')">
									{{infoList.MCSFENG.O2_ECF_QY ? "氧量投用" : "氧量切除"}}
								</div>
							</div>
							<div class="buttonx right"
								:style="{background:infoList.MCSXK__p__XK0Q &&infoList.MCSXK__p__XK0Q.RM==1 ? '#2AFC30' : 'red',}"
								@click="infoList.MCSXK__p__XK0Q ? toDetail(1,'XK0Q','MCSXK__p__XK0D','','烟气含氧量调节先控' ): ''">
								X
							</div>
						</div>
						<div class="line1 flex">
							<div class="button2">
								<div class="word2"
									@click="toIpt(infoList.MCSFENG.ECF_CWSEL,'按钮','ECF_CWSEL','MCSFENG')">
									{{infoList.MCSFENG.ECF_CWSEL ? "床温投用" : "床温切除"}}
								</div>
							</div>
							<div class="buttonr right"
								:style="{background:(!infoList.MCSRPQ__p__RSF0E.TS ?'#2AFC30':'red')}"
								@click="infoList.MCSRPQ__p__RSF0E ? toDetail(3,'RSF0E','MCSRPQ__p__RSF0E','','床温二次风软伺服' ): ''">
								R
							</div>
						</div>
						<div class="line1 flex">
							<div class="buttonr" :style="{background:(!infoList.MCSRPQ__p__RSF07.TS ?'#2AFC30':'red')}"
								@click="infoList.MCSRPQ__p__RSF07 ? toDetail(3,'RSF07','MCSRPQ__p__RSF07','','二次风电流纠偏' ): ''">
								R
							</div>
							<div class="buttonx right"
								:style="{background:infoList.MCSXK__p__XK0D &&!infoList.MCSXK__p__XK0D.RM ? '#2AFC30' : 'red',}"
								@click="infoList.MCSXK__p__XK0D ? toDetail(1,'XK0D','MCSXK__p__XK0D','','二次风挡板调节' ): ''">
								X
							</div>
							<div class="buttonx right"
								:style="{background:infoList.MCSMAN__p__MAN17.RM  == 1 || infoList.MCSMAN__p__MAN18.RM == 1 || infoList.MCSMAN__p__MAN26.RM == 1 || infoList.MCSMAN__p__MAN30.RM == 1 ? '#2AFC30' : 'red'}"
								@click="toCompon(0,'CFB_MANECF',4)">
								A
							</div>
							<div class="buttonx right"
								:style="{background:infoList.MCSXK__p__XK0E &&infoList.MCSXK__p__XK0E.RM==1 ? '#2AFC30' : 'red',}"
								@click="infoList.MCSXK__p__XK0E ? toDetail(1,'XK0E','MCSXK__p__XK0E','','二次风变频调节' ): ''">
								X
							</div>
						</div>
						<div class="words" @click="toCompon(7)" style="cursor: pointer;">二次风机</div>
					</div>
				</div>
				<div class="three flex">
					<div>
						<div class="up">
							<div class="flex">
								<div class="float1 flex">
									<div class="num1">0</div>
									<div class="num3">m3/h</div>
								</div>
								<div class="float2">
									<div class="flex">
										<div class="num1"
											@click="toCompon(2,'KF_YCFBP_B','MCSSOURCE','KF_YCFBP_BVV1','一次风机变频反馈')"
											@dblclick="Cclick(infoList.MCSSOURCE.KF_YCFBP_B,'KF_YCFBP_B','MCSSOURCE','KF_YCFBP_BVV1')">
											{{infoList.MCSSOURCE.KF_YCFBP_B}}
										</div>
										<div class="num3">%</div>
									</div>
									<div class="flex">
										<div class="num1"
											@click="toCompon(2,'YCFBPAO_B','MCSAO','YCFBPAO_BVV1','1#一次风机变频阀位输出')"
											@dblclick="Cclick(infoList.MCSAO.YCFBPAO_B,'YCFBPAO_B','MCSAO','YCFBPAO_BVV1')">
											{{infoList.MCSAO.YCFBPAO_B}}
										</div>
										<div class="num3">%</div>
									</div>
								</div>
								<div class="fan float3"></div>
								<div class="float4 flex">
									<div class="num1"
										@click="toCompon(2,'II_YCFJ_B','MCSSOURCE','II_YCFJ_BVV1','1#一次风机电流')"
										@dblclick="Cclick(infoList.MCSSOURCE.II_YCFJ_B,'II_YCFJ_B','MCSSOURCE','II_YCFJ_BVV1')">
										{{infoList.MCSSOURCE.II_YCFJ_B}}
									</div>
									<div class="num3">A</div>
								</div>
								<div>
									<div class="valve float5"></div>
									<div class="float6">
										<div class="flex">
											<div class="num1"
												@click="toCompon(2,'KF_YCFDB_B','MCSSOURCE','KF_YCFDB_BVV1','一次风机挡板反馈')"
												@dblclick="Cclick(infoList.MCSSOURCE.KF_YCFDB_B,'KF_YCFDB_B','MCSSOURCE','KF_YCFDB_BVV1')">
												{{infoList.MCSSOURCE.KF_YCFDB_B}}
											</div>
											<div class="num3">%</div>
										</div>
										<div class="flex">
											<div class="num1"
												@click="toCompon(2,'YCFDBAO_B','MCSAO','YCFDBAO_BVV1','1#一次风机挡板阀位输出')"
												@dblclick="Cclick(infoList.MCSAO.YCFDBAO_B,'YCFDBAO_B','MCSAO','YCFDBAO_BVV1')">
												{{infoList.MCSAO.YCFDBAO_B}}
											</div>
											<div class="num3">%</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="down">
							<div class="flex">
								<div class="float1 flex">
									<div class="num1">0</div>
									<div class="num3">m3/h</div>
								</div>
								<div class="float2">
									<div class="flex">
										<div class="num1"
											@click="toCompon(2,'KF_YCFBP2_B','MCSSOURCE','KF_YCFBP2_BVV1','2#一次风变频反馈')"
											@dblclick="Cclick(infoList.MCSSOURCE.KF_YCFBP2_B,'KF_YCFBP2_B','MCSSOURCE','KF_YCFBP2_BVV1')">
											{{infoList.MCSSOURCE.KF_YCFBP2_B}}
										</div>
										<div class="num3">%</div>
									</div>
									<div class="flex">
										<div class="num1"
											@click="toCompon(2,'YCFBPAO2_B','MCSAO','YCFBPAO2_BVV1','2#一次风机变频阀位输出')"
											@dblclick="Cclick(infoList.MCSAO.YCFBPAO2_B,'YCFBPAO2_B','MCSAO','YCFBPAO2_BVV1')">
											{{infoList.MCSAO.YCFBPAO2_B}}
										</div>
										<div class="num3">%</div>
									</div>
								</div>
								<div class="fan float3"></div>
								<div class="float4 flex">
									<div class="num1"
										@click="toCompon(2,'II_YCFJ2_B','MCSSOURCE','II_YCFJ2_BVV1','2#一次风机电流')"
										@dblclick="Cclick(infoList.MCSSOURCE.II_YCFJ2_B,'II_YCFJ2_B','MCSSOURCE','II_YCFJ2_BVV1')">
										{{infoList.MCSSOURCE.II_YCFJ2_B}}
									</div>
									<div class="num3">A</div>
								</div>
								<div>
									<div class="valve float5"></div>
									<div class="float6">
										<div class="flex">
											<div class="num1"
												@click="toCompon(2,'KF_YCFDB2_B','MCSSOURCE','KF_YCFDB2_BVV1','2#一次风挡板反馈')"
												@dblclick="Cclick(infoList.MCSSOURCE.KF_YCFDB2_B,'KF_YCFDB2_B','MCSSOURCE','KF_YCFDB2_BVV1')">
												{{infoList.MCSSOURCE.KF_YCFDB2_B}}
											</div>
											<div class="num3">%</div>
										</div>
										<div class="flex">
											<div class="num1"
												@click="toCompon(2,'YCFDBAO2_B','MCSAO','YCFDBAO2_BVV1','2#一次风机挡板阀位输出')"
												@dblclick="Cclick(infoList.MCSAO.YCFDBAO2_B,'YCFDBAO2_B','MCSAO','YCFDBAO2_BVV1')">
												{{infoList.MCSAO.YCFDBAO2_B}}
											</div>
											<div class="num3">%</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="buttons">
						<div class="buttonr float1"
							:style="{background:(!infoList.MCSRPQ__p__RSF08.TS ?'#2AFC30':'red')}"
							@click="infoList.MCSRPQ__p__RSF08 ? toDetail(3,'RSF08','MCSRPQ__p__RSF08','','一次风机电流纠偏' ): ''">
							R
						</div>
						<div class="line1 flex">
							<div class="buttonr" :style="{background:(!infoList.MCSRPQ__p__RSF04.TS ?'#2AFC30':'red')}"
								@click="infoList.MCSRPQ__p__RSF04 ? toDetail(3,'RSF04','MCSRPQ__p__RSF04','','一次风变频软伺服' ): ''">
								R
							</div>
							<div class="buttonx right" :style="{
						background:
						infoList.MCSMAN__p__MAN15.RM  == 1 || infoList.MCSMAN__p__MAN16.RM == 1 || infoList.MCSMAN__p__MAN25.RM == 1 || infoList.MCSMAN__p__MAN27.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'CFB_MANYCF',4)">A</div>
							<div class="buttonr right"
								:style="{background:(!infoList.MCSRPQ__p__RSF05.TS ?'#2AFC30':'red')}"
								@click="infoList.MCSRPQ__p__RSF05 ? toDetail(3,'RSF05','MCSRPQ__p__RSF05','','一次风挡板软伺服' ): ''">
								R
							</div>
						</div>
						<div class="words float2" @click="toCompon(8)" style="cursor: pointer;">一次风机</div>
					</div>
				</div>
				<div class="four flex">
					<div>
						<div class="up flex">
							<div>
								<div class="valve float1"></div>
								<div class="float2">
									<div class="flex">
										<div class="num1"
											@click="toCompon(2,'KF_YFDB1_B','MCSSOURCE','KF_YFDB1_BVV1','1#引风机挡板反馈')"
											@dblclick="Cclick(infoList.MCSSOURCE.KF_YFDB1_B,'KF_YFDB1_B','MCSSOURCE','KF_YFDB1_BVV1')">
											{{infoList.MCSSOURCE.KF_YFDB1_B}}
										</div>
										<div class="num3">%</div>
									</div>
									<div class="flex">
										<div class="num1"
											@click="toCompon(2,'YFDBAO1_B','MCSAO','YFDBAO1_BVV1','1#引风挡板调节输出')"
											@dblclick="Cclick(infoList.MCSAO.YFDBAO1_B,'YFDBAO1_B','MCSAO','YFDBAO1_BVV1')">
											{{infoList.MCSAO.YFDBAO1_B}}
										</div>
										<div class="num3">%</div>
									</div>
								</div>
							</div>
							<div class="float3">
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'KF_YFBP1_B','MCSSOURCE','KF_YFBP1_BVV1','1#引风机变频反馈')"
										@dblclick="Cclick(infoList.MCSSOURCE.KF_YFBP1_B,'KF_YFBP1_B','MCSSOURCE','KF_YFBP1_BVV1')">
										{{infoList.MCSSOURCE.KF_YFBP1_B}}
									</div>
									<div class="num3">%</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'YFBPAO1_B','MCSAO','YFBPAO1_BVV1','1#引风变频阀位输出')"
										@dblclick="Cclick(infoList.MCSAO.YFBPAO1_B,'YFBPAO1_B','MCSAO','YFBPAO1_BVV1')">
										{{infoList.MCSAO.YFBPAO1_B}}
									</div>
									<div class="num3">%</div>
								</div>
							</div>
							<div class="fan float4"></div>
							<div class="float5 flex">
								<div class="num1" @click="toCompon(2,'II_YFJ1_B','MCSSOURCE','II_YFJ1_BVV1','1#引风机电流')"
									@dblclick="Cclick(infoList.MCSSOURCE.II_YFJ1_B,'II_YFJ1_B','MCSSOURCE','II_YFJ1_BVV1')">
									{{infoList.MCSSOURCE.II_YFJ1_B}}
								</div>
								<div class="num3">A</div>
							</div>
						</div>
						<div class="down flex">
							<div>
								<div class="valve float1"></div>
								<div class="float2">
									<div class="flex">
										<div class="num1"
											@click="toCompon(2,'KF_YFDB2_B','MCSSOURCE','KF_YFDB2_BVV1','2#引风机挡板反馈')"
											@dblclick="Cclick(infoList.MCSSOURCE.KF_YFDB2_B,'KF_YFDB2_B','MCSSOURCE','KF_YFDB2_BVV1')">
											{{infoList.MCSSOURCE.KF_YFDB2_B}}
										</div>
										<div class="num3">%</div>
									</div>
									<div class="flex">
										<div class="num1"
											@click="toCompon(2,'YFDBAO2_B','MCSAO','YFDBAO2_BVV1','2#引风挡板调节输出')"
											@dblclick="Cclick(infoList.MCSAO.YFDBAO2_B,'YFDBAO2_B','MCSAO','YFDBAO2_BVV1')">
											{{infoList.MCSAO.YFDBAO2_B}}
										</div>
										<div class="num3">%</div>
									</div>
								</div>
							</div>
							<div class="float3">
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'KF_YFBP2_B','MCSSOURCE','KF_YFBP2_BVV1','2#引风机变频反馈')"
										@dblclick="Cclick(infoList.MCSSOURCE.KF_YFBP2_B,'KF_YFBP2_B','MCSSOURCE','KF_YFBP2_BVV1')">
										{{infoList.MCSSOURCE.KF_YFBP2_B}}
									</div>
									<div class="num3">%</div>
								</div>
								<div class="flex">
									<div class="num1"
										@click="toCompon(2,'YFBPAO2_B','MCSAO','YFBPAO2_BVV1','2#引风变频阀位输出')"
										@dblclick="Cclick(infoList.MCSAO.YFBPAO2_B,'YFBPAO2_B','MCSAO','YFBPAO2_BVV1')">
										{{infoList.MCSAO.YFBPAO2_B}}
									</div>
									<div class="num3">%</div>
								</div>
							</div>
							<div class="fan float4"></div>
							<div class="float5 flex">
								<div class="num1" @click="toCompon(2,'II_YFJ2_B','MCSSOURCE','II_YFJ2_BVV1','2#引风机电流')"
									@dblclick="Cclick(infoList.MCSSOURCE.II_YFJ2_B,'II_YFJ2_B','MCSSOURCE','II_YFJ2_BVV1')">
									{{infoList.MCSSOURCE.II_YFJ2_B}}
								</div>
								<div class="num3">A</div>
							</div>
						</div>
					</div>
					<div class="buttons">
						<div class="float1 flex">
							<div class="buttonr"
								:style="{background:infoList.MCSRPQ__p__QK04 &&!infoList.MCSRPQ__p__QK04.TS? '#2AFC30': 'red',}"
								@click="infoList.MCSRPQ__p__QK04 ? toDetail(2,'QK04','MCSRPQ__p__QK04','','送风前馈引风' ): ''">
								K
							</div>
							<div class="buttonr right"
								:style="{background:(infoList.MCSRPQ__p__RSF0H &&!infoList.MCSRPQ__p__RSF0H.TS ?'#2AFC30':'red')}"
								@click="infoList.MCSRPQ__p__RSF0H ? toDetail(3,'RSF0H','MCSRPQ__p__RSF0H','','引风机电流纠偏' ): ''">
								R
							</div>
						</div>
						<div class="line1 flex">
							<div class="buttonx"
								:style="{background:infoList.MCSXK__p__XK05.RM==1 ? '#2AFC30' : 'red',}"
								@click="infoList.MCSXK__p__XK05 ? toDetail(1,'XK05','MCSXK__p__XK05','','负压挡板调节' ): ''">
								X
							</div>
							<div class="buttonx right" :style="{
						background:
						infoList.MCSMAN__p__MAN19.RM  == 1 || infoList.MCSMAN__p__MAN20.RM == 1 || infoList.MCSMAN__p__MAN21.RM == 1 || infoList.MCSMAN__p__MAN22.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'CFB_MANYF',4)">A</div>
							<div class="buttonx right"
								:style="{background:infoList.MCSXK__p__XK06 &&infoList.MCSXK__p__XK06.RM==1 ? '#2AFC30' : 'red',}"
								@click="infoList.MCSXK__p__XK06 ? toDetail(1,'XK06','MCSXK__p__XK06','','负压变频调节' ): ''">
								X
							</div>
						</div>
						<div class="words float2" @click="toCompon(9)" style="cursor: pointer;">引风机</div>
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus='isClose' :historyname='historyname' :node='nodename' :Lkname='Aname'
			:chName="chName" :infoList='infoList'></Historical>
		<Parameter04 v-if="Parameter04" @sendStatus="isClose" :infoList="infoList"></Parameter04>
		<manyManual v-if="manyManual" :titname="Manualtitname" :numM="Manualnode" @sendStatus="isClose"
			:infoList="infoList"></manyManual>
		<Parameter022 v-if="Parameter022" @sendStatus="isClose" :infoList="infoList"></Parameter022>
		<Parameter08 v-if="Parameter08" @sendStatus="isClose" :infoList="infoList"></Parameter08>
		<Parameter02 v-if="Parameter02" @sendStatus="isClose" :infoList="infoList"></Parameter02>
		<Parameter07 v-if="Parameter07" @sendStatus="isClose" :infoList="infoList"></Parameter07>
		<Parameter06 v-if="Parameter06" @sendStatus="isClose" :infoList="infoList"></Parameter06>
		<Parameter05 v-if="Parameter05" @sendStatus="isClose" :infoList="infoList"></Parameter05>
		<Parameter03 v-if="Parameter03" @sendStatus="isClose" :infoList="infoList"></Parameter03>
		<Parameter01 v-if="Parameter01" @sendStatus="isClose" :infoList="infoList"></Parameter01>
		<Cfbcysz v-if="flag == 5 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose" :infoList="infoList">
		</Cfbcysz>
		<Cfbserve v-if="flag == 7 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose"
			:infoList="infoList"></Cfbserve>
	</div>
</template>

<script>
	import index from "./_index/index.js";
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Parameter01 from "@/views/CfbBoiler/CfbParameter1/index.vue"; //参数01
	import Parameter02 from "@/views/CfbBoiler/CfbParameter2/index.vue"; //参数02-1
	import Parameter022 from "@/views/CfbBoiler/CfbParameterSmoke2/index.vue"; //参数02-2
	import Parameter08 from "@/views/CfbBoiler/CfbParameter8/index.vue"; //参数08组件
	import Parameter04 from "@/views/CfbBoiler/CfbParameter4/index.vue"; //参数04组件
	import Parameter07 from "@/views/CfbBoiler/CfbParameter7/index.vue"; //参数07组件
	import Parameter06 from "@/views/CfbBoiler/CfbParameter6/index.vue"; //参数06组件
	import Parameter05 from "@/views/CfbBoiler/CfbParameter5/index.vue"; //参数05组件
	import Parameter03 from "@/views/CfbBoiler/CfbParameter3/index.vue"; //参数03组件
	import manyManual from "@/components/manyManual.vue"; //多手操器
	import Cfbcysz from "@/views/CfbBoiler/CfbCommonSetting/index.vue"; //常用设置组件
	import Cfbserve from "@/views/CfbBoiler/CfbOnlineService/index.vue"; //客服组件
	// import  from ;
	export default {
		name: 'Cfbindex',
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		components: {
			inputVal,
			Historical,
			Firstcontrol,
			Parameter022,
			Parameter08,
			Parameter04,
			Parameter02,
			Parameter07,
			Parameter06,
			Parameter05,
			Parameter03,
			Parameter01,
			manyManual,
			Cfbcysz,
			Cfbserve
		},
		data() {
			return {
				chName: '',
				projectData: {},
				Parameter04: false,
				Parameter022: false,
				Parameter08: false,
				Parameter02: false,
				Parameter07: false,
				Parameter06: false,
				Parameter05: false,
				Parameter03: false,
				Parameter01: false,
				Cfbcysz: false,
				manyManual: false,
				spotArr: [],
				grouptime: null,
				Jrconst: false,
				JrSecurity: false,
				flag: 0,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				historyname: "",
				nodename: "",
				Observername: "",
				Observernode: "",
				Rsfname: "",
				Rsfnode: "",
				bodyHeight: "",
				Firstcontrolname: "",
				Firstcontrolnode: "",
				Firstcontroltitname: "",
				fullscreenLoading: true,
				isHshow: false,
				isOshow: false,
				isRshow: false,
				Aname: "",
			}
		},
		mixins: [index],
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
					this.config = {
						data: [n.MCSSOURCE.PT102D1_B] || 0,
						shape: "round",
						colors: ["#43ff44", "#43ff44"],
						waveOpacity: "1",
						waveNum: "50",
						formatter: "{value}mpa",
					}
				},

				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.config = {
				data: [this.infoList.MCSSOURCE.PT102D1_B || 0],
				shape: "round",
				colors: ["#43ff44", "#43ff44"],
				waveOpacity: "1",
				waveNum: "50",
				formatter: "{value}mpa",
			}
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.$bus.$on("sendMsg", (msg) => {
				// 底部组件发送来的消息
				this.flag = msg;
				if (this.flag == 0) {
					return this.$router.push({
						path: '/Cfbindex'
					})
				} else if (this.flag == 1) {
					return this.$router.push({
						path: '/CfbDesulphurization'
					})
				} else if (this.flag == 2) {
					return this.$router.push({
						path: '/CfbOperationAssessment'
					})
				} else if (this.flag == 3) {
					return this.$router.push({
						path: '/CfbSwitchPage'
					})
				} else if (this.flag == 4) {
					return this.$router.push({
						path: '/CfbSecurity'
					})
				} else if (this.flag == 6) {
					return this.$router.push({
						path: '/CfbDataStatistics'
					})
				}

			});

		},
		mounted() {},
		methods: {
			utilFun(){
				let that = this
				this.$nextTick(function() { 
					console.log(that.infoList.MCSSOURCE.PT102D1_B)
				})
				return [this.infoList.MCSSOURCE.PT102D1_B] || [0]
			},
			// 打开趋势组图页面
			qsGroup() {
				this.$router.push({
					path: '/trendGroup'
				});
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {

						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			// 打开下置输入窗口
			toIpt(data, name, historyname, node, type) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无操作权限');
					}
				} else {
					this.$refs.inputVal.open(data, name, historyname, node, type);
				}

			},
			// 打开历史趋势窗口
			toHistory(data) {
				this.$refs.history.open(data);
			},
		},
	}
</script>

<style lang="scss" scoped>
	.fl-t {
		float: left;
	}

	.fr-t {
		float: right;
	}

	.title-hang {
		height: 5vh;
		width: 96vw;
		font-family: MicrosoftYaHei;
		font-size: 2vh;
		font-weight: normal;
		font-stretch: normal;
		line-height: 2vh;
		letter-spacing: 0vh;
	}

	.zhuangshizb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate06.png");
		background-size: 100% 100%;
		margin-left: 3.5vw;
		margin-top: 1.5vh;
	}

	.zhuangshizj {
		width: 68vw;
		height: 4vh;
		line-height: 4vh;
		margin-top: 1vh;
		text-align: center;
		// background-image: url("~@/assets/images/rfl_evaluate05.png");
		background-size: 100% 100%;
		// margin-left: 5vw;
		font-family: MicrosoftYaHei;
		font-size: 3vh;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0vh;
		color: #0ef7ff;
	}

	.zhuangshiyb {
		width: 14vw;
		height: 2vh;
		background-image: url("~@/assets/images/rfl_evaluate04.png");
		background-size: 100% 100%;
		margin-left: 5vw;
		margin-top: 1.5vh;
	}

	.total {
		width: 99%;
		height: 100%;
		// margin-top: 0vh;
		margin-left: 1vw;
		font-family: MicrosoftYaHei;
		font-size: 0.78vw;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0vh;

		.maskbok {
			width: 103vw;
			height: 100vh;
			margin-left: -3vw;
			background: rgba(0, 0, 0, .5);
			margin-top: -7vh;
			position: absolute;
			z-index: 99;
		}

		.part1 {
			// background-color: blue;
			line-height: 1.85vh;
			margin-top: 2.2vh;

			.words {
				width: 5vw;
				color: #5ca4a6;
				text-align: center;
			}

			.num1 {
				width: 5vw;
				color: #d9feff;
				text-align: center;
				cursor: pointer;
			}

			.num2 {
				width: 5vw;
				color: #14a4ff;
				text-align: center;
			}

			.float1 {
				margin-left: 6.41vw;
			}

			.float2 {
				margin-left: 6.7vw;
			}

			.float3 {
				margin-left: 7.08vw;
			}

			.float4 {
				margin-left: 3.44vw;
			}
		}

		.part2 {
			width: 96vw;
			height: 73vh;
			background-image: url("~@/assets/images/CfbBoiler/index_bg.png");
			background-size: 100% 100%;
			margin-top: -1vh;

			.buttonx {
				width: 0.94vw;
				height: 1.67vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 1.7vh;
				background-color: #00e4ff;
				cursor: pointer;
				color: white;
			}

			.buttonr {
				width: 0.94vw;
				height: 1.67vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 1.7vh;
				background-color: #04ff57;
				cursor: pointer;
				color: white;
			}

			.left {
				width: 45vw;

				// background-color: black;
				.block1 {
					margin-top: 1vh;

					.num1 {
						// margin-top: -2vh;
						width: 5vw;
						color: #d9feff;
						text-align: center;
						cursor: pointer;
					}

					.float1 {
						margin-left: 6.41vw;
					}

					.float2 {
						margin-left: 2.98vw;
					}

					.float3 {
						margin-left: 1.98vw;
					}

					.float4 {
						margin-left: 2vw;
					}

					.float5 {
						margin-left: 0vw;
					}
				}

				.block2 {
					.device {
						width: 9.32vw;
						height: 2.69vh;
						font-size: 1.56vw;
						line-height: 1.85vh;
						text-align: center;
						color: #ffffff;
						margin-top: 13vh;
						cursor: pointer;
					}

					.water_level_ball {
						margin-left: 6.45vw;
						margin-top: 3.8vh;

						.water_level {
							line-height: 1.85vh;

							.words {
								width: 2.7vw;
								color: white;
								text-align: center;
							}

							.num1 {
								width: 5vw;
								color: #d9feff;
								text-align: right;
								cursor: pointer;
							}

							.top {
								margin-top: 0.2vh;
							}
						}

						.location {
							margin-left: -6vw;
							margin-top: 2.9vh;
							z-index: 2;

							.num1 {
								font-size: 13px;
								font-weight: bold;
								width: 5vw;
								color: #43ff44;
								text-align: right;
								cursor: pointer;
							}
						}
					}

					.location1 {
						line-height: 1.85vh;
						margin-top: 13.63vh;
						margin-left: 4vw;

						.num1 {
							width: 5vw;
							color: #d9feff;
							text-align: right;
							cursor: pointer;
						}
					}

					.location2 {
						width: 4vw;
						line-height: 1.85vh;
						margin-top: 3.54vh;
						margin-left: 3vw;

						.num1 {
							width: 4vw;
							color: #d9feff;
							text-align: right;
							cursor: pointer;
						}

						.num3 {
							color: #d9feff;
							margin-left: 0.3vw;
						}
					}
				}

				.block3 {
					position: relative;

					.zy {
						width: 7.5vw;
						height: 16vh;
						position: absolute;
						right: -2vw;
						top: -2vh;
						cursor: pointer;
					}

					.one {
						margin-top: 28.8vh;
						margin-left: 0.52vw;

						.valve {
							width: 1.35vw;
							height: 2.31vh;
							background-image: url("~@/assets/images/CfbBoiler/valve01.png");
							background-size: 100% 100%;
						}

						.float1 {
							margin-left: 0.1vw;
							margin-top: 6.07vh;
						}

						.float2 {
							margin-left: 0.2vw;
						}

						.float3 {
							margin-left: 0.2vw;
							margin-top: 6.07vh;
						}

						.float4 {
							margin-left: 0.2vw;
						}

						.float5 {
							margin-left: 0.2vw;
							margin-top: 6.07vh;
						}
					}

					.two {
						margin-left: -1vw;

						.location1 {
							line-height: 1.85vh;
							color: #d9feff;
							margin-top: 7.32vh;

							.num1 {
								width: 4vw;
								color: #d9feff;
								text-align: center;
								cursor: pointer;
								cursor: pointer;
							}

							.float2 {
								margin-top: -0.7vh;
								margin-left: 3.54vw;
							}

							.float3 {
								margin-top: -0.9vh;
							}

							.float4 {
								margin-top: -0.8vh;
								margin-left: 3.54vw;
							}

							.float5 {
								margin-top: -0.9vh;
							}

							.float6 {
								margin-top: -0.8vh;
								margin-left: 3.54vw;
							}
						}

						.location2 {
							margin-top: 16vh;
							margin-left: 1vw;

							.words {
								line-height: 1.85vh;
								letter-spacing: 0.14vh;
								width: 5vw;
								color: #5ca4a6;
								text-align: center;
							}

							.num1 {
								width: 5vw;
								color: #d9feff;
								line-height: 2.22vh;
								text-align: center;
								cursor: pointer;
							}

							.num3 {
								color: #d9feff;
								line-height: 2.22vh;
								margin-left: 0.3vw;
							}

							.float1 {
								margin-left: 1.2vw;
								margin-bottom: 0.2vh;
							}

						}
					}

					.three {
						.location1 {
							margin-left: 3.5vw;
							margin-top: 2.7vh;

							.shadow {
								width: 3vw;
								height: 2.13vh;
								background-color: #000000;
								opacity: 0.2;
							}

							.words {
								line-height: 1.85vh;
								letter-spacing: 0.14vh;
								width: 3vw;
								color: #d9feff;
								text-align: center;
							}

							.num1 {
								width: 5vw;
								color: #d9feff;
								line-height: 2.22vh;
								text-align: center;
								cursor: pointer;
							}

							.num4 {
								width: 5vw;
								color: #14a4ff;
								line-height: 2.22vh;
								text-align: center;
								cursor: pointer;
							}

							.num6 {
								width: 5vw;
								color: #d9feff;
								line-height: 2.22vh;
								text-align: center;
								cursor: pointer;
							}

							.float1 {
								margin-top: 0.8vh;
							}

							.float2 {
								margin-left: 5.2vw;
							}
						}

						.location2 {
							width: 14vw;
							line-height: 1.85vh;
							margin-top: 1vh;
							margin-left: 1.5vw;
							
							.num1 {
								width: 3.5vw;
								color: #d9feff;
								line-height: 2.22vh;
								text-align: right;
								cursor: pointer;
							}

							.num3 {
								color: #d9feff;
								line-height: 2.22vh;
								margin-left: 0.3vw;
							}

							.left {
								margin-left: 2vw;
							}

							.right {
								margin-left: -1vw;
							}
						}

						.location3 {
							line-height: 1.75vh;
							margin-left: 2.7vw;

							.num1 {
								width: 4vw;
								color: #d9feff;
								text-align: center;
								cursor: pointer;
							}

							.num3 {
								width: 5vw;
								color: #d9feff;
								text-align: center;
								cursor: pointer;
							}

							.num4 {
								width: 5vw;
								color: #14a4ff;
								text-align: center;
								cursor: pointer;
							}

							.float1 {
								margin-left: 4vw;
							}

							.float2 {
								margin-left: 4.2vw;
							}

							.float3 {
								margin-left: 0.3vw;
							}

							.float4 {
								margin-left: 0.3vw;
							}

							.float5 {
								margin-left: 0.3vw;
							}

							.float6 {
								margin-left: 3.8vw;
							}
						}

						.location4 {
							margin-left: 7vw;

							.num4 {
								width: 2vw;
								color: #14a4ff;
								line-height: 2.22vh;
								text-align: right;
								cursor: pointer;
							}

							.num5 {
								width: 2vw;
								color: #14a4ff;
								line-height: 2.22vh;
								margin-left: 0.3vw;
								cursor: pointer;
							}
						}

						.location5 {
							width: 12vw;
							line-height: 1.85vh;
							margin-top: 1vh;
							margin-left: 1.0vw;

							.num1 {
								width: 4.2vw;
								color: #d9feff;
								line-height: 2.22vh;
								text-align: right;
								cursor: pointer;
							}

							.num3 {
								color: #d9feff;
								line-height: 2.22vh;
								margin-left: 0.3vw;
							}

							.left {
								width: 6vw;
								margin-left: 0.0vw;
							}

							.right {
								width: 6vw;
								margin-left: 0vw;
							}
						}

						.location6 {
							margin-top: 1.7vh;
							margin-left: -0.3vw;
							line-height: 2.22vh;

							.num1 {
								width: 1.6vw;
								color: #d9feff;
								text-align: right;
								cursor: pointer;
							}

							.num3 {
								color: #d9feff;
								margin-left: 0.5vw;
							}

							.float1 {
								margin-left: 1.78vw;
							}
						}

						.location7 {
							width: 16vw;
							height: 8.8vh;
							line-height: 1.55vh;
							margin-top: 1vh;
							margin-left: 1vw;

							.num1 {
								color: #d9feff;
								line-height: 2.22vh;
								text-align: right;
								cursor: pointer;
								// background-color: red;
							}

							.left {
								width: 5.5vw;
								margin-left: 0vw;
								// background-color: red;
							}

							.right {
								width: 5.5vw;
								margin-left: 0vw;
							}
						}
					}

					.four {
						margin-left: -0.7vw;

						.location1 {
							margin-left: 2.5vw;
							margin-top: 8vh;
							line-height: 1.87vh;

							.words {
								width: 5vw;
								color: #5ca4a6;
							}

							.num2 {
								width: 5vw;
								color: #14a4ff;
								text-align: center;
								margin-left: -4.5vw;
								cursor: pointer;
							}

							.num4 {
								width: 1.2vw;
								color: #14a4ff;
								text-align: right;
								cursor: pointer;
							}

							.num5 {
								width: 2vw;
								color: #14a4ff;
								margin-left: 0.9vw;
								cursor: pointer;
							}

							.float1 {
								margin-top: 0.3vh;
							}
						}

						.location2 {
							margin-top: 26.5vh;
							margin-left: 2.5vw;

							.float1 {
								margin-left: 0.1vw;
							}

							.words {
								line-height: 2.22vh;
								color: #5ca4a6;
							}
						}
					}
				}
			}

			.middle {
				width: 17vw;
				// background-color: red;
				// margin-left: 0vw;
				margin-top: 6.8vh;
				line-height: 1.87vh;

				.words {
					line-height: 1.85vh;
					letter-spacing: 0.14vh;
					width: 4vw;
					color: #5ca4a6;
					text-align: center;
				}

				.num1 {
					width: 4vw;
					color: #d9feff;
					cursor: pointer;
				}

				.num6 {
					width: 3vw;
					color: #14a4ff;
					cursor: pointer;
				}

				.num3 {
					color: #d9feff;
					margin-left: 0.3vw;
				}

				.num4 {
					width: 3.6vw;
					color: #14a4ff;
					line-height: 2.22vh;
					text-align: right;
					cursor: pointer;
				}

				.num5 {
					width: 2vw;
					color: #14a4ff;
					line-height: 2.22vh;
					margin-left: 0.3vw;
					cursor: pointer;
				}

				.button1 {
					width: 2.6vw;
					height: 1.85vh;
					background-color: #14705b;
					text-align: center;
					cursor: pointer;
				}

				.word1 {
					line-height: 1.8vh;
					color: #75e3ca;
				}

				.location1 {
					width: 4vw;
					height: 3vh;
					margin-left: 7vw;
				}

				.location2 {
					margin-top: 2vh;
					margin-left: 7.1vw;

					.up {
						margin-top: 0.3vh;
					}

					.right {
						margin-left: 0.2vw;
					}
				}

				.location3 {
					margin-top: 0.3vh;
					margin-left: 4.5vw;
				}

				.location4 {
					width: 6vw;
					height: 15vh;
					margin-top: -1.6vh;
					margin-left: 11.6vw;

					.float1 {
						margin-left: 2vw;
					}

					.float2 {
						margin-left: 0.3vw;
					}

					.float3 {
						margin-left: 0.3vw;
					}

					.float4 {
						margin-top: 6.2vh;
					}
				}

				.location5 {
					// background-color: red;
					margin-top: 0.5vh;
					margin-left: 0vw;

					.num1 {
						width: 7vw;
						color: #d9feff;
						cursor: pointer;
					}
				}

				.location6 {
					margin-top: 3.0vh;
					margin-left: -0.3vw;

					.num1 {
						width: 7vw;
						color: #d9feff;
						cursor: pointer;
					}
				}

				.location7 {
					margin-top: 7.0vh;
					margin-left: 11.6vw;

					.float1 {
						margin-left: 0.5vw;
					}
				}

				.location8 {
					margin-top: 4.5vh;
					margin-left: 17vw;
				}
			}

			.rightt {
				width: 30vw;
				// background-color: blueviolet;
				margin-left: 4vw;
				line-height: 1.85vh;

				.valve {
					width: 1.25vw;
					height: 2.41vh;
					background-image: url("~@/assets/images/CfbBoiler/valve02.png");
					background-size: 100% 100%;
				}

				.fan {
					width: 3.13vw;
					height: 4.81vh;
					background-image: url("~@/assets/images/CfbBoiler/fan.png");
					background-size: 100% 100%;
				}

				.words {
					letter-spacing: 0.14vh;
					width: 5vw;
					color: #5ca4a6;
					text-align: center;
				}

				.num1 {
					width: 2vw;
					color: #d9feff;
					text-align: right;
					cursor: pointer;
				}

				.num3 {
					color: #d9feff;
					margin-left: 0.1vw;
				}

				.num4 {
					width: 2vw;
					color: #14a4ff;
					line-height: 2.22vh;
					text-align: right;
					cursor: pointer;
				}

				.num5 {
					width: 2vw;
					color: #14a4ff;
					line-height: 2.22vh;
					margin-left: 0.3vw;
					cursor: pointer;
				}

				.one {
					.tap {
						line-height: 3.7vh;
						margin-top: 3.3vh;
						margin-left: 4.59vw;

						.float1 {
							margin-left: 1.51vw;
							margin-top: 0.7vh;
						}

						.float2 {
							margin-left: 1.51vw;
						}

						.float3 {
							margin-left: 1.51vw;
							margin-top: 0.9vh;
						}

						.float4 {
							margin-left: 1.51vw;
							margin-top: 1vh;
						}

						.float5 {
							margin-left: 1.51vw;
							margin-top: 1vh;
						}
					}

					.switch {
						margin-top: 3vh;
						margin-left: 4vw;

						.right {
							margin-left: 0.1vw;
						}

						.top {
							margin-top: 1vh;
						}

						.float1 {
							margin-top: 1.4vh;
							margin-left: 2vw;
						}

						.float2 {
							line-height: 1.5vh;
							margin-top: -1.2vh;
							margin-left: 0.6vw;
							.num3{
								margin-left: 0.6vw;
							}
						}

						.float3 {
							margin-left: -1.2vw;
							margin-top: 0.5vh;
						}

						.float4 {
							line-height: 1.5vh;
							margin-top: -0.8vh;
							margin-left: 0.1vw;
							.num3{
								margin-left: 0.6vw;
							}
						}
					}
				}

				.two {

					// background-color: red;
					// margin-top: 0.5vh;
					.up {
						.float1 {
							margin-left: 0.5vw;
							margin-top: 5.8vh;
						}

						.float2 {
							margin-left: 1vw;
							margin-top: 2.4vh;
						}

						.float3 {
							margin-left: 0.83vw;
							margin-top: 0.66vh;
						}

						.float4 {
							margin-top: 2.41vh;
						}

						.float5 {
							margin-left: 1.2vw;
							margin-top: 2.31vh;
						}

						.float6 {
							margin-left: 0.46vw;
						}
					}

					.down {
						margin-top: 0.3vh;

						.float1 {
							margin-left: 0.5vw;
							margin-top: -0.5vh;
						}

						.float2 {
							margin-left: 1vw;
							margin-top: 0.5vh;
						}

						.float3 {
							margin-left: 0.83vw;
							margin-top: -1vh;
						}

						.float4 {
							margin-top: 0.5vh;
						}

						.float5 {
							margin-left: 1.26vw;
							margin-top: 0.71vh;
						}

						.float6 {
							margin-left: 0.46vw;
						}
					}

					.buttons {
						margin-top: 4.4vh;
						margin-left: 1vw;

						.button1 {
							width: 4vw;
							height: 1.85vh;
							background-color: #0c6080;
							border-radius: 1px;
							text-align: center;

							.word1 {
								line-height: 1.7vh;
								color: #ffffff;
							}
						}

						.button2 {
							width: 4vw;
							height: 1.85vh;
							background-color: #0c6080;
							border-radius: 1px;
							text-align: center;

							.word2 {
								line-height: 1.7vh;
								color: #49c3f1;
							}
						}

						.line1 {
							margin-top: 0.15vh;
						}

						.right {
							margin-left: 0.2vw;
						}

						.words {
							margin-top: 0.8vh;
						}
					}
				}

				.three {

					// margin-top: 0.2vh;
					.up {
						.float1 {
							margin-left: 0.5vw;
							margin-top: 5vh;
						}

						.float2 {
							margin-left: 1vw;
							margin-top: 2.4vh;
						}

						.float3 {
							margin-left: 0.83vw;
							margin-top: 0.46vh;
						}

						.float4 {
							margin-left: 0.3vw;
							margin-top: 1.4vh;
						}

						.float5 {
							margin-left: 0.86vw;
							margin-top: 1.5vh;
						}

						.float6 {
							margin-left: 0.26vw;
						}
					}

					.down {
						margin-top: 0.3vh;

						.float1 {
							margin-left: 0.5vw;
							margin-top: -0.5vh;
						}

						.float2 {
							margin-left: 1vw;
							margin-top: 0.5vh;
						}

						.float3 {
							margin-left: 0.83vw;
							margin-top: -1vh;
						}

						.float4 {
							margin-left: 0.3vw;
							margin-top: 0.2vh;
						}

						.float5 {
							margin-left: 1.06vw;
							margin-top: 0.51vh;
						}

						.float6 {
							margin-left: 0.26vw;
						}
					}

					.buttons {
						margin-top: 5.8vh;
						margin-left: 1.5vw;

						.line1 {
							margin-top: 0.2vh;
						}

						.right {
							margin-left: 0.2vw;
						}

						.float1 {
							margin-left: 1.4vw;
						}

						.float2 {
							margin-left: -0.5vw;
							margin-top: 0.3vh;
						}

						.words {
							margin-top: 0.8vh;
						}
					}
				}

				.four {
					margin-left: 9.5vw;

					// background-color: red;
					.up {
						.float1 {
							margin-top: 2.4vh;
						}

						.float2 {
							margin-left: -0.3vw;
						}

						.float3 {
							margin-top: 4.6vh;
							margin-left: 0.5vw;
						}

						.float4 {
							margin-left: 0.5vw;
							margin-top: 0.5vh;
						}

						.float5 {
							margin-top: 2vh;
							.num3{
								margin-left: 0.6vw;
							}
						}
					}

					.down {
						margin-top: 1.2vh;

						.float1 {
							margin-top: 1.6vh;
						}

						.float2 {
							margin-left: -0.3vw;
						}

						.float3 {
							margin-top: 4vh;
							margin-left: 0.5vw;
						}

						.float4 {
							margin-left: 0.5vw;
							margin-top: 0vh;
						}

						.float5 {
							margin-top: 1vh;
							.num3{
								margin-left: 0.6vw;
							}
						}
					}

					.buttons {
						margin-top: 4.8vh;
						margin-left: 1vw;

						.line1 {
							margin-top: 0.3vh;
						}

						.right {
							margin-left: 0.2vw;
						}

						.float1 {
							margin-left: 1.4vw;
						}

						.float2 {
							margin-left: -0.5vw;
							margin-top: 0.3vh;
						}
					}
				}
			}
		}

	}

	::v-deep {
		.dv-water-pond-level text {
			font-size: 13px;
			dominant-baseline: hanging;
		}
	}
</style>
