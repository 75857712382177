var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"total drag",attrs:{"id":"RqSteamDrum"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title-hang flex"},[_c('div',{staticClass:"zhuangshizb"}),_c('div',{staticClass:"zhuangshizj"},[_vm._v(_vm._s(_vm.projectData.project)+"_参数1")]),_c('div',{staticClass:"zhuangshiyb",on:{"click":_vm.closeCompon}},[_c('div',{staticClass:"icon",on:{"click":_vm.closeCompon}})])])]),_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"line1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("A04-02SPH")]),_c('div',{staticClass:"shadow flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(2, 'LT101_B', _vm.infoList.LT101_B_node_string, 'LT101_NG3', '汽包水位1')},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.LT101_B,
                  'LT101_B',
                  _vm.infoList.LT101_B_node_string,
                  'LT101_NG3'
                )}}},[_vm._v(" "+_vm._s('LT101_B' in _vm.infoList?_vm.infoList.LT101_B :0)+" ")]),_c('div',{staticClass:"mm"},[_vm._v("mm")])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("A04-02SPL")]),_c('div',{staticClass:"shadow flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(2, 'LT102_B', _vm.infoList.LT101_B_node_string, 'LT102_NG3', '汽包水位2')},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.LT102_B,
                  'LT102_B',
                  _vm.infoList.LT101_B_node_string,
                  'LT102_NG3'
                )}}},[_vm._v(" "+_vm._s('LT102_B' in _vm.infoList?_vm.infoList.LT102_B :0)+" ")]),_c('div',{staticClass:"mm"},[_vm._v("mm")])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("A04-02TC")]),_c('div',{staticClass:"shadow flex"},[_c('div',{staticClass:"num1",on:{"click":function($event){return _vm.toCompon(2, 'LT103_B', _vm.infoList.LT103_B_node_string, 'LT103_NG3', '汽包水位3')},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.LT103_B,
                  'LT103_B',
                  _vm.infoList.LT103_B_node_string,
                  'LT103_NG3'
                )}}},[_vm._v(" "+_vm._s('LT103_B' in _vm.infoList?_vm.infoList.LT103_B :0)+" ")]),_c('div',{staticClass:"mm"},[_vm._v("mm")])])])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"line2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("A02-SWTC")]),_c('div',{staticClass:"shadow num2 color",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.SWTC,
                    'A02-SWTC',
                    'SWTC',
                    _vm.infoList.SWTC_node_string
                  )}}},[_vm._v(" "+_vm._s('SWTC' in _vm.infoList?_vm.infoList.SWTC :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("A02-HYYZ")]),_c('div',{staticClass:"shadow num2 color",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.SW_C,
                    'A02-HYYZ',
                    'SW_C',
                    _vm.infoList.SW_C_node_string
                  )}}},[_vm._v(" "+_vm._s('SW_C' in _vm.infoList?_vm.infoList.SW_C :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("A02-SWPV")]),_c('div',{staticClass:"shadow num2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'SWPV',
                    _vm.infoList.SWPV_node_string,
                    'SWPV_NG3',
                    '汽包水位实际测量值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.SWPV,
                    'SWPV',
                    _vm.infoList.SWPV_node_string,
                    'SWPV_NG3'
                  )}}},[_vm._v(" "+_vm._s('SWPV' in _vm.infoList?_vm.infoList.SWPV :0)+" ")])])]),_c('div',{staticClass:"line3"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("A04-FGS")]),_c('div',{staticClass:"shadow num2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'FICA_101_B',
                    _vm.infoList.FICA_101_B_node_string,
                    'FICA_101_NG3',
                    '给水流量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.FICA_101_B,
                    'FICA_101_B',
                    _vm.infoList.FICA_101_B_node_string,
                    'FICA_101_NG3'
                  )}}},[_vm._v(" "+_vm._s('FICA_101_B' in _vm.infoList?_vm.infoList.FICA_101_B :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("A04-FZQ")]),_c('div',{staticClass:"shadow num2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'FICA_102_B',
                    _vm.infoList.FICA_102_B_node_string,
                    'FICA_102_NG3',
                    '蒸汽流量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.FICA_102_B,
                    'FICA_102_B',
                    _vm.infoList.FICA_102_B_node_string,
                    'FICA_102_NG3'
                  )}}},[_vm._v(" "+_vm._s('FICA_102_B' in _vm.infoList?_vm.infoList.FICA_102_B :0)+" ")])])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"up"},[_c('div',{staticClass:"up_content flex"},[_c('div',{staticClass:"words"},[_vm._v("A01-SWSP")]),_c('div',{staticClass:"shadow num3 color",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.SWSP,
                    'A01-SWSP',
                    'SWSP',
                    _vm.infoList.SWSP_node_string
                  )}}},[_vm._v(" "+_vm._s('SWSP' in _vm.infoList?_vm.infoList.SWSP :0)+" ")])])]),_c('div',{staticClass:"down"},[_c('div',{staticClass:"button button1",style:({
                background:
                  _vm.infoList.XK1 && !_vm.infoList.XK1.RM
                    ? '#2AFC30'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.XK1
                  ? _vm.toDetail(1, 'XK1', _vm.infoList.XK1_node_string, '', '汽包水位主调节先控')
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"line"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("A04-QSTC")]),_c('div',{staticClass:"shadow num4 color",on:{"click":function($event){return _vm.toIpt(
                        _vm.infoList.QSTC,
                        'A04-QSTC',
                        'QSTC',
                        _vm.infoList.QSTC_node_string
                      )}}},[_vm._v(" "+_vm._s('QSTC' in _vm.infoList?_vm.infoList.QSTC :0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"words"},[_vm._v("A04-QSPC")]),_c('div',{staticClass:"shadow num4",on:{"click":function($event){return _vm.toCompon(
                        2,
                        'QSPV',
                        _vm.infoList.QSPV_node_string,
                        'QSPV_NG3',
                        '汽水偏差计算值'
                      )},"dblclick":function($event){return _vm.Cclick(
                        _vm.infoList.QSPV,
                        'A04-QSPC',
                        'QSPV',
                        _vm.infoList.QSPV_node_string
                      )}}},[_vm._v(" "+_vm._s('QSPV' in _vm.infoList?_vm.infoList.QSPV :0)+" ")])])])]),_c('div',{staticClass:"button button2",style:({
                background:
                  _vm.infoList.XK19 && !_vm.infoList.XK19.RM
                    ? '#2AFC30'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.XK19
                  ? _vm.toDetail(1, 'XK19', _vm.infoList.XK19_node_string, '', '主给水副调节')
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"button button3",style:({
                background:
                  _vm.infoList.XK20 && !_vm.infoList.XK20.RM
                    ? '#2AFC30'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.XK20
                  ? _vm.toDetail(1, 'XK20', _vm.infoList.XK20_node_string, '', '副给水副调节')
                  : ''}}},[_vm._v(" X ")])])]),_c('div',{staticClass:"right_last"},[_c('div',{staticClass:"one flex"},[_c('div',{staticClass:"button",style:({
                background:
                  _vm.infoList.MAN1.RM && _vm.infoList.MAN1.RM == 1
                    ? '#2AFC30'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.MAN1
                  ? _vm.toCompon(
                      0,
                      'AV',
                      'MAN1',
                      'MAN1_AV_NG3',
                      '主给水手操器MAN1',
                      'KF_101_B',
                      _vm.infoList.MAN1_node_string,
                      'KF_101_NG3'
                    )
                  : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"words word2"},[_vm._v("A05-A01")]),_c('div',{staticClass:"shadow num4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.MAN1_node_string,
                  'MAN1_AV_NG3',
                  '主给水手操器',
				  'MAN1'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.MAN1.AV,
                  'AV',
                  _vm.infoList.MAN1_node_string,
                  'MAN1_AV_NG3'
                )}}},[_vm._v(" "+_vm._s('MAN1' in _vm.infoList?_vm.infoList.MAN1.AV :0)+" ")])]),_c('div',{staticClass:"two flex"},[_c('div',{staticClass:"button button4",style:({
                background:
                  _vm.infoList.MAN2.RM && _vm.infoList.MAN2.RM == 1
                    ? '#2AFC30'
                    : 'red',
              }),on:{"click":function($event){_vm.infoList.MAN2
                  ? _vm.toCompon(
                      0,
                      'AV',
                      'MAN2',
                      'MAN2_AV_NG3',
                      '副给水手操器MAN2',
                      'KF_102_B',
                      _vm.infoList.MAN2_node_string,
                      'KF_102_NG3'
                    )
                  : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"words word2"},[_vm._v("A05-A02")]),_c('div',{staticClass:"shadow num4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.MAN2_node_string,
                  'MAN2_AV_NG3',
                  '副给水手操器',
				  'MAN2'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.MAN2.AV,
                  'AV',
                  _vm.infoList.MAN2_node_string,
                  'MAN2_AV_NG3'
                )}}},[_vm._v(" "+_vm._s('MAN2' in _vm.infoList?_vm.infoList.MAN2.AV :0)+" ")])])])])]),_vm._m(1),_c('inputVal',{ref:"inputVal",on:{"getTreeData":function($event){return _vm.DataJson(arguments)}}}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"node1":_vm.node1,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"pmark":_vm.Panualmark,"pnode":_vm.Panualnode,"pnm":_vm.Panualnodem,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isMshow = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main_title"},[_vm._v("汽包水位优化控制模型")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"explain"},[_c('div',{staticClass:"explain_content flex"},[_c('div',[_vm._v("说明：")]),_c('div',[_c('div',{staticClass:"explain1 flex"},[_c('div',[_vm._v("1、汽包液位主调节SADD：投入自动的阀门均达到安全限幅上限")]),_c('div',{staticClass:"blank"}),_c('div',[_vm._v("SSUB: 投入自动的阀门均达到安全限幅下限")])]),_c('div',{staticClass:"explain2 flex"},[_c('div',[_vm._v("2、主给水调节SADD：主给水调节阀门达到安全限幅上限")]),_c('div',{staticClass:"blank"}),_c('div',[_vm._v("SSUB: 主给水调节阀门达到安全限幅下限")])]),_c('div',{staticClass:"explain3 flex"},[_c('div',[_vm._v("3、副给水调节SADD：副给水调节阀门达到安全限幅上限")]),_c('div',{staticClass:"blank"}),_c('div',[_vm._v("SSUB: 副给水调节阀门达到安全限幅下限")])])])])])}]

export { render, staticRenderFns }