<template>
  <div>
    <div class="rqltltx">
      <div class="bgmain">
        <div class="title">{{ projectData.project }}脱硫脱硝系统</div>
        <div class="row1-main">
          <!-- 左侧部分 -->
          <div class="leftmain fl">
            <div class="leftrow1">炉膛口</div>
            <div class="leftrow2">
              <div class="fl left-lable">1#尿素分配</div>
              <div class="fl left-lable leftjg">2#尿素分配</div>
              <div class="fl left-lable leftjg">3#尿素分配</div>
            </div>
            <div class="leftrow3">压缩空气</div>
            <div class="leftrow4">
              <div class="fl">
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      infoList.QK20 && !infoList.QK20.TS ? '#2AFC30' : 'red',
                  }"
                  @click="
                    infoList.QK20
                      ? toDetail(
                          2,
                          'QK20',
                          infoList.QK20_node_string,
                          '',
                          '燃料量-脱硝前馈'
                        )
                      : ''
                  "
                >
                  K
                </div>
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      infoList.XK23 && !infoList.XK23.RM ? '#2AFC30' : 'red',
                  }"
                  @click="
                    infoList.XK23
                      ? toDetail(
                          1,
                          'XK23',
                          infoList.XK23_node_string,
                          '',
                          'NOx控制'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl"
                  style="margin-left: 0.01vw"
                  :style="{
                    background:
                      infoList.MAN30 && infoList.MAN30.RM == 1
                        ? '#2AFC30'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN30
                      ? toCompon(
                          0,
                          'RM',
                          'MAN30',
                          'MAN30_AV_NG3',
                          '尿素溶液手操器',
                          'KF_TX_B',
                          infoList.KF_TX_B_node_string,
                          'KF_TX_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
              </div>
              <div class="fl">
                <div
                  class="down leftrow4jg"
                  @click="
                    toIpt(
                      infoList.NOXSP,
                      'NOx设定点',
                      'NOXSP',
                      infoList.NOXSP_node_string
                    )
                  "
                >
                  {{ "NOXSP" in infoList ? infoList.NOXSP : 0 }}
                </div>
                <div
                  class="leftrow4jg1"
                  @click="
                    toCompon(
                      2,
                      'NOXSPSJ',
                      'MCSO2NOX',
                      'NOXSPSJ_NG3',
                      '氮氧化物实际控制点'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.NOXSPSJ,
                      'NOXSPSJ',
                      infoList.NOXSPSJ_node_string,
                      'NOXSPSJ_NG3'
                    )
                  "
                >
                  {{ "NOXSPSJ" in infoList ? infoList.NOXSPSJ : 0 }}
                </div>
              </div>
              <div class="fl leftrow4jg2">
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      infoList.XK27 && !infoList.XK27.RM ? '#2AFC30' : 'red',
                  }"
                  @click="
                    infoList.XK27
                      ? toDetail(
                          1,
                          'XK27',
                          infoList.XK27_node_string,
                          '',
                          '化水除盐水流量控制'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl"
                  style="margin-left: 0.01vw"
                  :style="{
                    background:
                      infoList.MAN32 && infoList.MAN32.RM == 1
                        ? '#2AFC30'
                        : 'red',
                  }"
                  @click="
                    infoList.MAN32
                      ? toCompon(
                          0,
                          'RM',
                          'MAN32',
                          'MAN32_AV_NG3',
                          '脱硝除盐水手操器',
                          'KF_CYS_B',
                          infoList.KF_CYS_B_node_string,
                          'KF_CYS_NG3'
                        )
                      : ''
                  "
                >
                  A
                </div>
              </div>
            </div>
            <div class="leftrow5">
              <div class="fl">
                <div class="leftrow5wd">
                  <div
                    @click="
                      toCompon(
                        2,
                        'F_NSRY_B',
                        infoList.F_NSRY_B_node_string,
                        'F_NSRY_NG3',
                        '尿素溶液流量'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.F_NSRY_B,
                        'F_NSRY_B',
                        infoList.F_NSRY_B_node_string,
                        'F_NSRY_NG3'
                      )
                    "
                  >
                    {{
                      "F_NSRY_B" in infoList ? infoList.F_NSRY_B : 0
                    }}&nbsp;t/h
                  </div>
                </div>
                <div class="leftrow5wd">
                  <div
                    @click="
                      toCompon(
                        2,
                        'KF_TX_B',
                        infoList.KF_TX_B_node_string,
                        'KF_TX_NG3',
                        '炉内SNCR脱硝脱硝剂控制阀门反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_TX_B,
                        'KF_TX_B',
                        infoList.KF_TX_B_node_string,
                        'KF_TX_NG3'
                      )
                    "
                  >
                    {{ "KF_TX_B" in infoList ? infoList.KF_TX_B : 0 }}&nbsp;%
                  </div>
                </div>
              </div>
              <div class="fl leftrow5jg">
                <div class="leftrow5wd">
                  <div
                    @click="
                      toCompon(
                        2,
                        'F_CYS_B',
                        infoList.F_CYS_B_node_string,
                        'F_CYS_NG3',
                        '化水除盐水流量'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.F_CYS_B,
                        'F_CYS_B',
                        infoList.F_CYS_B_node_string,
                        'F_CYS_NG3'
                      )
                    "
                  >
                    {{ "F_CYS_B" in infoList ? infoList.F_CYS_B : 0 }}&nbsp;t/h
                  </div>
                </div>
                <div class="leftrow5wd">
                  <div
                    @click="
                      toCompon(
                        2,
                        'KF_CYS_B',
                        infoList.KF_CYS_B_node_string,
                        'KF_CYS_NG3',
                        '化水除盐水阀门反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_CYS_B,
                        'KF_CYS_B',
                        infoList.KF_CYS_B_node_string,
                        'KF_CYS_NG3'
                      )
                    "
                  >
                    {{ "KF_CYS_B" in infoList ? infoList.KF_CYS_B : 0 }}&nbsp;%
                  </div>
                </div>
              </div>
            </div>
            <div class="leftrow5">
              <div class="fl leftrow5jg3">尿素溶液</div>
              <div class="fl leftrow5jg2">化水除盐水</div>
            </div>
          </div>
          <!-- 中间部分 -->
          <div class="centermain fl">
            <div class="cen-top">
              <div class="fl centerrow1">
                <div class="cen-co1">原烟气监测</div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">SO₂浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'ASO2_Y_B',
                          infoList.ASO2_Y_B_node_string,
                          'ASO2_Y_NG3',
                          '锅炉出口SO2含量'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.ASO2_Y_B,
                          'ASO2_Y_B',
                          infoList.ASO2_Y_B_node_string,
                          'ASO2_Y_NG3'
                        )
                      "
                    >
                      {{
                        "ASO2_Y_B" in infoList ? infoList.ASO2_Y_B : 0
                      }}&nbsp;mg/m³
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">NOx浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'ANOX_Y_B',
                          infoList.ANOX_Y_B_node_string,
                          'ANOX_Y_NG3',
                          '原烟气NOX浓度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.ANOX_Y_B,
                          'ANOX_Y_B',
                          infoList.ANOX_Y_B_node_string,
                          'ANOX_Y_NG3'
                        )
                      "
                    >
                      {{
                        "ANOX_Y_B" in infoList ? infoList.ANOX_Y_B : 0
                      }}&nbsp;mg/m³
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">O₂浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'ATO2_Y_B',
                          infoList.ATO2_Y_B_node_string,
                          'ATO2_Y_NG3',
                          '原烟气含氧量'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.ATO2_Y_B,
                          'ATO2_Y_B',
                          infoList.ATO2_Y_B_node_string,
                          'ATO2_Y_NG3'
                        )
                      "
                    >
                      {{
                        "ATO2_Y_B" in infoList ? infoList.ATO2_Y_B : 0
                      }}&nbsp;%
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">DUST浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'ATDUST_Y_B',
                          infoList.ATDUST_Y_B_node_string,
                          'ATDUST_Y_NG3',
                          '原烟气含尘量'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.ATDUST_Y_B,
                          'ATDUST_Y_B',
                          infoList.ATDUST_Y_B_node_string,
                          'ATDUST_Y_NG3'
                        )
                      "
                    >
                      {{
                        "ATDUST_Y_B" in infoList ? infoList.ATDUST_Y_B : 0
                      }}&nbsp;mg/m³
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">烟气温度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'TYQ_Y_B',
                          infoList.TYQ_Y_B_node_string,
                          'TYQ_Y_NG3',
                          '原烟气烟气温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.TYQ_Y_B,
                          'TYQ_Y_B',
                          infoList.TYQ_Y_B_node_string,
                          'TYQ_Y_NG3'
                        )
                      "
                    >
                      {{ "TYQ_Y_B" in infoList ? infoList.TYQ_Y_B : 0 }}&nbsp;℃
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
              </div>
              <div class="fl centerrow1">
                <div class="cen-co1">净烟气监测</div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">SO₂浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'ASO2_B',
                          infoList.ASO2_B_node_string,
                          'ASO2_NG3',
                          'SO2浓度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.ASO2_B,
                          'ASO2_B',
                          infoList.ASO2_B_node_string,
                          'ASO2_NG3'
                        )
                      "
                    >
                      {{
                        "ASO2_B" in infoList ? infoList.ASO2_B : 0
                      }}&nbsp;mg/m³
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">NOx浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'ANOX_B',
                          infoList.ANOX_B_node_string,
                          'ANOX_NG3',
                          'nox浓度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.ANOX_B,
                          'ANOX_B',
                          infoList.ANOX_B_node_string,
                          'ANOX_NG3'
                        )
                      "
                    >
                      {{
                        "ANOX_B" in infoList ? infoList.ANOX_B : 0
                      }}&nbsp;mg/m³
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">O₂浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'ATO2_B',
                          infoList.ATO2_B_node_string,
                          'ATO2_NG3',
                          '净烟气烟气含氧量'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.ATO2_B,
                          'ATO2_B',
                          infoList.ATO2_B_node_string,
                          'ATO2_NG3'
                        )
                      "
                    >
                      {{ "ATO2_B" in infoList ? infoList.ATO2_B : 0 }}&nbsp;%
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">DUST浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'ATDUST_B',
                          infoList.ATDUST_B_node_string,
                          'ATDUST_NG3',
                          '净烟气烟尘浓度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.ATDUST_B,
                          'ATDUST_B',
                          infoList.ATDUST_B_node_string,
                          'ATDUST_NG3'
                        )
                      "
                    >
                      {{
                        "ATDUST_B" in infoList ? infoList.ATDUST_B : 0
                      }}&nbsp;mg/m³
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">烟气温度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'T_YQ_B',
                          infoList.T_YQ_B_node_string,
                          'T_YQ_NG3',
                          '净烟气烟气温度'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.T_YQ_B,
                          'T_YQ_B',
                          infoList.T_YQ_B_node_string,
                          'T_YQ_NG3'
                        )
                      "
                    >
                      {{ "T_YQ_B" in infoList ? infoList.T_YQ_B : 0 }}&nbsp;℃
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
              </div>
            </div>
            <div class="cen-lable">工艺水</div>
            <div class="cen-lable1">原烟气</div>
            <div class="cen-lable2">
              <div
                @click="
                  toCompon(
                    2,
                    'TYQ_Y_B',
                    infoList.TYQ_Y_B_node_string,
                    'TYQ_Y_NG3',
                    '原烟气烟气温度'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.TYQ_Y_B,
                    'TYQ_Y_B',
                    infoList.TYQ_Y_B_node_string,
                    'TYQ_Y_NG3'
                  )
                "
              >
                {{ "TYQ_Y_B" in infoList ? infoList.TYQ_Y_B : 0 }}&nbsp;℃
              </div>
            </div>
            <div class="cen-lable3">循环泵A</div>
            <div class="cen-lable3">循环泵B</div>
            <div class="cen-lable3">循环泵C</div>
            <div class="cen-lable3">循环泵D</div>
          </div>
          <div class="rightmain fl">
            <div class="rig-left fl">
              <div class="rig-lable1">脱硫塔</div>
              <div class="rig-lable1 rig-jge">
                <div class="rig-row1 fl">
                  <div
                    @click="
                      toCompon(
                        2,
                        'T_XST1_B',
                        infoList.T_XST1_B_node_string,
                        'T_XST1_NG3',
                        '吸收塔内温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.T_XST1_B,
                        'T_XST1_B',
                        infoList.T_XST1_B_node_string,
                        'T_XST1_NG3'
                      )
                    "
                  >
                    {{ "T_XST1_B" in infoList ? infoList.T_XST1_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="rig-row1 fl">
                  <div
                    @click="
                      toCompon(
                        2,
                        'T_XST2_B',
                        infoList.T_XST2_B_node_string,
                        'T_XST2_NG3',
                        '吸收塔内温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.T_XST2_B,
                        'T_XST2_B',
                        infoList.T_XST2_B_node_string,
                        'T_XST2_NG3'
                      )
                    "
                  >
                    {{ "T_XST2_B" in infoList ? infoList.T_XST2_B : 0 }}&nbsp;℃
                  </div>
                </div>
              </div>
              <div class="rig-lable1 rig-jge1">
                <div class="rig-row1 fl">
                  <div
                    @click="
                      toCompon(
                        2,
                        'LTJYC1_B',
                        infoList.LTJYC1_B_node_string,
                        'LTJYC1_NG3',
                        '吸收塔浆液池液位'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.LTJYC1_B,
                        'LTJYC1_B',
                        infoList.LTJYC1_B_node_string,
                        'LTJYC1_NG3'
                      )
                    "
                  >
                    {{ "LTJYC1_B" in infoList ? infoList.LTJYC1_B : 0 }}&nbsp;m
                  </div>
                </div>
                <div class="rig-row1 fl">
                  <div
                    @click="
                      toCompon(
                        2,
                        'LTJYC2_B',
                        infoList.LTJYC2_B_node_string,
                        'LTJYC2_NG3',
                        '吸收塔浆液池液位'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.LTJYC2_B,
                        'LTJYC2_B',
                        infoList.LTJYC2_B_node_string,
                        'LTJYC2_NG3'
                      )
                    "
                  >
                    {{ "LTJYC2_B" in infoList ? infoList.LTJYC2_B : 0 }}&nbsp;m
                  </div>
                </div>
              </div>
              <div class="rig-lable1 rig-jge2" style="margin-left: 0.5vw">
                <div class="rig-row1 fl">
                  <div
                    @click="
                      toCompon(
                        2,
                        'DTJYC1_B',
                        infoList.DTJYC1_B_node_string,
                        'DTJYC1_NG3',
                        '吸收塔浆液池密度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DTJYC1_B,
                        'DTJYC1_B',
                        infoList.DTJYC1_B_node_string,
                        'DTJYC1_NG3'
                      )
                    "
                  >
                    {{
                      "DTJYC1_B" in infoList ? infoList.DTJYC1_B : 0
                    }}&nbsp;g/m3
                  </div>
                </div>
                <div class="rig-row1 fl">
                  <div
                    @click="
                      toCompon(
                        2,
                        'DTJYC2_B',
                        infoList.DTJYC2_B_node_string,
                        'DTJYC2_NG3',
                        '吸收塔浆液池密度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DTJYC2_B,
                        'DTJYC2_B',
                        infoList.DTJYC2_B_node_string,
                        'DTJYC2_NG3'
                      )
                    "
                  >
                    {{
                      "DTJYC2_B" in infoList ? infoList.DTJYC2_B : 0
                    }}&nbsp;g/m3
                  </div>
                </div>
              </div>
              <div class="rig-lable1 rig-jge2">
                <div class="rig-row1 fl">
                  <div
                    @click="
                      toCompon(
                        2,
                        'PH1_B',
                        infoList.PH1_B_node_string,
                        'PH1_NG3',
                        '吸收塔浆液池PH值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.PH1_B,
                        'PH1_B',
                        infoList.PH1_B_node_string,
                        'PH1_NG3'
                      )
                    "
                  >
                    {{ "PH1_B" in infoList ? infoList.PH1_B : 0 }}&nbsp;ph
                  </div>
                </div>
                <div class="rig-row1 fl">
                  <div
                    @click="
                      toCompon(2, 'PH2_B', infoList.PH2_B_node_string, 'PH2_B')
                    "
                    @dblclick="
                      Cclick(
                        infoList.PH2_B,
                        'PH2_B',
                        infoList.PH2_B_node_string,
                        'PH2_B'
                      )
                    "
                  >
                    {{ "PH2_B" in infoList ? infoList.PH2_B : 0 }}&nbsp;ph
                  </div>
                </div>
              </div>
              <div class="rig-lable1 rig-jge2">
                <div class="rig-row1 fl">
                  <div
                    class="down"
                    @click="
                      toIpt(
                        infoList.PH_H,
                        'PH值上限设定',
                        'PH_H',
                        infoList.PH_H_node_string
                      )
                    "
                  >
                    {{ "PH_H" in infoList ? infoList.PH_H : 0 }}
                  </div>
                </div>
                <div class="rig-row1 fl">
                  <div
                    class="down"
                    @click="
                      toIpt(
                        infoList.PH_L,
                        'PH值下限设定',
                        'PH_L',
                        infoList.PH_L_node_string
                      )
                    "
                  >
                    {{ "PH_L" in infoList ? infoList.PH_L : 0 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="rig-right fl">
              <div class="rig-lable1">
                <div
                  class="rig-rightjg"
                  @click="
                    toCompon(
                      2,
                      'T_YQ_B',
                      infoList.T_YQ_B_node_string,
                      'T_YQ_NG3',
                      '净烟气烟气温度'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.T_YQ_B,
                      'T_YQ_B',
                      infoList.T_YQ_B_node_string,
                      'T_YQ_NG3'
                    )
                  "
                >
                  {{ "T_YQ_B" in infoList ? infoList.T_YQ_B : 0 }}&nbsp;℃
                </div>
              </div>
              <div class="righ-lable2">除雾器冲洗水</div>
              <div class="righ-lable3">
                <div class="fl">
                  <div class="righ-col1">
                    <div
                      class="righ-col1-lb fl down"
                      @click="
                        toIpt(
                          infoList.SO2SP,
                          'SO2设定点',
                          'SO2SP',
                          infoList.SO2SP_node_string
                        )
                      "
                    >
                      {{ "SO2SP" in infoList ? infoList.SO2SP : 0 }}
                    </div>
                    <div
                      class="righ-col1-lb fl"
                      @click="
                        toCompon(
                          2,
                          'SO2SPSJ',
                          infoList.SO2SPSJ_node_string,
                          'SO2SPSJ_NG3',
                          '二氧化硫实际控制点'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.SO2SPSJ,
                          'SO2SPSJ',
                          infoList.SO2SPSJ_node_string,
                          'SO2SPSJ_NG3'
                        )
                      "
                    >
                      {{ "SO2SPSJ" in infoList ? infoList.SO2SPSJ : 0 }}
                    </div>
                  </div>
                  <div class="righ-col1 righ-col1-jg">
                    <div
                      class="righ-col1-lb fl"
                      @click="
                        toCompon(
                          2,
                          'F_SHJY_B',
                          'MCSOUREC',
                          'F_SHJY_NG3',
                          '石灰浆液流量'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.MCSOUREC.F_SHJY_B,
                          'F_SHJY_B',
                          'MCSOUREC',
                          'F_SHJY_NG3'
                        )
                      "
                    >
                      {{
                        "" in infoList ? infoList.MCSOUREC.F_SHJY_B : 0
                      }}&nbsp;m3/h
                    </div>
                    <div
                      class="righ-col1-lb righ-col1-jg2 fl"
                      @click="
                        toCompon(
                          2,
                          'KF_TL_B',
                          infoList.KF_TL_B_node_string,
                          'KF_TL_NG3',
                          '脱硫石灰浆液流量控制阀门反馈'
                        )
                      "
                      @dblclick="
                        Cclick(
                          infoList.KF_TL_B,
                          'KF_TL_B',
                          infoList.KF_TL_B_node_string,
                          'KF_TL_NG3'
                        )
                      "
                    >
                      {{ "KF_TL_B" in infoList ? infoList.KF_TL_B : 0 }}&nbsp;%
                    </div>
                  </div>
                  <div class="righ-col1 righ-col1-jg3">
                    <div
                      class="bottonx fl jgxx"
                      :style="{
                        background:
                          infoList.QK21 && !infoList.QK21.TS
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.QK21
                          ? toDetail(
                              2,
                              'QK21',
                              infoList.QK21_node_string,
                              '',
                              '燃料量-脱硫前馈'
                            )
                          : ''
                      "
                    >
                      K
                    </div>
                    <div
                      class="bottonx fl"
                      :style="{
                        background:
                          infoList.QK22 && !infoList.QK22.TS
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.QK22
                          ? toDetail(
                              2,
                              'QK22',
                              infoList.QK22_node_string,
                              '',
                              '出口SO2-脱硫前馈'
                            )
                          : ''
                      "
                    >
                      K
                    </div>
                    <div
                      class="bottonx fl"
                      :style="{
                        background:
                          infoList.XK26 && !infoList.XK26.RM
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.XK26
                          ? toDetail(
                              1,
                              'XK26',
                              infoList.XK26_node_string,
                              '',
                              '吸收塔浆液池PH范围控制'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="bottonx fl"
                      :style="{
                        background:
                          infoList.XK25 && !infoList.XK25.RM
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.XK25
                          ? toDetail(1, 'XK25', infoList.XK25_node_string, '', 'SO2控制')
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="bottonx fl"
                      :style="{
                        background:
                          infoList.XK24 && !infoList.XK24.RM
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.XK24
                          ? toDetail(
                              1,
                              'XK24',
                              infoList.XK24_node_string,
                              '',
                              '石灰浆液流量控制'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="bottonx fl"
                      :style="{
                        background:
                          infoList.MAN31 &&
                          infoList.MAN31.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.MAN31
                          ? toCompon(
                              0,
                              'RM',
                              'MAN31',
                              infoList.MAN31_node_string,
                              '脱硫石灰浆液手操器',
                              'KF_TL_B',
                              infoList.KF_TL_B_node_string,
                              'KF_TL_NG3'
                            )
                          : ''
                      "
                    >
                      A
                    </div>
                  </div>
                </div>
                <div class="fl jange">
                  <div class="mag-top1">石灰石旋流站</div>
                  <div class="mag-top2">事故浆液罐</div>
                  <div class="mag-top3">集水坑</div>
                  <div class="mag-top1">石灰石浆液</div>
                  <div class="mag-top4">氧化空气</div>
                </div>
              </div>
              <div class="righ-lable4">
                <div class="righ-col21 fl">
                  <div class="jianh">石膏排除泵A</div>
                  <div class="jianh1">石膏排除泵B</div>
                </div>
                <div class="righ-col21 jianh3 fl">
                  <div class="">工艺水</div>
                  <div class="jianh4">事故浆液罐</div>
                  <div class="jianh4">石膏旋流站</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :pmark="Panualmark"
      :pnode="Panualnode"
      :pnm="Panualnodem"
      :infoList="infoList"
    ></Manual>

    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
    <RqCommonParameters
      v-if="flag == 4 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></RqCommonParameters>
    <RqSoftMeasurement
      v-if="flag == 6 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></RqSoftMeasurement>
    <RqOnlineService
      v-if="flag == 7 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></RqOnlineService>
  </div>
</template>

<script>
import Manual from "@/components/Manual1.vue"; //手操器组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import inputVal from "@/components/inputVal.vue"; //输入框组件
import RqCommonParameters from "@/views/RqBoiler/RqCommonParameters/index.vue"; //常用参数组件
import RqSoftMeasurement from "@/views/RqBoiler/RqSoftNumber/index.vue"; //软测量数统
import RqOnlineService from "@/views/RqBoiler/RqOnlineService/index.vue"; //客服
export default {
  name: "index",
  components: {
    Manual,
    RqCommonParameters,
    RqSoftMeasurement,
    Historical,
    inputVal,
    RqOnlineService,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      projectData: "",
      isMshow: false,
      isHshow: false,
      headerStyle: {
        "font-weight": "500",
        "font-size": "1.8vh",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      flag: 5,
      Panualmark: "",
      Panualnode: "",
      Panualnodem: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      config: {
        data: [10],
        shape: "round",
        colors: ["#43ff44", "#43ff44"],
        waveOpacity: "1",
        waveNum: "50",
        formatter: "{value}",
      },
      rightHeight: "",
      spotArr: [],
      projectData: "",
      grouptime: null,
      authInfo: [],
      UserInfo: [],
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
    this.$bus.$on("sendMsg", (msg) => {
      // 兄弟组件发送来的消息
      this.flag = msg;
    });
  },
  computed: {},
  mounted() {},
  methods: {
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "rqkfirstcontolRQL";
            break;
          case 3:
            pathname = "rqRfirstcontolRQL";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    toCompon(key, name, name2, name3, name4, titname, param1, param2, param3) {
      this.isIndex = key;
      this.historyname = name;
      this.nodename = name2;
      this.Aname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];
              // 判断是否为调试还是查看权限
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无调试权限");
              }
            } else {
              return (
                (this.isMshow = true),
                (this.Manualname = name),
                (this.Manualnode = name2),
                (this.ManualAname = name3),
                (this.Manualtitname = titname),
                (this.Panualmark = param1),
                (this.Panualnode = param2),
                (this.Panualnodem = param3)
              );
            }
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = name4);
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
    isClose(val, val1) {
      this.maskbok = false;
      switch (val) {
        case "Manual":
          return (this.isMshow = val1);
        case "Historical":
          return (this.isHshow = val1);
        case "RqOnlineService":
        case this.flag == 7:
          return (this.flag = 5), this.$bus.$emit("footersendMsg", 5);
        case "RqSoftNumber":
        case this.flag == 6:
          return (this.flag = 5), this.$bus.$emit("footersendMsg", 5);
        case "RqCommonParameters":
        case this.flag == 4:
          return (this.flag = 5), this.$bus.$emit("footersendMsg", 5);
      }
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type, status) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type, status);
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.rqltltx {
  /* // margin-top:4vh; */
  width: 99vw;
  height: 83vh;
  margin-left: 0.5vw;
  font-size: 1.6vh;
  font-family: PingFang-SC-Regular;
  color: #fff;

  .fl {
    float: left;
  }

  .bgmain {
    margin-top: 2vh;
    width: 99vw;
    height: 83vh;
    background-image: url("~@/assets/images/rql_tltx.png");
    background-size: 100% 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .title {
    width: 58vw;
    margin-left: 36vw;
    margin-top: 0vh;
    margin-bottom: 14vh;
    height: 4vh;
    font-family: MicrosoftYaHei;
    font-size: 1.6vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 4vh;
    letter-spacing: 0vw;
    color: #ffffff;
    box-shadow: 0vw 0vw 0vw 0vw #009ed2;
  }

  .row1-main {
    width: 98vw;
    height: 78vh;
    // border: #ffffff 1px solid;
    margin-top: -13vh;
  }

  .leftmain {
    width: 28vw;
    height: 78vh;
    //  margin-top: -3vh;
    // border:1px solid springgreen
  }

  .centermain {
    width: 35vw;
    height: 78vh;
    // border:1px solid springgreen
  }

  .leftrow1 {
    width: 10vw;
    // margin-top:2vh;
    margin-left: 12vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.2vw;
    color: #fefefe;
  }

  .leftrow2 {
    width: 28vw;
    margin-top: 18vh;
    margin-left: 1vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.2vw;
    color: #fefefe;
  }

  .left-lable {
    width: 6vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: rgb(243, 17, 17);
  }

  .leftjg {
    margin-left: 4vw;
  }

  .leftrow3 {
    width: 28vw;
    margin-top: 27vh;
    margin-left: 23vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.1vw;
    color: #5ca4a6;
  }

  .bottonx {
    width: 1vw;
    height: 2vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 0.9vw;
    margin-right: 0.1vw;
    color: #fff;
    cursor: pointer;
  }

  .leftrow4 {
    margin-top: 8vh;
    width: 28vw;
    margin-left: 5vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.1vw;
    color: #5ca4a6;
  }

  .down {
    color: #14a4ff;
    cursor: pointer;
  }

  .leftrow4jg {
    margin-left: 3.5vw;
    height: 3vh;
    margin-top: -4vh;
  }

  .leftrow4jg1 {
    margin-left: 3.5vw;
    height: 3vh;
    color: #fff;
    cursor: pointer;
  }

  .leftrow4jg2 {
    margin-left: 3vw;
    height: 3vh;
    color: #fff;
  }

  .leftrow5 {
    margin-top: 3vh;
    width: 28vw;
    margin-left: 3.2vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.1vw;
    color: #fff;
    cursor: pointer;
  }

  .leftrow5wd {
    width: 10vw;
    height: 3vh;
  }

  .leftrow5jg {
    margin-left: 5vw;
  }

  .leftrow5jg2 {
    margin-left: 8vw;
    margin-top: 4vh;
    color: #5ca4a6;
  }

  .leftrow5jg3 {
    margin-top: 4vh;
    margin-left: 2vw;
    color: #5ca4a6;
  }

  .cen-top {
    width: 35vw;
    height: 25.8vh;
  }

  .cen-lable {
    margin-top: 6vh;
    margin-left: 5vw;
    widows: 1vw;
    height: 7vh;
    font-family: PingFang-SC-Regular;
    font-size: 1.1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #5ca4a6;
  }

  .cen-lable1 {
    margin-top: 4.6vh;
    margin-left: 25vw;
    widows: 1vw;
    height: 7vh;
    font-family: PingFang-SC-Regular;
    font-size: 1.1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #fff;
  }

  .cen-lable2 {
    margin-top: -1.6vh;
    margin-left: 25vw;
    widows: 1vw;
    height: 7vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #fff;
    cursor: pointer;
  }

  .cen-lable3 {
    margin-top: -0.5vh;
    margin-left: 26vw;
    widows: 1vw;
    height: 7vh;
    font-family: PingFang-SC-Regular;
    font-size: 1.1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #5ca4a6;
  }

  .centerrow1 {
    width: 15.5vw;
    height: 25.8vh;
    border-top: 1px solid #0e3140;
    border-left: 1px solid #0e3140;
    border-right: 1px solid #0e3140;
    margin-left: 1vw;
  }

  .cen-co1 {
    width: 15.5vw;
    height: 5vh;
    background-color: #205365;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    font-family: MicrosoftYaHei;
    line-height: 5vh;
    letter-spacing: 0vh;
    color: #5ca4a6;
    text-align: center;
  }

  .cen-co2 {
    width: 15.5vw;
    height: 4vh;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    color: #5ca4a6;
    text-align: center;
    border-bottom: 1px solid #0e3140;
  }

  .cen-co2-row1 {
    width: 7.6vw;
    height: 4vh;
    color: #53d0d3;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    text-align: center;
    font-family: PingFang-SC-Regular;
  }

  .jange {
    margin-left: -1vw;
    color: #5ca4a6;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
  }

  .mag-top1 {
    margin-top: 1.8vh;
    margin-left: 2.2vw;
    position: relative;
    z-index: 100;
  }

  .mag-top2 {
    margin-top: 1.8vh;
    margin-left: 2.2vw;
  }

  .mag-top3 {
    margin-top: 1.3vh;
    margin-left: 2.2vw;
  }

  .mag-top4 {
    margin-top: 3vh;
    margin-left: 2.2vw;
  }

  .cen-co2-row2 {
    width: 7.6vw;
    height: 4vh;
    color: #fff;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    text-align: center;
    font-family: PingFang-SC-Regular;
    border-left: 1px solid #0e3140;
    cursor: pointer;
  }

  .rig-left {
    width: 13vw;
    height: 80vh;
    margin-left: 0.8vw;
    // border:solid springgreen 1px
  }

  .rig-lable1 {
    width: 14vw;
    height: 3vh;
    font-family: PingFang-SC-Regular;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0vw;
    color: #fefefe;
    text-align: center;
  }

  .rig-row1 {
    width: 5vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0vw;
    color: #fefefe;
    text-align: center;
    margin-left: 1.3vw;
    cursor: pointer;
  }

  .rig-jge {
    margin-top: 40vh;
  }

  .rig-jge1 {
    margin-top: 5vh;
  }

  .rig-jge2 {
    margin-top: 3vh;
  }

  .rig-right {
    width: 20vw;
    height: 80vh;
    // border:solid springgreen 1px
  }

  .rig-rightjg {
    margin-left: 6vw;
    cursor: pointer;
  }

  .righ-lable2 {
    margin-top: 0vh;
    margin-left: 16vw;
    width: 10vw;
    height: 2vh;
    font-family: MicrosoftYaHei;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vw;
    color: #5ca4a6;
    // border:1px salmon solid;
  }

  .righ-lable3 {
    margin-top: 30vh;
    margin-left: 2vw;
    width: 21vw;
    height: 20vh;
    font-family: MicrosoftYaHei;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1vw;
    letter-spacing: 0vw;
    // color: #5ca4a6;
    // border:1px salmon solid
  }

  .righ-lable4 {
    margin-top: 2vh;
    margin-left: 2vw;
    width: 22vw;
    height: 20vh;
    font-family: MicrosoftYaHei;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1vw;
    letter-spacing: 0vw;
    // color: #5ca4a6;
    // border:1px salmon solid
  }

  .righ-col1 {
    width: 12vw;
    height: 20vh;
    margin-top: 1.5vh;
    font-size: 1vw;
    margin-left: 1vw;
  }

  .righ-col1-lb {
    width: 5vw;
    height: 2vh;
    margin-left: 1vw;
    cursor: pointer;
  }

  .righ-col1-jg {
    margin-top: -8vh;
  }

  .righ-col1-jg3 {
    margin-top: -15.5vh;
    margin-left: 1vw;
  }

  .jgxx {
    margin-left: 1vw;
    margin-top: vh;
  }

  .righ-col1-jg2 {
    margin-left: 1vw;
  }

  .righ-col21 {
    width: 10vw;
    height: 20vh;
    margin-top: -10vh;
    font-size: 1vw;
    margin-left: 1vw;
    color: #5ca4a6;
  }

  .jianh {
    margin-top: -4vh;
    margin-left: -1vw;
  }

  .jianh1 {
    margin-top: 14vh;
    margin-left: -1vw;
  }

  .jianh3 {
    margin-left: 13.5vw;
    margin-top: -21vh;
  }

  .jianh4 {
    margin-top: 4vh;
    margin-left: 1vw;
  }
}
</style>
