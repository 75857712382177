import http from "../../utils/http";
/**
 * 获取通讯总览接口
 */

export function getProjectlist(params) {
  return http({
    url: "rts_info/show",
    method: "POST",
    data: params,
  });
}

// 权限通讯总览
export function authProjectlist(params) {
  return http({
    url: "rts_info/auth_show",
    method: "POST",
    data: params,
  });
}
// CEI统计结果项目列表
export function ceilist(params) {
  return http({
    url: "/cei/list",
    method: "POST",
    data: params,
  });
}
/**
 * 新增项目
 */
export function getAddlist(params) {
  return http({
    url: "/rts_info/add",
    method: "POST",
    data: params,
  });
}
// 删除项目
export function deletList(params) {
  return http({
    url: "rts_info/delete",
    method: "POST",
    data: params,
  });
}
/**
 * 修改通讯总览项目
 */
export function editList(params) {
  return http({
    url: "rts_info/edit",
    method: "POST",
    data: params,
  });
}
/**
 * 通讯面板统计
 */
export function panelList(params) {
  return http({
    url: "rts_info/info_panel",
    method: "POST",
    data: params,
  });
}
/**
 * 获取权限菜单接口
 */

export function getAdminlist(params) {
  return http({
    url: "/user/device_list",
    method: "POST",
    data: params,
  });
}

/**
 * 注册用户接口
 */

export function getAdduser(params) {
  return http({
    url: "/add_user",
    method: "post",
    data: params,
  });
}

/**
 * 获取用户接口
 */
export function getUser(params) {
  return http({
    url: "/user/all_list",
    method: "post",
    data: params,
  });
}
/**
 * 获取用户已授权
 */
export function authUser(params) {
  return http({
    url: "/user/auth_device_list",
    method: "post",
    data: params,
  });
}
/**
 * 修改用户权限
 */
export function getEdituser(params) {
  return http({
    url: "/edit_user",
    method: "post",
    data: params,
  });
}

/**
 * VPN开关
 */
export function vpnLink(params) {
  return http({
    url: "/vpn",
    method: "post",
    data: params,
  });
}

/**
 * 删除用户
 */
export function del_user(params) {
  return http({
    url: "/del_user",
    method: "post",
    data: params,
  });
}
/**
 * 查询项目类型列表
 */
export function ProjectTypesAPI() {
  return http({
    url: "/rts_info/getProjectTypes",
    method: "get",
  });
}
/**
 * 添加项目
 */
export function saveProjectTypeAPI(params) {
	return http({
	  url: "/rts_info/saveProjectType",
	  method: "post",
	  data: params,
	});
  }