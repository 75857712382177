<template>
  <div class="manual drag" v-draw>
    <div class="param-header">
      <span class="param-header-title">手操器</span>
      <div class="param-header-icon" @click="closeCompon">
        <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
    </div>
    <div class="manual-con">
      <div class="manual-con-tit">{{ titname }}</div>
      <div class="manual-con-p">
        <el-slider
          v-model="manualP"
          :format-tooltip="formatTooltip"
          disabled
          v-if="projectData.type != 'rql'"
        ></el-slider>
        <el-slider
          v-model="manualP1"
          :format-tooltip="formatTooltip1"
          disabled
          v-else
        ></el-slider>
        <div class="manual-con-text flex">
          <div>关</div>
          <div style="margin-left: auto">开</div>
        </div>
      </div>
      <div class="manual-con-o manual-con-p">
        <el-slider
          v-model="manualP"
          :format-tooltip="formatTooltip"
          disabled
        ></el-slider>
        <div class="manual-con-text flex">
          <div>关</div>
          <div style="margin-left: auto">开</div>
        </div>
      </div>
      <div class="manual-con-type flex alicn-cen">
        <div class="type-p">
          <span class="" style="color: #1c9bfb">P</span>
          <span class="type-p-data" v-if="projectData.type != 'rql'">{{
            infoList[node].AV
          }}</span>
          <span class="type-p-data" v-else>{{ infoList[pmark] }}</span>
        </div>
        <div class="type-o">
          <span class="" style="color: #0de0ac">O</span>
          <span class="type-p-data">{{ infoList[node].AV }}</span>
        </div>
      </div>
      <div class="manual-con-btn flex">
        <div
          class="pzd"
          :class="{ oactive: 1 == current }"
          @click="isDisable ? downZ(1) : ''"
        >
          自动
        </div>
        <div
          class="pzd"
          :class="{ oactive: 1 != current }"
          @click="isDisable ? downZ(0) : ''"
        >
          手动
        </div>
      </div>
      <div class="manual-con-add flex">
        <div
          class="add-double-left"
          @mouseenter="current != 1 ? enter(1) : ''"
          @mouseleave="current != 1 ? leave(1) : ''"
          :style="
            index == 1 && current != 1
              ? activStyle
              : 'background:#001a2a;cursor:no-drop;'
          "
          @click="
            isDisable && current != 1
              ? numberjian(5, infoList[node].AV, 1)
              : autotip()
          "
        >
          <span
            class="el-icon-caret-left"
            :style="index == 1 ? textcololr : ''"
          ></span>
          <span
            class="el-icon-caret-left el-icon-caret-left2"
            :style="index == 1 ? textcololr : ''"
          ></span>
        </div>
        <div class="add-double-cen flex align-cen">
          <div
            class="add-double-cen-btn"
            @mouseenter="current != 1 ? enter(2) : ''"
            @mouseleave="current != 1 ? leave(2) : ''"
            :style="
              index == 2 && current != 1
                ? activStyle
                : 'background:#001a2a;cursor:no-drop;'
            "
            @click="
              isDisable && current != 1
                ? numberjian(1, infoList[node].AV, 2)
                : autotip()
            "
          >
            <span
              class="el-icon-caret-left"
              :style="index == 2 ? textcololr : ''"
            ></span>
          </div>
          <div
            class="add-double-cen-btn"
            @mouseenter="current != 1 ? enter(3) : ''"
            @mouseleave="current != 1 ? leave(3) : ''"
            :style="
              index == 3 && current != 1
                ? activStyle
                : 'background:#001a2a;cursor:no-drop;'
            "
            @click="
              isDisable && current != 1
                ? numberAdd(1, infoList[node].AV, 3)
                : autotip()
            "
          >
            <span
              class="el-icon-caret-right"
              :style="index == 3 ? textcololr : ''"
            ></span>
          </div>
        </div>
        <div
          class="add-double-left add-double-right"
          @mouseenter="current != 1 ? enter(4) : ''"
          @mouseleave="current != 1 ? leave(4) : ''"
          :style="
            index == 4 && current != 1
              ? activStyle
              : 'background:#001a2a;cursor:no-drop;'
          "
          @click="
            isDisable && current != 1
              ? numberAdd(5, infoList[node].AV, 4)
              : autotip()
          "
        >
          <span
            class="el-icon-caret-right el-icon-caret-left1"
            :style="index == 4 ? textcololr : ''"
          ></span>
          <span
            class="el-icon-caret-right"
            :style="index == 4 ? textcololr : ''"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { downmanAction } from "@/api/index/index";
import { downAction } from "@/api/index/index";
export default {
  name: "Manual",
  props: {
    historyname: [String, Number], //接口返回的点名
    node: [String, Number], //节点名
    Lkname: [String, Number], //力控表对应的点名
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
    titname: [String, Number], //tit名字
    pmark: [String, Number], //p变量
    pnode: [String, Number], //p节点
    pnm: [String, Number],
  },
  data: () => {
    return {
      manualP: 0,
      manualO: 0,
      manualP1: 0,
      isDisable: true,
      current: 0,
      activStyle: "",
      index: "",
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    historyname: {
      handler(n, o) {
        this.historyname = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    node: {
      handler(n, o) {
        this.node = n;
        
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    Lkname: {
      handler(n, o) {
        this.Lkname = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    titname: {
      handler(n, o) {
        this.titname = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    pnm: {
      handler(n, o) {
        this.titname = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    pmark: {
      handler(n, o) {
        this.titname = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    pnode: {
      handler(n, o) {
        this.titname = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    // console.log(this.node);
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.manualP = parseInt(this.infoList[this.node].AV);
    if (this.pnode)
      this.manualP1 = parseInt(this.infoList[this.pmark]);
    this.current = this.infoList[this.node].RM;
  },
  methods: {
    // 自动提示
    autotip() {
      if (!this.isDisable) return this.$message.error("请勿快速点击");
      if (this.current == 1) return this.$message.error("请先切换成手动");
    },
    // 移入移出
    enter(index) {
      this.index = index;
      this.activStyle = "background:#77e5c7";
      this.textcololr = "color:#fff";
    },
    leave(index) {
      this.index = index;
      this.activStyle = "background:#001a2a";
      this.textcololr = "color:#93ACB9";
    },
    // 自动手动
    downZ(val) {
      downAction({
        device: this.projectData.project,
        mark: "RM",
        node: this.node,
        value: val,
        cp_type: this.projectData.type,
      })
        .then((res) => {
          if (res.info.res == 1) {
            this.current = val;
          } else {
            this.$message.error("出错了");
          }
        })
        .catch((err) => {
          this.$message.error("下置失败");
        });
    },
    // 下边按钮增减
    numberAdd(num, nownum, action) {
      this.isDisable = false;
      this.manualP += num;
      let newnum = parseFloat(this.infoList[this.node].AV) + num;
      this.infoList[this.node].AV = newnum.toFixed(2);
      downmanAction({
        device: this.projectData.project,
        mark: this.historyname,
        node: this.node,
        value: this.infoList[this.node].AV,
        type: "FLOAT",
        action: action,
        cp_type: this.projectData.type,
      })
        .then((res) => {
          if (res.info.res == 1) {
            this.isDisable = true;
          } else {
            this.$message.error("出错了");
            this.isDisable = true;
            this.infoList[this.node].AV = parseFloat(nownum);
            this.manualP = parseFloat(nownum);
          }
        })
        .catch((err) => {
          this.$message.error("下置失败");
          this.isDisable = true;
          this.infoList[this.node].AV = parseFloat(nownum);
          this.manualP = parseFloat(nownum);
        });
    },
    numberjian(num, nownum, action) {
      if (this.manualP <= 0 || this.infoList[this.node].AV <= 0) {
        return;
      } else {
        this.manualO -= num;
        this.isDisable = false;
        let newnum1 = parseFloat(this.infoList[this.node].AV) - num;
        this.infoList[this.node].AV = newnum1.toFixed(2);
        downmanAction({
          device: this.projectData.project,
          mark: this.historyname,
          node: this.node,
          value: this.infoList[this.node].AV,
          type: "FLOAT",
          action: action,
          cp_type: this.projectData.type,
        })
          .then((res) => {
            if (res.info.res == 1) {
              this.isDisable = true;
            } else {
              this.$message.error("出错了");
              this.isDisable = true;
              this.infoList[this.node].AV = parseFloat(nownum);
              this.manualP = parseFloat(nownum);
            }
          })
          .catch((err) => {
            this.$message.error("下置失败");
            this.isDisable = true;
            this.infoList[this.node].AV = parseFloat(nownum);
            this.manualP = parseFloat(nownum);
          });
        if (this.infoList[this.node].AV <= 100) {
          this.manualP = parseInt(this.infoList[this.node].AV);
          if (this.infoList[this.node].AV <= 0) {
            this.infoList[this.node].AV = 0.0;
            this.manualP = 0;
          }
        }
      }
    },
    closeCompon() {
      this.$emit("sendStatus", "Manual", false);
    },
    // 格式化滑块数字
    formatTooltip(val) {
      return this.manualO;
    },
    formatTooltip1(val) {
      return this.manualP1;
    },
  },
};
</script>
<style lang="scss" scoped>
.manual {
  width: 588px;
  height: 416px;
  //  background: #fff;
  cursor: move;
  border-radius: 5px;
  background-image: url("~@/assets/images/shoucaoqi.png");
  .param-header {
    height: 50px;
    //  background: #5DCEF3;
    line-height: 50px;
    text-align: center;
    color: #041a28;
    font-size: 24px;
    position: relative;
    border-radius: 5px 5px 0 0;
    .param-header-title {
      width: 400px;
      height: 28px;
      font-family: MicrosoftYaHei;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #0ef7ff;
    }
    .param-header-icon {
      width: 2vw;
      height: 2vh;
      position: absolute;
      right: 1vw;
      margin-top: -7vh;
      cursor: pointer;
    }
  }
  .manual-header-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 10px;
    top: 8px;
    border-radius: 30px;
    border: 1px solid #041a28;
    color: #041a28;
    line-height: 32px;
    cursor: pointer;
    letter-spacing: 2px;
  }
  .manual-con {
    padding: 10px;
    .manual-con-tit {
      font-size: 20px;
      color: #d5fffe;
      margin: 5px 0;
      letter-spacing: 2px;
    }
    .manual-con-p {
      background: #014662;
      border-radius: 6px;
      height: 80px;
      margin: 5px 10px;
      padding: 0 10px;
    }
    .manual-con-text {
      font-size: 20px;
      padding: 0 10px;
      color: #22465b;
      line-height: 20px;
    }
    .manual-con-type {
      padding: 0px 10px;
      font-size: 20px;
      color: #22465b;
      line-height: 36px;
      .type-p-data {
        display: inline-block;
        background: #001a2a;
        padding: 2px 50px;
        margin-left: 20px;
        color: #1c9bfb;
      }
      .type-o {
        margin-left: auto;
        .type-p-data {
          display: inline-block;
          background: #001a2a;
          padding: 2px 50px;
          margin-right: 20px;
          color: #0de0ac !important;
        }
      }
    }
  }
}
::v-deep {
  .manual-con-p {
    .el-slider__runway {
      height: 10px;
      background-color: #022537;
      box-shadow: 0px 1px 0px 0px #d4e3e8,
        inset 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      .el-slider__bar {
        height: 10px;
        background-color: #d4e3e8;
        box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
      .el-slider__button-wrapper {
        top: -13px;
      }
      .el-slider__button {
        width: 32px;
        height: 18px;
        background-image: linear-gradient(-45deg, #61f9ff 0%, #02a1dc 65%),
          linear-gradient(-45deg, #207295 0%, #6ff6ff 100%);
        background-blend-mode: normal, normal;
        border-radius: 10px;
        border: none;
      }
    }
  }
  .manual-con-o {
    .el-slider__bar {
      background-color: #98ecd5 !important;
      box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.08) !important;
    }
    .el-slider__button {
      background-image: linear-gradient(-45deg, #61f9ff 0%, #07ae75 65%),
        linear-gradient(-45deg, #207295 0%, #6ff6ff 100%) !important;
      background-blend-mode: normal, normal !important;
    }
  }
  .manual-con-btn {
    justify-content: center;
    margin-top: 10px;

    div {
      padding: 2px 20px;
      border-radius: 3px;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
    }
    .pzd {
      background: #056181;

      color: #fff;
      margin-right: 10px;
    }
    .oactive {
      background: #0ef7ff;
      color: #fff;
    }
  }
  .manual-con-add {
    margin-top: 20px;
    .add-double-left {
      background: #022846;
      padding: 0px 40px;
      margin-left: 10px;
      color: #1ba3da;
      cursor: pointer;
      span {
        color: #93acb9;
        font-size: 25px;
        display: inline-block;
        height: 30px;
        line-height: 30px;
      }
      .el-icon-caret-left2 {
        margin-left: -10px;
      }
      .el-icon-caret-left1 {
        margin-right: -10px;
      }
    }
    .add-double-right {
      margin-right: 10px;
    }
  }

  .add-double-cen {
    flex-grow: 1;
    justify-content: center;
    .add-double-cen-btn {
      background: #022846;
      padding: 0px 30px;
      margin-left: 20px;
      color: #1ba3da;
      cursor: pointer;
      span {
        color: #93acb9;
        font-size: 25px;
        display: inline-block;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}
</style>