<template>
  <div class="jrlsecurty" id="jrlsecurty">
    <!-- 标题部分 -->
    <div class="title-hang">
      <div class="fl">
        <dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
      </div>
      <div class="fl"></div>
      <div class="zhuangshizj fl">{{ projectData.project }}_安全设置</div>
      <div class="fl">
        <div class="fl">
          <dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
        </div>
      </div>
    </div>
    <!-- 上半部分 -->
    <div class="topmain">
      <div class="topmain-row1 fl">
        <div class="htjg">安全</div>
        <div class="htjg">生产</div>
        <div class="htjg">阀位</div>
        <div class="htjg">设置</div>
      </div>
      <div class="topmain-row2 fl">
        <div class="topmain-row2-col1">均热段煤气阀门上限</div>
        <div class="topmain-row2-col1">均热段空气阀门上限</div>
        <div class="topmain-row2-col1">均热段煤烟阀门上限</div>
        <div class="topmain-row2-col1">均热段空烟阀门上限</div>
        <div class="topmain-row2-col1">二加热段煤气阀门上限</div>
        <div class="topmain-row2-col1">二加热段空气阀门上限</div>
        <div class="topmain-row2-col1">二加热段煤烟阀门上限</div>
        <div class="topmain-row2-col1">二加热段空烟阀门上限</div>
        <div class="topmain-row2-col1">鼓风机频率上限</div>
        <div class="topmain-row2-col1">空烟气引风频率上限</div>
        <div class="topmain-row2-col11">均热段煤气设定上限</div>
      </div>
      <div class="topmain-row3 fl">
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN2.OutT,
              '均热段煤气阀门上限',
              'OutT',
              infoList.MAN2_node_string
            )
          "
        >
          {{ "MAN2" in infoList ? infoList.MAN2.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN1.OutT,
              '均热段空气阀门上限',
              'OutT',
              infoList.MAN1_node_string
            )
          "
        >
          {{ "MAN1" in infoList ? infoList.MAN1.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN4.OutT,
              '均热段煤烟阀门上限',
              'OutT',
              infoList.MAN4_node_string
            )
          "
        >
          {{ "MAN4" in infoList ? infoList.MAN4.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN3.OutT,
              '均热段空烟阀门上限',
              'OutT',
              infoList.MAN3_node_string
            )
          "
        >
          {{ "MAN3" in infoList ? infoList.MAN3.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN10.OutT,
              '二加热段煤气阀门上限',
              'OutT',
              infoList.MAN10_node_string
            )
          "
        >
          {{ "MAN10" in infoList ? infoList.MAN10.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN9.OutT,
              '二加热段空气阀门上限',
              'OutT',
              infoList.MAN9_node_string
            )
          "
        >
          {{ "MAN9" in infoList ? infoList.MAN9.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN12.OutT,
              '二加热段煤烟阀门上限',
              'OutT',
              infoList.MAN12_node_string
            )
          "
        >
          {{ "MAN12" in infoList ? infoList.MAN12.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN11.OutT,
              '二加热段空烟阀门上限',
              'OutT',
              infoList.MAN11_node_string
            )
          "
        >
          {{ "MAN11" in infoList ? infoList.MAN11.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN16.OutT,
              '鼓风机频率上限',
              'OutT',
              infoList.MAN16_node_string
            )
          "
        >
          {{ "MAN16" in infoList ? infoList.MAN16.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN13.OutT,
              '空烟气引风频率上限',
              'OutT',
              infoList.MAN13_node_string
            )
          "
        >
          {{ "MAN13" in infoList ? infoList.MAN13.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col11"
          @click="
            toIpt(
              infoList.MQ11SPH,
              '均热段煤气设定上限',
              'MQ11SPH',
              infoList.MQ11SPH_node_string
            )
          "
        >
          {{ "MQ11SPH" in infoList ? infoList.MQ11SPH : 0 }}
        </div>
      </div>

      <div class="topmain-row2 fl">
        <div class="topmain-row2-col1">均热段煤气阀门下限</div>
        <div class="topmain-row2-col1">均热段空气阀门下限</div>
        <div class="topmain-row2-col1">均热段煤烟阀门下限</div>
        <div class="topmain-row2-col1">均热段空烟阀门下限</div>
        <div class="topmain-row2-col1">二加热段煤气阀门下限</div>
        <div class="topmain-row2-col1">二加热段空气阀门下限</div>
        <div class="topmain-row2-col1">二加热段煤烟阀门下限</div>
        <div class="topmain-row2-col1">二加热段空烟阀门下限</div>
        <div class="topmain-row2-col1">鼓风机频率下限</div>
        <div class="topmain-row2-col1">空烟气引风频率下限</div>
        <div class="topmain-row2-col11">三加热段煤气设定上限</div>
      </div>
      <div class="topmain-row3 fl">
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN2.OutB,
              '均热段煤气阀门下限',
              'OutB',
              infoList.MAN2_node_string
            )
          "
        >
          {{ "MAN2" in infoList ? infoList.MAN2.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN1.OutB,
              '均热段空气阀门下限',
              'OutB',
              infoList.MAN1_node_string
            )
          "
        >
          {{ "MAN1" in infoList ? infoList.MAN1.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN4.OutB,
              '均热段煤烟阀门下限',
              'OutB',
              infoList.MAN4_node_string
            )
          "
        >
          {{ "MAN4" in infoList ? infoList.MAN4.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN3.OutB,
              '均热段空烟阀门下限',
              'OutB',
              infoList.MAN3_node_string
            )
          "
        >
          {{ "MAN3" in infoList ? infoList.MAN3.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN10.OutB,
              '二加热段煤气阀门下限',
              'OutB',
              infoList.MAN10_node_string
            )
          "
        >
          {{ "MAN10" in infoList ? infoList.MAN10.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN9.OutB,
              '二加热段空气阀门下限',
              'OutB',
              infoList.MAN9_node_string
            )
          "
        >
          {{ "MAN9" in infoList ? infoList.MAN9.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN12.OutB,
              '二加热段煤烟阀门下限',
              'OutB',
              infoList.MAN12_node_string
            )
          "
        >
          {{ "MAN12" in infoList ? infoList.MAN12.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN11.OutB,
              '二加热段空烟阀门下限',
              'OutB',
              infoList.MAN11_node_string
            )
          "
        >
          {{ "MAN11" in infoList ? infoList.MAN11.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN16.OutB,
              '鼓风机频率下限',
              'OutB',
              infoList.MAN16_node_string
            )
          "
        >
          {{ "MAN16" in infoList ? infoList.MAN16.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN13.OutB,
              '空烟气引风频率下限',
              'OutB',
              infoList.MAN13_node_string
            )
          "
        >
          {{ "MAN13" in infoList ? infoList.MAN13.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col11"
          @click="
            toIpt(
              infoList.MQ21SPH,
              '三加热段煤气设定上限',
              'MQ21SPH',
              infoList.MQ21SPH_node_string
            )
          "
        >
          {{ "MQ21SPH" in infoList ? infoList.MQ21SPH : 0 }}
        </div>
      </div>

      <div class="topmain-row2 fl">
        <div class="topmain-row2-col1">三加热段煤气阀门上限</div>
        <div class="topmain-row2-col1">三加热段空气阀门上限</div>
        <div class="topmain-row2-col1">三加热段煤烟阀门上限</div>
        <div class="topmain-row2-col1">三加热段空烟阀门上限</div>
        <div class="topmain-row2-col1">一加热段煤气阀门上限</div>
        <div class="topmain-row2-col1">一加热段空气阀门上限</div>
        <div class="topmain-row2-col1">一加热段煤烟阀门上限</div>
        <div class="topmain-row2-col1">一加热段空烟阀门上限</div>
        <div class="topmain-row2-col1">煤气总管阀门上限</div>
        <div class="topmain-row2-col1">煤烟气引风频率上限</div>
        <div class="topmain-row2-col11">二加热段煤气设定上限</div>
      </div>
      <div class="topmain-row3 fl">
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN6.OutT,
              '三加热段煤气阀门上限',
              'OutT',
              infoList.MAN6_node_string
            )
          "
        >
          {{ "MAN6" in infoList ? infoList.MAN6.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN5.OutT,
              '三加热段空气阀门上限',
              'OutT',
              infoList.MAN5_node_string
            )
          "
        >
          {{ "MAN5" in infoList ? infoList.MAN5.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN8.OutT,
              '三加热段煤烟阀门上限',
              'OutT',
              infoList.MAN8_node_string
            )
          "
        >
          {{ "MAN8" in infoList ? infoList.MAN8.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN7.OutT,
              '三加热段空烟阀门上限',
              'OutT',
              infoList.MAN7_node_string
            )
          "
        >
          {{ "MAN7" in infoList ? infoList.MAN7.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN21.OutT,
              '一加热段煤气阀门上限',
              'OutT',
              infoList.MAN21_node_string
            )
          "
        >
          {{ "MAN21" in infoList ? infoList.MAN21.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN20.OutT,
              '一加热段空气阀门上限',
              'OutT',
              infoList.MAN20_node_string
            )
          "
        >
          {{ "MAN20" in infoList ? infoList.MAN20.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN23.OutT,
              '一加热段煤烟阀门上限',
              'OutT',
              infoList.MAN23_node_string
            )
          "
        >
          {{ "MAN23" in infoList ? infoList.MAN23.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN22.OutT,
              '一加热段空烟阀门上限',
              'OutT',
              infoList.MAN22_node_string
            )
          "
        >
          {{ "MAN22" in infoList ? infoList.MAN22.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN24.OutT,
              '煤气总管阀门上限',
              'OutT',
              infoList.MAN24_node_string
            )
          "
        >
          {{ "MAN24" in infoList ? infoList.MAN24.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN14.OutT,
              '煤烟气引风频率上限',
              'OutT',
              infoList.MAN14_node_string
            )
          "
        >
          {{ "MAN14" in infoList ? infoList.MAN14.OutT : 0 }}
        </div>
        <div
          class="topmain-row3-col11"
          @click="
            toIpt(
              infoList.MQ31SPH,
              '二加热段煤气设定上限',
              'MQ31SPH',
              infoList.MQ31SPH_node_string
            )
          "
        >
          {{ "MQ31SPH" in infoList ? infoList.MQ31SPH : 0 }}
        </div>
      </div>
      <div class="topmain-row2 fl">
        <div class="topmain-row2-col1">三加热段煤气阀门下限</div>
        <div class="topmain-row2-col1">三加热段空气阀门下限</div>
        <div class="topmain-row2-col1">三加热段煤烟阀门下限</div>
        <div class="topmain-row2-col1">三加热段空烟阀门下限</div>
        <div class="topmain-row2-col1">一加热段煤气阀门下限</div>
        <div class="topmain-row2-col1">一加热段空气阀门下限</div>
        <div class="topmain-row2-col1">一加热段煤烟阀门下限</div>
        <div class="topmain-row2-col1">一加热段空烟阀门下限</div>
        <div class="topmain-row2-col1">煤气总管阀门下限</div>
        <div class="topmain-row2-col1">煤烟气引风频率下限</div>
        <div class="topmain-row2-col11">一加热段煤气设定上限</div>
      </div>
      <div class="topmain-row3 fl">
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN6.OutB,
              '三加热段煤气阀门下限',
              'OutB',
              infoList.MAN6_node_string
            )
          "
        >
          {{ "MAN6" in infoList ? infoList.MAN6.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN5.OutB,
              '三加热段空气阀门下限',
              'OutB',
              infoList.MAN5_node_string
            )
          "
        >
          {{ "MAN5" in infoList ? infoList.MAN5.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN8.OutB,
              '三加热段煤烟阀门下限',
              'OutB',
              infoList.MAN8_node_string
            )
          "
        >
          {{ "MAN8" in infoList ? infoList.MAN8.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN7.OutB,
              '三加热段空烟阀门下限',
              'OutB',
              infoList.MAN7_node_string
            )
          "
        >
          {{ "MAN7" in infoList ? infoList.MAN7.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN21.OutB,
              '一加热段煤气阀门下限',
              'OutB',
              infoList.MAN21_node_string
            )
          "
        >
          {{ "MAN21" in infoList ? infoList.MAN21.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN20.OutB,
              '一加热段空气阀门下限',
              'OutB',
              infoList.MAN20_node_string
            )
          "
        >
          {{ "MAN20" in infoList ? infoList.MAN20.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN23.OutB,
              '一加热段煤烟阀门下限',
              'OutB',
              infoList.MAN23_node_string
            )
          "
        >
          {{ "MAN23" in infoList ? infoList.MAN23.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN22.OutB,
              '一加热段空烟阀门下限',
              'OutB',
              infoList.MAN22_node_string
            )
          "
        >
          {{ "MAN22" in infoList ? infoList.MAN22.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN24.OutB,
              '煤气总管阀门下限',
              'OutB',
              infoList.MAN24_node_string
            )
          "
        >
          {{ "MAN24" in infoList ? infoList.MAN24.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.MAN14.OutB,
              '煤烟气引风频率下限',
              'OutB',
              infoList.MAN14_node_string
            )
          "
        >
          {{ "MAN14" in infoList ? infoList.MAN14.OutB : 0 }}
        </div>
        <div
          class="topmain-row3-col11"
          @click="
            toIpt(
              infoList.MQ41SPH,
              '一加热段煤气设定上限',
              'MQ41SPH',
              infoList.MQ41SPH_node_string
            )
          "
        >
          {{ "MQ41SPH" in infoList ? infoList.MQ41SPH : 0 }}
        </div>
      </div>
    </div>
    <div class="bottommain">
      <div class="bottom-row1 fl">
        <div class="htjg1 htjg">安全</div>
        <div class="htjg1">生产</div>
        <div class="htjg1">报警</div>
        <div class="htjg1">参数</div>
        <div class="htjg1">设置</div>
      </div>
      <div class="bottommain-row2 fl">
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.T11EN,
                '均热段温度上限',
                'T11EN',
                infoList.T11EN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.T11EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          均热段温度上限
        </div>
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.T21EN,
                '三加热段温度上限',
                'T21EN',
                infoList.T21EN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.T21EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          三加热段温度上限
        </div>
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.T31EN,
                '二加热段温度上限',
                'T31EN',
                infoList.T31EN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.T31EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          二加热段温度上限
        </div>
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.T41EN,
                '一加热段温度上限',
                'T41EN',
                infoList.T41EN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.T41EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          一加热段温度上限
        </div>
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.T121EN,
                '均热空烟温度上限',
                'T121EN',
                infoList.T121EN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.T121EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          均热空烟温度上限
        </div>
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.T221EN,
                '三加空烟温度上限',
                'T221EN',
                infoList.T221EN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.T221EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          三加空烟温度上限
        </div>
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.T321EN,
                '二加空烟温度上限',
                'T321EN',
                infoList.T321EN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.T321EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          二加空烟温度上限
        </div>
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.T421EN,
                '一加空烟温度上限',
                'T421EN',
                infoList.T421EN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.T421EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          一加空烟温度上限
        </div>
      </div>
      <div class="bottommain-row3 fl">
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T11H,
              '均热段温度上限',
              'T11H',
              infoList.T11H_node_string
            )
          "
        >
          {{ "T11H" in infoList ? infoList.T11H : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T21H,
              '三加热段温度上限',
              'T21H',
              infoList.T21H_node_string
            )
          "
        >
          {{ "T21H" in infoList ? infoList.T21H : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T31H,
              '二加热段温度上限',
              'T31H',
              infoList.T31H_node_string
            )
          "
        >
          {{ "T31H" in infoList ? infoList.T31H : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T41H,
              '一加热段温度上限',
              'T41H',
              infoList.T41H_node_string
            )
          "
        >
          {{ "T41H" in infoList ? infoList.T41H : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T121H,
              '均热空烟温度上限',
              'T121H',
              infoList.T121H_node_string
            )
          "
        >
          {{ "T121H" in infoList ? infoList.T121H : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T221H,
              '三加空烟温度上限',
              'T221H',
              infoList.T221H_node_string
            )
          "
        >
          {{ "T221H" in infoList ? infoList.T221H : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T321H,
              '二加空烟温度上限',
              'T321H',
              infoList.T321H_node_string
            )
          "
        >
          {{ "T321H" in infoList ? infoList.T321H : 0 }}
        </div>
        <div
          class="topmain-row3-col11"
          @click="
            toIpt(
              infoList.T421H,
              '一加空烟温度上限',
              'T421H',
              infoList.T421H_node_string
            )
          "
        >
          {{ "T421H" in infoList ? infoList.T421H : 0 }}
        </div>
      </div>
      <div class="bottommain-row2 fl">
        <div class="topmain-row2-col1">均热段温度下限</div>
        <div class="topmain-row2-col1">三加热段温度下限</div>
        <div class="topmain-row2-col1">二加热段温度下限</div>
        <div class="topmain-row2-col1">一加热段温度下限</div>
        <div class="topmain-row2-col1">均热空烟温度下限</div>
        <div class="topmain-row2-col1">三加空烟温度下限</div>
        <div class="topmain-row2-col11">二加空烟温度下限</div>
        <div class="topmain-row2-col1">一加空烟温度下限</div>
      </div>
      <div class="bottommain-row3 fl">
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T11L,
              '均热段温度下限',
              'T11L',
              infoList.T11L_node_string
            )
          "
        >
          {{ "T11L" in infoList ? infoList.T11L : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T21L,
              '三加热段温度下限',
              'T21L',
              infoList.T21L_node_string
            )
          "
        >
          {{ "T21L" in infoList ? infoList.T21L : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T31L,
              '二加热段温度下限',
              'T31L',
              infoList.T31L_node_string
            )
          "
        >
          {{ "T31L" in infoList ? infoList.T31L : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T41L,
              '一加热段温度下限',
              'T41L',
              infoList.T41L_node_string
            )
          "
        >
          {{ "T41L" in infoList ? infoList.T41L : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T121L,
              '均热空烟温度下限',
              'T121L',
              infoList.T121L_node_string
            )
          "
        >
          {{ "T121L" in infoList ? infoList.T121L : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T221L,
              '三加空烟温度下限',
              'T221L',
              infoList.T221L_node_string
            )
          "
        >
          {{ "T221L" in infoList ? infoList.T221L : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T321L,
              '二加空烟温度下限',
              'T321L',
              infoList.T321L_node_string
            )
          "
        >
          {{ "T321L" in infoList ? infoList.T321L : 0 }}
        </div>
        <div
          class="topmain-row3-col11"
          @click="
            toIpt(
              infoList.T421L,
              '一加空烟温度下限',
              'T421L',
              infoList.T421L_node_string
            )
          "
        >
          {{ "T421L" in infoList ? infoList.T421L : 0 }}
        </div>
      </div>
      <div class="bottommain-row2 fl">
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.PMQEN,
                '煤气总管压力上限',
                'PMQEN',
                infoList.PMQEN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.PMQEN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          煤气总管压力上限
        </div>
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.PKQEN,
                '空气总管压力上限',
                'PKQEN',
                infoList.PKQEN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.PKQEN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          空气总管压力上限
        </div>
        <div class="topmain-row2-col1"></div>
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.YHZEN1,
                '优化站异常报警',
                'YHZEN1',
                infoList.YHZEN1_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.YHZEN1"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          优化站异常报警
        </div>
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.T122EN,
                '均热煤烟温度上限',
                'T122EN',
                infoList.T122EN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.T122EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          均热煤烟温度上限
        </div>
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.T222EN,
                '三加煤烟温度上限',
                'T222EN',
                infoList.T222EN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.T222EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          三加煤烟温度上限
        </div>
        <div class="topmain-row2-col11">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.T322EN,
                '二加煤烟温度上限',
                'T322EN',
                infoList.T322EN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.T322EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          二加煤烟温度上限
        </div>
        <div class="topmain-row2-col1">
          <div
            class="boxdiv fl"
            @click="
              toIpt(
                infoList.T422EN,
                '一加煤烟温度上限',
                'T422EN',
                infoList.T422EN_node_string
              )
            "
          >
            <el-switch
              v-model="infoList.T422EN"
              active-color="#29DFAE"
              inactive-color="red"
              disabled
            >
            </el-switch>
          </div>
          一加煤烟温度上限
        </div>
      </div>
      <div class="bottommain-row3 fl">
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.PMQH,
              '煤气总管压力上限',
              'PMQH',
              infoList.PMQH_node_string
            )
          "
        >
          {{ "PMQH" in infoList ? infoList.PMQH : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.PKQH,
              '空气总管压力上限',
              'PKQH',
              infoList.PKQH_node_string
            )
          "
        >
          {{ "PKQH" in infoList ? infoList.PKQH : 0 }}
        </div>

        <div class="topmain-row3-col1"></div>

        <div class="topmain-row3-col1"></div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T122H,
              '均热煤烟温度上限',
              'T122H',
              infoList.T122H_node_string
            )
          "
        >
          {{ "T122H" in infoList ? infoList.T122H : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T222H,
              '三加煤烟温度上限',
              'T222H',
              infoList.T222H_node_string
            )
          "
        >
          {{ "T222H" in infoList ? infoList.T222H : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T322H,
              '二加煤烟温度上限',
              'T322H',
              infoList.T322H_node_string
            )
          "
        >
          {{ "T322H" in infoList ? infoList.T322H : 0 }}
        </div>

        <div
          class="topmain-row3-col11"
          @click="
            toIpt(
              infoList.T422H,
              '一加煤烟温度上限',
              'T422H',
              infoList.T422H_node_string
            )
          "
        >
          {{ "T422H" in infoList ? infoList.T422H : 0 }}
        </div>
      </div>
      <div class="bottommain-row2 fl">
        <div class="topmain-row2-col1">煤气总管压力下限</div>
        <div class="topmain-row2-col1">空气总管压力下限</div>
        <div class="topmain-row2-col1"></div>
        <div class="topmain-row2-col1"></div>
        <div class="topmain-row2-col1">均热煤烟温度下限</div>
        <div class="topmain-row2-col1">三加煤烟温度下限</div>
        <div class="topmain-row2-col11">二加煤烟温度下限</div>
        <div class="topmain-row2-col1">一加煤烟温度下限</div>
      </div>
      <div class="bottommain-row3 fl">
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.PMQL,
              '煤气总管压力下限',
              'PMQL',
              infoList.PMQL_node_string
            )
          "
        >
          {{ "PMQL" in infoList ? infoList.PMQL : 0 }}
        </div>
        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.PKQL,
              '空气总管压力下限',
              'PKQL',
              infoList.PKQL_node_string
            )
          "
        >
          {{ "PKQL" in infoList ? infoList.PKQL : 0 }}
        </div>

        <div class="topmain-row3-col1"></div>

        <div class="topmain-row3-col1"></div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T122L,
              '均热煤烟温度下限',
              'T122L',
              infoList.T122L_node_string
            )
          "
        >
          {{ "T122L" in infoList ? infoList.T122L : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T222L,
              '三加煤烟温度下限',
              'T222L',
              infoList.T222L_node_string
            )
          "
        >
          {{ "T222L" in infoList ? infoList.T222L : 0 }}
        </div>

        <div
          class="topmain-row3-col1"
          @click="
            toIpt(
              infoList.T322L,
              '二加煤烟温度下限',
              'T322L',
              infoList.T322L_node_string
            )
          "
        >
          {{ "T322L" in infoList ? infoList.T322L : 0 }}
        </div>

        <div
          class="topmain-row3-col11"
          @click="
            toIpt(
              infoList.T422L,
              '一加煤烟温度下限',
              'T422L',
              infoList.T422L_node_string
            )
          "
        >
          {{ "T422L" in infoList ? infoList.T422L : 0 }}
        </div>
      </div>
    </div>

     <Historical
      v-if="isHshow"
      @sendStatus="isClose1"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
    <JRLcysz
      v-if="flag == 4 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLcysz>
  </div>
</template>
<script>
import Historical from "@/components/Historical";
import inputVal from "@/components/inputVal"; //输入框组件
import JRLcysz from "@/components/JRL_cysz.vue"; //常用设置组件
export default {
  name: "index",
  components: {
    Historical,
    inputVal,
    JRLcysz,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      headerStyle: {
        "font-weight": "500",
        "font-size": "1.8vh",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      flag: 0,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      config: {
        data: [10],
        shape: "round",
        colors: ["#43ff44", "#43ff44"],
        waveOpacity: "1",
        waveNum: "50",
        formatter: "{value}",
      },
      rightHeight: "",
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.$bus.$on("sendMsg", (msg, msg1) => {
      // 兄弟组件发送来的消息
      this.flag = msg;
    });
  },
  computed: {},
  mounted() {},
  methods: {
    DataJson(data) {
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        console.log(str.charAt(str.length - 1));
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无操作权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
    },
    closeCompon() {
      this.$emit("sendStatus", "Param01", false);
    },
    isClose() {
      (this.flag = 2), this.$bus.$emit("footersendMsg", 2);
    },
    isClose1() {
      this.isHshow = false;
    },
    toCompon(key, name, name2, name3, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      switch (key) {
        case 0:
          return (
            (this.isMshow = true),
            (this.Manualname = name),
            (this.Manualnode = name2),
            (this.ManualAname = name3),
            (this.Manualtitname = titname)
          );
        case 1:
          return (
            (this.isFshow = true),
            (this.Firstcontrolname = name),
            (this.Firstcontrolnode = name2),
            (this.Firstcontroltitname = titname)
          );
        case 2:
          return (this.isHshow = true);
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.jrlsecurty {
  width: 96vw;
  height: auto;
  margin-left: 2vw;
  //  background: #e9f7ff;
  background-image: linear-gradient(#0e3449 60%, #0e3146 61%, #0c2a38 100%),
    linear-gradient(#0e3449, #0e3449);
  background-blend-mode: normal, normal;
  overflow: hidden;
  //  cursor:move;
  border-radius: 5px;
  .ziti {
    font-size: 0.9vw;
  }
  .zhuangshi1 {
    width: 30vw;
    height: 10vh;
    background-color: #0ef7ff;
    opacity: 0.66;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .title-hang {
    height: 5vh;
    width: 96vw;
    font-family: MicrosoftYaHei;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
  }
  .conter1 {
    margin-left: 1vw;
    width: 17vw;
    height: 29vh;
    line-height: 29vh;
    background-image: url("~@/assets/images/rfl_evaluate01.png");
    background-size: 100% 100%;
  }
  .conter {
    height: 29vh;
    width: 100%;
    margin-top: 3vh;
  }
  .conter1 {
    margin-left: 1vw;
    width: 17vw;
    height: 29vh;
    line-height: 29vh;
    background-image: url("~@/assets/images/rfl_evaluate01.png");
    background-size: 100% 100%;
  }
  .conter2 {
    margin-left: 1vw;
    width: 29vw;
    height: 29vh;
    // line-height: 29vh;
    background-image: url("~@/assets/images/rfl_evaluate03.png");
    background-size: 100% 100%;
  }
  .title1 {
    width: 8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #ffffff;
    margin-top: 1vh;
    margin-left: 1vw;
  }
  .divcei {
    margin-top: 3vh;
    margin-left: 4vw;
    width: 9vw;
    height: 20vh;
    line-height: 20vh;
    background-image: url("~@/assets/images/rfl_evaluate02.png");
    background-size: 100% 100%;
    font-size: 2vw;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0vw;
    color: #ffffff;
    text-align: center;
  }
  .divjjx {
    margin-top: 3vh;
    margin-left: 4vw;
    width: 9vw;
    height: 26vh;
    line-height: 26vh;
    font-size: 1.6vw;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0vw;
    color: #ffffff;
    text-align: center;
  }
  .zhuangshizb {
    width: 14vw;
    height: 3vh;
    background-image: url("~@/assets/images/rfl_evaluate06.png");
    background-size: 100% 100%;
    margin-left: 3.5vw;
    margin-top: 1.6vh;
  }
  .zhuangshizj {
    width: 68vw;
    height: 5vh;
    line-height: 5vh;
    margin-top: 1vh;
    text-align: center;
    // background-image: url("~@/assets/images/rfl_evaluate05.png");
    background-size: 100% 100%;
    // margin-left: 1.5vw;
    font-family: MicrosoftYaHei;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0vh;
    color: #0ef7ff;
  }
  .zhuangshiyb {
    width: 14vw;
    height: 3vh;
    background-image: url("~@/assets/images/rfl_evaluate04.png");
    background-size: 100% 100%;
    margin-left: 2vw;
    margin-top: 1.6vh;
  }
  .title-hang {
    width: 100%;
  }
  .mainall {
    margin-top: 3vh;
  }
  .topmain {
    width: 94.4vw;
    height: 44vh;
    margin-top: 2vh;
    border: 1px #18394d solid;
  }
  .bottommain {
    width: 94.4vw;
    height: 32vh;
    margin-top: 1vh;
    border: 1px #18394d solid;
  }
  .htjg {
    height: 6vh;
  }
  .topmain-row1 {
    width: 5vw;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #fff;
    font-family: PingFang-SC-Regular;
    text-align: center;
    // line-height: 44vh;
  }
  .bottom-row1 {
    width: 5vw;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #fff;
    font-family: PingFang-SC-Regular;
    text-align: center;
    // line-height: 44vh;
  }
  .topmain-row2 {
    width: 15vw;
    font-size: 1vw;
    height: 44vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #70c2d6;
    font-family: PingFang-SC-Regular;
    text-align: center;
    // line-height: 44vh;
    border-left: 1px #18394d solid;
  }
  .bottommain-row2 {
    width: 15vw;
    font-size: 1vw;
    height: 32vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #70c2d6;
    font-family: PingFang-SC-Regular;
    text-align: center;
    // line-height: 44vh;
    border-left: 1px #18394d solid;
  }
  .htjg {
    margin-top: 3vh;
  }
  .htjg1 {
    height: 5.5vh;
    line-height: 5.5vh;
  }
  .topmain-row2-col1 {
    width: 15vw;
    height: 3.89vh;
    line-height: 3.89vh;
    border-bottom: 1px #18394d solid;
  }
  .topmain-row2-col11 {
    width: 15vw;
    height: 3.89vh;
    line-height: 3.89vh;
    //  border-bottom:1px #18394d solid
  }
  .topmain-row3 {
    width: 7.2vw;
    font-size: 1vw;
    height: 44vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #fff;
    font-family: PingFang-SC-Regular;
    text-align: center;
    // line-height: 44vh;
    border-left: 1px #18394d solid;
  }
  .bottommain-row3 {
    width: 7.2vw;
    font-size: 1vw;
    height: 32vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #fff;
    font-family: PingFang-SC-Regular;
    text-align: center;
    // line-height: 44vh;
    border-left: 1px #18394d solid;
  }
  .topmain-row3-col1 {
    width: 7.2vw;
    height: 3.89vh;
    line-height: 3.89vh;
    border-bottom: 1px #18394d solid;
    cursor: pointer;
  }
  .topmain-row3-col11 {
    width: 7.2vw;
    height: 3.89vh;
    line-height: 3.89vh;
    cursor: pointer;
    //  border-bottom:1px #18394d solid
  }
  .boxdiv {
    width: 1vw;
    height: 1vh;
    margin-left: 1vw;
  }
}
::v-deep {
  .el-switch.is-disabled .el-switch__core {
    cursor: pointer !important;
  }
  .el-switch.is-disabled {
    opacity: 1 !important;
  }
}
</style>