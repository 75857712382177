var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"jrlmyzg drag",attrs:{"id":"jrlmyzg"}},[_c('div',[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(" "+_vm._s(_vm.projectData.project)+"_参数04 ")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:" fl"},[_c('div',{staticClass:"lable"},[_vm._v("均热段煤烟控制模型")]),_c('div',{staticClass:"leftrow "},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.JRDMPTSP,
                    'A01-TSP',
                     'JRDMPTSP',
                    'G_TEMP'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.JRDMPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'T12PV2', 'G_TEMP', 'T12PV2_J1','均热段煤烟温度处理值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_TEMP.T12PV2,'T12PV2', 'G_TEMP', 'T12PV2_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T12PV2)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.T12SEL1,
                    '测量方式',
                     'T12SEL1',
                    'G_TEMP'
                   
                  )}}},[_vm._v(_vm._s(_vm.infoList.G_TEMP.T12SEL1?'段最大值':'单一温度'))])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__QK10', 'QK10_AV_J1','均热段煤烟煤气阀门前馈')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__QK10.AV,'AV', 'G_MCSOTHER__p__QK10', 'QK10_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__QK10.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSXK__p__XK2 &&
                !_vm.infoList.G_MCSXK__p__XK2.XK2
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK2 ? _vm.toDetail(1,'XK2','G_MCSXK__p__XK2','','均热段煤烟温度控制XK' ): ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"texlable",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_PFJ.FYSP_L,
                    'FYSP_L',
                     'FYSP_L',
                    'G_PFJ'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_PFJ.FYSP_L)+" Pa ")]),_c('div',{staticClass:"bottonx mar-jg3 ",style:({
                  background: !_vm.infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__RSF3 ? _vm.toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''}}},[_vm._v(" R")]),_c('div',{staticClass:"bottonx  mar-jg1",style:({
              background:
                _vm.infoList.G_MCSOTHER__p__QK10 &&
                !_vm.infoList.G_MCSOTHER__p__QK10.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK10 ? _vm.toDetail(2,'QK10','G_MCSOTHER__p__QK10','','均热段煤烟煤气阀门前馈' ): ''}}},[_vm._v("K ")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSMAN__p__MAN4 &&
                _vm.infoList.G_MCSMAN__p__MAN4.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN4
                ? _vm.toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN4',
                    'MAN4_RM_J1',
                    '均热段煤烟气阀门手操器'
                  )
                : ''}}},[_vm._v("A")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSXK__p__XK2', 'XK2_AV_J1','均热段煤烟温度控制XK')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSXK__p__XK2.AV,'AV', 'G_MCSXK__p__XK2', 'XK2_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSXK__p__XK2.AV)+" ")])])])])]),_c('div',{staticClass:" fl"},[_c('div',{staticClass:"lable"},[_vm._v("均热段空烟控制模型")]),_c('div',{staticClass:"leftrow "},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.JRDKPTSP,
                    'A01-TSP',
                     'JRDKPTSP',
                    'G_TEMP'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.JRDKPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'T12PV1', 'G_TEMP', 'T12PV1_J1','均热段空烟温度处理值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_TEMP.T12PV1,'T12PV1', 'G_TEMP', 'T12PV1_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T12PV1)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.T12SEL1,
                    '测量方式',
                     'T12SEL1',
                    'G_TEMP'
                   
                  )}}},[_vm._v(_vm._s(_vm.infoList.G_TEMP.T12SEL1?'段最大值':'单一温度'))])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__QK9', 'QK9_AV_J1','均热段空烟空气阀门前馈')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__QK9.AV,'AV', 'G_MCSOTHER__p__QK9', 'QK9_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__QK9.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSXK__p__XK3 &&
                !_vm.infoList.G_MCSXK__p__XK3.XK3
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK3 ? _vm.toDetail(1,'XK3','G_MCSXK__p__XK3','','均热段空烟温度控制XK' ): ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"texlable",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_PFJ.FYSP_L,
                    'FYSP_L',
                    'FYSP_L',
                    'G_PFJ'
                    
                  )}}}),_c('div',{staticClass:"bottonx  mar-jg33",style:({
                  background: !_vm.infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__RSF3 ? _vm.toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''}}},[_vm._v(" R")]),_c('div',{staticClass:"bottonx mar-jg1",style:({
              background:
                _vm.infoList.G_MCSOTHER__p__QK9 &&
                !_vm.infoList.G_MCSOTHER__p__QK9.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK9 ? _vm.toDetail(2,'QK9','G_MCSOTHER__p__QK9','','均热段空烟空气阀门前馈' ): ''}}},[_vm._v("K ")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSMAN__p__MAN3 &&
                _vm.infoList.G_MCSMAN__p__MAN3.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN3
                ? _vm.toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN3',
                    'MAN3_RM_J1',
                    '均热段空烟气阀门手操器'
                  )
                : ''}}},[_vm._v("A")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSXK__p__XK3', 'XK3_AV_J1','均热段空烟温度控制XK')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSXK__p__XK3.AV,'AV', 'G_MCSXK__p__XK3', 'XK3_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSXK__p__XK3.AV)+" ")])])])])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:" fl"},[_c('div',{staticClass:"lable"},[_vm._v("三加热段煤烟控制模型")]),_c('div',{staticClass:"leftrow "},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.SJRMPTSP,
                    'A01-TSP',
                    'SJRMPTSP',
                    'G_TEMP'
                    
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.SJRMPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'T22PV2', 'G_TEMP', 'T22PV2_J1','三加热段煤烟温度处理值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_TEMP.T22PV2,'AV', 'T22PV2', 'G_TEMP', 'T22PV2_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T22PV2)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.T22SEL1,
                    '测量方式',
                     'T22SEL1',
                    'G_TEMP'
                   
                  )}}},[_vm._v(_vm._s(_vm.infoList.G_TEMP.T22SEL1?'段最大值':'单一温度'))])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__QK12', 'QK12_AV_J1','三加热煤烟煤气阀门前馈')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__QK12.AV,'AV', 'G_MCSOTHER__p__QK12', 'QK12_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__QK12.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSXK__p__XK5 &&
                !_vm.infoList.G_MCSXK__p__XK5.XK5
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK5 ? _vm.toDetail(1,'XK5','G_MCSXK__p__XK5','','三加热段煤烟温度控制XK' ): ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx mar-jg33 ",style:({
                  background: !_vm.infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__RSF3 ? _vm.toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''}}},[_vm._v(" R")]),_c('div',{staticClass:"bottonx  mar-jg1",style:({
              background:
                _vm.infoList.G_MCSOTHER__p__QK12 &&
                !_vm.infoList.G_MCSOTHER__p__QK12.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK12 ? _vm.toDetail(2,'QK12','G_MCSOTHER__p__QK12','','三加热煤烟煤气阀门前馈' ): ''}}},[_vm._v("K ")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSMAN__p__MAN8 &&
                _vm.infoList.G_MCSMAN__p__MAN8.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN8
                ? _vm.toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN8',
                    'MAN8_RM_J1',
                    '三热段煤烟气阀门手操器'
                  )
                : ''}}},[_vm._v("A")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSXK__p__XK5', 'XK5_AV_J1','三加热段煤烟温度控制XK')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSXK__p__XK5.AV,'AV', 'AV', 'G_MCSXK__p__XK5', 'XK5_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSXK__p__XK5.AV)+" ")])])])])]),_c('div',{staticClass:" fl"},[_c('div',{staticClass:"lable"},[_vm._v("三加热段空烟控制模型")]),_c('div',{staticClass:"leftrow "},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.SJRKPTSP,
                    'A01-TSP',
                    'SJRKPTSP',
                    'G_TEMP'
                    
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.SJRKPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'T22PV1', 'G_TEMP', 'T22PV1_J1','三加热段空烟温度处理值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_TEMP.T22PV1,'AV', 'T22PV1', 'G_TEMP', 'T22PV1_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T22PV1)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.T22SEL1,
                    '测量方式',
                     'T22SEL1',
                    'G_TEMP'
                   
                  )}}},[_vm._v(_vm._s(_vm.infoList.G_TEMP.T22SEL1?'段最大值':'单一温度'))])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__QK11', 'QK11_AV_J1','三加热空烟空气阀门前馈')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__QK11.AV,'AV', 'G_MCSOTHER__p__QK11', 'QK11_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__QK11.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSXK__p__XK6 &&
                !_vm.infoList.G_MCSXK__p__XK6.XK6
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK6 ? _vm.toDetail(1,'XK6','G_MCSXK__p__XK6','','三加热段空烟温度控制XK' ): ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx  mar-jg33",style:({
                  background: !_vm.infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__RSF3 ? _vm.toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''}}},[_vm._v(" R")]),_c('div',{staticClass:"bottonx mar-jg1",style:({
              background:
                _vm.infoList.G_MCSOTHER__p__QK11 &&
                !_vm.infoList.G_MCSOTHER__p__QK11.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK11 ? _vm.toDetail(2,'QK11','G_MCSOTHER__p__QK11','','三加热段空烟空气阀门前馈' ): ''}}},[_vm._v(" K")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSMAN__p__MAN7 &&
                _vm.infoList.G_MCSMAN__p__MAN7.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN7
                ? _vm.toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN7',
                    'MAN7_RM_J1',
                    '三热段空烟气阀门手操器'
                  )
                : ''}}},[_vm._v("A")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSXK__p__XK6', 'XK6_AV_J1','三加热段空烟温度控制XK')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSXK__p__XK6.AV,'AV', 'AV', 'G_MCSXK__p__XK6', 'XK6_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSXK__p__XK6.AV)+" ")])])])])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:" fl"},[_c('div',{staticClass:"lable"},[_vm._v("二加热段煤烟控制模型")]),_c('div',{staticClass:"leftrow "},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.EJRMPTSP,
                    'A01-TSP',
                     'EJRMPTSP',
                    'G_TEMP'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.EJRMPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'T32PV2', 'G_TEMP', 'T32PV2_J1','二加热段煤烟温度处理值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_TEMP.T32PV2,'AV', 'T32PV2', 'G_TEMP', 'T32PV2_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T32PV2)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.T32SEL1,
                    '测量方式',
                     'T32SEL1',
                    'G_TEMP'
                   
                  )}}},[_vm._v(_vm._s(_vm.infoList.G_TEMP.T32SEL1?'段最大值':'单一温度'))])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__QK14', 'QK14_AV_J1','二加热煤烟煤气阀门前馈')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__QK14.AV,'AV', 'G_MCSOTHER__p__QK14', 'QK14_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__QK14.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSXK__p__XK8 &&
                !_vm.infoList.G_MCSXK__p__XK8.XK8
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK8 ? _vm.toDetail(1,'XK8','G_MCSXK__p__XK8','','二加热段煤烟温度控制XK' ): ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx mar-jg33 ",style:({
                  background: !_vm.infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__RSF3 ? _vm.toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''}}},[_vm._v(" R")]),_c('div',{staticClass:"bottonx  mar-jg1",style:({
              background:
                _vm.infoList.G_MCSOTHER__p__QK14 &&
                !_vm.infoList.G_MCSOTHER__p__QK14.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK14 ? _vm.toDetail(2,'QK14','G_MCSOTHER__p__QK14','','二加热煤烟煤气阀门前馈' ): ''}}},[_vm._v("K ")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSMAN__p__MAN12 &&
                _vm.infoList.G_MCSMAN__p__MAN12.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN12
                ? _vm.toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN12',
                    'MAN12_RM_J1',
                    '二热段煤烟气阀门手操器'
                  )
                : ''}}},[_vm._v("A")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSXK__p__XK8', 'XK8_AV_J1','二加热段煤烟温度控制XK')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSXK__p__XK8.AV,'AV', 'G_MCSXK__p__XK8', 'XK8_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSXK__p__XK8.AV)+" ")])])])])]),_c('div',{staticClass:" fl"},[_c('div',{staticClass:"lable"},[_vm._v("二加热段空烟控制模型")]),_c('div',{staticClass:"leftrow "},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.EJRKPTSP,
                    'A01-TSP',
                     'EJRKPTSP',
                    'G_TEMP'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.EJRKPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'T32PV1', 'G_TEMP', 'T32PV1_J1','二加热段空烟温度处理值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_TEMP.T32PV1,'T32PV1', 'G_TEMP', 'T32PV1_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T32PV1)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.T32SEL1,
                    '测量方式',
                     'T32SEL1',
                    'G_TEMP'
                   
                  )}}},[_vm._v(_vm._s(_vm.infoList.G_TEMP.T32SEL1?'段最大值':'单一温度'))])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__QK13', 'QK13_AV_J1','二加热空烟空气阀门前馈')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__QK13.AV,'AV', 'G_MCSOTHER__p__QK13', 'QK13_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__QK13.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSXK__p__XK9 &&
                !_vm.infoList.G_MCSXK__p__XK9.XK9
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK9 ? _vm.toDetail(1,'XK9','G_MCSXK__p__XK9','','二加热段空烟温度控制XK' ): ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx  mar-jg33",style:({
                  background: !_vm.infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__RSF3 ? _vm.toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''}}},[_vm._v(" R")]),_c('div',{staticClass:"bottonx mar-jg1",style:({
              background:
                _vm.infoList.G_MCSOTHER__p__QK13 &&
                !_vm.infoList.G_MCSOTHER__p__QK13.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK13 ? _vm.toDetail(2,'QK13','G_MCSOTHER__p__QK13','','二加热段空烟空气阀门前馈' ): ''}}},[_vm._v(" K")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSMAN__p__MAN11 &&
                _vm.infoList.G_MCSMAN__p__MAN11.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN11
                ? _vm.toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN11',
                    'MAN11_RM_J1',
                    '二热段空烟气阀门手操器'
                  )
                : ''}}},[_vm._v("A")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSXK__p__XK9', 'XK9_AV_J1','二加热段空烟温度控制XK')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSXK__p__XK9.AV,'AV', 'G_MCSXK__p__XK9', 'XK9_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSXK__p__XK9.AV)+" ")])])])])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:" fl"},[_c('div',{staticClass:"lable"},[_vm._v("一加热段煤烟控制模型")]),_c('div',{staticClass:"leftrow "},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.YJRMPTSP,
                    'A01-TSP',
                     'YJRMPTSP',
                    'G_TEMP'
                   
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.YJRMPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'T42PV2', 'G_TEMP', 'T42PV2_J1','一加热段煤烟温度处理值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_TEMP.T42PV2,'T42PV2', 'G_TEMP', 'T42PV2_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T42PV2)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.T42SEL1,
                    '测量方式',
                     'T42SEL1',
                    'G_TEMP'
                   
                  )}}},[_vm._v(_vm._s(_vm.infoList.G_TEMP.T42SEL1?'段最大值':'单一温度'))])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__RSF3.AV,'AV','G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__QK16', 'QK16_AV_J1','一加热煤烟煤气阀门前馈')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__QK16.AV,'AV', 'AV', 'G_MCSOTHER__p__QK16', 'QK16_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__QK16.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSXK__p__XK11 &&
                !_vm.infoList.G_MCSXK__p__XK11.XK11
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK11 ? _vm.toDetail(1,'XK11','G_MCSXK__p__XK11','','一加热段煤烟温度控制XK' ): ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx mar-jg33 ",style:({
                  background: !_vm.infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__RSF3 ? _vm.toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''}}},[_vm._v(" R")]),_c('div',{staticClass:"bottonx  mar-jg1",style:({
              background:
                _vm.infoList.G_MCSOTHER__p__QK16 &&
                !_vm.infoList.G_MCSOTHER__p__QK16.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK16 ? _vm.toDetail(2,'QK16','G_MCSOTHER__p__QK16','','一加热煤烟煤气阀门前馈' ): ''}}},[_vm._v("K ")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSMAN__p__MAN23 &&
                _vm.infoList.G_MCSMAN__p__MAN23.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN23
                ? _vm.toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN23',
                    'MAN23_RM_J1',
                    '一热段煤烟气阀门手操器'
                  )
                : ''}}},[_vm._v("A")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSXK__p__XK11', 'XK11_AV_J1','一加热段煤烟温度控制XK')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSXK__p__XK11.AV,'AV', 'G_MCSXK__p__XK11', 'XK11_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSXK__p__XK11.AV)+" ")])])])])]),_c('div',{staticClass:" fl"},[_c('div',{staticClass:"lable"},[_vm._v("一加热段空烟控制模型")]),_c('div',{staticClass:"leftrow "},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.YJRKPTSP,
                    'A01-TSP',
                    'YJRKPTSP',
                    'G_TEMP'
                    
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.YJRKPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'T42PV1', 'G_TEMP', 'T42PV1_J1','一加热空烟温度处理')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_TEMP.T42PV1,'AV', 'T42PV1', 'G_TEMP', 'T42PV1_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_TEMP.T42PV1)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.G_TEMP.T42SEL1,
                    '测量方式',
                     'T42SEL1',
                    'G_TEMP'
                   
                  )}}},[_vm._v(_vm._s(_vm.infoList.G_TEMP.T42SEL1?'段最大值':'单一温度'))])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSOTHER__p__QK15', 'QK15_AV_J1','一加热空烟空气阀门前馈')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSOTHER__p__QK15.AV,'AV', 'G_MCSOTHER__p__QK15', 'QK15_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSOTHER__p__QK15.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSXK__p__XK12 &&
                !_vm.infoList.G_MCSXK__p__XK12.XK12
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSXK__p__XK12 ? _vm.toDetail(1,'XK12','G_MCSXK__p__XK12','','一加热段空烟温度控制XK' ): ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx  mar-jg33",style:({
                  background: !_vm.infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__RSF3 ? _vm.toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''}}},[_vm._v(" R")]),_c('div',{staticClass:"bottonx mar-jg1",style:({
              background:
                _vm.infoList.G_MCSOTHER__p__QK15 &&
                !_vm.infoList.G_MCSOTHER__p__QK15.TS
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSOTHER__p__QK15 ? _vm.toDetail(2,'QK15','G_MCSOTHER__p__QK15','','一加热段空烟空气阀门前馈' ): ''}}},[_vm._v(" K")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx ",style:({
              background:
                _vm.infoList.G_MCSMAN__p__MAN22 &&
                _vm.infoList.G_MCSMAN__p__MAN22.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }),on:{"click":function($event){_vm.infoList.G_MCSMAN__p__MAN22
                ? _vm.toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN22',
                    'MAN22_RM_J1',
                    '一热段空烟气阀门手操器'
                  )
                : ''}}},[_vm._v("A")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(2, 'AV', 'G_MCSXK__p__XK12', 'XK12_AV_J1','一加热段空烟温度控制XK')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.G_MCSXK__p__XK12.AV,'AV', 'G_MCSXK__p__XK12', 'XK12_AV_J1')}}},[_vm._v(" "+_vm._s(_vm.infoList.G_MCSXK__p__XK12.AV)+" ")])])])])])]),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e(),_c('inputVal',{ref:"inputVal",on:{"getTreeData":function($event){return _vm.DataJson(arguments)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }