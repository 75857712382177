<template>
	<div class="jrlmyzg drag" id="jrlmyzg" v-draw>
		<!-- 标题部分 -->
		<div>
			<div class="title-hang" fl>
				{{ projectData.project }}_参数04
			</div>
			<div class="param11-header-icon" fr @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>

		<div class="main">
			<div class=" fl">
				<div class="lable">均热段煤烟控制模型</div>
				<div class="leftrow ">
					<div class="fl marleft">
						<div class="col1">
							<div class="fl color1">A01-TSP</div>
							<div class="fl color3" @click="
                  toIpt(
                    infoList.G_TEMP.JRDMPTSP,
                    'A01-TSP',
                     'JRDMPTSP',
                    'G_TEMP',
                   
                  )
                ">
								{{ infoList.G_TEMP.JRDMPTSP}}
							</div>
						</div>
						<div class="col2">
							<div class="fl color1">A01-TPV</div>
							<div class="fl color2" @click="toCompon(2, 'T12PV2', 'G_TEMP', 'T12PV2_J1','均热段煤烟温度处理值')"
								@dblclick="Cclick(infoList.G_TEMP.T12PV2,'T12PV2', 'G_TEMP', 'T12PV2_J1')">
								{{infoList.G_TEMP.T12PV2}}
							</div>
						</div>
						<div class="col3" @click="
                  toIpt(
                    infoList.G_TEMP.T12SEL1,
                    '测量方式',
                     'T12SEL1',
                    'G_TEMP',
                   
                  )
                ">{{infoList.G_TEMP.T12SEL1?'段最大值':'单一温度'}}</div>
					</div>
					<div class="fl xcent">

						<div class="fl">
							<div class="col2 martop">
								<div class="fl color1">A02-RSF</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')">
									{{infoList.G_MCSOTHER__p__RSF3.AV}}
								</div>
							</div>
							<div class="col2 martop1">
								<div class="fl color1">A03-QK</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__QK10', 'QK10_AV_J1','均热段煤烟煤气阀门前馈')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__QK10.AV,'AV', 'G_MCSOTHER__p__QK10', 'QK10_AV_J1')">
									{{infoList.G_MCSOTHER__p__QK10.AV}}
								</div>
							</div>
						</div>
						<div class="fl mar-jg">
							<div class="bottonx " :style="{
              background:
                infoList.G_MCSXK__p__XK2 &&
                !infoList.G_MCSXK__p__XK2.XK2
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSXK__p__XK2 ? toDetail(1,'XK2','G_MCSXK__p__XK2','','均热段煤烟温度控制XK' ): ''">
								X
							</div>
							<div class="texlable" @click="
                  toIpt(
                    infoList.G_PFJ.FYSP_L,
                    'FYSP_L',
                     'FYSP_L',
                    'G_PFJ',
                   
                  )
                ">
								{{ infoList.G_PFJ.FYSP_L}}&nbsp;Pa
							</div>
							<div class="bottonx mar-jg3 " :style="{
                  background: !infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList.G_MCSOTHER__p__RSF3 ? toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''">
								R</div>
							<div class="bottonx  mar-jg1" :style="{
              background:
                infoList.G_MCSOTHER__p__QK10 &&
                !infoList.G_MCSOTHER__p__QK10.TS
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSOTHER__p__QK10 ? toDetail(2,'QK10','G_MCSOTHER__p__QK10','','均热段煤烟煤气阀门前馈' ): ''">K
							</div>

						</div>
						<div class="fl"></div>

					</div>
					<div class="fl">
						<div class="bottonx " :style="{
              background:
                infoList.G_MCSMAN__p__MAN4 &&
                infoList.G_MCSMAN__p__MAN4.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }" @click="
              infoList.G_MCSMAN__p__MAN4
                ? toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN4',
                    'MAN4_RM_J1',
                    '均热段煤烟气阀门手操器'
                  )
                : ''
            ">A</div>
						<div class="col11 mar-left">
							<div class="fl color11">A01-AV</div>
							<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSXK__p__XK2', 'XK2_AV_J1','均热段煤烟温度控制XK')"
								@dblclick="Cclick(infoList.G_MCSXK__p__XK2.AV,'AV', 'G_MCSXK__p__XK2', 'XK2_AV_J1')">
								{{infoList.G_MCSXK__p__XK2.AV}}
							</div>
						</div>

					</div>
				</div>
			</div>
			<div class=" fl">
				<div class="lable">均热段空烟控制模型</div>
				<div class="leftrow ">
					<div class="fl marleft">
						<div class="col1">
							<div class="fl color1">A01-TSP</div>
							<div class="fl color3" @click="
                  toIpt(
                    infoList.G_TEMP.JRDKPTSP,
                    'A01-TSP',
                     'JRDKPTSP',
                    'G_TEMP',
                   
                  )
                ">
								{{ infoList.G_TEMP.JRDKPTSP}}
							</div>
						</div>
						<div class="col2">
							<div class="fl color1">A01-TPV</div>
							<div class="fl color2" @click="toCompon(2, 'T12PV1', 'G_TEMP', 'T12PV1_J1','均热段空烟温度处理值')"
								@dblclick="Cclick(infoList.G_TEMP.T12PV1,'T12PV1', 'G_TEMP', 'T12PV1_J1')">
								{{infoList.G_TEMP.T12PV1}}
							</div>
						</div>
						<div class="col3" @click="
                  toIpt(
                    infoList.G_TEMP.T12SEL1,
                    '测量方式',
                     'T12SEL1',
                    'G_TEMP',
                   
                  )
                ">{{infoList.G_TEMP.T12SEL1?'段最大值':'单一温度'}}</div>
					</div>
					<div class="fl xcent">

						<div class="fl">
							<div class="col2 martop">
								<div class="fl color1">A02-RSF</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')">
									{{infoList.G_MCSOTHER__p__RSF3.AV}}
								</div>
							</div>
							<div class="col2 martop1">
								<div class="fl color1">A03-QK</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__QK9', 'QK9_AV_J1','均热段空烟空气阀门前馈')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__QK9.AV,'AV', 'G_MCSOTHER__p__QK9', 'QK9_AV_J1')">
									{{infoList.G_MCSOTHER__p__QK9.AV}}
								</div>
							</div>
						</div>
						<div class="fl mar-jg">
							<div class="bottonx " :style="{
              background:
                infoList.G_MCSXK__p__XK3 &&
                !infoList.G_MCSXK__p__XK3.XK3
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSXK__p__XK3 ? toDetail(1,'XK3','G_MCSXK__p__XK3','','均热段空烟温度控制XK' ): ''">
								X
							</div>
							<div class="texlable" @click="
                  toIpt(
                    infoList.G_PFJ.FYSP_L,
                    'FYSP_L',
                    'FYSP_L',
                    'G_PFJ',
                    
                  )
                ">
							</div>
							<div class="bottonx  mar-jg33" :style="{
                  background: !infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList.G_MCSOTHER__p__RSF3 ? toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''">
								R</div>
							<div class="bottonx mar-jg1" :style="{
              background:
                infoList.G_MCSOTHER__p__QK9 &&
                !infoList.G_MCSOTHER__p__QK9.TS
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSOTHER__p__QK9 ? toDetail(2,'QK9','G_MCSOTHER__p__QK9','','均热段空烟空气阀门前馈' ): ''">K
							</div>

						</div>
						<div class="fl"></div>

					</div>
					<div class="fl">
						<div class="bottonx " :style="{
              background:
                infoList.G_MCSMAN__p__MAN3 &&
                infoList.G_MCSMAN__p__MAN3.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }" @click="
              infoList.G_MCSMAN__p__MAN3
                ? toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN3',
                    'MAN3_RM_J1',
                    '均热段空烟气阀门手操器'
                  )
                : ''
            ">A</div>
						<div class="col11 mar-left">
							<div class="fl color11">A01-AV</div>
							<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSXK__p__XK3', 'XK3_AV_J1','均热段空烟温度控制XK')"
								@dblclick="Cclick(infoList.G_MCSXK__p__XK3.AV,'AV', 'G_MCSXK__p__XK3', 'XK3_AV_J1')">
								{{infoList.G_MCSXK__p__XK3.AV}}
							</div>
						</div>

					</div>
				</div>
			</div>


		</div>

		<div class="main">
			<div class=" fl">
				<div class="lable">三加热段煤烟控制模型</div>
				<div class="leftrow ">
					<div class="fl marleft">
						<div class="col1">
							<div class="fl color1">A01-TSP</div>
							<div class="fl color3" @click="
                  toIpt(
                    infoList.G_TEMP.SJRMPTSP,
                    'A01-TSP',
                    'SJRMPTSP',
                    'G_TEMP',
                    
                  )
                ">
								{{ infoList.G_TEMP.SJRMPTSP}}
							</div>
						</div>
						<div class="col2">
							<div class="fl color1">A01-TPV</div>
							<div class="fl color2" @click="toCompon(2, 'T22PV2', 'G_TEMP', 'T22PV2_J1','三加热段煤烟温度处理值')"
								@dblclick="Cclick(infoList.G_TEMP.T22PV2,'AV', 'T22PV2', 'G_TEMP', 'T22PV2_J1')">
								{{infoList.G_TEMP.T22PV2}}
							</div>
						</div>
						<div class="col3" @click="
                  toIpt(
                    infoList.G_TEMP.T22SEL1,
                    '测量方式',
                     'T22SEL1',
                    'G_TEMP',
                   
                  )
                ">{{infoList.G_TEMP.T22SEL1?'段最大值':'单一温度'}}</div>
					</div>
					<div class="fl xcent">

						<div class="fl">
							<div class="col2 martop">
								<div class="fl color1">A02-RSF</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')">
									{{infoList.G_MCSOTHER__p__RSF3.AV}}
								</div>
							</div>
							<div class="col2 martop1">
								<div class="fl color1">A03-QK</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__QK12', 'QK12_AV_J1','三加热煤烟煤气阀门前馈')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__QK12.AV,'AV', 'G_MCSOTHER__p__QK12', 'QK12_AV_J1')">
									{{infoList.G_MCSOTHER__p__QK12.AV}}
								</div>
							</div>
						</div>
						<div class="fl mar-jg">
							<div class="bottonx " :style="{
              background:
                infoList.G_MCSXK__p__XK5 &&
                !infoList.G_MCSXK__p__XK5.XK5
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSXK__p__XK5 ? toDetail(1,'XK5','G_MCSXK__p__XK5','','三加热段煤烟温度控制XK' ): ''">
								X
							</div>


							<div class="bottonx mar-jg33 " :style="{
                  background: !infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList.G_MCSOTHER__p__RSF3 ? toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''">
								R</div>
							<div class="bottonx  mar-jg1" :style="{
              background:
                infoList.G_MCSOTHER__p__QK12 &&
                !infoList.G_MCSOTHER__p__QK12.TS
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSOTHER__p__QK12 ? toDetail(2,'QK12','G_MCSOTHER__p__QK12','','三加热煤烟煤气阀门前馈' ): ''">K
							</div>

						</div>
						<div class="fl"></div>

					</div>
					<div class="fl">
						<div class="bottonx " :style="{
              background:
                infoList.G_MCSMAN__p__MAN8 &&
                infoList.G_MCSMAN__p__MAN8.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }" @click="
              infoList.G_MCSMAN__p__MAN8
                ? toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN8',
                    'MAN8_RM_J1',
                    '三热段煤烟气阀门手操器'
                  )
                : ''
            ">A</div>
						<div class="col11 mar-left">
							<div class="fl color11">A01-AV</div>
							<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSXK__p__XK5', 'XK5_AV_J1','三加热段煤烟温度控制XK')"
								@dblclick="Cclick(infoList.G_MCSXK__p__XK5.AV,'AV', 'AV', 'G_MCSXK__p__XK5', 'XK5_AV_J1')">
								{{infoList.G_MCSXK__p__XK5.AV}}
							</div>
						</div>

					</div>
				</div>
			</div>
			<div class=" fl">
				<div class="lable">三加热段空烟控制模型</div>
				<div class="leftrow ">
					<div class="fl marleft">
						<div class="col1">
							<div class="fl color1">A01-TSP</div>
							<div class="fl color3" @click="
                  toIpt(
                    infoList.G_TEMP.SJRKPTSP,
                    'A01-TSP',
                    'SJRKPTSP',
                    'G_TEMP',
                    
                  )
                ">
								{{ infoList.G_TEMP.SJRKPTSP}}
							</div>
						</div>
						<div class="col2">
							<div class="fl color1">A01-TPV</div>
							<div class="fl color2" @click="toCompon(2, 'T22PV1', 'G_TEMP', 'T22PV1_J1','三加热段空烟温度处理值')"
								@dblclick="Cclick(infoList.G_TEMP.T22PV1,'AV', 'T22PV1', 'G_TEMP', 'T22PV1_J1')">
								{{infoList.G_TEMP.T22PV1}}
							</div>
						</div>
						<div class="col3" @click="
                  toIpt(
                    infoList.G_TEMP.T22SEL1,
                    '测量方式',
                     'T22SEL1',
                    'G_TEMP',
                   
                  )
                ">{{infoList.G_TEMP.T22SEL1?'段最大值':'单一温度'}}</div>
					</div>
					<div class="fl xcent">

						<div class="fl">
							<div class="col2 martop">
								<div class="fl color1">A02-RSF</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')">
									{{infoList.G_MCSOTHER__p__RSF3.AV}}
								</div>
							</div>
							<div class="col2 martop1">
								<div class="fl color1">A03-QK</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__QK11', 'QK11_AV_J1','三加热空烟空气阀门前馈')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__QK11.AV,'AV', 'G_MCSOTHER__p__QK11', 'QK11_AV_J1')">
									{{infoList.G_MCSOTHER__p__QK11.AV}}
								</div>
							</div>
						</div>
						<div class="fl mar-jg">
							<div class="bottonx " :style="{
              background:
                infoList.G_MCSXK__p__XK6 &&
                !infoList.G_MCSXK__p__XK6.XK6
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSXK__p__XK6 ? toDetail(1,'XK6','G_MCSXK__p__XK6','','三加热段空烟温度控制XK' ): ''">
								X
							</div>


							<div class="bottonx  mar-jg33" :style="{
                  background: !infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList.G_MCSOTHER__p__RSF3 ? toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''">
								R</div>
							<div class="bottonx mar-jg1" :style="{
              background:
                infoList.G_MCSOTHER__p__QK11 &&
                !infoList.G_MCSOTHER__p__QK11.TS
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSOTHER__p__QK11 ? toDetail(2,'QK11','G_MCSOTHER__p__QK11','','三加热段空烟空气阀门前馈' ): ''">
								K</div>

						</div>
						<div class="fl"></div>

					</div>
					<div class="fl">
						<div class="bottonx " :style="{
              background:
                infoList.G_MCSMAN__p__MAN7 &&
                infoList.G_MCSMAN__p__MAN7.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }" @click="
              infoList.G_MCSMAN__p__MAN7
                ? toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN7',
                    'MAN7_RM_J1',
                    '三热段空烟气阀门手操器'
                  )
                : ''
            ">A</div>
						<div class="col11 mar-left">
							<div class="fl color11">A01-AV</div>
							<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSXK__p__XK6', 'XK6_AV_J1','三加热段空烟温度控制XK')"
								@dblclick="Cclick(infoList.G_MCSXK__p__XK6.AV,'AV', 'AV', 'G_MCSXK__p__XK6', 'XK6_AV_J1')">
								{{infoList.G_MCSXK__p__XK6.AV}}
							</div>
						</div>

					</div>
				</div>
			</div>


		</div>
		<div class="main">
			<div class=" fl">
				<div class="lable">二加热段煤烟控制模型</div>
				<div class="leftrow ">
					<div class="fl marleft">
						<div class="col1">
							<div class="fl color1">A01-TSP</div>
							<div class="fl color3" @click="
                  toIpt(
                    infoList.G_TEMP.EJRMPTSP,
                    'A01-TSP',
                     'EJRMPTSP',
                    'G_TEMP',
                   
                  )
                ">
								{{ infoList.G_TEMP.EJRMPTSP}}
							</div>
						</div>
						<div class="col2">
							<div class="fl color1">A01-TPV</div>
							<div class="fl color2" @click="toCompon(2, 'T32PV2', 'G_TEMP', 'T32PV2_J1','二加热段煤烟温度处理值')"
								@dblclick="Cclick(infoList.G_TEMP.T32PV2,'AV', 'T32PV2', 'G_TEMP', 'T32PV2_J1')">
								{{infoList.G_TEMP.T32PV2}}
							</div>
						</div>
						<div class="col3" @click="
                  toIpt(
                    infoList.G_TEMP.T32SEL1,
                    '测量方式',
                     'T32SEL1',
                    'G_TEMP',
                   
                  )
                ">{{infoList.G_TEMP.T32SEL1?'段最大值':'单一温度'}}</div>
					</div>
					<div class="fl xcent">

						<div class="fl">
							<div class="col2 martop">
								<div class="fl color1">A02-RSF</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')">
									{{infoList.G_MCSOTHER__p__RSF3.AV}}
								</div>
							</div>
							<div class="col2 martop1">
								<div class="fl color1">A03-QK</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__QK14', 'QK14_AV_J1','二加热煤烟煤气阀门前馈')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__QK14.AV,'AV', 'G_MCSOTHER__p__QK14', 'QK14_AV_J1')">
									{{infoList.G_MCSOTHER__p__QK14.AV}}
								</div>
							</div>
						</div>
						<div class="fl mar-jg">
							<div class="bottonx " :style="{
              background:
                infoList.G_MCSXK__p__XK8 &&
                !infoList.G_MCSXK__p__XK8.XK8
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSXK__p__XK8 ? toDetail(1,'XK8','G_MCSXK__p__XK8','','二加热段煤烟温度控制XK' ): ''">
								X
							</div>


							<div class="bottonx mar-jg33 " :style="{
                  background: !infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList.G_MCSOTHER__p__RSF3 ? toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''">
								R</div>
							<div class="bottonx  mar-jg1" :style="{
              background:
                infoList.G_MCSOTHER__p__QK14 &&
                !infoList.G_MCSOTHER__p__QK14.TS
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSOTHER__p__QK14 ? toDetail(2,'QK14','G_MCSOTHER__p__QK14','','二加热煤烟煤气阀门前馈' ): ''">K
							</div>

						</div>
						<div class="fl"></div>

					</div>
					<div class="fl">
						<div class="bottonx " :style="{
              background:
                infoList.G_MCSMAN__p__MAN12 &&
                infoList.G_MCSMAN__p__MAN12.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }" @click="
              infoList.G_MCSMAN__p__MAN12
                ? toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN12',
                    'MAN12_RM_J1',
                    '二热段煤烟气阀门手操器'
                  )
                : ''
            ">A</div>
						<div class="col11 mar-left">
							<div class="fl color11">A01-AV</div>
							<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSXK__p__XK8', 'XK8_AV_J1','二加热段煤烟温度控制XK')"
								@dblclick="Cclick(infoList.G_MCSXK__p__XK8.AV,'AV', 'G_MCSXK__p__XK8', 'XK8_AV_J1')">
								{{infoList.G_MCSXK__p__XK8.AV}}
							</div>
						</div>

					</div>
				</div>
			</div>
			<div class=" fl">
				<div class="lable">二加热段空烟控制模型</div>
				<div class="leftrow ">
					<div class="fl marleft">
						<div class="col1">
							<div class="fl color1">A01-TSP</div>
							<div class="fl color3" @click="
                  toIpt(
                    infoList.G_TEMP.EJRKPTSP,
                    'A01-TSP',
                     'EJRKPTSP',
                    'G_TEMP',
                   
                  )
                ">
								{{ infoList.G_TEMP.EJRKPTSP}}
							</div>
						</div>
						<div class="col2">
							<div class="fl color1">A01-TPV</div>
							<div class="fl color2" @click="toCompon(2, 'T32PV1', 'G_TEMP', 'T32PV1_J1','二加热段空烟温度处理值')"
								@dblclick="Cclick(infoList.G_TEMP.T32PV1,'T32PV1', 'G_TEMP', 'T32PV1_J1')">
								{{infoList.G_TEMP.T32PV1}}
							</div>
						</div>
						<div class="col3" @click="
                  toIpt(
                    infoList.G_TEMP.T32SEL1,
                    '测量方式',
                     'T32SEL1',
                    'G_TEMP',
                   
                  )
                ">{{infoList.G_TEMP.T32SEL1?'段最大值':'单一温度'}}</div>
					</div>
					<div class="fl xcent">

						<div class="fl">
							<div class="col2 martop">
								<div class="fl color1">A02-RSF</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')">
									{{infoList.G_MCSOTHER__p__RSF3.AV}}
								</div>
							</div>
							<div class="col2 martop1">
								<div class="fl color1">A03-QK</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__QK13', 'QK13_AV_J1','二加热空烟空气阀门前馈')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__QK13.AV,'AV', 'G_MCSOTHER__p__QK13', 'QK13_AV_J1')">
									{{infoList.G_MCSOTHER__p__QK13.AV}}
								</div>
							</div>
						</div>
						<div class="fl mar-jg">
							<div class="bottonx " :style="{
              background:
                infoList.G_MCSXK__p__XK9 &&
                !infoList.G_MCSXK__p__XK9.XK9
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSXK__p__XK9 ? toDetail(1,'XK9','G_MCSXK__p__XK9','','二加热段空烟温度控制XK' ): ''">
								X
							</div>


							<div class="bottonx  mar-jg33" :style="{
                  background: !infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList.G_MCSOTHER__p__RSF3 ? toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''">
								R</div>
							<div class="bottonx mar-jg1" :style="{
              background:
                infoList.G_MCSOTHER__p__QK13 &&
                !infoList.G_MCSOTHER__p__QK13.TS
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSOTHER__p__QK13 ? toDetail(2,'QK13','G_MCSOTHER__p__QK13','','二加热段空烟空气阀门前馈' ): ''">
								K</div>

						</div>
						<div class="fl"></div>

					</div>
					<div class="fl">
						<div class="bottonx " :style="{
              background:
                infoList.G_MCSMAN__p__MAN11 &&
                infoList.G_MCSMAN__p__MAN11.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }" @click="
              infoList.G_MCSMAN__p__MAN11
                ? toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN11',
                    'MAN11_RM_J1',
                    '二热段空烟气阀门手操器'
                  )
                : ''
            ">A</div>
						<div class="col11 mar-left">
							<div class="fl color11">A01-AV</div>
							<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSXK__p__XK9', 'XK9_AV_J1','二加热段空烟温度控制XK')"
								@dblclick="Cclick(infoList.G_MCSXK__p__XK9.AV,'AV', 'G_MCSXK__p__XK9', 'XK9_AV_J1')">
								{{infoList.G_MCSXK__p__XK9.AV}}
							</div>
						</div>

					</div>
				</div>
			</div>


		</div>
		<div class="main">
			<div class=" fl">
				<div class="lable">一加热段煤烟控制模型</div>
				<div class="leftrow ">
					<div class="fl marleft">
						<div class="col1">
							<div class="fl color1">A01-TSP</div>
							<div class="fl color3" @click="
                  toIpt(
                    infoList.G_TEMP.YJRMPTSP,
                    'A01-TSP',
                     'YJRMPTSP',
                    'G_TEMP',
                   
                  )
                ">
								{{ infoList.G_TEMP.YJRMPTSP}}
							</div>
						</div>
						<div class="col2">
							<div class="fl color1">A01-TPV</div>
							<div class="fl color2" @click="toCompon(2, 'T42PV2', 'G_TEMP', 'T42PV2_J1','一加热段煤烟温度处理值')"
								@dblclick="Cclick(infoList.G_TEMP.T42PV2,'T42PV2', 'G_TEMP', 'T42PV2_J1')">
								{{infoList.G_TEMP.T42PV2}}
							</div>
						</div>
						<div class="col3" @click="
                  toIpt(
                    infoList.G_TEMP.T42SEL1,
                    '测量方式',
                     'T42SEL1',
                    'G_TEMP',
                   
                  )
                ">{{infoList.G_TEMP.T42SEL1?'段最大值':'单一温度'}}</div>
					</div>
					<div class="fl xcent">

						<div class="fl">
							<div class="col2 martop">
								<div class="fl color1">A02-RSF</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__RSF3.AV,'AV','G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')">
									{{infoList.G_MCSOTHER__p__RSF3.AV}}
								</div>
							</div>
							<div class="col2 martop1">
								<div class="fl color1">A03-QK</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__QK16', 'QK16_AV_J1','一加热煤烟煤气阀门前馈')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__QK16.AV,'AV', 'AV', 'G_MCSOTHER__p__QK16', 'QK16_AV_J1')">
									{{infoList.G_MCSOTHER__p__QK16.AV}}
								</div>
							</div>
						</div>
						<div class="fl mar-jg">
							<div class="bottonx " :style="{
              background:
                infoList.G_MCSXK__p__XK11 &&
                !infoList.G_MCSXK__p__XK11.XK11
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSXK__p__XK11 ? toDetail(1,'XK11','G_MCSXK__p__XK11','','一加热段煤烟温度控制XK' ): ''">
								X
							</div>


							<div class="bottonx mar-jg33 " :style="{
                  background: !infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList.G_MCSOTHER__p__RSF3 ? toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''">
								R</div>
							<div class="bottonx  mar-jg1" :style="{
              background:
                infoList.G_MCSOTHER__p__QK16 &&
                !infoList.G_MCSOTHER__p__QK16.TS
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSOTHER__p__QK16 ? toDetail(2,'QK16','G_MCSOTHER__p__QK16','','一加热煤烟煤气阀门前馈' ): ''">K
							</div>

						</div>
						<div class="fl"></div>

					</div>
					<div class="fl">
						<div class="bottonx " :style="{
              background:
                infoList.G_MCSMAN__p__MAN23 &&
                infoList.G_MCSMAN__p__MAN23.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }" @click="
              infoList.G_MCSMAN__p__MAN23
                ? toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN23',
                    'MAN23_RM_J1',
                    '一热段煤烟气阀门手操器'
                  )
                : ''
            ">A</div>
						<div class="col11 mar-left">
							<div class="fl color11">A01-AV</div>
							<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSXK__p__XK11', 'XK11_AV_J1','一加热段煤烟温度控制XK')"
								@dblclick="Cclick(infoList.G_MCSXK__p__XK11.AV,'AV', 'G_MCSXK__p__XK11', 'XK11_AV_J1')">
								{{infoList.G_MCSXK__p__XK11.AV}}
							</div>
						</div>

					</div>
				</div>
			</div>
			<div class=" fl">
				<div class="lable">一加热段空烟控制模型</div>
				<div class="leftrow ">
					<div class="fl marleft">
						<div class="col1">
							<div class="fl color1">A01-TSP</div>
							<div class="fl color3" @click="
                  toIpt(
                    infoList.G_TEMP.YJRKPTSP,
                    'A01-TSP',
                    'YJRKPTSP',
                    'G_TEMP',
                    
                  )
                ">
								{{ infoList.G_TEMP.YJRKPTSP}}
							</div>
						</div>
						<div class="col2">
							<div class="fl color1">A01-TPV</div>
							<div class="fl color2" @click="toCompon(2, 'T42PV1', 'G_TEMP', 'T42PV1_J1','一加热空烟温度处理')"
								@dblclick="Cclick(infoList.G_TEMP.T42PV1,'AV', 'T42PV1', 'G_TEMP', 'T42PV1_J1')">
								{{infoList.G_TEMP.T42PV1}}
							</div>
						</div>
						<div class="col3" @click="
                  toIpt(
                    infoList.G_TEMP.T42SEL1,
                    '测量方式',
                     'T42SEL1',
                    'G_TEMP',
                   
                  )
                ">{{infoList.G_TEMP.T42SEL1?'段最大值':'单一温度'}}</div>
					</div>
					<div class="fl xcent">

						<div class="fl">
							<div class="col2 martop">
								<div class="fl color1">A02-RSF</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1','负压空废煤废温度补偿')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__RSF3.AV,'AV', 'G_MCSOTHER__p__RSF3', 'RSF3_AV_J1')">
									{{infoList.G_MCSOTHER__p__RSF3.AV}}
								</div>
							</div>
							<div class="col2 martop1">
								<div class="fl color1">A03-QK</div>
								<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSOTHER__p__QK15', 'QK15_AV_J1','一加热空烟空气阀门前馈')"
									@dblclick="Cclick(infoList.G_MCSOTHER__p__QK15.AV,'AV', 'G_MCSOTHER__p__QK15', 'QK15_AV_J1')">
									{{infoList.G_MCSOTHER__p__QK15.AV}}
								</div>
							</div>
						</div>
						<div class="fl mar-jg">
							<div class="bottonx " :style="{
              background:
                infoList.G_MCSXK__p__XK12 &&
                !infoList.G_MCSXK__p__XK12.XK12
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSXK__p__XK12 ? toDetail(1,'XK12','G_MCSXK__p__XK12','','一加热段空烟温度控制XK' ): ''">
								X
							</div>


							<div class="bottonx  mar-jg33" :style="{
                  background: !infoList.G_MCSOTHER__p__RSF3.TS
                    ? '#2AFC30'
                    : 'red',
                }" @click="infoList.G_MCSOTHER__p__RSF3 ? toDetail(3,'RSF3','G_MCSOTHER__p__RSF3','','负压空废煤废温度补偿' ): ''">
								R</div>
							<div class="bottonx mar-jg1" :style="{
              background:
                infoList.G_MCSOTHER__p__QK15 &&
                !infoList.G_MCSOTHER__p__QK15.TS
                  ? '#2AFC30'
                  : 'red',
            }" @click="infoList.G_MCSOTHER__p__QK15 ? toDetail(2,'QK15','G_MCSOTHER__p__QK15','','一加热段空烟空气阀门前馈' ): ''">
								K</div>

						</div>
						<div class="fl"></div>

					</div>
					<div class="fl">
						<div class="bottonx " :style="{
              background:
                infoList.G_MCSMAN__p__MAN22 &&
                infoList.G_MCSMAN__p__MAN22.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }" @click="
              infoList.G_MCSMAN__p__MAN22
                ? toCompon(
                    0,
                    'AV',
                    'G_MCSMAN__p__MAN22',
                    'MAN22_RM_J1',
                    '一热段空烟气阀门手操器'
                  )
                : ''
            ">A</div>
						<div class="col11 mar-left">
							<div class="fl color11">A01-AV</div>
							<div class="fl color2" @click="toCompon(2, 'AV', 'G_MCSXK__p__XK12', 'XK12_AV_J1','一加热段空烟温度控制XK')"
								@dblclick="Cclick(infoList.G_MCSXK__p__XK12.AV,'AV', 'G_MCSXK__p__XK12', 'XK12_AV_J1')">
								{{infoList.G_MCSXK__p__XK12.AV}}
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<Manual :key="isIndex" v-if="isMshow" @sendStatus="isClose" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :titname="Manualtitname" :infoList="infoList"></Manual>
		 <Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
			:chName="chName" :infoList="infoList"></Historical>
		<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
	</div>
</template>
<script>
	import Historical from "@/components/Historical";
	import inputVal from "@/components/inputVal"; //输入框组件
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "JRL_myzg",
		components: {
			Manual,
			Historical,
			inputVal,
		},
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},
		data: () => {
			return {
				chName: '',
				isMshow: false,
				headerStyle: {
					"font-weight": "500",
					"font-size": "1.8vh",
					height: "1vh",
					borderColor: "#0f3747",
					background: "#205365",
					padding: "0px",
					border: "none",
					color: "#5ca4a6",
				},
				flag: 0,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				historyname: "",
				nodename: "",
				Observername: "",
				Observernode: "",
				Rsfname: "",
				Rsfnode: "",
				bodyHeight: "",
				Firstcontrolname: "",
				Firstcontrolnode: "",
				Firstcontroltitname: "",
				fullscreenLoading: true,
				isHshow: false,
				isOshow: false,
				isRshow: false,
				screenWidth: document.body.clientWidth,
				screeHeight: "",
				conHeight: "",
				leftHeight: "",
				leftcon: "",
				tabline: "",
				tabline1: "",
				tabstyle: "",
				tablist: "",
				tabdatawidth: "",
				tabdatawidth1: "",
				Aname: "",
				changValue: null,
				config: {
					data: [10],
					shape: "round",
					colors: ["#43ff44", "#43ff44"],
					waveOpacity: "1",
					waveNum: "50",
					formatter: "{value}",
				},
				rightHeight: "",
			};
		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
		},
		computed: {},
		mounted() {},
		methods: {
			DataJson(data) {
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'jrkfirstcontol'
							break
						case 3:
							pathname = 'jrRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node);
			},
			closeCompon() {
				this.$emit("sendStatus", "JRL_myzg", false);
			},
			isClose1() {
				this.isHshow = false;
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return (
								(this.isMshow = true),
								(this.Manualname = name),
								(this.Manualnode = name2),
								(this.ManualAname = name3),
								(this.Manualtitname = titname)
							);
						case 1:
							return (
								(this.isFshow = true),
								(this.Firstcontrolname = name),
								(this.Firstcontrolnode = name2),
								(this.Firstcontroltitname = titname)
							);
						case 2:
							return (
								(this.isHshow = true),
								(this.chName = name4)
							);
					}
				}, 300);
			},
			isClose() {
				this.isMshow = false;
			},
			// 打开历史趋势窗口
			toHistory(data) {
				this.$refs.history.open(data);
			},
		},

	};
</script>
<style lang="scss" scoped>
	.jrlmyzg {
		width: 78vw;
		height: 85vh;
		//  background: #e9f7ff;
		background-image: url("~@/assets/images/jrl-param.png");
		background-size: 100% 100%;
		//  cursor:move;
		border-radius: 5px;

		.ziti {
			font-size: 0.9vw;
		}

		.zhuangshi1 {
			width: 30vw;
			height: 10vh;
			background-color: #0ef7ff;
			opacity: 0.66;
		}

		.main-top {
			margin-left: 3.5vw;
			margin-top: 1vh;
		}

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.title-hang {
			height: 5vh;
			width: 53vw;
			font-family: MicrosoftYaHei;
			font-size: 0.9vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
			padding-top: 1vh;
			// margin-top:vh;
		}

		.title1 {
			width: 8vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #ffffff;
			margin-top: 1vh;
			margin-left: 1vw;
		}


		.title-hang {
			width: 100%;
			height: 6vh;
			text-align: center;
			font-family: MicrosoftYaHei;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 6vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
		}

		.lable1 {
			font-family: PingFang-SC-Regular;
			font-size: 1.1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.5vh;
			letter-spacing: 0vh;
			color: #d5fffe;
			margin-left: 2.5vw;
			margin-top: 1vh;
		}

		.titlelable {
			width: 53vw;
			height: 3vh;
			font-family: PingFang-SC-Regular;
			font-size: 1.5vw;
			;
			font-weight: normal;
			font-stretch: normal;
			line-height: 3vh;
			letter-spacing: 0.3vw;
			color: #d5fffe;
			text-align: center;
			margin-top: 2vh;
		}

		.leftrow {
			width: 35vw;
			height: 14vh;
			border: solid 1px #00e4ff20;
			margin-left: 3vw;
			margin-top: 1vh;
			padding-top: 1vh;


		}

		.col3 {
			width: 5vw;
			;
			height: 3vh;
			background-color: #22fff7;
			border-radius: 0vh;
			margin-top: 4vh;
			line-height: 3vh;
			text-align: center;
			cursor: pointer;
		}

		.martop {
			margin-top: 7vh;
			margin-left: -4vw;
		}

		.mar-jg {
			margin-left: 5vw;
			margin-top: 0.5vh;
		}

		.mar-jg1 {
			// margin-left:3vw;
			margin-top: 0.5vh;
		}

		.mar-jg3 {
			// margin-left:3vw;
			margin-top: 0.2vh;
		}

		.mar-jg33 {
			// margin-left:3vw;
			margin-top: 3.5vh;
		}

		.martop1 {
			margin-top: 0.5vh;
			margin-left: -4vw;
		}

		.mar-left {
			margin-left: -5.5vw;
		}

		.texlable {
			margin-left: -4vw;
			margin-top: 1.6vh;
			font-family: PingFang-SC-Regular;
			// font-size: 1vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #00ffb4;
			cursor: pointer;
		}

		.lable {
			width: 34vw;
			height: 2vh;
			margin-left: 4vw;
			text-align: center;
			font-family: PingFang-SC-Regular;
			font-size: 1.1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #d5fffe;
			margin-top: 0.3vh;

		}

		.param11-header-icon {
			width: 2vw;
			height: 2vh;
			right: 1vw;
			margin-top: -5vh;
			cursor: pointer;
			margin-left: 75vw;
		}

		.main {
			margin-top: 4vh;
			font-size: 1vw;
		}

		.rightmain {
			width: 34vw;
			height: 16vh;
			border: solid 1px #00e4ff20;
			margin-left: 2vw;
		}

		.col2 {
			width: 9vw;
			height: 2.5vh;
			background-color: #001b2890;
			border-radius: 0vw;
			border: solid 1px #236f8d;
			line-height: 2.5vh;
			// margin-left:-1vw;
		}

		.col1 {
			width: 10vw;
			height: 3vh;

		}

		.col11 {
			width: 10vw;
			height: 3vh;

		}

		.marleft {
			margin-left: 1vw;
			// padding-top:1vh;
		}

		.color1 {
			color: #8aeaff;
			width: 5vw;
			text-align: center;
			line-height: 2.5vh;
		}

		.color11 {
			color: #8aeaff;
			width: 5vw;
			text-align: center;
			line-height: 2.5vh;
			margin-left: 1vw;
		}

		.color2 {
			color: #00e4ff;
			text-align: center;
			line-height: 2.5vh;
			cursor: pointer;
		}

		.color3 {
			color: #00ffb4;
			text-align: center;
			line-height: 3vh;
			cursor: pointer;
		}

		.xcent {
			width: 18vw;
			height: 11vh;
			background-image: url("~@/assets/images/jrl-myzg.png");
			background-size: 100% 100%;
			margin-top: -0.8vh;
		}

		.bottonx {
			width: 1vw;
			height: 2vh;
			text-align: center;
			line-height: 2vh;
			border: 2px solid rgb(217, 243, 145);
			font-size: 0.9vw;
			// margin-right: 0.1vw;
			color: #fff;
			cursor: pointer;
		}

		.jg1 {
			margin-top: 4.5vh;
		}

		.jg2 {
			margin-top: 12vh;
		}

		.jg3 {
			margin-top: 6.5vh;
		}


	}
</style>
