var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"jrlEvaluate",attrs:{"id":"jrlEvaluate"}},[_c('div',{staticClass:"title-hang"},[_c('div',{staticClass:"fl"},[_c('dv-decoration-1',{staticStyle:{"width":"14vw","height":"3vh","margin-top":"1vh"}})],1),_c('div',{staticClass:"fl"}),_c('div',{staticClass:"zhuangshizj fl"},[_vm._v(_vm._s(_vm.projectData.project)+"_运行考核")]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"fl"},[_c('dv-decoration-1',{staticStyle:{"width":"14vw","height":"3vh","margin-top":"1vh"}})],1)])]),_c('div',{staticClass:"mainall"},[_c('div',{staticClass:"fl leftmain"},[_c('div',{staticClass:"leftmain-row1"},[_vm._v("数据显示")]),_vm._m(0),_c('div',{staticClass:"leftmain-row3"},[_c('div',{staticClass:"leftmain-row3-col fl"},[_vm._v("过钢累计(根)")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHGGLJB',
              _vm.infoList.KHGGLJB_node_string,
              'KHGGLJB_J1',
              '过钢量班累积'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHGGLJB,
              'KHGGLJB',
              _vm.infoList.KHGGLJB_node_string,
              'KHGGLJB_J1'
            )}}},[_vm._v(" "+_vm._s('KHGGLJB' in _vm.infoList?_vm.infoList.KHGGLJB :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHGGLJT',
              _vm.infoList.KHGGLJT_node_string,
              'KHGGLJT_J1',
              '过钢量天累积'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHGGLJT,
              'KHGGLJT',
              _vm.infoList.KHGGLJT_node_string,
              'KHGGLJT_J1'
            )}}},[_vm._v(" "+_vm._s('KHGGLJT' in _vm.infoList?_vm.infoList.KHGGLJT :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHGGLJY',
              _vm.infoList.KHGGLJY_node_string,
              'HGGLJY_J1',
              '过钢量月累积'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHGGLJY,
              'KHGGLJY',
              _vm.infoList.KHGGLJY_node_string,
              'HGGLJY_J1'
            )}}},[_vm._v(" "+_vm._s('KHGGLJY' in _vm.infoList?_vm.infoList.KHGGLJY :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHGGLJS',
              _vm.infoList.KHGGLJS_node_string,
              'KHGGLJS_J1',
              '过钢量月累积'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHGGLJS,
              'KHGGLJS',
              _vm.infoList.KHGGLJS_node_string,
              'KHGGLJS_J1'
            )}}},[_vm._v(" "+_vm._s('KHGGLJS' in _vm.infoList?_vm.infoList.KHGGLJS :0)+" ")])]),_c('div',{staticClass:"leftmain-row3"},[_c('div',{staticClass:"leftmain-row3-col fl"},[_vm._v("钢重累计(T)")]),_c('div',{staticClass:"leftmain-row3-col1 fl"},[_vm._v(_vm._s(this.gzlj1))]),_c('div',{staticClass:"leftmain-row3-col1 fl"},[_vm._v(_vm._s(this.gzlj2))]),_c('div',{staticClass:"leftmain-row3-col1 fl"},[_vm._v(_vm._s(this.gzlj3))]),_c('div',{staticClass:"leftmain-row3-col1 fl"},[_vm._v(_vm._s(this.gzlj4))])]),_c('div',{staticClass:"leftmain-row3"},[_c('div',{staticClass:"leftmain-row3-col fl"},[_vm._v("煤气累计(Wm³)")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHMQLJB',
              _vm.infoList.KHMQLJB_node_string,
              'KHMQLJB_J1',
              '煤气班累积'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHMQLJB,
              'KHMQLJB',
              _vm.infoList.KHMQLJB_node_string,
              'KHMQLJB_J1'
            )}}},[_vm._v(" "+_vm._s('KHMQLJB' in _vm.infoList?_vm.infoList.KHMQLJB :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHMQLJT',
              _vm.infoList.KHMQLJT_node_string,
              'KHMQLJT_J1',
              '煤气天累积'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHMQLJT,
              'KHMQLJT',
              _vm.infoList.KHMQLJT_node_string,
              'KHMQLJT_J1'
            )}}},[_vm._v(" "+_vm._s('KHMQLJT' in _vm.infoList?_vm.infoList.KHMQLJT :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHMQLJY',
              _vm.infoList.KHMQLJY_node_string,
              'KHMQLJY_J1',
              '煤气月累积'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHMQLJY,
              'KHMQLJY',
              _vm.infoList.KHMQLJY_node_string,
              'KHMQLJY_J1'
            )}}},[_vm._v(" "+_vm._s('KHMQLJY' in _vm.infoList?_vm.infoList.KHMQLJY :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHMQLJS',
              _vm.infoList.KHMQLJS_node_string,
              'KHMQLJS_J1',
              '煤气月累积'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHMQLJS,
              'KHMQLJS',
              _vm.infoList.KHMQLJS_node_string,
              'KHMQLJS_J1'
            )}}},[_vm._v(" "+_vm._s('KHMQLJS' in _vm.infoList?_vm.infoList.KHMQLJS :0)+" ")])]),_c('div',{staticClass:"leftmain-row3"},[_c('div',{staticClass:"leftmain-row3-col fl"},[_vm._v("炉膛均压")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(2, 'KHPLTB', _vm.infoList.KHPLTB_node_string, 'KHPLTB_J1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHPLTB,
              'KHPLTB',
              _vm.infoList.KHPLTB_node_string,
              'KHPLTB_J1'
            )}}},[_vm._v(" "+_vm._s('KHPLTB' in _vm.infoList?_vm.infoList.KHPLTB :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(2, 'KHPLTT', _vm.infoList.KHPLTT_node_string, 'KHPLTT_J1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHPLTT,
              'KHPLTT',
              _vm.infoList.KHPLTT_node_string,
              'KHPLTT_J1'
            )}}},[_vm._v(" "+_vm._s('KHPLTT' in _vm.infoList?_vm.infoList.KHPLTT :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(2, 'KHPLTY', _vm.infoList.KHPLTY_node_string, 'KHPLTY_J1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHPLTY,
              'KHPLTY',
              _vm.infoList.KHPLTY_node_string,
              'KHPLTY_J1'
            )}}},[_vm._v(" "+_vm._s('KHPLTY' in _vm.infoList?_vm.infoList.KHPLTY :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(2, 'KHPLTS', _vm.infoList.KHPLTS_node_string, 'KHPLTS_J1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHPLTS,
              'KHPLTS',
              _vm.infoList.KHPLTS_node_string,
              'KHPLTS_J1'
            )}}},[_vm._v(" "+_vm._s('KHPLTS' in _vm.infoList?_vm.infoList.KHPLTS :0)+" ")])]),_c('div',{staticClass:"leftmain-row3"},[_c('div',{staticClass:"leftmain-row3-col fl"},[_vm._v("均热均温")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(2, 'KHT11B', _vm.infoList.KHT11B_node_string, 'KHT11B_J1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHT11B,
              'KHT11B',
              _vm.infoList.KHT11B_node_string,
              'KHT11B_J1'
            )}}},[_vm._v(" "+_vm._s('KHT11B' in _vm.infoList?_vm.infoList.KHT11B :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(2, 'KHT11T', _vm.infoList.KHT11T_node_string, 'KHT11T_J1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHT11T,
              'KHT11T',
              _vm.infoList.KHT11T_node_string,
              'KHT11T_J1'
            )}}},[_vm._v(" "+_vm._s('KHT11T' in _vm.infoList?_vm.infoList.KHT11T :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(2, 'KHT11Y', _vm.infoList.KHT11Y_node_string, 'KHPLTY_J1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHT11Y,
              'KHT11Y',
              _vm.infoList.KHT11Y_node_string,
              'KHPLTY_J1'
            )}}},[_vm._v(" "+_vm._s('KHT11Y' in _vm.infoList?_vm.infoList.KHT11Y :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(2, 'KHT11S', _vm.infoList.KHT11S_node_string, 'KHPLTS_J1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHT11S,
              'KHT11S',
              _vm.infoList.KHT11S_node_string,
              'KHPLTS_J1'
            )}}},[_vm._v(" "+_vm._s('KHT11S' in _vm.infoList?_vm.infoList.KHT11S :0)+" ")])]),_c('div',{staticClass:"leftmain-row3"},[_c('div',{staticClass:"leftmain-row3-col fl"},[_vm._v("出钢均温")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(2, 'KHTCGB', _vm.infoList.KHTCGB_node_string, 'KHTCGB_J1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHTCGB,
              'KHTCGB',
              _vm.infoList.KHTCGB_node_string,
              'KHTCGB_J1'
            )}}},[_vm._v(" "+_vm._s('KHTCGB' in _vm.infoList?_vm.infoList.KHTCGB :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(2, 'KHTCGT', _vm.infoList.KHTCGT_node_string, 'KHTCGT_J1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHTCGT,
              'KHTCGT',
              _vm.infoList.KHTCGT_node_string,
              'KHTCGT_J1'
            )}}},[_vm._v(" "+_vm._s('KHTCGT' in _vm.infoList?_vm.infoList.KHTCGT :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(2, 'KHTCGY', _vm.infoList.KHTCGY_node_string, 'KHTCGY_J1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHTCGY,
              'KHTCGY',
              _vm.infoList.KHTCGY_node_string,
              'KHTCGY_J1'
            )}}},[_vm._v(" "+_vm._s('KHTCGY' in _vm.infoList?_vm.infoList.KHTCGY :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(2, 'KHTCGS', _vm.infoList.KHTCGS_node_string, 'KHTCGS_J1')},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHTCGS,
              'KHTCGS',
              _vm.infoList.KHTCGS_node_string,
              'KHTCGS_J1'
            )}}},[_vm._v(" "+_vm._s('KHTCGS' in _vm.infoList?_vm.infoList.KHTCGS :0)+" ")])]),_c('div',{staticClass:"leftmain-row3"},[_c('div',{staticClass:"leftmain-row3-col fl"},[_vm._v("入钢均温")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHTRGB',
              _vm.infoList.KHTRGB_node_string,
              'KHTRGB_J1',
              '入炉钢温班平均值'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHTRGB,
              'KHTRGB',
              _vm.infoList.KHTRGB_node_string,
              'KHTRGB_J1'
            )}}},[_vm._v(" "+_vm._s('KHTRGB' in _vm.infoList?_vm.infoList.KHTRGB :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHTRGT',
              _vm.infoList.KHTRGT_node_string,
              'KHTRGT_J1',
              '入炉钢温天平均值'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHTRGT,
              'KHTRGT',
              _vm.infoList.KHTRGT_node_string,
              'KHTRGT_J1'
            )}}},[_vm._v(" "+_vm._s('KHTRGT' in _vm.infoList?_vm.infoList.KHTRGT :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHTRGY',
              _vm.infoList.KHTRGY_node_string,
              'KHTRGY_J1',
              '入炉钢温班平均值'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHTRGY,
              'KHTRGY',
              _vm.infoList.KHTRGY_node_string,
              'KHTRGY_J1'
            )}}},[_vm._v(" "+_vm._s('KHTRGY' in _vm.infoList?_vm.infoList.KHTRGY :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHTRGS',
              _vm.infoList.KHTRGS_node_string,
              'KHTRGS_J1',
              '入炉钢温-随机考核平均值'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHTRGS,
              'KHTRGS',
              _vm.infoList.KHTRGS_node_string,
              'KHTRGS_J1'
            )}}},[_vm._v(" "+_vm._s('KHTRGS' in _vm.infoList?_vm.infoList.KHTRGS :0)+" ")])]),_c('div',{staticClass:"leftmain-row3"},[_c('div',{staticClass:"leftmain-row3-col fl"},[_vm._v("热装率%")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHRZLB',
              _vm.infoList.KHRZLB_node_string,
              'KHRZLB_J1',
              '热装率-班统计'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHRZLB,
              'KHRZLB',
              _vm.infoList.KHRZLB_node_string,
              'KHRZLB_J1'
            )}}},[_vm._v(" "+_vm._s('KHRZLB' in _vm.infoList?_vm.infoList.KHRZLB :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHRZLT',
              _vm.infoList.KHRZLT_node_string,
              'KHRZLT_J1',
              '热装率-天统计'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHRZLT,
              'KHRZLT',
              _vm.infoList.KHRZLT_node_string,
              'KHRZLT_J1'
            )}}},[_vm._v(" "+_vm._s('KHRZLT' in _vm.infoList?_vm.infoList.KHRZLT :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHRZLY',
              _vm.infoList.KHRZLY_node_string,
              'KHRZLY_J1',
              '热装率-月统计'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHRZLY,
              'KHRZLY',
              _vm.infoList.KHRZLY_node_string,
              'KHRZLY_J1'
            )}}},[_vm._v(" "+_vm._s('KHRZLY' in _vm.infoList?_vm.infoList.KHRZLY :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHRZLS',
              _vm.infoList.KHRZLS_node_string,
              'KHRZLS_J1',
              '热装率-随机考核统计'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHRZLS,
              'KHRZLS',
              _vm.infoList.KHRZLS_node_string,
              'KHRZLS_J1'
            )}}},[_vm._v(" "+_vm._s('KHRZLS' in _vm.infoList?_vm.infoList.KHRZLS :0)+" ")])]),_c('div',{staticClass:"leftmain-row3"},[_c('div',{staticClass:"leftmain-row3-col fl"},[_vm._v("单耗(m³/t)")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHMQGGB',
              _vm.infoList.KHMQGGB_node_string,
              'KHMQGGB_J1',
              '本班吨钢耗煤气'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHMQGGB,
              'KHMQGGB',
              _vm.infoList.KHMQGGB_node_string,
              'KHMQGGB_J1'
            )}}},[_vm._v(" "+_vm._s('KHMQGGB' in _vm.infoList?_vm.infoList.KHMQGGB :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHMQGGT',
              _vm.infoList.KHMQGGT_node_string,
              'KHMQGGT_J1',
              '当天吨钢耗煤气'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHMQGGT,
              'KHMQGGT',
              _vm.infoList.KHMQGGT_node_string,
              'KHMQGGT_J1'
            )}}},[_vm._v(" "+_vm._s('KHMQGGT' in _vm.infoList?_vm.infoList.KHMQGGT :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHMQGGY',
              _vm.infoList.KHMQGGY_node_string,
              'KHMQGGY_J1',
              '本月吨钢耗煤气'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHMQGGY,
              'KHMQGGY',
              _vm.infoList.KHMQGGY_node_string,
              'KHMQGGY_J1'
            )}}},[_vm._v(" "+_vm._s('KHMQGGY' in _vm.infoList?_vm.infoList.KHMQGGY :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHMQGGS',
              _vm.infoList.KHMQGGS_node_string,
              'KHMQGGS_J1',
              '上月吨钢耗煤气'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHMQGGS,
              'KHMQGGS',
              _vm.infoList.KHMQGGS_node_string,
              'KHMQGGS_J1'
            )}}},[_vm._v(" "+_vm._s('KHMQGGS' in _vm.infoList?_vm.infoList.KHMQGGS :0)+" ")])]),_c('div',{staticClass:"leftmain-row3"},[_c('div',{staticClass:"leftmain-row3-col fl"},[_vm._v("自控率累计%")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHZKLB',
              _vm.infoList.KHZKLB_node_string,
              'KHZKLB_J1',
              '班总自控率累计'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHZKLB,
              'KHZKLB',
              _vm.infoList.KHZKLB_node_string,
              'KHZKLB_J1'
            )}}},[_vm._v(" "+_vm._s('KHZKLB' in _vm.infoList?_vm.infoList.KHZKLB :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHZKLT',
              _vm.infoList.KHZKLT_node_string,
              'KHZKLT_J1',
              '天总自控率累计'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHZKLT,
              'KHZKLT',
              _vm.infoList.KHZKLT_node_string,
              'KHZKLT_J1'
            )}}},[_vm._v(" "+_vm._s('KHZKLT' in _vm.infoList?_vm.infoList.KHZKLT :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHZKLY',
              _vm.infoList.KHZKLY_node_string,
              'KHZKLY_J1',
              '月总自控率累计'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHZKLY,
              'KHZKLY',
              _vm.infoList.KHZKLY_node_string,
              'KHZKLY_J1'
            )}}},[_vm._v(" "+_vm._s('KHZKLY' in _vm.infoList?_vm.infoList.KHZKLY :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHZKLS',
              _vm.infoList.KHZKLS_node_string,
              'KHZKLS_J1',
              '自定义总自控率累计'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHZKLS,
              'KHZKLS',
              _vm.infoList.KHZKLS_node_string,
              'KHZKLS_J1'
            )}}},[_vm._v(" "+_vm._s('KHZKLS' in _vm.infoList?_vm.infoList.KHZKLS :0)+" ")])]),_c('div',{staticClass:"leftmain-row3"},[_c('div',{staticClass:"leftmain-row3-col fl"},[_vm._v("稳定性（%）")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHWDXB',
              _vm.infoList.KHWDXB_node_string,
              'KHWDXB_J1',
              '班稳定性考核'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHWDXB,
              'KHWDXB',
              _vm.infoList.KHWDXB_node_string,
              'KHWDXB_J1'
            )}}},[_vm._v(" "+_vm._s('KHWDXB' in _vm.infoList?_vm.infoList.KHWDXB :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHWDXT',
              _vm.infoList.KHWDXT_node_string,
              'KHWDXT_J1',
              '天稳定性考核'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHWDXT,
              'KHWDXT',
              _vm.infoList.KHWDXT_node_string,
              'KHWDXT_J1'
            )}}},[_vm._v(" "+_vm._s('KHWDXT' in _vm.infoList?_vm.infoList.KHWDXT :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHWDXY',
              _vm.infoList.KHWDXY_node_string,
              'KHWDXY_J1',
              '月稳定性考核'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHWDXY,
              'KHWDXY',
              _vm.infoList.KHWDXY_node_string,
              'KHWDXY_J1'
            )}}},[_vm._v(" "+_vm._s('KHWDXY' in _vm.infoList?_vm.infoList.KHWDXY :0)+" ")]),_c('div',{staticClass:"leftmain-row3-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'KHWDXS',
              _vm.infoList.KHWDXS_node_string,
              'KHWDXS_J1',
              '自定义稳定性考核'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KHWDXS,
              'KHWDXS',
              _vm.infoList.KHWDXS_node_string,
              'KHWDXS_J1'
            )}}},[_vm._v(" "+_vm._s('KHWDXS' in _vm.infoList?_vm.infoList.KHWDXS :0)+" ")])])])]),_c('div',{staticClass:"fl center-main"},[_c('div',{staticClass:"center-main-top"},[_vm._m(1),_c('div',[_c('div',{staticClass:"fl center-main-top1-col"},[_c('div',{staticClass:"col1"},[_vm._v("考核时间设定")]),_c('div',{staticClass:"col2",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.KH_SJ,
                '考核时间设定',
                'KH_SJ',
                _vm.infoList.KH_SJ_node_string
              )}}},[_vm._v(" "+_vm._s('KH_SJ' in _vm.infoList?_vm.infoList.KH_SJ :0)+" ")]),_c('div',{staticClass:"col1"},[_vm._v("考核进行时间")]),_c('div',{staticClass:"col2"},[_vm._v(_vm._s('KH_JXSJ' in _vm.infoList?_vm.infoList.KH_JXSJ :0))])]),_vm._m(2)])]),_c('div',{staticClass:"center-main-bottom"},[_c('div',{staticClass:"centermain-row1"},[_vm._v("指标名称和精度")]),_c('div',{staticClass:"centermain-row2 fl"},[_c('div',{staticClass:"fl centermain-row3"},[_c('div',{staticClass:"centermain-row3-lable",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.KH_MQPSP,
                '煤气压力',
                'KH_MQPSP',
                _vm.infoList.KH_MQPSP_node_string
              )}}},[_vm._v(" "+_vm._s('KH_MQPSP' in _vm.infoList?_vm.infoList.KH_MQPSP :0)+" ")]),_c('div',{staticClass:"centermain-row3-lable1"},[_vm._v("煤气压力")])]),_c('div',{staticClass:"fl centermain-row3 centermain-row3-jg"},[_c('div',{staticClass:"centermain-row3-lable",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.KH_FY5SP,
                '炉膛压力',
                'KH_FY5SP',
                _vm.infoList.KH_FY5SP_node_string
              )}}},[_vm._v(" "+_vm._s('KH_FY5SP' in _vm.infoList?_vm.infoList.KH_FY5SP :0)+" ")]),_c('div',{staticClass:"centermain-row3-lable1"},[_vm._v("炉膛压力")])]),_c('div',{staticClass:"fl centermain-row3 centermain-row3-jg"},[_c('div',{staticClass:"centermain-row3-lable",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.KH_T12SP,
                '均热烟温',
                'KH_T12SP',
                _vm.infoList.KH_T12SP_node_string
              )}}},[_vm._v(" "+_vm._s('KH_T12SP' in _vm.infoList?_vm.infoList.KH_T12SP :0)+" ")]),_c('div',{staticClass:"centermain-row3-lable1"},[_vm._v("均热烟温")])]),_c('div',{staticClass:"fl centermain-row3 centermain-row3-jg"},[_c('div',{staticClass:"centermain-row3-lable",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.KH_T22SP,
                '三加烟温',
                'KH_T22SP',
                _vm.infoList.KH_T22SP_node_string
              )}}},[_vm._v(" "+_vm._s('KH_T22SP' in _vm.infoList?_vm.infoList.KH_T22SP :0)+" ")]),_c('div',{staticClass:"centermain-row3-lable1"},[_vm._v("三加烟温")])]),_c('div',{staticClass:"fl centermain-row3 centermain-row3-jg"},[_c('div',{staticClass:"centermain-row3-lable",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.KH_T32SP,
                '二加烟温',
                'KH_T32SP',
                _vm.infoList.KH_T32SP_node_string
              )}}},[_vm._v(" "+_vm._s('KH_T32SP' in _vm.infoList?_vm.infoList.KH_T32SP :0)+" ")]),_c('div',{staticClass:"centermain-row3-lable1"},[_vm._v("二加烟温")])]),_c('div',{staticClass:"fl centermain-row3 centermain-row3-jg"},[_c('div',{staticClass:"centermain-row3-lable",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.KH_T42SP,
                '一加烟温',
                'KH_T42SP',
                _vm.infoList.KH_T42SP_node_string
              )}}},[_vm._v(" "+_vm._s('KH_T42SP' in _vm.infoList?_vm.infoList.KH_T42SP :0)+" ")]),_c('div',{staticClass:"centermain-row3-lable1"},[_vm._v("一加烟温")])])]),_c('div',{staticClass:"centermain-row2 fl centermain-row2-jg"},[_c('div',{staticClass:"fl centermain-row3"},[_c('div',{staticClass:"centermain-row3-lable",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.KH_KQPSP,
                '空气压力',
                'KH_KQPSP',
                _vm.infoList.KH_KQPSP_node_string
              )}}},[_vm._v(" "+_vm._s('KH_KQPSP' in _vm.infoList?_vm.infoList.KH_KQPSP :0)+" ")]),_c('div',{staticClass:"centermain-row3-lable1"},[_vm._v("空气压力")])]),_c('div',{staticClass:"fl centermain-row3 centermain-row3-jg"},[_c('div',{staticClass:"centermain-row3-lable",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.KH_T11SP,
                '均热温度',
                'KH_T11SP',
                _vm.infoList.KH_T11SP_node_string
              )}}},[_vm._v(" "+_vm._s('KH_T11SP' in _vm.infoList?_vm.infoList.KH_T11SP :0)+" ")]),_c('div',{staticClass:"centermain-row3-lable1"},[_vm._v("均热温度")])]),_c('div',{staticClass:"fl centermain-row3 centermain-row3-jg"},[_c('div',{staticClass:"centermain-row3-lable",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.KH_T21SP,
                '三加温度',
                'KH_T21SP',
                _vm.infoList.KH_T21SP_node_string
              )}}},[_vm._v(" "+_vm._s('KH_T21SP' in _vm.infoList?_vm.infoList.KH_T21SP :0)+" ")]),_c('div',{staticClass:"centermain-row3-lable1"},[_vm._v("三加温度")])]),_c('div',{staticClass:"fl centermain-row3 centermain-row3-jg"},[_c('div',{staticClass:"centermain-row3-lable",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.KH_T31SP,
                '二加温度',
                'KH_T31SP',
                _vm.infoList.KH_T31SP_node_string
              )}}},[_vm._v(" "+_vm._s('KH_T31SP' in _vm.infoList?_vm.infoList.KH_T31SP :0)+" ")]),_c('div',{staticClass:"centermain-row3-lable1"},[_vm._v("二加温度")])]),_c('div',{staticClass:"fl centermain-row3 centermain-row3-jg"},[_c('div',{staticClass:"centermain-row3-lable",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.KH_T41SP,
                '一加温度',
                'KH_T41SP',
                _vm.infoList.KH_T41SP_node_string
              )}}},[_vm._v(" "+_vm._s('KH_T41SP' in _vm.infoList?_vm.infoList.KH_T41SP :0)+" ")]),_c('div',{staticClass:"centermain-row3-lable1"},[_vm._v("一加温度")])]),_c('div',{staticClass:"fl centermain-row3 centermain-row3-jg"},[_c('div',{staticClass:"centermain-row3-lable",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.KHTRZ,
                '热装温度',
                'KHTRZ',
                _vm.infoList.KHTRZ_node_string
              )}}},[_vm._v(" "+_vm._s('KHTRZ' in _vm.infoList?_vm.infoList.KHTRZ :0)+" ")]),_c('div',{staticClass:"centermain-row3-lable1"},[_vm._v("热装温度")])])])])]),_c('div',{staticClass:"fl righttmain"},[_c('div',{staticClass:"rightmain-top"},[_c('div',{staticClass:"centermain-row1"},[_vm._v("自控率%")]),_c('div',{staticClass:"centermain-top2"},[_c('div',{staticClass:"centermain-top2-col1 fl"},[_vm._v("煤气压力")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL9',
              _vm.infoList.ZKL9_node_string,
              'ZKL9_J1',
              '煤气总管压力自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL9,
              'ZKL9',
              _vm.infoList.ZKL9_node_string,
              'ZKL9_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL9' in _vm.infoList?_vm.infoList.ZKL9 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.EN9, '煤气压力', 'EN9', _vm.infoList.EN9_node_string)}}},[_vm._v(" "+_vm._s('EN9' in _vm.infoList?_vm.infoList.EN9 ? "统计使能" : "统计切除" :0)+" ")]),_c('div',{staticClass:"centermain-top2-col2 fl"},[_vm._v("三加煤烟")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL4',
              _vm.infoList.ZKL4_node_string,
              'ZKL4_J1',
              '三加热段煤烟自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL4,
              'ZKL4',
              _vm.infoList.ZKL4_node_string,
              'ZKL4_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL4' in _vm.infoList?_vm.infoList.ZKL4 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.EN4, '三加煤烟', 'EN4', _vm.infoList.EN4_node_string)}}},[_vm._v(" "+_vm._s('EN4' in _vm.infoList?_vm.infoList.EN4 ? "统计使能" : "统计切除" :0)+" ")])]),_c('div',{staticClass:"centermain-top2"},[_c('div',{staticClass:"centermain-top2-col1 fl"},[_vm._v("空气压力")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL10',
              _vm.infoList.ZKL10_node_string,
              'ZKL10_J1',
              '鼓风自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL10,
              'ZKL10',
              _vm.infoList.ZKL10_node_string,
              'ZKL10_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL10' in _vm.infoList?_vm.infoList.ZKL10 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.EN10,
              '空气压力',
              'EN10',
              _vm.infoList.EN10_node_string
            )}}},[_vm._v(" "+_vm._s('EN10' in _vm.infoList?_vm.infoList.EN10 ? "统计使能" : "统计切除" :0)+" ")]),_c('div',{staticClass:"centermain-top2-col2 fl"},[_vm._v("二加空气")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL31',
              _vm.infoList.ZKL31_node_string,
              'ZKL31_J1',
              '二加热段空气自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL31,
              'ZKL31',
              _vm.infoList.ZKL31_node_string,
              'ZKL31_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL31' in _vm.infoList?_vm.infoList.ZKL31 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.EN31,
              '二加空气',
              'EN31',
              _vm.infoList.EN31_node_string
            )}}},[_vm._v(" "+_vm._s('EN31' in _vm.infoList?_vm.infoList.EN31 ? "统计使能" : "统计切除" :0)+" ")])]),_c('div',{staticClass:"centermain-top2"},[_c('div',{staticClass:"centermain-top2-col1 fl"},[_vm._v("炉膛压力")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL20',
              _vm.infoList.ZKL20_node_string,
              'ZKL20_J1',
              '炉膛压力自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL20,
              'ZKL20',
              _vm.infoList.ZKL20_node_string,
              'ZKL20_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL20' in _vm.infoList?_vm.infoList.ZKL20 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.EN20,
              '炉膛压力',
              'EN20',
              _vm.infoList.EN20_node_string
            )}}},[_vm._v(" "+_vm._s('EN20' in _vm.infoList?_vm.infoList.EN20 ? "统计使能" : "统计切除" :0)+" ")]),_c('div',{staticClass:"centermain-top2-col2 fl"},[_vm._v("二加煤气")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL32',
              _vm.infoList.ZKL32_node_string,
              'ZKL32_J1',
              '二加热段煤气自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL32,
              'ZKL32',
              _vm.infoList.ZKL32_node_string,
              'ZKL32_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL32' in _vm.infoList?_vm.infoList.ZKL32 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.EN32,
              '二加煤气',
              'EN32',
              _vm.infoList.EN32_node_string
            )}}},[_vm._v(" "+_vm._s('EN32' in _vm.infoList?_vm.infoList.EN32 ? "统计使能" : "统计切除" :0)+" ")])]),_c('div',{staticClass:"centermain-top2"},[_c('div',{staticClass:"centermain-top2-col1 fl"},[_vm._v("均热空气")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL11',
              _vm.infoList.ZKL11_node_string,
              'ZKL11_J1',
              '均热段空气自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL11,
              'ZKL11',
              _vm.infoList.ZKL11_node_string,
              'ZKL11_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL11' in _vm.infoList?_vm.infoList.ZKL11 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.EN11,
              '均热空气',
              'EN11',
              _vm.infoList.EN11_node_string
            )}}},[_vm._v(" "+_vm._s('EN11' in _vm.infoList?_vm.infoList.EN11 ? "统计使能" : "统计切除" :0)+" ")]),_c('div',{staticClass:"centermain-top2-col2 fl"},[_vm._v("二加空烟")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL5',
              _vm.infoList.ZKL5_node_string,
              'ZKL5_J1',
              '二加热段空烟自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL5,
              'ZKL5',
              _vm.infoList.ZKL5_node_string,
              'ZKL5_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL5' in _vm.infoList?_vm.infoList.ZKL5 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.EN5, '二加空烟', 'EN5', _vm.infoList.EN5_node_string)}}},[_vm._v(" "+_vm._s('EN5' in _vm.infoList?_vm.infoList.EN5 ? "统计使能" : "统计切除" :0)+" ")])]),_c('div',{staticClass:"centermain-top2"},[_c('div',{staticClass:"centermain-top2-col1 fl"},[_vm._v("均热煤气")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL12',
              _vm.infoList.ZKL12_node_string,
              'ZKL12_J1',
              '均热段煤气自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL12,
              'ZKL12',
              _vm.infoList.ZKL12_node_string,
              'ZKL12_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL12' in _vm.infoList?_vm.infoList.ZKL12 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.EN12,
              '均热煤气',
              'EN12',
              _vm.infoList.EN12_node_string
            )}}},[_vm._v(" "+_vm._s('EN12' in _vm.infoList?_vm.infoList.EN12 ? "统计使能" : "统计切除" :0)+" ")]),_c('div',{staticClass:"centermain-top2-col2 fl"},[_vm._v("二加煤烟")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL6',
              _vm.infoList.ZKL6_node_string,
              'ZKL6_J1',
              '二加热段煤烟自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL6,
              'ZKL6',
              _vm.infoList.ZKL6_node_string,
              'ZKL6_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL6' in _vm.infoList?_vm.infoList.ZKL6 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.EN6, '二加煤烟', 'EN6', _vm.infoList.EN6_node_string)}}},[_vm._v(" "+_vm._s('EN6' in _vm.infoList?_vm.infoList.EN6 ? "统计使能" : "统计切除" :0)+" ")])]),_c('div',{staticClass:"centermain-top2"},[_c('div',{staticClass:"centermain-top2-col1 fl"},[_vm._v("均热空烟")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL1',
              _vm.infoList.ZKL1_node_string,
              'ZKL1_J1',
              '均热段空烟自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL1,
              'ZKL1',
              _vm.infoList.ZKL1_node_string,
              'ZKL1_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL1' in _vm.infoList?_vm.infoList.ZKL1 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.EN1, '均热空烟', 'EN1', _vm.infoList.EN1_node_string)}}},[_vm._v(" "+_vm._s('EN1' in _vm.infoList?_vm.infoList.EN1 ? "统计使能" : "统计切除" :0)+" ")]),_c('div',{staticClass:"centermain-top2-col2 fl"},[_vm._v("一加空气")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL41',
              _vm.infoList.ZKL41_node_string,
              'ZKL41_J1',
              '一加热段空气自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL41,
              'ZKL41',
              _vm.infoList.ZKL41_node_string,
              'ZKL41_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL41' in _vm.infoList?_vm.infoList.ZKL41 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.EN41,
              '一加空气',
              'EN41',
              _vm.infoList.EN41_node_string
            )}}},[_vm._v(" "+_vm._s('EN41' in _vm.infoList?_vm.infoList.EN41 ? "统计使能" : "统计切除" :0)+" ")])]),_c('div',{staticClass:"centermain-top2"},[_c('div',{staticClass:"centermain-top2-col1 fl"},[_vm._v("均热煤烟")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL2',
              _vm.infoList.ZKL2_node_string,
              'ZKL2_J1',
              '均热段煤烟自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL2,
              'ZKL2',
              _vm.infoList.ZKL2_node_string,
              'ZKL2_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL2' in _vm.infoList?_vm.infoList.ZKL2 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.EN2, '均热煤烟', 'EN2', _vm.infoList.EN2_node_string)}}},[_vm._v(" "+_vm._s('EN2' in _vm.infoList?_vm.infoList.EN2 ? "统计使能" : "统计切除" :0)+" ")]),_c('div',{staticClass:"centermain-top2-col2 fl"},[_vm._v("一加煤气")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL42',
              _vm.infoList.ZKL42_node_string,
              'ZKL42_J1',
              '一加热段煤气自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL42,
              'ZKL42',
              _vm.infoList.ZKL42_node_string,
              'ZKL42_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL42' in _vm.infoList?_vm.infoList.ZKL42 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.EN42,
              '一加煤气',
              'EN42',
              _vm.infoList.EN42_node_string
            )}}},[_vm._v(" "+_vm._s('EN42' in _vm.infoList?_vm.infoList.EN42 ? "统计使能" : "统计切除" :0)+" ")])]),_c('div',{staticClass:"centermain-top2"},[_c('div',{staticClass:"centermain-top2-col1 fl"},[_vm._v("三加空气")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL21',
              _vm.infoList.ZKL21_node_string,
              'ZKL21_J1',
              '三加热段空气自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL21,
              'ZKL21',
              _vm.infoList.ZKL21_node_string,
              'ZKL21_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL21' in _vm.infoList?_vm.infoList.ZKL21 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.EN21,
              '三加空气',
              'EN21',
              _vm.infoList.EN21_node_string
            )}}},[_vm._v(" "+_vm._s('EN21' in _vm.infoList?_vm.infoList.EN21 ? "统计使能" : "统计切除" :0)+" ")]),_c('div',{staticClass:"centermain-top2-col2 fl"},[_vm._v("一加空烟")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL7',
              _vm.infoList.ZKL7_node_string,
              'ZKL7_J1',
              '一加热段空烟自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL7,
              'ZKL7',
              _vm.infoList.ZKL7_node_string,
              'ZKL7_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL7' in _vm.infoList?_vm.infoList.ZKL7 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.EN7, '一加空烟', 'EN7', _vm.infoList.EN7_node_string)}}},[_vm._v(" "+_vm._s('EN7' in _vm.infoList?_vm.infoList.EN7 ? "统计使能" : "统计切除" :0)+" ")])]),_c('div',{staticClass:"centermain-top2"},[_c('div',{staticClass:"centermain-top2-col1 fl"},[_vm._v("三加煤气")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL22',
              _vm.infoList.ZKL22_node_string,
              'ZKL22_J1',
              '三加热段煤气自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL22,
              'ZKL22',
              _vm.infoList.ZKL22_node_string,
              'ZKL22_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL22' in _vm.infoList?_vm.infoList.ZKL22 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(
              _vm.infoList.EN22,
              '三加煤气',
              'EN22',
              _vm.infoList.EN22_node_string
            )}}},[_vm._v(" "+_vm._s('EN22' in _vm.infoList?_vm.infoList.EN22 ? "统计使能" : "统计切除" :0)+" ")]),_c('div',{staticClass:"centermain-top2-col2 fl"},[_vm._v("一加煤烟")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL8',
              _vm.infoList.ZKL8_node_string,
              'ZKL8_J1',
              '一加热段煤烟自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL8,
              'ZKL8',
              _vm.infoList.ZKL8_node_string,
              'ZKL8_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL8' in _vm.infoList?_vm.infoList.ZKL8 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.EN8, '一加煤烟', 'EN8', _vm.infoList.EN8_node_string)}}},[_vm._v(" "+_vm._s('EN8' in _vm.infoList?_vm.infoList.EN8 ? "统计使能" : "统计切除" :0)+" ")])]),_c('div',{staticClass:"centermain-top2"},[_c('div',{staticClass:"centermain-top2-col1 fl"},[_vm._v("三加空烟")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZKL3',
              _vm.infoList.ZKL3_node_string,
              'ZKL3_J1',
              '三加热段空烟自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZKL3,
              'ZKL3',
              _vm.infoList.ZKL3_node_string,
              'ZKL3_J1'
            )}}},[_vm._v(" "+_vm._s('ZKL3' in _vm.infoList?_vm.infoList.ZKL3 :0)+" ")]),_c('div',{staticClass:"centermain-top2-col1 fl",on:{"click":function($event){return _vm.toIpt(_vm.infoList.EN3, '三加空烟', 'EN3', _vm.infoList.EN3_node_string)}}},[_vm._v(" "+_vm._s('EN3' in _vm.infoList?_vm.infoList.EN3 ? "统计使能" : "统计切除" :0)+" ")])])]),_c('div',{staticClass:"rightmain-center"},[_c('div',{staticClass:"centermain-row1"},[_vm._v("综合统计参数")]),_c('div',{staticClass:"center1 fl"}),_c('div',{staticClass:"center2 fl"},[_c('div',{staticClass:"lable11",on:{"click":function($event){return _vm.toCompon(
              2,
              'ZZKL',
              _vm.infoList.ZZKL_node_string,
              'ZZKL_J1',
              '总自控率'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.ZZKL,
              'ZZKL',
              _vm.infoList.ZZKL_node_string,
              'ZZKL_J1'
            )}}},[_vm._v(" "+_vm._s('ZZKL' in _vm.infoList?_vm.infoList.ZZKL :0)+" ")]),_c('div',[_vm._v("总自控率")])]),_c('div',{staticClass:"center3 fl"},[_c('div',{staticClass:"lable22",on:{"click":function($event){return _vm.toCompon(
              2,
              'CEI_LEVEL',
              _vm.infoList.CEI_LEVEL_node_string,
              'CEI_PV_J1',
              '综合运行评价指标'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.CEI_LEVEL,
              'CEI_LEVEL',
              _vm.infoList.CEI_LEVEL_node_string,
              'CEI_PV_J1'
            )}}},[_vm._v(" "+_vm._s('CEI_LEVEL' in _vm.infoList?_vm.infoList.CEI_LEVEL :0)+" ")]),_c('div',[_vm._v("综合性能指标")])])]),_c('div',{staticClass:"rightmain-bottom"},[_c('div',{staticClass:"centermain-row1"},[_vm._v("数据统计")]),_c('div',{staticClass:"centermain-xxx1"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"centermain-row2-wid"},[_vm._v("经济性")]),_c('div',{staticClass:"centermain-row2-wid",on:{"click":function($event){return _vm.toCompon(
                2,
                'KHJJXB',
                _vm.infoList.KHJJXB_node_string,
                'KHJJXB',
                '经济性判据'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KHJJXB,
                'KHJJXB',
                _vm.infoList.KHJJXB_node_string,
                'KHJJXB'
              )}}},[_vm._v(" "+_vm._s('KHJJXB' in _vm.infoList?_vm.infoList.KHJJXB :0)+" ")])]),_c('div',{staticClass:"fl jgx"},[_c('div',{staticClass:"centermain-row2-wid"},[_vm._v("运行效果")]),_c('div',{staticClass:"centermain-row2-wid",on:{"click":function($event){return _vm.toCompon(
                2,
                'KHYXXGPJ',
                _vm.infoList.KHYXXGPJ_node_string,
                'KHYXXGPJ',
                '运行效果综合判据'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.KHYXXGPJ,
                'KHYXXGPJ',
                _vm.infoList.KHYXXGPJ_node_string,
                'KHYXXGPJ'
              )}}},[_vm._v(" "+_vm._s('KHYXXGPJ' in _vm.infoList?_vm.infoList.KHYXXGPJ :0)+" ")])]),_c('div',{staticClass:"fl jgx"},[_c('div',{staticClass:"centermain-row2-wid"},[_vm._v("煤气时间单耗")]),_c('div',{staticClass:"centermain-row2-wid",on:{"click":function($event){return _vm.toCompon(
                2,
                'MQSJDH',
                _vm.infoList.MQSJDH_node_string,
                'MQSJDH',
                '煤气时间单耗'
              )},"dblclick":function($event){return _vm.Cclick(
                _vm.infoList.MQSJDH,
                'MQSJDH',
                _vm.infoList.MQSJDH_node_string,
                'MQSJDH'
              )}}},[_vm._v(" "+_vm._s('MQSJDH' in _vm.infoList?_vm.infoList.MQSJDH :0)+" ")])])]),_c('div',{staticClass:"centermain-xxx2"},[_c('div',{staticClass:"fl lable33"},[_vm._v("班清零时间1：")]),_c('div',{staticClass:"fl lable33"},[_c('div',{staticClass:"fl kdwd",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.BQL_H1,
                '小时',
                'BQL_H1',
                _vm.infoList.BQL_H1_node_string
              )}}},[_vm._v(" "+_vm._s('BQL_H1' in _vm.infoList?_vm.infoList.BQL_H1 :0)+": ")]),_c('div',{staticClass:"fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.BQL_M1,
                '分钟',
                'BQL_M1',
                _vm.infoList.BQL_M1_node_string
              )}}},[_vm._v(" "+_vm._s('BQL_M1' in _vm.infoList?_vm.infoList.BQL_M1 :0)+" ")])]),_c('div',{staticClass:"fl lable33"},[_vm._v("班清零时间2：")]),_c('div',{staticClass:"fl lable33"},[_c('div',{staticClass:"fl kdwd",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.BQL_H2,
                '小时',
                'BQL_H2',
                _vm.infoList.BQL_H2_node_string
              )}}},[_vm._v(" "+_vm._s('BQL_H2' in _vm.infoList?_vm.infoList.BQL_H2 :0)+": ")]),_c('div',{staticClass:"fl",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.BQL_M2,
                '分钟',
                'BQL_M2',
                _vm.infoList.BQL_M2_node_string
              )}}},[_vm._v(" "+_vm._s('BQL_M2' in _vm.infoList?_vm.infoList.BQL_M2 :0)+" ")])])])])]),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e(),_c('inputVal',{ref:"inputVal"}),(_vm.flag == 4 ? (_vm.isComShow = true) : (_vm.isComShow = false))?_c('JRLcysz',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leftmain-row2"},[_c('div',{staticClass:"leftmain-row2-col fl"},[_vm._v("序号")]),_c('div',{staticClass:"leftmain-row2-col1 fl",staticStyle:{"padding-left":"1vw"}},[_vm._v(" 班运行 ")]),_c('div',{staticClass:"leftmain-row2-col1 fl"},[_vm._v("天运行")]),_c('div',{staticClass:"leftmain-row2-col1 fl"},[_vm._v("月运行")]),_c('div',{staticClass:"leftmain-row2-col1 fl"},[_vm._v("自定义运行")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"center-main-top-col"},[_c('div',{staticClass:"center-main-top1 fl"},[_vm._v("等待考核")]),_c('div',{staticClass:"center-main-top2 fl"},[_vm._v("自定义考核")]),_c('div',{staticClass:"center-main-top3 fl"},[_vm._v("结束考核")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fl center-main-top1-col"},[_c('div',{staticClass:"col1"},[_vm._v("考核开始时间")]),_c('div',{staticClass:"col2"}),_c('div',{staticClass:"col1"},[_vm._v("考核结束时间")]),_c('div',{staticClass:"col2"})])}]

export { render, staticRenderFns }