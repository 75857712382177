import Config from "@/utils/config";
import { mapGetters } from "vuex";

export default {
  name: "index",
  components: {},
  data: () => {
    return {
      code: "",
      timer: null,
      loadingAd: true,
      loadingFloor: true,
      isMshow: false,
      isFshow: false,
      isPshow: false,
      isPshow01: false,
      isPshow11: false,
      isPshow21: false,
      isPshow31: false,
      isPshow41: false,
      isIndex: "",
      info: {
        code: "",
        device: "",
      },
      isComShow: false,
      deviceType: {},
      authInfo: [],
      UserInfo: [],
      maskbok: false,
    };
  },
  watch: {},
  created() {
    this.deviceType = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {},
  methods: {
    DataJson(data) {
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        const newurl = this.$router.resolve({
          name: "firstcontolRFL",
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 打开手操器组件
    toCompon(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1] && key != 2) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        this.isIndex = key;
        this.Aname = Aname;
        (this.historyname = name), (this.nodename = nodename);
        clearTimeout(this.grouptime);

        this.grouptime = setTimeout(() => {
          this.maskbok = true;
          switch (key) {
            case 0:
              return (
                (this.isMshow = true),
                (this.Manualname = name),
                (this.Manualnode = nodename),
                (this.ManualAname = Aname),
                (this.Manualtitname = titname)
              );
            case 1:
              return (
                (this.isFshow = true),
                (this.Firstcontrolname = name),
                (this.Firstcontrolnode = nodename),
                (this.Firstcontroltitname = titname)
              );
            case 2:
              return (
                (this.isHshow = true),
                (this.chName = titname),
                (this.maskbok = false)
              );
            case 3:
              return (
                (this.isOshow = true),
                (this.Observername = name),
                (this.Observernode = nodename)
              );
            case 4:
              return (
                (this.isRshow = true),
                (this.Rsfname = name),
                (this.Rsfnode = nodename)
              );
            case 5:
              return (this.isPshow = true);
            case 6:
              return (this.isPshow01 = true);
            case 7:
              return (this.isPshow11 = true);
            case 8:
              return (this.isPshow21 = true);
            case 9:
              return (this.isPshow31 = true);
            case 10:
              return (this.isPshow41 = true);
          }
        }, 300);
      }
    },
    isClose(val, val1) {
      this.maskbok = false;
      switch (val) {
        case "Manual":
          return (this.isMshow = val1);
        case "Evaluate":
        case this.flag == 2:
          return (this.flag = 0), this.$bus.$emit("footersendMsg", 0);
        case "Security":
        case this.flag == 1:
          return (this.flag = 0), this.$bus.$emit("footersendMsg", 0);
        case "Switchpage":
        case this.flag == 3:
          return (this.flag = 0), this.$bus.$emit("footersendMsg", 0);
        case "Firstcontrol":
          return (this.isFshow = val1);
        case "Historical":
          return (this.isHshow = val1);
        case "Observer":
          return (this.isOshow = val1);
        case "Rsf":
          return (this.isRshow = val1);
        case "param":
          return (this.isPshow = val1);
        case "Param01":
          return (this.isPshow01 = val1);
        case "param11":
          return (this.isPshow11 = val1);
        case "param21":
          return (this.isPshow21 = val1);
        case "param31":
          return (this.isPshow31 = val1);
        case "param41":
          return (this.isPshow41 = val1);
        case "JrSecurity":
          return (this.JrSecurity = val1);
        case "Jrconst":
          return (this.Jrconst = val1);
      }
      // if(val = 'Manual'){
      // 	this.isMshow = val1
      // }
    },
    // 获取燃烧流程页面接口
    // 监听滚动条
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (scrollTop >= 680) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    destroyed() {
      // 离开该页面需要移除这个监听的事件，不然会报错
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
