var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"jrlmyzg drag",attrs:{"id":"jrlmyzg"}},[_c('div',[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(_vm._s(_vm.projectData.project)+"_参数04")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"lable"},[_vm._v("均热段煤烟控制模型")]),_c('div',{staticClass:"leftrow"},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.JRDMPTSP,
                  'A01-TSP',
                  'JRDMPTSP',
                  _vm.infoList.JRDMPTSP_node_string
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.JRDMPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'T12PV2',
                  _vm.infoList.T12PV2_node_string,
                  'T12PV2_J1',
                  '均热段煤烟温度处理值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.T12PV2,
                  'T12PV2',
                  _vm.infoList.T12PV2_node_string,
                  'T12PV2_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.T12PV2)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.T12SEL1,
                '测量方式',
                'T12SEL1',
                _vm.infoList.T12SEL1_node_string
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.T12SEL1 ? "段最大值" : "单一温度")+" ")])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1',
                    '负压空废煤废温度补偿'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.RSF3.AV,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.QK10_node_string,
                    'QK10_AV_J1',
                    '均热段煤烟煤气阀门前馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.QK10.AV,
                    'AV',
                    _vm.infoList.QK10_node_string,
                    'QK10_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.QK10.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx",style:({
                background:
                  _vm.infoList.XK2 && !_vm.infoList.XK2.XK2 ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK2
                  ? _vm.toDetail(
                      1,
                      'XK2',
                      _vm.infoList.XK2_node_string,
                      '',
                      '均热段煤烟温度控制XK'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"texlable",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.FYSP_L,
                  'FYSP_L',
                  'FYSP_L',
                  _vm.infoList.FYSP_L_node_string
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.FYSP_L)+" Pa ")]),_c('div',{staticClass:"bottonx mar-jg3",style:({
                background: !_vm.infoList.RSF3.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF3
                  ? _vm.toDetail(
                      3,
                      'RSF3',
                      _vm.infoList.RSF3_node_string,
                      '',
                      '负压空废煤废温度补偿'
                    )
                  : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx mar-jg1",style:({
                background:
                  _vm.infoList.QK10 && !_vm.infoList.QK10.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK10
                  ? _vm.toDetail(
                      2,
                      'QK10',
                      _vm.infoList.QK10_node_string,
                      '',
                      '均热段煤烟煤气阀门前馈'
                    )
                  : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx",style:({
              background:
                _vm.infoList.MAN4 && _vm.infoList.MAN4.RM == 1 ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN4
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN4',
                    'MAN4_RM_J1',
                    '均热段煤烟气阀门手操器'
                  )
                : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.XK2_node_string,
                  'XK2_AV_J1',
                  '均热段煤烟温度控制XK'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.XK2.AV,
                  'AV',
                  _vm.infoList.XK2_node_string,
                  'XK2_AV_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.XK2.AV)+" ")])])])])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"lable"},[_vm._v("均热段空烟控制模型")]),_c('div',{staticClass:"leftrow"},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.JRDKPTSP,
                  'A01-TSP',
                  'JRDKPTSP',
                  _vm.infoList.JRDKPTSP_node_string
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.JRDKPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'T12PV1',
                  _vm.infoList.T12PV1_node_string,
                  'T12PV1_J1',
                  '均热段空烟温度处理值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.T12PV1,
                  'T12PV1',
                  _vm.infoList.T12PV1_node_string,
                  'T12PV1_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.T12PV1)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.T12SEL1,
                '测量方式',
                'T12SEL1',
                _vm.infoList.T12SEL1_node_string
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.T12SEL1 ? "段最大值" : "单一温度")+" ")])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1',
                    '负压空废煤废温度补偿'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.RSF3.AV,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.QK9_node_string,
                    'QK9_AV_J1',
                    '均热段空烟空气阀门前馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.QK9.AV,
                    'AV',
                    _vm.infoList.QK9_node_string,
                    'QK9_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.QK9.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx",style:({
                background:
                  _vm.infoList.XK3 && !_vm.infoList.XK3.XK3 ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK3
                  ? _vm.toDetail(
                      1,
                      'XK3',
                      _vm.infoList.XK3_node_string,
                      '',
                      '均热段空烟温度控制XK'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"texlable",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.FYSP_L,
                  'FYSP_L',
                  'FYSP_L',
                  _vm.infoList.FYSP_L_node_string
                )}}}),_c('div',{staticClass:"bottonx mar-jg33",style:({
                background: !_vm.infoList.RSF3.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF3
                  ? _vm.toDetail(
                      3,
                      'RSF3',
                      _vm.infoList.RSF3_node_string,
                      '',
                      '负压空废煤废温度补偿'
                    )
                  : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx mar-jg1",style:({
                background:
                  _vm.infoList.QK9 && !_vm.infoList.QK9.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK9
                  ? _vm.toDetail(
                      2,
                      'QK9',
                      _vm.infoList.QK9_node_string,
                      '',
                      '均热段空烟空气阀门前馈'
                    )
                  : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx",style:({
              background:
                _vm.infoList.MAN3 && _vm.infoList.MAN3.RM == 1 ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN3
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN3',
                    'MAN3_RM_J1',
                    '均热段空烟气阀门手操器'
                  )
                : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.XK3_node_string,
                  'XK3_AV_J1',
                  '均热段空烟温度控制XK'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.XK3.AV,
                  'AV',
                  _vm.infoList.XK3_node_string,
                  'XK3_AV_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.XK3.AV)+" ")])])])])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"lable"},[_vm._v("三加热段煤烟控制模型")]),_c('div',{staticClass:"leftrow"},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.SJRMPTSP,
                  'A01-TSP',
                  'SJRMPTSP',
                  _vm.infoList.SJRMPTSP_node_string
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.SJRMPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'T22PV2',
                  _vm.infoList.T22PV2_node_string,
                  'T22PV2_J1',
                  '三加热段煤烟温度处理值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.T22PV2,

                  'T22PV2',
                  _vm.infoList.T22PV2_node_string,
                  'T22PV2_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.T22PV2)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.T22SEL1,
                '测量方式',
                'T22SEL1',
                _vm.infoList.T22SEL1_node_string
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.T22SEL1 ? "段最大值" : "单一温度")+" ")])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1',
                    '负压空废煤废温度补偿'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.RSF3.AV,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.QK12_node_string,
                    'QK12_AV_J1',
                    '三加热煤烟煤气阀门前馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.QK12.AV,
                    'AV',
                    _vm.infoList.QK12_node_string,
                    'QK12_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.QK12.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx",style:({
                background:
                  _vm.infoList.XK5 && !_vm.infoList.XK5.XK5 ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK5
                  ? _vm.toDetail(
                      1,
                      'XK5',
                      _vm.infoList.XK5_node_string,
                      '',
                      '三加热段煤烟温度控制XK'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx mar-jg33",style:({
                background: !_vm.infoList.RSF3.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF3
                  ? _vm.toDetail(
                      3,
                      'RSF3',
                      _vm.infoList.RSF3_node_string,
                      '',
                      '负压空废煤废温度补偿'
                    )
                  : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx mar-jg1",style:({
                background:
                  _vm.infoList.QK12 && !_vm.infoList.QK12.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK12
                  ? _vm.toDetail(
                      2,
                      'QK12',
                      _vm.infoList.QK12_node_string,
                      '',
                      '三加热煤烟煤气阀门前馈'
                    )
                  : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx",style:({
              background:
                _vm.infoList.MAN8 && _vm.infoList.MAN8.RM == 1 ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN8
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN8',
                    'MAN8_RM_J1',
                    '三热段煤烟气阀门手操器'
                  )
                : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.XK5_node_string,
                  'XK5_AV_J1',
                  '三加热段煤烟温度控制XK'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.XK5.AV,
                  'AV',
                  _vm.infoList.XK5_node_string,
                  'XK5_AV_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.XK5.AV)+" ")])])])])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"lable"},[_vm._v("三加热段空烟控制模型")]),_c('div',{staticClass:"leftrow"},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.SJRKPTSP,
                  'A01-TSP',
                  'SJRKPTSP',
                  _vm.infoList.SJRKPTSP_node_string
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.SJRKPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'T22PV1',
                  _vm.infoList.T22PV1_node_string,
                  'T22PV1_J1',
                  '三加热段空烟温度处理值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.T22PV1,

                  'T22PV1',
                  _vm.infoList.T22PV1_node_string,
                  'T22PV1_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.T22PV1)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.T22SEL1,
                '测量方式',
                'T22SEL1',
                _vm.infoList.T22SEL1_node_string
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.T22SEL1 ? "段最大值" : "单一温度")+" ")])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1',
                    '负压空废煤废温度补偿'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.RSF3.AV,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.QK11_node_string,
                    'QK11_AV_J1',
                    '三加热空烟空气阀门前馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.QK11.AV,
                    'AV',
                    _vm.infoList.QK11_node_string,
                    'QK11_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.QK11.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx",style:({
                background:
                  _vm.infoList.XK6 && !_vm.infoList.XK6.XK6 ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK6
                  ? _vm.toDetail(
                      1,
                      'XK6',
                      _vm.infoList.XK6_node_string,
                      '',
                      '三加热段空烟温度控制XK'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx mar-jg33",style:({
                background: !_vm.infoList.RSF3.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF3
                  ? _vm.toDetail(
                      3,
                      'RSF3',
                      _vm.infoList.RSF3_node_string,
                      '',
                      '负压空废煤废温度补偿'
                    )
                  : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx mar-jg1",style:({
                background:
                  _vm.infoList.QK11 && !_vm.infoList.QK11.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK11
                  ? _vm.toDetail(
                      2,
                      'QK11',
                      _vm.infoList.QK11_node_string,
                      '',
                      '三加热段空烟空气阀门前馈'
                    )
                  : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx",style:({
              background:
                _vm.infoList.MAN7 && _vm.infoList.MAN7.RM == 1 ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN7
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN7',
                    'MAN7_RM_J1',
                    '三热段空烟气阀门手操器'
                  )
                : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.XK6_node_string,
                  'XK6_AV_J1',
                  '三加热段空烟温度控制XK'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.XK6.AV,
                  'AV',

                  _vm.infoList.XK6_node_string,
                  'XK6_AV_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.XK6.AV)+" ")])])])])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"lable"},[_vm._v("二加热段煤烟控制模型")]),_c('div',{staticClass:"leftrow"},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.EJRMPTSP,
                  'A01-TSP',
                  'EJRMPTSP',
                  _vm.infoList.EJRMPTSP_node_string
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.EJRMPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'T32PV2',
                  _vm.infoList.T32PV2_node_string,
                  'T32PV2_J1',
                  '二加热段煤烟温度处理值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.T32PV2,

                  'T32PV2',
                  _vm.infoList.T32PV2_node_string,
                  'T32PV2_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.T32PV2)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.T32SEL1,
                '测量方式',
                'T32SEL1',
                _vm.infoList.T32SEL1_node_string
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.T32SEL1 ? "段最大值" : "单一温度")+" ")])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1',
                    '负压空废煤废温度补偿'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.RSF3.AV,

                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.QK14_node_string,
                    'QK14_AV_J1',
                    '二加热煤烟煤气阀门前馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.QK14.AV,
                    'AV',
                    _vm.infoList.QK14_node_string,
                    'QK14_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.QK14.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx",style:({
                background:
                  _vm.infoList.XK8 && !_vm.infoList.XK8.XK8 ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK8
                  ? _vm.toDetail(
                      1,
                      'XK8',
                      _vm.infoList.XK8_node_string,
                      '',
                      '二加热段煤烟温度控制XK'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx mar-jg33",style:({
                background: !_vm.infoList.RSF3.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF3
                  ? _vm.toDetail(
                      3,
                      'RSF3',
                      _vm.infoList.RSF3_node_string,
                      '',
                      '负压空废煤废温度补偿'
                    )
                  : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx mar-jg1",style:({
                background:
                  _vm.infoList.QK14 && !_vm.infoList.QK14.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK14
                  ? _vm.toDetail(
                      2,
                      'QK14',
                      _vm.infoList.QK14_node_string,
                      '',
                      '二加热煤烟煤气阀门前馈'
                    )
                  : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx",style:({
              background:
                _vm.infoList.MAN12 && _vm.infoList.MAN12.RM == 1 ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN12
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN12',
                    'MAN12_RM_J1',
                    '二热段煤烟气阀门手操器'
                  )
                : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.XK8_node_string,
                  'XK8_AV_J1',
                  '二加热段煤烟温度控制XK'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.XK8.AV,
                  'AV',
                  _vm.infoList.XK8_node_string,
                  'XK8_AV_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.XK8.AV)+" ")])])])])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"lable"},[_vm._v("二加热段空烟控制模型")]),_c('div',{staticClass:"leftrow"},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.EJRKPTSP,
                  'A01-TSP',
                  'EJRKPTSP',
                  _vm.infoList.EJRKPTSP_node_string
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.EJRKPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'T32PV1',
                  _vm.infoList.T32PV1_node_string,
                  'T32PV1_J1',
                  '二加热段空烟温度处理值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.T32PV1,
                  'T32PV1',
                  _vm.infoList.T32PV1_node_string,
                  'T32PV1_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.T32PV1)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.T32SEL1,
                '测量方式',
                'T32SEL1',
                _vm.infoList.T32SEL1_node_string
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.T32SEL1 ? "段最大值" : "单一温度")+" ")])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1',
                    '负压空废煤废温度补偿'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.RSF3.AV,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.QK13_node_string,
                    'QK13_AV_J1',
                    '二加热空烟空气阀门前馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.QK13.AV,
                    'AV',
                    _vm.infoList.QK13_node_string,
                    'QK13_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.QK13.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx",style:({
                background:
                  _vm.infoList.XK9 && !_vm.infoList.XK9.XK9 ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK9
                  ? _vm.toDetail(
                      1,
                      'XK9',
                      _vm.infoList.XK9_node_string,
                      '',
                      '二加热段空烟温度控制XK'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx mar-jg33",style:({
                background: !_vm.infoList.RSF3.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF3
                  ? _vm.toDetail(
                      3,
                      'RSF3',
                      _vm.infoList.RSF3_node_string,
                      '',
                      '负压空废煤废温度补偿'
                    )
                  : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx mar-jg1",style:({
                background:
                  _vm.infoList.QK13 && !_vm.infoList.QK13.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK13
                  ? _vm.toDetail(
                      2,
                      'QK13',
                      _vm.infoList.QK13_node_string,
                      '',
                      '二加热段空烟空气阀门前馈'
                    )
                  : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx",style:({
              background:
                _vm.infoList.MAN11 && _vm.infoList.MAN11.RM == 1 ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN11
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN11',
                    'MAN11_RM_J1',
                    '二热段空烟气阀门手操器'
                  )
                : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.XK9_node_string,
                  'XK9_AV_J1',
                  '二加热段空烟温度控制XK'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.XK9.AV,
                  'AV',
                  _vm.infoList.XK9_node_string,
                  'XK9_AV_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.XK9.AV)+" ")])])])])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"lable"},[_vm._v("一加热段煤烟控制模型")]),_c('div',{staticClass:"leftrow"},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.YJRMPTSP,
                  'A01-TSP',
                  'YJRMPTSP',
                  _vm.infoList.YJRMPTSP_node_string
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.YJRMPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'T42PV2',
                  _vm.infoList.T42PV2_node_string,
                  'T42PV2_J1',
                  '一加热段煤烟温度处理值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.T42PV2,
                  'T42PV2',
                  _vm.infoList.T42PV2_node_string,
                  'T42PV2_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.T42PV2)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.T42SEL1,
                '测量方式',
                'T42SEL1',
                _vm.infoList.T42SEL1_node_string
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.T42SEL1 ? "段最大值" : "单一温度")+" ")])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1',
                    '负压空废煤废温度补偿'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.RSF3.AV,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.QK16_node_string,
                    'QK16_AV_J1',
                    '一加热煤烟煤气阀门前馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.QK16.AV,
                    'AV',
                    _vm.infoList.QK16_node_string,
                    'QK16_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.QK16.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx",style:({
                background:
                  _vm.infoList.XK11 && !_vm.infoList.XK11.XK11 ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK11
                  ? _vm.toDetail(
                      1,
                      'XK11',
                      _vm.infoList.XK11_node_string,
                      '',
                      '一加热段煤烟温度控制XK'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx mar-jg33",style:({
                background: !_vm.infoList.RSF3.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF3
                  ? _vm.toDetail(
                      3,
                      'RSF3',
                      _vm.infoList.RSF3_node_string,
                      '',
                      '负压空废煤废温度补偿'
                    )
                  : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx mar-jg1",style:({
                background:
                  _vm.infoList.QK16 && !_vm.infoList.QK16.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK16
                  ? _vm.toDetail(
                      2,
                      'QK16',
                      _vm.infoList.QK16_node_string,
                      '',
                      '一加热煤烟煤气阀门前馈'
                    )
                  : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx",style:({
              background:
                _vm.infoList.MAN23 && _vm.infoList.MAN23.RM == 1 ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN23
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN23',
                    'MAN23_RM_J1',
                    '一热段煤烟气阀门手操器'
                  )
                : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.XK11_node_string,
                  'XK11_AV_J1',
                  '一加热段煤烟温度控制XK'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.XK11.AV,
                  'AV',
                  _vm.infoList.XK11_node_string,
                  'XK11_AV_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.XK11.AV)+" ")])])])])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"lable"},[_vm._v("一加热段空烟控制模型")]),_c('div',{staticClass:"leftrow"},[_c('div',{staticClass:"fl marleft"},[_c('div',{staticClass:"col1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TSP")]),_c('div',{staticClass:"fl color3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.YJRKPTSP,
                  'A01-TSP',
                  'YJRKPTSP',
                  _vm.infoList.YJRKPTSP_node_string
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.YJRKPTSP)+" ")])]),_c('div',{staticClass:"col2"},[_c('div',{staticClass:"fl color1"},[_vm._v("A01-TPV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'T42PV1',
                  _vm.infoList.T42PV1_node_string,
                  'T42PV1_J1',
                  '一加热空烟温度处理'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.T42PV1,
                  'T42PV1',
                  _vm.infoList.T42PV1_node_string,
                  'T42PV1_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.T42PV1)+" ")])]),_c('div',{staticClass:"col3",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.T42SEL1,
                '测量方式',
                'T42SEL1',
                _vm.infoList.T42SEL1_node_string
              )}}},[_vm._v(" "+_vm._s(_vm.infoList.T42SEL1 ? "段最大值" : "单一温度")+" ")])]),_c('div',{staticClass:"fl xcent"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"col2 martop"},[_c('div',{staticClass:"fl color1"},[_vm._v("A02-RSF")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1',
                    '负压空废煤废温度补偿'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.RSF3.AV,
                    'AV',
                    _vm.infoList.RSF3_node_string,
                    'RSF3_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.RSF3.AV)+" ")])]),_c('div',{staticClass:"col2 martop1"},[_c('div',{staticClass:"fl color1"},[_vm._v("A03-QK")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.QK15_node_string,
                    'QK15_AV_J1',
                    '一加热空烟空气阀门前馈'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.QK15.AV,
                    'AV',
                    _vm.infoList.QK15_node_string,
                    'QK15_AV_J1'
                  )}}},[_vm._v(" "+_vm._s(_vm.infoList.QK15.AV)+" ")])])]),_c('div',{staticClass:"fl mar-jg"},[_c('div',{staticClass:"bottonx",style:({
                background:
                  _vm.infoList.XK12 && !_vm.infoList.XK12.XK12 ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK12
                  ? _vm.toDetail(
                      1,
                      'XK12',
                      _vm.infoList.XK12_node_string,
                      '',
                      '一加热段空烟温度控制XK'
                    )
                  : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx mar-jg33",style:({
                background: !_vm.infoList.RSF3.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF3
                  ? _vm.toDetail(
                      3,
                      'RSF3',
                      _vm.infoList.RSF3_node_string,
                      '',
                      '负压空废煤废温度补偿'
                    )
                  : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"bottonx mar-jg1",style:({
                background:
                  _vm.infoList.QK15 && !_vm.infoList.QK15.TS ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.QK15
                  ? _vm.toDetail(
                      2,
                      'QK15',
                      _vm.infoList.QK15_node_string,
                      '',
                      '一加热段空烟空气阀门前馈'
                    )
                  : ''}}},[_vm._v(" K ")])]),_c('div',{staticClass:"fl"})]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx",style:({
              background:
                _vm.infoList.MAN22 && _vm.infoList.MAN22.RM == 1 ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.MAN22
                ? _vm.toCompon(
                    0,
                    'AV',
                    'MAN22',
                    'MAN22_RM_J1',
                    '一热段空烟气阀门手操器'
                  )
                : ''}}},[_vm._v(" A ")]),_c('div',{staticClass:"col11 mar-left"},[_c('div',{staticClass:"fl color11"},[_vm._v("A01-AV")]),_c('div',{staticClass:"fl color2",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.XK12_node_string,
                  'XK12_AV_J1',
                  '一加热段空烟温度控制XK'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.XK12.AV,
                  'AV',
                  _vm.infoList.XK12_node_string,
                  'XK12_AV_J1'
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.XK12.AV)+" ")])])])])])]),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e(),_c('inputVal',{ref:"inputVal",on:{"getTreeData":function($event){return _vm.DataJson(arguments)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }