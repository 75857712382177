<template>
	<div class="Historical drag" v-draw id="Historical">
		<div class="evaluate-header">
			<!-- {{projactNanme}}_{{ historyname }} -->
			测点历史趋势窗口
			<div class="evaluate-header-icon" @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>
		<!-- 折线图 -->
		<div class="rts_Chart flex">
			<div class="rts_Chart-left" id="rts_Chart-left" 
			v-loading="loading" 
			element-loading-text="趋势加载中"
			element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(255, 255, 255, 1)"
			:option="option">
			</div>
			<div class="rts_Chart-right" id="rts_Chart-right">
				<!--  -->
				<div class="lineCh" :style="{color: textColor}">
					<div>曲线&nbsp;{{Lkname}}</div>
					<div v-if="node1">Y[{{infoList[node1][historyname]}}]</div>
					<div v-else>Y[{{infoList[historyname]}}]</div>
					<div>Y[{{Lkname}}]</div>
					<div>Y[{{chName}}]</div>
				</div>

			</div>
		</div>
		<div class="bot-condition flex align-cen">
			<div>
				<div class="btn-f flex">
					<div class="btn-f-btn" @click="serchDt(10)" :class="{current:active == 10}">{{dtname}}</div>
					<div class="btn-f-btn" @click="serchHis(1,6,'up')" :class="{current:active == 6}">前翻一页</div>
					<div class="btn-f-btn" @click="serchHis(0.5,7,'up')" :class="{current:active == 7}">前翻半页</div>
					<div class="btn-f-btn" @click="serchHis(0.5,8,'down')" :class="{current:active == 8}">后翻半页</div>
					<div class="btn-f-btn" @click="serchHis(1,9,'down')" :class="{current:active == 9}">后翻一页</div>
				</div>
			</div>
			<div class="condition-time flex">
				<div @click="serchHis(1,0,'time')" :class="{current:active == 0}">1h</div>
				<div @click="serchHis(2,1,'time')" :class="{current:active == 1}">2h</div>
				<div @click="serchHis(4,2,'time')" :class="{current:active == 2}">4h</div>
				<div @click="serchHis(8,3,'time')" :class="{current:active == 3}">8h</div>
				<div @click="serchHis(24,4,'time')" :class="{current:active == 4}">24h</div>
				<div @click="serchHis(72,5,'time')" :class="{current:active == 5}">72h</div>
			</div>
			<div class="row4 flex align-cen cxtj">
				<div class="time-tit">指定时间:</div>
				<el-date-picker v-model="value2" type="datetimerange" :picker-options="pickerOptions"
					range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" format="yyyy-MM-dd HH:mm:ss"
					value-format="yyyy-MM-dd HH:mm:ss">
				</el-date-picker>
				<div class="row4-btn" @click="sech">查询</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		gethistoryInfo
	} from "@/api/index/index"
	export default {
		name: "Historicaljrl",
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			},
			historyname: [String, Number], //接口返回的点名
			node: [String, Number], //节点名
			Lkname: [String, Number], //力控表对应的点名
			chName: [String, Number],
			node1: [String, Number] //新点表有的还是有三层,直接拿不到,需要处理一下,比如XK11.SP,MAN11>AV,这些就需要特殊处理,但是不能用之前的处理格式
		},
		data: () => {
			return {
				addnember: '',
				upflag: false,
				isHshow: false,
				name: '',
				mark: '',
				// Lkname: '',
				// node: '',
				xAxis: [],
				echartData: [],
				legendData: [],
				textColor: '',
				timer: null,
				myChart: '',
				loading: true,
				end_time: '',
				start_time: '',
				option: '',
				projactNanme: '',
				value2: '',
				havetrue: '',
				current: 'current',
				active: 10,
				now: true,
				dtname: '实时趋势',
				projactNanme: '热风炉',
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}],
					disabledDate(date) {
						// 当天可选：date.getTime() < Date.now() - 24 * 60 * 60 * 1000
						return date.getTime() > Date.now()
					}
				},
			}

		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n
				},
				deep: true // 深度监听父组件传过来对象变化
			},
			historyname: {
				handler(n, o) {
					this.historyname = n
					// console.log(this.historyname)
				},
				deep: true // 深度监听父组件传过来对象变化
			},
			node: {
				handler(n, o) {
					this.node = n
				},
				deep: true // 深度监听父组件传过来对象变化
			},
			node1: {
				handler(n, o) {
					this.node1 = n
				},
				deep: true // 深度监听父组件传过来对象变化
			},
			Lkname: {
				handler(n, o) {
					this.Lkname = n
					this.gethisInfo()
				},
				deep: true // 深度监听父组件传过来对象变化
			},
			chName: {
				handler(n, o) {
					this.chName = n
					this.gethisInfo()
				},
				deep: true // 深度监听父组件传过来对象变化
			},
			// 
			option: {
				handler(newVal, oldVal) {
					if (this.myChart) {
						if (newVal) {
							this.loading = false
							this.myChart.setOption(newVal, true);
						} else {
							this.myChart.setOption(oldVal);
						}
					} else {
						this.drawLine();
					}
				},
			}
		},
		mounted() {
			// this.gethisInfo()
			this.timer = setInterval(() => {
				setTimeout(this.gethisInfo(), 0);
			}, 5000);
			// 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
			this.$once('hook:beforeDestroy', () => {
				clearInterval(this.timer);
			})
			// this.drawLine()
			// this.global1.ws.onmessage = (msg) => {
			// 	console.log(msg.data, "我的结果")
			// }
		},
		methods: {
			DataJson(data) {
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			// 时间段查询
			sech() {
				this.start_time = this.value2[0];
				this.end_time = this.value2[1];
				this.gethisInfo();
			},
			// 历史实时
			serchDt(index) {
				this.now = !this.now
				if (this.now) {
					this.active = index
					this.dtname = '实时趋势'
					this.start_time = ""
					this.timer = setInterval(() => {
						setTimeout(this.gethisInfo(), 0);
					}, 5000);
				} else {
					this.active = '-1'
					this.dtname = '历史趋势'
					this.gethisInfo();
					clearInterval(this.timer);
				}
			},
			//  查看过去趋势
			serchHis(val, i, type) {
				if (!this.now) {
					//  this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
					// this.start_time = this.$util.getTimeData(val);
					// this.active = i
					// this.gethisInfo();
					this.value2 = []
					if (type == 'down') {
						if (this.start_time == "") {
							this.$message.warning('已经是最新的了～～');
							return
						} else {
							this.addnember = this.addnember >= 1 ? this.addnember - val : 0
							if (this.addnember != 0) {
								this.start_time = this.$util.getTimeData(this.addnember);
								this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
							} else {
								this.end_time = "";
								this.start_time = ""
							}
							this.active = i
						}
					} else if (type == 'up') {
						if (this.start_time) {
							this.addnember = this.addnember + val
						} else {
							this.addnember = val
						}
						this.start_time = this.start_time ? this.$util.getTimeData(this.addnember) : this.$util
							.getTimeData(val);
						this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
						this.active = i
					} else {
						this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
						this.start_time = this.$util.getTimeData(val);
						this.active = i
					}
					this.gethisInfo();
				} else {
					this.$message.error("请先切换为历史趋势");
				}

			},
			//    保存折线图片
			downloadImpByChart() {
				var url = this.myChart.getConnectedDataURL({
					pixelRatio: 5, //导出的图片分辨率比率,默认是1
					backgroundColor: 'dark', //图表背景色
					excludeComponents: [ //保存图表时忽略的工具组件,默认忽略工具栏
						'toolbox'
					],
					type: 'png' //图片类型支持png和jpeg
				});
				var $a = document.createElement('a');
				var type = 'png';
				$a.download = this.myChart.getOption().title[0].text + '.' + type;
				$a.target = '_blank';
				$a.href = url;
				// Chrome and Firefox
				if (typeof MouseEvent === 'function') {
					var evt = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					$a.dispatchEvent(evt);
				}
				// IE
				else {
					var html = ''

					'<body style="margin:0;">'
					'![](' + url + ')'
					'</body>';
					var tab = window.open();
					tab.document.write(html);
				}
			},
			// 获取折线图数据
			gethisInfo() {
				// this.echartData = []
				// this.xAxis = []
				let projectData = JSON.parse(localStorage.getItem("deviceType"));
				this.projactNanme = projectData.device

				gethistoryInfo({
						device: projectData.device,
						mark: this.historyname,
						name: this.Lkname,
						node: this.node,
						end_time: this.end_time,
						start_time: this.start_time,
						cp_type: projectData.type
					})
					.then(res => {
						let hisdata = {
							dataZoom: {},
							dataView: {
								readOnly: true,
								optionToContent: function(option) {
									var axisData = option.xAxis[0].data;
									var series = option.series;
									var tdHeaders =
										'<td style="border:1px solid #ccc;border-bottom:0;border-right:0;padding:0 10px;background: #f5f7fa;">时间</td>'; //表头
									series.forEach(function(series) {
										tdHeaders +=
											'<td style="border:1px solid #ccc;border-bottom:0;border-right:0;padding:0 10px;background: #f5f7fa;">' +
											series.name + '</td>'; //组装表头
									});
									var table =
										'<div class="addtable"><table border="0" style="text-align:center;margin:10px;border-right:1px solid #ccc;border-bottom:1px solid #ccc;" cellspacing="0" cellpadding="0"><tbody><tr>' +
										tdHeaders + '</tr>';
									var tdBodys = ''; //数据
									for (var i = 0, l = axisData.length; i < l; i++) {
										for (var j = 0; j < series.length; j++) {
											tdBodys +=
												'<td style="border:1px solid #ccc;border-bottom:0;border-right:0"> ' +
												series[j].data[i] + '</td>'; //组装表数据
										}
										table +=
											'<tr ><td style="border:1px solid #ccc;border-bottom:0;border-right:0"> <div   class="addar" style="width:100px;height:40px;line-height:40px">' +
											axisData[i] + '</div></td>' + tdBodys + '</tr>';
										tdBodys = '';
									}
									table += '</tbody></table></div>';
									return table;
								}
							},

							restore: {},
							saveAsImage: {}
						}
						this.loading = false
						this.xAxis = res.data.x
						this.echartData = res.data.series
						let legendname = res.data.series.name
						this.legendData = legendname.split(',')
						this.option = {
							title: {
								text: "",
							},
							tooltip: {
								trigger: "axis",
							},
							legend: {
								data: this.legendData,
								textStyle: {
									fontSize: 14
								}
							},
							grid: {
								left: "3%",
								right: "4%",
								bottom: "10%",
								containLabel: true,
							},
							toolbox: {
								feature: this.active == '10' ? {} : hisdata,
								top: 20
							},
							xAxis: {
								type: "category",
								boundaryGap: false,
								data: this.xAxis,
								nameLocation: 'middle',
								axisLabel: { //  如果这个字段不设置，echarts会根据屏宽及横坐标数据自动给出间隔
									//  interval: 30, // 间隔长度，可自定义（如果是时间格式，echarts会自动处理）
									rotate: 40 // 横坐标上label的倾斜度
								},
							},
							yAxis: {
								type: 'value',
								name: '',
								position: 'left',
								scale: true,
								axisLine: {
									show: true,
									lineStyle: {
										color: '#ffffff'
									}
								},
								axisTick: {
									show: true
								},
								axisLabel: {
									formatter: '{value}'
								}
							},
							series: this.echartData,
						}
						// 基于准备好的dom，初始化echarts实例
						// this.drawLine()
					})
					.catch(err => {
						this.loading = false
						// this.$message.error('历史数据获取失败');
					})
			},
			//    折线图js
			drawLine() {
				let myChart = this.$echarts.init(document.getElementById("rts_Chart-left"), 'dark');
				this.myChart = myChart
				// 绘制图表
				myChart.setOption(this.option);
				window.addEventListener("resize", function() {
					myChart.resize();
				});
				this.textColor = myChart.getModel().option.color[0]
			},
			closeCompon() {
				this.$emit('sendStatus', 'Historical', false)
			},
		}
	}
</script>
<style lang="scss" scoped>
	::v-deep {

		.el-range__icon,
		.el-range__close-icon {
			line-height: 2vh !important;
		}

		.el-range__close-icon {
			position: absolute;
			z-index: 2;
			right: 0;
		}

		.el-range__icon {
			display: none;
		}

		.el-range-input {
			background: #031d30 !important;
			color: #fff;
			width: 42%;
			font-size: .6vw;
			cursor: pointer;
		}

		.el-range-separator {
			color: #fff;
			line-height: 2vh;
		}
	}

	.row4 {
		cursor: pointer;

		.time-tit {
			color: #0c78a3;
			font-size: 1vw;
			width: 5vw;
		}

		.row4-btn {
			width: 3vw;
			height: 2.8vh;
			background: rgba(14, 247, 255, 0.2);
			border-radius: 2px;
			color: rgba(14, 247, 255, 0.5);
			text-align: center;
			line-height: 2.8vh;
			font-size: 1.8vh;
			margin-left: 10px;
		}

		.el-range-editor.el-input__inner {
			background: #031d30 !important;
			border: 1px solid #064a6d;
			width: 10vw;
			height: 3vh;
			padding: 3px 5px;
			position: relative;
			z-index: 1;
			cursor: pointer;
		}

	}

	.current {
		color: #0ef7ff !important;
		border: 1px solid #0ef7ff;
		box-sizing: border-box;
	}

	.btn-f {
		height: 3vh;
		margin: 0vh auto;

		.btn-f-btn {
			width: 5vw;
			background: rgba(14, 247, 255, 0.2);
			border-radius: 2px;
			color: rgba(14, 247, 255, 0.5);
			text-align: center;
			line-height: 3vh;
			font-size: 1.2vw;
			margin-left: .5vw;
			cursor: pointer;
		}

		.btn-f-btn:first-child {
			margin-left: 5;

		}
	}

	.zdsj {
		margin-top: 10vh;
	}

	.Historical {
		width: 68vw;
		height: 82vh;
		background: url("~@/assets/images/anquanshezhiditu.png") no-repeat 0 0;
		background-size: 100% 100%;
		cursor: move;
		border-radius: 5px;

		.evaluate-header {
			height: 3vh;
			line-height: 3vh;
			text-align: center;
			color: #0ef7ff;
			font-size: 3vh;
			position: relative;
			border-radius: 5px 5px 0 0;
			padding-top: 1.4vh;

			.evaluate-header-icon {
				width: 2vw;
				height: 2vh;
				position: absolute;
				right: 1vw;
				margin-top: -3vh;

				img {
					cursor: pointer;
				}
			}
		}

		.rts_Chart {
			margin: 10px;
			margin-top: 3vh;

			.rts_Chart-left {
				width: 55vw;
				height: 65vh;
			}

			.rts_Chart-right {
				width: 11vw;

				.lineCh {
					width: 9vw;
					height: 65vh;
					background: rgba(0, 22, 36, 0.2);
					margin: 0 auto;
					font-size: 0.9vw;
					padding: 0vw .5vw;
					margin-left: .5vw;
				}
			}
		}

		.bot-condition {
			padding: 0 10px;
			margin-top: 15px;

			.condition-list {
				div {
					font-size: 12px;
					border-right: 1px solid #d3e4ec;
					padding-right: 10px;
					padding-left: 5px;
				}

				div:last-child {
					border-right: none;
				}
			}

			.condition-btn {
				div {
					width: 5vw;
					height: 4vh;
					background-color: #d4e3ec;
					border-radius: 2px;
					line-height: 4vh;
					text-align: center;
					font-size: 1.2vw;
					color: #94acba;
					text-shadow: 0px 1px 0px 0px #ffffff;
					margin-right: 3px;
					cursor: pointer;
				}
			}

			.condition-time {
				margin-left: auto;

				div {
					width: 2.5vw;
					height: 3vh;
					background-color: rgba(14, 247, 255, .2);
					border-radius: 2px;
					line-height: 3vh;
					text-align: center;
					font-size: 1vw;
					color: #209caf;
					margin-right: 3px;
					cursor: pointer;
				}
			}
		}

		.cxtj {
			margin-left: .5vw;
			font-size: 2vh;
		}

		.bot-condition-add {
			position: absolute;
			bottom: 20px;
			right: 10px;

			div {
				width: 90px;
				height: 30px;
				background-color: #d4e3ec;
				border-radius: 2px;
				line-height: 30px;
				text-align: center;
				font-size: 20px;
				color: #94acba;
				text-shadow: 0px 1px 0px 0px #ffffff;
				margin-right: 3px;
				cursor: pointer;
			}
		}
	}
</style>
