<template>
  <div class="total drag" v-draw id="RqParameter4">
    <!-- 抬头标题 -->
    <div class="flex">
      <div class="title-hang flex">
        <div class="zhuangshizb"></div>
        <div class="zhuangshizj">{{ projectData.project}}_参数4_0</div>
        <div class="zhuangshiyb">
          <div class="icon" @click="closeCompon"></div>
        </div>
      </div>
    </div>
    <!-- 标题 -->
    <div class="main">
      <div class="main_title">送风优化控制模型</div>
    </div>
    <!-- 内容 -->
    <div class="content flex">
      <div class="one">
        <div class="shadow shadow1">
          <div class="flex">
            <div class="shadow1_column1">A04-02SPH</div>
            <div
              class="shadow1_column2"
              @click="
                toIpt(
                  infoList.O2SPH,
                  'A04-02SPH',
                  'O2SPH',
                  infoList.O2SPH_node_string
                )
              "
            >
              {{'O2SPH' in infoList? infoList.O2SPH :0}}
            </div>
          </div>
          <div class="flex">
            <div class="shadow1_column1">A04-02SPL</div>
            <div
              class="shadow1_column2"
              @click="
                toIpt(
                  infoList.O2SPL,
                  'A04-02SPL',
                  'O2SPL',
                  infoList.O2SPL_node_string
                )
              "
            >
              {{'O2SPL' in infoList? infoList.O2SPL :0}}
            </div>
          </div>
          <div class="flex">
            <div class="shadow1_column1">A04-02TC</div>
            <div
              class="shadow1_column2"
              @click="
                toIpt(
                  infoList.O2TC,
                  'A04-02TC',
                  'O2TC',
                  infoList.O2TC_node_string
                )
              "
            >
              {{'O2TC' in infoList? infoList.O2TC :0}}
            </div>
          </div>
        </div>
        <div class="shadow shadow2">
          <div class="flex">
            <div class="shadow2_column">
              <div class="flex">
                <div class="shadow2_column1">A08-TCP</div>
                <div
                  class="shadow2_column2"
                  @click="
                    toIpt(
                      infoList
                        .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL
                        .TOP,
                      'A08-TCP',
                      'TOP',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL_node_string
                    )
                  "
                >
                  {{'BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL' in infoList?
                    infoList
                      .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL.TOP
                  :0}}
                </div>
              </div>
              <div class="flex">
                <div class="shadow2_column1">A08-SOP</div>
                <div
                  class="shadow2_column2"
                  @click="
                    toIpt(
                      infoList
                        .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL
                        .SOP,
                      'A08-SOP',
                      'SOP',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL_node_string
                    )
                  "
                >
                  {{'BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL' in infoList?
                    infoList
                      .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL.SOP
                  :0}}
                </div>
              </div>
              <div class="flex">
                <div class="shadow2_column1">A08-EOP</div>
                <div
                  class="shadow2_column2"
                  @click="
                    toIpt(
                      infoList
                        .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL
                        .EOP,
                      'A08-EOP',
                      'EOP',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL_node_string
                    )
                  "
                >
                  {{'BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL' in infoList?
                    infoList
                      .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL.EOP
                  :0}}
                </div>
              </div>
              <div class="flex">
                <div class="shadow2_column1">A08-TC(m)</div>
                <div
                  class="shadow2_column2"
                  @click="
                    toIpt(
                      infoList
                        .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL
                        .YHJG,
                      'A08-TC(m)',
                      'YHJG',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL_node_string
                    )
                  "
                >
                  {{'BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL' in infoList?
                    infoList
                      .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL.YHJG
                  :0}}
                </div>
              </div>
              <div class="flex">
                <div class="shadow2_column1">风T</div>
                <div
                  class="shadow2_column3"
                  @click="
                    toCompon(
                      2,
                      'YHJGSYSJ',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__DXBL_node_string,
                      'SYSJ3_NG3',
                      '氧量优化剩余时间',
					  'BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__DXBL'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList
                        .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__DXBL
                        .YHJGSYSJ,
                      '风T',
                      'YHJGSYSJ',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__DXBL_node_string
                    )
                  "
                >
                  {{'BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__DXBL' in infoList?
                    infoList
                      .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__DXBL
                      .YHJGSYSJ
                  :0}}
                </div>
              </div>
            </div>
            <div class="shadow2_column">
              <div class="flex">
                <div class="shadow2_column1">A08-OH</div>
                <div
                  class="shadow2_column2"
                  @click="
                    toIpt(
                      infoList
                        .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL
                        .YHZL_H,
                      'A08-OH',
                      'YHZL_H',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL_node_string
                    )
                  "
                >
                  {{'BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL' in infoList?
                    infoList
                      .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL
                      .YHZL_H
                  :0}}
                </div>
              </div>
              <div class="flex">
                <div class="shadow2_column1">A05-OL</div>
                <div
                  class="shadow2_column2"
                  @click="
                    toIpt(
                      infoList
                        .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL
                        .YHZL_L,
                      'A05-OL',
                      'YHZL_L',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL_node_string
                    )
                  "
                >
                  {{'BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL' in infoList?
                    infoList
                      .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL
                      .YHZL_L
                  :0}}
                </div>
              </div>
              <div class="flex">
                <div class="shadow2_column1">A05-OT</div>
                <div
                  class="shadow2_column3"
                  @click="
                    toCompon(
                      2,
                      'YHZL',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                      'YHYL_NG3',
                      '优化氧量增量',
					  'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList
                        .BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL
                        .YHZL,
                      'YHZL',
                      infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                      'YHYL_NG3'
                    )
                  "
                >
                  {{'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL' in infoList?
                    infoList
                      .BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL.YHZL
                  :0}}
                </div>
              </div>
              <div class="shadow2_progress">
                <div class="flex">
                  <div class="shadow2_num">0</div>
                  <div class="shadow2_num shadow2_num1">100</div>
                </div>
                <div class="progress">
                  <div class="progress">
                    <div class="progress_content"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="shadow shadow3 location1">
          <div class="flex">
            <div class="shadow3_column1">A02-ZLTC</div>
            <div
              class="shadow3_column2"
              @click="
                toIpt(infoList.ZLTC, 'A02-ZLTC', 'ZLTC', infoList.ZLTC_node_string)
              "
            >
              {{'ZLTC' in infoList? infoList.ZLTC :0}}
            </div>
          </div>
        </div>
        <div class="shadow shadow3 location2">
          <div class="flex">
            <div class="shadow3_column1">A02-FTZLMQ</div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="infoList.ZLPV"
              placement="top"
            >
              <div
                class="shadow3_column3 textov"
                @click="
                  toCompon(2, 'ZLPV', infoList.ZLPV_node_string, 'ZLPV_NG3', '转炉煤气流量')
                "
                @dblclick="
                  Cclick(infoList.ZLPV, 'ZLPV', infoList.ZLPV_node_string, 'ZLPV_NG3')
                "
              >
                {{'ZLPV' in infoList? infoList.ZLPV :0}}
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="shadow shadow3 location3">
          <div class="flex">
            <div class="shadow3_column1">A02-ZL_GL</div>
            <div
              class="shadow3_column3"
              @click="toCompon(2, 'ZL_GL', infoList.ZL_GL_node_string, 'ZL_GL_NG3')"
              @dblclick="
                Cclick(infoList.ZL_GL, 'ZL_GL', infoList.ZL_GL_node_string, 'ZL_GL_NG3')
              "
            >
              {{'ZL_GL' in infoList? infoList.ZL_GL :0}}
            </div>
          </div>
        </div>
        <div class="shadow shadow3 location4">
          <div class="flex">
            <div class="shadow3_column1">A02-JLTC</div>
            <div
              class="shadow3_column2"
              @click="
                toIpt(infoList.JLTC, 'A02-JLTC', 'JLTC', infoList.JLTC_node_string)
              "
            >
              {{'JLTC' in infoList? infoList.JLTC :0}}
            </div>
          </div>
        </div>
        <div class="shadow shadow3 location5">
          <div class="flex">
            <div class="shadow3_column1">A02-FTJLMQ</div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="infoList.JLPV"
              placement="top"
            >
              <div
                class="shadow3_column3 textov"
                @click="
                  toCompon(2, 'JLPV', infoList.JLPV_node_string, 'JLPV_NG3', '焦炉煤气流量')
                "
                @dblclick="
                  Cclick(infoList.JLPV, 'JLPV', infoList.JLPV_node_string, 'JLPV_NG3')
                "
              >
                {{'JLPV' in infoList? infoList.JLPV :0}}
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="shadow shadow3 location6">
          <div class="flex">
            <div class="shadow3_column1">A02-JL_MQ</div>
            <div
              class="shadow3_column3"
              @click="toCompon(2, 'JL_GL', infoList.JL_GL_node_string, 'JL_GL_NG3')"
              @dblclick="
                Cclick(infoList.JL_GL, 'JL_GL', infoList.JL_GL_node_string, 'JL_GL_NG3')
              "
            >
              {{'JL_GL' in infoList? infoList.JL_GL :0}}
            </div>
          </div>
        </div>
        <div class="shadow shadow4">
          <div class="flex">
            <div class="shadow4_column">
              <div class="shadow4_words">实际测量</div>
              <div class="flex">
                <div class="shadow4_column1">B01-RCLK</div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="infoList.SFRCLK1"
                  placement="top"
                >
                  <div
                    class="shadow4_column2 textov"
                    @click="
                      toIpt(
                        infoList.SFRCLK1,
                        'B01-RCLK',
                        'SFRCLK1',
                        infoList.SFRCLK1_node_string
                      )
                    "
                  >
                    {{'SFRCLK1' in infoList? infoList.SFRCLK1 :0}}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex">
                <div class="shadow4_column1">B01-RCLC</div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="infoList.SFRCLC1"
                  placement="top"
                >
                  <div
                    class="shadow4_column2 textov"
                    @click="
                      toIpt(
                        infoList.SFRCLC1,
                        'B01-RCLC',
                        'SFRCLC1',
                        infoList.SFRCLC1_node_string
                      )
                    "
                  >
                    {{'SFRCLC1' in infoList? infoList.SFRCLC1 :0}}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex">
                <div class="shadow4_column1">B01-RCL</div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="infoList.SFRCL1"
                  placement="top"
                >
                  <div
                    class="shadow4_column3 textov"
                    @click="
                      toCompon(
                        2,
                        'SFRCL1',
                        infoList.SFRCL1_node_string,
                        'SFRCL1_NG3',
                        '1#送风软测量'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.SFRCL1,
                        'SFRCL1',
                        infoList.SFRCL1_node_string,
                        'SFRCL1_NG3'
                      )
                    "
                  >
                    {{'SFRCL1' in infoList? infoList.SFRCL1 :0}}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex">
                <div class="shadow4_column1">B01-SJCL</div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="infoList.FICA_103_B"
                  placement="top"
                >
                  <div
                    class="shadow4_column3 textov"
                    @click="
                      toCompon(
                        2,
                        'FICA_103_B',
                        infoList.FICA_103_B_node_string,
                        'FICA_103_NG3',
                        '1#送风量'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.FICA_103_B,
                        'FICA_103_B',
                        infoList.FICA_103_B_node_string,
                        'FICA_103_NG3'
                      )
                    "
                  >
                    {{'FICA_103_B' in infoList? infoList.FICA_103_B :0}}
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="shadow4_column">
              <div class="shadow4_words">实际测量</div>
              <div class="flex">
                <div class="shadow4_column1">B01-RCLK</div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="infoList.SFRCLK2"
                  placement="top"
                >
                  <div
                    class="shadow4_column2 textov"
                    @click="
                      toIpt(
                        infoList.SFRCLK2,
                        'B01-RCLK',
                        'SFRCLK2',
                        infoList.SFRCLK2_node_string
                      )
                    "
                  >
                    {{'SFRCLK2' in infoList? infoList.SFRCLK2 :0}}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex">
                <div class="shadow4_column1">B01-RCLC</div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="infoList.SFRCLC2"
                  placement="top"
                >
                  <div
                    class="shadow4_column2 textov"
                    @click="
                      toIpt(
                        infoList.SFRCLC2,
                        'B01-RCLC',
                        'SFRCLC2',
                        infoList.SFRCLC2_node_string
                      )
                    "
                  >
                    {{'SFRCLC2' in infoList? infoList.SFRCLC2 :0}}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex">
                <div class="shadow4_column1">B01-RCL</div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="infoList.SFRCL2"
                  placement="top"
                >
                  <div
                    class="shadow4_column3 textov"
                    @click="
                      toCompon(
                        2,
                        'SFRCL2',
                        infoList.SFRCL2_node_string,
                        'SFRCL2_NG3',
                        '2#送风软测量'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.SFRCL2,
                        'SFRCL2',
                        infoList.SFRCL2_node_string,
                        'SFRCL2_NG3'
                      )
                    "
                  >
                    {{'SFRCL2' in infoList? infoList.SFRCL2 :0}}
                  </div>
                </el-tooltip>
              </div>
              <div class="flex">
                <div class="shadow4_column1">B01-SJCL</div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="infoList.FICA_104_B"
                  placement="top"
                >
                  <div
                    class="shadow4_column3 textov"
                    @click="
                      toCompon(
                        2,
                        'FICA_104_B',
                        infoList.FICA_104_B_node_string,
                        'FICA_104_NG3',
                        '2#送风量'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.FICA_104_B,
                        'FICA_104_B',
                        infoList.FICA_104_B_node_string,
                        'FICA_104_NG3'
                      )
                    "
                  >
                    {{'FICA_104_B' in infoList? infoList.FICA_104_B :0}}
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="two flex">
        <div class="part1">
          <div class="flex">
            <div class="shadow shadow5 flex">
              <div class="shadow5_column1">A04-02SP</div>
              <div
                class="shadow5_column2"
                @click="
                  toIpt(
                    infoList.O2SP,
                    'A04-02SP',
                    'O2SP',
                    infoList.O2SP_node_string
                  )
                "
              >
                {{'O2SP' in infoList? infoList.O2SP :0}}
              </div>
            </div>
            <div
              class="button button1"
              :style="{
                background:
                  infoList.XK6 && !infoList.XK6.RM
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.XK6
                  ? toDetail(1, 'XK6', infoList.XK6_node_string, '', '送风变频先控调节')
                  : ''
              "
            >
              X
            </div>
          </div>
          <div>
            <div class="blue">
              <div
                @click="
                  toIpt(
                    infoList.BCSYH_SHM1__p__BCSYHn__ll__3__rr__
                      .BCSYHQY,
                    '按钮',
                    'BCSYHQY',
                    infoList.BCSYH_SHM1__p__BCSYHn__ll__3__rr___node_string
                  )
                "
              >
                {{'BCSYH_SHM1__p__BCSYHn__ll__3__rr__' in infoList?
                  infoList.BCSYH_SHM1__p__BCSYHn__ll__3__rr__.BCSYHQY
                    ? "优化启用"
                    : "优化切除"
                :0}}
              </div>
            </div>
            <div class="green">
              <div
                @click="
                  toIpt(
                    infoList
                      .BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL
                      .RESET,
                    '按钮',
                    'RESET',
                    infoList.BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL_node_string
                  )
                "
              >
                {{'BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL' in infoList?
                  infoList.BCSYH_SHM1__p__BCSYHn__ll__3__rr____p__ZDBL
                    .RESET
                    ? "强制优化"
                    : "自动优化"
                :0}}
              </div>
            </div>
          </div>
          <div>
            <div class="flex">
              <div class="part1_words">
                <div class="flex">
                  <div class="part1_word">OE</div>
                  <div
                    class="part1_num"
                    @click="
                      toCompon(
                        2,
                        'OBCS',
                        infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                        'OE2_NG3',
                        '空燃比优化上一时刻优化参考值',
						'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList
                          .BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL
                          .OBCS,
                        'OBCS',
                        infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                        'OE2_NG3'
                      )
                    "
                  >
                    {{'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL' in infoList?
                      infoList
                        .BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL
                        .OBCS
                    :0}}
                  </div>
                </div>
                <div class="flex">
                  <div class="part1_word">NE</div>
                  <div
                    class="part1_num"
                    @click="
                      toCompon(
                        2,
                        'NBCS',
                        infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                        'NE2_NG3',
                        '空燃比优化当前优化参考值',
						'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList
                          .BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL
                          .NBCS,
                        'NBCS',
                        infoList.BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL_node_string,
                        'NE2_NG3'
                      )
                    "
                  >
                    {{'BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL' in infoList?
                      infoList
                        .BCSYH_SHM1__p__BCSYHn__ll__2__rr____p__DXBL
                        .NBCS
                    :0}}
                  </div>
                </div>
              </div>
              <div class="shadow shadow6 flex">
                <div class="shadow6_column1">A04-02PV</div>
                <div
                  class="shadow6_column2"
                  @click="
                    toCompon(
                      2,
                      'O2PV',
                      infoList.O2PV_node_string,
                      'O2PV_NG3',
                      '氧量设定值'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.O2PV,
                      'O2PV',
                      infoList.O2PV_node_string,
                      'O2PV_NG3'
                    )
                  "
                >
                  {{'O2PV' in infoList? infoList.O2PV :0}}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="shadow shadow7 flex">
              <div class="shadow7_column1">A01-FTGLMQ</div>
              <el-tooltip
                class="item"
                effect="dark"
                :content="infoList.GLSP"
                placement="top"
              >
                <div
                  class="shadow7_column2 textov"
                  @click="
                    toCompon(2, 'GLSP', infoList.GLSP_node_string, 'GLSP_NG3', '高炉煤气设定值')
                  "
                  @dblclick="
                    Cclick(infoList.GLSP, 'GLSP', infoList.GLSP_node_string, 'GLSP_NG3')
                  "
                >
                  {{'GLSP' in infoList? infoList.GLSP :0}}
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="flex">
            <!-- 	<div class="button button2" :style="">X</div> -->
            <div
              class="button button3"
              :style="{
                background:
                  infoList.QK19 && !infoList.QK19.TS
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.QK19
                  ? toDetail(
                      2,
                      'QK19',
                      infoList.QK19_node_string,
                      '',
                      '高煤气-送风机K挡板开度前馈'
                    )
                  : ''
              "
            >
              K
            </div>
          </div>
          <div class="flex">
            <!-- <div class="button button4" :style="">X</div> -->
            <div
              class="button button5"
              :style="{
                background:
                  infoList.QK11 && !infoList.QK11.TS
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.QK11
                  ? toDetail(
                      2,
                      'QK11',
                      infoList.QK11_node_string,
                      '',
                      '高煤气-送风机变频开度前馈'
                    )
                  : ''
              "
            >
              K
            </div>
          </div>
          <div>
            <div
              class="button button6"
              :style="{
                background:
                  infoList.XK7 && !infoList.XK7.RM
                    ? '#2AFC30'
                    : 'red',
              }"
              @click="
                infoList.XK7
                  ? toDetail(1, 'XK7', infoList.XK7_node_string, '', '送风挡板先控调节')
                  : ''
              "
            >
              X
            </div>
          </div>
        </div>
        <div class="part2">
          <div
            class="button button7"
            :style="{
              background:
                infoList.MAN11.RM && infoList.MAN11.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.MAN11
                ? toCompon(
                    0,
                    'AV',
                    'MAN11',
                    'MAN11_AV_NG3',
                    '1#送风机变频手操器MAN11',
                    'KF_111_B',
                    infoList.MAN11_node_string,
                    'KF_111_NG3'
                  )
                : ''
            "
          >
            A
          </div>
          <div class="part2_word part2_word1">PZ1</div>
          <div
            class="button button8"
            :style="{
              background:
                infoList.MAN12.RM && infoList.MAN12.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.MAN12
                ? toCompon(
                    0,
                    'AV',
                    'MAN12',
                    'MAN12_AV_NG3',
                    '2#送风机变频手操器MAN12',
                    'KF_112_B',
                    infoList.MAN12_node_string,
                    'KF_112_NG3'
                  )
                : ''
            "
          >
            A
          </div>
          <div class="part2_word part2_word2">PZ2</div>
          <div
            class="button button9"
            :style="{
              background:
                infoList.MAN13.RM && infoList.MAN13.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.MAN13
                ? toCompon(
                    0,
                    'AV',
                    'MAN13',
                    'MAN13_AV_NG3',
                    '1#送风机挡板手操器MAN13',
                    'KF_113_B',
                    infoList.MAN13_node_string,
                    'KF_113_NG3'
                  )
                : ''
            "
          >
            A
          </div>
          <div class="part2_word part2_word3">PZ3</div>
          <div
            class="button button10"
            :style="{
              background:
                infoList.MAN14.RM && infoList.MAN14.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.MAN14
                ? toCompon(
                    0,
                    'AV',
                    'MAN14',
                    'MAN14_AV_NG3',
                    '2#送风机挡板手操器MAN14',
                    'KF_114_B',
                    infoList.MAN14_node_string,
                    'KF_114_NG3'
                  )
                : ''
            "
          >
            A
          </div>
          <div class="part2_word part2_word4">PZ4</div>
        </div>
      </div>
      <div class="three">
        <div class="shadow shadow8 flex">
          <div class="shadow8_column1">A06-A01</div>
          <div
            class="shadow8_column2"
            @click="
              toCompon(
                2,
                'AV',
                infoList.MAN11_node_string,
                'MAN11_AV_NG3',
                '1#送风机变频手操器',
				'MAN11'
              )
            "
            @dblclick="
              Cclick(
                infoList.MAN11.AV,
                'AV',
                infoList.MAN11_node_string,
                'MAN11_AV_NG3'
              )
            "
          >
            {{'MAN11' in infoList? infoList.MAN11.AV :0}}
          </div>
        </div>
        <div class="shadow shadow9 flex">
          <div class="shadow9_column1">A06-A02</div>
          <div
            class="shadow9_column2"
            @click="
              toCompon(
                2,
                'AV',
                infoList.MAN12_node_string,
                'MAN12_AV_NG3',
                '2#送风机变频手操器',
				'MAN12'
              )
            "
            @dblclick="
              Cclick(
                infoList.MAN12.AV,
                'AV',
                infoList.MAN12_node_string,
                'MAN12_AV_NG3'
              )
            "
          >
            {{'MAN12' in infoList? infoList.MAN12.AV :0}}
          </div>
        </div>
        <div class="table2">
          <div class="table_title">逆风挡板--氧量系数</div>
          <div class="table_total">
            <div class="table_line flex">
              <div class="table_column3">挡板开度</div>
              <div class="table_column4 words">系数</div>
            </div>
            <div class="table_line flex">
              <div class="table_column3 flex">
                <div class="zero1"></div>
                <div class="mark"></div>
                <div
                  class="zero2"
                  @click="
                    toIpt(
                      infoList.SFDBFD.IN_FD__ll__1__rr__,
                      '逆风挡板开度',
                      'IN_FD__ll__1__rr__',
                      infoList.SFDBFD_node_string
                    )
                  "
                >
                  {{'SFDBFD' in infoList? infoList.SFDBFD.IN_FD__ll__1__rr__ :0}}
                </div>
              </div>
              <div
                class="table_column4"
                @click="
                  toIpt(
                    infoList.SFDBFD.AV_FD__ll__1__rr__,
                    '逆风挡板系数',
                    'AV_FD__ll__1__rr__',
                    infoList.SFDBFD_node_string
                  )
                "
              >
                {{'SFDBFD' in infoList? infoList.SFDBFD.AV_FD__ll__1__rr__ :0}}
              </div>
            </div>
            <div class="table_line flex">
              <div class="table_column3 flex">
                <div
                  class="zero1"
                  @click="
                    toIpt(
                      infoList.SFDBFD.IN_FD__ll__1__rr__,
                      '逆风挡板开度',
                      'IN_FD__ll__1__rr__',
                      infoList.SFDBFD_node_string
                    )
                  "
                >
                  {{'SFDBFD' in infoList? infoList.SFDBFD.IN_FD__ll__1__rr__ :0}}
                </div>
                <div class="mark">---</div>
                <div
                  class="zero2"
                  @click="
                    toIpt(
                      infoList.SFDBFD.IN_FD__ll__2__rr__,
                      '逆风挡板开度',
                      'IN_FD__ll__2__rr__',
                      infoList.SFDBFD_node_string
                    )
                  "
                >
                  {{'SFDBFD' in infoList? infoList.SFDBFD.IN_FD__ll__2__rr__ :0}}
                </div>
              </div>
              <div
                class="table_column4"
                @click="
                  toIpt(
                    infoList.SFDBFD.AV_FD__ll__2__rr__,
                    '逆风挡板系数',
                    'AV_FD__ll__2__rr__',
                    infoList.SFDBFD_node_string
                  )
                "
              >
                {{'SFDBFD' in infoList? infoList.SFDBFD.AV_FD__ll__2__rr__ :0}}
              </div>
            </div>
            <div class="table_line flex">
              <div class="table_column3 flex">
                <div
                  class="zero1"
                  @click="
                    toIpt(
                      infoList.SFDBFD.IN_FD__ll__2__rr__,
                      '逆风挡板开度',
                      'IN_FD__ll__2__rr__',
                      infoList.SFDBFD_node_string
                    )
                  "
                >
                  {{'SFDBFD' in infoList? infoList.SFDBFD.IN_FD__ll__2__rr__ :0}}
                </div>
                <div class="mark">---</div>
                <div
                  class="zero2"
                  @click="
                    toIpt(
                      infoList.SFDBFD.IN_FD__ll__3__rr__,
                      '逆风挡板开度',
                      'IN_FD__ll__3__rr__',
                      infoList.SFDBFD_node_string
                    )
                  "
                >
                  {{'SFDBFD' in infoList? infoList.SFDBFD.IN_FD__ll__3__rr__ :0}}
                </div>
              </div>
              <div
                class="table_column4"
                @click="
                  toIpt(
                    infoList.SFDBFD.AV_FD__ll__3__rr__,
                    '逆风挡板系数',
                    'AV_FD__ll__3__rr__',
                    infoList.SFDBFD_node_string
                  )
                "
              >
                {{'SFDBFD' in infoList? infoList.SFDBFD.AV_FD__ll__3__rr__ :0}}
              </div>
            </div>
            <div class="table_line flex">
              <div class="table_column3 flex">
                <div
                  class="zero1"
                  @click="
                    toIpt(
                      infoList.SFDBFD.IN_FD__ll__3__rr__,
                      '逆风挡板开度',
                      'IN_FD__ll__3__rr__',
                      infoList.SFDBFD_node_string
                    )
                  "
                >
                  {{'SFDBFD' in infoList? infoList.SFDBFD.IN_FD__ll__3__rr__ :0}}
                </div>
                <div class="mark">---</div>
                <div
                  class="zero2"
                  @click="
                    toIpt(
                      infoList.SFDBFD.IN_FD__ll__4__rr__,
                      '逆风挡板开度',
                      'IN_FD__ll__4__rr__',
                      infoList.SFDBFD_node_string
                    )
                  "
                >
                  {{'SFDBFD' in infoList? infoList.SFDBFD.IN_FD__ll__4__rr__ :0}}
                </div>
              </div>
              <div
                class="table_column4"
                @click="
                  toIpt(
                    infoList.SFDBFD.AV_FD__ll__4__rr__,
                    '逆风挡板系数',
                    'AV_FD__ll__4__rr__',
                    infoList.SFDBFD_node_string
                  )
                "
              >
                {{'SFDBFD' in infoList? infoList.SFDBFD.AV_FD__ll__4__rr__ :0}}
              </div>
            </div>
            <div class="table_line flex">
              <div class="table_column3 flex">
                <div
                  class="zero1"
                  @click="
                    toIpt(
                      infoList.SFDBFD.IN_FD__ll__4__rr__,
                      '逆风挡板开度',
                      'IN_FD__ll__4__rr__',
                      infoList.SFDBFD_node_string
                    )
                  "
                >
                  {{'SFDBFD' in infoList? infoList.SFDBFD.IN_FD__ll__4__rr__ :0}}
                </div>
                <div class="mark">>=</div>
                <div
                  class="zero2"
                  @click="
                    toIpt(
                      infoList.SFDBFD.IN_FD__ll__4__rr__,
                      '逆风挡板开度',
                      'IN_FD__ll__4__rr__',
                      infoList.SFDBFD_node_string
                    )
                  "
                >
                  {{'SFDBFD' in infoList? infoList.SFDBFD.IN_FD__ll__4__rr__ :0}}
                </div>
              </div>
              <div
                class="table_column4"
                @click="
                  toIpt(
                    infoList.SFDBFD.AV_FD__ll__5__rr__,
                    '逆风挡板系数',
                    'AV_FD__ll__5__rr__',
                    infoList.SFDBFD_node_string
                  )
                "
              >
                {{'SFDBFD' in infoList? infoList.SFDBFD.AV_FD__ll__5__rr__ :0}}
              </div>
            </div>
          </div>
        </div>
        <div class="shadow shadow10 flex">
          <div class="shadow10_column1">A06-A01</div>
          <div
            class="shadow10_column2"
            @click="
              toCompon(
                2,
                'AV',
                infoList.MAN13_node_string,
                'MAN13_AV_NG3',
                '1#送风机档板手操器',
				'MAN13'
              )
            "
            @dblclick="
              Cclick(
                infoList.MAN13.AV,
                'AV',
                infoList.MAN13_node_string,
                'MAN13_AV_NG3'
              )
            "
          >
            {{'MAN13' in infoList? infoList.MAN13.AV :0}}
          </div>
        </div>
        <div class="shadow shadow11 flex">
          <div class="shadow11_column1">A06-A02</div>
          <div
            class="shadow11_column2"
            @click="
              toCompon(
                2,
                'AV',
                infoList.MAN14_node_string,
                'MAN14_AV_NG3',
                '2#送风机档板手操器',
				'MAN14'
              )
            "
            @dblclick="
              Cclick(
                infoList.MAN14.AV,
                'AV',
                infoList.MAN14_node_string,
                'MAN14_AV_NG3'
              )
            "
          >
            {{'MAN14' in infoList? infoList.MAN14.AV :0}}
          </div>
        </div>
      </div>
    </div>
    <!-- 补充 -->
    <!-- <div class="supplement">
			<div class="up">
				<div class="main_title">
					送风优化控制模型
				</div>
			</div>
			<div class="down flex">
				<div class="left">
					<div class="flex">
						<div class="shadow ">
							<div class="flex">
								<div class="shadow1_column1">A09-SFYSPH</div>
								<div class="shadow1_column2">0.0</div>
							</div>
						</div>
						<div class="shadow right1">
							<div class="flex">
								<div class="shadow1_column1">A09-SFYSPH</div>
								<div class="shadow1_column2">0.0</div>
							</div>
						</div>
					</div>
					<div class="down1 flex">
						<div class="shadow">
							<div class="flex">
								<div class="shadow1_column1">A09-SFYSPH</div>
								<div class="shadow1_column2">0.0</div>
							</div>
						</div>
						<div class="shadow right1">
							<div class="flex">
								<div class="shadow1_column1">A09-SFYSPH</div>
								<div class="shadow1_column2">0.0</div>
							</div>
						</div>
					</div>
				</div>
				<div class="middle flex">
					<div class="button button1" :style="{
							background:infoList.XK6 && !infoList.XK6.RM
					        ? '#2AFC30'
					        : 'red',
							}" @click="infoList.XK6
							? toDetail(1, 'XK6', infoList._node_string, '', '送风变频先控调节'): ''">
						X
					</div>
					<div>
						<div class="part2_word part2_word1">PZ1</div>
						<div class="part2_word part2_word2">PZ2</div>
						<div class="part2_word part2_word3">PZ3</div>
					</div>
					
				</div>
				<div class="right">
					<div class="shadow float1">
						<div class="flex">
							<div class="shadow1_column1">A09-SFYSPH</div>
							<div class="shadow1_column2">0.0</div>
						</div>
					</div>
					<div class="shadow float2">
						<div class="flex">
							<div class="shadow1_column1">A09-SFYSPH</div>
							<div class="shadow1_column2">0.0</div>
						</div>
					</div>
					<div class="shadow float3">
						<div class="flex">
							<div class="shadow1_column1">A09-SFYSPH</div>
							<div class="shadow1_column2">0.0</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
    <!-- explain -->
    <div class="explain">
      <div class="explain_content">
        <div class="explain1">
          说明：送风调节SADD：投入自动的变频或阀门均差1达到上限或者其中一个风机电流达到上限或者引风机能力达到上限；
        </div>
        <div class="explain2 flex">
          <div class="blank"></div>
          <div>SSUB：变频或阀门均差1达到下限。</div>
        </div>
      </div>
    </div>
    <inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
    <Historical
      v-if="isHshow"
      @sendStatus="isHshow = false"
      :historyname="historyname"
      :node="node"
      :node1="node1"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isMshow = false"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :pmark="Panualmark"
      :pnode="Panualnode"
      :pnm="Panualnodem"
      :infoList="infoList"
    >
    </Manual>
  </div>
</template>

<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import Manual from "@/components/Manual1.vue"; //手操器组件
export default {
  name: "Param11",
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
  },
  components: {
    inputVal,
    Historical,
    Manual,
  },
  data: () => {
    return {
      chName: "",
      Panualmark: "",
      Panualnode: "",
      Panualnodem: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
	  node1:''
    };
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    DataJson(data) {
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
			case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "rqkfirstcontolRQL";
            break;
          case 3:
            pathname = "rqRfirstcontolRQL";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重fsdndsfjhfdkshj
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isshowfase() {
      this.isComShow = false;
    },
    closeCompon() {
      this.$emit("sendStatus", "Param04", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
      }
    },
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    toCompon(key, name, name2, name3, name4, titname, param1, param2, param3) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            // console.log(1);
            return (
              (this.isMshow = true),
              (this.Manualname = name),
              (this.Manualnode = name2),
              (this.ManualAname = name3),
              (this.Manualtitname = titname),
              (this.Panualmark = param1),
              (this.Panualnode = param2),
              (this.Panualnodem = param3)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = name4),(this.node1 = titname);
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.total {
  width: 53vw;
  height: 67vh;
  // height: 86vh;
  background-image: url("~@/assets/images/RqBoiler/servive_bg.png");
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -35vh 0 0 -26.5vw;
  z-index: 999;

  .title-hang {
    width: 53vw;
    height: 5vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
  }

  .zhuangshizb {
    width: 16vw;
    height: 2vh;
    background-image: url("~@/assets/images/rfl_evaluate06.png");
    background-size: 100% 100%;
    margin-top: 2vh;
  }

  .zhuangshizj {
    width: 19vw;
    height: 5vh;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
    margin-top: 1vh;
    text-align: center;
    background-image: url("~@/assets/images/rfl_evaluate05.png");
    background-size: 100% 100%;
    margin-left: 1vw;
  }

  .zhuangshiyb {
    width: 16vw;
    height: 2vh;
    background-image: url("~@/assets/images/rfl_evaluate04.png");
    background-size: 100% 100%;
    margin-left: 1vw;
    margin-top: 2vh;
  }

  .icon {
    width: 2vw;
    height: 3.4vh;
    background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
    background-size: 100% 100%;
    margin-left: 13vw;
    margin-top: -0.5vh;
    cursor: pointer;
  }

  .main {
    width: 53vw;
    height: 2vh;
    margin-top: 2vh;

    .main_title {
      font-family: PingFang-SC-Regular;
      font-size: 1.7vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vh;
      letter-spacing: 1vh;
      color: #d5fffe;
      text-align: center;
    }
  }

  .content {
    width: 50vw;
    height: 51vh;
    margin-left: 1.5vw;

    .shadow {
      background-color: #001b2860;
      box-shadow: 0vw 0vw 0vw 0vw #15384c;
      border-radius: 0vw;
      border: 1px solid #236f8d;
      box-sizing: border-box;
      font-family: PingFang-SC-Regular;
      font-size: 0.8vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vh;
      letter-spacing: 0vh;
    }

    .button {
      width: 1vw;
      height: 2vh;
      border: 2px solid rgb(217, 243, 145);
      text-align: center;
      line-height: 2vh;
      background-color: #00e4ff;
      color: white;
      cursor: pointer;
    }

    .one {
      font-family: PingFang-SC-Regular;
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0vh;
      line-height: 2.23vh;
      z-index: 1;

      .shadow1 {
        width: 10vw;
        height: 7vh;

        .shadow1_column1 {
          margin-left: 0.5vw;
          width: 5.5vw;
          color: #8aeaff;
        }

        .shadow1_column2 {
          width: 3vw;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }
      }

      .shadow2 {
        width: 17vw;
        height: 12vh;
        margin-top: 0.56vh;
        line-height: 2.23vh;

        .shadow2_column {
          width: 8.8vw;

          .shadow2_column1 {
            margin-left: 0.5vw;
            width: 5vw;
            color: #8aeaff;
          }

          .shadow2_column2 {
            width: 2.5vw;
            color: #00ffb4;
            text-align: right;
            cursor: pointer;
          }

          .shadow2_column3 {
            width: 2.5vw;
            color: #14a4ff;
            text-align: right;
            cursor: pointer;
          }

          .shadow2_progress {
            margin-left: 0.8vw;
            margin-top: 0.6vh;

            .shadow2_num {
              line-height: 1.8vh;
              width: 2.5vw;
              color: #8aeaff;
            }

            .shadow2_num1 {
              line-height: 1.8vh;
              width: 4.4vw;
              text-align: right;
              color: #14a4ff;
            }

            .progress {
              width: 7vw;
              height: 1vh;
              background-color: #8aeaff;
            }
          }
        }
      }

      .shadow3 {
        width: 9vw;
        height: 2.7vh;

        .shadow3_column1 {
          margin-left: 0.3vw;
          width: 6.3vw;
          color: #8aeaff;
        }

        .shadow3_column2 {
          width: 2vw;
          color: #00ffb4;
          text-align: right;
          cursor: pointer;
        }

        .shadow3_column3 {
          width: 2vw;
          color: #14a4ff;
          text-align: right;
          cursor: pointer;
        }
      }

      .location1 {
        margin-top: 1.8vh;
      }

      .location3 {
        margin-top: 0.6vh;
      }

      .location4 {
        margin-top: 0.8vh;
      }

      .location6 {
        margin-top: 0.6vh;
      }

      .shadow4 {
        width: 16vw;
        height: 11vh;
        margin-top: 0.5vh;

        .shadow4_column {
          width: 8vw;

          .shadow4_words {
            width: 8vw;
            text-align: center;
            color: #2fc3e3;
          }

          .shadow4_column1 {
            width: 5vw;
            margin-left: 0.3vw;
            color: #8aeaff;
          }

          .shadow4_column2 {
            width: 2vw;
            color: #00ffb4;
            text-align: right;
            cursor: pointer;
          }

          .shadow4_column3 {
            width: 2vw;
            color: #14a4ff;
            text-align: right;
            cursor: pointer;
          }
        }
      }
    }

    .two {
      width: 33vw;
      height: 42.5vh;
      background-image: url("~@/assets/images/RqBoiler/para4.png");
      background-size: 100% 100%;
      margin-left: -9vw;
      margin-top: 3vh;
      font-family: PingFang-SC-Regular;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0vh;

      .part1 {
        .shadow5 {
          font-size: 0.8vw;
          line-height: 2vh;
          width: 7.6vw;
          height: 2.7vh;
          margin-left: 7.76vw;
          margin-top: 0.8vh;

          .shadow5_column1 {
            margin-left: 0.3vw;
            width: 4.7vw;
            color: #8aeaff;
          }

          .shadow5_column2 {
            width: 2vw;
            color: #00ffb4;
            text-align: right;
            cursor: pointer;
          }
        }

        .button1 {
          margin-left: 1.7vw;
          margin-top: 0.6vh;
          cursor: pointer;
        }

        .blue {
          font-size: 0.8vw;
          line-height: 2vh;
          width: 3.9vw;
          height: 2.4vh;
          background-color: #00afdc;
          border-radius: 0.19vh;
          margin-left: 9.1vw;
          margin-top: 1.6vh;
          color: #e3f6ff;
          text-align: center;
          cursor: pointer;
        }

        .green {
          font-size: 0.8vw;
          line-height: 2vh;
          width: 3.9vw;
          height: 2.4vh;
          background-color: #04ff57;
          border-radius: 0.19vh;
          margin-left: 9.1vw;
          margin-top: 1.5vh;
          color: #00832b;
          text-align: center;
          cursor: pointer;
        }

        .part1_words {
          width: 3vw;
          height: 2.78vh;
          font-size: 0.8vw;
          line-height: 2vh;
          color: #8aeaff;
          margin-left: 12.5vw;

          .part1_word {
            width: 1.5vw;
          }

          .part1_num {
            width: 1.5vw;
            text-align: right;
            cursor: pointer;
          }
        }

        .shadow6 {
          font-size: 0.8vw;
          line-height: 2vh;
          width: 7.6vw;
          height: 2.7vh;
          margin-left: 0.5vw;
          margin-top: -1vh;

          .shadow6_column1 {
            margin-left: 0.3vw;
            width: 4.7vw;
            color: #8aeaff;
          }

          .shadow6_column2 {
            width: 2vw;
            color: #14a4ff;
            text-align: right;
            cursor: pointer;
          }
        }

        .shadow7 {
          font-size: 0.8vw;
          line-height: 2vh;
          width: 10vw;
          height: 2.7vh;
          margin-left: 2vw;
          margin-top: 2.5vh;

          .shadow7_column1 {
            margin-left: 0.3vw;
            width: 7vw;
            color: #8aeaff;
          }

          .shadow7_column2 {
            width: 2vw;
            color: #14a4ff;
            text-align: right;
            cursor: pointer;
          }
        }

        .button2 {
          margin-left: 2.34vw;
          margin-top: 2vh;
        }

        .button3 {
          margin-left: 11.8vw;
          margin-top: 2vh;
          background-color: #04ff57;
        }

        .button4 {
          margin-left: 2.34vw;
          margin-top: 6.8vh;
        }

        .button5 {
          margin-left: 11.8vw;
          margin-top: 9.5vh;
          background-color: #04ff57;
        }

        .button6 {
          margin-left: 17vw;
          margin-top: 2vh;
        }
      }

      .part2 {
        .part2_word {
          width: 1.3vw;
          height: 1.11vh;
          font-family: PingFang-SC-Regular;
          font-size: 1vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.59vh;
          letter-spacing: 0vh;
          color: #8aeaff;
        }

        .button7 {
          margin-left: 8vw;
          margin-top: 2.8vh;
        }

        .part2_word1 {
          margin-left: 5.5vw;
          margin-top: -1.6vh;
        }

        .button8 {
          margin-left: 8vw;
          margin-top: 3.5vh;
        }

        .button9 {
          margin-left: 8vw;
          margin-top: 23vh;
        }

        .button10 {
          margin-left: 8vw;
          margin-top: 3.3vh;
        }

        .part2_word2 {
          margin-left: 5.5vw;
          margin-top: -1.3vh;
        }

        .part2_word3 {
          margin-left: 5.5vw;
          margin-top: -1vh;
        }

        .part2_word4 {
          margin-left: 5.5vw;
          margin-top: -1vh;
        }
      }
    }

    .three {
      .shadow8 {
        font-size: 0.8vw;
        line-height: 2vh;
        width: 8vw;
        height: 2.7vh;
        margin-left: 0vw;
        margin-top: 3.5vh;

        .shadow8_column1 {
          margin-left: 0.3vw;
          color: #8aeaff;
          width: 4vw;
        }

        .shadow8_column2 {
          width: 3vw;
          color: #14a4ff;
          text-align: right;
          cursor: pointer;
        }
      }

      .shadow9 {
        font-size: 0.8vw;
        line-height: 2vh;
        width: 8vw;
        height: 2.7vh;
        margin-left: 0vw;
        margin-top: 3.5vh;

        .shadow9_column1 {
          margin-left: 0.3vw;
          color: #8aeaff;
          width: 4vw;
        }

        .shadow9_column2 {
          width: 3vw;
          color: #14a4ff;
          text-align: right;
          cursor: pointer;
        }
      }

      .shadow10 {
        font-size: 0.8vw;
        line-height: 2vh;
        width: 8vw;
        height: 2.7vh;
        margin-left: 0vw;
        margin-top: 2.5vh;

        .shadow10_column1 {
          margin-left: 0.3vw;
          color: #8aeaff;
          width: 4vw;
        }

        .shadow10_column2 {
          width: 3vw;
          color: #14a4ff;
          text-align: right;
          cursor: pointer;
        }
      }

      .shadow11 {
        font-size: 0.8vw;
        line-height: 2vh;
        width: 8vw;
        height: 2.7vh;
        margin-left: 0vw;
        margin-top: 3.2vh;

        .shadow11_column1 {
          margin-left: 0.3vw;
          color: #8aeaff;
          width: 4vw;
        }

        .shadow11_column2 {
          width: 3vw;
          color: #14a4ff;
          text-align: right;
          cursor: pointer;
        }
      }

      .table2 {
        margin-left: -2vw;
        margin-top: 3vh;

        .table_title {
          width: 10vw;
          text-align: center;
          color: #8aeaff;
        }

        .table_total {
          width: 10vw;
          height: 14vh;
          border: 1px solid rgba(0, 228, 255, 0.2);
          box-sizing: border-box;
          font-family: PingFang-SC-Regular;
          font-size: 0.8vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2vh;
          letter-spacing: 0vh;

          .table_line {
            height: 2.32vh;
            border-bottom: 1px solid rgba(0, 228, 255, 0.2);
            box-sizing: border-box;

            .table_column3 {
              width: 7vw;
              color: #2fc3e3;
              text-align: center;

              .zero1 {
                width: 1.75vw;
                color: #00ffb4;
                text-align: center;
                cursor: pointer;
              }

              .mark {
                width: 3vw;
                color: #8aeaff;
                text-align: center;
              }

              .zero2 {
                width: 1.75vw;
                color: #00ffb4;
                text-align: center;
                cursor: pointer;
              }
            }

            .table_column4 {
              width: 3vw;
              text-align: center;
              border-left: 1px solid rgba(0, 228, 255, 0.2);
              color: #00ffb4;
              cursor: pointer;
            }

            .words {
              color: #2fc3e3;
            }
          }
        }
      }
    }
  }

  // .supplement {
  // 	width: 50vw;
  // 	height: 21vh;
  // 	margin-top: -3vh;
  // 	// background-color: white;
  // 	margin-left: 1.5vw;

  // 	.shadow {
  // 		width: 9vw;
  // 		height: 2.22vh;
  // 		background-color: #001b2860;
  // 		box-shadow: 0vw 0vw 0vw 0vw #15384c;
  // 		border-radius: 0vw;
  // 		border: 1px solid #236f8d;
  // 		box-sizing: border-box;
  // 		font-family: PingFang-SC-Regular;
  // 		font-size: 0.8vw;
  // 		font-weight: normal;
  // 		font-stretch: normal;
  // 		line-height: 2vh;
  // 		letter-spacing: 0vh;
  // 		z-index: 3;

  // 		.shadow1_column1 {
  // 			margin-left: 0.2vw;
  // 			width: 6vw;
  // 			color: #8aeaff;
  // 		}

  // 		.shadow1_column2 {
  // 			width: 1.8vw;
  // 			color: #00ffb4;
  // 			text-align: right;
  // 			cursor: pointer;
  // 		}

  // 	}

  // 	.button {
  // 		width: 1vw;
  // 		height: 2vh;
  // 		border: 2px solid rgb(217, 243, 145);
  // 		text-align: center;
  // 		line-height: 2vh;
  // 		background-color: #00e4ff;
  // 		color: white;
  // 		cursor: pointer;
  // 	}

  // 	.main_title {
  // 		width: 50vw;
  // 		text-align: center;
  // 		margin-left: 2vw;
  // 		font-family: PingFang-SC-Regular;
  // 		font-size: 1.7vw;
  // 		font-weight: normal;
  // 		font-stretch: normal;
  // 		line-height: 2vh;
  // 		letter-spacing: 1vh;
  // 		color: #d5fffe;
  // 		text-align: center;
  // 	}

  // 	.down {
  // 		margin-top: 1.5vh;

  // 		.left {
  // 			width: 15.73vw;
  // 			margin-top: 2vh;

  // 			.right1 {
  // 				margin-left: 0.78vw;
  // 			}

  // 			.down1 {
  // 				margin-top: 2.7vh;
  // 			}
  // 		}

  // 		.middle {
  // 			width: 24.72vw;
  // 			height: 15.87vh;
  // 			background-image: url("~@/assets/images/RqBoiler/para041.png");
  // 			background-size: 100% 100%;

  // 			.button1{
  // 				margin-left: 11.5vw;
  // 				margin-top: 1.9vh;
  // 			}

  // 			.part2_word {
  // 				width: 1.3vw;
  // 				height: 1.11vh;
  // 				font-family: PingFang-SC-Regular;
  // 				font-size: 1vw;
  // 				font-weight: normal;
  // 				font-stretch: normal;
  // 				line-height: 2.59vh;
  // 				letter-spacing: 0vh;
  // 				color: #8aeaff;
  // 				margin-left: 8.2vw;
  // 			}

  // 			.part2_word1 {
  // 				margin-top: -0.5vh;
  // 			}

  // 			.part2_word2 {
  // 				margin-top: 4.5vh;
  // 			}

  // 			.part2_word3 {
  // 				margin-top: 4.5vh;
  // 			}
  // 		}

  // 		.right {
  // 			width: 7.6vw;

  // 			.float1 {
  // 				margin-top: 2vh;
  // 			}

  // 			.float2 {
  // 				margin-top: 3.5vh;
  // 			}

  // 			.float3 {
  // 				margin-top: 3.4vh;
  // 			}
  // 		}
  // 	}
  // }

  .explain {
    width: 53vw;
    height: 6vh;
    border-top: 2px solid #bbd6e5;
    font-family: PingFang-SC-Regular;
    font-size: 1vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #4d91a7;
    margin-top: 1vh;

    .explain_content {
      margin-top: 1vh;
      margin-left: 2vw;

      .explain2 {
        .blank {
          width: 6.5vw;
        }
      }
    }
  }
}
</style>
